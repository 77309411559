import { call, fork, put, select } from 'redux-saga/effects';
import { Action, PayloadAction } from '@reduxjs/toolkit';
import { UserEntries, UserId, UserLeftBatchPayload } from 'features/users/types';
import { selectUserEntries } from 'features/users/usersSlice';
import { selectIsRecorderSession } from 'features/recorder/recorderSlice';
import { cleanupUsers } from 'features/users/thunks/cleanupUsers';
import { RTCClient } from 'utils/webrtc';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { cleanupE2eeUser } from 'features/e2ee/cleanupE2eeUser';
import { refreshEncryptionKeys } from 'features/e2ee/sagas/refreshEncryptionKeys';
import { board } from 'utils/whiteboard/BoardStateManager';
import {
  raiseHandNotificationUpdateRequested,
  userLeftNotificationTriggered,
} from 'features/notifications/actions';
import { eventBus } from 'utils/eventBus';
import { refineSframeStatus } from 'features/e2ee/sagas/refineSframeStatus';

export function* onUserLeftBatchedSaga(action: PayloadAction<UserLeftBatchPayload>) {
  const { users } = action.payload;

  const isRecorderSession: boolean = yield select(selectIsRecorderSession);
  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);

  const userEntities: UserEntries = yield select(selectUserEntries);
  const availableUserIds: UserId[] = [];

  if (e2eeEnabled) {
    yield call(refineSframeStatus);
  }

  // filter out users not available to prevent possible race-condition from the backend
  for (const { id } of users) {
    // we don't yield inside a loop to avoid stack overflow by saga https://github.com/redux-saga/redux-saga/issues/1592
    const user = userEntities[id];
    if (user) {
      availableUserIds.push(user.id);

      // cleanup E2EE stuff
      if (e2eeEnabled) {
        cleanupE2eeUser(user.id, user.e2eeId!);
      }

      RTCClient.receivingFeed.unloadFeedAudio(user.id);
      board.removeRemotePointer(user.id);
    }
  }

  if (!isRecorderSession) {
    yield put(userLeftNotificationTriggered(availableUserIds));

    yield call(eventBus.sendMessage, 'userLeftBatch', undefined, {
      reason: 'generic',
      userIds: availableUserIds,
    });
  }

  const cleanupUsersThunk: Action = yield call(cleanupUsers, availableUserIds);
  yield put(cleanupUsersThunk);

  if (!isRecorderSession) {
    // we have to wait until the user is completely removed from the store
    if (e2eeEnabled) {
      yield fork(refreshEncryptionKeys);
    }

    // update raise hand notification
    yield put(raiseHandNotificationUpdateRequested({ reason: 'user-left' }));
  }
}
