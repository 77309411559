import { EnumeratedMediaDevices } from 'features/user-media/types';
import webrtcAdapter from 'webrtc-adapter';
import { getDefaultMediaDevice } from 'features/user-media/utils';
import { getPersistedDeviceId } from 'features/user-media/utils/getPersistedDeviceId';

// Gets a correct device to be used on the first user media request
export const getDefaultPromptedDevice = (
  mediaDevices: EnumeratedMediaDevices,
  kind: MediaDeviceKind
) => {
  const persistedDeviceId = getPersistedDeviceId(mediaDevices, kind);
  if (persistedDeviceId) {
    return persistedDeviceId;
  }

  // Firefox returns `deviceId` even for devices without permissions.
  // We will use this device for our request.
  if (webrtcAdapter.browserDetails.browser === 'firefox') {
    return mediaDevices[kind][0]?.deviceId;
  }

  return getDefaultMediaDevice(mediaDevices, kind);
};
