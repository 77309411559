import SearchInput from 'components/SearchInput';
import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BreakoutsUsersList from 'features/breakout-rooms/components/breakouts-users-selector/BreakoutsUsersList';
import {
  SelectedUserIds,
  ToggleUserHandler,
} from 'features/breakout-rooms/modals/AddUsersToRoomModal';
import { useSearch } from 'hooks/useSearch';

export type BreakoutUsersSelectorProps = {
  toggleUser: ToggleUserHandler;
  selectedIds: SelectedUserIds;
  participantsLimit: number;
};

const BreakoutsUsersSelector = React.memo(
  ({ toggleUser, selectedIds, participantsLimit }: BreakoutUsersSelectorProps) => {
    const { t } = useTranslation(['breakout-rooms', 'common']);

    const { onSearchInputChange, searchQuery } = useSearch();

    return (
      <div>
        <Box
          sx={{
            mb: 6,
          }}
        >
          <SearchInput
            fullWidth
            placeholder={t('common:input_search_placeholder')}
            onChange={onSearchInputChange}
            inputProps={{
              'aria-label': t('common:input_search_placeholder'),
            }}
          />
        </Box>
        <BreakoutsUsersList
          searchQuery={searchQuery}
          selectedIds={selectedIds}
          toggleUser={toggleUser}
          participantsLimit={participantsLimit}
        />
      </div>
    );
  }
);

export default BreakoutsUsersSelector;
