import { createSelector } from '@reduxjs/toolkit';
import { selectUnreadPollUpdates } from 'features/polls/pollsSlice';
import { selectChatUnreadMessagesCount } from 'features/chat/chatSlice';
import { selectQAUnreadCount } from 'features/qa/qaSlice';

export const selectToolbarHasUpdates = createSelector(
  [selectUnreadPollUpdates, selectChatUnreadMessagesCount, selectQAUnreadCount],
  (pollUpdates, unreadChatMessages, qaUnreadCount) =>
    pollUpdates.length > 0 || unreadChatMessages > 0 || qaUnreadCount > 0
);
