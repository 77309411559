import { call, put } from 'redux-saga/effects';
import { clearWaitingUsersList } from 'features/users/actions';
import { Action } from '@reduxjs/toolkit';
import { closeActionNotification } from 'features/notifications/thunks/closeActionNotification';
import { WAITING_ENTRY_NOTIFICATION_ID } from 'features/notifications/constants';

export function* onBulkEntryRejectedSaga() {
  yield put(clearWaitingUsersList());

  const cleanupNotificationThunk: Action = yield call(
    closeActionNotification,
    WAITING_ENTRY_NOTIFICATION_ID
  );
  yield put(cleanupNotificationThunk);
}
