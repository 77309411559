import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal, selectActiveModalName } from 'features/modal/modalSlice';
import Settings from 'features/join/publisher/settings/Settings';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, DialogContent } from 'components/Dialog';

const SettingsModal = () => {
  const { t } = useTranslation('settings');
  const dispatch = useAppDispatch();
  const activeModal = useAppSelector(selectActiveModalName);

  const hideModal = () => {
    dispatch(closeModal('publisherSettings'));
  };

  return (
    <Dialog
      open={activeModal === 'publisherSettings'}
      aria-labelledby="join-publisher-settings-dialog"
      onClose={hideModal}
      maxWidth="md"
      centered={false}
    >
      <DialogTitle id="join-publisher-settings-dialog" onClose={hideModal}>
        {t('modal_title')}
      </DialogTitle>
      <DialogContent>
        <Settings />
      </DialogContent>
    </Dialog>
  );
};

export default SettingsModal;
