import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Title from 'components/Title';
import Subtitle from 'components/Subtitle';
import FormFooter from 'components/FormFooter';
import { useAudioOutputField } from 'hooks/useAudioOutputField';
import DeviceTest from 'components/DeviceTest';
import { useSpeakersTest } from 'hooks/useSpeakersTest';
import { useTranslation } from 'react-i18next';
import { JoinScreenInner, JoinScreenWrapper } from 'features/join/styles';
import { Box, Button, Typography } from '@mui/material';
import DeviceSelect from 'components/DeviceSelect';
import { useMediaDevices } from 'hooks/useMediaDevices';
import { enumerateDevices, getDefaultMediaDevice } from 'features/user-media/utils';
import { activeMediaDeviceUpdated, mediaDevicesUpdated } from 'features/user-media/userMediaSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { findDeviceDifferences } from 'features/user-media/utils/findDeviceDifferences';
import { roomJoinRequest } from 'features/room/actions';
import { useUserAudioOutput } from 'hooks/useUserAudioOutput';
import { selectAwaitingRoomJoin } from 'features/room/roomSlice';
import AwaitingRoomJoin from 'features/join/shared/AwaitingRoomJoin';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import Popper from 'components/Popper';
import { E2eePopoverTrigger } from 'features/join/shared/E2eePopoverTrigger';

export type JoinAudienceFormValues = {
  audiooutput: string;
};

const JoinAudience = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['join', 'settings']);

  const awaitingRoomJoin = useAppSelector(selectAwaitingRoomJoin);

  const { activeMediaDevices, mediaDevices } = useMediaDevices();

  const { handleSubmit, control, setValue } = useForm<JoinAudienceFormValues>({
    defaultValues: {
      audiooutput: activeMediaDevices.audiooutput || '',
    },
  });

  const audioOutputHandler = useUserAudioOutput();

  const { audioOutputDisabled, audioOutputOptions, audioOutputPlaceholder } = useAudioOutputField();

  const { status: speakersTestStatus, playTestSound } = useSpeakersTest();

  const e2eeEnabled = useAppSelector(selectE2eeEnabled);

  useEffect(() => {
    const listener = async () => {
      const updatedDevices = await enumerateDevices();
      const { removedActiveDevices, addedDevices } = findDeviceDifferences(
        updatedDevices,
        mediaDevices,
        activeMediaDevices
      );

      if (removedActiveDevices.audiooutput || addedDevices.audiooutput) {
        const deviceId = getDefaultMediaDevice(updatedDevices, 'audiooutput');
        if (!deviceId) {
          throw new Error('Error updating media devices');
        }

        dispatch(
          activeMediaDeviceUpdated({
            kind: 'audiooutput',
            id: deviceId,
          })
        );

        setValue('audiooutput', deviceId);
      }

      dispatch(
        mediaDevicesUpdated(updatedDevices, {
          updatePermissions: true,
        })
      );
    };

    navigator.mediaDevices.addEventListener('devicechange', listener);
    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', listener);
    };
  }, [dispatch, setValue, mediaDevices, activeMediaDevices]);

  const handleJoin: SubmitHandler<JoinAudienceFormValues> = async () => {
    dispatch(roomJoinRequest());
  };

  const renderJoinButton = () => {
    if (awaitingRoomJoin) {
      return <AwaitingRoomJoin />;
    }

    return (
      <Button type="submit" variant="contained" fullWidth>
        {t('audience.btn_label_join')}
      </Button>
    );
  };

  return (
    <JoinScreenWrapper>
      <JoinScreenInner>
        <Box textAlign="center">
          <Title>{t('audience.title')}</Title>
          {e2eeEnabled ? (
            <Subtitle>
              {t('audience.e2ee_subtitle.before_e2ee_label')}{' '}
              <Popper
                id="e2ee-session-message"
                triggerType={['hover', 'focus']}
                placement="top"
                content={<Typography>{t('audience.e2ee_subtitle.e2ee_tooltip_text')}</Typography>}
                contentWidth="304px"
              >
                <E2eePopoverTrigger>{t('audience.e2ee_subtitle.e2ee_label')}</E2eePopoverTrigger>
              </Popper>{' '}
              {t('audience.e2ee_subtitle.after_e2ee_label')}
            </Subtitle>
          ) : (
            <Subtitle>{t('audience.subtitle')}</Subtitle>
          )}
        </Box>
        <Typography textAlign="center" mb={8}>
          {t('audience.description')}
        </Typography>
        <form onSubmit={handleSubmit(handleJoin)}>
          <div>
            <Box mb={3}>
              <DeviceSelect
                id="join-audience-speakers-select"
                name="audiooutput"
                control={control}
                icon="speakers"
                onChange={audioOutputHandler}
                disabled={audioOutputDisabled}
                placeholder={audioOutputPlaceholder}
                options={audioOutputOptions}
                ariaLabel={t('settings:device_dropdowns.audiooutput.aria_label')}
              />
            </Box>
            <DeviceTest
              tooltip={t('settings:device_test.speakers.tooltip_text')}
              id="speakers-test"
              helpText={t('settings:device_test.speakers.description')}
              onClick={playTestSound}
              iconLabel={t('settings:device_test.speakers.icon_aria_label')}
              processing={speakersTestStatus === 'processing'}
            >
              {speakersTestStatus === 'processing'
                ? t('settings:device_test.speakers.btn_label_processing')
                : t('settings:device_test.speakers.btn_label_default')}
            </DeviceTest>
          </div>
          <FormFooter>{renderJoinButton()}</FormFooter>
        </form>
      </JoinScreenInner>
    </JoinScreenWrapper>
  );
};

export default JoinAudience;
