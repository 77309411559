import { call, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { UserHandLoweredPayload, UserId } from 'features/users/types';
import { selectLocalUserId } from 'features/users/usersSlice';
import { eventBus } from 'utils/eventBus';
import { logger } from 'utils/logger';
import { raiseHandNotificationUpdateRequested } from 'features/notifications/actions';

export function* onHandLoweredSaga(action: PayloadAction<UserHandLoweredPayload>) {
  if (action.payload.initiator) {
    logger
      .remote({ tier: 1 })
      .info(`The user's hand was lowered by user id=${action.payload.initiator.id}`);
  }

  const eventPayload = action.payload.initiator
    ? { initiator: action.payload.initiator.id }
    : undefined;

  yield call(eventBus.sendMessage, 'handLowered', { userId: action.payload.id }, eventPayload);

  const localUserId: UserId = yield select(selectLocalUserId);
  if (localUserId === action.payload.id) {
    // ignore notification cleanup for the local user since there is no notification
    return;
  }

  yield put(raiseHandNotificationUpdateRequested({ reason: 'hand-lowered' }));
}
