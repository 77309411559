import { PayloadAction } from '@reduxjs/toolkit';
import { RTCEventPayload } from 'features/streaming/types';
import { NO_PUBLISHING_FEED } from 'utils/webrtc/errors';
import { call } from 'redux-saga/effects';
import { PublishingRoomJoinedMessage, RTCClient } from 'utils/webrtc';
import { PublishingFeed } from 'utils/webrtc/publishing/PublishingFeed';
import { ScreensharingFeed } from 'utils/webrtc/publishing/ScreensharingFeed';

// @TODO refine payload type
export function* onPublisherJoinedSaga(
  action: PayloadAction<RTCEventPayload<PublishingRoomJoinedMessage>>
) {
  const { handle, message, kind } = action.payload;
  if (!kind) {
    throw NO_PUBLISHING_FEED(handle);
  }

  const feed: PublishingFeed | ScreensharingFeed = yield call(RTCClient.getPublishingFeed, kind);
  const { handle: videoroom } = feed;

  if (videoroom) {
    videoroom.privateId = message.private_id;
    videoroom.feedId = message.id;

    yield call(RTCClient.setupLocalFeed, message);

    yield call(feed.requestPublish);

    yield call(RTCClient.receivingFeed.updateSimulcastConfig);
  } else {
    throw NO_PUBLISHING_FEED(handle);
  }
}
