import cx from 'clsx';
import { UserId } from 'features/users/types';
import { MutableRefObject, useEffect, useState, VideoHTMLAttributes } from 'react';
import { Video } from 'features/streaming/components/media/Video';
import { useRemoteVideo } from 'features/streaming/useRemoteVideo';
import { SubscriberMid } from 'features/streaming/types';
import { useAppSelector } from 'store/hooks';

export const RemoteVideo = ({
  mid,
  userId,
  ...videoProps
}: { mid: SubscriberMid; userId: UserId } & VideoHTMLAttributes<any>) => {
  const videoRef = useRemoteVideo(mid);
  const [fitment, setFitment] = useState('crop');

  const preserveAspectRatio = useAppSelector(
    (state) => state.streaming.preserveAspectRatioByUserId[userId]
  );

  useEffect(() => {
    setFitment(preserveAspectRatio ? 'fit' : 'crop');
  }, [preserveAspectRatio]);

  const onResize = (e: React.SyntheticEvent<HTMLVideoElement>) => {
    if (preserveAspectRatio) {
      return;
    }

    if (e.target && e.target instanceof HTMLVideoElement) {
      const [width, height] = [e.target.videoWidth, e.target.videoHeight];

      if (width > height) {
        setFitment('crop');
      } else {
        setFitment('fit');
      }
    }
  };

  return (
    <Video
      ref={videoRef as MutableRefObject<HTMLVideoElement>}
      onResize={onResize}
      muted
      playsInline
      {...videoProps}
      className={cx(videoProps.className, `fitment-${fitment}`)}
    />
  );
};
