import { call } from 'redux-saga/effects';
import { UICallbackName, UICallbackTypes } from 'utils/eventBus';
import { SDKEventListenerManager } from 'utils/sdk/eventLiseners';

export function* onControlUICallbackSaga(type: UICallbackTypes, data: { name: UICallbackName }) {
  if (type === 'connectUICallback') {
    yield call(SDKEventListenerManager.addUICallback, data.name);

    return;
  }

  yield call(SDKEventListenerManager.removeUICallback, data.name);
}
