import { call, put, select } from 'redux-saga/effects';
import {
  selectIsRecorderSession,
  selectRecorderStatus,
  stopRecording,
} from 'features/recorder/recorderSlice';
import { RecorderStatus } from 'features/recorder/types';
import { stopRecorderSaga } from 'features/recorder/sagas/stopRecorderSaga';
import { logger } from 'utils/logger';
import { signalingRecordingStopRequested } from 'features/recorder/actions';
import { openModal } from 'features/modal/modalSlice';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';

export function* stopRecordingSaga(action: ReturnType<typeof signalingRecordingStopRequested>) {
  const isRecorderSession: boolean = yield select(selectIsRecorderSession);
  if (isRecorderSession) {
    yield call(stopRecorderSaga);
    return;
  }

  const recorderStatus: RecorderStatus = yield select(selectRecorderStatus);
  if (recorderStatus === 'stopped') {
    return;
  }

  logger.remote({ tier: 1 }).info('Recording stopped');

  yield put(stopRecording());

  if (action.payload) {
    const canManageRecording: boolean = yield call(hasPermissionsSaga, PermissionTypes.recording);

    if (canManageRecording) {
      const { reason, minutesLimit } = action.payload;

      if (reason === 'limit_reached') {
        yield put(openModal('recordingPlanLengthLimit', { minutesLimit }));
      }
    }
  }
}
