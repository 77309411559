import React from 'react';
import { Box, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { selectApplicationLogo } from 'features/branding/brandingSlice';

type PageLayoutProps = {
  children: React.ReactNode;
};

const BrandedLayout = ({ children }: PageLayoutProps) => {
  const { t } = useTranslation();

  const logo = useAppSelector(selectApplicationLogo);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        height: '100vh',
        minHeight: '100vh',
      }}
    >
      {logo ? (
        <Container maxWidth={false}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              pt: 8,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: { xs: 'center', lg: 'flex-start' },
                '& img': {
                  verticalAlign: 'middle',
                  maxWidth: '200px',
                  maxHeight: '40px',
                  objectFit: 'contain',
                },
              }}
            >
              <img src={logo} alt={t('logo_alt')} />
            </Box>
          </Box>
        </Container>
      ) : null}
      <Box
        sx={{
          py: 8,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth="xl">{children}</Container>
      </Box>
    </Box>
  );
};

export default BrandedLayout;
