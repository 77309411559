import { alpha, styled } from '@mui/material/styles';
import NoStylesButton from 'components/NoStylesButton';

export const E2eePopoverTrigger = styled(NoStylesButton)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  cursor: 'pointer',
  '&:focus-visible': {
    borderRadius: '4px',
    boxShadow: `0 0 0 3px ${alpha(theme.palette.primary.main, 0.35)}`,
  },
}));
