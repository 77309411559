import React, { useCallback, useDeferredValue, useState } from 'react';

export const useSearch = () => {
  const [value, setValue] = useState('');
  const deferredQuery = useDeferredValue(value);

  const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }, []);

  const resetSearch = useCallback(() => {
    setValue('');
  }, []);

  return {
    searchValue: value,
    searchQuery: deferredQuery,
    onSearchInputChange: handleSearchChange,
    resetSearch,
  };
};
