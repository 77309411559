import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  selectActiveVirtualInitialized,
  selectVirtualBackgroundStatus,
} from 'features/virtual-backgrounds/selectors';
import { RTCClient } from 'utils/webrtc';
import { RootState } from 'store/store';

export const initVirtualBackground = createAsyncThunk<
  void,
  void,
  {
    state: RootState;
  }
>(
  'virtualBackground/init',
  async () => {
    await RTCClient.publishingFeed.virtualBackground.init();
  },
  {
    condition(_, { getState }) {
      const status = selectVirtualBackgroundStatus(getState());
      const isInitialized = selectActiveVirtualInitialized(getState());

      return !(isInitialized || status === 'initializing');
    },
    dispatchConditionRejection: true,
  }
);
