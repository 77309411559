import { styled } from '@mui/material/styles';
import { Box, BoxProps, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';
import { styleConstants } from 'theme/styleConstants';
import cx from 'clsx';

export { default as TabPanel } from './TabPanel';

export const Tabs = styled(MuiTabs)(({ theme }) => ({
  minHeight: 40,
  '&.MuiTabs-vertical': {
    minWidth: '210px',
    marginRight: '2rem',
    borderRight: `1px solid ${theme.palette.divider}`,
    '& .MuiTab-root': {
      paddingLeft: 0,
      alignItems: 'flex-start',
      textAlign: 'left',
      '&:hover': {
        color: theme.palette.primary.light,
      },

      '&.Mui-selected:hover': {
        color: theme.palette.primary.main,
      },
    },
  },
  '.MuiTabs-indicator': {
    transition: 'none',
  },
  '&:not(.MuiTabs-vertical)': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

export const Tab = styled(MuiTab)(({ theme }) => ({
  position: 'relative',
  flexShrink: 0,
  minWidth: '120px',
  minHeight: 0,
  padding: '0.625rem 1rem',
  overflow: 'hidden',
  color: theme.palette.text.primary,
  fontSize: '1rem',
  fontWeight: 'normal',
  textTransform: 'none',
  whiteSpace: 'normal',
  '&:hover': {
    color: theme.palette.primary.light,
  },
  '&.Mui-selected': {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

export const TabPanelInner = styled(({ className, ...props }: BoxProps) => (
  <Box className={cx('TabPanelInner-root', className)} {...props} />
))(({ theme }) => ({
  maxWidth: styleConstants.narrowContainerWidth,
  margin: '0 auto',
  [theme.breakpoints.up('md')]: {
    margin: 0,
  },
}));
