import { streamMaximized } from 'features/layout/actions';
import {
  selectExpectScreenshareOnStage,
  selectPreviouslyMaximizedStream,
} from 'features/layout/features/content/contentSlice';
import {
  contentWidthChanged,
  selectPinnedContentWidth,
} from 'features/layout/features/contentDimensions/contentDimensionsSlice';
import { selectOrderedStreams } from 'features/layout/features/order/orderSlice';
import { SourceDetails } from 'features/layout/types';
import { sourceMatches } from 'features/streaming/utils';
import { put, select } from 'redux-saga/effects';

export function* putScreenshareOnStageSaga() {
  const expectScreenshareOnStage: boolean = yield select(selectExpectScreenshareOnStage);

  if (!expectScreenshareOnStage) {
    return;
  }

  const excludedStream: SourceDetails = yield select(selectPreviouslyMaximizedStream);

  const orderedStreams: SourceDetails[] = yield select(selectOrderedStreams);

  const screenshareStream = orderedStreams.find(
    (stream) =>
      (stream.kind === 'screenshare-local' || stream.kind === 'screenshare-remote') &&
      !sourceMatches(stream, excludedStream)
  );

  if (screenshareStream) {
    const pinnedWidth: number = yield select(selectPinnedContentWidth);

    yield put(contentWidthChanged(pinnedWidth));
    yield put(streamMaximized({ source: screenshareStream }));
  }
}
