import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';

export type TooltipPlacement = TooltipProps['placement'];

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} arrow classes={{ popper: className }} />
))(({ theme, open }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 14,
    padding: theme.spacing(3),
    fontWeight: 400,
    backgroundColor: theme.palette.common.black,
    backfaceVisibility: 'hidden',
    '.Tooltip-title': {
      opacity: open ? 1 : 0,
    },
  },
  [`& .${tooltipClasses.tooltipPlacementRight}`]: {
    [`& .${tooltipClasses.arrow}`]: {
      marginLeft: -12,
      height: 20,
      width: 14,
    },
  },
  [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
    [`& .${tooltipClasses.arrow}`]: {
      marginTop: -12,
      height: 14,
      width: 20,
    },
  },
  [`& .${tooltipClasses.tooltipPlacementTop}`]: {
    [`& .${tooltipClasses.arrow}`]: {
      marginBottom: -12,
      height: 14,
      width: 20,
    },
  },
  [`& .${tooltipClasses.tooltipPlacementLeft}`]: {
    [`& .${tooltipClasses.arrow}`]: {
      marginRight: -12,
      height: 20,
      width: 14,
    },
  },
}));

export default Tooltip;
