import {
  messagesRead,
  selectPrivateChat,
  selectPrivateChatActiveView,
} from 'features/chat/chatSlice';
import { PrivateChatActiveViewState, PrivateChatView } from 'features/chat/types';
import { put, select } from 'redux-saga/effects';

export function* readPrivateChatSaga(chatId?: string) {
  let targetChat = chatId;
  if (!chatId) {
    const activeView: PrivateChatActiveViewState = yield select(selectPrivateChatActiveView);

    if (activeView.name === 'personal') {
      targetChat = activeView.data.id;
    }
  }

  if (!targetChat) {
    return;
  }

  const chatData: PrivateChatView = yield select(selectPrivateChat, targetChat);
  const readIds = chatData.messages.map((message) => message.id);

  yield put(messagesRead({ messages: readIds, chat: targetChat }));
}
