import { openModal } from 'features/modal/modalSlice';
import {
  selectRecorderStatus,
  selectRecordingConsentEnabled,
} from 'features/recorder/recorderSlice';
import { RecorderStatus } from 'features/recorder/types';
import { put, select } from 'redux-saga/effects';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { recordingToggled, requestRecording } from 'features/recorder/actions';

export function* toggleRecordingSaga(action: ReturnType<typeof recordingToggled>) {
  const status: RecorderStatus = yield select(selectRecorderStatus);

  const targetState = action.payload;

  if (status === 'started') {
    if (targetState) {
      return;
    }

    yield put(openModal('stopRecordingConfirmation'));

    return;
  }

  if (targetState !== undefined && !targetState) {
    return;
  }

  const consentEnabled: boolean = yield select(selectRecordingConsentEnabled);
  if (!consentEnabled) {
    yield put(requestRecording());
    return;
  }

  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
  if (e2eeEnabled) {
    yield put(openModal('recordingE2eeConsent'));
  } else {
    yield put(openModal('recordingConsent'));
  }
}
