import {
  addListener,
  createListenerMiddleware,
  TypedAddListener,
  TypedStartListening,
} from '@reduxjs/toolkit';
import { addMediaDeviceListeners } from 'features/user-media/listeners';
import { addUsersListeners } from 'features/users/listeners';
import { AppDispatch, RootState } from 'store/store';
import { addToolbarListeners } from 'features/toolbar/listeners';

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

export const listenerMiddleware = createListenerMiddleware();

export const startAppListening = listenerMiddleware.startListening as AppStartListening;

export const addAppListener = addListener as TypedAddListener<RootState, AppDispatch>;

addToolbarListeners(startAppListening);
addMediaDeviceListeners(startAppListening);
addUsersListeners(startAppListening);
