import { styled } from '@mui/material/styles';
import { Switch as MuiSwitch } from '@mui/material';

export const Switch = styled(MuiSwitch)(({ theme }) => ({
  width: 36,
  height: 22,
  padding: 0,
  display: 'flex',
  '& .MuiSwitch-switchBase': {
    padding: 3,
    color: theme.palette.grey['400'],
    '&.Mui-checked': {
      transform: 'translateX(14px)',
      color: theme.palette.common.white,
      '&.Mui-disabled': {
        color: theme.palette.common.white,
        opacity: 1,
      },
      '&.MuiSwitch-colorSecondary': {
        '& + .MuiSwitch-track': {
          borderColor: theme.palette.secondary.main,
        },
      },
      '&.MuiSwitccolorSecondary.Mui-focusVisible': {
        '.MuiSwitch-thumb': {
          border: `4px solid ${theme.palette.common.white}`,
          backgroundColor: theme.palette.secondary.main,
        },
      },
      '&.MuiSwitch-colorPrimary': {
        '& + .MuiSwitch-track': {
          borderColor: theme.palette.primary.main,
        },
      },
      '&.MuiSwitch-colorPrimary.Mui-focusVisible': {
        '.MuiSwitch-thumb': {
          border: `4px solid ${theme.palette.common.white}`,
          backgroundColor: theme.palette.primary.main,
        },
      },
      '&.MuiSwitch-colorWarning': {
        '& + .MuiSwitch-track': {
          borderColor: theme.palette.warning.main,
        },
      },
      '&.MuiSwitch-colorInfo': {
        '& + .MuiSwitch-track': {
          borderColor: theme.palette.info.main,
        },
      },
      '&.MuiSwitch-colorError': {
        '& + .MuiSwitch-track': {
          borderColor: theme.palette.error.main,
        },
      },
      '&.MuiSwitch-colorDefault': {
        color: theme.palette.grey['400'],
        '&.Mui-disabled': {
          color: theme.palette.grey['400'],
          opacity: 0.4,
        },
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
      },
    },
    '&.Mui-disabled': {
      color: theme.palette.grey['400'],
      opacity: 0.4,
      '& + .MuiSwitch-track': {
        opacity: 0.4,
      },
    },
    '&.MuiSwitccolorSecondary.Mui-focusVisible': {
      '.MuiSwitch-thumb': {
        border: `4px solid ${theme.palette.secondary.main}`,
      },
    },
    '&.MuiSwitch-colorPrimary.Mui-focusVisible': {
      '.MuiSwitch-thumb': {
        border: `4px solid ${theme.palette.primary.main}`,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
  },
  '& .MuiSwitch-track': {
    border: `1px solid ${theme.palette.grey['400']}`,
    borderRadius: 36,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : theme.palette.common.white,
    boxSizing: 'border-box',
    transition: theme.transitions.create(['borderColor', 'opacity', 'backgroundColor'], {
      duration: 150,
    }),
  },
}));

Switch.defaultProps = {
  focusVisibleClassName: 'Mui-focusVisible',
};
