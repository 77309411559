import i18n from 'i18n';
import { getDisplayNameLocaleKey } from 'features/permissions/utils/getDisplayNameLocaleKey';
import { RoleEntries } from 'features/permissions/types';

// Translates display names explicitly to maintain simple access
// without the need to use dynamic keys to resolve them on the UI
export const localizeRoles = (roleEntries: RoleEntries) => {
  const results: RoleEntries = {};
  for (const role of Object.values(roleEntries)) {
    const { displayNameKey, name } = role;
    results[name] = { ...role };
    if (displayNameKey) {
      const localizationKey = getDisplayNameLocaleKey(displayNameKey);
      results[name].displayName = i18n.t(localizationKey);
    }
  }
  return results;
};
