import React from 'react';
import { BreakoutsUsersVirtualListItemType } from 'features/breakout-rooms/types';
import { Checkbox, ListItemIcon } from '@mui/material';
import { useAppSelector } from 'store/hooks';
import { selectRoleDisplayName } from 'features/permissions/permissionsSlice';
import { BreakoutsUsersListContext } from 'features/breakout-rooms/components/breakouts-users-selector/BreakoutsUsersList';
import { styled } from '@mui/material/styles';
import AvatarNameEntity from 'components/AvatarNameEntity';

export type VirtualListItemContentProps = {
  item: BreakoutsUsersVirtualListItemType;
  context: BreakoutsUsersListContext;
};

const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: 'auto',
});

const BreakoutsUsersListItemContent = React.memo(
  ({ item, context }: VirtualListItemContentProps) => {
    const { user, local } = item;

    const roleDisplayName = useAppSelector((state) => selectRoleDisplayName(state, user?.role));

    if (!user) {
      return null;
    }

    const labelId = `breakout-user-checkbox-${user.id}`;

    const { toggleUser, selectedIds } = context;

    return (
      <>
        <AvatarNameEntity
          id={labelId}
          src={user.avatarUrl}
          color={user.avatarColor}
          name={user.name!}
          initials={user.initials}
          secondary={roleDisplayName}
          local={local}
        />
        <StyledListItemIcon>
          <Checkbox
            edge="end"
            checked={selectedIds.indexOf(user.id) !== -1}
            tabIndex={-1}
            onChange={() => toggleUser(user.id)}
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </StyledListItemIcon>
      </>
    );
  }
);

export default BreakoutsUsersListItemContent;
