import { call } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { renameRoomRequested } from 'features/breakout-rooms/actions';

export function* renameBreakoutRoomSaga(action: ReturnType<typeof renameRoomRequested>) {
  yield call(SignalingSocket.send, {
    event: 'renameBreakoutRoom',
    data: {
      id: action.payload.id,
      name: action.payload.name,
    },
  });
}
