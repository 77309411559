import { useLayoutEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { placementChanged, selectToolbarSystemPlacement } from 'features/toolbar/toolbarSlice';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { selectDeviceType } from 'features/application/applicationSlice';

export const useToolbarOrientation = () => {
  const dispatch = useAppDispatch();

  const { isPortrait } = useBreakpoints();

  const toolbarSystemPlacement = useAppSelector(selectToolbarSystemPlacement);
  const deviceType = useAppSelector(selectDeviceType);

  const isMobile = deviceType === 'mobile';

  useLayoutEffect(() => {
    if (!isMobile) {
      return;
    }

    if (isPortrait) {
      dispatch(placementChanged('bottom'));
    } else if (toolbarSystemPlacement === 'right') {
      dispatch(placementChanged('right'));
    } else {
      dispatch(placementChanged('left'));
    }
  }, [dispatch, isPortrait, isMobile, toolbarSystemPlacement]);
};
