import { call } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { logger } from 'utils/logger';

export function* bulkMuteRemoteAudioSaga() {
  logger.remote({ action: true }).log('Bulk mute remote audio requested');

  yield call(SignalingSocket.send, {
    event: 'bulkMuteRemoteAudio',
  });
}
