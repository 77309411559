import { useToolbarPanel } from 'hooks/useToolbarPanel';
import { useTranslation } from 'react-i18next';
import { useContentLibrary } from 'features/content-library/useContentLibrary';

export const useContentLibraryControl = () => {
  const { t } = useTranslation('room');

  const { toolbarPanel, togglePanel } = useToolbarPanel();

  const { isFeatureAvailable } = useContentLibrary();

  const active = toolbarPanel === 'content-library';

  const toggleContentLibraryPanel = () => {
    togglePanel('content-library');
  };

  return {
    active,
    label: t('toolbar.content_library'),
    toggleContentLibrary: toggleContentLibraryPanel,
    isFeatureAvailable,
    icon: 'content-folder-1',
  };
};
