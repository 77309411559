import { PaletteMode } from '@mui/material';
import { PaletteOptions } from '@mui/material/styles';

export const basePalette: PaletteOptions = {
  grey: {
    400: '#c5c5c5',
    600: '#797979',
    700: '#545557',
  },
  grayscale: {
    main: '#797979',
  },
};

export const lightThemePalette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#3771e0',
  },
  error: {
    main: '#f00',
  },
  divider: '#c5c5c5',
  action: {
    selectedOpacity: 0.1,
  },
};

export const darkThemePalette: PaletteOptions = {
  mode: 'dark',
  primary: {
    main: '#739CE9',
  },
  error: {
    main: '#f00',
  },
  grayscale: {
    main: 'rgba(255, 255, 255, 0.7)',
  },
  divider: 'rgba(255,255,255, 0.2)',
  background: {
    paper: '#272A2E',
    default: '#000000',
  },
  action: {
    hover: 'rgba(255,255,255, 0.2)',
    hoverOpacity: 0.2,
    selected: 'rgba(255,255,255, 0.2)',
    selectedOpacity: 0.2,
    disabledBackground: 'rgba(255,255,255, 0.2)',
  },
};

export const getThemePalette = (mode?: PaletteMode) => {
  const palette = mode === 'dark' ? darkThemePalette : lightThemePalette;
  return {
    ...basePalette,
    ...palette,
  };
};
