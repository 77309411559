export interface CustomThemeOptions {
  room: {
    background: string;
    tileBackground: string;
    lightBackground: string;
    tileGap: number;
  };
  input: {
    lineHeight: number;
  };
  toolbar: {
    width: string;
    background: string;
    badge: {
      active: {
        background: string;
      };
    };
  };
  toolbarPanel: {
    width: string;
  };
  topBar: {
    height: string;
  };
  layout: {
    containerPadding: string;
    innerPadding: string;
  };
}

export const customThemeOptions: CustomThemeOptions = {
  room: {
    background: '#000',
    tileBackground: '#272a2e',
    lightBackground: '#383c42',
    tileGap: 4,
  },
  input: {
    lineHeight: 1.375,
  },
  topBar: {
    height: 'var(--topbar-height)',
  },
  toolbarPanel: {
    width: 'var(--toolbar-panel-width)',
  },
  toolbar: {
    width: 'var(--toolbar-width)',
    background: '#000',
    badge: {
      active: {
        background: '#f55',
      },
    },
  },
  layout: {
    containerPadding: 'var(--room-container-padding)',
    innerPadding: 'var(--room-layout-padding)',
  },
};
