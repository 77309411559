import { IconButton } from '@mui/material';
import {
  layoutModeChanged,
  selectCurrentLayoutMode,
} from 'features/layout/features/config/configSlice';
import { LayoutMode } from 'features/layout/types';
import { TopbarControl } from 'features/room/components/topbar/TopbarControl';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';

const LayoutModeControl = () => {
  const dispatch = useAppDispatch();
  const currentMode = useAppSelector(selectCurrentLayoutMode);
  const { t } = useTranslation('room');

  const handleToggle = () => {
    if (currentMode === LayoutMode.auto) {
      dispatch(layoutModeChanged(LayoutMode.tiled));
    } else {
      dispatch(layoutModeChanged(LayoutMode.auto));
    }
  };

  const renderTooltipText = () =>
    currentMode === LayoutMode.auto
      ? t('topbar.switch_to_custom_mode')
      : t('topbar.switch_to_auto_mode');

  const { isMobile } = useBreakpoints();

  const renderIcon = () => (
    <Icon name={currentMode === LayoutMode.auto ? 'active-speaker-mode' : 'tiled-mode'} />
  );

  const renderButtonText = () => {
    if (isMobile) {
      return renderIcon();
    }

    return currentMode === LayoutMode.auto ? t('topbar.auto_mode') : t('topbar.custom_mode');
  };

  const renderControl = () =>
    isMobile ? (
      <TopbarControl
        data-testid="layout-mode-control"
        as={IconButton}
        onClick={handleToggle}
        sx={{ fontSize: 24 }}
      >
        {renderIcon()}
      </TopbarControl>
    ) : (
      <TopbarControl
        data-testid="layout-mode-control"
        onClick={handleToggle}
        startIcon={renderIcon()}
        sx={{
          py: 1,
          px: 4,
          borderRadius: '20px',
          fontSize: 14,
        }}
      >
        {renderButtonText()}
      </TopbarControl>
    );

  return (
    <Tooltip title={renderTooltipText()} placement="bottom">
      {renderControl()}
    </Tooltip>
  );
};

export default LayoutModeControl;
