import BreakoutsSplashScreen from 'features/breakout-rooms/components/splash-screen/BreakoutsSplashScreen';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import {
  selectBreakoutSplashScreenName,
  selectBreakoutTargetRoom,
  selectShowBreakoutsSplash,
} from 'features/breakout-rooms/breakoutRoomsSlice';
import { useMemo } from 'react';
import { BreakoutRoomsSplashScreenName } from 'features/breakout-rooms/types';

const BreakoutsSplashScreenManager = () => {
  const { t } = useTranslation('waiting-screens');

  const screenName = useAppSelector(selectBreakoutSplashScreenName);
  const showSplash = useAppSelector(selectShowBreakoutsSplash);

  const targetRoom = useAppSelector(selectBreakoutTargetRoom);

  const stringsMap: Partial<
    Record<
      BreakoutRoomsSplashScreenName,
      {
        title: string;
        description: string;
      } | null
    >
  > = useMemo(
    () => ({
      started: {
        title: t('breakout_rooms.breakout_started.title'),
        description: t('breakout_rooms.breakout_started.description', {
          roomName: targetRoom?.name,
        }),
      },
      ended: {
        title: t('breakout_rooms.breakout_ended.title'),
        description: t('breakout_rooms.breakout_ended.description'),
      },
      joining: targetRoom
        ? {
            title: t('breakout_rooms.joining_room.title'),
            description: t('breakout_rooms.joining_room.description', {
              roomName: targetRoom.name,
            }),
          }
        : null,
      leaving: {
        title: t('breakout_rooms.leaving_room.title'),
        description: t('breakout_rooms.leaving_room.description'),
      },
    }),
    [t, targetRoom]
  );

  const strings = stringsMap[screenName];

  if (!strings || !showSplash) {
    return null;
  }

  return <BreakoutsSplashScreen title={strings.title}>{strings.description}</BreakoutsSplashScreen>;
};

export default BreakoutsSplashScreenManager;
