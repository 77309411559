import { call } from 'redux-saga/effects';
import { VideoroomConnectionState } from 'utils/webrtc/ControlledReceivingHandle';
import { RTCClient } from 'utils/webrtc';

export function* onSubscriberAttachedSaga() {
  const feed = RTCClient.receivingFeed.receiver?.plugin;

  if (feed) {
    yield call(feed.setConnectionState, VideoroomConnectionState.connected);
  }
}
