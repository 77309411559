import {
  ActiveMediaDevices,
  EnumeratedMediaDevices,
  UnwrappedMediaDeviceInfo,
} from 'features/user-media/types';
import { logger } from 'utils/logger';
import differencewith from 'lodash.differencewith';
import isEqual from 'lodash.isequal';

export type AutoChangedDevices = Partial<Record<MediaDeviceKind, UnwrappedMediaDeviceInfo>>;
export type DeviceDifferences = Record<'addedDevices' | 'removedActiveDevices', AutoChangedDevices>;

/**
 * Finds changed devices
 *
 * Note: the output may or may not contain audiooutput and list of default/communication devices depending on a browser/OS
 */
export const findDeviceDifferences = (
  updated: EnumeratedMediaDevices,
  current: EnumeratedMediaDevices,
  activeMediaDevices: ActiveMediaDevices
): DeviceDifferences => {
  const updatedDevices = Object.values(updated).flat();
  const currentDevices = Object.values(current).flat();

  logger.log('currentDevices', current);
  logger.log('updatedDevices', updated);
  logger.log('activeMediaDevices', activeMediaDevices);

  // find removed devices among existing ones
  const removedDiff: UnwrappedMediaDeviceInfo[] = differencewith(
    currentDevices,
    updatedDevices,
    isEqual
  );

  const removedActiveDevices: AutoChangedDevices = {};
  for (const device of removedDiff) {
    if (device.deviceId === activeMediaDevices[device.kind]) {
      removedActiveDevices[device.kind] = device;
    }
  }

  // find added devices among updated ones
  const addedDiff: UnwrappedMediaDeviceInfo[] = differencewith(
    updatedDevices,
    currentDevices,
    isEqual
  );

  const addedDevices: AutoChangedDevices = {};
  for (const device of addedDiff) {
    addedDevices[device.kind] = device;
  }

  logger.log('addedDevices', addedDevices);
  logger.log('removedActiveDevices', removedActiveDevices);

  return {
    addedDevices,
    removedActiveDevices,
  };
};
