import { useAppSelector } from 'store/hooks';
import { selectRecorderLogoVisibility } from 'features/recorder/recorderSlice';
import { useRecorder } from 'features/recorder/useRecorder';
import RecorderTopBar from 'features/room/components/topbar/RecorderTopBar';
import RoomTopBar from 'features/room/components/topbar/RoomTopBar';
import { RecorderGuard } from 'features/recorder/RecorderGuard';
import { selectTopBarVisibility } from '../../roomSlice';

const TopBar = () => {
  const isRecorder = useRecorder();
  const recordingLogoVisible = useAppSelector(selectRecorderLogoVisibility);
  const topbarVisible = useAppSelector(selectTopBarVisibility);

  if (isRecorder && recordingLogoVisible) {
    return <RecorderTopBar />;
  }

  if (!topbarVisible) {
    return null;
  }

  return (
    <RecorderGuard>
      <RoomTopBar />
    </RecorderGuard>
  );
};

export default TopBar;
