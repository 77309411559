import { useTranslation } from 'react-i18next';
import ToolbarMenuItem from 'features/toolbar/ToolbarMenuItem/ToolbarMenuItem';
import { ToolbarMenuItemWrapperProps } from 'features/toolbar/types';
import React from 'react';
import { useQAControl } from 'features/toolbar/Toolbar/controls/qa/useQAControl';

const QAControl = ({ onClick }: ToolbarMenuItemWrapperProps) => {
  const { t } = useTranslation('room');

  const { togglePanel, icon, unreadCount, active, isFeatureAvailable } = useQAControl();

  if (!isFeatureAvailable) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(event);
    togglePanel();
  };

  return (
    <ToolbarMenuItem
      id="qa"
      overlay={t('toolbar.qa')}
      ariaLabel={t('toolbar.qa')}
      icon={icon}
      badgeContent={unreadCount}
      badgeActive
      active={active}
      onClick={handleClick}
    />
  );
};

export default QAControl;
