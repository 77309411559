import { UnwrappedMediaDeviceInfo } from 'features/user-media/types';

// Bypass device auto-selection
// find a related device in the same group omitting system-specific devices
export const getRelatedDevice = (
  devices: UnwrappedMediaDeviceInfo[],
  targetDevice: UnwrappedMediaDeviceInfo
): UnwrappedMediaDeviceInfo => {
  if (targetDevice.deviceId === 'default' || targetDevice.deviceId === 'communications') {
    const relatedDevice = devices.find(
      ({ deviceId, groupId }) =>
        deviceId !== 'default' && deviceId !== 'communications' && groupId === targetDevice.groupId
    );

    return relatedDevice || targetDevice;
  }

  return targetDevice;
};
