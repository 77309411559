import { FeedId, PublisherMid, SubscriberMid } from 'features/streaming/types';
import { RTCClient } from 'utils/webrtc/index';
import {
  ReceivingFeedStreamSendingState,
  StreamKind,
  StreamSubscriptionState,
} from 'utils/webrtc/types';

export class StreamMedia {
  kind: StreamKind = 'audio';

  mediaStream: MediaStream = new MediaStream();

  feedId: FeedId;

  simulcast: {
    substream?: number;
    temporal?: number;
  } = {};

  publisherMid: PublisherMid;

  private _subscriberMid?: SubscriberMid;

  hasTrack: boolean = false;

  receivingState: ReceivingFeedStreamSendingState = ReceivingFeedStreamSendingState.on;

  subscriptionState: StreamSubscriptionState = StreamSubscriptionState.created;

  originalTrackId: string = '';

  constructor(feedId: FeedId, publisherMid: PublisherMid) {
    this.feedId = feedId;
    this.publisherMid = publisherMid;
  }

  setSubscriptionState(state: StreamSubscriptionState) {
    this.subscriptionState = state;
  }

  get canSubscribe() {
    if (RTCClient.receiveMode === 'streaming') {
      return true;
    }

    // we're not receiving, subscribe to the media regardless of state.
    // TODO: we'll need another fix for this
    // if (this.receivingState !== ReceivingFeedStreamSendingState.on) {
    //   return true;
    // }

    return [StreamSubscriptionState.created, StreamSubscriptionState.disconnected].includes(
      this.subscriptionState
    );
  }

  get subscribed() {
    return this.subscriptionState === StreamSubscriptionState.connected;
  }

  get canConfigure() {
    return this.receivingState === ReceivingFeedStreamSendingState.off;
  }

  setReceivingState = (state: ReceivingFeedStreamSendingState) => {
    this.receivingState = state;
  };

  setKind = (kind: StreamKind) => {
    this.kind = kind;
  };

  setSubscriberMid = (mid: SubscriberMid) => {
    this._subscriberMid = mid;
  };

  get subscriberMid() {
    return this._subscriberMid === undefined ? false : this._subscriberMid;
  }

  setSimulcastValues = (substream?: number, temporal?: number) => {
    this.simulcast.substream = substream;
    this.simulcast.temporal = temporal;
  };

  addTrack = (track: MediaStreamTrack) => {
    this.originalTrackId = track.id;

    // make sure we don't use or modify the original track
    // this lets Janus run all necessary events and cleanups;
    this.mediaStream.addTrack(track.clone());

    this.hasTrack = true;
  };

  getTrack = () => {
    if (this.kind === 'audio') {
      return this.mediaStream.getAudioTracks()[0];
    }

    return this.mediaStream.getVideoTracks()[0];
  };

  removeTrack = (track: MediaStreamTrack) => {
    this.mediaStream.removeTrack(track);
    this.hasTrack = !!this.mediaStream.getTracks().length;
  };
}
