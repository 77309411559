import { createAction } from '@reduxjs/toolkit';
import { CaptionsSpeechChunk } from 'features/transcripts/slices/captions/types';

export const speechCaptioned = createAction<CaptionsSpeechChunk>('captions/speechCaptioned');

export const startTranscriptionRequested = createAction('room/startTranscriptionRequested');
export const stopTranscriptionRequested = createAction('room/stopTranscriptionRequested');

export const signalingTranscriptionStarted = createAction('signaling/transcriptionStarted');
export const signalingTranscriptionStopped = createAction('signaling/transcriptionStopped');
