import { useMemo } from 'react';
import { RTCClient } from 'utils/webrtc';

export const useReceivingFeed = () => {
  const control = useMemo(() => RTCClient.receivingFeed, []);

  return {
    control,
  };
};
