import { JoinMediaDefaults } from 'features/user-media/types';
import { joinDefaultsChanged } from 'features/user-media/userMediaSlice';
import { put } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';
import { logger } from 'utils/logger';

export function* setupMediaDefaultsSaga(userDefaults: JoinMediaDefaults) {
  logger.remote().info(`Video ${userDefaults.video ? 'enabled' : 'disabled'} by default`);
  logger.remote().info(`Audio ${userDefaults.audio ? 'enabled' : 'disabled'} by default`);

  const modifiedDefaults = { ...userDefaults };

  // support setting default media state from sdk;
  // by the time we get here it should be set properly,
  // so we just determine the final default media state
  if (eventBus.initState.audioEnabled !== undefined) {
    modifiedDefaults.audio = eventBus.initState.audioEnabled;
  }

  if (eventBus.initState.videoEnabled !== undefined) {
    modifiedDefaults.video = eventBus.initState.videoEnabled;
  }

  if (
    modifiedDefaults.video !== userDefaults.video ||
    modifiedDefaults.audio !== userDefaults.audio
  ) {
    yield put(joinDefaultsChanged(modifiedDefaults));
  }
}
