import { selectRoomStatus } from 'features/room/roomSlice';
import {
  pendingConfigApplied,
  virtualBackgroundReset,
} from 'features/virtual-backgrounds/virtualBackgroundsSlice';
import { put, select } from 'redux-saga/effects';

export function* onDisableVBCommandSaga() {
  const roomStatus: string = yield select(selectRoomStatus);
  const roomJoined = roomStatus === 'joined';

  if (roomJoined) {
    yield put(virtualBackgroundReset());
  } else {
    yield put(pendingConfigApplied(Number(new Date())));
  }
}
