import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

const UnavailableMicPopper = () => {
  const { t } = useTranslation('join');

  return <Typography>{t('publisher.microphone_unavailable')}</Typography>;
};

export default UnavailableMicPopper;
