import { Routes, Route } from 'react-router-dom';
import { IndexPage, NotMatch } from 'router';
import { RoomPage } from 'features/room';
import { AppLayout } from 'layouts';

const Router = () => (
  <Routes>
    <Route path="/" element={<AppLayout />}>
      <Route index element={<IndexPage />} />
      <Route path=":roomId" element={<RoomPage />} />
      <Route path="*" element={<NotMatch />} />
    </Route>
  </Routes>
);

export default Router;
