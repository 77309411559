import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal, selectActiveModalData } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { useQA } from 'features/qa/useQA';
import { selectAnswerById } from 'features/qa/qaSlice';
import { deleteAnswerRequested } from 'features/qa/actions';
import { Answer } from 'features/qa/types';

type SharedProps = {
  answer: Answer;
  onOk: () => void;
  onCancel: () => void;
};

const ModeratorDialog = ({ answer, ...props }: SharedProps) => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const { isFeatureAvailable, canModerate } = useQA();

  const modalShouldBeClosed = !isFeatureAvailable || !canModerate;

  useEffect(() => {
    if (modalShouldBeClosed) {
      dispatch(closeModal('deleteAnswer'));
    }
  }, [modalShouldBeClosed, dispatch]);

  return (
    <ConfirmationDialog
      open={!modalShouldBeClosed}
      title={t('qa.delete_answer_moderator.title')}
      description={t('qa.delete_answer_moderator.description', { username: answer.user.name })}
      cancelText={t('qa.delete_answer_moderator.btn_cancel_label')}
      okText={t('qa.delete_answer_moderator.btn_ok_label')}
      hideCloseButton
      preventClose
      {...props}
    />
  );
};

const OwnAnswerDialog = (props: SharedProps) => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const { isFeatureAvailable } = useQA();

  const modalShouldBeClosed = !isFeatureAvailable;

  useEffect(() => {
    if (modalShouldBeClosed) {
      dispatch(closeModal('deleteAnswer'));
    }
  }, [modalShouldBeClosed, dispatch]);

  return (
    <ConfirmationDialog
      {...props}
      open={!modalShouldBeClosed}
      title={t('qa.delete_your_answer.title')}
      description={t('qa.delete_your_answer.description')}
      cancelText={t('qa.delete_your_answer.btn_cancel_label')}
      okText={t('qa.delete_your_answer.btn_ok_label')}
      hideCloseButton
      preventClose
    />
  );
};

const DeleteAnswerConfirmation = () => {
  const dispatch = useAppDispatch();

  const modalData = useAppSelector(selectActiveModalData);
  const answerId = modalData.answerId as string;
  const local = modalData.local as boolean;

  const answer = useAppSelector((state) => selectAnswerById(state, answerId));

  useEffect(() => {
    if (!answer) {
      dispatch(closeModal('deleteAnswer'));
    }
  }, [dispatch, answer]);

  const handleCancel = () => {
    dispatch(closeModal('deleteAnswer'));
  };

  const handleOk = () => {
    if (answer) {
      dispatch(
        deleteAnswerRequested({
          id: answer.id,
          questionId: answer.questionId,
        })
      );
    }

    dispatch(closeModal('deleteAnswer'));
  };

  if (!answer) {
    return null;
  }

  const Component = local ? OwnAnswerDialog : ModeratorDialog;

  return <Component answer={answer} onOk={handleOk} onCancel={handleCancel} />;
};

export default DeleteAnswerConfirmation;
