import { ShapeList } from 'utils/whiteboard/BoardStateManager';

export const parseBoardState = (shapes: string[]): ShapeList => {
  const obj: ShapeList = {};
  for (const shape of shapes) {
    const shapeState = JSON.parse(shape);
    if (shapeState.typeName === 'shape' || shapeState.typeName === 'asset') {
      obj[shapeState.id] = shapeState;
    }
  }
  return obj;
};
