import { call, put } from 'redux-saga/effects';
import { signalingFeatureEnabled } from 'features/feature-set/actions';
import { Action } from '@reduxjs/toolkit';
import i18n from 'i18n';
import { displaySimpleNotification } from 'features/notifications/simple-notifications/displaySimpleNotification';

export function* onFeatureEnabledSaga(action: ReturnType<typeof signalingFeatureEnabled>) {
  // show a notification
  const localizedFeatureName = i18n.t(`settings:components.${action.payload.feature}.label`);
  const notificationThunk: Action = yield call(displaySimpleNotification, {
    content: i18n.t('notifications:components.component_enabled', {
      component: localizedFeatureName,
    }),
  });
  yield put(notificationThunk);
}
