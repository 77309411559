import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import NoStylesButton from 'components/NoStylesButton';

const Wrapper = React.forwardRef<HTMLButtonElement, React.ComponentProps<'button'>>(
  (props, ref) => <NoStylesButton type="button" {...props} ref={ref} />
);

export const TooltipIconTrigger = styled(Wrapper)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: '32px',
  lineHeight: 1,
  cursor: 'pointer',
  '&:focus-visible': {
    boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.35)}`,
  },
}));
