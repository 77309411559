import { LocalVotedPolls, Poll, PollEntities, PollIds } from 'features/polls/types';

export const normalizePolls = (
  polls: Poll[]
): {
  entities: PollEntities;
  ids: PollIds;
  activePollId: string | null;
  activePollSessionId: string | null;
  localVotedPolls: LocalVotedPolls | null;
} => {
  const entities: PollEntities = {};
  const ids: PollIds = [];
  let activePollId: string | null = null;
  let activePollSessionId: string | null = null;
  let localVotedPolls: LocalVotedPolls | null = null;

  for (const poll of polls) {
    entities[poll.id] = poll;
    ids.push(poll.id);

    if (poll.active) {
      activePollId = poll.id;
      activePollSessionId = poll.sessionId;
    }

    // preserve choices when using an externalId property in the token
    if (poll.voted) {
      localVotedPolls ??= {};
      localVotedPolls[poll.id] = Object.fromEntries(poll.voted.map((index) => [index, true]));
    }
  }

  return {
    entities,
    ids,
    activePollId,
    activePollSessionId,
    localVotedPolls,
  };
};
