import * as Sentry from '@sentry/react';
import {
  SignalingReceivedEventType,
  SignalingReceivedMessage,
  SignalingSocket,
} from 'services/signaling';
import { singleton } from 'utils/singleton';

export type CustomConsole = Record<LogLevel, (...args: any[]) => any>;

type LogLevel = 'trace' | 'debug' | 'log' | 'info' | 'warn' | 'error';

// 0 - essential, default. 1 and so on is less important
type LogTier = 0 | 1;

const TIER_THRESHOLD = 100;

type LoggerMessage = (...args: any[]) => void;
export type LoggerMethods = Record<LogLevel, LoggerMessage>;

type LogOptions = Partial<{
  action: boolean;
  system: boolean;
  tier: LogTier;
  capture?: string;
}>;

interface RemoteLog {
  message: string;
  payload?: string;
  level: LogLevel;
  timestamp: number;
  action: boolean;
  system: boolean;
}

const noopMessage: LoggerMessage = () => {};

const noopConsole: CustomConsole = {
  trace: noopMessage,
  debug: noopMessage,
  log: noopMessage,
  info: noopMessage,
  warn: noopMessage,
  error: noopMessage,
};

export class Logger implements LoggerMethods {
  private logLevels: LogLevel[] = ['trace', 'debug', 'log', 'info', 'warn', 'error'];

  tier: LogTier = 0;

  console: CustomConsole = { ...noopConsole };

  debug = noopConsole.debug;

  trace = noopConsole.trace;

  log = noopConsole.log;

  info = noopConsole.info;

  warn = noopConsole.warn;

  error = noopConsole.error;

  private tail: RemoteLog[] = [];

  history: RemoteLog[] = [];

  timeout: number = 0;

  constructor() {
    if (window?.console && process.env.REACT_APP_DEBUG === 'true') {
      this.console = window.console;
    }

    for (const level of this.logLevels) {
      this[level] = (...args: any[]) => this.logMessage(false, level, {}, ...args);
    }

    this.postMessages();
  }

  remote = (options: LogOptions = {}) => {
    const loggerMethods = {} as LoggerMethods;

    for (const level of this.logLevels) {
      loggerMethods[level] = (...args: any[]) => this.logMessage(true, level, options, ...args);
    }
    return loggerMethods;
  };

  private logMessage = (remote: boolean, level: LogLevel, options: LogOptions, ...args: any[]) => {
    const ts = this.timestamp;
    const prefix = `${ts} [${level.toLocaleUpperCase()}]:`;
    this.console[level](prefix, ...args);

    const tier = options.tier || 0;

    if (remote && tier <= this.tier) {
      this.addRemoteMessage(level, options, ts, ...args);
    }
  };

  private addRemoteMessage = (
    level: LogLevel,
    options: LogOptions,
    timestamp: number,
    ...args: any[]
  ) => {
    const [message, ...rest] = args;
    const payload = rest.length ? JSON.stringify(rest) : undefined;

    const log = {
      message: typeof message === 'string' ? message : String(message),
      payload,
      level,
      action: !!options.action,
      system: !!options.system,
      timestamp,
    };

    this.tail.push(log);
    this.history.push(log);

    if (options?.capture) {
      Sentry.addBreadcrumb({
        category: options.capture,
        message,
        data: rest,
      });
    }
  };

  private get timestamp() {
    return Number(new Date());
  }

  private postMessages = () => {
    this.timeout = window.setTimeout(() => {
      if (this.tail.length) {
        while (this.tail.length > 0) {
          const messages = this.tail.splice(0, 50);
          SignalingSocket.send({
            event: 'log',
            data: messages,
          });
        }
      }

      this.postMessages();
    }, 5000);
  };

  updateTier = (users: number) => {
    const oldTier = this.tier;

    this.tier = users <= TIER_THRESHOLD ? 1 : 0;

    if (oldTier !== this.tier) {
      this.remote({ system: true }).info(`Logger tier changed from ${oldTier} to ${this.tier}`);
    }
  };
}

export const logger = singleton<Logger>(() => new Logger());

const allowLoggedEvents: Partial<Record<SignalingReceivedEventType, boolean>> = {
  streamStarted: true,
  streamStopped: true,
  screenshareStarted: true,
  screenshareStopped: true,
  roomJoined: true,
  userJoined: true,
  userLeft: true,
  audioMutedRemotely: true,
  askedToUnmuteAudioRemotely: true,
  videoMutedRemotely: true,
  askedToUnmuteVideoRemotely: true,
  recordingStarted: true,
  recordingRequested: true,
  recordingStopRequested: true,
  recordingFailure: true,
  recordingStopped: true,
  recorderJoined: true,
  broadcastAllowed: true,
  broadcastDisallowed: true,
  screenshareAllowed: true,
  screenshareDisallowed: true,
  roleChanged: true,
  encryptionKeyShared: true,
};

export const logSignalingEvent = (messageStr: string, message: SignalingReceivedMessage) => {
  if (allowLoggedEvents[message.event]) {
    const payload = { ...message.data };

    if (message.event === 'roomJoined') {
      delete payload.iceServers;
      delete payload.token;

      if (logger.tier === 0) {
        payload.users = payload.users?.length;
      }
    }
    if (message.event === 'fileUploadPrepared') {
      delete payload.token;
    }

    if (message.event === 'whiteboardOpened') {
      payload.shapes = payload.shapes?.length;
    }

    logger.remote().log(messageStr, { ...message, data: payload });
  }
};

window.logger = logger;
