import React from 'react';
import { useReceivingFeed } from 'hooks/useReceivingFeed';
import { sendMessage, updateSimulcastTemplate } from 'utils/webrtc/messages';
import { useAppSelector } from 'store/hooks';
import { selectRemoteStreamByMid } from 'features/streaming/streamingSlice';

export const useSimulcast = (mid: string) => {
  const videoStream = useAppSelector((state) => selectRemoteStreamByMid(state, mid));

  const { control } = useReceivingFeed();

  const changeSubstream = (event: React.MouseEvent<HTMLElement>, value: number) => {
    const feed = control.receiver?.plugin;
    if (feed) {
      sendMessage(feed.janusPlugin, updateSimulcastTemplate([{ mid, substream: value }]));
    }
  };

  const changeTemporal = (event: React.MouseEvent<HTMLElement>, value: number) => {
    const feed = control.receiver?.plugin;
    if (feed) {
      sendMessage(feed.janusPlugin, updateSimulcastTemplate([{ mid, temporal: value }]));
    }
  };

  return {
    changeSubstream,
    changeTemporal,
    substream: videoStream?.substream,
    temporal: videoStream?.temporal,
  };
};
