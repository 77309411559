import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal, selectActiveModalData } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { useQA } from 'features/qa/useQA';
import { selectQuestionById } from 'features/qa/qaSlice';
import { deleteQuestionRequested } from 'features/qa/actions';
import { Question } from 'features/qa/types';

type SharedProps = {
  question: Question;
  onOk: () => void;
  onCancel: () => void;
};

const ModeratorDialog = ({ question, ...props }: SharedProps) => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const { isFeatureAvailable, canModerate } = useQA();

  const modalShouldBeClosed = !isFeatureAvailable || !canModerate;

  useEffect(() => {
    if (modalShouldBeClosed) {
      dispatch(closeModal('deleteQuestion'));
    }
  }, [modalShouldBeClosed, dispatch]);

  return (
    <ConfirmationDialog
      open={!modalShouldBeClosed}
      title={t('qa.delete_question_moderator.title')}
      description={t('qa.delete_question_moderator.description', { username: question.user.name })}
      cancelText={t('qa.delete_question_moderator.btn_cancel_label')}
      okText={t('qa.delete_question_moderator.btn_ok_label')}
      hideCloseButton
      preventClose
      {...props}
    />
  );
};

const OwnQuestionDialog = ({ question, ...props }: SharedProps) => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const { isFeatureAvailable, canModerate } = useQA();

  const modalShouldBeClosed = !isFeatureAvailable || (!canModerate && question?.answers.length > 0);

  useEffect(() => {
    if (modalShouldBeClosed) {
      dispatch(closeModal('deleteQuestion'));
    }
  }, [modalShouldBeClosed, dispatch]);

  return (
    <ConfirmationDialog
      {...props}
      open={!modalShouldBeClosed}
      title={t('qa.delete_your_question.title')}
      description={t('qa.delete_your_question.description')}
      cancelText={t('qa.delete_your_question.btn_cancel_label')}
      okText={t('qa.delete_your_question.btn_ok_label')}
      hideCloseButton
      preventClose
    />
  );
};

const DeleteQuestionConfirmation = () => {
  const dispatch = useAppDispatch();

  const modalData = useAppSelector(selectActiveModalData);
  const questionId = modalData.questionId as string;
  const local = modalData.local as boolean;

  const question = useAppSelector((state) => selectQuestionById(state, questionId));

  useEffect(() => {
    if (!question) {
      dispatch(closeModal('deleteQuestion'));
    }
  }, [dispatch, question]);

  const handleCancel = () => {
    dispatch(closeModal('deleteQuestion'));
  };

  const handleOk = () => {
    dispatch(deleteQuestionRequested(questionId));
    dispatch(closeModal('deleteQuestion'));
  };

  if (!question) {
    return null;
  }

  const Component = local ? OwnQuestionDialog : ModeratorDialog;

  return <Component question={question} onOk={handleOk} onCancel={handleCancel} />;
};

export default DeleteQuestionConfirmation;
