import { createContext, useContext } from 'react';
import { BroadcastSetupContextValue } from 'utils/broadcast-setup/types';

const Context = createContext<BroadcastSetupContextValue | undefined>(undefined);
export const { Provider: BroadcastSetupContextProvider } = Context;

export const useBroadcastSetup = () => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('useBroadcastSetup must be used within a BroadcastSetupProvider');
  }

  return context;
};
