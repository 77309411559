import { signalingWaitingUserJoined } from 'features/users/actions';
import { call, put, select } from 'redux-saga/effects';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import * as Sentry from '@sentry/react';
import { logger } from 'utils/logger';
import { waitingEntryNotificationUpdateRequested } from 'features/notifications/actions';

export function* onWaitingUserJoinedSaga(action: ReturnType<typeof signalingWaitingUserJoined>) {
  try {
    const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
    if (e2eeEnabled) {
      yield call(
        E2EEManager.exchangeEncryptionKey,
        action.payload.id,
        action.payload.e2eePublicKey!
      );
    }

    yield put(waitingEntryNotificationUpdateRequested());
  } catch (error) {
    Sentry.captureException(error);
    logger
      .remote({ tier: 1 })
      .error(`Cannot handle waiting entry user id=${action.payload.id}`, error);
  }
}
