import { call, select } from 'redux-saga/effects';
import { selectLocalUser } from 'features/users/usersSlice';
import { PermissionTypes } from 'features/permissions/types';
import { User } from 'features/users/types';
import {
  HasDynamicPermissionsOptions,
  lookupDynamicPermission,
} from 'features/permissions/utils/lookupDynamicPermission';

export function* hasDynamicPermissionsSaga(
  permission: PermissionTypes,
  { targetUser }: HasDynamicPermissionsOptions = {}
) {
  const localUser: User = yield select(selectLocalUser);

  let { dynamicPermissions } = localUser;

  if (targetUser) {
    dynamicPermissions = targetUser.dynamicPermissions;
  }

  const granted: boolean = yield call(lookupDynamicPermission, permission, dynamicPermissions);

  return granted;
}
