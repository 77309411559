import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { RecorderState } from 'features/recorder/types';
import { signalingRoomJoined } from 'features/room/actions';
import { roomTokenParsed } from 'features/room/roomSlice';
import { authorizeUser } from 'features/room/thunks/authorizeUser';
import {
  signalingRecorderJoined,
  signalingRecordingFailed,
  signalingRecordingStopped,
} from 'features/recorder/actions';

export const initialState: RecorderState = {
  consentEnabled: false,
  isRecorderSession: false,
  status: 'stopped',
  logoEnabled: false,
};

export const recorderSlice = createSlice({
  name: 'recorder',
  initialState,
  reducers: {
    recordingRequested(state) {
      state.status = 'starting';
    },
    recordingStarted(state) {
      state.status = 'started';
    },
    stopRecordingRequest(state) {
      state.status = 'stopped';
    },
    stopRecording(state) {
      state.status = 'stopped';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signalingRoomJoined, (state, action) => {
        state.status = action.payload.recording.status;
        state.recorder = action.payload.recorder;
      })
      .addCase(signalingRecorderJoined, (state, action) => {
        state.recorder = action.payload;
      })
      .addCase(authorizeUser.fulfilled, (state, action) => {
        state.logoEnabled = action.payload.settings.recordingLogoEnabled;
        state.consentEnabled = action.payload.settings.recordingConsentMessageEnabled;
      })
      .addCase(signalingRecordingStopped, (state) => {
        state.recorder = undefined;
      })
      .addCase(signalingRecordingFailed, (state) => {
        state.recorder = undefined;
      })
      .addCase(roomTokenParsed, (state, action) => {
        if (action.payload.systemRole === 'recorder') {
          state.isRecorderSession = true;
        }
      });
  },
});

export const { recordingRequested, recordingStarted, stopRecording, stopRecordingRequest } =
  recorderSlice.actions;

export const selectIsRecorderSession = (state: RootState) => state.recorder.isRecorderSession;

export const selectRecorderStatus = (state: RootState) => state.recorder.status;

export const selectRecorderLogoVisibility = (state: RootState) => state.recorder.logoEnabled;

export const selectRecorder = (state: RootState) => state.recorder.recorder;

export const selectRecordingConsentEnabled = (state: RootState) => state.recorder.consentEnabled;

export default recorderSlice.reducer;
