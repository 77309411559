import { selectFeatureFlags } from 'features/feature-set/featuresSlice';
import { FeatureFlags } from 'features/feature-set/types';
import { selectActiveSpeakerId } from 'features/layout/features/order/orderSlice';
import { selectPermissionsMap } from 'features/permissions/selectPermissionsMap';
import { RolePermissions } from 'features/permissions/types';
import { selectSDKRoomState } from 'features/sdk/selectors';
import { UserId } from 'features/users/types';
import i18n from 'i18next';
import { call, select } from 'redux-saga/effects';
import { SignalingRoomUser } from 'services/signaling';
import { eventBus, StoredRoomState } from 'utils/eventBus';
import { AudioPool } from 'utils/webrtc/AudioPool';

export function* configureSDKStoredStateSaga(users: SignalingRoomUser[]) {
  const permissionsMap: RolePermissions = yield select(selectPermissionsMap);
  const featureFlags: FeatureFlags = yield select(selectFeatureFlags);

  const usersList: Record<string, any> = {};
  for (const user of users) {
    usersList[user.id] = {
      id: user.id,
      name: user.name,
      avatarColor: user.avatarColor,
      role: user.role,
      kind: 'remote',
    };

    if (user.externalId) {
      usersList[user.id].externalId = user.externalId;
    }
  }

  const roomState: StoredRoomState = yield select(selectSDKRoomState);

  const activeSpeaker: UserId | undefined = yield select(selectActiveSpeakerId);

  const payload = {
    users: usersList,
    activeSpeaker,
    roomState: {
      ...roomState,
      frameMuted: AudioPool.globalMute,
      appLanguage: i18n.language,
    },
    features: JSON.parse(JSON.stringify(featureFlags)),
    permissionsMap,
  };

  yield call(eventBus.sendMessage, 'roomJoined', undefined, payload);
}
