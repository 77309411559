import { useEffect, useRef } from 'react';
import FastJoinAudience from 'features/join/fast-join/FastJoinAudience';
import Intro from 'features/join/shared/Intro';
import { useFastJoinMedia } from 'features/join/fast-join/useFastJoinMedia';

const FastJoinPublisher = () => {
  const didInitRef = useRef(false);

  const { requestPermissions, state } = useFastJoinMedia();

  useEffect(() => {
    if (!didInitRef.current) {
      didInitRef.current = true;
      requestPermissions();
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  if (state.status === 'failure' || state.status === 'denied') {
    return <FastJoinAudience />;
  }

  return <Intro />;
};

export default FastJoinPublisher;
