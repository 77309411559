import { styled } from '@mui/material/styles';

const NoStylesButton = styled('button')({
  width: 'auto',
  margin: 0,
  padding: 0,
  overflow: 'visible',
  border: 0,
  background: 'transparent',
  color: 'inherit',
  font: 'inherit',
  lineHeight: 'inherit',
  appearance: 'none',
  outline: 'none',
});

export default NoStylesButton;
