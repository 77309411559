import { PermissionTypes } from 'features/permissions/types';
import { usePermissions } from 'features/permissions/usePermissions';
import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { useAppSelector } from 'store/hooks';
import { selectIsBreakoutRoom } from 'features/breakout-rooms/breakoutRoomsSlice';
import { useRecorder } from 'features/recorder/useRecorder';

export const usePolls = () => {
  const { hasPermissions } = usePermissions();
  const isFeatureEnabled = useFeatureFlag('polls');

  const isRecorder = useRecorder();

  const isBreakoutRoom = useAppSelector(selectIsBreakoutRoom);

  const canManagePolls = hasPermissions(PermissionTypes.managePolls);
  const canTakePolls = hasPermissions(PermissionTypes.takePolls);

  const isFeatureAvailable =
    !isBreakoutRoom && isFeatureEnabled && (canManagePolls || canTakePolls || isRecorder);

  return {
    isFeatureAvailable,
    canManagePolls,
    canTakePolls,
  };
};
