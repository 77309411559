import React from 'react';
import { selectMaximizedStream } from 'features/layout/features/content/contentSlice';
import ContentTile from 'features/layout/tiles/ContentTile';
import { useAppSelector } from 'store/hooks';

export const Stage = () => {
  const maximizedStream = useAppSelector(selectMaximizedStream);

  return maximizedStream ? <ContentTile source={maximizedStream} /> : null;
};
