import { Box } from '@mui/material';
import BaseLoadingIndicator from 'features/room/components/BaseLoadingIndicator';
import React from 'react';

export const WhiteboardLoader = () => (
  <Box
    sx={{
      backgroundColor: '#fff',
      display: 'flex',
      width: 'calc(100% - 8px)',
      height: 'calc(100% - 8px)',
      left: '4px',
      top: '4px',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '8px',
      position: 'absolute',
      zIndex: 50000,
    }}
  >
    <BaseLoadingIndicator />
  </Box>
);
