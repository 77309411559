import {
  AssetRecordType,
  createTLStore,
  defaultShapeUtils,
  DocumentRecordType,
  Editor,
  getHashForString,
  InstancePresenceRecordType,
  isGifAnimated,
  MediaHelpers,
  PageRecordType,
  TLAsset,
  TLAssetId,
  TLDocument,
  TLInstancePresence,
  TLPageId,
  TLShape,
  TLShapeId,
  TLStoreWithStatus,
} from '@digitalsamba/tldraw';
import Uppy, { Meta } from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import { notification } from 'features/notifications/toast/notification';
import { UserId } from 'features/users/types';
import { selectUserById } from 'features/users/usersSlice';
import { loaderDisplayChanged, uploadProgressChanged } from 'features/whiteboard/whiteboardSlice';
import i18n from 'i18n';
import { SignalingSocket } from 'services/signaling';
import { store as reduxStore } from 'store/store';
import { singleton } from 'utils/singleton';
import { RTCClient } from 'utils/webrtc';

const toBase64: (file: File) => Promise<string> = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
  });

export type Shape = TLShape;

export type ShapeList = Record<Shape['id'], Shape>;

export interface RemoteUpdatePayload {
  changed: { id: string; data: string }[];
  removed: TLShapeId[];
}

export interface CursorUpdatePayload {
  id: string;
  data: string;
}

type UppyBody = {
  link: string;
};

const CHANGE_INTERVAL = 2 * 1000;
const POINTER_CHANGE_INTERVAL = 100;

interface Coords {
  x: number;
  y: number;
}

class BoardStateManager {
  intervalId: number = 0;

  pointerChangeIntervalId: number = 0;

  store: TLStoreWithStatus = {
    status: 'loading',
  };

  buffer: Record<string, Shape> = {};

  removedBuffer: Record<string, boolean> = {};

  localChangesPending = false;

  pointerCoords: {
    x: number;
    y: number;
  } = { x: 0, y: 0 };

  presenceIdByUserId: Record<UserId, TLInstancePresence['id']> = {};

  storedCoordsByUserId: Record<UserId, Coords> = {};

  showPointers: boolean = true;

  editor: Editor | null = null;

  uploader: Uppy<Meta, UppyBody> | null = null;

  uploadTokens: Record<string, string> = {};

  shouldInitStore = false;

  private previousPointerCoords: {
    x: number;
    y: number;
  } = { x: 0, y: 0 };

  createStore = () => {
    if (this.store.status !== 'loading') {
      if (this.shouldInitStore) {
        this.store = {
          status: 'loading',
        };

        this.buffer = {};
        this.removedBuffer = {};
        this.localChangesPending = false;
        this.stop();
      } else {
        return;
      }
    }

    this.shouldInitStore = false;

    const store = createTLStore({ shapeUtils: defaultShapeUtils });

    store.put([
      DocumentRecordType.create({
        id: 'document:document' as TLDocument['id'],
      }),

      PageRecordType.create({
        id: 'page:page' as TLPageId,
        name: 'Page 1',
        index: 'a1',
      }),
    ]);

    store.listen(
      ({ changes }) => {
        Object.values(changes.added).forEach((record) => {
          this.insertShape(record.id, record);
        });

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.values(changes.updated).forEach(([_, record]) => {
          this.insertShape(record.id, record);
        });

        Object.values(changes.removed).forEach((record) => {
          this.removeShape(record.id);
        });
      },
      { source: 'user', scope: 'document' }
    );

    // old store will be gargbage collected, right? =);
    this.store = {
      store,
      status: 'synced-remote',
      connectionStatus: 'online',
    };

    reduxStore.dispatch(loaderDisplayChanged(false));
  };

  insertShape = (id: string, shape: any) => {
    this.buffer[id] = shape;

    delete this.removedBuffer[id];

    this.localChangesPending = true;
  };

  removeShape = (id: string) => {
    delete this.buffer[id];

    this.removedBuffer[id] = true;

    this.localChangesPending = true;
  };

  watch = () => {
    this.intervalId = window.setInterval(this.checkForChanges, CHANGE_INTERVAL);
    this.pointerChangeIntervalId = window.setInterval(
      this.updatePointerCoords,
      POINTER_CHANGE_INTERVAL
    );
  };

  stop = () => {
    clearInterval(this.intervalId);
    clearInterval(this.pointerChangeIntervalId);
  };

  setState = (shapes: ShapeList) => {
    this.store.store?.put(Object.values(shapes));
  };

  acceptRemoteChanges = (data: RemoteUpdatePayload) => {
    this.store.store?.mergeRemoteChanges(() => {
      const toPut = data.changed?.map((row: any) => JSON.parse(row.data));
      const toRemove = data.removed;

      if (toPut.length) this.store.store?.put(toPut);
      if (toRemove.length) this.store.store?.remove(toRemove);
    });
  };

  updateRemotePointer = (data: { id: UserId; coords: Coords }) => {
    if (!this.store.store) {
      return;
    }

    this.storedCoordsByUserId[data.id] = data.coords;

    if (!this.showPointers) {
      return;
    }

    const user = selectUserById(reduxStore.getState(), data.id);

    if (!(user?.name && user?.avatarColor)) {
      return;
    }

    const presence = InstancePresenceRecordType.create({
      id: InstancePresenceRecordType.createId(data.id),
      currentPageId: 'page:page' as TLPageId,
      userId: data.id,
      userName: user.name,
      color: user.avatarColor,
      cursor: { x: data.coords.x, y: data.coords.y, type: 'default', rotation: 0 },
      lastActivityTimestamp: Date.now(),
    });

    this.presenceIdByUserId[data.id] = presence.id;

    this.store.store.mergeRemoteChanges(() => {
      this.store.store?.put([presence]);
    });
  };

  removeRemotePointer = (id: UserId) => {
    if (this.store.store && this.presenceIdByUserId[id]) {
      this.store.store.mergeRemoteChanges(() => {
        this.store.store?.remove([this.presenceIdByUserId[id]]);
      });
    }
  };

  togglePointers = () => {
    if (this.showPointers) {
      Object.keys(this.presenceIdByUserId).forEach((key) => {
        this.removeRemotePointer(key);
      });

      this.showPointers = false;
    } else {
      this.showPointers = true;

      Object.entries(this.storedCoordsByUserId).forEach(([id, coords]) => {
        this.updateRemotePointer({ id, coords });
      });
    }

    return this.showPointers;
  };

  setReadonly = (state: boolean) => {
    if (this.editor) {
      this.editor.updateInstanceState({ isReadonly: state });
    }
  };

  getUploader = () => {
    if (!this.uploader) {
      this.uploader = new Uppy({
        restrictions: {
          maxFileSize: 10 * (1024 * 1024),
        },
        autoProceed: true,
        // allow duplicate files (bc i KNOW people will try to)
        onBeforeFileAdded: () => true,
      });

      this.uploader.use(XHRUpload, {
        endpoint: `${process.env.REACT_APP_API_URL}/room-api/${RTCClient.roomId}/files/upload`,
        formData: true,
        fieldName: 'file',
        headers: (file) => {
          const token = this.uploadTokens[file.name!];

          return {
            authorization: `Bearer ${token}`,
          };
        },
      });

      this.uploader.on('progress', (progress) => {
        reduxStore.dispatch(uploadProgressChanged(progress));
      });
    }

    return this.uploader;
  };

  handleImageUpload = async ({ file }: { file: File }) => {
    const tokenResponse = (await SignalingSocket.sendAsync({ event: 'requestFileUpload' })) as {
      token: string;
    };

    if (tokenResponse?.token) {
      this.uploadTokens[file.name] = tokenResponse.token;
    }

    const uploader = this.getUploader();
    let fileId: string;

    try {
      fileId = uploader.addFile({
        name: file.name,
        type: 'wb-image',
        data: file,
      });
    } catch (e) {
      notification(i18n.t('notifications:wb_upload_file_invalid'));
      return undefined;
    }

    const result = await uploader.upload();

    if (!result) {
      return;
    }

    if (result.successful?.[0]) {
      const res = result.successful[0];
      const url = res.response?.body!.link as string;

      const dataUrl = await toBase64(file);

      const size = await MediaHelpers.getImageSizeFromSrc(dataUrl);
      const assetId: TLAssetId = AssetRecordType.createId(getHashForString(url));
      const isAnimated = file.type === 'image/gif' && (await isGifAnimated(file));

      const asset: TLAsset = AssetRecordType.create({
        id: assetId,
        type: 'image',
        typeName: 'asset',
        props: {
          name: file.name,
          src: url,
          w: size.w,
          h: size.h,
          mimeType: file.type,
          isAnimated,
        },
      });

      uploader.removeFile(fileId);

      return asset;
    }

    // @ts-ignore
    if (result.failed[0]) {
      reduxStore.dispatch(uploadProgressChanged(0));
      uploader.removeFile(fileId);
      notification(i18n.t('notifications:wb_upload_failed'));

      return undefined;
    }

    return undefined;
  };

  private flushBuffer = () => {
    if (!this.localChangesPending) {
      return;
    }

    const buffer = { ...this.buffer };
    const removedBuffer = { ...this.removedBuffer };

    this.buffer = {};
    this.removedBuffer = {};

    this.localChangesPending = false;

    const changedShapes = Object.keys(buffer).map((shapeId) => ({
      shapeId,
      data: JSON.stringify(buffer[shapeId]),
    }));

    const removedShapes = Object.keys(removedBuffer);

    SignalingSocket.send({
      event: 'updateWhiteboard',
      data: {
        changed: changedShapes,
        removed: removedShapes,
      },
    });
  };

  private checkForChanges = () => {
    if (this.localChangesPending) {
      this.flushBuffer();
    }
  };

  private updatePointerCoords = () => {
    if (
      this.pointerCoords.x !== this.previousPointerCoords.x ||
      this.pointerCoords.y !== this.previousPointerCoords.y
    ) {
      SignalingSocket.send({
        event: 'updateWhiteboardCursor',
        data: {
          data: JSON.stringify(this.pointerCoords),
        },
      });

      this.previousPointerCoords = this.pointerCoords;
    }
  };
}

export const board = singleton<BoardStateManager>(() => new BoardStateManager());
