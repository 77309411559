import { DialogTitle as MDialogTitle, DialogTitleProps } from '@mui/material';
import CloseButton from 'components/CloseButton';
import { DialogCloseCallback } from 'components/Dialog/index';

type TitleProps = DialogTitleProps & {
  id: string;
  onClose?: DialogCloseCallback;
};

const DialogTitle = ({ children, onClose, ...props }: TitleProps) => (
  <MDialogTitle
    {...props}
    sx={{
      paddingRight: (theme) => (onClose ? theme.spacing(10) : theme.spacing(6)),
    }}
  >
    {children}
    {onClose ? <CloseButton onClick={onClose} /> : null}
  </MDialogTitle>
);

export default DialogTitle;
