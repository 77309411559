import Icon from 'components/Icon';
import { useTranslation } from 'react-i18next';
import { IconButton, IconButtonProps } from '@mui/material';
import cx from 'clsx';

const CloseButton = ({ sx = [], className, ...props }: IconButtonProps) => {
  const { t } = useTranslation();

  return (
    <IconButton
      aria-label={t('cross_aria_label')}
      sx={[
        {
          fontSize: '32px',
          padding: 0,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
      className={cx('CloseButton-root', className)}
    >
      <Icon name="close" />
    </IconButton>
  );
};

export default CloseButton;
