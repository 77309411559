import { call } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { PayloadAction } from '@reduxjs/toolkit';
import { UserId } from 'features/users/types';

export function* askUnmuteRemoteAudioSaga(action: PayloadAction<UserId>) {
  yield call(SignalingSocket.send, {
    event: 'askToUnmuteRemoteAudio',
    data: {
      id: action.payload,
    },
  });
}
