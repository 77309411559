import FastJoinAudience from 'features/join/fast-join/FastJoinAudience';
import { PermissionTypes } from 'features/permissions/types';
import { usePermissions } from 'features/permissions/usePermissions';
import { JoinScreenWrapper } from 'features/join/styles';
import FastJoinPublisher from 'features/join/fast-join/FastJoinPublisher';

// @TODO find better approach to bypass auto-play blocking policy
const FastJoin = () => {
  const { hasPermissions } = usePermissions();

  const allowBroadcast = hasPermissions(PermissionTypes.broadcast);

  return (
    <JoinScreenWrapper>
      {allowBroadcast ? <FastJoinPublisher /> : <FastJoinAudience />}
    </JoinScreenWrapper>
  );
};

export default FastJoin;
