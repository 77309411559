import { FeedId, PublisherMid } from 'features/streaming/types';
import { FeedStreamInfo, PublishingKind } from 'utils/webrtc/types';
import { SignalingSendEventType, SignalingSocket } from 'services/signaling';

const commands: Record<PublishingKind, Record<string, SignalingSendEventType>> = {
  publishing: {
    start: 'startStream',
    stop: 'stopStream',
  },
  screensharing: {
    start: 'startScreenshare',
    stop: 'stopScreenshare',
  },
};

export class SignalingBus {
  timeouts: Record<FeedId, number>;

  kind: PublishingKind;

  stack: Record<
    FeedId,
    {
      start: Record<PublisherMid, FeedStreamInfo>;
      stop: Record<PublisherMid, FeedStreamInfo>;
    }
  > = {};

  constructor(kind: PublishingKind) {
    this.kind = kind;

    this.timeouts = {};
  }

  addMessage = (type: 'start' | 'stop', feedId: FeedId | undefined, streams: FeedStreamInfo[]) => {
    const opposingType = type === 'start' ? 'stop' : 'start';

    if (feedId) {
      if (!this.stack[feedId]) {
        this.stack[feedId] = {
          start: {},
          stop: {},
        };
      }

      streams.forEach((stream) => {
        this.stack[feedId][type][stream.mid] = stream;
        delete this.stack[feedId][opposingType][stream.mid];
      });

      clearTimeout(this.timeouts[feedId]);

      // Just small enough to batch multiple calls caused by janus, UI interactions will still cause different requests.
      // @TODO: If we're able to debounce janus media state updates, we can remove this delay whatsoever;
      const delay = 300;

      this.timeouts[feedId] = window.setTimeout(() => this.sendMessage(type, feedId), delay);
    }
  };

  sendMessage = (type: 'start' | 'stop', feedId: FeedId) => {
    const streams = Object.values(this.stack[feedId][type]);

    // batch streams only on started
    const event = commands[this.kind][type];

    if (type === 'start') {
      SignalingSocket.send({
        event,
        data: {
          feedId,
          streams,
        },
      });
    } else if (this.kind === 'screensharing') {
      SignalingSocket.send({
        event,
        data: {
          mids: streams.map((stream) => stream.mid),
        },
      });
    } else {
      streams.forEach((stream) => {
        SignalingSocket.send({
          event,
          data: {
            mid: stream.mid,
          },
        });
      });
    }
    this.stack[feedId][type] = {};
  };
}
