import { call, select, take } from 'redux-saga/effects';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { SFrameManager } from 'features/e2ee/SFrameManager';
import { selectIsRatcheting, senderKeyRatchetFinished } from 'features/e2ee/e2eeSlice';
import { logger } from 'utils/logger';

export function* refreshStreamKey() {
  logger.remote({ system: true, capture: 'e2ee' }).debug('Refreshing the stream encryption key...');

  const isRatcheting: boolean = yield select(selectIsRatcheting, 'stream');
  if (isRatcheting) {
    logger
      .remote({ system: true, capture: 'e2ee' })
      .debug('Stream key ratcheting is in-progress. Waiting for it to finish...');

    yield take(senderKeyRatchetFinished('stream').type);
  }

  const streamKey: ArrayBuffer = yield call(E2EEManager.generateStreamKey);
  yield call(SFrameManager.setSenderEncryptionKey, streamKey);
}
