import React from 'react';
import ToolbarMenuItem from 'features/toolbar/ToolbarMenuItem/ToolbarMenuItem';
import { useRecordingControl } from 'features/toolbar/Toolbar/controls/recording/useRecordingControl';
import { RecordingIcon } from 'features/toolbar/Toolbar/controls/recording/RecordingIcon';
import { ToolbarMenuItemWrapperProps } from 'features/toolbar/types';

const RecordingControl = ({ onClick }: ToolbarMenuItemWrapperProps) => {
  const { recorderStatus, icon, label, toggleRecording, active, isFeatureAvailable } =
    useRecordingControl();

  if (!isFeatureAvailable) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(event);
    toggleRecording();
  };

  return (
    <ToolbarMenuItem
      id="recording-control"
      overlay={label}
      ariaLabel={label}
      disabled={recorderStatus === 'starting'}
      icon={<RecordingIcon name={icon} active={active} disabled={recorderStatus === 'starting'} />}
      onClick={handleClick}
    />
  );
};

export default RecordingControl;
