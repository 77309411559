import { useCallback } from 'react';
import { openModal } from 'features/modal/modalSlice';
import { UserId } from 'features/users/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectRemoteBroadcastingState } from 'features/streaming/streamingSlice';
import { PermissionTypes } from 'features/permissions/types';
import { usePermissions } from 'features/permissions/usePermissions';
import { selectGlobalUser } from 'features/users/usersSlice';

export const useRemoteAudioMute = (userId: UserId) => {
  const dispatch = useAppDispatch();

  const isAudioBroadcasting = useAppSelector((state) =>
    selectRemoteBroadcastingState(state, userId, 'audio')
  );
  const user = useAppSelector((state) => selectGlobalUser(state, userId));

  const { hasPermissions } = usePermissions();

  const muteRemotely = useCallback(() => {
    dispatch(
      openModal('muteRemoteAudioConfirmation', {
        userId,
      })
    );
  }, [dispatch, userId]);

  const unmuteRemotely = useCallback(() => {
    dispatch(
      openModal('unmuteRemoteAudioConfirmation', {
        userId,
      })
    );
  }, [dispatch, userId]);

  const toggleMicRemotely = useCallback(() => {
    if (isAudioBroadcasting) {
      muteRemotely();
    } else {
      unmuteRemotely();
    }
  }, [isAudioBroadcasting, muteRemotely, unmuteRemotely]);

  const canRemotelyMuteAudio =
    isAudioBroadcasting &&
    hasPermissions(PermissionTypes.remoteMuting, {
      targetRole: user?.role,
    });

  const canAskToUnmuteAudio =
    !isAudioBroadcasting &&
    hasPermissions(PermissionTypes.askRemoteUnmute, {
      targetRole: user?.role,
    });

  return {
    toggleMicRemotely,
    unmuteRemotely,
    muteRemotely,
    isAudioBroadcasting,
    canRemotelyMute: canRemotelyMuteAudio,
    canAskToUnmute: canAskToUnmuteAudio,
  };
};
