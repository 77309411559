import { all, call } from 'redux-saga/effects';
import { ratchetStreamKeySaga } from 'features/e2ee/sagas/ratchetStreamKeySaga';
import { ratchetApplicationKeySaga } from 'features/e2ee/sagas/ratchetApplicationKeySaga';
import { exchangeEncryptionKeysSaga } from 'features/e2ee/sagas/exchangeEncryptionKeysSaga';
import { ExchangeKeyParams } from 'features/e2ee/types';

export function* ratchetEncryptionKeys(params: ExchangeKeyParams) {
  yield all([call(ratchetStreamKeySaga), call(ratchetApplicationKeySaga)]);

  yield call(exchangeEncryptionKeysSaga, {
    id: params.id,
    e2eePublicKey: params.e2eePublicKey!,
  });
}
