import Icon from 'components/Icon';
import { useController, UseControllerProps } from 'react-hook-form';
import Select, { OptionType } from 'components/Select';
import { MediaDeviceChangeHandler } from 'features/join/publisher/JoinPreview';
import { Control } from 'react-hook-form/dist/types/form';
import { FormControl, InputAdornment, MenuItem } from '@mui/material';

interface DeviceSelecProps extends UseControllerProps {
  options: OptionType[];
  placeholder?: string;
  control: Control<any>;
  id: string;
  ariaLabel: string;
  icon: string;
  disabled?: boolean;
  onChange?: MediaDeviceChangeHandler;
}

const DeviceSelect = ({
  onChange,
  disabled,
  options,
  placeholder,
  id,
  ariaLabel,
  icon,
  ...props
}: DeviceSelecProps) => {
  const {
    field: { ref, ...fieldProps },
  } = useController(props);

  return (
    <FormControl fullWidth disabled={disabled}>
      <Select
        {...fieldProps}
        id={id}
        onChange={(e) => {
          fieldProps.onChange(e.target.value);
          onChange?.(e.target.value);
        }}
        inputRef={ref}
        defaultValue=""
        placeholder={placeholder}
        label={ariaLabel}
        labelId={`label-${id}`}
        startAdornment={
          <InputAdornment position="start">
            <Icon name={icon} />
          </InputAdornment>
        }
      >
        {options.map(({ value, label }, i) => (
          <MenuItem key={i} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DeviceSelect;
