import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectActiveMediaDevice } from 'features/user-media/userMediaSlice';
import { AudioPool, UnlockedAudio } from 'utils/webrtc/AudioPool';

export const useAudio = () => {
  const [attached, setAttached] = useState(false);
  const audioOutputId = useAppSelector((state) => selectActiveMediaDevice(state, 'audiooutput'));
  const audio = useRef<HTMLAudioElement | UnlockedAudio | null>(null);

  useEffect(() => {
    const init = async () => {
      audio.current = await AudioPool.obtainElement();
      if (audioOutputId && audio.current?.setSinkId) {
        await audio.current.setSinkId(audioOutputId);
      }
      setAttached(true);
    };

    init();

    return () => {
      if (audio.current) {
        audio.current.pause();
        if (audio.current instanceof UnlockedAudio && !audio.current.unloaded) {
          AudioPool.unloadElement(audio.current);
        }
      }
    };
  }, [audioOutputId]);

  useEffect(() => {
    if (!audio.current) {
      return;
    }

    if (attached && audioOutputId && audio.current.setSinkId) {
      audio.current.setSinkId(audioOutputId).catch(() => {
        // mute possible DOMException for stale ids during re-rendering
      });
    }
  }, [audioOutputId, attached]);

  return {
    attached,
    audio: audio.current,
  };
};
