import { useEffect } from 'react';

export const useThemeMeta = (value: string) => {
  useEffect(() => {
    let metaTag = document.querySelector('meta[name=theme-color]');

    if (!metaTag) {
      metaTag = document.createElement('meta');

      document.head.appendChild(metaTag);
    }

    metaTag.setAttribute('content', value);
  }, [value]);
};
