import { createSelector } from '@reduxjs/toolkit';
import {
  selectIsRecorderSession,
  selectRecorderLogoVisibility,
} from 'features/recorder/recorderSlice';
import { styleConstants } from 'theme/styleConstants';
import { selectTopBarVisibility } from './roomSlice';

export const selectTopbarHeight = createSelector(
  [selectIsRecorderSession, selectTopBarVisibility, selectRecorderLogoVisibility],
  (isRecorder, topBarVisible, recorderLogoEnabled) => {
    if (isRecorder && recorderLogoEnabled) {
      return styleConstants.topBar.height;
    }

    if (isRecorder) {
      return 0;
    }

    if (!topBarVisible) {
      return 0;
    }

    return styleConstants.topBar.height;
  }
);
