import { ZenModeTooltip } from 'components/ZenModeTooltip';
import { StreamControlButton } from 'features/streaming/components/StreamControlButton';
import { useAppSelector } from 'store/hooks';
import { selectIsActiveSpeaker } from 'features/streaming/streamingSlice';
import { UserId } from 'features/users/types';
import { useLocalAudio } from 'hooks/useLocalAudio';

export type MicControlLocalProps = {
  userId: UserId;
};

export const MicControlLocal = ({ userId }: MicControlLocalProps) => {
  const { isAudioBroadcasting, toggleMic, isPermissionsGranted, audioTooltipText, micIcon } =
    useLocalAudio();

  const activeSpeaker = useAppSelector((state) => selectIsActiveSpeaker(state, userId));

  const color = () => {
    if (!isPermissionsGranted) {
      return 'error.main';
    }

    if (activeSpeaker && isAudioBroadcasting) {
      return '#00ff00';
    }

    return 'common.white';
  };

  return (
    <ZenModeTooltip title={audioTooltipText} placement="top">
      <StreamControlButton
        icon={micIcon}
        sx={{
          color,
        }}
        onClick={toggleMic}
      />
    </ZenModeTooltip>
  );
};
