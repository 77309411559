import { useEffect } from 'react';
import { useReceivingFeed } from 'hooks/useReceivingFeed';

export const useRemoteAudio = (mid: string | undefined) => {
  const { control } = useReceivingFeed();

  useEffect(() => {
    if (mid !== undefined) {
      control.attachAudio(mid);
    }

    return () => {
      if (mid !== undefined) {
        control.detachFromElement(mid);
      }
    };
  }, [mid, control]);
};
