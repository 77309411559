import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, DialogContent } from 'components/Dialog';
import { Button, DialogActions, DialogContentText } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { usePageReload } from 'hooks/usePageReload';

const PeerConnectionFailureModal = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const { createReloadUrl } = usePageReload();

  const hideModal = () => dispatch(closeModal('peerConnectionFailure'));

  return (
    <Dialog
      open
      aria-labelledby="connection-failure-dialog"
      aria-describedby="connection-failure-dialog-description"
      onClose={hideModal}
      maxWidth="xs"
    >
      <DialogTitle id="connection-failure-dialog" onClose={hideModal}>
        {t('connection_failure.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="connection-failure-dialog-description">
          {t('connection_failure.description')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button type="button" component={RouterLink} to={createReloadUrl()} reloadDocument replace>
          {t('connection_failure.btn_ok_label')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PeerConnectionFailureModal;
