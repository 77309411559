import { PayloadAction } from '@reduxjs/toolkit';
import { call, select, take } from 'redux-saga/effects';
import { RTCClient } from 'utils/webrtc';
import { RTCClientInitialized } from 'features/application/actions';
import { SignalingSocket } from 'services/signaling';
import { selectAppReconnecting } from 'features/application/applicationSlice';
import { PrepareRoomOptions } from 'features/room/types';

export function* prepareRoomJoinSaga(action: PayloadAction<PrepareRoomOptions>) {
  const isReconnecting: boolean = yield select(selectAppReconnecting);
  if (!isReconnecting) {
    yield call(
      RTCClient.initialize,
      action.payload.roomId,
      action.payload.roomPin,
      action.payload.mountpointId
    );
    yield take(RTCClientInitialized);
  }

  yield call(SignalingSocket.joinRoom);
}
