import { singleton } from 'utils/singleton';
import { canExposeInternals } from 'utils/development';
import Socket from './SignalingSocket';

export * from './eventMap';
export * from './roomOnlyEvents';
export * from './types';

export const SignalingSocket = singleton<Socket>(() => new Socket());

if (canExposeInternals()) {
  window.Signaling = SignalingSocket;
}
