import { ChatState, ChatTab } from 'features/chat/types';

export const setupUnreadCounters = (state: ChatState) => {
  const newUnreadCounters: Record<string, number> = {
    public: 0,
  };

  const { panelOpen, activeTab, privateChat } = state;

  const publicChatVisible = panelOpen && activeTab === ChatTab.public;

  if (!publicChatVisible) {
    for (const messageId of state.publicIds) {
      const message = state.entities[messageId];
      if (!message.local && !state.readMessages[messageId]) {
        newUnreadCounters.public += 1;
      }
    }
  }

  for (const [targetId, chat] of Object.entries(state.privateChat.chats)) {
    newUnreadCounters[targetId] ??= 0;

    const targetChatOpened =
      privateChat.activeView.name === 'personal' && privateChat.activeView.data.id === targetId;

    const targetChatVisible = panelOpen && activeTab === ChatTab.private && targetChatOpened;

    if (!targetChatVisible) {
      for (const messageId of chat.messages) {
        const message = state.entities[messageId];
        if (!message.local && !state.readMessages[messageId]) {
          newUnreadCounters[targetId] += 1;
        }
      }
    }
  }

  state.unreadCounters = newUnreadCounters;
};
