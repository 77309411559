import { logger } from 'utils/logger';

export const getUserMedia = async (constraints?: MediaStreamConstraints) => {
  logger.remote({ tier: 1 }).info('Requesting user media', constraints);

  const stream = await navigator.mediaDevices.getUserMedia(constraints);
  const tracks = stream.getTracks();

  tracks.forEach((track) => {
    logger.remote({ tier: 1 }).info(`${track.kind} track settings:`, track.getSettings());
    logger.remote({ tier: 1 }).info(`${track.kind} track constraints:`, track.getConstraints());
  });

  return stream;
};
