import { openModal } from 'features/modal/modalSlice';
import { broadcasterLimitReachedNotificationUpdateRequested } from 'features/notifications/actions';
import { signalingBroadcasterLimitReached } from 'features/room/actions';
import { call, put } from 'redux-saga/effects';
import { RTCClient } from 'utils/webrtc';

export function* onBroadcasterLimitReachedSaga(
  action: ReturnType<typeof signalingBroadcasterLimitReached>
) {
  const { limit, id: initiatorId } = action.payload;

  if (initiatorId) {
    yield put(broadcasterLimitReachedNotificationUpdateRequested());

    return;
  }

  // local user can't broadcast
  yield put(openModal('broadcasterLimitReached', { limit }));

  yield call(RTCClient.publishingFeed.stopBroadcasting);
}
