import { BrandingOptions } from 'features/room/types';
import { NormalizedBrandingOptions } from 'features/branding/types';

export function normalizeBrandingOptions(options: BrandingOptions): NormalizedBrandingOptions {
  return {
    palette: {
      mode: options.palette.mode,
      primary: {
        main: options.palette.primary,
      },
    },
    components: {
      room: {
        background: options.components.roomBackground,
      },
      toolbar: {
        background: options.components.toolbarBackground,
      },
    },
  };
}
