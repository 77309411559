import { call } from 'redux-saga/effects';
import { eventBus, SDKConnectionPayload } from 'utils/eventBus';

export function* validateSDKInitStateSaga(initState: SDKConnectionPayload) {
  const returnState = { ...initState };

  if (returnState.initials) {
    if (returnState.initials.length < 1 || returnState.initials.length > 2) {
      yield call(eventBus.error, {
        name: 'initials',
        message: `Invalid initials provided. Initials string must be 1 or 2 characters long, without whitespaces`,
      });

      returnState.initials = undefined;
    }
  }

  return returnState;
}
