export const chunkSubstring = (str: string, size: number) => {
  const chunks: string[] = [];

  for (let i = 0; i < str.length; i += size) {
    chunks.push(str.substring(i, i + size));
  }

  // append 0 to the last chunk to match the chunk size
  const lastChunk = chunks[chunks.length - 1];
  chunks[chunks.length - 1] = lastChunk.padEnd(size, '0');

  return chunks;
};
