import { arrayBufferToBase64 } from 'features/e2ee/utils/base64';
import { hkdf } from 'features/e2ee/utils/hkdf';
import { GenerateKeyOptions } from 'features/e2ee/types';

export class AesCm256EncryptionKey {
  encryptionKey?: CryptoKey;

  key?: ArrayBuffer;

  isGenerating: boolean = false;

  static async create(raw: ArrayBuffer) {
    const key = new AesCm256EncryptionKey();
    await key.setKey(raw);

    return key;
  }

  export = async () => {
    if (!this.key) {
      throw new Error('Cannot export a secret. Key is not defined');
    }

    return arrayBufferToBase64(this.key);
  };

  generate = async (options: GenerateKeyOptions = {}) => {
    this.isGenerating = true;

    const encryptionKey = await window.crypto.subtle.generateKey(
      {
        name: 'AES-GCM',
        length: 256,
      },
      true,
      ['encrypt', 'decrypt']
    );
    this.encryptionKey = encryptionKey;

    const rawKey = await crypto.subtle.exportKey('raw', encryptionKey);
    this.key = rawKey;

    if (options.hkdf) {
      await this.hkdf();
    }

    this.isGenerating = false;

    return rawKey;
  };

  hkdf = async () => {
    if (!this.key) {
      throw new Error('Cannot run HKDF over the encryption key. Key is not defined.');
    }

    const importedKey = await window.crypto.subtle.importKey(
      'raw',
      this.key,
      { name: 'HKDF' },
      false,
      ['deriveKey', 'deriveBits']
    );

    const derivedKey = await hkdf(importedKey);
    this.key = derivedKey;

    this.encryptionKey = await window.crypto.subtle.importKey(
      'raw',
      derivedKey,
      { name: 'AES-GCM' },
      true,
      ['encrypt', 'decrypt']
    );

    return this.encryptionKey;
  };

  getIsGenerating = async () => this.isGenerating;

  setKey = async (inputKey: CryptoKey | ArrayBuffer) => {
    let key: CryptoKey;
    if (inputKey instanceof CryptoKey) {
      if (inputKey.algorithm.name !== 'HKDF') {
        throw new Error('Invalid key');
      }

      key = inputKey;
    } else {
      this.key = inputKey;

      key = await window.crypto.subtle.importKey('raw', inputKey, { name: 'AES-GCM' }, false, [
        'encrypt',
        'decrypt',
      ]);
    }

    this.encryptionKey = key;
  };

  ratchet = async () => this.hkdf();
}
