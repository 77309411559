import React, { useState } from 'react';
import ToolbarControl from 'features/toolbar/ToolbarControl';
import { styled, darken } from '@mui/material/styles';
import { ClickAwayListener, TooltipProps as MuiTooltipProps } from '@mui/material';
import Tooltip from 'components/Tooltip';
import { tooltipClasses } from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { ToolbarOrientation } from 'features/toolbar/types';

export type OverflowMenuItems = Array<Exclude<React.ReactNode, boolean | null | undefined>>;

export type OverflowMenuButtonProps = Omit<React.ComponentPropsWithRef<'div'>, 'children'> & {
  menuItems: OverflowMenuItems;
  orientation?: ToolbarOrientation;
  icon?: string;
  active?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  TooltipProps?: Omit<MuiTooltipProps, 'children' | 'title' | 'placement'>;
  badgeContent?: number;
  badgeActive?: boolean;
};

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ theme, active }) => ({
  position: 'absolute',
  bottom: '0',
  padding: 'var(--toolbar-item-padding) 0',
  color: theme.palette.common.white,
  ...(active && {
    color: darken(theme.palette.common.white, 0.4),
  }),
  flexShrink: 0,
  visibility: 'hidden',
  transition: theme.transitions.create('color'),
  '& > div': {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  '&:hover': {
    color: darken(theme.palette.common.white, 0.4),
  },
  '[data-is-overflowing="true"] ~ &': {
    position: 'relative',
    visibility: 'visible',
  },
}));

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: '8px',
    padding: 'var(--toolbar-inner-padding) 0',
    width: theme.toolbar.width,
    backgroundColor: theme.toolbar.background,
    color: theme.toolbar.background,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.toolbar.background,
  },
}));

const OverflowMenuButton = React.forwardRef<HTMLDivElement, OverflowMenuButtonProps>(
  (
    {
      menuItems,
      icon = 'settings1',
      onClick,
      badgeActive,
      badgeContent,
      TooltipProps,
      active,
      orientation = 'vertical',
      ...props
    },
    ref
  ) => {
    const placement = orientation === 'vertical' ? 'right-end' : 'top';
    const { t } = useTranslation('room');
    const [open, setOpen] = useState(false);
    const handleClose = () => {
      setOpen(false);
    };

    const handleClick = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const renderMenuItems = () => {
      if (React.Children.count(menuItems) === 0) {
        return null;
      }

      return React.Children.map(menuItems, (item: any) =>
        React.cloneElement(item, {
          onClick: () => {
            setOpen(false);
            item.props.onClick?.();
          },
        })
      );
    };

    return (
      <Root
        {...props}
        active={active}
        ref={ref}
        className="ToolbarItem-root"
        data-testid="overflow-menu-button"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <StyledTooltip
              disableHoverListener
              disableFocusListener
              disableTouchListener
              {...TooltipProps}
              title={renderMenuItems()}
              placement={placement}
              open={open}
              onClose={handleClose}
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 24],
                    },
                  },
                ],
              }}
            >
              <ToolbarControl
                aria-label={t('toolbar.overflow_menu')}
                icon={icon}
                badgeActive={badgeActive}
                badgeContent={badgeContent}
                onClick={handleClick}
                orientation={orientation}
              />
            </StyledTooltip>
          </div>
        </ClickAwayListener>
      </Root>
    );
  }
);

export default OverflowMenuButton;
