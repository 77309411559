import { call, put, select } from 'redux-saga/effects';
import { signalingBreakoutRoomMessageBroadcast } from 'features/breakout-rooms/actions';
import { Action } from '@reduxjs/toolkit';
import i18n from 'i18n';
import { addActionNotification } from 'features/notifications/addActionNotification';
import { selectLocalUserId } from 'features/users/usersSlice';
import { UserId } from 'features/users/types';

export function* onMessageBroadcastSaga(
  action: ReturnType<typeof signalingBreakoutRoomMessageBroadcast>
) {
  const localUserId: UserId = yield select(selectLocalUserId);
  if (localUserId === action.payload.initiator.id) {
    // don't show the notification to the initiator
    return;
  }

  const actionNotificationThunk: Action = yield call(addActionNotification, {
    content: i18n.t('notifications:breakout_rooms.moderator_broadcast_message', {
      username: action.payload.initiator.name,
      message: action.payload.message,
    }),
    initiator: action.payload.initiator,
    scope: ['breakout-room'],
  });

  yield put(actionNotificationThunk);
}
