import cx from 'clsx';
import {
  selectShowHoverChildren,
  selectZenModeEnabled,
  showHoverChildrenChanged,
  zenModeChanged,
} from 'features/layout/features/config/configSlice';
import { HoverParent } from 'features/layout/features/config/types';
import { useRecorder } from 'features/recorder/useRecorder';
import { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getZenModeSetting } from 'utils/zenMode';

export const useHoverParent = (area: HoverParent) => {
  const isRecorder = useRecorder();

  const showHoverChildren = useAppSelector((state) => selectShowHoverChildren(state, area));
  const zenModeEnabled = useAppSelector(selectZenModeEnabled);

  const isHovering = useRef(false);

  const dispatch = useAppDispatch();

  const setShowHoverChildren = useCallback(
    (show: boolean) => {
      if (isHovering.current !== show) {
        isHovering.current = show;

        dispatch(showHoverChildrenChanged({ show, area }));
      }
    },
    [dispatch, area]
  );

  const timerRef = useRef(0);

  const setHideHoverContentTimeout = useCallback(() => {
    if (isRecorder) {
      return;
    }

    setShowHoverChildren(true);

    timerRef.current = window.setTimeout(() => {
      setShowHoverChildren(false);
    }, 4000);
  }, [isRecorder, setShowHoverChildren]);

  useEffect(() => {
    const savedZenModeSetting = getZenModeSetting();

    setHideHoverContentTimeout();
    if (savedZenModeSetting) {
      dispatch(zenModeChanged(savedZenModeSetting === 'true'));
    }
  }, [setHideHoverContentTimeout, dispatch]);

  const className = cx({
    'hover-parent-movement-on': isRecorder ? true : showHoverChildren,
    'hover-parent-movement-off': isRecorder ? false : !showHoverChildren,
    'hover-parent-show': !zenModeEnabled,
  });

  const cancelHoverContentTimeout = () => {
    clearTimeout(timerRef.current);
  };

  const onMouseEnter = () => {
    cancelHoverContentTimeout();
    setHideHoverContentTimeout();
    setShowHoverChildren(true);
  };
  const onMouseMove = () => {
    cancelHoverContentTimeout();
    setHideHoverContentTimeout();
  };

  const onMouseLeave = () => {
    if (isRecorder) {
      return;
    }

    cancelHoverContentTimeout();
    setShowHoverChildren(false);
  };

  return {
    className,
    onMouseEnter,
    onMouseMove,
    onMouseLeave,
  };
};

export const hoverParentStyles = {
  '& .hover-child': {
    opacity: 0,
    transition: 'opacity .2s ease-in',
  },
  '.hover-parent-movement-off & .hover-child': {
    opacity: 0,
  },
  '.hover-parent-show & .hover-child': {
    opacity: '1 !important',
  },
  '.hover-parent-movement-on &:hover .hover-child': {
    opacity: 1,
  },
  '.hover-parent-movement-off &:hover .hover-child': {
    opacity: 0,
  },
  '.hover-parent-movement-on &:hover .hover-child-eager': {
    opacity: 1,
  },
  '.hover-parent-movement-off &:hover .hover-child-eager': {
    opacity: 0,
  },
  '&:focus .hover-child': {
    opacity: 1,
  },
  '&:focus .hover-child-eager': {
    opacity: '1 !important',
  },
  '.hover-parent-movement-off &:focus .hover-child': {
    opacity: 0,
  },
  '.hover-parent-movement-off &:focus .hover-child-eager': {
    opacity: '0 !important',
  },
};
