import { call } from 'redux-saga/effects';
import { SignalingPong } from 'services/signaling';
import { ConnectionMediaTokens, RTCClient } from 'utils/webrtc';
import { PayloadAction } from '@reduxjs/toolkit';

export function* refreshConnectionMediaTokensSaga(action: PayloadAction<SignalingPong>) {
  const tokens: ConnectionMediaTokens = {
    publishing: action.payload.publishingMediaToken,
    subscription: action.payload.subscribingMediaToken,
  };

  yield call(RTCClient.refreshConnectionMediaTokens, tokens);
}
