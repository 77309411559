import { useEffect } from 'react';

interface UseAudioLevelOptions {
  stream: MediaStream | null;
  onChange: (volume: number) => void;
}

export const useAudioLevel = ({ stream, onChange }: UseAudioLevelOptions) => {
  useEffect(() => {
    let requestId: number;
    let audioContext: AudioContext;
    if (stream) {
      audioContext = new AudioContext();
      const analyser = audioContext.createAnalyser();
      analyser.minDecibels = -90;
      analyser.maxDecibels = -10;
      analyser.smoothingTimeConstant = 0.85;
      analyser.fftSize = 512;

      const source = audioContext.createMediaStreamSource(stream);
      source.connect(analyser);

      const volumes = new Uint8Array(analyser.frequencyBinCount);

      const calculate = () => {
        requestId = window.requestAnimationFrame(calculate);
        analyser.getByteFrequencyData(volumes);

        let sum = 0;
        for (let i = 0; i < volumes.length; i++) {
          sum += volumes[i];
        }

        const averageVolume = sum / volumes.length;
        onChange(averageVolume);
      };

      calculate();
    }

    return () => {
      if (audioContext) {
        onChange(0);
        window.cancelAnimationFrame(requestId);
        audioContext.close();
      }
    };
  }, [onChange, stream]);
};
