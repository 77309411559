import React, { useCallback, useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  OverflowItemsChangedCallback,
  useOverflow,
} from 'components/OverflowMenu/useOverflow/useOverflow';
import OverflowMenuButton, {
  OverflowMenuItems,
} from 'components/OverflowMenu/OverflowMenuButton/OverflowMenuButton';
import { useAppSelector } from 'store/hooks';
import { selectToolbarOrientation } from 'features/toolbar/toolbarSlice';
import { ToolbarOrientation } from 'features/toolbar/types';

const Root = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'orientation' && prop !== 'leading',
})<{ orientation: ToolbarOrientation; leading?: boolean }>(({ orientation, leading }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  height: '100%',
  flexDirection: 'column',
  ...(orientation === 'horizontal' && {
    height: 'auto',
    width: '100%',
    flexDirection: 'row',
  }),
  '[data-is-overflowing="true"]': {
    display: 'none',
  },
  ...(leading && {
    justifyContent: 'flex-start',
  }),
}));

type OverflowMenuProps = {
  children: React.ReactNode;
  leading?: boolean;
};

const OverflowMenu = React.memo(({ children, leading }: OverflowMenuProps) => {
  const toolbarOrientation = useAppSelector(selectToolbarOrientation);
  const [overflowMenuItems, setOverflowMenuItems] = useState<OverflowMenuItems>([]);
  const onOverflowItemsChanged = useCallback<OverflowItemsChangedCallback>(
    (overflowIndex, elements) => {
      elements.forEach(
        ({ ele, isOverflowing }) => (ele.dataset.isOverflowing = `${isOverflowing}`)
      );

      const menuItems = React.Children.toArray(children).slice(overflowIndex);
      setOverflowMenuItems(menuItems);
    },
    [children]
  );

  const { menuButtonRef: overflowMenuButtonRef } = useOverflow({
    onOverflowItemsChanged,
    orientation: toolbarOrientation,
  });

  return (
    <Root orientation={toolbarOrientation} leading={leading}>
      {children}
      <OverflowMenuButton
        menuItems={overflowMenuItems}
        ref={overflowMenuButtonRef}
        orientation={toolbarOrientation}
      />
    </Root>
  );
});

export default OverflowMenu;
