import { Paper, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSimulcast } from 'hooks/useSimulcast';

type SimulcastControlsProps = {
  mid: string;
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '&:not(:first-of-type)': {
    marginTop: -1,
  },
  '& .MuiToggleButtonGroup-grouped': {
    borderRadius: 0,
    minWidth: 30,
    fontSize: 12,
    fontWeight: 500,
    padding: theme.spacing(1),
    color: theme.palette.text.primary,
    lineHeight: 1,
    '&.Mui-selected': {
      color: theme.palette.error.main,
    },
  },
}));

const SimulcastControls = ({ mid }: SimulcastControlsProps) => {
  const { substream, temporal, changeTemporal, changeSubstream } = useSimulcast(mid);
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <StyledToggleButtonGroup
        value={substream}
        exclusive
        onChange={changeSubstream}
        aria-label="Substream controls"
      >
        <ToggleButton value={2}>H</ToggleButton>
        <ToggleButton value={1}>M</ToggleButton>
        <ToggleButton value={0}>L</ToggleButton>
      </StyledToggleButtonGroup>
      <StyledToggleButtonGroup
        value={temporal}
        exclusive
        onChange={changeTemporal}
        aria-label="Temporal controls"
      >
        <ToggleButton value={2}>HT</ToggleButton>
        <ToggleButton value={1}>MT</ToggleButton>
        <ToggleButton value={0}>LT</ToggleButton>
      </StyledToggleButtonGroup>
    </Paper>
  );
};

export default SimulcastControls;
