import cx from 'clsx';
import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

const RatioBox = styled('div')({
  position: 'relative',
  height: '100%',
  width: '100%',
  transition: 'padding .15s linear',
});

const Inner = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: `${theme.room.tileGap}px`,
  '& *': {
    zIndex: 1,
  },
  '& video': {
    borderRadius: '8px',
  },
  '&::after': {
    content: '""',
    backgroundColor: theme.room.tileBackground,
    position: 'absolute',
    top: `${theme.room.tileGap}px`,
    left: `${theme.room.tileGap}px`,
    width: `calc(100% - ${theme.room.tileGap * 2}px)`,
    height: `calc(100% - ${theme.room.tileGap * 2}px)`,
    borderRadius: '8px',
    zIndex: 0,
  },
}));

interface TileProps {
  cols?: number;
  ratio?: number;
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
}

const Tile = React.memo(
  ({ cols, ratio, children, onClick, className, sx = [] }: BoxProps<'div', TileProps>) => (
    <Box
      className={cx(className)}
      sx={[
        {
          overflow: 'hidden',
          width: cols ? `${(1 / cols) * 100}%` : '100%',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      onClick={onClick}
    >
      <RatioBox
        sx={{
          paddingTop: ratio ? ` ${ratio * 100}%` : '0',
          height: ratio ? 'auto' : '100%',
        }}
      >
        <Inner>{children}</Inner>
      </RatioBox>
    </Box>
  )
);

export default Tile;
