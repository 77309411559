import { createSelector } from '@reduxjs/toolkit';
import {
  selectCaptionsDisplayed,
  selectCaptionsFontSize,
  selectCaptionsSpokenLanguage,
} from 'features/transcripts/slices/captions/captionsSlice';
import { selectCurrentLayoutMode } from 'features/layout/features/config/configSlice';
import {
  selectLocalAudioEnabled,
  selectLocalVideoEnabled,
} from 'features/streaming/streamingSlice';
import { selectToolbarPlacement, selectToolbarVisibility } from 'features/toolbar/toolbarSlice';
import { selectActiveVirtualBackground } from 'features/virtual-backgrounds/selectors';
import { RootState } from 'store/store';
import { StoredRoomState } from 'utils/eventBus';
import { vbConfigToSDKState } from 'utils/eventBus/vbConfigToSDKState';

export const selectSDKRoomState: (state: RootState) => Partial<StoredRoomState> = createSelector(
  [
    selectLocalAudioEnabled,
    selectLocalVideoEnabled,
    selectCurrentLayoutMode,
    selectToolbarVisibility,
    selectToolbarPlacement,
    selectCaptionsDisplayed,
    selectCaptionsSpokenLanguage,
    selectCaptionsFontSize,
    selectActiveVirtualBackground,
  ],
  (
    audioEnabled,
    videoEnabled,
    layoutMode,
    showToolbar,
    toolbarPosition,
    showCaptions,
    spokenLanguage,
    fontSize,
    activeVB
  ) => ({
    media: {
      audioEnabled,
      videoEnabled,
    },
    layout: {
      mode: layoutMode,
      showToolbar,
      toolbarPosition,
    },
    captionsState: {
      showCaptions,
      spokenLanguage,
      fontSize:
        {
          16: 'small',
          24: 'medium',
          32: 'large',
        }[fontSize] || 'medium',
    },
    virtualBackground: vbConfigToSDKState(activeVB),
  })
);
