import { styled } from '@mui/material/styles';
import Icon from 'components/Icon';

export const RecordingIcon = styled(Icon, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'disabled',
})<{ active?: boolean; disabled?: boolean }>(({ theme, active, disabled }) => ({
  fontSize: 32,
  '&::after': {
    content: '""',
    display: 'block',
    width: 8,
    height: 8,
    borderRadius: '100%',
    backgroundColor: theme.palette.getContrastText(theme.toolbar.background),
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    transition: theme.transitions.create('background-color'),
    ...(active && {
      backgroundColor: theme.palette.error.main,
    }),
    ...(disabled && {
      backgroundColor: theme.palette.grey['600'],
    }),
  },
}));
