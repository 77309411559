import { ChatMessageDeletedPayload, ChatState } from 'features/chat/types';
import { getPrivateChatTarget } from 'features/chat/utils/getPrivateChatTarget';

export const deleteMessage = (state: ChatState, message: ChatMessageDeletedPayload) => {
  const { id, targetUserId, privateGroup } = message;

  const messageToDelete = state.entities[id];

  // Update the appropriate chat message lists based on message properties
  if (targetUserId || privateGroup) {
    let targetChatId: string;

    // Determine the target ID based on whether it's a private or group chat
    if (targetUserId && messageToDelete.targetUser) {
      const { targetChatId: targetId } = getPrivateChatTarget({
        localUserId: state.localUserId,
        localUserExternalId: state.localUserExternalId,
        user: messageToDelete.user,
        targetUser: messageToDelete.targetUser,
      });

      targetChatId = targetId;
    } else {
      targetChatId = state.privateChat.groupChat.id;
    }

    // Remove the deleted message from the appropriate private chat
    state.privateChat.chats[targetChatId].messages = state.privateChat.chats[
      targetChatId
    ].messages.filter((msgId) => msgId !== id);

    if (!state.readMessages[id]) {
      state.unreadCounters[targetChatId] = Math.max(
        0,
        (state.unreadCounters[targetChatId] || 0) - 1
      );
    }
  } else {
    if (!state.readMessages[id]) {
      state.unreadCounters.public = Math.max(0, (state.unreadCounters.public || 0) - 1);
    }
    // Remove the deleted message from the public chat
    state.publicIds = state.publicIds.filter((msgId) => msgId !== id);
  }

  delete state.entities[id];
};
