import { RoomToken } from 'features/room/types';
import { call, select } from 'redux-saga/effects';
import { selectRoomToken } from 'features/room/roomSlice';
import { stopRecorder } from 'features/recorder/utils';
import { SignalingSocket } from 'services/signaling';
import * as Sentry from '@sentry/react';
import { logger } from 'utils/logger';

export function* stopRecorderSaga() {
  try {
    const token: RoomToken = yield select(selectRoomToken);
    if (!token.parsedData) {
      throw new Error('Missing token data');
    }

    yield call(stopRecorder, {
      recordingId: token.parsedData.recordingId!,
    });
    yield call(SignalingSocket.send, { event: 'recordingStopped' });
  } catch (error) {
    yield call(SignalingSocket.send, { event: 'recordingFailure' });

    Sentry.captureException(error);
    logger.remote({ tier: 1 }).error('Recording stop failed', error);
  }
}
