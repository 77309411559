import { signalingWhiteboardCursorUpdated } from 'features/layout/features/content/actions';
import { call } from 'redux-saga/effects';
import { board } from 'utils/whiteboard/BoardStateManager';

export function* onWhiteboardCursorPositionChangedSaga(
  action: ReturnType<typeof signalingWhiteboardCursorUpdated>
) {
  const { id, data: stringifiedCoords } = action.payload;
  const coords = JSON.parse(stringifiedCoords);

  yield call(board.updateRemotePointer, { id, coords });
}
