import { broadcastPermissionGranted } from 'features/permissions/actions';
import { UserId } from 'features/users/types';
import { selectLocalUserId } from 'features/users/usersSlice';
import { call, select } from 'redux-saga/effects';
import { board } from 'utils/whiteboard/BoardStateManager';

export function* onWhiteboardPermissionsRevokedSaga(
  action: ReturnType<typeof broadcastPermissionGranted>
) {
  const { id } = action.payload;
  const localUserId: UserId = yield select(selectLocalUserId);

  if (id === localUserId) {
    yield call(board.setReadonly, true);
  }

  yield call(board.removeRemotePointer, id);
}
