import { JanusJS } from 'lib/janus';
import { RTCClient, JanusConnection } from 'utils/webrtc';
import { SubscribeStreamList } from 'features/streaming/types';

export enum VideoroomConnectionState {
  created,
  connecting,
  connected,
  disconnected,
  disconnecting,
}

export class ControlledReceivingHandle {
  janusPlugin: JanusJS.PluginHandle;

  handle: string;

  connection: JanusConnection;

  privateId?: number;

  connectionState: VideoroomConnectionState = VideoroomConnectionState.created;

  pendingSubscriptions: SubscribeStreamList = [];

  constructor(janusPlugin: JanusJS.PluginHandle, handle: string, connection: JanusConnection) {
    this.janusPlugin = janusPlugin;
    this.handle = handle;
    this.connection = connection;
  }

  get canSubscribe() {
    return [VideoroomConnectionState.created, VideoroomConnectionState.connected].includes(
      this.connectionState
    );
  }

  setConnectionState = (state: VideoroomConnectionState) => {
    this.connectionState = state;

    if (this.canSubscribe) {
      this.processQueue();
    }
  };

  enqueueSubscription = (streams: SubscribeStreamList) => {
    this.pendingSubscriptions = this.pendingSubscriptions.concat(streams);
  };

  processQueue = () => {
    if (this.pendingSubscriptions.length) {
      const streams = [...this.pendingSubscriptions];

      this.pendingSubscriptions = [];

      RTCClient.receivingFeed.requestSubscription(this.connection.handle, streams);
    }
  };
}
