import { call } from 'redux-saga/effects';
import { MuteFrameTypes } from 'utils/eventBus';
import { AudioPool } from 'utils/webrtc/AudioPool';

export function* onMuteFrameCommandSaga(type: MuteFrameTypes) {
  if (type === 'muteFrame') {
    yield call(AudioPool.muteAll);
    return;
  }

  if (type === 'unmuteFrame') {
    yield call(AudioPool.unmuteAll);
    return;
  }

  if (AudioPool.globalMute) {
    yield call(AudioPool.unmuteAll);
  } else {
    yield call(AudioPool.muteAll);
  }
}
