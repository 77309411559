import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';

export function* onFontSizeChangedSaga(action: PayloadAction<number>) {
  const fontSizeDenominator =
    {
      16: 'small',
      24: 'medium',
      32: 'large',
    }[action.payload] || 'medium';

  yield call(
    eventBus.sendMessage,
    'captionsFontSizeChanged',
    {},
    { fontSize: fontSizeDenominator }
  );
}
