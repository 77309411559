import { usePermissions } from 'features/permissions/usePermissions';
import { PermissionTypes } from 'features/permissions/types';
import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { useAppSelector } from 'store/hooks';
import {
  selectBreakoutParticipantsLimit,
  selectBreakoutRoomsCreated,
  selectBreakoutRoomsStatus,
} from 'features/breakout-rooms/breakoutRoomsSlice';

export const useBreakoutRooms = () => {
  const { hasPermissions } = usePermissions();
  const canManageBreakout = hasPermissions(PermissionTypes.manageBreakout);

  const status = useAppSelector(selectBreakoutRoomsStatus);

  const participantsLimit = useAppSelector(selectBreakoutParticipantsLimit);

  const breakoutRoomsCreated = useAppSelector(selectBreakoutRoomsCreated);

  const isBreakoutsEnabled = useFeatureFlag('breakoutRooms');

  const isBreakoutsAvailable = isBreakoutsEnabled && canManageBreakout;

  return {
    isBreakoutsAvailable,
    participantsLimit,
    breakoutRoomsCreated,
    canManageBreakout,
    status,
  };
};
