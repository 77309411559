import { call } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { deleteAnswerRequested } from 'features/qa/actions';

export function* deleteAnswerSaga(action: ReturnType<typeof deleteAnswerRequested>) {
  yield call(SignalingSocket.send, {
    event: 'deleteAnswer',
    data: {
      id: action.payload.id,
      questionId: action.payload.questionId,
    },
  });
}
