import { User } from '@sentry/react';
import { selectFeatureFlag } from 'features/feature-set/featuresSlice';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { UserId } from 'features/users/types';
import { lowerHand, selectLocalUserId, selectUserById } from 'features/users/usersSlice';
import { call, put, select } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';

export function* onLowerHandCommandSaga(target?: UserId) {
  const controlEnabled: boolean = yield select(selectFeatureFlag, 'raiseHand');

  // exit silently if room doesn't support raising hand;
  if (!controlEnabled) {
    return;
  }

  const localUserId: UserId = yield select(selectLocalUserId);

  if (target && localUserId !== target) {
    const targetUser: User = yield select(selectUserById, target);

    const canLowerHand: boolean = yield call(hasPermissionsSaga, PermissionTypes.manageBroadcast, {
      targetRole: targetUser?.role,
    });

    if (canLowerHand) {
      yield put(lowerHand(target));
    } else {
      yield call(
        eventBus.forbiddenActionError,
        'Forbidden action. This participant is not allowed to lower target participant’s hand',
        'lower-hand'
      );
    }
  } else {
    yield put(lowerHand());
  }
}
