import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal, selectActiveModalData } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { deleteContentLibraryFileRequested } from 'features/content-library/actions';
import { selectFile } from 'features/content-library/contentLibrarySlice';
import { useContentLibrary } from 'features/content-library/useContentLibrary';

const DeleteContentLibraryFileConfirmation = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const modalData = useAppSelector(selectActiveModalData);
  const fileId = modalData.id as string;

  const file = useAppSelector((state) => selectFile(state, fileId));

  const { isFeatureAvailable } = useContentLibrary();

  const shouldClose = !isFeatureAvailable || !file;

  useEffect(() => {
    if (shouldClose) {
      dispatch(closeModal('deleteContentLibraryFileConfirmation'));
    }
  }, [shouldClose, dispatch]);

  const handleCancel = () => {
    dispatch(closeModal('deleteContentLibraryFileConfirmation'));
  };

  const handleOk = () => {
    dispatch(
      deleteContentLibraryFileRequested({
        id: fileId,
      })
    );
    dispatch(closeModal('deleteContentLibraryFileConfirmation'));
  };

  return (
    <ConfirmationDialog
      open={!shouldClose}
      title={t('content_library.delete_file.title')}
      description={t('content_library.delete_file.description')}
      cancelText={t('content_library.delete_file.btn_cancel_label')}
      okText={t('content_library.delete_file.btn_ok_label')}
      onOk={handleOk}
      onCancel={handleCancel}
      hideCloseButton
      preventClose
    />
  );
};

export default DeleteContentLibraryFileConfirmation;
