import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { ToolbarPanel } from 'features/toolbar/ToolbarPanel';
import { useAppSelector } from 'store/hooks';
import { selectToolbarPlacement } from 'features/toolbar/toolbarSlice';
import { useToolbarPanel } from 'hooks/useToolbarPanel';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { Portal } from '@mui/base';
import { ToolbarPanelType } from 'features/toolbar/types';

const importView = async (panelName: ToolbarPanelType) =>
  lazy(() => import(`features/${panelName}/panel/Panel`));

const ErrorFallback = () => {
  const { t } = useTranslation();

  return (
    <Typography
      sx={{
        p: 2,
      }}
    >
      {t('generic_error_short')}
    </Typography>
  );
};

const ToolbarPanelManager = () => {
  const { toolbarPanel, closePanel } = useToolbarPanel();

  const [panels, setPanels] = useState<Partial<Record<ToolbarPanelType, React.ComponentType>>>({});

  const toolbarPlacement = useAppSelector(selectToolbarPlacement);

  const loadPanel = useCallback(
    async (panelName: ToolbarPanelType) => {
      if (panels[panelName]) {
        return;
      }

      const Panel = await importView(panelName);
      setPanels((prev) => ({ ...prev, [panelName]: Panel }));
    },
    [panels]
  );

  useEffect(() => {
    if (toolbarPanel) {
      loadPanel(toolbarPanel);
    }
  }, [loadPanel, toolbarPanel]);

  return (
    <Portal>
      <ToolbarPanel open={!!toolbarPanel} onBackdropClick={closePanel} placement={toolbarPlacement}>
        <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
          <Suspense fallback={null}>
            {Object.entries(panels).map(([type, Panel]) => (
              <Box
                key={type}
                sx={{
                  height: '100%',
                  display: type === toolbarPanel ? 'block' : 'none',
                }}
              >
                <Panel />
              </Box>
            ))}
          </Suspense>
        </Sentry.ErrorBoundary>
      </ToolbarPanel>
    </Portal>
  );
};

export default ToolbarPanelManager;
