import { EncryptedData, UserId } from 'features/users/types';
import { singleton } from 'utils/singleton';
import { canExposeInternals } from 'utils/development';
import { exportKey } from 'features/e2ee/utils/exportKey';
import { hkdf } from 'features/e2ee/utils/hkdf';
import { generateSharedKey } from 'features/e2ee/utils/generateSharedKey';
import { logger } from 'utils/logger';
import { generateTestStreamKey } from 'features/e2ee/utils/generateTestStreamKey';
import { store } from 'store/store';
import {
  applicationKeyGenerated,
  remoteStreamSecretDecrypted,
  userNameDecrypted,
} from 'features/e2ee/actions';
import { AesCm256EncryptionKey } from 'features/e2ee/AesCm256EncryptionKey';
import { generateTestAppKey } from 'features/e2ee/utils/generateTestAppKey';
import { E2eeReceiver } from 'features/e2ee/E2eeReceiver';
import { base64Decrypt } from 'features/e2ee/utils/base64Decrypt';
import { e2eeDecrypt } from 'features/e2ee/utils/e2eeDecrypt';
import { PayloadAction } from '@reduxjs/toolkit';

export const e2eeNameSensitiveEvents = new Set(['handRaised']);

class E2EE {
  private enabled: boolean = false;

  private ellipticCurveKeys?: CryptoKeyPair;

  private receivers = new Map<UserId, E2eeReceiver>();

  private pendingDecryption = new Map<UserId, EncryptedData>();

  private pendingActions = new Map<UserId, Map<string, PayloadAction<any>>>();

  streamKey = new AesCm256EncryptionKey();

  applicationKey = new AesCm256EncryptionKey();

  generateApplicationKey = async () => {
    // @FIXME remove test code
    if (canExposeInternals() && window.e2eeAppKey) {
      window.e2eeAppSharedKey = await generateTestAppKey();
    }

    await this.applicationKey.generate({ hkdf: true });

    store.dispatch(applicationKeyGenerated());
  };

  generateStreamKey = async () => {
    // @FIXME remove test code
    if (canExposeInternals()) {
      window.e2eeStreamSharedKey = await generateTestStreamKey();
    }

    return this.streamKey.generate();
  };

  private generateEllipticCurveKeys = async () => {
    this.ellipticCurveKeys = await window.crypto.subtle.generateKey(
      {
        name: 'ECDH',
        namedCurve: 'P-256',
      },
      true,
      ['deriveKey', 'deriveBits']
    );
  };

  private generateSharedSecret = async (targetPublicKey: CryptoKey) => {
    if (!this.ellipticCurveKeys) {
      throw new Error('Cannot generate a shared secret. Elliptic curve keys are not defined');
    }

    const sharedKey = await generateSharedKey(targetPublicKey, this.ellipticCurveKeys.privateKey);
    const derivedKey = await hkdf(sharedKey);

    return window.crypto.subtle.importKey('raw', derivedKey, { name: 'AES-GCM' }, false, [
      'encrypt',
      'decrypt',
    ]);
  };

  importPublicKey = async (publicKeyString: string) => {
    const parsedKey = JSON.parse(publicKeyString);
    return crypto.subtle.importKey(
      'jwk',
      parsedKey,
      {
        name: 'ECDH',
        namedCurve: parsedKey.crv,
      },
      true,
      parsedKey.key_ops
    );
  };

  getStreamKey = () => {
    if (canExposeInternals() && window.e2eeStreamKey) {
      return {
        key: window.e2eeStreamSharedKey,
      };
    }

    return this.streamKey;
  };

  getApplicationKey = () => {
    if (canExposeInternals() && window.e2eeAppKey) {
      return {
        encryptionKey: window.e2eeAppSharedKey,
      };
    }

    return this.applicationKey;
  };

  private generateKeys = async () =>
    Promise.all([
      this.generateStreamKey(),
      this.generateApplicationKey(),
      this.generateEllipticCurveKeys(),
    ]);

  get e2eeEnabled() {
    return this.enabled;
  }

  enableE2ee = async () => {
    this.enabled = true;

    logger.remote({ system: true, capture: 'e2ee' }).debug('Generating encryption keys');

    await this.generateKeys();
  };

  exportPublicKey = async () => {
    if (!this.ellipticCurveKeys) {
      throw new Error('Cannot export a public key. Elliptic curve keys are not defined');
    }

    const key = await exportKey(this.ellipticCurveKeys.publicKey);

    return JSON.stringify(key);
  };

  exchangeEncryptionKey = async (userId: UserId, publicKeyString: string) => {
    logger
      .remote({ system: true, capture: 'e2ee' })
      .info(`Exchanging an encryption key with the userId=${userId}`);

    const receiver = this.getReceiver(userId);

    // return an existing shared secret if it was generated before
    if (receiver?.sharedSecret) {
      return receiver.sharedSecret;
    }

    if (!publicKeyString) {
      throw new Error('Cannot exchange an encryption key. Remote public key is not defined.');
    }

    const publicKey = await this.importPublicKey(publicKeyString);

    const sharedSecret = await this.generateSharedSecret(publicKey);
    if (!sharedSecret) {
      throw new Error('Cannot exchange an encryption key. Shared secret is not defined');
    }

    this.receivers.set(userId, new E2eeReceiver(userId, { publicKey, sharedSecret }));

    if (this.pendingDecryption.has(userId)) {
      const encryptedData = this.pendingDecryption.get(userId) as EncryptedData;

      await this.decryptRemoteData(userId, encryptedData);
    }

    return sharedSecret;
  };

  decryptRemoteData = async (userId: UserId, data: EncryptedData) => {
    const receiver = this.receivers.get(userId);

    if (!receiver) {
      logger
        .remote({ system: true, capture: 'e2ee' })
        .debug(
          `Received remote encryption data while local ECDH computation has not finished yet. Adding usedId=${userId} to the decryption queue.`
        );

      this.pendingDecryption.set(userId, data);

      return;
    }

    logger
      .remote({ system: true, capture: 'e2ee' })
      .debug(`Storing remote encryption data of the userId=${userId}`);

    if (this.pendingDecryption.has(userId)) {
      this.pendingDecryption.delete(userId);
    }

    if (!receiver.sharedSecret) {
      throw new Error('Cannot decrypt a remote encryption data. Shared secret is not defined');
    }

    if (data.streamKey) {
      const streamKey = await base64Decrypt(data.streamKey, receiver.sharedSecret);
      receiver.setStreamKey(streamKey);

      store.dispatch(remoteStreamSecretDecrypted(userId));
    }

    if (data.applicationKey) {
      const applicationKey = await base64Decrypt(data.applicationKey, receiver.sharedSecret);
      await receiver.setApplicationKey(applicationKey);
    }

    if (!receiver.name && data.name) {
      const name = await e2eeDecrypt(data.name, receiver.sharedSecret);
      receiver.setName(name);

      store.dispatch(userNameDecrypted({ id: receiver.id, name }));
    }
  };

  cleanup = () => {
    logger.remote({ system: true, capture: 'e2ee' }).info('Cleaning E2EE keys');

    this.receivers.clear();
    this.pendingDecryption.clear();
    this.pendingActions.clear();
  };

  removeReceiver = (userId: UserId) => {
    this.receivers.delete(userId);
    this.pendingDecryption.delete(userId);
    this.pendingActions.delete(userId);
  };

  getReceiver = (userId: UserId) => this.receivers.get(userId);

  enqueuePendingAction = (action: PayloadAction<any>) => {
    const initiatorId = action.payload.initiator?.id || action.payload.id;

    const receiver = this.getReceiver(initiatorId);
    if (!receiver?.name) {
      logger
        .remote({ system: true, capture: 'e2ee' })
        .debug(`Receiver's name is not known yet. Putting the action=${action.type} to the queue`);
      const pendingActions = this.pendingActions.get(initiatorId);

      if (!pendingActions) {
        this.pendingActions.set(initiatorId, new Map([[action.type, action]]));
      } else {
        pendingActions.set(action.type, action);
      }

      return true;
    }

    return false;
  };

  releasePendingActions = (id: UserId) => {
    const pendingActions = this.pendingActions.get(id);
    if (pendingActions) {
      const queue = new Map(pendingActions);
      queue.forEach((action, actionType) => {
        logger
          .remote({ system: true, capture: 'e2ee' })
          .debug(`Releasing pending action=${action.type} of a receiverId=${id}`);

        store.dispatch(action);

        pendingActions.delete(actionType);
      });
    }
  };
}

export const E2EEManager = singleton<E2EE>(() => new E2EE());

// @TODO FIXME remove after testing
if (canExposeInternals()) {
  window.E2EE = E2EEManager;
}
