import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import { DialogContent, DialogContentText } from '@mui/material';
import { Dialog, DialogTitle } from 'components/Dialog';

const RecordingFailureModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('modals');

  const hideModal = () => dispatch(closeModal('recordingFailure'));

  return (
    <Dialog
      open
      aria-labelledby="recording-failure-title"
      aria-describedby="recording-failure-description"
      onClose={hideModal}
      maxWidth="xs"
    >
      <DialogTitle id="recording-failure-title" onClose={hideModal}>
        {t('recording_failure.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="recording-failure-description">
          {t('recording_failure.description')}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default RecordingFailureModal;
