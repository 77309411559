import { Dialog, DialogActions, DialogContent, DialogTitle } from 'components/Dialog';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import { closeModal, selectActiveModalData } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import React, { useEffect, useId } from 'react';
import { usePolls } from 'features/polls/usePolls';
import PollForm, { PollFormValues } from 'features/polls/components/PollForm';
import { selectPoll } from 'features/polls/pollsSlice';
import { editPollRequested } from 'features/polls/actions';

const EditPollModal = () => {
  const { t } = useTranslation(['modals', 'polls']);
  const dispatch = useAppDispatch();

  const modalId = useId();

  const modalData = useAppSelector(selectActiveModalData);
  const pollId = modalData.id as string;

  const { isFeatureAvailable, canManagePolls } = usePolls();

  const poll = useAppSelector((state) => selectPoll(state, pollId));

  const methods = useForm<PollFormValues>({
    mode: 'onChange',
    defaultValues: {
      question: poll?.question || '',
      choices: poll?.choices || [],
      multipleChoices: poll?.multipleChoices || false,
      anonymous: poll?.anonymous || false,
    },
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const modalShouldBeClosed = !isFeatureAvailable || !canManagePolls || !poll?.editable;

  useEffect(() => {
    if (modalShouldBeClosed) {
      dispatch(closeModal('editPoll'));
    }
  }, [modalShouldBeClosed, dispatch]);

  const editPoll: SubmitHandler<PollFormValues> = (data) => {
    dispatch(
      editPollRequested({
        ...data,
        id: pollId,
        choices: data.choices.map((choice) => choice.text),
      })
    );
    dispatch(closeModal('editPoll'));
  };

  const handleCancel = () => {
    dispatch(closeModal('editPoll'));
  };

  return (
    <Dialog
      open={!modalShouldBeClosed}
      maxWidth="xs"
      aria-labelledby={`${modalId}-title`}
      onClose={handleCancel}
      scroll="paper"
      disableRestoreFocus
    >
      <DialogTitle id={`${modalId}-title`}>{t('polls.edit_poll.title')}</DialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          <PollForm />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button type="button" color="grayscale" onClick={handleCancel}>
          {t('polls.edit_poll.btn_cancel_label')}
        </Button>
        <Button type="button" onClick={handleSubmit(editPoll)} disabled={!isValid}>
          {t('polls.edit_poll.btn_ok_label')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPollModal;
