import { PayloadAction } from '@reduxjs/toolkit';
import { findContentForStageSaga } from 'features/layout/sagas/findContentForStageSaga';
import { broadcasterLimitReachedNotificationUpdateRequested } from 'features/notifications/actions';
import { UserId } from 'features/users/types';
import { selectLocalUserId } from 'features/users/usersSlice';
import { call, put, select } from 'redux-saga/effects';
import { RTCClient } from 'utils/webrtc';
import { StreamingStarted } from '../types';

export function* onStreamingStartSaga(action: PayloadAction<StreamingStarted>) {
  const { id: userId, feedId, streams } = action.payload;
  const localUserId: UserId = yield select(selectLocalUserId);

  yield call(RTCClient.receivingFeed.addAvailableStreams, feedId, streams);

  yield call(RTCClient.setBroadcastingState, userId, streams);

  yield call(RTCClient.subscribe, action.payload);

  // @TODO: we then call this second time when order changes, which may be too much.
  // Think about better solutions
  yield call(RTCClient.receivingFeed.updateSimulcastConfig);

  if (userId !== localUserId) {
    yield put(broadcasterLimitReachedNotificationUpdateRequested());
  }

  yield call(findContentForStageSaga);
}
