import { call, put, select } from 'redux-saga/effects';
import { downloadFile } from 'utils/http/downloadFile';
import { AxiosResponse } from 'axios';
import { selectRoomId } from 'features/room/roomSlice';
import { exportPollResultsFulfilled, exportPollResultsRejected } from 'features/polls/pollsSlice';
import { exportPollResultsService } from 'features/polls/services';
import * as Sentry from '@sentry/react';

export function* exportPollsSaga() {
  try {
    const roomId: string = yield select(selectRoomId);

    const response: AxiosResponse<Blob> = yield call(exportPollResultsService, roomId);
    const contentDisposition = response.headers['content-disposition'];

    yield call(downloadFile, response.data, contentDisposition);

    yield put(exportPollResultsFulfilled());
  } catch (error) {
    yield put(exportPollResultsRejected());
    Sentry.captureException(error);
  }
}
