import cx from 'clsx';
import { ZenModeTooltip } from 'components/ZenModeTooltip';
import { StreamControlButton } from 'features/streaming/components/StreamControlButton';
import { useAppSelector } from 'store/hooks';
import { selectIsActiveSpeaker } from 'features/streaming/streamingSlice';
import { UserId } from 'features/users/types';
import { useTranslation } from 'react-i18next';
import { useRemoteAudioMute } from 'hooks/useRemoteAudioMute';

export type MicControlRemoteProps = {
  userId: UserId;
};

export const MicControlRemote = ({ userId }: MicControlRemoteProps) => {
  const { t } = useTranslation('room');

  const activeSpeaker = useAppSelector((state) => selectIsActiveSpeaker(state, userId));
  const { isAudioBroadcasting, toggleMicRemotely, canRemotelyMute, canAskToUnmute } =
    useRemoteAudioMute(userId);

  const color = () => {
    if (activeSpeaker && isAudioBroadcasting) {
      return '#00ff00';
    }

    return 'common.white';
  };

  const renderTooltipText = () => {
    if (isAudioBroadcasting) {
      return t('tooltips.mute_remote_audio');
    }

    return t('tooltips.unmute_remote_audio');
  };

  const icon = isAudioBroadcasting ? 'mic' : 'mute';

  const canInteract = canRemotelyMute || canAskToUnmute;

  return (
    <ZenModeTooltip title={renderTooltipText()} placement="top">
      <StreamControlButton
        icon={icon}
        className={cx({ 'hover-child-eager': !(activeSpeaker && isAudioBroadcasting) })}
        sx={{
          color,
        }}
        tabIndex={canInteract ? 0 : -1}
        disableInteraction={!canInteract}
        onClick={toggleMicRemotely}
      />
    </ZenModeTooltip>
  );
};
