import { useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import {
  selectMediaDeviceByKind,
  selectMediaDevicePermissions,
} from 'features/user-media/userMediaSlice';
import { useTranslation } from 'react-i18next';

export const useVideoInputField = () => {
  const { t } = useTranslation('settings');
  const mediaDevices = useAppSelector((state) => selectMediaDeviceByKind(state, 'videoinput'));
  const mediaDevicePermissions = useAppSelector((state) =>
    selectMediaDevicePermissions(state, 'videoinput')
  );

  const getPlaceholder = () => {
    if (!mediaDevices.length || mediaDevicePermissions === 'not-found') {
      return t('device_dropdowns.videoinput.placeholder_device_not_found');
    }

    if (mediaDevicePermissions === 'denied') {
      return t('device_dropdowns.videoinput.placeholder_permissions_denied');
    }

    return undefined;
  };

  const videoInputOptions = useMemo(() => {
    if (mediaDevicePermissions !== 'granted' || !mediaDevices.length) {
      return [];
    }

    return mediaDevices.map((device) => ({ value: device.deviceId, label: device.label }));
  }, [mediaDevices, mediaDevicePermissions]);

  return {
    videoInputOptions,
    videoInputPlaceholder: getPlaceholder(),
  };
};
