import { logger } from 'utils/logger';

export const getDisplayMedia = async () => {
  const constraints = {
    audio: true,
    video: {
      width: { max: 1920 },
      height: { max: 1080 },
    },
  };

  logger.remote({ tier: 1 }).info('Requesting display media', constraints);

  const stream = await navigator.mediaDevices.getDisplayMedia(constraints);

  const tracks = stream.getTracks();

  tracks.forEach((track) => {
    logger.remote({ tier: 1 }).info(`${track.kind} track settings:`, track.getSettings());
    logger.remote({ tier: 1 }).info(`${track.kind} track constraints:`, track.getConstraints());
  });

  return stream;
};
