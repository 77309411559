import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { startTranscriptionRequested } from 'features/transcripts/actions';
import { useTranscription } from 'features/transcripts/slices/transcription/useTranscription';

const StartTranscriptionConfirmation = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const { transcriptionStarted, transcriptionAvailable, canTranscribeSession } = useTranscription();

  const modalShouldBeClosed =
    !transcriptionAvailable || !canTranscribeSession || transcriptionStarted;

  useEffect(() => {
    if (modalShouldBeClosed) {
      dispatch(closeModal('startTranscriptionConfirmation'));
    }
  }, [modalShouldBeClosed, dispatch]);

  const handleOk = () => {
    dispatch(startTranscriptionRequested());
    dispatch(closeModal('startTranscriptionConfirmation'));
  };

  const handleCancel = () => {
    dispatch(closeModal('startTranscriptionConfirmation'));
  };

  return (
    <ConfirmationDialog
      open={!modalShouldBeClosed}
      title={t('start_transcription_confirmation.title')}
      description={t('start_transcription_confirmation.description')}
      cancelText={t('start_transcription_confirmation.btn_cancel_label')}
      okText={t('start_transcription_confirmation.btn_ok_label')}
      onOk={handleOk}
      onCancel={handleCancel}
      hideCloseButton
    />
  );
};

export default StartTranscriptionConfirmation;
