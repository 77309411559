import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal } from 'features/modal/modalSlice';
import { Trans, useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, DialogContent } from 'components/Dialog';
import { selectMediaDevicePermissions } from 'features/user-media/userMediaSlice';
import getBrowserPermissionsDocLink from 'utils/getBrowserPermissionsDocLink';
import { DialogContentText } from '@mui/material';

const VideoPermissionsError = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('modals');

  const camPermissions = useAppSelector((state) =>
    selectMediaDevicePermissions(state, 'videoinput')
  );

  const hideModal = () => dispatch(closeModal('videoPermissionsError'));

  const renderTitle = () => {
    if (camPermissions === 'denied') {
      return t('camera_blocked.title');
    }

    return t('camera_unavailable.title');
  };

  const renderContent = () => {
    if (camPermissions === 'denied') {
      return (
        <Trans
          i18nKey="modals:camera_blocked.description"
          t={t}
          components={[
            // eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content
            <a href={getBrowserPermissionsDocLink()} target="_blank" rel="noopener noreferrer" />,
          ]}
        />
      );
    }

    return t('camera_unavailable.description');
  };

  return (
    <Dialog open aria-labelledby="video-error-dialog" onClose={hideModal} maxWidth="xs">
      <DialogTitle id="video-error-dialog" onClose={hideModal}>
        {renderTitle()}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{renderContent()}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default VideoPermissionsError;
