import { PayloadAction } from '@reduxjs/toolkit';
import { UserMediaFailurePayload } from 'features/user-media/types';
import { call } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';

export function* onUserMediaFailedCommandSaga(action: PayloadAction<UserMediaFailurePayload>) {
  const { kind, value } = action.payload;

  if (value === 'denied') {
    yield call(eventBus.sendMessage, 'mediaPermissionsFailed', {}, { kind });
  }
}
