import { call, put } from 'redux-saga/effects';
import { FeedMediaStates, RTCClient } from 'utils/webrtc';
import { interactionTriggered } from 'features/application/actions';

export function* toggleLocalAudioSaga() {
  const mediaStates: FeedMediaStates = yield call(RTCClient.publishingFeed.getMediaStates);
  if (mediaStates.audio.broadcasting) {
    yield put(interactionTriggered('muteAudio'));
  } else {
    yield put(interactionTriggered('unmuteAudio'));
  }

  yield call(RTCClient.publishingFeed.toggleAudioBroadcasting);
}
