import { selectFeatureFlag } from 'features/feature-set/featuresSlice';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { raiseHand } from 'features/users/usersSlice';
import { call, put, select } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';

export function* onRaiseHandCommandSaga() {
  const controlEnabled: boolean = yield select(selectFeatureFlag, 'raiseHand');
  const canRaiseHand: boolean = yield call(hasPermissionsSaga, PermissionTypes.raiseHand);

  if (!controlEnabled) {
    yield call(
      eventBus.notAllowedError,
      'This action is disabled. You’ll need to edit this room’s properties to enable it.',
      'raise-hand'
    );

    return;
  }

  if (!canRaiseHand) {
    yield call(
      eventBus.forbiddenActionError,
      'Forbidden action. This participant is not allowed to raise hand',
      'raise-hand'
    );

    return;
  }

  yield put(raiseHand());
}
