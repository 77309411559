import { signalingWaitingUsersUpdated } from 'features/users/actions';
import { call, put, select } from 'redux-saga/effects';
import { bulkComputeEncryptionKeysSaga } from 'features/e2ee/sagas/bulkComputeEncryptionKeysSaga';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { waitingEntryNotificationUpdateRequested } from 'features/notifications/actions';

export function* onWaitingUsersUpdatedSaga(
  action: ReturnType<typeof signalingWaitingUsersUpdated>
) {
  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
  if (e2eeEnabled) {
    yield call(bulkComputeEncryptionKeysSaga, action.payload);
  }

  yield put(waitingEntryNotificationUpdateRequested());
}
