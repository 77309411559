import React from 'react';
import ToolbarMenuItem from 'features/toolbar/ToolbarMenuItem/ToolbarMenuItem';
import { ToolbarMenuItemWrapperProps } from 'features/toolbar/types';
import { useRaiseHandControl } from 'features/toolbar/Toolbar/controls/raise-hand/useRaiseHandControl';

const RaiseHandControl = ({ onClick }: ToolbarMenuItemWrapperProps) => {
  const { toggleControl, icon, label, active, isFeatureAvailable } = useRaiseHandControl();

  if (!isFeatureAvailable) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(event);
    toggleControl();
  };

  return (
    <ToolbarMenuItem
      active={active}
      id="raise-hand"
      overlay={label}
      ariaLabel={label}
      icon={icon}
      onClick={handleClick}
    />
  );
};

export default RaiseHandControl;
