import {
  selectBreakoutsSplashOpenedAt,
  splashScreenClosed,
} from 'features/breakout-rooms/breakoutRoomsSlice';
import { put, select, delay } from 'redux-saga/effects';

const SPLASH_WAIT_MS = 2500;

export function* onSplashScreenCloseRequestedSaga() {
  const openedAt: number = yield select(selectBreakoutsSplashOpenedAt);
  const elapsed = Number(new Date()) - openedAt;

  if (elapsed < SPLASH_WAIT_MS) {
    yield delay(SPLASH_WAIT_MS - elapsed);
  }

  yield put(splashScreenClosed());
}
