import React, { useEffect, useId } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'components/Dialog';
import { Box, Button, FormControl, FormHelperText } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Input from 'components/Input';
import { useContentLibrary } from 'features/content-library/useContentLibrary';
import { createEmbedContentRequested } from 'features/content-library/actions';
import { CreateContentLibraryEmbedPayload } from 'features/content-library/types';
import { selectActiveFolderId } from 'features/content-library/contentLibrarySlice';

export interface CreateEmbedContentLibraryContentValues {
  embedName: string;
  url: string;
}

const validateUrl = (value: string) => {
  const urlPattern = /^https?:\/\/[^\s/$.?#].\S*$/i;
  return urlPattern.test(value.trim());
};

const CreateEmbedContentLibraryContentModal = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const modalId = useId();

  const activeFolderId = useAppSelector(selectActiveFolderId);

  const { isFeatureAvailable } = useContentLibrary();

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    setFocus,
  } = useForm<CreateEmbedContentLibraryContentValues>({
    mode: 'onChange',
    defaultValues: {
      embedName: '',
      url: '',
    },
  });

  const shouldClose = !isFeatureAvailable;

  useEffect(() => {
    if (shouldClose) {
      dispatch(closeModal('createEmbedContentLibraryContent'));
    }
  }, [shouldClose, dispatch]);

  useEffect(() => {
    setTimeout(() => {
      setFocus('embedName');
    }, 0);
  }, [setFocus]);

  const handleCancel = () => {
    dispatch(closeModal('createEmbedContentLibraryContent'));
  };

  const handleOk: SubmitHandler<CreateEmbedContentLibraryContentValues> = (data) => {
    const payload: CreateContentLibraryEmbedPayload = {
      name: data.embedName.trim(),
      url: data.url.trim(),
      folderId: activeFolderId === 'root' ? undefined : activeFolderId,
    };

    dispatch(createEmbedContentRequested(payload));
    dispatch(closeModal('createEmbedContentLibraryContent'));
  };

  return (
    <Dialog
      open={!shouldClose}
      maxWidth="xs"
      aria-labelledby={`${modalId}-title`}
      aria-describedby={`${modalId}-description`}
      onClose={handleCancel}
    >
      <DialogTitle id={`${modalId}-title`}>{t('content_library.add_embed.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText id={`${modalId}-description`}>
          {t('content_library.add_embed.description')}
        </DialogContentText>
        <Box
          sx={{
            mt: 6,
          }}
        >
          <FormControl
            fullWidth
            error={!!errors.embedName}
            sx={{
              mb: 6,
            }}
          >
            <Controller
              name="embedName"
              control={control}
              rules={{
                required: t('common:validation.field_required'),
                validate: (value) => !!value.trim() || t('common:validation.field_required'),
              }}
              render={({ field: { ref, ...field } }) => (
                <Input
                  {...field}
                  inputProps={{
                    'aria-label': t('content_library.add_embed.input_name_placeholder'),
                  }}
                  placeholder={t('content_library.add_embed.input_name_placeholder')}
                  autoComplete="off"
                  inputRef={ref}
                />
              )}
            />
            <FormHelperText>{errors.embedName?.message}</FormHelperText>
          </FormControl>
          <FormControl fullWidth error={!!errors.url}>
            <Controller
              name="url"
              control={control}
              rules={{
                required: t('common:validation.field_required'),
                validate: {
                  nonEmpty: (value) => !!value.trim() || t('common:validation.field_required'),
                  validUrl: (value) =>
                    validateUrl(value) ||
                    t('content_library.add_embed.invalid_url_validation_error'),
                },
              }}
              render={({ field: { ref, ...field } }) => (
                <Input
                  {...field}
                  inputProps={{
                    'aria-label': t('content_library.add_embed.input_link_placeholder'),
                  }}
                  placeholder={t('content_library.add_embed.input_link_placeholder')}
                  autoComplete="off"
                  inputRef={ref}
                />
              )}
            />
            <FormHelperText>{errors.url?.message}</FormHelperText>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button type="button" color="grayscale" onClick={handleCancel}>
          {t('content_library.add_embed.btn_cancel_label')}
        </Button>
        <Button type="button" onClick={handleSubmit(handleOk)} disabled={!isValid}>
          {t('content_library.add_embed.btn_ok_label')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEmbedContentLibraryContentModal;
