import DevicesError from 'features/join/publisher/DevicesError';
import JoinPreview, { PreviewProps } from 'features/join/publisher/JoinPreview';
import { JoinScreenWrapper } from 'features/join/styles';
import Intro from 'features/join/shared/Intro';
import { BroadcastMediaStatus } from 'utils/broadcast-setup';

type DevicesSelectionProps = {
  joinWithoutDevices: () => void;
  status: BroadcastMediaStatus;
} & PreviewProps;

const JoinPublisherLayout = ({ status, joinWithoutDevices, ...props }: DevicesSelectionProps) => (
  <JoinScreenWrapper>
    {status === 'prompt' && <Intro />}
    {(status === 'failure' || status === 'denied') && (
      <DevicesError joinWithoutDevices={joinWithoutDevices} status={status} />
    )}
    {status === 'success' && <JoinPreview {...props} />}
  </JoinScreenWrapper>
);
export default JoinPublisherLayout;
