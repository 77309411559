import { useCallback, useRef, useState } from 'react';
import { SingularUserMedia, useSingularUserMedia } from 'hooks/useSingularUserMedia';
import { stopStreamTracks } from 'features/user-media/utils';
import { logger } from 'utils/logger';

export const useTemporaryMedia = () => {
  const [loading, setLoading] = useState(false);
  const mediaStream = useRef<MediaStream | null>(null);
  const requestSingularUserMedia = useSingularUserMedia();

  const requestTemporaryMedia = useCallback(
    async (constraints: SingularUserMedia) => {
      if (loading) {
        return;
      }

      logger.log('Requesting temporal media...', constraints);

      setLoading(true);

      const { stream } = await requestSingularUserMedia(constraints, {
        updateActiveDevice: false,
      });

      mediaStream.current = stream;
      setLoading(false);
    },
    [loading, requestSingularUserMedia]
  );

  const cleanupTemporaryMedia = useCallback(() => {
    if (mediaStream.current) {
      stopStreamTracks(mediaStream.current);
      mediaStream.current = null;
    }
  }, []);

  return {
    loading,
    mediaStream: mediaStream.current,
    requestTemporaryMedia,
    cleanupTemporaryMedia,
  };
};
