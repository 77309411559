import LocalVideoStream from 'features/streaming/components/media/LocalVideoStream';
import React from 'react';
import StreamMedia from 'features/streaming/components/stream-display/StreamMedia';
import { StreamingMediaProps } from 'features/streaming/types';
import { useAppSelector } from 'store/hooks';
import {
  selectLocalFeedStreams,
  selectLocalVideoBroadcasting,
} from 'features/streaming/streamingSlice';

const LocalMedia = ({ source, avatarSize }: StreamingMediaProps) => {
  const streams = useAppSelector(selectLocalFeedStreams);
  const videoBroadcasting = useAppSelector(selectLocalVideoBroadcasting);

  return (
    <StreamMedia source={source} avatarSize={avatarSize}>
      {streams.map((stream) =>
        stream.kind === 'video' && videoBroadcasting ? (
          <LocalVideoStream mode="media" id={stream.id} key={stream.id} />
        ) : null
      )}
    </StreamMedia>
  );
};

export default LocalMedia;
