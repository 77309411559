import NotificationPanel from 'features/notifications/components/NotificationPanel';
import { ToastContainer } from 'features/notifications/toast/ToastContainer';
import { advancedToastTransition } from 'features/notifications/toast/transitions';
import { ACTION_NOTIFICATION_CONTAINER_ID } from 'features/notifications/constants';
import React from 'react';
import { useAppSelector } from 'store/hooks';
import { selectToolbarPlacement } from 'features/toolbar/toolbarSlice';
import { selectActionNotificationPanel } from 'features/notifications/notificationsSlice';
import { Fade } from '@mui/material';

const ActionNotificationContainer = () => {
  const toolbarPlacement = useAppSelector(selectToolbarPlacement);
  const notificationPanelOpened = useAppSelector(selectActionNotificationPanel);

  return (
    <>
      <Fade in={notificationPanelOpened} unmountOnExit mountOnEnter>
        <NotificationPanel />
      </Fade>
      <ToastContainer
        colorTheme="light"
        placement={toolbarPlacement}
        transition={advancedToastTransition}
        position="top-left"
        autoClose={false}
        hideProgressBar
        newestOnTop
        pauseOnFocusLoss={false}
        draggable={false}
        closeButton={false}
        toastClassName="action-toast"
        className="action-toast-container"
        containerId={ACTION_NOTIFICATION_CONTAINER_ID}
      />
    </>
  );
};

export default ActionNotificationContainer;
