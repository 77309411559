import { useAudioOutputField } from 'hooks/useAudioOutputField';
import { useAudioInputField } from 'hooks/useAudioInputField';
import DeviceTest from 'components/DeviceTest';
import { MediaDeviceChangeHandler } from 'features/join/publisher/JoinPreview';
import { useSpeakersTest } from 'hooks/useSpeakersTest';
import { useAudioTest } from 'hooks/useAudioTest';
import { Control } from 'react-hook-form/dist/types/form';
import DeviceSelect from 'components/DeviceSelect';
import VUMeter from 'components/VUMeter';
import { useTranslation } from 'react-i18next';
import { TabPanelInner } from 'components/Tabs';
import { Box } from '@mui/material';
import { useAppSelector } from 'store/hooks';
import { selectMediaDevicePermissions } from 'features/user-media/userMediaSlice';
import React from 'react';

export type AudioTabProps = {
  onMicChange: MediaDeviceChangeHandler;
  onOutputChange: MediaDeviceChangeHandler;
  control: Control<any>;
  audioStream: MediaStream | null;
  allowBroadcast: boolean;
  vuMeterActive: boolean;
  micDisabled?: boolean;
  micToggle?: React.ReactNode;
};

const AudioTab = ({
  micDisabled,
  control,
  onMicChange,
  onOutputChange,
  audioStream,
  allowBroadcast,
  vuMeterActive,
  micToggle,
}: AudioTabProps) => {
  const { t } = useTranslation(['settings', 'common']);

  const micPermissions = useAppSelector((state) =>
    selectMediaDevicePermissions(state, 'audioinput')
  );

  const { audioOutputDisabled, audioOutputOptions, audioOutputPlaceholder } = useAudioOutputField({
    micDisabled,
  });

  const { audioInputPlaceholder, audioInputOptions } = useAudioInputField();
  const { status: speakersTestStatus, playTestSound } = useSpeakersTest();
  const { status: audioTestStatus, testAudio, countdown } = useAudioTest();

  const renderAudioTestLabel = () => {
    if (audioTestStatus === 'processing') {
      return t('device_test.microphone.btn_label_processing', {
        count: countdown,
      });
    }

    if (audioTestStatus === 'success') {
      return t('device_test.microphone.btn_label_success');
    }

    return t('device_test.microphone.btn_label_default');
  };

  const renderSpeakerTestLabel = () => {
    if (speakersTestStatus === 'processing') {
      return t('device_test.speakers.btn_label_processing');
    }
    if (speakersTestStatus === 'loading') {
      return t('device_test.speakers.btn_label_loading');
    }

    return t('device_test.speakers.btn_label_default');
  };

  const handleAudioTest = () => {
    if (audioStream) {
      testAudio(audioStream);
    }
  };

  return (
    <TabPanelInner>
      {micToggle && (
        <Box
          sx={{
            mb: 5,
          }}
        >
          {micToggle}
        </Box>
      )}
      {allowBroadcast && (
        <Box mb={3} mt={1}>
          <Box mb={3}>
            <DeviceSelect
              id="settings-mic-select"
              name="audioinput"
              control={control}
              icon="mic"
              onChange={onMicChange}
              options={audioInputOptions}
              disabled={micDisabled}
              placeholder={audioInputPlaceholder}
              ariaLabel={t('device_dropdowns.audioinput.aria_label')}
            />
          </Box>
          <VUMeter
            on={vuMeterActive}
            stream={audioStream}
            label={t('common:vumeter.vumeter_label')}
            sx={{ mb: 3 }}
          />
          {micPermissions === 'granted' && (
            <DeviceTest
              tooltip={t('device_test.microphone.tooltip_text')}
              id="settings-microphone-test"
              helpText={t('device_test.microphone.description')}
              iconLabel={t('device_test.microphone.icon_aria_label')}
              onClick={handleAudioTest}
              processing={audioTestStatus === 'processing' || audioTestStatus === 'success'}
            >
              {renderAudioTestLabel()}
            </DeviceTest>
          )}
        </Box>
      )}
      <div>
        <Box mb={3}>
          <DeviceSelect
            id="settings-speakers-select"
            name="audiooutput"
            control={control}
            icon="speakers"
            disabled={audioOutputDisabled}
            placeholder={audioOutputPlaceholder}
            options={audioOutputOptions}
            onChange={onOutputChange}
            ariaLabel={t('device_dropdowns.audiooutput.aria_label')}
          />
        </Box>
        <DeviceTest
          tooltip={t('device_test.speakers.tooltip_text')}
          id="settings-speakers-test"
          helpText={t('device_test.speakers.description')}
          iconLabel={t('device_test.speakers.icon_aria_label')}
          processing={speakersTestStatus === 'processing'}
          onClick={playTestSound}
        >
          {renderSpeakerTestLabel()}
        </DeviceTest>
      </div>
    </TabPanelInner>
  );
};

export default AudioTab;
