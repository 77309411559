import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import { usePermissions } from 'features/permissions/usePermissions';
import { PermissionTypes } from 'features/permissions/types';
import { useEffect } from 'react';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { bulkAdmitEntry } from 'features/users/actions';

const AdmitAllWaitingParticipants = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const { hasPermissions } = usePermissions();

  const canControlRoomEntry = hasPermissions(PermissionTypes.controlRoomEntry);

  useEffect(() => {
    if (!canControlRoomEntry) {
      dispatch(closeModal('admitAllWaitingParticipants'));
    }
  }, [canControlRoomEntry, dispatch]);

  const handleOk = () => {
    dispatch(bulkAdmitEntry());
    dispatch(closeModal('admitAllWaitingParticipants'));
  };

  const handleCancel = () => {
    dispatch(closeModal('admitAllWaitingParticipants'));
  };

  return (
    <ConfirmationDialog
      open
      title={t('admit_all_waiting_participants_confirmation.title')}
      description={t('admit_all_waiting_participants_confirmation.description')}
      cancelText={t('admit_all_waiting_participants_confirmation.btn_cancel_label')}
      okText={t('admit_all_waiting_participants_confirmation.btn_ok_label')}
      onOk={handleOk}
      onCancel={handleCancel}
    />
  );
};

export default AdmitAllWaitingParticipants;
