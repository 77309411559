import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Portal } from '@mui/base';
import { Box, CircularProgress, Typography } from '@mui/material';
import BrandedLayout from 'layouts/BrandedLayout';

const Root = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: theme.zIndex.tooltip + 1,
  backgroundColor: theme.palette.background.default,
}));

type BreakoutsSplashScreenProps = {
  children?: React.ReactNode;
  title?: string;
  disableSpinner?: boolean;
};

const BreakoutsSplashScreen = ({ title, children, disableSpinner }: BreakoutsSplashScreenProps) => {
  useEffect(() => {
    const disableKeyboard = (e: Event) => {
      e.preventDefault();
    };

    document.addEventListener('keydown', disableKeyboard);

    return () => {
      document.removeEventListener('keydown', disableKeyboard);
    };
  }, []);

  return (
    <Portal>
      <Root>
        <BrandedLayout>
          <Box
            sx={{
              textAlign: 'center',
            }}
          >
            {title && (
              <Typography variant="h3" mb={3}>
                {title}
              </Typography>
            )}
            {typeof children === 'string' ? <Typography>{children}</Typography> : children}
            {disableSpinner ? null : <CircularProgress sx={{ mt: 4 }} disableShrink />}
          </Box>
        </BrandedLayout>
      </Root>
    </Portal>
  );
};

export default BreakoutsSplashScreen;
