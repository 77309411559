import { useAppSelector } from 'store/hooks';
import { selectLocalStreamMinimized } from 'features/layout/features/config/configSlice';
import { MinimizedLocalStream } from 'features/streaming/components/stream-display/MinimizedLocalStream';
import React from 'react';
import { usePermissions } from 'features/permissions/usePermissions';
import { PermissionTypes } from 'features/permissions/types';

const MinimizedLocalTile = () => {
  const localStreamMinimized = useAppSelector(selectLocalStreamMinimized);

  const { hasPermissions } = usePermissions();

  const canBroadcast = hasPermissions(PermissionTypes.broadcast);

  const featureAvailable = localStreamMinimized && canBroadcast;
  if (!featureAvailable) {
    return null;
  }

  return <MinimizedLocalStream />;
};

export default MinimizedLocalTile;
