import React, { useState } from 'react';
import MobileMenuItem from 'features/toolbar/Toolbar/mobile/MobileMenuItem';
import { MobileMenuItemWrapperProps } from 'features/toolbar/types';
import { useTranscriptionControl } from 'features/toolbar/Toolbar/controls/transcription/useTranscriptionControl';
import TranscriptionOptionsDialog from 'features/toolbar/Toolbar/controls/transcription/TranscriptionOptionsDialog';

const TranscriptionControlMobile = ({ onClick }: MobileMenuItemWrapperProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const {
    label,
    isFeatureAvailable,
    captionsAvailable,
    canTranscribeSession,
    icon,
    transcriptionStarted,
    active,
    toggleControl,
  } = useTranscriptionControl();

  if (!isFeatureAvailable) {
    return null;
  }

  if (!canTranscribeSession && !captionsAvailable) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (canTranscribeSession && transcriptionStarted) {
      setDialogOpen(true);
    } else {
      onClick?.(event);
      toggleControl();
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <MobileMenuItem id="transcription" selected={active} icon={icon} onClick={handleClick}>
        {label}
      </MobileMenuItem>
      <TranscriptionOptionsDialog open={dialogOpen} onClose={handleClose} />
    </>
  );
};

export default TranscriptionControlMobile;
