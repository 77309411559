import { Typography, TypographyProps } from '@mui/material';

type TitleProps = TypographyProps & {
  children: string;
};

const Title = ({ children, variant = 'h3', ...props }: TitleProps) => (
  <Typography variant={variant} sx={{ mb: 5 }} {...props}>
    {children}
  </Typography>
);

export default Title;
