import { createSelector } from '@reduxjs/toolkit';
import { selectHasPermissions } from 'features/permissions/permissionsSlice';
import { PermissionTypes } from 'features/permissions/types';
import { selectAwaitingEntryIds, selectUserEntries } from 'features/users/usersSlice';
import { getAwaitingEntryUsers } from 'features/users/utils';

export const selectAwaitingEntryUsers = createSelector(
  [
    selectUserEntries,
    selectAwaitingEntryIds,
    (state) => selectHasPermissions(state, PermissionTypes.controlRoomEntry),
  ],
  (userEntries, awaitingIds, canControlRoomEntry) =>
    getAwaitingEntryUsers({
      userEntries,
      awaitingIds,
      canControlRoomEntry,
    })
);
