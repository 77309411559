import { selectDeviceInfo, selectDeviceType } from 'features/application/applicationSlice';
import { select } from 'redux-saga/effects';
import UAParser from 'ua-parser-js';
import { logger } from 'utils/logger';

export function* logEnvSaga() {
  const deviceInfo: UAParser.IResult = yield select(selectDeviceInfo);
  const deviceType: string = yield select(selectDeviceType);
  if (deviceInfo) {
    logger
      .remote()
      .info(
        `Browser: ${deviceInfo.browser.name} ${deviceInfo.browser.version}, ${deviceInfo.os.name}. Platform: ${deviceType}, ${window.innerWidth}x${window.innerHeight}`
      );
  }
}
