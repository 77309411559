import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export const criticalNamespaces = ['common', 'join', 'waiting-screens', 'room', 'notifications'];

export const lazyNamespaces = [
  'settings',
  'modals',
  'participants',
  'chat',
  'breakout-rooms',
  'qa',
  'polls',
  'content-library',
];

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    returnNull: false,
    resources: {},
    fallbackLng: 'en',
    debug: false,
    load: 'currentOnly',
    defaultNS: 'common',
    lng: process.env.NODE_ENV === 'test' ? 'cimode' : undefined,
    appendNamespaceToCIMode: true,
    partialBundledLanguages: true,
    ns: [],
    interpolation: {
      escapeValue: false,
    },

    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      addPath: '/locales/add/{{lng}}/{{ns}}',
      requestOptions: {
        mode: 'cors',
        credentials: 'same-origin',
        cache: 'no-cache',
      },
    },
  });

export default i18n;
