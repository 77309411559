import { useAppSelector } from 'store/hooks';
import { selectUserCount } from 'features/users/usersSlice';
import { useToolbarPanel } from 'hooks/useToolbarPanel';
import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { usePermissions } from 'features/permissions/usePermissions';
import { PermissionTypes } from 'features/permissions/types';

export const useParticipantsControl = () => {
  const userCount = useAppSelector(selectUserCount);

  const { toolbarPanel, togglePanel } = useToolbarPanel();

  const { hasPermissions } = usePermissions();
  const isFeatureEnabled = useFeatureFlag('participantsList');

  const permissionsGranted = hasPermissions(PermissionTypes.seeParticipantsPanel);
  const isFeatureAvailable = isFeatureEnabled && permissionsGranted;

  const toggleParticipantsPanel = () => {
    togglePanel('users');
  };

  return {
    active: toolbarPanel === 'users',
    totalParticipants: userCount,
    togglePanel: toggleParticipantsPanel,
    icon: 'participants',
    isFeatureAvailable,
  };
};
