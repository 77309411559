import React, { forwardRef } from 'react';
import { IconButton, IconButtonProps } from '@mui/material';
import Icon from 'components/Icon';
import cx from 'clsx';

export interface ToolbarPanelActionProps extends IconButtonProps {
  icon: string;
}

const ToolbarPanelAction = forwardRef<HTMLButtonElement, ToolbarPanelActionProps>(
  ({ icon, className, ...props }, ref) => (
    <IconButton
      {...props}
      ref={ref}
      sx={{
        mr: 1,
        fontSize: '32px',
      }}
      className={cx('ToolbarPanelAction-root', className)}
    >
      <Icon name={icon} />
    </IconButton>
  )
);

export default ToolbarPanelAction;
