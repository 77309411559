import { User } from '@sentry/react';
import { allowScreenshare, disallowScreenshare } from 'features/permissions/actions';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { UserId } from 'features/users/types';
import { selectUserById } from 'features/users/usersSlice';
import { call, put, select } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';

export function* onManageScreenshareCommandSaga(userId: UserId, allow: boolean) {
  const targetUser: User = yield select(selectUserById, userId);

  if (!targetUser) {
    return;
  }

  const canManageScreenshare: boolean = yield call(
    hasPermissionsSaga,
    PermissionTypes.manageScreenshare,
    {
      targetRole: targetUser.role,
    }
  );

  if (!canManageScreenshare) {
    yield call(
      eventBus.forbiddenActionError,
      'Forbidden action. This participant is not allowed to manage screenshare',
      'manage-screenshare'
    );

    return;
  }

  const targetRoleCanScreenshare: boolean = yield call(
    hasPermissionsSaga,
    PermissionTypes.screenshare,
    {
      role: targetUser.role,
    }
  );

  if (targetRoleCanScreenshare) {
    // if target users has static permissions granted by role
    // we can't change them

    yield call(
      eventBus.forbiddenActionError,
      'Forbidden action. This participant is not allowed to manage target participant’s screenshare',
      'manage-screenshare'
    );

    return;
  }

  if (allow) {
    yield put(allowScreenshare(userId));
  } else {
    yield put(disallowScreenshare(userId));
  }
}
