import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MobileMenuItem from 'features/toolbar/Toolbar/mobile/MobileMenuItem';
import { useEndSessionControl } from 'features/toolbar/Toolbar/controls/end-call/useEndSessionControl';
import EndCallDialog from 'features/toolbar/Toolbar/controls/end-call/EndCallDialog';

const EndCallControlMobile = () => {
  const { t } = useTranslation('room');

  const [dialogOpen, setDialogOpen] = useState(false);
  const { leaveSession, endSessionAvailable, leaveBreakoutAvailable, icon } =
    useEndSessionControl();

  const handleClick = () => {
    if (leaveBreakoutAvailable || endSessionAvailable) {
      setDialogOpen(true);
    } else {
      leaveSession();
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const renderLabel = () => {
    if (endSessionAvailable) {
      return t('toolbar.end_session');
    }
    if (leaveBreakoutAvailable) {
      return t('toolbar.leave_breakout');
    }
    return t('toolbar.leave_session');
  };

  return (
    <>
      <MobileMenuItem icon={icon} onClick={handleClick}>
        {renderLabel()}
      </MobileMenuItem>
      <EndCallDialog open={dialogOpen} onClose={handleClose} />
    </>
  );
};

export default EndCallControlMobile;
