import { selectOrderedStreams } from 'features/layout/features/order/orderSlice';
import { makeSourceKey } from 'features/streaming/utils';
import React from 'react';
import { useAppSelector } from 'store/hooks';
import RemoteAudioWrapper from 'features/streaming/components/media/remote-stream/RemoteAudioWrapper';

export const AudioStreams = () => {
  const orderedStreams = useAppSelector(selectOrderedStreams);

  return (
    <div className="audio-streams">
      {orderedStreams.map((stream) => (
        <RemoteAudioWrapper source={stream} key={makeSourceKey(stream)} />
      ))}
    </div>
  );
};
