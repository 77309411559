import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal } from 'features/modal/modalSlice';
import { Trans, useTranslation } from 'react-i18next';
import { selectMediaDevicePermissions } from 'features/user-media/userMediaSlice';
import getBrowserPermissionsDocLink from 'utils/getBrowserPermissionsDocLink';
import { DialogContent, DialogContentText } from '@mui/material';
import { Dialog, DialogTitle } from 'components/Dialog';

const AudioPermissionsError = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('modals');

  const micPermissions = useAppSelector((state) =>
    selectMediaDevicePermissions(state, 'audioinput')
  );

  const hideModal = () => dispatch(closeModal('audioPermissionsError'));

  const renderTitle = () => {
    if (micPermissions === 'denied') {
      return t('microphone_blocked.title');
    }

    return t('microphone_unavailable.title');
  };

  const renderContent = () => {
    if (micPermissions === 'denied') {
      return (
        <Trans
          i18nKey="modals:microphone_blocked.description"
          t={t}
          components={[
            // eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content
            <a href={getBrowserPermissionsDocLink()} target="_blank" rel="noopener noreferrer" />,
          ]}
        />
      );
    }

    return t('microphone_unavailable.description');
  };

  return (
    <Dialog open aria-labelledby="audio-error-dialog" onClose={hideModal} maxWidth="xs">
      <DialogTitle id="audio-error-dialog" onClose={hideModal}>
        {renderTitle()}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{renderContent()}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default AudioPermissionsError;
