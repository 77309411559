import { selectVirtualBackgroundStatus } from 'features/virtual-backgrounds/selectors';
import { SelectedVirtualBackground } from 'utils/webrtc/VirtualBackground';
import { AppThunk } from 'store/store';
import {
  virtualBackgroundChanged,
  virtualBackgroundChangeStarted,
  virtualBackgroundFailure,
} from 'features/virtual-backgrounds/virtualBackgroundsSlice';
import { RTCClient } from 'utils/webrtc';
import { logger } from 'utils/logger';

export const changeVirtualBackground =
  (options: SelectedVirtualBackground): AppThunk =>
  async (dispatch, getState) => {
    const status = selectVirtualBackgroundStatus(getState());
    if (status === 'updating') {
      return;
    }

    dispatch(virtualBackgroundChangeStarted());

    try {
      await RTCClient.publishingFeed.virtualBackground.changeBackground(options);

      logger.remote({ action: true, tier: 1 }).log('The user changed the virtual background');

      dispatch(
        virtualBackgroundChanged({
          type: options.type,
          value: options.value,
        })
      );
    } catch (error) {
      dispatch(virtualBackgroundFailure());
    }
  };
