import { streamMinimized } from 'features/layout/actions';
import { selectMaximizedStream } from 'features/layout/features/content/contentSlice';
import { SourceDetails } from 'features/layout/types';
import { call, put, select } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';

export function* localStreamCollapseSaga() {
  const maximizedStream: SourceDetails = yield select(selectMaximizedStream);

  if (maximizedStream?.kind === 'local') {
    yield put(streamMinimized());
  }

  yield call(eventBus.sendMessage, 'localTileMinimized', {});
}
