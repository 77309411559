import { call } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { sharePollResultsRequested } from 'features/polls/actions';

export function* sharePollResultsSaga(action: ReturnType<typeof sharePollResultsRequested>) {
  yield call(SignalingSocket.send, {
    event: 'sharePollResults',
    data: {
      id: action.payload,
    },
  });
}
