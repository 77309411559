import { selectOrderedStreams } from 'features/layout/features/order/orderSlice';
import { put, select } from 'redux-saga/effects';
import { orderChanged } from 'features/layout/actions';
import { SourceDetails } from '../types';

export function* debugTilesChangeSaga() {
  const streams: SourceDetails[] = yield select(selectOrderedStreams);

  yield put(orderChanged(streams));
}
