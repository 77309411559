import { eventBusCommandReceived } from 'features/application/actions';
import { LayoutMode } from 'features/layout/types';
import { User, UserId } from 'features/users/types';
import { selectLocalUser, selectLocalUserId, selectUserById } from 'features/users/usersSlice';
import { selectVirtualBackgroundEnforced } from 'features/virtual-backgrounds/selectors';
import { store } from 'store/store';
import { vbConfigToSDKState } from 'utils/eventBus/vbConfigToSDKState';
import { singleton } from 'utils/singleton';
import { SelectedVirtualBackground } from 'utils/webrtc/VirtualBackground';

export type AnyFn = (...args: any[]) => any;

export interface BrandingOptionsConfig {
  paletteMode: 'dark' | 'light';
  primaryColor: string;
  toolbarColor: string;
  roomBackgroundColor: string;
}

export interface SDKInitState {
  muteFrame: boolean;
  // device config
  videoEnabled: boolean;
  audioEnabled: boolean;
  // user config
  username: string;
  initials: string;
  // layout config
  layoutMode: LayoutMode;
  showToolbar: boolean;
  showCaptions: boolean;

  virtualBackground: Partial<VirtualBackgroundOptions>;

  appLanguage: string;

  mediaDevices?: Partial<Record<MediaDeviceKind, string>>;

  requireRemoveUserConfirmation: boolean;
}

export interface QueuedEventListener {
  operation: EventTypes;
  event: string;
  target: 'document' | 'window';
}

export interface QueuedUICallback {
  operation: 'connectUICallback' | 'disconnectUICallback';
  name: UICallbackName;
}

export interface QueuedTileAction {
  operation: TileControlTypes;
  name: string;
  properties: TileActionProperties;
}

export interface SDKConnectionPayload extends Partial<SDKInitState> {
  eventListeners: QueuedEventListener[];
  UICallbacks: QueuedUICallback[];
  tileActions: QueuedTileAction[];
}

export type TileActionScope =
  | 'all'
  | 'remote'
  | 'local'
  | 'screenshare-local'
  | 'screenshare-remote';

export type TileActionProperties = {
  label: string;
  scope: TileActionScope;
  // only support native samba icons. not recommended to use in this version;
  icon?: string;
};

export interface VirtualBackgroundOptions {
  enforce: boolean;
  blur: 'balanced' | 'strong';
  image: string;
  imageUrl: string;
}

export type BroadcastMessageTypes =
  | 'enableVideo'
  | 'enableAudio'
  | 'disableVideo'
  | 'disableAudio'
  | 'toggleVideo'
  | 'toggleAudio';

export type ScreenshareMessageTypes = 'startScreenshare' | 'stopScreenshare';

export type MuteFrameTypes = 'muteFrame' | 'unmuteFrame' | 'toggleMuteFrame';

export type TileControlTypes = 'addTileAction' | 'removeTileAction';

type SendMessageType =
  | 'connected'
  | 'roomJoined'
  | 'userJoined'
  | 'userLeft'
  | 'userLeftBatch'
  | 'sessionEnded'
  | 'videoEnabled'
  | 'videoDisabled'
  | 'audioEnabled'
  | 'audioDisabled'
  | 'screenshareStarted'
  | 'screenshareStopped'
  | 'recordingStarted'
  | 'recordingStopped'
  | 'recordingFailed'
  | 'activeSpeakerChanged'
  | 'layoutModeChanged'
  | 'appError'
  | 'captionsEnabled'
  | 'captionsDisabled'
  | 'captionsSpokenLanguageChanged'
  | 'captionsFontSizeChanged'
  | 'permissionsChanged'
  | 'handRaised'
  | 'handLowered'
  | 'virtualBackgroundChanged'
  | 'virtualBackgroundDisabled'
  | 'localTileMaximized'
  | 'localTileMinimized'
  | 'userMaximized'
  | 'userMinimized'
  | 'internalMediaDeviceChanged'
  | 'mediaPermissionsFailed'
  | 'documentEvent'
  | 'appLanguageChanged'
  | 'UICallback'
  | 'roleChanged'
  | 'tileAction'
  | 'chatMessageReceived';

export type LocalTileTypes = 'minimizeLocalTile' | 'maximizeLocalTile';

export type ContentTypes = 'pinUser' | 'maximizeUser' | 'minimizeContent';

export type EventTypes = 'connectEventListener' | 'disconnectEventListener';

export type UICallbackName = 'leaveSession';

export type UICallbackTypes = 'connectUICallback' | 'disconnectUICallback';

export type ReceiveMessageType = 'connect' &
  BroadcastMessageTypes &
  ScreenshareMessageTypes &
  LocalTileTypes &
  ContentTypes &
  EventTypes &
  UICallbackTypes &
  TileControlTypes &
  (
    | 'startRecording'
    | 'stopRecording'
    | 'showToolbar'
    | 'hideToolbar'
    | 'changeToolbarPosition'
    | 'toggleToolbar'
    | 'changeLayoutMode'
    | 'leaveSession'
    | 'endSession'
    | 'requestToggleAudio'
    | 'requestMute'
    | 'requestUnmute'
    | 'removeUser'
    | 'showCaptions'
    | 'hideCaptions'
    | 'toggleCaptions'
    | 'configureCaptions'
    | 'raiseHand'
    | 'lowerHand'
    | 'allowBroadcast'
    | 'disallowBroadcast'
    | 'allowScreenshare'
    | 'disallowScreenshare'
    | 'configureVirtualBackground'
    | 'disableVirtualBackground'
    | 'changeBrandingOptions'
    | 'changeRole'
  );

type EnrichFields = Partial<{
  userId: UserId;
  local?: boolean;
  localId?: boolean;
  virtualBackground?: SelectedVirtualBackground;
}>;

type ReceiveMessage = { type: ReceiveMessageType; payload: any };

interface ErrorMessage {
  name: string;
  message: string;
  data?: any;
}

interface SendMessage {
  type: SendMessageType;
  data?: Partial<{
    user: User;
    userId: UserId;
    type: 'local' | 'remote';
    [key: string]: any;
  }>;
}

export interface StoredRoomState {
  appLanguage: string;
  media: {
    videoEnabled: boolean;
    audioEnabled: boolean;
  };
  layout: {
    mode: LayoutMode;
    showToolbar: boolean;
    toolbarPosition: 'left' | 'right' | 'bottom';
  };
  captionsState: {
    showCaptions: boolean;
    spokenLanguage: string;
    fontSize: string;
  };
  frameMuted: boolean;
}

export class EventBus {
  allowedOrigin: string = '*';

  isEmbed = false;

  isNative = false;

  connected: boolean = false;

  initState: Partial<SDKInitState> = {};

  constructor() {
    const url = new URL(window.location.href);
    this.isEmbed = url.searchParams.has('dsEmbedFrame');

    if (this.isEmbed) {
      if (url.searchParams.has('dsEmbedFrameNative')) {
        this.isNative = true;
      }

      // @ts-ignore
      window.handleNativeMessage = (data: any) => this.onMessage({ data });
    }

    window.addEventListener('message', this.onMessage, false);
  }

  private onMessage = (event: MessageEvent<ReceiveMessage | any>) => {
    if (event.origin === window.location.origin) {
      // ignore dev server messages;
      return;
    }

    if (event.data.type) {
      if (event.data.type === 'connect' && event.origin !== 'null') {
        this.allowedOrigin = event.origin;
      }

      this.handleMessage(event.data);
    }
  };

  onConnection = (initState: Partial<SDKInitState>) => {
    this.initState = initState || {};
    this.connected = true;

    this.sendMessage('connected');
  };

  handleMessage = (message: ReceiveMessage) => {
    if (store) {
      store.dispatch(eventBusCommandReceived(message));
    } else {
      console.error(message);
    }
  };

  error = (error: ErrorMessage) => {
    this.sendMessage('appError', {}, error);
  };

  notAllowedError = (message: string, type: string, extraFields?: any) => {
    const data = { type };

    if (extraFields && typeof extraFields === 'object') {
      Object.assign(data, extraFields);
    }

    this.error({
      name: 'not-allowed',
      message,
      data,
    });
  };

  forbiddenActionError = (message: string, type: string, extraFields?: any) => {
    const data = { type };

    if (extraFields && typeof extraFields === 'object') {
      Object.assign(data, extraFields);
    }

    this.error({
      name: 'forbidden-action',
      message,
      data,
    });
  };

  sendMessage = <G>(type: SendMessageType, enrichFields?: EnrichFields, data?: G) => {
    if (this.isEmbed) {
      const message: SendMessage = this.enrichMesageFields(enrichFields, { type });

      if (message.data || data) {
        message.data = Object.assign(message.data || {}, data);
      }

      window.parent.postMessage(
        {
          DSPayload: message,
        },
        this.allowedOrigin
      );
    }
  };

  private enrichMesageFields = (enrichFields: EnrichFields | undefined, message: SendMessage) => {
    if (enrichFields?.userId) {
      message.data = {};

      const localUserId = selectLocalUserId(store.getState());
      message.data.user = selectUserById(store.getState(), enrichFields?.userId);

      message.data.type = localUserId === enrichFields.userId ? 'local' : 'remote';
    }

    if (enrichFields?.local) {
      message.data = {
        type: 'local',
      };

      message.data.user = selectLocalUser(store.getState());
    }

    if (enrichFields?.virtualBackground) {
      message.data = {};
      message.data.virtualBackgroundConfig = vbConfigToSDKState(enrichFields.virtualBackground);

      if (message.data.virtualBackgroundConfig) {
        message.data.virtualBackgroundConfig.enforced = selectVirtualBackgroundEnforced(
          store.getState()
        );
      }
    }

    return message;
  };
}

export const eventBus = singleton<EventBus>(() => new EventBus());
