import * as Sentry from '@sentry/react';
import { getBrowserId } from 'utils/getBrowserId';
import { call } from 'redux-saga/effects';

export function* initSentrySaga() {
  if (process.env.NODE_ENV === 'production') {
    yield call(Sentry.init, {
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new Sentry.BrowserTracing()],
      transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
      normalizeDepth: 10,
      debug: false,
      environment: process.env.REACT_APP_ENV,
      release: process.env.REACT_APP_RELEASE_VERSION,
      maxBreadcrumbs: 500,

      tracesSampleRate: 1.0,
      tracePropagationTargets: [process.env.REACT_APP_API_URL as string],
      initialScope: {
        user: { id: getBrowserId() },
      },
    });
  }
}
