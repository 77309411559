import { sessionEnded, sessionLeft } from 'features/room/actions';
import { call } from 'redux-saga/effects';
import { CloseCodes, SignalingSocket } from 'services/signaling';
import { eventBus } from 'utils/eventBus';

export function* terminateSessionSaga(
  action: ReturnType<typeof sessionEnded> | ReturnType<typeof sessionLeft>
) {
  yield call(SignalingSocket.close, CloseCodes.TERMINATED);

  if (action.type === 'signaling/sessionEnded') {
    yield call(eventBus.sendMessage, 'sessionEnded', {}, {});
    yield call(eventBus.sendMessage, 'userLeft', { local: true }, { reason: 'sessionEnded' });
  } else {
    yield call(eventBus.sendMessage, 'userLeft', { local: true }, { reason: 'generic' });
  }
}
