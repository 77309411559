import { call, put } from 'redux-saga/effects';
import {
  deviceProcessingStarted,
  deviceProcessingStopped,
} from 'features/streaming/streamingSlice';
import { DefaultDevice, getDefaultMediaDevice } from 'features/user-media/utils';
import { RTCClient } from 'utils/webrtc';
import { devicesAutoChanged, mediaDevicesUpdated } from 'features/user-media/userMediaSlice';
import { EnumeratedMediaDevices } from 'features/user-media/types';
import { AutoChangedDevices } from 'features/user-media/utils/findDeviceDifferences';
import * as Sentry from '@sentry/react';

export function* handleRemovedDevicesSaga(
  updatedDevices: EnumeratedMediaDevices,
  removedActiveDevices: AutoChangedDevices
) {
  yield put(
    mediaDevicesUpdated(updatedDevices, {
      updatePermissions: true,
    })
  );

  if (removedActiveDevices.audioinput) {
    yield put(deviceProcessingStarted('audio'));

    try {
      const deviceId: DefaultDevice = yield call(
        getDefaultMediaDevice,
        updatedDevices,
        'audioinput'
      );

      if (!deviceId) {
        throw new Error('Could not find audio device');
      }

      yield put(
        devicesAutoChanged({
          kind: 'audioinput',
          id: deviceId,
        })
      );

      if (RTCClient.publishingFeed.mediaStates.audio.enabled) {
        yield call(RTCClient.publishingFeed.stopAudioBroadcasting, true);
      }
    } catch (error) {
      Sentry.captureException(error);
      yield call(RTCClient.publishingFeed.blockAudioPublishing);
    }

    yield put(deviceProcessingStopped('audio'));
  }

  if (removedActiveDevices.videoinput) {
    yield put(deviceProcessingStarted('video'));

    try {
      const deviceId: DefaultDevice = yield call(
        getDefaultMediaDevice,
        updatedDevices,
        'videoinput'
      );

      if (!deviceId) {
        throw new Error('Could not find video device');
      }

      yield put(
        devicesAutoChanged({
          kind: 'videoinput',
          id: deviceId,
        })
      );

      if (RTCClient.publishingFeed.mediaStates.video.enabled) {
        yield call(RTCClient.publishingFeed.stopVideoBroadcasting);
      }
    } catch (error) {
      Sentry.captureException(error);
      yield call(RTCClient.publishingFeed.blockVideoPublishing);
    }

    yield put(deviceProcessingStopped('video'));
  }

  if (removedActiveDevices.audiooutput) {
    try {
      const deviceId: DefaultDevice = yield call(
        getDefaultMediaDevice,
        updatedDevices,
        'audiooutput'
      );

      if (!deviceId) {
        throw new Error('Could not find the audio output device');
      }

      yield put(
        devicesAutoChanged({
          kind: 'audiooutput',
          id: deviceId,
        })
      );
    } catch (error) {
      Sentry.captureException(error);
    }
  }
}
