import { Action } from '@reduxjs/toolkit';
import { selectFeatureFlag, selectFeaturesInitialized } from 'features/feature-set/featuresSlice';
import { selectRoomStatus } from 'features/room/roomSlice';
import { validateVBOptionsSaga } from 'features/sdk/validateVBOptionsSaga';
import { virtualBackgroundChangeRequest } from 'features/virtual-backgrounds/actions';
import { initVirtualBackground } from 'features/virtual-backgrounds/thunks/initVirtualBackground';
import {
  customImageApplied,
  pendingConfigApplied,
  virtualBackgroundEnforceToggled,
} from 'features/virtual-backgrounds/virtualBackgroundsSlice';
import { call, put, select, take } from 'redux-saga/effects';
import { eventBus, VirtualBackgroundOptions } from 'utils/eventBus';
import { selectDeviceType } from 'features/application/applicationSlice';

export function* onConfigureVBCommandSaga(
  options: Partial<VirtualBackgroundOptions>,
  activate: boolean
) {
  const deviceType: string = yield select(selectDeviceType);
  if (deviceType !== 'desktop') {
    yield call(
      eventBus.notAllowedError,
      'Virtual backgrounds are not supported on mobile and tablet devices',
      'virtual-bg'
    );

    return;
  }

  const controlsInitialized: boolean = yield select(selectFeaturesInitialized);
  const controlEnabled: boolean = yield select(selectFeatureFlag, 'virtualBackgrounds');

  // if we're not yet in a room fully, init state will be updated
  if (controlsInitialized && !controlEnabled) {
    yield call(
      eventBus.notAllowedError,
      'Virtual backgrounds are disabled. You’ll need to edit this room’s properties to enable them.',
      'virtual-bg'
    );

    return;
  }

  const { valid, config, targetOption } = yield call(validateVBOptionsSaga, options);

  if (valid && config) {
    yield put(virtualBackgroundEnforceToggled(!!options.enforce));

    if (targetOption === 'imageUrl') {
      yield put(customImageApplied(config.value));
    }

    yield put(initVirtualBackground() as unknown as Action);

    yield take([initVirtualBackground.fulfilled, initVirtualBackground.rejected]);

    const roomStatus: string = yield select(selectRoomStatus);
    // if inside the room, apply immediately,
    // otherwise trigger side effect so that it will be updated in publishing media
    const roomJoined = roomStatus === 'joined';

    if (activate && roomJoined) {
      yield put(virtualBackgroundChangeRequest(config));
    } else {
      yield put(pendingConfigApplied(config));
    }
  }
}
