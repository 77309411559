import { createSelector } from '@reduxjs/toolkit';
import { BreakoutsUsersVirtualListItemType } from 'features/breakout-rooms/types';
import { selectBreakoutsUnassignedUserEntries } from 'features/breakout-rooms/breakoutRoomsSlice';
import { selectLocalUser } from 'features/users/usersSlice';
import { searchUsers } from 'features/users/utils/searchUsers';
import { sortUserAlphabetically } from 'features/users/sortUserAlphabetically';

export const selectBreakoutsUnassignedUsers = createSelector(
  [
    selectBreakoutsUnassignedUserEntries,
    selectLocalUser,
    (state, searchQuery: string) => searchQuery.toLowerCase(),
  ],
  (unassignedUsers, localUser, searchQuery) => {
    const result: BreakoutsUsersVirtualListItemType[] = [];

    const searchedUnassignedUsers = searchUsers(unassignedUsers, searchQuery);

    const searchedUnassignedUsersArray = Object.values(searchedUnassignedUsers);

    searchedUnassignedUsersArray.sort(sortUserAlphabetically);

    for (const user of searchedUnassignedUsersArray) {
      result.push({
        id: user.id,
        type: 'unassigned',
        local: localUser.id === user.id,
        label: user.name as string,
        user,
      });
    }

    return result;
  }
);
