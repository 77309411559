import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { usePermissions } from 'features/permissions/usePermissions';
import { PermissionTypes } from 'features/permissions/types';

export const useContentLibrary = () => {
  const featureEnabled = useFeatureFlag('contentLibrary');

  const { hasPermissions } = usePermissions();
  const canManageLibrary = hasPermissions(PermissionTypes.manageContentLibrary);

  const isFeatureAvailable = featureEnabled && canManageLibrary;

  return {
    canManageLibrary,
    isFeatureAvailable,
  };
};
