import { useAppDispatch } from 'store/hooks';
import { openModal } from 'features/modal/modalSlice';

export const useSettingsControl = () => {
  const dispatch = useAppDispatch();

  const openSettings = () => {
    dispatch(openModal('roomSettings'));
  };

  return {
    openSettings,
    icon: 'settings',
  };
};
