import { createAction } from '@reduxjs/toolkit';
import {
  AssignUsersToBreakoutRoomsPayload,
  BreakoutRoomDeletedPayload,
  BreakoutRoomsPayload,
  BreakoutRoomsSplashScreenName,
  BreakoutRoomsStartedPayload,
  BreakoutUserJoinedPayload,
  BreakoutUserLeftBatchPayload,
  BreakoutUserLeftPayload,
  BroadcastMessagePayload,
  CreateBreakoutRoomsPayload,
  EmptyBreakoutRoom,
  RenameBreakoutRoomPayload,
  UnassignUsersFromBreakoutRoomsPayload,
} from 'features/breakout-rooms/types';

export const createRoomsRequested = createAction<CreateBreakoutRoomsPayload>(
  'breakoutRooms/createRoomsRequested'
);

export const signalingBreakoutRoomsCreated = createAction<BreakoutRoomsPayload>(
  'signaling/breakoutRoomsCreated'
);

export const renameRoomRequested = createAction<RenameBreakoutRoomPayload>(
  'breakoutRooms/renameRoomRequested'
);

export const signalingBreakoutRoomRenamed = createAction<RenameBreakoutRoomPayload>(
  'signaling/breakoutRoomRenamed'
);

export const signalingBreakoutRoomsReceived = createAction<BreakoutRoomsPayload>(
  'signaling/breakoutRoomsReceived'
);

export const addBreakoutRoomRequested = createAction('breakoutRooms/addBreakoutRoomRequested');

export const signalingBreakoutRoomAdded = createAction<EmptyBreakoutRoom>(
  'signaling/breakoutRoomAdded'
);

export const clearRoomsRequested = createAction('breakoutRooms/clearRoomsRequested');
export const signalingBreakoutRoomsCleared = createAction('signaling/breakoutRoomsCleared');

export const deleteRoomRequested = createAction<string>('breakoutRooms/deleteRoomRequested');
export const signalingBreakoutRoomDeleted = createAction<BreakoutRoomDeletedPayload>(
  'signaling/breakoutRoomDeleted'
);

export const startBreakoutRoomsRequested = createAction(
  'breakoutRooms/startBreakoutRoomsRequested'
);

export const signalingBreakoutRoomsStarted = createAction<BreakoutRoomsStartedPayload | undefined>(
  'signaling/breakoutRoomsStarted'
);

export const stopBreakoutRoomsRequested = createAction('breakoutRooms/stopBreakoutRoomsRequested');

export const signalingBreakoutRoomsStopped = createAction('signaling/breakoutRoomsStopped');

export const assignUsersToBreakoutRoomRequested = createAction<AssignUsersToBreakoutRoomsPayload>(
  'breakoutRooms/assignUsersToBreakoutRoomRequested'
);

export const assignLocalUserToBreakoutRoomRequested = createAction<string>(
  'breakoutRooms/assignLocalUserToBreakoutRoomRequested'
);

export const signalingUsersAssignedToBreakoutRoom = createAction<AssignUsersToBreakoutRoomsPayload>(
  'signaling/usersAssignedToBreakoutRoom'
);

export const unassignUserFromBreakoutRoomRequested =
  createAction<UnassignUsersFromBreakoutRoomsPayload>(
    'breakoutRooms/unassignUserFromBreakoutRoomRequested'
  );

export const signalingUserUnassignedFromBreakoutRoom =
  createAction<UnassignUsersFromBreakoutRoomsPayload>('signaling/userUnassignedFromBreakoutRoom');

export const broadcastRoomMessageRequested = createAction<string>(
  'breakoutRooms/broadcastMessageRequested'
);

export const signalingBreakoutRoomMessageBroadcast = createAction<BroadcastMessagePayload>(
  'signaling/breakoutRoomMessageBroadcast'
);

export const signalingBreakoutUserJoined = createAction<BreakoutUserJoinedPayload>(
  'signaling/breakoutUserJoined'
);

export const signalingBreakoutUserLeft = createAction<BreakoutUserLeftPayload>(
  'signaling/breakoutUserLeft'
);

export const signalingBreakoutUserLeftBatch = createAction<BreakoutUserLeftBatchPayload>(
  'signaling/breakoutUserLeftBatch'
);

export const splashScreenOpenRequested = createAction<BreakoutRoomsSplashScreenName>(
  'breakoutRooms/splashScreenOpenRequested'
);

export const splashScreenCloseRequested = createAction('breakoutRooms/splashScreenCloseRequested');

export const leaveBreakoutRoomRequested = createAction('breakoutRooms/leaveBreakoutRoomRequested');
