import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import {
  AppState,
  SignalingDisconnectPayload,
  SignalingInitData,
  SupportedLanguages,
} from 'features/application/types';
import { roomError, sessionDuplicationDetected, sessionIntercepted } from 'features/room/roomSlice';
import { authorizeUser } from 'features/room/thunks/authorizeUser';
import {
  pong,
  signalingConnected,
  signalingDisconnected,
  systemUpdate,
} from 'features/application/actions';
import {
  participantsLimitReached,
  sessionEnded,
  sessionLeft,
  signalingRoomJoined,
} from 'features/room/actions';
import { selectLocalUserId, selectUserEntries } from 'features/users/usersSlice';
import { streamingConnectionEstablished } from 'features/streaming/actions';
import { signalingAccessRejected, signalingUserKicked } from 'features/users/actions';
import { userAgentDetails } from 'utils/userAgentDetails';

export const initialState: AppState = {
  signaling: {
    url: '',
    token: '',
    isReconnecting: false,
    isConnectionEstablished: false,
    iceServers: null,
    mediaServers: null,
  },
  streaming: {
    isReconnecting: false,
    isConnectionEstablished: false,
  },
  waitingScreen: null,
  deviceInfo: userAgentDetails(),
  supportedLanguages: [],
  isPiP: false,
};

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    mediaServerError(state) {
      state.streaming.isReconnecting = true;
    },
    signalingReconnectionStarted(state) {
      state.signaling.isReconnecting = true;
    },
    supportedLanguagesLoaded(state, action: PayloadAction<SupportedLanguages>) {
      state.supportedLanguages = action.payload;
    },
    PiPToggled(state, action: PayloadAction<boolean>) {
      state.isPiP = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signalingConnected, (state) => {
        state.signaling.isConnectionEstablished = true;
        state.signaling.isReconnecting = false;
      })
      .addCase(
        signalingDisconnected,
        (state, action: PayloadAction<SignalingDisconnectPayload>) => {
          state.signaling.isConnectionEstablished = false;
          state.signaling.isReconnecting = false;

          if (action.payload.error) {
            state.waitingScreen = 'connectionFailure';
          }
        }
      )
      .addCase(streamingConnectionEstablished, (state) => {
        state.streaming.isConnectionEstablished = true;
        state.streaming.isReconnecting = false;
      })
      .addCase(pong, (state, action) => {
        state.signaling.token = action.payload.token;

        if (state.signaling.mediaServers?.publishing && action.payload.publishingMediaToken) {
          state.signaling.mediaServers.publishing.token = action.payload.publishingMediaToken;
        }

        if (state.signaling.mediaServers?.subscribing && action.payload.subscribingMediaToken) {
          state.signaling.mediaServers.subscribing.token = action.payload.subscribingMediaToken;
        }
      })
      .addCase(authorizeUser.fulfilled, (state, action) => {
        state.signaling.token = action.payload.signallingToken;
        state.signaling.url = action.payload.signallingUrl;
      })
      .addCase(systemUpdate, (state, action) => {
        state.signaling.token = action.payload.token;
      })
      .addCase(signalingRoomJoined, (state, action) => {
        state.signaling.token = action.payload.token;
        state.signaling.iceServers = action.payload.iceServers;
        state.signaling.mediaServers = action.payload.mediaServers;
      })
      .addCase(sessionLeft, (state) => {
        state.waitingScreen = 'sessionLeft';
      })
      .addCase(participantsLimitReached, (state) => {
        state.waitingScreen = 'participantsLimit';
      })
      .addCase(signalingUserKicked, (state, action) => {
        if (action.payload.reason === 'session_duplicated') {
          state.waitingScreen = 'sessionDuplicated';
        } else {
          state.waitingScreen = 'userKicked';
        }
      })
      .addCase(signalingAccessRejected, (state) => {
        state.waitingScreen = 'accessRejected';
      })
      .addCase(sessionDuplicationDetected, (state) => {
        state.waitingScreen = 'interceptSession';
      })
      .addCase(sessionIntercepted, (state) => {
        state.waitingScreen = null;
      })
      .addCase(sessionEnded, (state, action) => {
        state.waitingScreen = 'sessionEnded';
        state.endSessionInitiator = action.payload.initiator;
      })
      .addCase(roomError, (state, action) => {
        if (action.payload?.global) {
          state.waitingScreen = 'roomError';
        }
      });
  },
});

export const {
  PiPToggled,
  supportedLanguagesLoaded,
  mediaServerError,
  signalingReconnectionStarted,
} = applicationSlice.actions;

export const selectWaitingScreen = (state: RootState) => state.application.waitingScreen;
export const selectSignalingInitData = (state: RootState): SignalingInitData => ({
  url: state.application.signaling.url,
  token: state.application.signaling.token,
  iceServers: state.application.signaling.iceServers,
  mediaServers: state.application.signaling.mediaServers,
});

export const selectAppReconnecting = (state: RootState) =>
  state.application.signaling.isReconnecting || state.application.streaming.isReconnecting;

export const selectIsPiP = (state: RootState) => state.application.isPiP;

export const selectDeviceInfo = (state: RootState) => state.application.deviceInfo;

// UA parser returns undefined for desktop clients and a specifier for everything else, hence default to desktop;
export const selectDeviceType = (state: RootState) =>
  state.application.deviceInfo.device.type || 'desktop';

export const selectEndSessionData = createSelector(
  [
    selectLocalUserId,
    selectUserEntries,
    (state: RootState) => state.application.endSessionInitiator,
  ],
  (localUserId, userEntries, endSessionInitiator) => {
    if (!endSessionInitiator?.id) {
      return {
        initiatorName: null,
        endedByMe: false,
      };
    }

    const initiatorUser = userEntries[endSessionInitiator.id];

    return {
      initiatorName: initiatorUser?.name || endSessionInitiator.name,
      endedByMe: localUserId === endSessionInitiator.id,
    };
  }
);

export const selectSupportedLanguages = (state: RootState) => state.application.supportedLanguages;

export default applicationSlice.reducer;
