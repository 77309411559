import {
  selectActiveVirtualBackground,
  selectVirtualBackgroundStatus,
} from 'features/virtual-backgrounds/selectors';
import { useEffect, useReducer, useRef } from 'react';
import { initialState, reducer } from 'utils/broadcast-setup/reducer';
import { useMediaDevices } from 'hooks/useMediaDevices';
import { isDomException } from 'utils/types';
import { logger } from 'utils/logger';
import { getDefaultPromptedDevice } from 'features/user-media/utils/getDefaultPromptedDevice';
import {
  getDefaultActiveDevices,
  getDevicePromptConstraints,
  stopStreamTracks,
} from 'features/user-media/utils';
import { environment } from 'utils/webrtc/environment';
import * as Sentry from '@sentry/react';
import { refineMediaDevices } from 'features/user-media/utils/refineMediaDevices';
import webrtcAdapter from 'webrtc-adapter';
import { getUserMedia } from 'features/user-media/utils/getUserMedia';
import {
  activeMediaDeviceUpdated,
  facingModeChanged,
  selectJoinMediaDefaults,
} from 'features/user-media/userMediaSlice';
import { activateVirtualBackground } from 'features/virtual-backgrounds/thunks/activateVirtualBackground';
import { useUserAudioOutput } from 'hooks/useUserAudioOutput';
import { useSingularUserMedia } from 'hooks/useSingularUserMedia';
import { useVirtualBackground } from 'hooks/useVirtualBackground';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  virtualBackgroundChanged,
  virtualBackgroundReset,
} from 'features/virtual-backgrounds/virtualBackgroundsSlice';
import { changeVirtualBackground } from 'features/virtual-backgrounds/thunks/changeVirtualBackground';
import { SelectedVirtualBackground } from 'utils/webrtc/VirtualBackground';
import { usePublishingFeed } from 'hooks/usePublishingFeed';
import { PublishingFeed } from 'utils/webrtc/publishing/PublishingFeed';

export const useBroadcastSetupMedia = () => {
  const appDispatch = useAppDispatch();

  const isMounted = useRef(false);

  const feed = usePublishingFeed('media');
  const publishingFeed = feed.control as PublishingFeed;

  const [state, dispatch] = useReducer(reducer, initialState);

  const audioOutputHandler = useUserAudioOutput();
  const requestSingularUserMedia = useSingularUserMedia();

  const { control: VB } = useVirtualBackground();
  const selectedBackground = useAppSelector(selectActiveVirtualBackground);

  const mediaDefaults = useAppSelector(selectJoinMediaDefaults);
  const VBStatus = useAppSelector(selectVirtualBackgroundStatus);

  const VBApplied = useRef(false);

  const {
    updateMediaDevices,
    mediaDevices,
    activeMediaDevices,
    camPermissions,
    micPermissions,
    persistDevices,
  } = useMediaDevices();

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (micPermissions === 'granted') {
      dispatch({ type: 'mediaUnblocked', payload: 'audio' });
    } else {
      dispatch({ type: 'mediaDeactivated', payload: 'audio' });
    }
  }, [micPermissions]);

  useEffect(() => {
    if (camPermissions === 'granted') {
      dispatch({ type: 'mediaUnblocked', payload: 'video' });
    } else {
      dispatch({ type: 'mediaDeactivated', payload: 'video' });
    }
  }, [camPermissions]);

  const handleMediaError = async (error: unknown) => {
    if (!isMounted.current) {
      return;
    }

    if (isDomException(error)) {
      logger.error(`${error.name}: ${error.message}`);

      const audioRequest = requestSingularUserMedia({
        audio: {
          deviceId: {
            exact: getDefaultPromptedDevice(mediaDevices, 'audioinput'),
          },
        },
      });

      const videoRequest = requestSingularUserMedia({
        video: {
          ...environment.videoConstraints,
          deviceId: {
            exact: getDefaultPromptedDevice(mediaDevices, 'videoinput'),
          },
        },
      });

      const [audio, video] = await Promise.all([audioRequest, videoRequest]);

      await updateMediaDevices();

      if (video.stream) {
        if (mediaDefaults.video) {
          publishingFeed.setJoinMedia(video.stream, 'video');
          dispatch({
            type: 'mediaUpdated',
            payload: { type: 'video', activateMedia: true },
          });
        } else {
          stopStreamTracks(video.stream);
        }
      }

      if (audio.stream) {
        if (mediaDefaults.audio) {
          publishingFeed.setJoinMedia(audio.stream, 'audio');
          dispatch({
            type: 'mediaUpdated',
            payload: { type: 'audio', activateMedia: true },
          });
        } else {
          stopStreamTracks(audio.stream);
        }
      }

      if (audio.stream || video.stream) {
        dispatch({ type: 'statusUpdated', payload: 'success' });
      } else if (audio.error.name === 'NotAllowedError' && video.error.name === 'NotAllowedError') {
        dispatch({ type: 'statusUpdated', payload: 'denied' });
      } else {
        dispatch({ type: 'statusUpdated', payload: 'failure' });
      }
    } else {
      logger.error(error);
      Sentry.captureException(error);
      dispatch({ type: 'statusUpdated', payload: 'failure' });
    }
  };

  // @TODO Firefox requires separate permissions for each device. This case is unhandled.
  const requestPermissions = async () => {
    try {
      const updatedMediaDevices = await refineMediaDevices(updateMediaDevices);

      // check against the media defaults from the backend
      // if all values are false, obtain only device permissions and list of devices
      if (!mediaDefaults.video && !mediaDefaults.audio) {
        if (webrtcAdapter.browserDetails.browser === 'firefox') {
          const constraints = getDevicePromptConstraints(updatedMediaDevices);

          const stream = await getUserMedia(constraints);

          if (!isMounted.current) {
            stopStreamTracks(stream);
            return;
          }

          const activeDevices = getDefaultActiveDevices(updatedMediaDevices);

          await updateMediaDevices({
            updatePermissions: true,
            updateActiveDevices: true,
            activeDevices,
          });
          stopStreamTracks(stream);

          dispatch({ type: 'statusUpdated', payload: 'success' });
          return;
        }

        if (!isMounted.current) {
          return;
        }

        const activeDevices = getDefaultActiveDevices(updatedMediaDevices);

        await updateMediaDevices({
          updatePermissions: true,
          updateActiveDevices: true,
          activeDevices,
        });

        dispatch({ type: 'statusUpdated', payload: 'success' });
        return;
      }

      const constraints = getDevicePromptConstraints(updatedMediaDevices);
      const stream = await getUserMedia(constraints);

      if (!isMounted.current) {
        stopStreamTracks(stream);
        return;
      }

      const activeDevices = getDefaultActiveDevices(updatedMediaDevices);

      await updateMediaDevices({
        updatePermissions: true,
        updateActiveDevices: true,
        activeDevices,
      });

      if (mediaDefaults.video) {
        const videoTrack = stream.getVideoTracks()[0];
        if (videoTrack) {
          const newVideoStream = new MediaStream();
          newVideoStream.addTrack(videoTrack.clone());

          const settings = videoTrack.getSettings();

          if (settings.facingMode) {
            appDispatch(facingModeChanged(settings.facingMode));
          }
          publishingFeed.setJoinMedia(newVideoStream, 'video');
          dispatch({
            type: 'mediaUpdated',
            payload: { type: 'video', activateMedia: true },
          });
        }
      }

      if (mediaDefaults.audio) {
        const audioTrack = stream.getAudioTracks()[0];
        if (audioTrack) {
          const newAudioStream = new MediaStream();
          newAudioStream.addTrack(audioTrack.clone());
          publishingFeed.setJoinMedia(newAudioStream, 'audio');
          dispatch({
            type: 'mediaUpdated',
            payload: { type: 'audio', activateMedia: true },
          });
        }
      }

      stopStreamTracks(stream);

      dispatch({ type: 'statusUpdated', payload: 'success' });
    } catch (error) {
      await handleMediaError(error);
    }
  };

  const disableCamera = async () => {
    if (publishingFeed.joinMedia.video) {
      dispatch({ type: 'mediaUpdated', payload: { type: 'video', processing: true } });

      await VB.deactivate();

      publishingFeed.cleanupJoinMedia('video');

      dispatch({
        type: 'mediaUpdated',
        payload: { type: 'video', processing: false },
      });
    }
  };

  const enableCamera = async (deviceId?: string) => {
    dispatch({ type: 'mediaUpdated', payload: { type: 'video', processing: true } });

    const { stream } = await requestSingularUserMedia({
      video: {
        ...environment.videoConstraints,
        deviceId: {
          exact: deviceId || activeMediaDevices.videoinput,
        },
      },
    });

    if (stream) {
      let updatedStream = stream;

      if (selectedBackground.value && !VB.activated) {
        logger.remote({ tier: 1 }).log('Activating the virtual background');

        const vbStream = await appDispatch(
          activateVirtualBackground({
            stream,
            type: selectedBackground.type,
            value: selectedBackground.value,
          })
        );

        if (vbStream) {
          updatedStream = vbStream;
        }
      }

      publishingFeed.setJoinMedia(updatedStream, 'video');
      dispatch({
        type: 'mediaUpdated',
        payload: {
          type: 'video',
          processing: false,
          active: true,
          disabled: false,
        },
      });
    }
  };

  const disableAudio = () => {
    if (publishingFeed.joinMedia.audio) {
      dispatch({ type: 'mediaUpdated', payload: { type: 'audio', processing: true } });

      publishingFeed.cleanupJoinMedia('audio');

      dispatch({
        type: 'mediaUpdated',
        payload: { type: 'audio', processing: false },
      });
    }
  };

  const handleVideoToggle = async (checked: boolean) => {
    if (state.video.processing) {
      return;
    }

    dispatch({ type: 'setMediaActive', payload: { type: 'video', value: checked } });

    if (publishingFeed.joinMedia.video) {
      await disableCamera();
    } else {
      await enableCamera();
    }
  };

  const enableAudio = async (deviceId?: string) => {
    dispatch({ type: 'mediaUpdated', payload: { type: 'audio', processing: true } });

    const { stream } = await requestSingularUserMedia({
      audio: {
        deviceId: {
          exact: deviceId || activeMediaDevices.audioinput,
        },
      },
    });

    publishingFeed.setJoinMedia(stream, 'audio');
    dispatch({
      type: 'mediaUpdated',
      payload: { type: 'audio', processing: false, active: true, disabled: false },
    });
  };

  const handleAudioToggle = async (checked: boolean) => {
    if (state.audio.processing) {
      return;
    }

    dispatch({ type: 'setMediaActive', payload: { type: 'audio', value: checked } });

    if (publishingFeed.joinMedia.audio) {
      disableAudio();
    } else {
      await enableAudio();
    }
  };

  const handleAudioInputChange = async (deviceId: string) => {
    const currentDeviceId = publishingFeed.joinMedia.audio
      ?.getAudioTracks()[0]
      .getSettings().deviceId;

    if (currentDeviceId === deviceId) {
      return;
    }

    persistDevices(deviceId, 'audioinput');

    if (!state.audio.active) {
      appDispatch(
        activeMediaDeviceUpdated({
          kind: 'audioinput',
          id: deviceId,
        })
      );

      return;
    }

    dispatch({ type: 'mediaUpdated', payload: { type: 'audio', processing: true } });

    if (publishingFeed.joinMedia.audio) {
      stopStreamTracks(publishingFeed.joinMedia.audio);
    }

    const { stream } = await requestSingularUserMedia({
      audio: {
        deviceId: {
          exact: deviceId,
        },
      },
    });

    publishingFeed.setJoinMedia(stream, 'audio');
    dispatch({ type: 'mediaUpdated', payload: { type: 'audio', processing: false } });
  };

  const handleAudioOutputChange = async (deviceId: string) => {
    persistDevices(deviceId, 'audiooutput');
    await audioOutputHandler(deviceId);
  };

  const handleVideoInputChange = async (deviceId: string) => {
    const currentDeviceId = publishingFeed.joinMedia.video
      ?.getVideoTracks()[0]
      .getSettings().deviceId;
    if (currentDeviceId === deviceId) {
      return;
    }

    persistDevices(deviceId, 'videoinput');

    if (!state.video.active) {
      appDispatch(
        activeMediaDeviceUpdated({
          kind: 'videoinput',
          id: deviceId,
        })
      );

      return;
    }

    dispatch({ type: 'mediaUpdated', payload: { type: 'video', processing: true } });

    if (publishingFeed.joinMedia.video) {
      stopStreamTracks(publishingFeed.joinMedia.video);
    }

    const { stream } = await requestSingularUserMedia({
      video: {
        ...environment.videoConstraints,
        deviceId: {
          exact: deviceId,
        },
      },
    });

    if (stream) {
      let updatedStream = stream;

      if (VB.activated) {
        updatedStream = await VB.setInputMedia({
          stream,
        });
      }

      const videoTrack = stream.getVideoTracks()[0];
      if (videoTrack) {
        const settings = videoTrack.getSettings();

        if (settings.facingMode) {
          appDispatch(facingModeChanged(settings.facingMode));
        }
      }

      publishingFeed.setJoinMedia(updatedStream, 'video');
      dispatch({
        type: 'mediaUpdated',
        payload: { type: 'video', processing: false },
      });
    }
  };

  const resetVB = async () => {
    VBApplied.current = false;

    appDispatch(virtualBackgroundReset());

    if (!publishingFeed.joinMedia.video) {
      return;
    }

    if (VBStatus === 'updating') {
      return;
    }

    dispatch({ type: 'mediaUpdated', payload: { type: 'video', processing: true } });

    const { stream } = await requestSingularUserMedia({
      video: {
        ...environment.videoConstraints,
        deviceId: {
          exact: activeMediaDevices.videoinput,
        },
      },
    });

    await VB.deactivate();
    stopStreamTracks(publishingFeed.joinMedia.video);

    if (stream) {
      publishingFeed.setJoinMedia(stream, 'video');
      dispatch({
        type: 'mediaUpdated',
        payload: {
          type: 'video',
          processing: false,
          active: true,
          disabled: false,
        },
      });
    }
  };

  const changeVB = async (config: SelectedVirtualBackground) => {
    VBApplied.current = true;

    if (!publishingFeed.joinMedia.video) {
      appDispatch(virtualBackgroundChanged(config));
      return;
    }

    if (VB.activated) {
      appDispatch(changeVirtualBackground(config));
    } else {
      dispatch({ type: 'mediaUpdated', payload: { type: 'video', processing: true } });

      const stream = await appDispatch(
        activateVirtualBackground({
          type: config.type,
          stream: publishingFeed.joinMedia.video,
          value: config.value,
        })
      );

      if (stream) {
        publishingFeed.setJoinMedia(stream, 'video');
      }

      dispatch({ type: 'mediaUpdated', payload: { type: 'video', processing: false } });
    }
  };

  const changeVBEffect = (
    stream: MediaStream | null,
    config?: SelectedVirtualBackground | number
  ) => {
    // eslint-disable-next-line no-prototype-builtins
    if (stream && config?.hasOwnProperty('type')) {
      changeVB(config as SelectedVirtualBackground);
    } else if (VBApplied.current && config) {
      resetVB();
    }
  };

  const cleanupBroadcastMedia = () => {
    publishingFeed.cleanupJoinMedia();
  };

  return {
    state,
    onVideoToggle: handleVideoToggle,
    onAudioToggle: handleAudioToggle,
    onAudioInputChange: handleAudioInputChange,
    onAudioOutputChange: handleAudioOutputChange,
    onVideoInputChange: handleVideoInputChange,
    enableAudio,
    disableAudio,
    disableCamera,
    enableCamera,
    dispatch,
    requestPermissions,
    changeVB,
    resetVB,
    changeVBEffect,
    videoStream: publishingFeed.joinMedia.video,
    audioStream: publishingFeed.joinMedia.audio,
    cleanupBroadcastMedia,
  };
};
