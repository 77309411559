import { createAction } from '@reduxjs/toolkit';
import { CursorUpdatePayload, RemoteUpdatePayload } from 'utils/whiteboard/BoardStateManager';

export const whiteboardOpened = createAction('layout/whiteboardOpened');
export const whiteboardClosed = createAction('layout/whiteboardClosed');

export const signalingWhiteboardOpened = createAction<{ shapes: string[] }>(
  'signaling/whiteboardOpened'
);
export const signalingWhiteboardClosed = createAction('signaling/whiteboardClosed');
export const signalingWhiteboardChanged = createAction<RemoteUpdatePayload>(
  'signaling/whiteboardChanged'
);

export const signalingWhiteboardCursorUpdated = createAction<CursorUpdatePayload>(
  'signaling/whiteboardCursorUpdated'
);
