import { Box, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { usePiPWindow } from 'components/PiP/PiPProvider';
import { LayoutProvider } from 'features/layout/LayoutRoot/LayoutProvider';
import RoomGrid from 'features/layout/layouts/TiledWithContent/RoomGrid';
import ActionNotificationContainer from 'features/notifications/components/ActionNotificationContainer';
import { PipToolbar } from 'features/toolbar/PipToolbar/PipToolbar';
import { selectToolbarWidth } from 'features/toolbar/toolbarSlice';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';

import 'theme/icons.css';

const FlexBox = styled(Box)({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
});

const MIN_WIDTH = 280;
const MIN_HEIGHT = 90;

export const PipLayout = ({
  width: outerWidth,
  height: outerHeight,
}: {
  width: number;
  height: number;
}) => {
  const theme = useTheme();
  const { t } = useTranslation('room');

  const toolbarWidth = useAppSelector(selectToolbarWidth);

  const [width, height] = useMemo(
    () => [outerWidth, outerHeight - toolbarWidth - theme.room.tileGap],
    [outerHeight, outerWidth, toolbarWidth, theme.room.tileGap]
  );

  const { pipWindow } = usePiPWindow();

  useEffect(() => {
    if (pipWindow) {
      pipWindow.document.body.style.backgroundColor = theme.room.background;
      pipWindow.document.body.style.overflow = 'hidden';
    }
  }, [pipWindow, theme.room.background]);

  return (
    <Box
      sx={(th) => ({
        paddingBottom: th.toolbar.width,
        height: '100vh',
        width: '100vw',
        minWidth: MIN_WIDTH,
      })}
    >
      <Box
        onClick={(e) => {
          e.stopPropagation();
          window.focus();
        }}
      >
        <ActionNotificationContainer />
      </Box>
      <Box style={{ position: 'relative', height }}>
        <FlexBox
          sx={{
            padding: `${theme.room.tileGap}px`,
            position: 'relative',
          }}
        >
          <LayoutProvider size={{ width, height }}>
            <RoomGrid />
          </LayoutProvider>
        </FlexBox>
      </Box>
      <PipToolbar />
      {outerWidth < MIN_WIDTH || outerHeight < MIN_HEIGHT ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            position: 'fixed',
            zIndex: 9999,
            backgroundColor: theme.room.background,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1rem',
            textAlign: 'center',
          }}
        >
          <Typography
            variant="body2"
            sx={(th) => ({
              color: th.palette.getContrastText(th.room.background),
            })}
          >
            {t('grid.pip_size_warning')}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};
