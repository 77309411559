import { FeatureFlag } from 'features/feature-set/types';
import { put } from 'redux-saga/effects';
import {
  groupChatCounterReset,
  privateChatsCounterReset,
  publicChatCounterReset,
} from 'features/chat/chatSlice';

export function* onChatFeatureDisabled(feature: FeatureFlag) {
  if (feature === FeatureFlag.generalChat) {
    yield put(publicChatCounterReset());
  }

  if (feature === FeatureFlag.privateChat) {
    yield put(privateChatsCounterReset());
  }

  if (feature === FeatureFlag.privateGroupChat) {
    yield put(groupChatCounterReset());
  }
}
