import { CaptionsContainer } from 'features/transcripts/slices/captions/CaptionsContainer';
import { selectMaximizedContent } from 'features/layout/features/content/contentSlice';
import { GridPanel } from 'features/layout/GridPanel/GridPanel';
import React from 'react';
import { useAppSelector } from 'store/hooks';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import RoomGrid from 'features/layout/layouts/TiledWithContent/RoomGrid';
import { LayoutProvider } from 'features/layout/LayoutRoot/LayoutProvider';
import DebugTotalBandwidth from 'features/dev-mode/components/DebugTotalBandwidth';
import { selectDevModeEnabled } from 'features/dev-mode/devModeSlice';
import ActionNotificationContainer from 'features/notifications/components/ActionNotificationContainer';
import { useResizeDetector } from 'react-resize-detector';
import { FeatureGuard } from 'features/feature-set/FeatureGuard';
import MinimizedLocalTile from 'features/streaming/components/stream-display/MinimizedLocalTile';
import { ToastContainer } from 'features/notifications/toast/ToastContainer';
import { simpleToastTransition } from 'features/notifications/toast/transitions';
import { SIMPLE_NOTIFICATION_CONTAINER_ID } from 'features/notifications/constants';
import { selectToolbarPlacement } from 'features/toolbar/toolbarSlice';

const FlexBox = styled(Box)({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
});

const Root = styled(FlexBox)(({ theme }) => ({
  padding: `${theme.room.tileGap}px`,
  position: 'relative',

  '& .fullscreen-panel': {
    position: 'absolute',
    top: `${theme.room.tileGap}px`,
    left: `${theme.room.tileGap}px`,
    width: `calc(100% - ${theme.room.tileGap * 2}px)`,
    height: `calc(100% - ${theme.room.tileGap * 2}px)`,
    zIndex: 500,
  },

  '&.maximized-stream .react-resizable': {
    position: 'static',
  },
}));

const LayoutRoot = () => {
  const devModeEnabled = useAppSelector(selectDevModeEnabled);

  const maximizedContent = useAppSelector(selectMaximizedContent);

  const toolbarPlacement = useAppSelector(selectToolbarPlacement);

  const {
    width: outerWidth,
    height: outerHeight,
    ref: outerSizeRef,
  } = useResizeDetector({
    handleWidth: true,
    handleHeight: true,
    refreshMode: 'debounce',
    refreshRate: 16,
  });

  const {
    width,
    height,
    ref: targetRef,
  } = useResizeDetector({
    handleWidth: true,
    handleHeight: true,
    refreshMode: 'debounce',
    refreshRate: 16,
  });

  return (
    <Root
      ref={outerSizeRef}
      className={`layout-root ${maximizedContent ? `maximized-${maximizedContent}` : ''}`}
    >
      <ActionNotificationContainer />
      <ToastContainer
        colorTheme="dark"
        placement={toolbarPlacement}
        transition={simpleToastTransition}
        position="bottom-left"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        limit={5}
        closeButton={false}
        className="simple-toast-container"
        containerId={SIMPLE_NOTIFICATION_CONTAINER_ID}
      />
      <GridPanel roomSize={{ width: outerWidth, height: outerHeight }} />
      <FlexBox
        ref={targetRef}
        sx={{
          position: maximizedContent === 'stream' ? 'static' : 'relative',
        }}
      >
        <LayoutProvider size={{ width, height }}>
          <RoomGrid />
        </LayoutProvider>
        <FeatureGuard feature="minimizeOwnTile">
          <MinimizedLocalTile />
        </FeatureGuard>
      </FlexBox>
      {devModeEnabled && <DebugTotalBandwidth />}
      {outerWidth !== undefined && outerHeight !== undefined ? (
        <CaptionsContainer roomWidth={outerWidth} roomHeight={outerHeight} />
      ) : null}
    </Root>
  );
};

export default LayoutRoot;
