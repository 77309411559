import { Box } from '@mui/material';
import SettingControl from 'components/SettingControl';
import { Switch } from 'components/Switch';
import {
  preserveAspectRatioToggled,
  selectPreserveAspectRatio,
} from 'features/streaming/streamingSlice';
import { useVideoInputField } from 'hooks/useVideoInputField';
import VideoRenderer from 'components/VideoRenderer';
import { MediaDeviceChangeHandler } from 'features/join/publisher/JoinPreview';
import { Control } from 'react-hook-form/dist/types/form';
import { TabPanelInner } from 'components/Tabs';
import DeviceSelect from 'components/DeviceSelect';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export type VideoTabProps = {
  videoStream: MediaStream | null;
  onVideoInputChange: MediaDeviceChangeHandler;
  control: Control<any>;
  cameraDisabled?: boolean;
  cameraToggle?: React.ReactNode;
};

const VideoTab = ({
  videoStream,
  onVideoInputChange,
  cameraDisabled,
  control,
  cameraToggle,
}: VideoTabProps) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation('settings');
  const { videoInputOptions, videoInputPlaceholder } = useVideoInputField();

  const preserveAspectRatio = useAppSelector(selectPreserveAspectRatio);

  const handlePreserveAspectRatioChange = () => {
    dispatch(preserveAspectRatioToggled());
  };

  return (
    <TabPanelInner>
      <VideoRenderer mediaStream={videoStream} sx={{ mb: 8 }} />
      {cameraToggle && cameraToggle}
      <DeviceSelect
        id="settings-video-select"
        name="videoinput"
        control={control}
        icon="camera"
        onChange={onVideoInputChange}
        options={videoInputOptions}
        disabled={cameraDisabled}
        placeholder={videoInputPlaceholder}
        ariaLabel={t('device_dropdowns.videoinput.aria_label')}
      />

      <Box sx={{ mt: 8 }}>
        <SettingControl
          label={t('video.preserve_aspect_ratio')}
          alignItems="flex-start"
          control={
            <Switch
              onChange={handlePreserveAspectRatioChange}
              checked={preserveAspectRatio}
              inputProps={{
                'aria-label': preserveAspectRatio
                  ? t('video.preserve_aspec_ratio_disable_aria_label')
                  : t('video.preserve_aspec_ratio_enable_aria_label'),
              }}
            />
          }
        />
      </Box>
    </TabPanelInner>
  );
};

export default VideoTab;
