import { useToolbarPanel } from 'hooks/useToolbarPanel';
import { usePolls } from 'features/polls/usePolls';
import { useAppSelector } from 'store/hooks';
import { selectUnreadPollUpdates } from 'features/polls/pollsSlice';

export const usePollsControl = () => {
  const { toolbarPanel, togglePanel } = useToolbarPanel();
  const unreadUpdates = useAppSelector(selectUnreadPollUpdates);

  const { isFeatureAvailable } = usePolls();

  const toggleChatPanel = () => {
    togglePanel('polls');
  };

  return {
    active: toolbarPanel === 'polls',
    togglePanel: toggleChatPanel,
    icon: 'polls',
    isFeatureAvailable,
    unreadUpdates,
  };
};
