import { styled } from '@mui/material/styles';
import { styleConstants } from 'theme/styleConstants';
import { Box } from '@mui/material';

export const JoinScreenWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  textAlign: 'left',
});

export const JoinScreenInner = styled(Box)({
  maxWidth: styleConstants.narrowContainerWidth,
  margin: 'auto',
});

export const JoinScreenInnerWide = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: styleConstants.narrowContainerWidth,
  margin: 'auto',
  [theme.breakpoints.up('lg')]: {
    maxWidth: 'none',
  },
}));
