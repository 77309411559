import { styled } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import { GlobalStyles } from '@mui/material';
import React from 'react';
import { styleConstants } from 'theme/styleConstants';

const bodyStyles = (
  <GlobalStyles
    styles={{
      ':root': {
        '--toolbar-width': `${styleConstants.toolbar.width}px`,
        '--toolbar-panel-width': `${styleConstants.toolbarPanel.width}px`,
        '--toolbar-inner-padding': `${styleConstants.toolbar.innerPadding}px`,
        '--toolbar-item-padding': `${styleConstants.toolbar.item.padding}px`,
        '--room-layout-padding': `${styleConstants.layout.innerPadding}px`,
        '--room-container-padding': `${styleConstants.layout.containerPadding}px`,
      },
    }}
  />
);

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  '.bg-blurred #root &': {
    position: 'relative',
    pointerEvents: 'none',
    '@supports not (backdrop-filter: none)': {
      filter: 'blur(10px)',
    },
    '@supports (backdrop-filter: none)': {
      '&:after': {
        content: '""',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: theme.zIndex.tooltip + 1,
        bottom: 0,
        right: 0,
        backdropFilter: 'blur(10px)',
      },
    },
  },
}));

const GlobalLayout = () => (
  <Root>
    {bodyStyles}
    <Outlet />
  </Root>
);

export default GlobalLayout;
