import { call, put, select } from 'redux-saga/effects';
import { selectRecorderStatus, stopRecording } from 'features/recorder/recorderSlice';
import { openModal } from 'features/modal/modalSlice';
import { eventBus } from 'utils/eventBus';
import { logger } from 'utils/logger';
import { RecorderStatus } from 'features/recorder/types';
import { signalingRecordingFailed } from 'features/recorder/actions';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';

export function* onRecordingFailureSaga(action: ReturnType<typeof signalingRecordingFailed>) {
  logger.remote().error('Recording failure');

  const reason = action.payload?.reason;

  yield call(eventBus.sendMessage, 'recordingFailed', {}, reason ? { reason } : undefined);

  yield put(stopRecording());

  if (reason === 'total_free_minutes_exceeded') {
    const canManageRecording: boolean = yield call(hasPermissionsSaga, PermissionTypes.recording);
    if (canManageRecording) {
      yield put(openModal('recordingFreePlanTotalLimit'));
    }

    return;
  }

  const recordingStatus: RecorderStatus = yield select(selectRecorderStatus);
  if (recordingStatus === 'started') {
    yield put(openModal('recordingFailureRuntime'));
  } else {
    yield put(openModal('recordingFailure'));
  }
}
