import { useForm } from 'react-hook-form';
import { PreviewFormValues } from 'features/join/publisher/JoinPreview';
import { useCallback, useEffect } from 'react';
import {
  enumerateDevices,
  getDefaultMediaDevice,
  getDevicePermissions,
} from 'features/user-media/utils';
import { findDeviceDifferences } from 'features/user-media/utils/findDeviceDifferences';
import * as Sentry from '@sentry/react';
import { activeMediaDeviceUpdated, mediaDevicesUpdated } from 'features/user-media/userMediaSlice';
import { useAppDispatch } from 'store/hooks';
import { useMediaDevices } from 'hooks/useMediaDevices';
import { useBroadcastSetup } from 'utils/broadcast-setup/context';

// This hook manages preview form values and synchronizes broadcast state with media device changes
export const useBroadcastSetupPreview = () => {
  const appDispatch = useAppDispatch();

  const { mediaDevices, activeMediaDevices, micPermissions, camPermissions } = useMediaDevices();

  const {
    audioStream,
    videoStream,
    dispatch,
    disableAudio,
    enableAudio,
    enableCamera,
    disableCamera,
  } = useBroadcastSetup();

  const formMethods = useForm<PreviewFormValues>({
    defaultValues: {
      audioinput: activeMediaDevices.audioinput || '',
      audiooutput: activeMediaDevices.audiooutput || '',
      videoinput: activeMediaDevices.videoinput || '',
    },
  });

  const { setValue } = formMethods;

  const activateMic = useCallback(
    async (deviceId: string) => {
      await enableAudio(deviceId);
      setValue('audioinput', deviceId);
    },
    [enableAudio, setValue]
  );

  const deactivateMic = useCallback(() => {
    dispatch({ type: 'mediaDeactivated', payload: 'audio' });
  }, [dispatch]);

  const activateCamera = useCallback(
    async (deviceId: string) => {
      await enableCamera(deviceId);
      setValue('videoinput', deviceId);
    },
    [enableCamera, setValue]
  );

  const deactivateCamera = useCallback(() => {
    dispatch({ type: 'mediaDeactivated', payload: 'video' });
  }, [dispatch]);

  useEffect(() => {
    const listener = async () => {
      const updatedDevices = await enumerateDevices();
      const { removedActiveDevices, addedDevices } = findDeviceDifferences(
        updatedDevices,
        mediaDevices,
        activeMediaDevices
      );

      if (Object.keys(removedActiveDevices).length) {
        if (removedActiveDevices.audioinput) {
          try {
            const deviceId = getDefaultMediaDevice(updatedDevices, 'audioinput');

            if (!deviceId) {
              throw new Error('Could not find audio device');
            }

            disableAudio();
            await activateMic(deviceId);
          } catch (error) {
            Sentry.captureException(error);
            deactivateMic();
          }
        }

        if (removedActiveDevices.videoinput) {
          try {
            const deviceId = getDefaultMediaDevice(updatedDevices, 'videoinput');

            if (!deviceId) {
              throw new Error('Could not find video device');
            }

            await disableCamera();
            await activateCamera(deviceId);
          } catch (error) {
            Sentry.captureException(error);
            deactivateCamera();
          }
        }
      } else if (Object.keys(addedDevices).length) {
        if (micPermissions !== 'granted' && addedDevices.audioinput) {
          try {
            const permissions = getDevicePermissions(updatedDevices, 'audioinput');

            if (permissions === 'granted') {
              const deviceId = getDefaultMediaDevice(updatedDevices, 'audioinput');

              if (!deviceId) {
                throw new Error('Could not find audio device');
              }

              await activateMic(deviceId);
            }
          } catch (error) {
            Sentry.captureException(error);
            deactivateMic();
          }
        }

        if (camPermissions !== 'granted' && addedDevices.videoinput) {
          try {
            const permissions = getDevicePermissions(updatedDevices, 'videoinput');

            if (permissions === 'granted') {
              const deviceId = getDefaultMediaDevice(updatedDevices, 'videoinput');

              if (!deviceId) {
                throw new Error('Could not find video device');
              }

              await activateCamera(deviceId);
            }
          } catch (error) {
            Sentry.captureException(error);
            deactivateCamera();
          }
        }
      }

      if (removedActiveDevices.audiooutput || addedDevices.audiooutput) {
        const deviceId = getDefaultMediaDevice(updatedDevices, 'audiooutput');

        if (!deviceId) {
          throw new Error('Error updating media devices');
        }

        appDispatch(
          activeMediaDeviceUpdated({
            kind: 'audiooutput',
            id: deviceId,
          })
        );

        setValue('audiooutput', deviceId);
      }

      appDispatch(
        mediaDevicesUpdated(updatedDevices, {
          updatePermissions: true,
        })
      );
    };

    navigator.mediaDevices.addEventListener('devicechange', listener);
    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', listener);
    };
  }, [
    activateCamera,
    activateMic,
    activeMediaDevices,
    camPermissions,
    deactivateCamera,
    deactivateMic,
    disableCamera,
    disableAudio,
    appDispatch,
    mediaDevices,
    micPermissions,
    setValue,
  ]);

  useEffect(() => {
    const videoTrack = videoStream?.getVideoTracks()[0];
    if (!videoTrack) {
      return undefined;
    }

    const listener = async () => {
      const updatedDevices = await enumerateDevices();
      const permissions = getDevicePermissions(updatedDevices, 'videoinput');

      if (permissions !== 'granted') {
        appDispatch(
          mediaDevicesUpdated(updatedDevices, {
            updateActiveDevices: true,
            updatePermissions: true,
          })
        );

        setValue('videoinput', '');
        deactivateCamera();
        await disableCamera();
      }
    };

    videoTrack.addEventListener('ended', listener);

    return () => {
      videoTrack.removeEventListener('ended', listener);
    };
  }, [deactivateCamera, disableCamera, appDispatch, setValue, videoStream]);

  useEffect(() => {
    const audioTrack = audioStream?.getAudioTracks()[0];
    if (!audioTrack) {
      return undefined;
    }

    const listener = async () => {
      const updatedDevices = await enumerateDevices();
      const permissions = getDevicePermissions(updatedDevices, 'audioinput');

      if (permissions !== 'granted') {
        appDispatch(
          mediaDevicesUpdated(updatedDevices, {
            updateActiveDevices: true,
            updatePermissions: true,
          })
        );

        setValue('audioinput', '');
        setValue('audiooutput', '');
        deactivateMic();
        disableAudio();
      }
    };

    audioTrack.addEventListener('ended', listener);

    return () => {
      audioTrack.removeEventListener('ended', listener);
    };
  }, [deactivateMic, disableAudio, appDispatch, setValue, audioStream]);

  return formMethods;
};
