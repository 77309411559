import {
  BreakoutRoomAllIds,
  BreakoutRoomByUser,
  BreakoutRoomEntries,
  BreakoutRoomWithUserEntries,
  NormalizedRoomsState,
} from 'features/breakout-rooms/types';
import { UserEntries } from 'features/users/types';

export const normalizeRooms = (rooms: BreakoutRoomWithUserEntries[]): NormalizedRoomsState => {
  const byId: BreakoutRoomEntries = {};
  const allIds: BreakoutRoomAllIds = [];
  const users: UserEntries = {};
  const roomByUser: BreakoutRoomByUser = {};

  for (const room of rooms) {
    const userIds: string[] = [];

    for (const user of room.users) {
      users[user.id] = user;
      userIds.push(user.id);
      roomByUser[user.id] = room.id;
    }

    byId[room.id] = { id: room.id, name: room.name, users: userIds };
    allIds.push(room.id);
  }

  return { byId, allIds, users, roomByUser };
};
