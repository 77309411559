import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  clonePollRequested,
  createPollRequested,
  deletePollRequested,
  editPollRequested,
  endPollRequested,
  exportSinglePollRequested,
  launchPollRequested,
  sharePollResultsRequested,
  signalingPollDeleted,
  signalingPollEnded,
  signalingPollLaunched,
  signalingPollResultsShared,
  signalingPollResultsSharingStopped,
  stopSharingPollResultsRequested,
  votePollRequested,
} from 'features/polls/actions';
import { createPollSaga } from 'features/polls/sagas/createPollSaga';
import { launchPollSaga } from 'features/polls/sagas/launchPollSaga';
import { endPollSaga } from 'features/polls/sagas/endPollSaga';
import { deletePollSaga } from 'features/polls/sagas/deletePollSaga';
import { votePollSaga } from 'features/polls/sagas/votePollSaga';
import { sharePollResultsSaga } from 'features/polls/sagas/sharePollResultsSaga';
import { stopSharingPollResultsSaga } from 'features/polls/sagas/stopSharingPollResultsSaga';
import { onPollResultsSharedSaga } from 'features/polls/sagas/onPollResultsSharedSaga';
import { onPollResultsSharingStoppedSaga } from 'features/polls/sagas/onPollResultsSharingStoppedSaga';
import { onPollLaunchedSaga } from 'features/polls/sagas/onPollLaunchedSaga';
import { editPollSaga } from 'features/polls/sagas/editPollSaga';
import { onPollDeletedSaga } from 'features/polls/sagas/onPollDeletedSaga';
import { onPollEndedSaga } from 'features/polls/sagas/onPollEndedSaga';
import { clonePollSaga } from 'features/polls/sagas/clonePollSaga';
import { exportPollResultsRequested } from 'features/polls/pollsSlice';
import { exportPollsSaga } from 'features/polls/sagas/exportPollsSaga';
import { exportSinglePollSaga } from 'features/polls/sagas/exportSinglePollSaga';

export default function* pollsWatcher() {
  yield takeEvery(createPollRequested, createPollSaga);
  yield takeEvery(editPollRequested, editPollSaga);
  yield takeEvery(votePollRequested, votePollSaga);

  yield takeEvery(clonePollRequested, clonePollSaga);

  yield takeEvery(launchPollRequested, launchPollSaga);
  yield takeEvery(signalingPollLaunched, onPollLaunchedSaga);

  yield takeEvery(endPollRequested, endPollSaga);
  yield takeEvery(signalingPollEnded, onPollEndedSaga);

  yield takeEvery(deletePollRequested, deletePollSaga);
  yield takeEvery(signalingPollDeleted, onPollDeletedSaga);

  yield takeEvery(sharePollResultsRequested, sharePollResultsSaga);
  yield takeEvery(signalingPollResultsShared, onPollResultsSharedSaga);
  yield takeEvery(stopSharingPollResultsRequested, stopSharingPollResultsSaga);
  yield takeEvery(signalingPollResultsSharingStopped, onPollResultsSharingStoppedSaga);

  yield takeLatest(exportPollResultsRequested, exportPollsSaga);
  yield takeLatest(exportSinglePollRequested, exportSinglePollSaga);
}
