import { useBroadcastSetup } from 'utils/broadcast-setup';
import React, { useState } from 'react';
import { Tab, TabPanel } from 'components/Tabs';
import VideoTab from 'features/join/publisher/settings/VideoTab';
import AudioTab from 'features/room/settings/tabs/AudioTab';
import VirtualBackground from 'features/join/publisher/settings/VirtualBackground';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { PreviewFormValues } from 'features/join/publisher/JoinPreview';
import Popper from 'components/Popper';
import Icon from 'components/Icon';
import { useMediaDevices } from 'hooks/useMediaDevices';
import BlockedCameraPopper from 'features/join/publisher/components/poppers/BlockedCameraPopper';
import UnavailableCameraPopper from 'features/join/publisher/components/poppers/UnavailableCameraPopper';
import BlockedMicPopper from 'features/join/publisher/components/poppers/BlockedMicPopper';
import UnavailableMicPopper from 'features/join/publisher/components/poppers/UnavailableMicPopper';
import { TooltipIconTrigger } from 'components/TooltipIconTrigger';
import { Box } from '@mui/material';
import { StyledSettingsTabs } from 'components/StyledSettingsTabs';
import { styled } from '@mui/material/styles';
import { useAppSelector } from 'store/hooks';
import { selectVirtualBackgroundAvailable } from 'features/virtual-backgrounds/selectors';
import { Switch } from 'components/Switch';
import SettingControl from 'components/SettingControl';

const Root = styled('div')({
  '.TabPanelInner-root': {
    margin: '0 auto',
  },
});

const BroadcastSettings = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation(['room', 'settings']);

  const { control } = useFormContext<PreviewFormValues>();

  const {
    video,
    audio,
    audioStream,
    videoStream,
    onVideoInputChange,
    onAudioOutputChange,
    onAudioInputChange,
    onVideoToggle,
    onAudioToggle,
  } = useBroadcastSetup();

  const { micPermissions, camPermissions } = useMediaDevices();

  const VBAvailable = useAppSelector(selectVirtualBackgroundAvailable);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const renderCameraPopover = () =>
    camPermissions === 'denied' ? <BlockedCameraPopper /> : <UnavailableCameraPopper />;

  const renderCameraToggle = () => (
    <Box
      sx={{
        mb: 7,
      }}
    >
      <SettingControl
        label={t('broadcast_setup.preview.camera_toggle.label')}
        control={
          <Switch
            onChange={(e) => onVideoToggle(e.target.checked)}
            checked={video.active}
            inputProps={{
              'aria-label': video.active
                ? t('broadcast_setup.preview.camera_toggle.enabled_aria_label')
                : t('broadcast_setup.preview.camera_toggle.disabled_aria_label'),
            }}
            disabled={video.disabled}
          />
        }
        infoIcon={
          camPermissions !== 'granted' ? (
            <Popper
              id={camPermissions === 'denied' ? 'blockedCamera' : 'unavailableCamera'}
              placement="right"
              content={renderCameraPopover()}
            >
              <TooltipIconTrigger>
                <Icon name="warning" />
              </TooltipIconTrigger>
            </Popper>
          ) : undefined
        }
      />
    </Box>
  );
  const renderMicPopover = () =>
    micPermissions === 'denied' ? <BlockedMicPopper /> : <UnavailableMicPopper />;

  const renderMicToggle = () => (
    <Box
      sx={{
        mb: 7,
      }}
    >
      <SettingControl
        label={t('broadcast_setup.preview.mic_toggle.label')}
        control={
          <Switch
            onChange={(e) => onAudioToggle(e.target.checked)}
            checked={audio.active}
            inputProps={{
              'aria-label': audio.active
                ? t('broadcast_setup.preview.mic_toggle.enabled_aria_label')
                : t('broadcast_setup.preview.mic_toggle.disabled_aria_label'),
            }}
            disabled={audio.disabled}
          />
        }
        infoIcon={
          micPermissions !== 'granted' ? (
            <Popper
              id={camPermissions === 'denied' ? 'blockedMic' : 'unavailableMic'}
              placement="right"
              content={renderMicPopover()}
            >
              <TooltipIconTrigger>
                <Icon name="warning" />
              </TooltipIconTrigger>
            </Popper>
          ) : undefined
        }
      />
    </Box>
  );

  return (
    <Root>
      <StyledSettingsTabs
        variant="scrollable"
        value={activeTab}
        onChange={handleTabChange}
        aria-label={t('settings:tabs_aria_label')}
      >
        <Tab
          label={t('settings:video.tab_label')}
          value={0}
          id="video-tab"
          aria-controls="video-tabpanel"
        />
        <Tab
          label={t('settings:audio.tab_label')}
          value={1}
          id="audio-tab"
          aria-controls="audio-tabpanel"
        />
        {VBAvailable && (
          <Tab
            label={t('settings:virtual_bg.tab_label')}
            value={2}
            id="vb-tab"
            aria-controls="vb-tabpanel"
          />
        )}
      </StyledSettingsTabs>
      <TabPanel value={activeTab} index={0} id="video-tabpanel" aria-labelledby="video-tab">
        <VideoTab
          cameraToggle={renderCameraToggle()}
          control={control}
          videoStream={videoStream}
          onVideoInputChange={onVideoInputChange}
          cameraDisabled={video.disabled}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={1} id="audio-tabpanel" aria-labelledby="audio-tab">
        <AudioTab
          micToggle={renderMicToggle()}
          vuMeterActive={audio.active}
          onMicChange={onAudioInputChange}
          onOutputChange={onAudioOutputChange}
          micDisabled={audio.disabled}
          control={control}
          audioStream={audioStream}
          allowBroadcast
        />
      </TabPanel>
      {VBAvailable && (
        <TabPanel value={activeTab} index={2} id="vb-tabpanel" aria-labelledby="vb-tab">
          <VirtualBackground />
        </TabPanel>
      )}
    </Root>
  );
};

export default BroadcastSettings;
