import React from 'react';
import { Box, BoxProps, FormHelperText, Typography } from '@mui/material';
import { Properties } from 'csstype';

export type SettingControlProps = BoxProps<
  'div',
  {
    label: string;
    control: React.ReactNode;
    helperText?: string;
    infoIcon?: React.ReactNode;
    alignItems?: Properties['alignItems'];
    className?: 'string';
  }
>;

const SettingControl = ({
  infoIcon,
  control,
  label,
  helperText,
  alignItems = 'center',
  sx = [],
  ...props
}: SettingControlProps) => (
  <Box
    sx={[
      {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '8px',
        alignItems,
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...props}
  >
    <div className="SettingControl-label-wrapper">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography>{label}</Typography>
        {infoIcon && infoIcon}
      </Box>
      {helperText && (
        <FormHelperText
          sx={{
            whiteSpace: 'pre-wrap',
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </div>
    <div>{control}</div>
  </Box>
);

export default SettingControl;
