import { UserId } from 'features/users/types';
import { EventUser } from 'services/signaling';

export type ChatMessageId = string;

export interface ChatMessageUser extends EventUser {
  externalId?: string;
}

export interface ChatMessage {
  id: ChatMessageId;
  message: string;
  date: string;
  local: boolean;
  user: ChatMessageUser;
  targetUser?: ChatMessageUser;
  privateGroup?: boolean;
}

export type PrivateChatActiveView = 'list' | 'personal';

export interface PrivateChatActiveViewState {
  name: PrivateChatActiveView;
  data: Record<string, any>;
}

export interface PrivateGroupChatState {
  id: string;
  name: string;
  roles: string[];
  avatarColor: string;
}

export interface PrivateChatState {
  chats: Record<
    string,
    {
      lastActivity: string;
      messages: ChatMessageId[];
      group?: boolean;
      externalId?: string;
    }
  >;
  activeView: PrivateChatActiveViewState;
  groupChat: PrivateGroupChatState;
}

export enum ChatTab {
  public,
  private,
}

export interface ChatState {
  panelOpen: boolean;
  activeTab: ChatTab;
  localUserId: UserId;
  messageSentWithCurrentKey: boolean;
  unreadCounters: Record<string, number>;
  readMessages: Record<ChatMessageId, boolean>;
  entities: Record<string, ChatMessage>;
  publicIds: string[];
  privateChat: PrivateChatState;
  senders: Record<UserId, ChatMessageUser>;
  localUserExternalId?: string;
}

export interface NewMessagePayload {
  message: string;
  targetUserId?: UserId;
  privateGroup?: boolean;
}

export interface ChatMessageDeletedPayload {
  id: string;
  userId: UserId;
  targetUserId?: UserId;
  privateGroup?: boolean;
}

export interface PrivateChatOpenedPayload {
  id: string;
  group?: boolean;
}

export type UnreadMessageCounterUpdatedPayload = {
  key: string;
  modifier: number;
};

export interface PrivateChatView {
  id: string;
  name: string | null;
  disabled: boolean;
  messages: ChatMessage[];
  avatarColor: string;
  initials?: string;
  avatarUrl?: string;
  group?: boolean;
  targetId?: string;
}

export interface TargetChatItem extends Omit<PrivateChatView, 'name' | 'messages' | 'disabled'> {
  name: string;
  targetId: string;
}
