import { FeedId } from 'features/streaming/types';
import { selectReceivingStatsByUser } from 'features/system-stats/systemStatsSlice';
import { UserId } from 'features/users/types';
import { useAppSelector } from 'store/hooks';
import { Box, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export type FeedMetaProps = {
  userId: UserId;
  feedId?: FeedId;
};

const Text = styled(Typography)({
  fontSize: 12,
});

const FeedMeta = ({ userId, feedId }: FeedMetaProps) => {
  const meta = useAppSelector((state) => selectReceivingStatsByUser(state, userId, feedId));

  const renderVideoStats = () => {
    if (!meta.videoBitrate) {
      return null;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Box minWidth={150}>
          <Text>
            <span>Res:</span> {meta.videoWidth}x{meta.videoHeight}
          </Text>
          <Text>
            <span>Video bitrate:</span> {meta.videoBitrate} kb/s
          </Text>
        </Box>
        <div>
          <Text>
            <span>Codec:</span> {meta.codec}
          </Text>
          <Text>
            <span>FPS:</span> {meta.fps}
          </Text>
        </div>
      </Box>
    );
  };

  return (
    <Paper
      sx={{
        p: 1,
        mb: 2,
        opacity: meta.videoBitrate || meta.audioBitrate ? 1 : 0,
        span: {
          fontWeight: 700,
        },
      }}
    >
      {renderVideoStats()}
      {meta.audioBitrate ? (
        <Text>
          <span>Audio bitrate:</span> {meta.audioBitrate} kb/s
        </Text>
      ) : null}
    </Paper>
  );
};

export default FeedMeta;
