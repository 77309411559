import { IconButton } from '@mui/material';
import { usePiPWindow } from 'components/PiP/PiPProvider';
import PiPWindow from 'components/PiP/PiPWindow';
import { PipLayout } from 'features/layout/features/pictureInPicture/PipLayout';
import { TopbarControl } from 'features/room/components/topbar/TopbarControl';
import { useBreakpoints } from 'hooks/useBreakpoints';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';

function useResizablePipWindow(pipWindow: Window | null) {
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (!pipWindow) return;

    const handleResize = throttle(
      (e) => {
        if (!e.target) return;

        // if ((e.target as Window).innerWidth < 400) {
        //   e.target.resizeTo(400, e.target.innerHeight);
        // }

        setSize({
          width: (e.target as Window).innerWidth,
          height: (e.target as Window).innerHeight,
        });
      },
      1000,
      { leading: true, trailing: false }
    );

    const handleResizeEnd = debounce(() => {
      setSize({
        width: pipWindow.innerWidth,
        height: pipWindow.innerHeight,
      });
    }, 100);

    pipWindow.addEventListener('resize', handleResize);
    pipWindow.addEventListener('resize', handleResizeEnd);

    // eslint-disable-next-line consistent-return
    return () => {
      pipWindow.removeEventListener('resize', handleResize);
      pipWindow.removeEventListener('resize', handleResizeEnd);
    };
  }, [pipWindow]);

  return size;
}

export const PiPControl = () => {
  const { pipWindow, requestPipWindow, closePipWindow } = usePiPWindow();
  const { t } = useTranslation('room');

  const handleToggle = () => {
    if (pipWindow === null) {
      requestPipWindow(500, 500);
    } else {
      closePipWindow();
    }
  };

  const renderTooltipText = () =>
    pipWindow === null ? t('topbar.enter_pip') : t('topbar.exit_pip');

  const { isMobile } = useBreakpoints();

  const renderIcon = () => <Icon name={pipWindow === null ? 'pip' : 'pip-exit'} />;

  const renderButtonText = () => {
    if (isMobile) {
      return renderIcon();
    }

    return pipWindow === null ? t('topbar.enter_pip') : t('topbar.exit_pip');
  };

  const size = useResizablePipWindow(pipWindow);

  const renderControl = () =>
    isMobile ? (
      <TopbarControl
        data-testid="pip-control"
        as={IconButton}
        onClick={handleToggle}
        sx={{ fontSize: 24 }}
      >
        {renderIcon()}
      </TopbarControl>
    ) : (
      <TopbarControl
        data-testid="pip-control"
        onClick={handleToggle}
        startIcon={renderIcon()}
        sx={{
          py: 1,
          px: 4,
          borderRadius: '20px',
          fontSize: 14,
        }}
      >
        {renderButtonText()}
      </TopbarControl>
    );

  return (
    <>
      <Tooltip title={renderTooltipText()} placement="bottom">
        {renderControl()}
      </Tooltip>

      {pipWindow && (
        <PiPWindow pipWindow={pipWindow}>
          <div className="pipRoot">
            <PipLayout {...size} />
          </div>
        </PiPWindow>
      )}
    </>
  );
};
