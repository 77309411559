import { createAction, prepareAutoBatched } from '@reduxjs/toolkit';
import { UserId } from 'features/users/types';
import { ActiveMediaDevicePayload } from 'features/user-media/types';
import {
  ActiveSpeakerChanged,
  DeviceMutedRemotely,
  RemoteTrackReceived,
  RTCEventPayload,
  ScreenshareStopped,
  StreamingErrorMessage,
  StreamingStarted,
  StreamingStopped,
} from './types';

export const videoroomError = createAction<RTCEventPayload>('streaming/videoroomError');
export const streamingError = createAction<StreamingErrorMessage>('streaming/streamingError');

// streaming lifecycle events
export const streamStarted = createAction<StreamingStarted>('signaling/streamStarted');
export const streamStopped = createAction<StreamingStopped>('signaling/streamStopped');

export const screenshareStarted = createAction<StreamingStarted>('signaling/screenshareStarted');
export const screenshareStopped = createAction<ScreenshareStopped>('signaling/screenshareStopped');

export const publisherJoined = createAction<RTCEventPayload>('streaming/publisherJoined');
export const publisherLeft = createAction<RTCEventPayload>('streaming/publisherLeft');

export const subscriberAttached = createAction<RTCEventPayload>('streaming/subscriberAttached');

export const signalingAspectRatioAdjusted = createAction<{ id: UserId; keep: boolean }>(
  'signaling/aspectRatioAdjusted'
);

export const activeSpeakerChanged = createAction(
  'signaling/activeSpeakerChanged',
  prepareAutoBatched<ActiveSpeakerChanged>()
);
export const streamingDeviceChanged = createAction<ActiveMediaDevicePayload>(
  'streaming/streamingDeviceChanged'
);

// init events
export const streamingConnectionEstablished = createAction('streaming/connectionEstablished');

export const publishingConnectionEstablished = createAction(
  'streaming/publishingConnectionEstablished'
);

// local interactions
export const localVideoToggled = createAction('streaming/localVideoToggled');
export const localAudioToggled = createAction('streaming/localAudioToggled');
export const localScreenshareToggled = createAction('streaming/localScreenshareToggled');

// remote audio mute
export const muteRemoteAudio = createAction<UserId>('streaming/muteRemoteAudio');
export const audioMutedRemotely = createAction<DeviceMutedRemotely>('signaling/audioMutedRemotely');

// remove audio unmute
export const askUnmuteRemoteAudio = createAction<UserId>('streaming/askUnmuteRemoteAudio');
export const remoteAudioUnmuteAccepted = createAction('streaming/remoteAudioUnmuteAccepted');
export const audioUnmuteAsked = createAction<DeviceMutedRemotely>('signaling/audioUnmuteAsked');
export const bulkMuteRemoteAudio = createAction('streaming/bulkMuteRemoteAudio');

// remote video mute
export const muteRemoteVideo = createAction<UserId>('streaming/muteRemoteVideo');
export const videoMutedRemotely = createAction<DeviceMutedRemotely>('signaling/videoMutedRemotely');

// remove video unmute
export const askUnmuteRemoteVideo = createAction<UserId>('streaming/askUnmuteRemoteVideo');
export const remoteVideoUnmuteAccepted = createAction('streaming/remoteVideoUnmuteAccepted');
export const videoUnmuteAsked = createAction<DeviceMutedRemotely>('signaling/videoUnmuteAsked');

export const remoteTrackReceived = createAction<RemoteTrackReceived>(
  'streaming/remoteTrackReceived'
);

export const receivingPluginCleanup = createAction<string>('streaming/receavingPluginCleanup');

export const pluginHandleAttachFailed = createAction<string>('streaming/pluginHandleAttachFailed');

export const publisherJoinedWithoutBroadcast = createAction(
  'streaming/publisherJoinedWithoutBroadcast'
);
