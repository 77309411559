import { PayloadAction } from '@reduxjs/toolkit';
import { call, select } from 'redux-saga/effects';
import { ActiveMediaDevicePayload, EnumeratedMediaDevices } from 'features/user-media/types';
import { RTCClient } from 'utils/webrtc';
import { logger } from 'utils/logger';
import { selectMediaDevicesByKind } from 'features/user-media/userMediaSlice';

export function* changeStreamingDeviceSaga(action: PayloadAction<ActiveMediaDevicePayload>) {
  const currentMediaDevices: EnumeratedMediaDevices = yield select(selectMediaDevicesByKind);

  logger
    .remote({ action: true })
    .info(
      `User changed ${action.payload.kind} media device from ${
        currentMediaDevices[action.payload.kind]
      } to ${action.payload.id}`
    );

  if (action.payload.kind === 'audioinput') {
    yield call(RTCClient.publishingFeed.changeAudioDevice, action.payload);
  }

  if (action.payload.kind === 'videoinput') {
    yield call(RTCClient.publishingFeed.changeVideoDevice, action.payload);
  }
}
