import { AppThunk } from 'store/store';
import {
  selectSimpleNotificationById,
  simpleNotificationUpdated,
} from 'features/notifications/notificationsSlice';
import { NotificationId, SimpleNotificationParams } from 'features/notifications/types';
import { notification } from 'features/notifications/toast/notification';
import { ToastOptions } from 'react-toastify';
import { SIMPLE_NOTIFICATION_CONTAINER_ID } from 'features/notifications/constants';

export const updateSimpleNotification =
  (
    id: NotificationId,
    data: Partial<SimpleNotificationParams>,
    toastOptions?: ToastOptions
  ): AppThunk<void | NotificationId> =>
  (dispatch, getState) => {
    const simpleNotification = selectSimpleNotificationById(getState(), id);
    if (!simpleNotification) {
      // nothing to update
      return;
    }

    notification.update(id, {
      ...toastOptions,
      render: data.content,
      containerId: SIMPLE_NOTIFICATION_CONTAINER_ID,
    });

    dispatch(
      simpleNotificationUpdated({
        id,
        ...data,
      })
    );
  };
