import { call, put } from 'redux-saga/effects';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { SFrameManager } from 'features/e2ee/SFrameManager';
import { RatchetResult } from 'features/e2ee/types';
import { refineSframeStatus } from 'features/e2ee/sagas/refineSframeStatus';
import { senderKeyRatchetFinished, senderKeyRatchetStarted } from 'features/e2ee/e2eeSlice';
import { logger } from 'utils/logger';

export function* ratchetStreamKeySaga() {
  yield call(refineSframeStatus);

  const isGeneratingSecret: boolean = yield call(E2EEManager.streamKey.getIsGenerating);
  if (isGeneratingSecret) {
    // skip ratcheting since the new key will still be generated
    logger
      .remote({ system: true, capture: 'e2ee' })
      .debug('The stream encryption key is being refreshed. Skipping the ratcheting');

    return;
  }

  logger.remote({ system: true, capture: 'e2ee' }).debug("Ratchet sender's stream encryption key");

  yield put(senderKeyRatchetStarted('stream'));

  const ratchetResult: RatchetResult = yield call(SFrameManager.ratchetSenderEncryptionKey);

  yield call(E2EEManager.streamKey.setKey, ratchetResult.rawKey);

  yield put(senderKeyRatchetFinished('stream'));
}
