import { PublishingOptions, RTCClient } from 'utils/webrtc';
import { call } from 'redux-saga/effects';

export function* startRoomBroadcastSaga() {
  const publishingOptions: PublishingOptions = yield call(
    RTCClient.publishingFeed.getJoinPublishingOptions
  );

  yield call(RTCClient.setupPublishState, publishingOptions, true);

  yield call(RTCClient.publishingFeed.requestPublish);
}
