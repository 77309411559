import { useState } from 'react';

export const useMobileMenu = () => {
  const [open, setOpen] = useState(false);

  const triggerMenu = () => {
    setOpen(!open);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  return {
    open,
    closeMenu,
    triggerMenu,
  };
};
