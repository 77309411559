import { SignalingRoomUser } from 'services/signaling';
import { checkPermissions } from 'features/permissions/utils/checkPermissions';
import { PermissionsState, PermissionTypes } from 'features/permissions/types';
import { select } from 'redux-saga/effects';
import { selectPermissionsState } from 'features/permissions/permissionsSlice';

export function* findRoomPublishersSaga(users: SignalingRoomUser[]) {
  const permissionsState: PermissionsState = yield select(selectPermissionsState);

  return users.filter(
    (user) =>
      checkPermissions({
        permissions: PermissionTypes.broadcast,
        permissionsState,
        role: user.role,
        dynamicPermissions: user.dynamicPermissions,
      }) //|| user.screensharing;
  );
}
