import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery, Theme } from '@mui/material';
import { useAppSelector } from 'store/hooks';
import { selectDevModeEnabled } from 'features/dev-mode/devModeSlice';
import { StyledSettingsTabs } from 'components/StyledSettingsTabs';

type SettingsTabsProps = {
  children: React.ReactNode;
  renderPanels: (activeTab: number) => React.ReactNode;
  defaultActiveIndex?: number;
};

const SettingsTabs = ({ children, renderPanels, defaultActiveIndex = 0 }: SettingsTabsProps) => {
  const { t } = useTranslation('settings');
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [activeTab, setActiveTab] = useState(defaultActiveIndex);

  const devModeEnabled = useAppSelector(selectDevModeEnabled);

  useEffect(() => {
    if (!devModeEnabled && activeTab === 99) {
      setActiveTab(defaultActiveIndex);
    }
  }, [activeTab, defaultActiveIndex, devModeEnabled]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: '100%',
        display: !isMobile ? 'flex' : 'block',
      }}
    >
      <StyledSettingsTabs
        orientation={isMobile ? 'horizontal' : 'vertical'}
        variant="scrollable"
        value={activeTab}
        onChange={handleChange}
        aria-label={t('tabs_aria_label')}
      >
        {children}
      </StyledSettingsTabs>
      {renderPanels(activeTab)}
    </Box>
  );
};

export default SettingsTabs;
