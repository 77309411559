import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { ModalData, ModalName, ModalState } from 'features/modal/types';

export const initialState: ModalState = {
  name: null,
  data: {},
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: {
      reducer: (state, action: PayloadAction<ModalName, string, ModalData>) => {
        state.name = action.payload;
        state.data = action.meta;
      },
      prepare: (payload: ModalName, meta: ModalData = {}) => ({
        payload,
        meta,
      }),
    },
    closeModal(state, action: PayloadAction<ModalName>) {
      if (state.name === action.payload) {
        return initialState;
      }

      return undefined;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export const selectActiveModal = (state: RootState) => state.modal;
export const selectActiveModalName = (state: RootState) => state.modal.name;
export const selectActiveModalData = (state: RootState) => state.modal.data;

export default modalSlice.reducer;
