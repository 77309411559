import { Box, BoxProps, CircularProgress } from '@mui/material';
import Icon from 'components/Icon';
import { styleConstants } from 'theme/styleConstants';
import { RecorderStatus } from 'features/recorder/types';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/Tooltip';

export type RecordingIndicatorProps = BoxProps<
  'div',
  {
    recorderStatus: RecorderStatus;
  }
>;

const RecordingIndicator = ({ recorderStatus, sx = [], ...props }: RecordingIndicatorProps) => {
  const { t } = useTranslation('room');

  return (
    <Tooltip title={t('topbar.recording_badge_tooltip')} placement="bottom">
      <Box
        {...props}
        sx={[
          {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: styleConstants.featureBadge.width,
            height: styleConstants.featureBadge.height,
            backgroundColor: recorderStatus === 'started' ? 'error.main' : 'grey.200',
            borderRadius: '8px',
            transition: (theme) => theme.transitions.create('background-color'),
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {recorderStatus === 'starting' ? (
          <CircularProgress
            size={18}
            thickness={4}
            sx={{
              color: 'grey.600',
            }}
          />
        ) : (
          <Icon
            name="rec"
            sx={{
              fontSize: 32,
            }}
          />
        )}
      </Box>
    </Tooltip>
  );
};

export default RecordingIndicator;
