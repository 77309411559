import React from 'react';
import { useAppSelector } from 'store/hooks';
import { selectWaitingScreen } from 'features/application/applicationSlice';
import WaitingScreens from './WaitingScreens';

type WaitingScreensProviderProps = {
  children: React.ReactNode;
};

const WaitingScreensProvider = ({ children }: WaitingScreensProviderProps) => {
  const waitingScreen = useAppSelector(selectWaitingScreen);

  return waitingScreen ? <WaitingScreens /> : <div>{children}</div>;
};

export default WaitingScreensProvider;
