import WaitingScreen from 'components/WaitingScreen/WaitingScreen';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { sessionInterceptionCancelled } from 'features/room/actions';
import { sessionIntercepted } from 'features/room/roomSlice';

const InterceptSession = () => {
  const { t } = useTranslation('waiting-screens');
  const dispatch = useAppDispatch();

  const [cancelled, setCancelled] = useState<boolean>();

  const handleOk = () => {
    dispatch(sessionIntercepted());
  };

  const handleCancel = () => {
    setCancelled(true);
    dispatch(sessionInterceptionCancelled());
  };

  return (
    <WaitingScreen show>
      <Typography mb={4}>
        {cancelled ? t('intercept_session.request_cancelled_text') : t('intercept_session.text')}
      </Typography>
      {cancelled ? null : (
        <Stack
          direction="row"
          useFlexGap
          spacing={4}
          sx={{
            justifyContent: 'center',
          }}
        >
          <Button variant="contained" onClick={handleOk}>
            {t('intercept_session.btn_ok_label')}
          </Button>
          <Button variant="outlined" onClick={handleCancel}>
            {t('intercept_session.btn_cancel_label')}
          </Button>
        </Stack>
      )}
    </WaitingScreen>
  );
};

export default InterceptSession;
