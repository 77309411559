import * as Sentry from '@sentry/react';
import { selectDeviceInfo } from 'features/application/applicationSlice';
import { openModal } from 'features/modal/modalSlice';
import { localScreenshareToggled } from 'features/streaming/actions';
import { selectLocalScreenshareBroadcasting } from 'features/streaming/streamingSlice';
import ToolbarMenuItem from 'features/toolbar/ToolbarMenuItem/ToolbarMenuItem';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RTCClient } from 'utils/webrtc';
import webrtcAdapter from 'webrtc-adapter';
import { notification } from 'features/notifications/toast/notification';
import { usePermissions } from 'features/permissions/usePermissions';
import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { PermissionTypes } from 'features/permissions/types';
import { isDomException } from 'utils/types';

const ScreenshareControl = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const deviceInfo = useAppSelector(selectDeviceInfo);
  const isBroadcasting = useAppSelector(selectLocalScreenshareBroadcasting);

  const { hasPermissions } = usePermissions();
  const isFeatureEnabled = useFeatureFlag('screenshare');

  const permissionsGranted = hasPermissions(PermissionTypes.screenshare);
  const isFeatureAvailable = isFeatureEnabled && permissionsGranted;

  if (!isFeatureAvailable) {
    return null;
  }

  const toggleScreenshare = async () => {
    try {
      if (!isBroadcasting) {
        await RTCClient.screensharingFeed.saveStream();
      }
      dispatch(localScreenshareToggled());
    } catch (e) {
      if (isDomException(e)) {
        if (deviceInfo.os.name === 'Mac OS') {
          if (
            (webrtcAdapter.browserDetails.browser === 'chrome' && e.message.includes('system')) ||
            (webrtcAdapter.browserDetails.browser === 'firefox' && e.name === 'NotFoundError')
          ) {
            dispatch(openModal('screensharePermissionsError'));
          } else {
            notification(t('notifications:screen_permissions_not_granted'));
          }
        } else {
          notification(t('notifications:screen_permissions_not_granted'));
        }
      } else {
        Sentry.captureException(e);
        notification(t('notifications:screen_permissions_not_granted'));
      }
    }
  };

  const tooltip = isBroadcasting
    ? t('room:toolbar.stop_screen_share')
    : t('room:toolbar.screen_share');

  return (
    <ToolbarMenuItem
      id="screenshare"
      overlay={tooltip}
      active={isBroadcasting}
      ariaLabel={tooltip}
      icon="share-screen"
      onClick={toggleScreenshare}
    />
  );
};

export default ScreenshareControl;
