import { createAction } from '@reduxjs/toolkit';
import { ChatMessage, ChatMessageDeletedPayload } from 'features/chat/types';

export const deleteChatMessage = createAction<string>('chat/deleteMessage');

export const signalingChatMessageSent = createAction<ChatMessage>('signaling/chatMessageSent');
export const signalingChatMessageDeleted = createAction<ChatMessageDeletedPayload>(
  'signaling/chatMessageDeleted'
);

export const signalingAllChatMessagesReceived = createAction<{ messages: ChatMessage[] }>(
  'signaling/allChatMessagesReceived'
);
