import { call, select } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { logger } from 'utils/logger';
import { selectLocalUserId } from 'features/users/usersSlice';
import { UserId } from 'features/users/types';

export function* requestRecordingSaga() {
  const userId: UserId = yield select(selectLocalUserId);
  logger.remote({ tier: 1 }).info(`Recording initiated by user: ${userId}`);

  yield call(SignalingSocket.send, {
    event: 'requestRecording',
  });
}
