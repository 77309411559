import { useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectMediaDeviceByKind } from 'features/user-media/userMediaSlice';
import { OptionType } from 'components/Select';
import { useTranslation } from 'react-i18next';

interface AudioOutputFieldOptions {
  micDisabled?: boolean;
}

export const useAudioOutputField = ({ micDisabled }: AudioOutputFieldOptions = {}) => {
  const { t } = useTranslation('settings');
  const mediaDevice = useAppSelector((state) => selectMediaDeviceByKind(state, 'audiooutput'));

  const audioOutputDisabled =
    mediaDevice.length === 0 || mediaDevice[0]?.label === '' || micDisabled;

  const getPlaceholder = () => {
    if (audioOutputDisabled) {
      return t('device_dropdowns.audiooutput.placeholder_audio_disabled');
    }

    return undefined;
  };

  const audioOutputOptions = useMemo((): OptionType[] => {
    if (!mediaDevice.length || mediaDevice[0]?.label === '') {
      return [];
    }

    return mediaDevice.map((device) => ({ value: device.deviceId, label: device.label }));
  }, [mediaDevice]);

  return {
    audioOutputDisabled,
    audioOutputOptions,
    audioOutputPlaceholder: getPlaceholder(),
  };
};
