import { SFrameManager } from 'features/e2ee/SFrameManager';
import {
  receivingPluginCleanup,
  remoteTrackReceived,
  streamingError,
  videoroomError,
} from 'features/streaming/actions';
import {
  Mountpoint,
  StreamingErrorMessage,
  SubscriberMid,
  SubscribeStreamList,
} from 'features/streaming/types';
import Janus, { JanusJS } from 'lib/janus';
import { store } from 'store/store';
import { noop } from 'utils/flow';
import { logger } from 'utils/logger';
import { ControlledReceivingHandle } from 'utils/webrtc/ControlledReceivingHandle';
import { GENERIC_JANUS_ERROR } from 'utils/webrtc/errors';
import { RTCClient, ToggleMediaTemplate } from 'utils/webrtc/index';
import {
  batchToggleMediaTemplate,
  sendMessage,
  startSubscriptionTemplate,
  watchRoomTemplate,
} from 'utils/webrtc/messages';
import { BaseReceiver } from 'utils/webrtc/receiving/BaseReceiver';
import { JanusConnection } from 'utils/webrtc/types';
import { handleAttachError } from 'utils/webrtc/handleAttachError';

export class StreamingReceiver extends BaseReceiver {
  pluginHandle: string = '';

  skipCleanup = false;

  trackPool: Record<SubscriberMid, MediaStreamTrack> = {};

  private localStreams: ToggleMediaTemplate[] = [];

  attachPlugin = (pluginHandle: string, connection: JanusConnection) => {
    this.connection = connection;
    this.pluginHandle = pluginHandle;

    connection.janus.attach({
      plugin: 'janus.plugin.streaming',
      opaqueId: connection.handle,
      success: async (plugin) => {
        this.skipCleanup = false;

        this.plugin = new ControlledReceivingHandle(plugin, pluginHandle, connection);

        this.watchRoomStreams();
      },
      error: handleAttachError('attach_streaming_feed'),
      consentDialog: noop,
      onmessage: this.onMessage,
      onlocaltrack: noop,
      onremotetrack: (track, mid, on) => {
        if (on) {
          if (this.feed.feedIdByMid[mid]) {
            store.dispatch(remoteTrackReceived({ pluginHandle, track, mid }));
          }

          this.trackPool[mid] = track;
        }
      },
      oncleanup: () => {
        if (!this.skipCleanup) {
          store.dispatch(receivingPluginCleanup(pluginHandle));
        }
      },
      webrtcState: (on) => {
        Janus.log(`Receiving Feed: WebRTC PeerConnection is ${on ? 'up' : 'down'} now`);
      },
      iceState: this.onIceState,
      slowLink: (uplink, lost, mid) => {
        Janus.warn(
          `Janus reports problems (${
            uplink ? 'sending' : 'receiving'
          }) packets on mid ${mid} (${lost} lost packets)`
        );
      },
      sframe: SFrameManager.getClient(),
    });
  };

  private onMessage = (message: JanusJS.EvtMessage, messageJsep?: JanusJS.JSEP) => {
    const feed = this.plugin;

    if (!feed) {
      return;
    }

    logger.debug(`Got a remote message in receiving feed:`, message);
    if (messageJsep) {
      feed.janusPlugin.createAnswer({
        jsep: messageJsep,
        media: { audioSend: false, videoSend: false },
        success: async (jsep: JanusJS.JSEP) => {
          await sendMessage(feed.janusPlugin, startSubscriptionTemplate(RTCClient.roomId!), jsep);
        },
        error: GENERIC_JANUS_ERROR('receiving_feed'),
      });
    }

    if (message.result?.status) {
      logger
        .remote({ system: true, capture: 'streaming' })
        .info('Streaming receiver status =', message.result?.status);

      if (message.result?.status === 'started') {
        this.stopQueuedLocalStreams();
      }

      return;
    }

    if (message.result) {
      const event = message.streaming;

      switch (event) {
        case 'event': {
          // @ts-ignore
          const { substream, temporal, mid } = message.result;

          if (
            (substream !== null && substream !== undefined) ||
            (temporal !== null && temporal !== undefined)
          ) {
            logger.debug('Simulcast substream update: ', substream);
            if (temporal) {
              logger.debug('Simulcast temporal update: ', temporal);
            }
            this.feed.updateSimulcastValue(mid, substream, temporal);
          } else if (message.error_code) {
            store.dispatch(videoroomError({ handle: this.plugin!.handle, message }));
          } else {
            const messageStr = 'Got an anomalous message in receiving feed';

            logger.remote({ system: true, capture: 'streaming' }).warn(messageStr, message);
          }
          break;
        }
        case 'updated': {
          // all good;
          break;
        }
        default:
          logger
            .remote({ system: true, capture: 'streaming' })
            .warn('Got an anomalous message in receiving feed', message);
      }
    } else if (message.error) {
      store.dispatch(streamingError(message as unknown as StreamingErrorMessage));
    }
  };

  subscribe = async (connectionHandle: string, streams: SubscribeStreamList) => {
    const configureStreams: ToggleMediaTemplate = [];

    streams.forEach((stream) => {
      if (stream.mid) {
        this.feed.feedIdByMid[stream.mid] = stream.feed;

        // yes, double stream.mid here is valid because in streaming pub and sub mid are identical;
        this.feed.media.setSubscriberMid(stream.feed, stream.mid, stream.mid);

        if (this.trackPool[stream.mid]) {
          store.dispatch(
            remoteTrackReceived({
              pluginHandle: this.plugin!.handle,
              track: this.trackPool[stream.mid],
              mid: stream.mid,
            })
          );
        }

        configureStreams.push({ mid: stream.mid, send: true, ...(stream.options || {}) });
      }
    });

    if (configureStreams.length && this.plugin?.janusPlugin) {
      sendMessage(this.plugin.janusPlugin, batchToggleMediaTemplate(configureStreams));
    }
  };

  watchRoomStreams = async () => {
    if (RTCClient.mountpointId) {
      await sendMessage(
        this.plugin!.janusPlugin,
        watchRoomTemplate(RTCClient.mountpointId, RTCClient.roomPin!)
      );
    }
  };

  list: () => Promise<{ data: { list: Mountpoint[] } }> = () =>
    sendMessage(this.plugin!.janusPlugin, { request: 'list' });

  cf: (a: any) => Promise<{ list: Mountpoint[] }> = () =>
    // @ts-ignore
    sendMessage(this.plugin!.janusPlugin, batchToggleMediaTemplate(a));

  unsubscribe = () => {
    // this is a stub; currently streaming receiver doesn't unsubscribe
    // since we're always connected to all tracks from a mountpoint
    // in future for pagination we'll need to send configure request here probably
  };

  stopQueuedLocalStreams = () => {
    if (this.localStreams.length) {
      this.localStreams.forEach((streams) => {
        this.stopLocalStreams(streams);
      });

      this.localStreams = [];
    }
  };

  stopLocalStreams = (streams: ToggleMediaTemplate) => {
    if (this.plugin?.janusPlugin) {
      sendMessage(this.plugin!.janusPlugin, batchToggleMediaTemplate(streams));
    } else {
      this.localStreams.push(streams);
    }
  };

  iceRestartHandler = async () => {
    this.skipCleanup = true;

    this.plugin!.janusPlugin.hangup();
    this.attachPlugin(this.pluginHandle, this.connection!);
  };
}
