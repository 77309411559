import { createSelector, createSlice, PayloadAction, prepareAutoBatched } from '@reduxjs/toolkit';
import { streamMaximized, streamMinimized } from 'features/layout/actions';
import {
  LayoutPaginationState,
  PaginationUpdatePayload,
} from 'features/layout/features/pagination/types';
import { RootState } from 'store/store';

export const initialState: LayoutPaginationState = {
  page: 1,
  numPages: 0,
  totalStreams: 0,
  streams: [],
};

export const layoutPaginationSlice = createSlice({
  name: 'layoutPagination',
  initialState,
  reducers: {
    paginationUpdated: {
      reducer(state, action: PayloadAction<PaginationUpdatePayload>) {
        Object.assign(state, action.payload);
      },
      prepare: prepareAutoBatched<PaginationUpdatePayload>(),
    },
    paginationPageChanged(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    paginationReset(state) {
      state.page = 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(streamMaximized, (state) => {
      state.page = 1;
    });
    builder.addCase(streamMinimized, (state) => {
      state.page = 1;
    });
  },
});

export const { paginationReset, paginationUpdated, paginationPageChanged } =
  layoutPaginationSlice.actions;

export const selectMaxTiles = (state: RootState) => state.layout.dimensions.maxTiles;
export const selectPageStreams = (state: RootState) => state.layout.pagination.streams;
export const selectPagination = (state: RootState) => state.layout.pagination;
export const selectPaginationTotalStreams = (state: RootState) =>
  state.layout.pagination.totalStreams;
export const selectPaginationPages = createSelector(
  (state: RootState) => state.layout.pagination,
  (pagination) => ({
    page: pagination.page,
    numPages: pagination.numPages,
  })
);

export const selectCurrentPage = (state: RootState) => state.layout.pagination.page;

export default layoutPaginationSlice.reducer;
