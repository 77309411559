import { useBlurredBackground } from 'hooks/useBlurredBackground';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { logger } from 'utils/logger';
import { styled } from '@mui/material/styles';
import { Portal } from '@mui/base';
import { Typography } from '@mui/material';

const Root = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 8,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  zIndex: theme.zIndex.tooltip + 1,
  [theme.breakpoints.down('sm')]: {
    padding: 4,
    top: 0,
  },
}));

const Message = styled(Typography)({
  padding: '12px',
  overflow: 'hidden',
  background: 'var(--toastify-color-dark)',
  color: 'var(--toastify-text-color-dark)',
  borderRadius: '8px',
  boxShadow: '0 0 7px rgba(0, 0, 0, 0.3)',
  fontSize: '0.875rem',
  lineHeight: '1rem',
});

const ReconnectionOverlay = () => {
  const { t } = useTranslation();

  useBlurredBackground(true);

  useEffect(() => {
    const disableKeyboard = (e: Event) => {
      e.preventDefault();
    };

    logger.remote().log('Application is reconnecting');
    document.addEventListener('keydown', disableKeyboard);

    return () => {
      logger.remote().log('Application reconnected');
      document.removeEventListener('keydown', disableKeyboard);
    };
  }, []);

  return (
    <Portal>
      <Root>
        <Message>{t('connection_failure')}</Message>
      </Root>
    </Portal>
  );
};

export default ReconnectionOverlay;
