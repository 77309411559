import ToolbarMenuItem from 'features/toolbar/ToolbarMenuItem/ToolbarMenuItem';
import { useLocalVideo } from 'hooks/useLocalVideo';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

const CameraControl = () => {
  const { t } = useTranslation('room');
  const theme = useTheme();
  const { isVideoBroadcasting, toggleVideo, isPermissionsGranted, camIcon, isConnectedToFeed } =
    useLocalVideo();

  const renderCameraAriaLabel = () => {
    if (!isConnectedToFeed) {
      return t('toolbar.camera_setup');
    }

    if (!isPermissionsGranted) {
      return t('toolbar.camera_error');
    }

    return isVideoBroadcasting ? t('toolbar.disable_camera') : t('toolbar.enable_camera');
  };

  const renderCameraOverlay = () => {
    if (!isPermissionsGranted || !isConnectedToFeed) {
      return null;
    }

    return isVideoBroadcasting ? t('toolbar.disable_camera') : t('toolbar.enable_camera');
  };

  return (
    <ToolbarMenuItem
      id="camera"
      overlay={renderCameraOverlay()}
      overlayType={isPermissionsGranted ? 'tooltip' : undefined}
      icon={camIcon}
      ariaLabel={renderCameraAriaLabel()}
      onClick={toggleVideo}
      disabled={!isConnectedToFeed}
      error={!isPermissionsGranted}
      controlColor={
        isVideoBroadcasting
          ? theme.palette.getContrastText(theme.toolbar.background)
          : theme.palette.error.main
      }
    />
  );
};

export default CameraControl;
