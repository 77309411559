import { UserEntries } from 'features/users/types';
import { searchByName } from 'features/users/utils/searchByName';

export const searchUsers = (userEntries: UserEntries, searchQuery: string) => {
  if (!searchQuery) {
    return userEntries;
  }

  const lowerCaseSearchQuery = searchQuery.toLowerCase();
  const result: UserEntries = {};

  for (const user of Object.values(userEntries)) {
    if (searchByName(user.name?.toLowerCase(), lowerCaseSearchQuery)) {
      result[user.id] = user;
    }
  }

  return result;
};
