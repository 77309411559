import { fork, select } from 'redux-saga/effects';
import { contentLibraryFileUploadRequested } from 'features/content-library/actions';
import { ContentLibraryManager } from 'utils/content-library/ContentLibraryManager';
import { selectActiveFolderId } from 'features/content-library/contentLibrarySlice';

export function* uploadContentLibraryFilesSaga(
  action: ReturnType<typeof contentLibraryFileUploadRequested>
) {
  const activeFolderId: string = yield select(selectActiveFolderId);

  yield fork(ContentLibraryManager.upload, action.payload, {
    activeFolderId,
  });
}
