import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectRecorderStatus } from 'features/recorder/recorderSlice';
import { useTranslation } from 'react-i18next';
import { recordingToggled } from 'features/recorder/actions';
import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { usePermissions } from 'features/permissions/usePermissions';
import { PermissionTypes } from 'features/permissions/types';

export const useRecordingControl = () => {
  const { t } = useTranslation('room');
  const dispatch = useAppDispatch();

  const recorderStatus = useAppSelector(selectRecorderStatus);

  const { hasPermissions } = usePermissions();
  const isFeatureEnabled = useFeatureFlag('recordings');

  const permissionsGranted = hasPermissions(PermissionTypes.recording);
  const isFeatureAvailable = isFeatureEnabled && permissionsGranted;

  const on = recorderStatus === 'started';

  const toggleRecording = () => {
    dispatch(recordingToggled());
  };

  const renderLabel = () => {
    if (recorderStatus === 'starting') {
      return t('toolbar.recording_starting');
    }

    if (on) {
      return t('toolbar.recording_stop');
    }

    return t('toolbar.recording_start');
  };

  return {
    toggleRecording,
    recorderStatus,
    active: on,
    icon: 'recording-base',
    label: renderLabel(),
    isFeatureAvailable,
  };
};
