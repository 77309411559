import {
  CoverageAcrossRoles,
  Role,
  RolePermissions,
  TargetRolesMap,
} from 'features/permissions/types';
import i18n from 'i18n';
import { getDisplayNameLocaleKey } from 'features/permissions/utils/getDisplayNameLocaleKey';

export const normalizePermissions = (roles: Role[]) => {
  const rolePermissions: RolePermissions = {};
  const targetRolesMap: TargetRolesMap = {};
  const byRole: Record<string, Role> = {};
  const allRoles = new Set<string>();
  const coverageAcrossRoles: CoverageAcrossRoles = {};

  for (const role of roles) {
    // Generate a key for the role's display name.
    const displayNameKey = role.displayName
      .trim()
      .toLowerCase()
      .replaceAll('.', '')
      .replaceAll(/\s+/g, '_');

    const localizationKey = getDisplayNameLocaleKey(displayNameKey);
    if (i18n.exists(localizationKey)) {
      role.displayNameKey = displayNameKey;
      role.displayName = i18n.t(localizationKey);
    }

    byRole[role.name] = role;
    allRoles.add(role.name);

    // Ensure that rolePermissions and targetRolesMap have entries for the current role
    rolePermissions[role.name] ??= {};
    targetRolesMap[role.name] ??= {};

    for (const permission of role.permissions) {
      if (!permission.targetRoles) {
        // Initialize the coverage for the current permission
        coverageAcrossRoles[permission.name] ??= true;

        rolePermissions[role.name][permission.name] = true;
      } else {
        targetRolesMap[role.name][permission.name] = permission.targetRoles;

        // For each target role, indicate that the role has permission over it.
        for (const targetRole of permission.targetRoles) {
          rolePermissions[role.name][`${permission.name}_${targetRole}`] = true;
        }
      }
    }
  }

  // @TODO do we need permissions with target roles?
  // Check if all roles have the permission
  for (const permission of Object.keys(coverageAcrossRoles)) {
    for (const role of roles) {
      if (!rolePermissions[role.name][permission]) {
        coverageAcrossRoles[permission] = false;
        break;
      }
    }
  }

  return {
    rolePermissions,
    byRole,
    allRoles: Array.from(allRoles),
    targetRolesMap,
    coverageAcrossRoles,
  };
};
