import React, { useEffect, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { initRoomStarted, selectRoomStatus } from 'features/room/roomSlice';
import { RoomQueryParams } from 'router';
import { WaitingScreensProvider } from 'features/waiting-screens';
import RoomContainer from 'features/room/RoomContainer';
import ReconnectionOverlay from 'features/room/components/ReconnectionOverlay';
import RoomLoading from 'features/room/components/RoomLoading';
import { selectAppReconnecting } from 'features/application/applicationSlice';
import { canExposeInternals } from 'utils/development';

const RoomPage = () => {
  const didInitRef = useRef(false);

  const dispatch = useAppDispatch();
  const params = useParams<RoomQueryParams>();
  const [searchParams, setSearchParams] = useSearchParams();

  const roomStatus = useAppSelector(selectRoomStatus);
  const isReconnecting = useAppSelector(selectAppReconnecting);

  useEffect(() => {
    if (!didInitRef.current) {
      didInitRef.current = true;

      const token = searchParams.get('token');

      // preserve token for development not to break the app during hot reloading
      if (token) {
        setSearchParams({}, { replace: true });
      }

      if (params.roomId) {
        dispatch(initRoomStarted({ roomId: params.roomId, token }));
      }

      // @FIXME remove test code
      if (canExposeInternals()) {
        window.e2eeStreamKey = searchParams.get('e2eeStreamKey');
        window.e2eeAppKey = searchParams.get('e2eeAppKey');
      }
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <>
      <WaitingScreensProvider>
        {roomStatus === 'initializing' || roomStatus === 'duplication-detected' ? (
          <RoomLoading />
        ) : (
          <RoomContainer />
        )}
      </WaitingScreensProvider>
      {isReconnecting && <ReconnectionOverlay />}
    </>
  );
};
export default RoomPage;
