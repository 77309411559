import { selectFeatureFlag } from 'features/feature-set/featuresSlice';
import {
  localStreamCollapsed,
  localStreamExpanded,
} from 'features/layout/features/config/configSlice';
import { call, put, select } from 'redux-saga/effects';
import { eventBus, LocalTileTypes } from 'utils/eventBus';

export function* onControlLocalTileCommandSaga(command: LocalTileTypes) {
  const controlEnabled: boolean = yield select(selectFeatureFlag, 'minimizeOwnTile');

  if (!controlEnabled) {
    yield call(
      eventBus.notAllowedError,
      'This feature is disabled. You’ll need to edit this room’s properties to enable it.',
      'minimizeOwnTile'
    );

    return;
  }

  switch (command) {
    case 'minimizeLocalTile':
      yield put(localStreamCollapsed());

      break;

    case 'maximizeLocalTile':
      yield put(localStreamExpanded());
      break;

    default:
      break;
  }
}
