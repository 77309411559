import xss from 'xss';
import { DocumentMeta } from 'features/application/types';

export function setDocumentMeta(meta: DocumentMeta) {
  // set title
  if (meta.title) {
    document.title = xss(meta.title);
  }

  // set favicon
  if (meta.favicon) {
    const link: HTMLLinkElement =
      document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = meta.favicon;
    document.getElementsByTagName('head')[0].appendChild(link);
  }
}
