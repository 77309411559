import {
  EnumeratedMediaDevices,
  MediaDevicesPermissions,
  PermissionStatus,
} from 'features/user-media/types';
import { call, put, select } from 'redux-saga/effects';
import {
  devicesAutoChanged,
  mediaDevicesUpdated,
  selectAllMediaDevicePermissions,
} from 'features/user-media/userMediaSlice';
import {
  DefaultDevice,
  getDefaultMediaDevice,
  getDevicePermissions,
} from 'features/user-media/utils';
import { RTCClient } from 'utils/webrtc';
import { AutoChangedDevices } from 'features/user-media/utils/findDeviceDifferences';
import * as Sentry from '@sentry/react';

export function* handleAddedDevicesSaga(
  updatedDevices: EnumeratedMediaDevices,
  addedDevices: AutoChangedDevices
) {
  const currentPermissions: MediaDevicesPermissions = yield select(selectAllMediaDevicePermissions);

  yield put(
    mediaDevicesUpdated(updatedDevices, {
      updatePermissions: true,
    })
  );

  if (currentPermissions.audioinput !== 'granted' && addedDevices.audioinput) {
    try {
      const permissions: PermissionStatus = yield call(
        getDevicePermissions,
        updatedDevices,
        'audioinput'
      );

      if (permissions === 'granted') {
        yield call(RTCClient.publishingFeed.updateStreamingPermissions);

        const deviceId: DefaultDevice = yield call(
          getDefaultMediaDevice,
          updatedDevices,
          'audioinput'
        );

        if (!deviceId) {
          throw new Error('Could not find audio device');
        }

        yield put(
          devicesAutoChanged({
            kind: 'audioinput',
            id: deviceId,
          })
        );
      }
    } catch (error) {
      Sentry.captureException(error);
      yield call(RTCClient.publishingFeed.blockAudioPublishing);
    }
  }

  if (currentPermissions.videoinput !== 'granted' && addedDevices.videoinput) {
    try {
      const permissions: PermissionStatus = yield call(
        getDevicePermissions,
        updatedDevices,
        'videoinput'
      );

      if (permissions === 'granted') {
        yield call(RTCClient.publishingFeed.updateStreamingPermissions);

        const deviceId: DefaultDevice = yield call(
          getDefaultMediaDevice,
          updatedDevices,
          'videoinput'
        );

        if (!deviceId) {
          throw new Error('Could not find video device');
        }

        yield put(
          devicesAutoChanged({
            kind: 'videoinput',
            id: deviceId,
          })
        );
      }
    } catch (error) {
      Sentry.captureException(error);
      yield call(RTCClient.publishingFeed.blockVideoPublishing);
    }
  }

  if (addedDevices.audiooutput) {
    try {
      const deviceId: DefaultDevice = yield call(
        getDefaultMediaDevice,
        updatedDevices,
        'audiooutput'
      );

      if (!deviceId) {
        throw new Error('Could not find the audio output device');
      }

      yield put(
        devicesAutoChanged({
          kind: 'audiooutput',
          id: deviceId,
        })
      );
    } catch (error) {
      Sentry.captureException(error);
    }
  }
}
