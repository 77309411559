import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal, selectActiveModalData } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from 'components/Dialog';
import Button from '@mui/material/Button';
import { askUnmuteRemoteAudio } from 'features/streaming/actions';
import { UserId } from 'features/users/types';
import { selectActiveUserById } from 'features/users/usersSlice';
import { usePermissions } from 'features/permissions/usePermissions';
import { PermissionTypes } from 'features/permissions/types';
import { useEffect } from 'react';

const UnmuteRemoteAudioConfirmation = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const modalData = useAppSelector(selectActiveModalData);

  const userId = modalData.userId as UserId;
  const user = useAppSelector((state) => selectActiveUserById(state, userId));

  const { hasPermissions } = usePermissions();

  const canRemotelyUnmute = hasPermissions(PermissionTypes.askRemoteUnmute, {
    targetRole: user?.role,
  });

  useEffect(() => {
    if (!user || !canRemotelyUnmute) {
      dispatch(closeModal('unmuteRemoteAudioConfirmation'));
    }
  }, [user, canRemotelyUnmute, dispatch]);

  if (!user) {
    return null;
  }

  const handleOk = () => {
    dispatch(askUnmuteRemoteAudio(user.id));
    dispatch(closeModal('unmuteRemoteAudioConfirmation'));
  };

  const handleCancel = () => {
    dispatch(closeModal('unmuteRemoteAudioConfirmation'));
  };

  return (
    <Dialog
      open
      maxWidth="xs"
      aria-labelledby="remote-audio-unmute-confirm-title"
      aria-describedby="remote-audio-unmute-confirm-description"
    >
      <DialogTitle id="remote-audio-unmute-confirm-title">
        {t('unmute_remote_audio_confirm.title', { username: user.name })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="remote-audio-unmute-confirm-description">
          {t('unmute_remote_audio_confirm.description', { username: user.name })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="grayscale">
          {t('unmute_remote_audio_confirm.btn_cancel_label')}
        </Button>
        <Button onClick={handleOk}>{t('unmute_remote_audio_confirm.btn_ok_label')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnmuteRemoteAudioConfirmation;
