import { takeEvery } from 'redux-saga/effects';
import {
  disableFeatureRequested,
  enabledFeatureRequested,
  signalingFeatureDisabled,
  signalingFeatureEnabled,
} from 'features/feature-set/actions';
import { enableFeatureSaga } from 'features/feature-set/sagas/enableFeatureSaga';
import { disableFeatureSaga } from 'features/feature-set/sagas/disableFeatureSaga';
import { onFeatureDisabledSaga } from 'features/feature-set/sagas/onFeatureDisabledSaga';
import { onFeatureEnabledSaga } from 'features/feature-set/sagas/onFeatureEnabledSaga';

export default function* featuresWatcher() {
  yield takeEvery(enabledFeatureRequested, enableFeatureSaga);
  yield takeEvery(disableFeatureRequested, disableFeatureSaga);
  yield takeEvery(signalingFeatureDisabled, onFeatureDisabledSaga);
  yield takeEvery(signalingFeatureEnabled, onFeatureEnabledSaga);
}
