import React, { useEffect, useId } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle } from 'components/Dialog';
import { Box, Button, FormControl, FormHelperText } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Input from 'components/Input';
import { useContentLibrary } from 'features/content-library/useContentLibrary';
import { selectActiveFolderId } from 'features/content-library/contentLibrarySlice';
import { createContentFolderRequested } from 'features/content-library/actions';
import { CreateContentLibraryFolderPayload } from 'features/content-library/types';

export interface CreateContentLibraryFolderValues {
  folderName: string;
}

const CreateContentLibraryFolderModal = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const modalId = useId();

  const activeFolderId = useAppSelector(selectActiveFolderId);

  const { isFeatureAvailable } = useContentLibrary();

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    setFocus,
  } = useForm<CreateContentLibraryFolderValues>({
    mode: 'onChange',
    defaultValues: {
      folderName: '',
    },
  });

  const shouldClose = !isFeatureAvailable;

  useEffect(() => {
    if (shouldClose) {
      dispatch(closeModal('createContentLibraryFolder'));
    }
  }, [shouldClose, dispatch]);

  useEffect(() => {
    setTimeout(() => {
      setFocus('folderName');
    }, 0);
  }, [setFocus]);

  const handleCancel = () => {
    dispatch(closeModal('createContentLibraryFolder'));
  };

  const handleOk: SubmitHandler<CreateContentLibraryFolderValues> = (data) => {
    const payload: CreateContentLibraryFolderPayload = {
      name: data.folderName.trim(),
      parentId: activeFolderId === 'root' ? undefined : activeFolderId,
    };

    dispatch(createContentFolderRequested(payload));
    dispatch(closeModal('createContentLibraryFolder'));
  };

  return (
    <Dialog
      open={!shouldClose}
      maxWidth="xs"
      aria-labelledby={`${modalId}-title`}
      onClose={handleCancel}
    >
      <DialogTitle id={`${modalId}-title`}>{t('content_library.add_folder.title')}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            pt: 1,
          }}
        >
          <FormControl fullWidth error={!!errors.folderName}>
            <Controller
              name="folderName"
              control={control}
              rules={{
                required: t('common:validation.field_required'),
                validate: (value) => !!value.trim() || t('common:validation.field_required'),
              }}
              render={({ field: { ref, ...field } }) => (
                <Input
                  {...field}
                  autoFocus
                  inputProps={{
                    'aria-label': t('content_library.add_folder.input_name_placeholder'),
                  }}
                  placeholder={t('content_library.add_folder.input_name_placeholder')}
                  autoComplete="off"
                  inputRef={ref}
                />
              )}
            />
            <FormHelperText>{errors.folderName?.message}</FormHelperText>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button type="button" color="grayscale" onClick={handleCancel}>
          {t('content_library.add_folder.btn_cancel_label')}
        </Button>
        <Button type="button" onClick={handleSubmit(handleOk)} disabled={!isValid}>
          {t('content_library.add_folder.btn_ok_label')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateContentLibraryFolderModal;
