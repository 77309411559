import { Box, Chip as MuiChip } from '@mui/material';
import { chipClasses } from '@mui/material/Chip';
import { alpha, styled } from '@mui/material/styles';
import React, { useEffect, useRef } from 'react';
import Thumbnail from 'features/content-library/components/content-types/GalleryFile/thumbnails/Thumbnail';
import ThumbnailControl from 'features/content-library/components/content-types/GalleryFile/thumbnails/ThumbnailControl';
import { scrollIntoViewIfVisible } from 'utils/dom/scrollIntoViewIfVisible';

const Chip = styled(MuiChip, {
  shouldForwardProp: (prop) => prop !== 'handRaised',
})(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: alpha(theme.palette.common.black, 0.7),
  fontSize: theme.typography.pxToRem(14),
  height: 26,
  textTransform: 'capitalize',
  transition: 'none',
  position: 'absolute',
  bottom: '8px',
  left: '50%',
  transform: 'translateX(-50%)',
  [`&.${chipClasses.sizeMedium} > .${chipClasses.label}`]: {
    padding: theme.spacing(0, 3),
  },
}));

type ThumbnailListProps = {
  goToPage: (index: number) => void;
  pages: {
    thumbnailUrl: string;
    url: string;
  }[];
  currentPage: number;
};

const ThumbnailList = React.memo(({ pages, goToPage, currentPage }: ThumbnailListProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const activeThumb = document.querySelector(`[data-file-thumbnail-index="${currentPage}"]`);
    if (activeThumb) {
      scrollIntoViewIfVisible(activeThumb);
    }
  }, [currentPage]);

  return (
    <Box
      ref={ref}
      sx={(theme) => ({
        minWidth: '190px',
        width: '190px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRight: `1px solid ${theme.palette.grey['400']}`,
        height: '100%',
        overflow: 'auto',
        padding: theme.spacing(3.5, 0),
      })}
    >
      {pages.map((img, idx) => (
        <Box
          key={idx}
          data-file-thumbnail-index={idx}
          sx={{
            py: 1.5,
          }}
        >
          <ThumbnailControl onClick={() => goToPage(idx)} containerRef={ref}>
            <Thumbnail src={img.thumbnailUrl} active={idx === currentPage} />
            <Chip label={idx + 1} />
          </ThumbnailControl>
        </Box>
      ))}
    </Box>
  );
});

export default ThumbnailList;
