import { takeEvery, takeLatest } from 'redux-saga/effects';
import { signalingRoomJoined } from 'features/room/actions';
import { onRoomJoinedSaga } from 'features/e2ee/sagas/onRoomJoinedSaga';
import {
  remoteStreamSecretDecrypted,
  signalingEncryptedNameShared,
  signalingEncryptionKeyShared,
  signalingPublicKeyShared,
  userNameDecrypted,
} from 'features/e2ee/actions';
import { onEncryptionKeySharedSaga } from 'features/e2ee/sagas/onEncryptionKeySharedSaga';
import { onRemoteStreamSecretDecryptedSaga } from 'features/e2ee/sagas/onRemoteStreamSecretDecryptedSaga';
import { userLeft } from 'features/users/usersSlice';
import { generateRoomE2eeCode } from 'features/e2ee/sagas/generateRoomE2eeCode';
import { onUserNameDecryptedSaga } from 'features/e2ee/sagas/onUserNameDecryptedSaga';
import { onEncryptedNameSharedSaga } from 'features/e2ee/sagas/onEncryptedNameSharedSaga';
import { onPublicKeySharedSaga } from 'features/e2ee/sagas/onPublicKeySharedSaga';
import { signalingUserJoined } from 'features/users/actions';
import { onUserJoinedSaga } from 'features/e2ee/sagas/onUserJoinedSaga';

export default function* e2eeWatcher() {
  yield takeLatest(signalingRoomJoined, onRoomJoinedSaga);
  yield takeEvery(signalingEncryptionKeyShared, onEncryptionKeySharedSaga);
  yield takeEvery(signalingEncryptedNameShared, onEncryptedNameSharedSaga);

  yield takeEvery(signalingPublicKeyShared, onPublicKeySharedSaga);
  yield takeEvery(remoteStreamSecretDecrypted, onRemoteStreamSecretDecryptedSaga);

  yield takeEvery(signalingUserJoined, onUserJoinedSaga);
  yield takeEvery(userLeft, generateRoomE2eeCode);
  yield takeEvery(userNameDecrypted, onUserNameDecryptedSaga);
}
