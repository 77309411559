import { Action } from '@reduxjs/toolkit';
import { splashScreenOpenRequested } from 'features/breakout-rooms/actions';
import { roomMovementRequested } from 'features/breakout-rooms/breakoutRoomsSlice';
import { selectNotificationsByScope } from 'features/notifications/notificationsSlice';
import { closeActionNotifications } from 'features/notifications/thunks/closeActionNotifications';
import { NotificationId } from 'features/notifications/types';
import { call, put, select } from 'redux-saga/effects';
import { RTCClient } from 'utils/webrtc';

export function* onBreakoutRoomsStoppedSaga() {
  // reset only if in a BR. If in a main room, do nothing;
  if (RTCClient.roomId === RTCClient.breakoutRoomId) {
    yield put(splashScreenOpenRequested('ended'));

    yield put(roomMovementRequested());
  } else {
    const allBreakoutNotifications: NotificationId[] = yield select(
      selectNotificationsByScope,
      'breakout-room'
    );

    const cleanupNotificationsThunk: Action = yield call(
      closeActionNotifications,
      allBreakoutNotifications
    );
    yield put(cleanupNotificationsThunk);
  }
}
