import { Id, toast, ToastContent, ToastOptions } from 'react-toastify';
import { store } from 'store/store';
import {
  selectIsNotificationBlacklisted,
  simpleNotificationClosed,
} from 'features/notifications/notificationsSlice';
import { NotificationName } from 'features/notifications/types';
import { selectIsRecorderSession } from 'features/recorder/recorderSlice';
import { ToastItem } from 'react-toastify/dist/types';
import { SIMPLE_NOTIFICATION_CONTAINER_ID } from 'features/notifications/constants';

export interface NotificationOptions extends ToastOptions<{}> {
  name?: NotificationName;
}

export function notification(content: ToastContent, options?: NotificationOptions): Id | null {
  const isRecorder = selectIsRecorderSession(store.getState());
  if (isRecorder) {
    return null;
  }

  if (options?.name) {
    const blacklisted = selectIsNotificationBlacklisted(store.getState(), options.name);
    if (blacklisted) {
      return null;
    }
  }

  return toast(content, {
    containerId: SIMPLE_NOTIFICATION_CONTAINER_ID,
    ...options,
  });
}

// replicate original API
notification.promise = toast.promise;
notification.success = toast.success;
notification.info = toast.info;
notification.promise = toast.promise;
notification.error = toast.error;
notification.warning = toast.warning;
notification.warn = toast.warning;
notification.dismiss = toast.dismiss;
notification.clearWaitingQueue = toast.clearWaitingQueue;
notification.isActive = toast.isActive;
notification.update = toast.update;
notification.done = toast.done;
notification.onChange = toast.onChange;
notification.loading = toast.loading;
notification.dark = toast.dark;
notification.play = toast.play;
notification.pause = toast.pause;

// sync the redux state when a simple notification was auto-closed
notification.onChange((payload: ToastItem<{ kind?: string }>) => {
  if (payload.data.kind !== 'simple') {
    return;
  }

  if (payload.status === 'removed') {
    store.dispatch(simpleNotificationClosed(payload.id as string));
  }
});

export * from 'react-toastify';
