import { call, select } from 'redux-saga/effects';
import { selectUserById } from 'features/users/usersSlice';
import { User } from 'features/users/types';
import { RTCClient } from 'utils/webrtc';
import i18n from 'i18n';
import { logger } from 'utils/logger';
import { notification } from 'features/notifications/toast/notification';
import { audioMutedRemotely } from 'features/streaming/actions';

export function* onAudioMutedRemotelySaga(action: ReturnType<typeof audioMutedRemotely>) {
  logger.remote({ tier: 1 }).info(`Muted remotely by user id=${action.payload.initiator.id}`);

  const user: User = yield select(selectUserById, action.payload.initiator.id);
  if (user?.name) {
    yield call(notification, i18n.t('notifications:audio_muted_remotely', { username: user.name }));
  } else {
    yield call(notification, i18n.t('notifications:audio_muted_remotely_unnamed'));
  }

  yield call(RTCClient.publishingFeed.stopAudioBroadcasting);
}
