class Holder<Held> {
  instance: Held | null;

  constructor(builder: () => Held) {
    this.instance = builder();
  }
}

export const singleton = <Shape extends Record<string, any>>(builder: () => Shape) => {
  const interceptor = {
    get(holder: Holder<Shape>, prop: string) {
      if (prop === '__debug') {
        // support console output
        return holder.instance;
      }

      return holder.instance![prop];
    },
    set(holder: Holder<Record<string, any>>, prop: string, val: any) {
      if (holder.instance) {
        holder.instance[prop] = val;
        return true;
      }

      return false;
    },
    enumerate(holder: Holder<Shape>) {
      return Object.keys(holder.instance!);
    },
    ownKeys(holder: Holder<Shape>) {
      return Object.keys(holder.instance!);
    },
    has(holder: Holder<Shape>, prop: string) {
      return prop in holder.instance! || holder.instance!.hasItem(prop);
    },
    getOwnPropertyDescriptor(holder: Holder<Shape>, prop: string) {
      return Object.getOwnPropertyDescriptor(holder.instance!, holder.instance![prop]);
    },
  };

  // hint generic type for completion to work. This will still show up as proxy but with all <Shape> properties
  return new Proxy(new Holder(builder), interceptor) as unknown as Shape & { __debug: Shape };
};
