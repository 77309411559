import { TooltipProps } from '@mui/material';
import Tooltip from 'components/Tooltip';
import { selectShowHoverChildren } from 'features/layout/features/config/configSlice';
import { useAppSelector } from 'store/hooks';

export const ZenModeTooltip = (props: TooltipProps) => {
  const showHoverChildren = useAppSelector((state) => selectShowHoverChildren(state, 'tiles'));

  const { title, ...tooltipProps } = props;

  return <Tooltip title={showHoverChildren ? title : undefined} {...tooltipProps} />;
};
