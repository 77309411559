import { streamMinimized } from 'features/layout/actions';
import { selectMaximizedStream } from 'features/layout/features/content/contentSlice';
import {
  contentWidthChanged,
  selectContentWidth,
  selectPinnedContentWidth,
} from 'features/layout/features/contentDimensions/contentDimensionsSlice';
import { SourceDetails } from 'features/layout/types';
import { sourceMatches } from 'features/streaming/utils';
import { put, select } from 'redux-saga/effects';
import { layoutConfig } from 'utils/layout';

// If this hase more usages, figure out some other way of accessing stopepd stream
export function* updateContentSizeSaga(stoppedStream: SourceDetails) {
  const maximizedStream: SourceDetails = yield select(selectMaximizedStream);

  const contentWidth: number = yield select(selectContentWidth);
  const pinnedContentWidth: number = yield select(selectPinnedContentWidth);

  if (sourceMatches(maximizedStream, stoppedStream)) {
    yield put(streamMinimized());
  } else if (!contentWidth) {
    yield put(contentWidthChanged(pinnedContentWidth || layoutConfig.defaultPinWidth));
  }
}
