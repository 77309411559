import { PayloadAction } from '@reduxjs/toolkit';
import { CaptionsSpokenLanguage } from 'features/application/types';
import { call } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { eventBus } from 'utils/eventBus';

export function* onSpokenLanguageChangedSaga(action: PayloadAction<CaptionsSpokenLanguage>) {
  SignalingSocket.send({
    event: 'changeSpokenLanguage',
    data: { code: action.payload },
  });

  yield call(
    eventBus.sendMessage,
    'captionsSpokenLanguageChanged',
    {},
    { language: action.payload }
  );
}
