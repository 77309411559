import { Action, PayloadAction } from '@reduxjs/toolkit';
import { InteractionKind } from 'features/application/types';
import { call, put } from 'redux-saga/effects';
import { cleanupRelatedInteractionNotifications } from 'features/notifications/thunks/cleanupRelatedInteractionNotifications';

export function* interactionBusCommandSaga(action: PayloadAction<InteractionKind>) {
  const interaction = action.payload;

  switch (interaction) {
    case 'unmuteAudio':
    case 'unmuteVideo':
      {
        const cleanupNotificationsThunk: Action = yield call(
          cleanupRelatedInteractionNotifications,
          interaction
        );
        yield put(cleanupNotificationsThunk);
      }
      break;
    default:
      break;
  }
}
