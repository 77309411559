import { Box, Slide } from '@mui/material';
import { styleConstants } from 'theme/styleConstants';
import { useAppSelector } from 'store/hooks';
import { selectRecorderStatus } from 'features/recorder/recorderSlice';
import RecordingIndicator from 'features/toolbar/RecordingIndicator';
import { selectToolbarPlacement } from 'features/toolbar/toolbarSlice';

const SlidingRecordingIndicator = () => {
  const recorderStatus = useAppSelector(selectRecorderStatus);
  const toolbarPlacement = useAppSelector(selectToolbarPlacement);

  return (
    <Slide
      direction={toolbarPlacement === 'right' ? 'left' : 'right'}
      in={recorderStatus !== 'stopped'}
      mountOnEnter
      unmountOnExit
    >
      <Box
        sx={{
          position: 'absolute',
          top: styleConstants.recordingIndicator.offset,
          left: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <RecordingIndicator
          recorderStatus={recorderStatus}
          data-testid="sliding-recording-indicator"
        />
      </Box>
    </Slide>
  );
};

export default SlidingRecordingIndicator;
