import { call } from 'redux-saga/effects';
import { RTCClient } from 'utils/webrtc';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { notification } from 'features/notifications/toast/notification';
import i18n from 'i18n';

export function* toggleLocalScreenshareSaga() {
  const allowScreenshare: boolean = yield call(hasPermissionsSaga, PermissionTypes.screenshare);

  if (allowScreenshare) {
    yield call(RTCClient.screensharingFeed.toggleScreenshare);
  } else {
    yield call(RTCClient.screensharingFeed.stopScreenshare);

    yield call(notification, i18n.t('notifications:screenshare_no_permissions'));
  }
}
