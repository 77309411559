import { getPrivateChatSenderId } from 'features/chat/utils/getPrivateChatSenderId';
import { ChatMessageUser } from 'features/chat/types';
import { UserId } from 'features/users/types';

export interface PrivateChatTargetOptions {
  localUserId: UserId;
  user: ChatMessageUser;
  targetUser: ChatMessageUser;
  localUserExternalId?: string;
}

export interface PrivateChatTarget {
  targetChatId: string;
  isLocalTarget: boolean;
  chatTargetUser: ChatMessageUser;
}

export const getPrivateChatTarget = ({
  localUserId,
  localUserExternalId,
  user,
  targetUser,
}: PrivateChatTargetOptions) => {
  let targetChatId = getPrivateChatSenderId(targetUser);

  const isLocalTarget = localUserId === targetChatId || localUserExternalId === targetChatId;
  const refinedTargetUser = isLocalTarget ? user : targetUser;

  targetChatId = getPrivateChatSenderId(refinedTargetUser);

  return {
    targetChatId,
    isLocalTarget,
    chatTargetUser: refinedTargetUser,
  };
};
