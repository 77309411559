import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal, selectActiveModalData } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import RoomSettings from 'features/room/settings/RoomSettings';
import { Dialog, DialogTitle, DialogContent } from 'components/Dialog';

const RoomSettingsModal = () => {
  const { t } = useTranslation('settings');
  const dispatch = useAppDispatch();

  const hideModal = () => {
    dispatch(closeModal('roomSettings'));
  };

  const modalData = useAppSelector(selectActiveModalData);

  return (
    <Dialog
      open
      aria-labelledby="room-settings-title"
      onClose={hideModal}
      maxWidth="md"
      centered={false}
    >
      <DialogTitle id="room-settings-title" onClose={hideModal}>
        {t('modal_title')}
      </DialogTitle>
      <DialogContent>
        <RoomSettings activeTabIndex={modalData.activeTab} />
      </DialogContent>
    </Dialog>
  );
};

export default RoomSettingsModal;
