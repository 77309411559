import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Button, FormControl, Stack } from '@mui/material';
import InputLabel from 'components/InputLabel';
import RoundedMultilineInput from 'components/RoundedMultilineInput';
import Icon from 'components/Icon';
import SettingControl from 'components/SettingControl';
import { Switch } from 'components/Switch';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';

import { DynamicChoice } from 'features/polls/components/DynamicChoice';
import type { DragEndEvent } from '@dnd-kit/core/dist/types';

export interface PollFormValues {
  question: string;
  choices: {
    text: string;
  }[];
  multipleChoices: boolean;
  anonymous: boolean;
}

const PollForm = () => {
  const { t } = useTranslation(['modals', 'polls']);

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<PollFormValues>();

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'choices',
    rules: {
      required: true,
      validate: (_, values) => values.choices.length > 1,
    },
  });

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const addChoice = () => {
    append({ text: '' });
  };

  const deleteChoice = (index: number) => {
    remove(index);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over === null || active.id === over.id) {
      return;
    }

    let oldIndex = -1;
    let newIndex = -1;

    fields.forEach((field, index) => {
      if (field.id === active.id) {
        oldIndex = index;
      } else if (field.id === over.id) {
        newIndex = index;
      }
    });

    if (oldIndex !== -1 && newIndex !== -1) {
      move(oldIndex, newIndex);
    }
  };

  const choices = watch('choices');

  return (
    <Stack spacing={6}>
      <FormControl fullWidth error={!!errors?.question}>
        <InputLabel htmlFor="poll-form-question">{t('polls:poll_form.question.label')}</InputLabel>
        <Controller
          name="question"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { ref, ...field } }) => (
            <RoundedMultilineInput
              id="poll-form-question"
              autoFocus
              inputProps={{
                maxLength: 256,
              }}
              {...field}
              inputRef={ref}
            />
          )}
        />
      </FormControl>
      <div>
        <InputLabel
          sx={{
            mb: 1.5,
          }}
        >
          {t('polls:poll_form.choices.label')}
        </InputLabel>
        <div>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToParentElement]}
          >
            <SortableContext items={fields} strategy={verticalListSortingStrategy}>
              {fields.map((item, index) => (
                <DynamicChoice
                  key={item.id}
                  id={item.id}
                  control={control}
                  index={index}
                  errors={errors}
                  deleteChoice={deleteChoice}
                />
              ))}
            </SortableContext>
          </DndContext>
        </div>
        <Button
          startIcon={<Icon name="add" />}
          type="button"
          variant="outlined"
          onClick={addChoice}
          disabled={choices.length === 10}
        >
          {t('polls:poll_form.choices.btn_add_choice')}
        </Button>
      </div>
      <SettingControl
        label={t('polls:poll_form.multiple_choice.label')}
        control={
          <FormControl>
            <Controller
              name="multipleChoices"
              control={control}
              render={({ field: { value, ref, ...field } }) => (
                <Switch
                  {...field}
                  inputRef={ref}
                  checked={value}
                  inputProps={{
                    'aria-label': value
                      ? t('polls:poll_form.multiple_choice.switch_disable_aria_label')
                      : t('polls:poll_form.multiple_choice.switch_enable_aria_label'),
                  }}
                />
              )}
            />
          </FormControl>
        }
      />
      <SettingControl
        label={t('polls:poll_form.anonymous_poll.label')}
        control={
          <FormControl>
            <Controller
              name="anonymous"
              control={control}
              render={({ field: { value, ref, ...field } }) => (
                <Switch
                  {...field}
                  inputRef={ref}
                  checked={value}
                  inputProps={{
                    'aria-label': value
                      ? t('polls:poll_form.anonymous_poll.switch_disable_aria_label')
                      : t('polls:poll_form.anonymous_poll.switch_enable_aria_label'),
                  }}
                />
              )}
            />
          </FormControl>
        }
      />
    </Stack>
  );
};

export default PollForm;
