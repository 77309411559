import i18n from 'i18n';
import { Action } from '@reduxjs/toolkit';
import { addActionNotification } from 'features/notifications/addActionNotification';
import { SESSION_ENDS_SOON_NOTIFICATION_ID } from 'features/notifications/constants';
import { sessionEndsSoonDismissed, signalingSessionEndsSoon } from 'features/room/actions';
import { call, put } from 'redux-saga/effects';

export function* onSessionEndsSoonSaga(action: ReturnType<typeof signalingSessionEndsSoon>) {
  const { minutes } = action.payload; //SESSION_ENDS_SOON_NOTIFICATION_ID

  const actionNotificationThunk: Action = yield call(
    addActionNotification,
    {
      content: i18n.t('notifications:session_ends_soon.content', { minutes }),
      okText: i18n.t('notifications:session_ends_soon.btn_ok_label'),
      okAction: sessionEndsSoonDismissed(),
    },
    {
      toastId: SESSION_ENDS_SOON_NOTIFICATION_ID,
    }
  );
  yield put(actionNotificationThunk);
}
