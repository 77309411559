import { call, take } from 'redux-saga/effects';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import * as Sentry from '@sentry/react';
import { logger } from 'utils/logger';
import { e2eeEncrypt } from 'features/e2ee/utils/e2eeEncrypt';
import { applicationKeyGenerated } from 'features/e2ee/actions';
import { AesCm256EncryptionKey } from 'features/e2ee/AesCm256EncryptionKey';

export function* encryptE2eeMessageSaga(message: string) {
  const isGeneratingSecret: boolean = yield call(E2EEManager.applicationKey.getIsGenerating);
  if (isGeneratingSecret) {
    // we need to wait for the new key before encrypting the message

    logger
      .remote({ system: true, capture: 'e2ee' })
      .debug('The application encryption key is generating. Wait for it to finish.');

    yield take(applicationKeyGenerated);
  }

  const applicationKey: AesCm256EncryptionKey = yield call(E2EEManager.getApplicationKey);
  if (!applicationKey.encryptionKey) {
    return undefined;
  }

  try {
    const encryptedMessage: string = yield call(e2eeEncrypt, message, applicationKey.encryptionKey);

    return encryptedMessage;
  } catch (error) {
    Sentry.captureException(error);
    logger.remote().error('Cannot encrypt chat message', error);
  }

  return undefined;
}
