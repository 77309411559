import { brandingReceived, selectBrandingOptions } from 'features/branding/brandingSlice';
import { BrandingState } from 'features/branding/types';
import { BrandingOptions } from 'features/room/types';
import { call, put, select } from 'redux-saga/effects';
import { BrandingOptionsConfig, eventBus } from 'utils/eventBus';

const sdkBrandingOptionsToAppConfig = (
  config: BrandingState,
  options: Partial<BrandingOptionsConfig>
) => {
  const newConfig: BrandingOptions = {
    palette: {
      mode: options.paletteMode || config.palette.mode!,
      // @ts-ignore
      primary: options.primaryColor || config.palette.primary.main,
    },
    components: {
      toolbarBackground: options.toolbarColor || config.components.toolbar?.background!,
      roomBackground: options.roomBackgroundColor || config.components.room?.background!,
    },
  };

  return newConfig;
};

export function* onChangeBrandingOptionsCommandSaga(options: Partial<BrandingOptionsConfig>) {
  const currentConfig: BrandingState = yield select(selectBrandingOptions);

  const optionsValid = [
    options.primaryColor,
    options.roomBackgroundColor,
    options.toolbarColor,
  ].every((color) => (color ? /^#([0-9A-F]{3}){1,2}$/i.test(color) : true));

  if (!optionsValid) {
    yield call(eventBus.error, {
      name: 'branding',
      message: 'Can not apply branding, some of requested options were invalid',
    });

    return;
  }

  const newConfig: BrandingOptions = yield call(
    sdkBrandingOptionsToAppConfig,
    currentConfig,
    options
  );

  yield put(brandingReceived(newConfig));
}
