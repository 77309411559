import { ListItemIcon, ListItemText, MenuItem, MenuItemProps } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import Icon from 'components/Icon';
import React from 'react';

const StreamControlMenuItemOuter = styled(MenuItem)(({ theme }) => ({
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.1),
  },
}));

type StreamControlMenuItemProps = {
  icon: string;
  text: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement | HTMLLIElement, MouseEvent>) => void;
} & MenuItemProps;

export const StreamControlMenuItem = ({
  icon,
  text,
  onClick,
  ...props
}: StreamControlMenuItemProps) => (
  <StreamControlMenuItemOuter onClick={onClick} {...props}>
    <ListItemIcon>
      <Icon name={icon} />
    </ListItemIcon>
    <ListItemText>{text}</ListItemText>
  </StreamControlMenuItemOuter>
);
