import { createAction } from '@reduxjs/toolkit';
import {
  EditPollPayload,
  Poll,
  PollPayload,
  VotePayload,
  VoteResponse,
} from 'features/polls/types';
import { EntityIdPayload, SignalingEventPayload } from 'services/signaling';

export const createPollRequested = createAction<PollPayload>('polls/createPollRequested');

export const signalingPollCreated = createAction<Poll>('signaling/pollCreated');

export const launchPollRequested = createAction<string>('polls/launchPollRequested');
export const signalingPollLaunched =
  createAction<SignalingEventPayload<Poll>>('signaling/pollLaunched');

export const endPollRequested = createAction<string>('polls/endPollRequested');
export const signalingPollEnded = createAction<EntityIdPayload>('signaling/pollEnded');

export const signalingPollsReceived = createAction<{
  polls: Poll[];
}>('signaling/pollsReceived');

export const deletePollRequested = createAction<string>('polls/deletePollRequested');
export const signalingPollDeleted = createAction<EntityIdPayload>('signaling/pollDeleted');

export const votePollRequested = createAction<VotePayload>('polls/votePollRequested');
export const signalingPollVoted = createAction<VoteResponse>('signaling/pollVoted');

export const sharePollResultsRequested = createAction<string>('polls/shareResultsRequested');
export const signalingPollResultsShared = createAction<
  SignalingEventPayload<Poll | EntityIdPayload>
>('signaling/pollResultsShared');

export const stopSharingPollResultsRequested = createAction<string>(
  'polls/stopSharingResultsRequested'
);
export const signalingPollResultsSharingStopped = createAction<EntityIdPayload>(
  'signaling/pollResultsSharingStopped'
);

export const editPollRequested = createAction<EditPollPayload>('polls/editPollRequested');
export const signalingPollEdited = createAction<Poll>('signaling/pollEdited');

export const clonePollRequested = createAction<string>('polls/clonePollRequested');

export const exportSinglePollRequested = createAction<string>('polls/exportSinglePollRequested');
