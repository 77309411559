import { selectCaptionsDisplayed } from 'features/transcripts/slices/captions/captionsSlice';
import { call, select } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { eventBus } from 'utils/eventBus';

export function* onCaptionsToggledSaga() {
  const captionsDisplayed: boolean = yield select(selectCaptionsDisplayed);

  if (captionsDisplayed) {
    yield call(eventBus.sendMessage, 'captionsEnabled', {});
    yield call(SignalingSocket.send, { event: 'openCaptionsPanel' });
  } else {
    yield call(eventBus.sendMessage, 'captionsDisabled', {});
    yield call(SignalingSocket.send, { event: 'closeCaptionsPanel' });
  }
}
