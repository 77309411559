import { selectDeviceType } from 'features/application/applicationSlice';
import {
  contentWidthChanged,
  selectContentWidth,
} from 'features/layout/features/contentDimensions/contentDimensionsSlice';
import { selectAdditionalControlsDisabled } from 'features/layout/selectors';
import { useRecorder } from 'features/recorder/useRecorder';
import React, { ForwardedRef, useCallback, useContext } from 'react';
import { RoomLayoutValuesContext } from 'features/layout/Context';
import { Axis, Resizable, ResizeCallbackData } from 'react-resizable';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { layoutConfig } from 'utils/layout';

const HandleOuter = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'show',
})<{ show?: boolean }>(({ theme, show }) => ({
  height: '100%',
  width: '10px',
  position: 'absolute',
  right: 0,
  top: 0,
  display: show ? 'flex' : 'none',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'ew-resize',
  zIndex: 2,
  '.resize-pill': {
    opacity: 1,
    height: '36px',
    width: '6px',
    borderRadius: '.25rem',
    border: `1px solid ${theme.room.tileBackground}`,
    backgroundColor: '#fff',
  },
}));

const CustomHandle = React.forwardRef(
  (props: Partial<{ handleAxis: Axis; show: boolean }>, ref: ForwardedRef<HTMLDivElement>) => {
    const { handleAxis, show, ...restProps } = props;
    return (
      <HandleOuter ref={ref} className={`handle-${handleAxis}`} show={show} {...restProps}>
        <Box className="resize-pill" />
      </HandleOuter>
    );
  }
);

export const ResizableBox = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();
  // @TODO: Optimize these?
  const { roomWidth, roomHeight, verticalLayout } = useContext(RoomLayoutValuesContext);

  const deviceType = useAppSelector(selectDeviceType);
  const contentWidth = useAppSelector(selectContentWidth);

  const isMobileBreakpoint = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const resizableBoxHeight = verticalLayout ? roomWidth * 0.75 : roomHeight;

  const additionalControlsDisabled = useAppSelector(selectAdditionalControlsDisabled);

  const isRecorder = useRecorder();

  const showHandle = !(verticalLayout || isRecorder || additionalControlsDisabled);

  const resizeLayout = useCallback(
    (e: React.SyntheticEvent, { size: { width } }: ResizeCallbackData) => {
      const widthPercent = width / roomWidth;

      dispatch(contentWidthChanged(widthPercent));
    },
    [roomWidth, dispatch]
  );

  const maxContentWidth = roomWidth - layoutConfig.minSidebarWidth;
  const boxWidth = Math.min(contentWidth * roomWidth, maxContentWidth);

  return (
    <Resizable
      maxConstraints={[maxContentWidth, roomHeight]}
      minConstraints={[roomWidth * layoutConfig.minContentWidth, 0]}
      height={resizableBoxHeight}
      width={boxWidth}
      onResize={resizeLayout}
      resizeHandles={deviceType === 'mobile' || isMobileBreakpoint ? [] : ['e']}
      axis="x"
      handle={<CustomHandle show={showHandle} />}
    >
      <Box
        sx={{
          width: '100%',
          height: resizableBoxHeight,
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          maxHeight: `${roomHeight}px`,
          paddingRight: showHandle ? '10px' : 0,
        }}
      >
        {children}
      </Box>
    </Resizable>
  );
};
