import { styled } from '@mui/material/styles';
import { InputLabel as MuiInputLabel, InputLabelProps } from '@mui/material';

// @TODO possible make global styles override through theme config
const InputLabel = styled((props: InputLabelProps) => <MuiInputLabel shrink {...props} />)(
  ({ theme }) => ({
    fontSize: '1rem',
    color: theme.palette.text.primary,
    transform: 'none',
  })
);

export default InputLabel;
