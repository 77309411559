import { call, put, select } from 'redux-saga/effects';
import { Action } from '@reduxjs/toolkit';
import { signalingAnswerDeleted } from 'features/qa/actions';
import { closeActionNotification } from 'features/notifications/thunks/closeActionNotification';
import { selectNotificationById } from 'features/notifications/notificationsSlice';
import { ActionNotification, NotificationId } from 'features/notifications/types';

export function* onAnswerDeletedSaga(action: ReturnType<typeof signalingAnswerDeleted>) {
  const notificationId: NotificationId = `${action.payload.questionId}_${action.payload.id}`;
  const activeNotification: ActionNotification = yield select(
    selectNotificationById,
    notificationId
  );

  if (activeNotification) {
    const cleanupNotificationThunk: Action = yield call(closeActionNotification, notificationId);
    yield put(cleanupNotificationThunk);
  }
}
