import WaitingScreen from 'components/WaitingScreen/WaitingScreen';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

const ParticipantsLimit = () => {
  const { t } = useTranslation('waiting-screens');

  return (
    <WaitingScreen show title={t('participants_limit.title')}>
      <Typography>{t('participants_limit.text')}</Typography>
    </WaitingScreen>
  );
};

export default ParticipantsLimit;
