import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal, selectActiveModalData } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from 'components/Dialog';
import Button from '@mui/material/Button';
import { UserId } from 'features/users/types';
import { selectActiveUserById } from 'features/users/usersSlice';
import { kickUser } from 'features/users/actions';
import { PermissionTypes } from 'features/permissions/types';
import { useEffect } from 'react';
import { usePermissions } from 'features/permissions/usePermissions';

const KickUserConfirmation = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const modalData = useAppSelector(selectActiveModalData);
  const userId = modalData.userId as UserId;

  const user = useAppSelector((state) => selectActiveUserById(state, userId));

  const { hasPermissions } = usePermissions();

  const canRemoveParticipant = hasPermissions(PermissionTypes.removeParticipant, {
    targetRole: user?.role,
  });

  useEffect(() => {
    if (!user || !canRemoveParticipant) {
      dispatch(closeModal('kickUserConfirmation'));
    }
  }, [user, canRemoveParticipant, dispatch]);

  const handleOk = () => {
    dispatch(kickUser(userId));
    dispatch(closeModal('kickUserConfirmation'));
  };

  const handleCancel = () => {
    dispatch(closeModal('kickUserConfirmation'));
  };

  if (!user) {
    return null;
  }

  return (
    <Dialog
      open
      maxWidth="xs"
      aria-labelledby="kick-user-title"
      aria-describedby="kick-user-description"
    >
      <DialogTitle id="kick-user-title">
        {t('kick_user_confirm.title', { username: user.name })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="kick-user-description">
          {t('kick_user_confirm.description', { username: user.name })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="grayscale">
          {t('kick_user_confirm.btn_cancel_label')}
        </Button>
        <Button onClick={handleOk}>{t('kick_user_confirm.btn_ok_label')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default KickUserConfirmation;
