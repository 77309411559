import { call, put, select } from 'redux-saga/effects';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { User } from 'features/users/types';
import { selectAwaitingEntryUsers } from 'features/users/selectors/selectAwaitingEntryUsers';
import { ActionNotification } from 'features/notifications/types';
import { selectNotificationById } from 'features/notifications/notificationsSlice';
import { WAITING_ENTRY_NOTIFICATION_ID } from 'features/notifications/constants';
import i18n from 'i18n';
import { ToolbarPanelType } from 'features/toolbar/types';
import { selectToolbarPanel, toolbarPanelOpened } from 'features/toolbar/toolbarSlice';
import { Action } from '@reduxjs/toolkit';
import { addActionNotification } from 'features/notifications/addActionNotification';
import * as Sentry from '@sentry/react';
import { logger } from 'utils/logger';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { waitingEntryNotificationUpdateRequested } from 'features/notifications/actions';
import { updateActionNotification } from 'features/notifications/updateActionNotification';
import { closeActionNotification } from 'features/notifications/thunks/closeActionNotification';

export function* handleWaitingEntryNotificationSaga() {
  try {
    const canControlRoomEntry: boolean = yield call(
      hasPermissionsSaga,
      PermissionTypes.controlRoomEntry
    );

    if (!canControlRoomEntry) {
      return;
    }

    const awaitingUsers: User[] = yield select(selectAwaitingEntryUsers);
    const awaitingUsersCount = awaitingUsers.length;

    const activeNotification: ActionNotification = yield select(
      selectNotificationById,
      WAITING_ENTRY_NOTIFICATION_ID
    );

    if (awaitingUsersCount === 0 && activeNotification) {
      // close the notification if the last waiting user left
      const cleanupNotificationThunk: Action = yield call(
        closeActionNotification,
        WAITING_ENTRY_NOTIFICATION_ID
      );
      yield put(cleanupNotificationThunk);

      return;
    }

    if (awaitingUsersCount === 0) {
      // there are no waiting users, no need to create or update the notification
      return;
    }

    // only the name of this user will be shown on the notification
    const featuredUser = awaitingUsers[0];

    const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
    if (e2eeEnabled) {
      if (!featuredUser.name) {
        yield call(
          E2EEManager.enqueuePendingAction,
          waitingEntryNotificationUpdateRequested({ id: featuredUser.id })
        );

        return;
      }
    }

    if (!activeNotification) {
      // show the action notification once
      const activePanel: ToolbarPanelType = yield select(selectToolbarPanel);

      const actionNotificationThunk: Action = yield call(
        addActionNotification,
        {
          content: i18n.t('notifications:waiting_user_joined.content', {
            username: featuredUser.name,
            count: awaitingUsers.length - 1,
            context: awaitingUsersCount > 1 ? 'grouped' : undefined,
          }),
          okText: i18n.t('notifications:waiting_user_joined.btn_ok_label'),
          okAction: toolbarPanelOpened('users'),
          hideOkAction: activePanel === 'users',
          initiatorId: featuredUser.id,
          preventCloseOnAction: true,
          userAgnostic: true,
        },
        {
          toastId: WAITING_ENTRY_NOTIFICATION_ID,
        }
      );
      yield put(actionNotificationThunk);
      return;
    }

    // group incoming notifications
    const updateNotificationThunk: Action = yield call(
      updateActionNotification,
      WAITING_ENTRY_NOTIFICATION_ID,
      {
        content: i18n.t('notifications:waiting_user_joined.content', {
          username: featuredUser.name,
          count: awaitingUsers.length - 1,
          context: awaitingUsersCount > 1 ? 'grouped' : undefined,
        }),
        initiatorId: featuredUser.id,
      }
    );
    yield put(updateNotificationThunk);
  } catch (error) {
    Sentry.captureException(error);
    logger.remote({ tier: 1 }).error('Cannot handle waiting entry notification', error);
  }
}
