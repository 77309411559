import { StreamingMediaProps } from 'features/streaming/types';
import { getFillerColor } from 'utils/colors';
import Avatar from 'features/streaming/components/stream-display/Avatar';

const DebugMedia = ({ source, avatarSize }: StreamingMediaProps) => (
  <Avatar
    size={avatarSize}
    sx={{
      bgcolor: getFillerColor(Number(source.userId)),
    }}
  >
    {source.userId}
  </Avatar>
);

export default DebugMedia;
