import { SourceDetails } from 'features/layout/types';
import { makeSourceKey } from 'features/streaming/utils';
import { RemoteAudio } from 'features/streaming/components/media/remote-stream/RemoteAudio';
import React from 'react';
import { useAppSelector } from 'store/hooks';
import { selectFeedIdByUser } from 'features/streaming/streamingSlice';

type RemoteAudioWrapperProps = {
  source: SourceDetails;
};

const RemoteAudioWrapper = ({ source }: RemoteAudioWrapperProps) => {
  const lookupFeedId = useAppSelector((state) => selectFeedIdByUser(state, source.userId));
  const feedId = source.feedId || lookupFeedId;

  if (!feedId) {
    return null;
  }

  const componentKey = `${makeSourceKey(source)}`;

  return <RemoteAudio userId={source.userId} feedId={feedId} key={componentKey} />;
};

export default RemoteAudioWrapper;
