import { call } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { removeUpvoteQuestionRequested } from 'features/qa/actions';

export function* removeUpvoteQuestionSaga(
  action: ReturnType<typeof removeUpvoteQuestionRequested>
) {
  yield call(SignalingSocket.send, {
    event: 'removeUpvoteQuestion',
    data: {
      id: action.payload.id,
    },
  });
}
