import React from 'react';
import { selectShowParticipantNamesInRecording } from 'features/layout/features/config/configSlice';
import { AvatarSize } from 'features/layout/features/dimensions/types';
import { getInitials } from 'utils/getInitials';
import { useAppSelector } from 'store/hooks';
import { selectUserById } from 'features/users/usersSlice';
import { SourceDetails, SourceKind } from 'features/layout/types';
import { StreamBadge } from 'features/streaming/components/stream-display/StreamBadge';
import { useTranslation } from 'react-i18next';
import Avatar from 'features/streaming/components/stream-display/Avatar';
import { RecorderGuard } from 'features/recorder/RecorderGuard';
import { UserName } from 'features/users/types';

type StreamMediaProps = {
  source: SourceDetails;
  children: React.ReactNode;
  avatarSize?: AvatarSize;
};

const StreamMedia = React.memo(({ source, children, avatarSize }: StreamMediaProps) => {
  const { t } = useTranslation();
  const showBadge = useAppSelector(selectShowParticipantNamesInRecording);
  const user = useAppSelector((state) => selectUserById(state, source.userId));

  if (!user) {
    return null;
  }

  const labels: Record<SourceKind, UserName> = {
    'screenshare-local': `${t('screenshare')}`,
    'screenshare-remote': `${user.name} (${t('screenshare')})`,
    local: t('you'),
    debug: user.name,
    remote: user.name,
  };

  const badgeLabel = labels[source.kind];

  return (
    <>
      <RecorderGuard show={showBadge}>
        <StreamBadge label={badgeLabel} />
      </RecorderGuard>
      <Avatar
        src={user.avatarUrl}
        size={avatarSize}
        sx={{
          bgcolor: user.avatarColor,
        }}
      >
        {user.initials || getInitials(user.name)}
      </Avatar>
      {children}
    </>
  );
});

export default StreamMedia;
