import { call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { User } from 'features/users/types';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { updateOrderSaga } from 'features/layout/sagas/updateOrderSaga';

export function* onUserJoinedSaga(action: PayloadAction<User>) {
  const allowBroadcast: boolean = yield call(hasPermissionsSaga, PermissionTypes.broadcast, {
    targetUser: action.payload,
  });

  if (!allowBroadcast) {
    return;
  }

  yield call(updateOrderSaga, action.payload.id);
}
