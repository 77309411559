import { useAppSelector } from 'store/hooks';
import { selectDeviceType } from 'features/application/applicationSlice';
import { Inner, Root, ToolbarBg } from 'features/toolbar/Toolbar/styles';
import MobileNav from 'features/toolbar/Toolbar/mobile/MobileNav';
import ToolbarNav from 'features/toolbar/ToolbarNav';
import MobileMenu from 'features/toolbar/Toolbar/mobile/MobileMenu';
import { selectToolbarOrientation, selectToolbarPlacement } from 'features/toolbar/toolbarSlice';
import { useMobileMenu } from 'features/toolbar/Toolbar/mobile/useMobileMenu';
import React from 'react';

const Toolbar = React.memo(() => {
  const toolbarOrientation = useAppSelector(selectToolbarOrientation);
  const toolbarPlacement = useAppSelector(selectToolbarPlacement);
  const deviceType = useAppSelector(selectDeviceType);

  const { open: mobileMenuOpen, closeMenu, triggerMenu } = useMobileMenu();

  return (
    <>
      <Root placement={toolbarPlacement} orientation={toolbarOrientation} data-testid="toolbar">
        <ToolbarBg />
        <Inner orientation={toolbarOrientation}>
          {deviceType === 'mobile' ? (
            <MobileNav orientation={toolbarOrientation} onHamburgerClick={triggerMenu} />
          ) : (
            <ToolbarNav orientation={toolbarOrientation} />
          )}
        </Inner>
      </Root>
      <MobileMenu open={mobileMenuOpen} placement={toolbarPlacement} onClose={closeMenu} />
    </>
  );
});

export default Toolbar;
