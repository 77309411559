import { call, put, select } from 'redux-saga/effects';
import { signalingPollResultsShared } from 'features/polls/actions';
import { Action } from '@reduxjs/toolkit';
import { addActionNotification } from 'features/notifications/addActionNotification';
import i18n from 'i18n';
import { selectToolbarPanel, toolbarPanelOpened } from 'features/toolbar/toolbarSlice';
import { FeatureFlag } from 'features/feature-set/types';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { ToolbarPanelType } from 'features/toolbar/types';

export function* onPollResultsSharedSaga(action: ReturnType<typeof signalingPollResultsShared>) {
  const canModeratePolls: boolean = yield call(hasPermissionsSaga, PermissionTypes.managePolls);
  if (canModeratePolls) {
    // skip for moderators
    return;
  }

  const openedPanel: ToolbarPanelType = yield select(selectToolbarPanel);
  if (openedPanel === 'polls') {
    // show only if the polls panel is not opened
    return;
  }

  const { initiator, data } = action.payload;

  const actionNotificationThunk: Action = yield call(
    addActionNotification,
    {
      content: i18n.t('notifications:polls.results_shared.content', {
        username: initiator.name,
      }),
      okText: i18n.t('notifications:polls.results_shared.btn_ok_label'),
      okAction: toolbarPanelOpened('polls'),
      initiator,
      scope: [FeatureFlag.polls],
    },
    {
      toastId: `poll_results_shared_${data.id}`,
    }
  );

  yield put(actionNotificationThunk);
}
