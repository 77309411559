import {
  selectCorrectionHeight,
  selectCorrectionWidth,
} from 'features/layout/features/dimensions/dimensionsSlice';
import { PagedTiles } from 'features/layout/pagination/PagedTiles';
import { useRecorder } from 'features/recorder/useRecorder';
import { useAppSelector } from 'store/hooks';
import { styled } from '@mui/material/styles';
import { usePaginationControls } from 'features/layout/pagination/hooks';
import { PaginationControl } from 'features/layout/pagination/PaginationControl';
import React from 'react';
import { AudioStreams } from 'features/streaming/components/media/AudioStreams';
import { styleConstants } from 'theme/styleConstants';

const Root = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'correctionHeight' && prop !== 'hasPagination' && prop !== 'correctionWidth',
})<{
  correctionHeight: number;
  correctionWidth: number;
  hasPagination: boolean;
}>(({ correctionHeight, correctionWidth, hasPagination }) => ({
  height: '100%',
  position: 'relative',
  maxHeight: '100%',
  width: '100%',
  transition: 'padding .05s linear',
  padding: `${correctionHeight}px ${
    (hasPagination ? styleConstants.layout.pagination.controlWidth : 0) + correctionWidth
  }px`,
}));

export const TileArea = ({ ref }: { ref?: React.RefObject<HTMLDivElement> }) => {
  const [canGoNext, canGoPrev] = usePaginationControls();
  const isRecorder = useRecorder();

  const hasPagination = !isRecorder && (canGoNext || canGoPrev);
  const correctionHeight = useAppSelector(selectCorrectionHeight);
  const correctionWidth = useAppSelector(selectCorrectionWidth);

  return (
    <Root
      ref={ref}
      id="layout-paged-tiles"
      data-testid="layout-paged-tiles"
      hasPagination={hasPagination}
      correctionHeight={correctionHeight}
      correctionWidth={correctionWidth}
    >
      <PagedTiles />
      <AudioStreams />
      {canGoPrev && !isRecorder && <PaginationControl direction="prev" />}
      {canGoNext && !isRecorder && <PaginationControl direction="next" />}
    </Root>
  );
};
