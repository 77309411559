import { selectPendingVirtualBackgroundConfig } from 'features/virtual-backgrounds/selectors';
import React, { useEffect, useRef } from 'react';
import { PreviewFormValues } from 'features/join/publisher/JoinPreview';
import { SubmitHandler } from 'react-hook-form';
import JoinPublisherLayout from 'features/join/publisher/JoinPublisherLayout';
import { roomJoinRequest } from 'features/room/actions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { BroadcastSetupContextProvider, useBroadcastSetupMedia } from 'utils/broadcast-setup';

const JoinPublisher = () => {
  const appDispatch = useAppDispatch();

  const didInitRef = useRef(false);

  const {
    onAudioOutputChange,
    onAudioInputChange,
    onVideoInputChange,
    onAudioToggle,
    onVideoToggle,
    dispatch,
    enableAudio,
    disableAudio,
    disableCamera,
    enableCamera,
    requestPermissions,
    videoStream,
    audioStream,
    resetVB,
    changeVB,
    changeVBEffect,
    state: { audio, video, status },
  } = useBroadcastSetupMedia();

  const pendingVBConfig = useAppSelector(selectPendingVirtualBackgroundConfig);

  useEffect(() => {
    if (!didInitRef.current) {
      didInitRef.current = true;
      requestPermissions();
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    changeVBEffect(videoStream, pendingVBConfig);
  }, [pendingVBConfig, videoStream]);

  const handleJoinWithoutDevices = () => {
    appDispatch(roomJoinRequest());
  };

  const handleJoinRoom: SubmitHandler<PreviewFormValues> = () => {
    appDispatch(roomJoinRequest());
  };

  return (
    <BroadcastSetupContextProvider
      value={{
        video,
        audio,
        videoStream,
        audioStream,
        onVideoToggle,
        onAudioToggle,
        onAudioInputChange,
        onAudioOutputChange,
        onVideoInputChange,
        enableAudio,
        disableAudio,
        disableCamera,
        enableCamera,
        dispatch,
        resetVB,
        changeVB,
      }}
    >
      <JoinPublisherLayout
        joinWithoutDevices={handleJoinWithoutDevices}
        status={status}
        joinRoom={handleJoinRoom}
      />
    </BroadcastSetupContextProvider>
  );
};

export default JoinPublisher;
