import getBrowserPermissionsDocLink from 'utils/getBrowserPermissionsDocLink';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

const BlockedMicPopper = () => {
  const { t } = useTranslation('join');

  return (
    <Typography>
      <Trans
        i18nKey="join:publisher.poppers.microphone_blocked"
        t={t}
        components={[
          // eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content
          <a href={getBrowserPermissionsDocLink()} target="_blank" rel="noopener noreferrer" />,
        ]}
      />
    </Typography>
  );
};

export default BlockedMicPopper;
