import { combineReducers } from '@reduxjs/toolkit';
import layoutConfigReducer, {
  initialState as layoutConfigInitialState,
} from './features/config/configSlice';
import layoutContentReducer, {
  initialState as layoutContentInitialState,
} from './features/content/contentSlice';
import layoutContentDimensionsReducer, {
  initialState as layoutContentDimensionsInitialState,
} from './features/contentDimensions/contentDimensionsSlice';
import layoutDimensionsReducer, {
  initialState as layoutDimensionsInitialState,
} from './features/dimensions/dimensionsSlice';
import layoutOrderReducer, {
  initialState as layoutOrderInitialState,
} from './features/order/orderSlice';
import layoutPaginationReducer, {
  initialState as layoutPaginationInitialState,
} from './features/pagination/paginationSlice';

export const initialState = {
  config: layoutConfigInitialState,
  content: layoutContentInitialState,
  contentDimensions: layoutContentDimensionsInitialState,
  dimensions: layoutDimensionsInitialState,
  order: layoutOrderInitialState,
  pagination: layoutPaginationInitialState,
};

export default combineReducers({
  config: layoutConfigReducer,
  content: layoutContentReducer,
  contentDimensions: layoutContentDimensionsReducer,
  dimensions: layoutDimensionsReducer,
  order: layoutOrderReducer,
  pagination: layoutPaginationReducer,
});
