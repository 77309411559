import { onRecordingStoppedSaga } from 'features/recorder/sagas/onRecordingStoppedSaga';
import { toggleRecordingSaga } from 'features/recorder/sagas/toggleRecordingSaga';
import { takeLatest } from 'redux-saga/effects';
import { stopRecordingRequest } from 'features/recorder/recorderSlice';
import { requestRecordingSaga } from 'features/recorder/sagas/requestRecordingSaga';
import {
  recordingToggled,
  requestRecording,
  signalingRecorderJoined,
  signalingRecordingFailed,
  signalingRecordingRequested,
  signalingRecordingStarted,
  signalingRecordingStopped,
  signalingRecordingStopRequested,
  stopRecorder,
} from 'features/recorder/actions';
import { onRecordingStartedSaga } from 'features/recorder/sagas/onRecordingStartedSaga';
import { stopRecordingRequestSaga } from 'features/recorder/sagas/stopRecordingRequestSaga';
import { stopRecordingSaga } from 'features/recorder/sagas/stopRecordingSaga';
import { onRecordingFailureSaga } from 'features/recorder/sagas/onRecordingFailureSaga';
import { stopRecorderSaga } from 'features/recorder/sagas/stopRecorderSaga';
import { onRecordingRequestedSaga } from 'features/recorder/sagas/onRecordingRequestedSaga';
import { onRecorderJoinedSaga } from 'features/recorder/sagas/onRecorderJoinedSaga';

export default function* recorderWatcher() {
  yield takeLatest(recordingToggled, toggleRecordingSaga);

  yield takeLatest(requestRecording, requestRecordingSaga);
  yield takeLatest(signalingRecordingRequested, onRecordingRequestedSaga);
  yield takeLatest(signalingRecordingStarted, onRecordingStartedSaga);

  yield takeLatest(stopRecordingRequest, stopRecordingRequestSaga);
  yield takeLatest(signalingRecordingStopRequested, stopRecordingSaga);
  yield takeLatest(stopRecorder, stopRecorderSaga);
  yield takeLatest(signalingRecordingStopped, onRecordingStoppedSaga);

  yield takeLatest(signalingRecordingFailed, onRecordingFailureSaga);

  yield takeLatest(signalingRecorderJoined, onRecorderJoinedSaga);
}
