import { selectAvailableUserIds } from 'features/users/usersSlice';
import { AppStartListening } from 'store/listenerMiddleware';
import { logger } from 'utils/logger';

export const userListChangeListener = (startListening: AppStartListening) =>
  startListening({
    predicate: (_action, currentState, originalState) => {
      const previousUsers = selectAvailableUserIds(originalState);
      const currentUsers = selectAvailableUserIds(currentState);

      return previousUsers.length !== currentUsers.length;
    },
    effect: (action, { getState }) => {
      const currentUsers = selectAvailableUserIds(getState());

      logger.updateTier(currentUsers.length);
    },
  });
