import { isAPIError } from 'utils/types';
import { call, put } from 'redux-saga/effects';
import { roomError } from 'features/room/roomSlice';
import * as Sentry from '@sentry/react';
import { isErrorLike } from 'serialize-error';
import { APIErrorMessage, lookupApiErrorMessage } from 'utils/http';

export function* roomErrorSaga(error: unknown) {
  Sentry.captureException(error);

  if (isAPIError(error)) {
    const message: APIErrorMessage | undefined = yield call(lookupApiErrorMessage, error);

    yield put(
      roomError({
        global: true,
        name: error.name,
        title: message?.title,
        message: message?.description,
      })
    );
    return;
  }

  if (isErrorLike(error)) {
    yield put(
      roomError({
        global: true,
        name: error.name,
        message: error.message,
      })
    );

    return;
  }

  yield put(roomError({ global: true }));
}
