import React, { ComponentPropsWithRef } from 'react';
import { alpha, styled } from '@mui/material/styles';
import NoStylesButton from 'components/NoStylesButton';
import Tooltip from 'components/Tooltip';

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disabled' && prop !== 'bordered' && prop !== 'selected',
})<{ selected: boolean; bordered?: boolean; disabled?: boolean }>(
  ({ selected, theme, bordered, disabled }) => ({
    width: '100%',
    height: '100%',
    borderRadius: '4px',
    overflow: 'hidden',
    img: {
      height: '100%',
      objectFit: 'cover',
      verticalAlign: 'middle',
    },
    ...(bordered && {
      boxShadow: `0 0 0 1px ${theme.palette.divider}`,
    }),
    ...(selected && {
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
    }),
    ...(disabled && {
      cursor: 'default',
      button: {
        pointerEvents: 'none',
        opacity: 0.2,
      },
    }),
  })
);

const Control = styled(NoStylesButton)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  position: 'relative',
  fontSize: theme.typography.pxToRem(32),
  '&:focus:after': {
    content: '""',
    position: 'absolute',
    display: 'block',
    width: '100%',
    height: '100%',
    boxShadow: `${alpha(theme.palette.primary.main, 1)} 0px 50px 100px -20px, ${alpha(
      theme.palette.primary.main,
      1
    )} 0px 30px 60px -30px, ${alpha(theme.palette.primary.main, 1)} 0px 0px 6px 0px inset`,
  },
}));

type BackgroundSelectControlProps = ComponentPropsWithRef<'button'> & {
  containerClassName?: string;
  children: React.ReactNode;
  selected: boolean;
  bordered?: boolean;
  disabled?: boolean;
  tooltipText?: string;
};

const BackgroundTile = ({
  selected,
  children,
  bordered,
  containerClassName,
  tooltipText,
  disabled,
  ...props
}: BackgroundSelectControlProps) => (
  <Root selected={selected} bordered={bordered} className={containerClassName} disabled={disabled}>
    <Tooltip placement="top" title={tooltipText}>
      <span>
        <Control type="button" {...props} disabled={disabled}>
          {children}
        </Control>
      </span>
    </Tooltip>
  </Root>
);

export default BackgroundTile;
