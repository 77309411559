import {
  resetJoinedUsersCount,
  resetLeftUsersCount,
  simpleNotificationClosed,
} from 'features/notifications/notificationsSlice';
import {
  USER_JOIN_NOTIFICATION_ID,
  USER_LEFT_NOTIFICATION_ID,
} from 'features/notifications/constants';
import { put } from 'redux-saga/effects';

export function* onSimpleNotificationClosedSaga(
  action: ReturnType<typeof simpleNotificationClosed>
) {
  switch (action.payload) {
    case USER_JOIN_NOTIFICATION_ID: {
      yield put(resetJoinedUsersCount());
      break;
    }

    case USER_LEFT_NOTIFICATION_ID: {
      yield put(resetLeftUsersCount());
      break;
    }
    default:
      break;
  }
}
