import { CacheProvider } from '@emotion/react';
import { PiPProvider } from 'components/PiP/PiPProvider';
import React, { Suspense, useMemo } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { CssBaseline } from '@mui/material';
import { Router } from 'router';
import { useAppSelector } from 'store/hooks';
import { selectBrandingOptions } from 'features/branding/brandingSlice';
import { createTheme } from 'theme';
import { useWindowHeight } from 'hooks/useWindowHeight';
import ErrorBoundaryFallback from 'components/ErrorBoundaryFallback';

import 'theme/common.css';
import 'theme/icons.css';

import 'react-resizable/css/styles.css';
import 'features/notifications/toast/toasts.css';
import { emotionCache } from 'utils/css';

function App() {
  useWindowHeight();

  const brandingOptions = useAppSelector(selectBrandingOptions);

  const theme = useMemo(() => createTheme(brandingOptions), [brandingOptions]);

  return (
    <ThemeProvider theme={theme}>
      <CacheProvider value={emotionCache}>
        <CssBaseline />
        <Sentry.ErrorBoundary fallback={<ErrorBoundaryFallback />}>
          <Suspense fallback={null}>
            <PiPProvider>
              <Router />
            </PiPProvider>
          </Suspense>
        </Sentry.ErrorBoundary>
      </CacheProvider>
    </ThemeProvider>
  );
}

export default App;
