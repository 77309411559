import { PayloadAction } from '@reduxjs/toolkit';
import { RemoteTrackReceived } from 'features/streaming/types';
import { call } from 'redux-saga/effects';
import { RTCClient } from 'utils/webrtc';

export function* onRemoteTrackReceivedSaga(action: PayloadAction<RemoteTrackReceived>) {
  const { pluginHandle, track, mid } = action.payload;

  yield call(RTCClient.receivingFeed.addStreamTrack, pluginHandle, track, mid);
}
