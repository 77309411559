import React from 'react';
import ReactDOM from 'react-dom/client';
import { store } from 'store/store';
import { Provider } from 'react-redux';
import { StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';
import { eventBus } from 'utils/eventBus';
import { overrideMS } from 'utils/ms';
import { RTCClient } from 'utils/webrtc';

import './i18n';
import { canExposeInternals } from 'utils/development';

if (canExposeInternals()) {
  overrideMS();
  window.RTCClient = RTCClient;
}

window.EventBus = eventBus;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <Provider store={store}>
          <App />
        </Provider>
      </StyledEngineProvider>
    </BrowserRouter>
  </React.StrictMode>
);
