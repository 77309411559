import { call } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { rejectEntry } from 'features/users/actions';

export function* rejectEntrySaga(action: ReturnType<typeof rejectEntry>) {
  yield call(SignalingSocket.send, {
    event: 'rejectUserAccess',
    data: {
      id: action.payload,
    },
  });
}
