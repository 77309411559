import { createAsyncThunk } from '@reduxjs/toolkit';
import { SignalingSocket } from 'services/signaling';
import { DetailedPollResultsPayload } from 'features/polls/types';
import { selectPollDetailedResults } from 'features/polls/pollsSlice';
import { RootState } from 'store/store';

export const requestDetailedPollResults = createAsyncThunk<
  DetailedPollResultsPayload,
  string,
  {
    state: RootState;
  }
>(
  'polls/requestDetailedPollResults',
  async (id) =>
    SignalingSocket.sendAsync<DetailedPollResultsPayload>({
      event: 'requestDetailedPollResults',
      data: {
        id,
      },
    }),
  {
    condition: (id, { getState }) => {
      const pollResults = selectPollDetailedResults(getState(), id);
      const { status } = pollResults || {};

      return !(pollResults && (status === 'loading' || status === 'success'));
    },
  }
);
