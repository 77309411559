import { createSelector } from '@reduxjs/toolkit';
import { VirtualListItem } from 'components/SectionedVirtualList';
import {
  selectAllBreakoutRoomsIds,
  selectBreakoutRoomsEntries,
} from 'features/breakout-rooms/breakoutRoomsSlice';
import { searchRooms } from 'features/breakout-rooms/utils/searchRooms';
import { getRooms } from 'features/breakout-rooms/utils/getRooms';
import { BreakoutRoomEntries, BreakoutRoomListItemType } from 'features/breakout-rooms/types';
import { RootState } from 'store/store';

const filterRooms = (
  roomIds: string[],
  searchedRooms: BreakoutRoomEntries,
  excludedIds?: string[]
) => {
  const excludedIdsSet = excludedIds ? new Set(excludedIds) : undefined;

  return roomIds.filter((id) => {
    const isInSearchedRooms = !!searchedRooms[id];
    const isExcluded = excludedIdsSet ? excludedIdsSet.has(id) : false;

    return isInSearchedRooms && !isExcluded;
  });
};

export const selectTargetBreakoutRooms = createSelector(
  [
    selectAllBreakoutRoomsIds,
    selectBreakoutRoomsEntries,
    (state: RootState, searchQuery: string) => searchQuery.toLowerCase(),
    (state: RootState, searchQuery: string, excludedIds?: string[]) => excludedIds,
  ],
  (roomIds, roomEntries, searchQuery, excludedIds) => {
    const searchedRooms = searchRooms(roomEntries, searchQuery);

    const filteredRoomIds = filterRooms(roomIds, searchedRooms, excludedIds);

    const rooms = getRooms({ roomEntries: searchedRooms, ids: filteredRoomIds });

    const result: VirtualListItem<BreakoutRoomListItemType>[] = [];

    for (const room of rooms) {
      result.push({
        id: room.id,
        type: 'common',
        label: room.name,
        data: room,
      });
    }

    return result;
  }
);
