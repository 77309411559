import { SignalingSocket } from 'services/signaling';
import { DownloadFileEventResponse } from 'features/content-library/types';
import { http } from 'utils/http';

export const downloadContentLibraryFileService = async (fileId: string) => {
  const { url, name } = await SignalingSocket.sendAsync<DownloadFileEventResponse>({
    event: 'downloadLibraryFile',
    data: {
      id: fileId,
    },
  });

  const response = await http.get<Blob>(url, {
    responseType: 'blob',
  });

  return {
    fileName: name,
    data: response.data,
    contentType: response.headers['content-type'],
  };
};
