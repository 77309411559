import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal, selectActiveModalData } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { selectPoll } from 'features/polls/pollsSlice';
import { usePolls } from 'features/polls/usePolls';
import { deletePollRequested } from 'features/polls/actions';

const DeletePollConfirmation = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const modalData = useAppSelector(selectActiveModalData);
  const pollId = modalData.id as string;

  const { isFeatureAvailable, canManagePolls } = usePolls();

  const poll = useAppSelector((state) => selectPoll(state, pollId));

  const shouldClose = !isFeatureAvailable || !canManagePolls || !poll;

  useEffect(() => {
    if (shouldClose) {
      dispatch(closeModal('deletePoll'));
    }
  }, [shouldClose, dispatch]);

  const handleCancel = () => {
    dispatch(closeModal('deletePoll'));
  };

  const handleOk = () => {
    dispatch(deletePollRequested(pollId));
    dispatch(closeModal('deletePoll'));
  };

  return (
    <ConfirmationDialog
      open={!shouldClose}
      title={t('polls.delete_poll.title')}
      description={t('polls.delete_poll.description')}
      cancelText={t('polls.delete_poll.btn_cancel_label')}
      okText={t('polls.delete_poll.btn_ok_label')}
      onOk={handleOk}
      onCancel={handleCancel}
      hideCloseButton
      preventClose
    />
  );
};

export default DeletePollConfirmation;
