import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { streamMaximized, streamMinimized } from 'features/layout/actions';
import { GridPanelName, LayoutContentState } from 'features/layout/features/content/types';
import { RootState } from 'store/store';
import { fileOpened } from 'features/content-library/contentLibrarySlice';

export const initialState: LayoutContentState = {
  maximizedContent: null,
  maximizedStream: null,
  previouslyMaximizedStream: null,
  expectScreenshareOnStage: false,
  gridPanel: null,
};

const layoutContentSlice = createSlice({
  name: 'layoutContent',
  initialState,
  reducers: {
    maximizedContentChanged(state, action: PayloadAction<'whiteboard' | 'stream' | null>) {
      state.maximizedContent = action.payload;
    },
    gridPanelOpened(state, action: PayloadAction<GridPanelName>) {
      state.gridPanel = action.payload;
    },
    gridPanelClosed(state) {
      state.gridPanel = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(streamMaximized, (state, action) => {
      state.maximizedContent = 'stream';
      state.maximizedStream = action.payload.source;
      // screenshare was maximized. from now on until user explicitly
      // minimizes it, we'll try to maximize it when necessary
      if (
        state.maximizedStream.kind === 'screenshare-local' ||
        state.maximizedStream.kind === 'screenshare-remote'
      ) {
        state.expectScreenshareOnStage = true;
      }
    });
    builder.addCase(streamMinimized, (state) => {
      state.previouslyMaximizedStream = state.maximizedStream;

      // user has minimized screenshare manually. Don't expose it now.
      if (
        state.maximizedStream?.kind === 'screenshare-local' ||
        state.maximizedStream?.kind === 'screenshare-remote'
      ) {
        state.expectScreenshareOnStage = false;
      }

      state.maximizedContent = null;
      state.maximizedStream = null;
    });
    builder.addCase(fileOpened, (state) => {
      state.gridPanel = 'contentLibrary';
    });
  },
});
export const { maximizedContentChanged, gridPanelOpened, gridPanelClosed } =
  layoutContentSlice.actions;

export const selectMaximizedStream = (state: RootState) => state.layout.content.maximizedStream;
export const selectPreviouslyMaximizedStream = (state: RootState) =>
  state.layout.content.previouslyMaximizedStream;

export const selectExpectScreenshareOnStage = (state: RootState) =>
  state.layout.content.expectScreenshareOnStage;

export const selectMaximizedContent = (state: RootState) => {
  if (
    state.layout.content.maximizedContent === 'stream' &&
    state.layout.contentDimensions.width !== 1
  ) {
    return null;
  }

  return state.layout.content.maximizedContent;
};

export const selectGridPanel = (state: RootState) => state.layout.content.gridPanel;

export default layoutContentSlice.reducer;
