import { selectVirtualBackgroundAvailable } from 'features/virtual-backgrounds/selectors';
import React from 'react';
import { Tab, TabPanel } from 'components/Tabs';
import AudioTab from 'features/room/settings/tabs/AudioTab';
import VideoTab from 'features/join/publisher/settings/VideoTab';
import { useAppSelector } from 'store/hooks';
import { useBroadcastSetup } from 'utils/broadcast-setup';
import { useFormContext } from 'react-hook-form';
import SettingsTabs from 'components/SettingsTabs';
import { PreviewFormValues } from 'features/join/publisher/JoinPreview';
import { useTranslation } from 'react-i18next';
import VirtualBackground from './VirtualBackground';

const Settings = () => {
  const { t } = useTranslation('settings');
  const {
    video,
    audio,
    audioStream,
    videoStream,
    onVideoInputChange,
    onAudioOutputChange,
    onAudioInputChange,
  } = useBroadcastSetup();
  const { control } = useFormContext<PreviewFormValues>();

  const VBAvailable = useAppSelector(selectVirtualBackgroundAvailable);

  return (
    <SettingsTabs
      renderPanels={(activeTab) => (
        <>
          <TabPanel value={activeTab} index={0} id="video-tabpanel" aria-labelledby="video-tab">
            <VideoTab
              control={control}
              videoStream={videoStream}
              onVideoInputChange={onVideoInputChange}
              cameraDisabled={video.disabled}
            />
          </TabPanel>
          <TabPanel value={activeTab} index={1} id="audio-tabpanel" aria-labelledby="audio-tab">
            <AudioTab
              vuMeterActive={audio.active}
              onMicChange={onAudioInputChange}
              onOutputChange={onAudioOutputChange}
              micDisabled={audio.disabled}
              control={control}
              audioStream={audioStream}
              allowBroadcast
            />
          </TabPanel>
          {VBAvailable && (
            <TabPanel value={activeTab} index={2} id="vb-tabpanel" aria-labelledby="vb-tab">
              <VirtualBackground />
            </TabPanel>
          )}
        </>
      )}
    >
      <Tab label={t('video.tab_label')} value={0} id="video-tab" aria-controls="video-tabpanel" />
      <Tab label={t('audio.tab_label')} value={1} id="audio-tab" aria-controls="audio-tabpanel" />
      {VBAvailable && (
        <Tab label={t('virtual_bg.tab_label')} value={2} id="vb-tab" aria-controls="vb-tabpanel" />
      )}
    </SettingsTabs>
  );
};

export default Settings;
