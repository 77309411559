import { Slide } from 'features/content-library/components/content-types/styled';
import { GridPanelContainer } from 'features/layout/GridPanel/GridPanelContainer';
import { StreamBadge } from 'features/streaming/components/stream-display/StreamBadge';
import { ContentLibraryOpenedFile } from 'features/content-library/types';
import ResponsiveIframe from 'features/content-library/components/ResponsiveIframe';

export type SinglePageFileProps = {
  file: ContentLibraryOpenedFile;
};

const EmbedFile = ({ file }: SinglePageFileProps) => (
  <GridPanelContainer>
    <Slide sx={{ flexGrow: 1 }}>
      <StreamBadge label={file.name} />
      <ResponsiveIframe src={file.url} title={file.name} />
    </Slide>
  </GridPanelContainer>
);

export default EmbedFile;
