import { styled } from '@mui/material/styles';
import { Badge as MuiBadge } from '@mui/material';
import { badgeClasses } from '@mui/material/Badge';

const Badge = styled(MuiBadge, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ theme: { palette, toolbar, spacing }, active }) => {
  const badgeBg = palette.getContrastText(toolbar.background);

  return {
    [`& .${badgeClasses.standard}`]: {
      border: `2px solid ${toolbar.background}`,
      padding: spacing(0, 1),
      fontSize: 10,
      fontWeight: 'bold',
      height: 17,
      lineHeight: '17px',
      minWidth: 24,
      borderRadius: '8px',
      backgroundColor: badgeBg,
      color: palette.getContrastText(badgeBg),
    },
    [`& .${badgeClasses.badge}`]: {
      ...(active && {
        backgroundColor: toolbar.badge.active.background,
        color: palette.common.white,
      }),
    },
  };
});

export default Badge;
