import { selectConsentConfig, selectRoomToken } from 'features/room/roomSlice';
import { RoomToken } from 'features/room/types';
import { useAppSelector } from 'store/hooks';
import { getConsentFlag } from 'utils/consentFlag';

export const useGDPRConfig = () => {
  const consentConfig = useAppSelector(selectConsentConfig);
  const consentFlag = getConsentFlag();

  const consentRequired = consentConfig && !consentFlag;
  const token: RoomToken = useAppSelector(selectRoomToken);

  const tokenUserName = token.parsedData?.u;

  return { consentConfig, consentFlag, consentRequired, tokenUserName };
};
