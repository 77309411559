import { RECORDER_EXTENSION_ID } from 'features/room/constants';

export interface RecorderOptions {
  recordingId: string;
  chunkIntervalMinutes?: number;
}

export const startRecorder = (options: RecorderOptions) => {
  const extensionId = sessionStorage.getItem(RECORDER_EXTENSION_ID);
  if (!extensionId) {
    return undefined;
  }

  return new Promise((resolve) => {
    chrome.runtime.sendMessage(
      extensionId,
      {
        type: 'start',
        ...options,
      },
      (filename: string) => {
        resolve(filename);
      }
    );
  });
};

export const stopRecorder = (options: RecorderOptions) => {
  const extensionId = sessionStorage.getItem(RECORDER_EXTENSION_ID);
  if (!extensionId) {
    return undefined;
  }

  return new Promise((resolve) => {
    chrome.runtime.sendMessage(extensionId, { type: 'stop', ...options }, (data: any) => {
      resolve(data);
    });
  });
};
