import { streamMaximized } from 'features/layout/actions';
import { call, put } from 'redux-saga/effects';
import { SignalingRoomUser } from 'services/signaling';
import { RTCClient } from 'utils/webrtc';

export function* prepareExposedContentSaga(users: SignalingRoomUser[], localUserId: string) {
  // @TODO: in future we'll need to handle other types of content, like whiteboard.

  const usersWithScreenshare = users
    .filter(
      (user) => user.screensharing && !!user.screenshareFeedId && user.screenshareStreams?.length
    )
    // pick up the most recent screenshare
    .sort((userA, userB) => {
      if (userA.screenshareStartedTimestamp && userB.screenshareStartedTimestamp) {
        return userB.screenshareStartedTimestamp - userA.screenshareStartedTimestamp;
      }

      return 0;
    });

  let exposedUser: SignalingRoomUser | undefined = usersWithScreenshare[0];

  const localScreenshare = usersWithScreenshare.find((user) => user.id === localUserId);

  if (localScreenshare) {
    if (usersWithScreenshare.length > 1) {
      // someone else is sharing, stop your own screenshare;
      yield call(RTCClient.screensharingFeed.stopScreenshare);

      exposedUser = usersWithScreenshare.find((user) => user.id !== localUserId);
    }
  }

  // nothing to put on stage;
  if (!exposedUser) {
    return null;
  }

  yield put(
    streamMaximized({
      source: {
        userId: exposedUser.id,
        kind: `screenshare-${exposedUser.id === localUserId ? 'local' : 'remote'}`,
      },
    })
  );

  return exposedUser;
}
