import { call, put } from 'redux-saga/effects';
import { Action } from '@reduxjs/toolkit';
import { closeActionNotification } from 'features/notifications/thunks/closeActionNotification';

export function* closeSharedPollNotificationSaga(id: string) {
  const cleanupNotificationThunk: Action = yield call(
    closeActionNotification,
    `poll_results_shared_${id}`
  );
  yield put(cleanupNotificationThunk);
}
