import { http } from 'utils/http';
import { SignalingSocket, SignalingTokenResponse } from 'services/signaling';

export const exportQuestionsService = async (roomId: string) => {
  const { token } = await SignalingSocket.sendAsync<SignalingTokenResponse>({
    event: 'requestDownloadToken',
    data: {
      type: 'questions',
    },
  });

  return http.get<Blob>(`/room-api/${roomId}/questions/export?format=txt/json`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  });
};
