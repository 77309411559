import { useTranslation } from 'react-i18next';
import ToolbarMenuItem from 'features/toolbar/ToolbarMenuItem/ToolbarMenuItem';
import { useLocalAudio } from 'hooks/useLocalAudio';
import { useTheme } from '@mui/material/styles';

const MicControl = () => {
  const { t } = useTranslation('room');
  const theme = useTheme();

  const { isAudioBroadcasting, toggleMic, isPermissionsGranted, micIcon, isConnectedToFeed } =
    useLocalAudio();

  const renderMicrophoneAriaLabel = () => {
    if (!isConnectedToFeed) {
      return t('toolbar.mic_setup');
    }

    if (!isPermissionsGranted) {
      return t('toolbar.mic_error');
    }

    return isAudioBroadcasting ? t('toolbar.disable_microphone') : t('toolbar.enable_microphone');
  };

  const renderMicOverlay = () => {
    if (!isPermissionsGranted || !isConnectedToFeed) {
      return null;
    }

    return isAudioBroadcasting ? t('toolbar.disable_microphone') : t('toolbar.enable_microphone');
  };

  return (
    <ToolbarMenuItem
      id="toolbar-mic"
      overlay={renderMicOverlay()}
      overlayType={isPermissionsGranted ? 'tooltip' : undefined}
      icon={micIcon}
      disabled={!isConnectedToFeed}
      controlColor={
        isAudioBroadcasting
          ? theme.palette.getContrastText(theme.toolbar.background)
          : theme.palette.error.main
      }
      ariaLabel={renderMicrophoneAriaLabel()}
      onClick={toggleMic}
      error={!isPermissionsGranted}
    />
  );
};

export default MicControl;
