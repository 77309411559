import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from 'components/Dialog';
import Button from '@mui/material/Button';
import { stopRecordingRequest } from 'features/recorder/recorderSlice';
import { PermissionTypes } from 'features/permissions/types';
import { usePermissions } from 'features/permissions/usePermissions';
import { useEffect } from 'react';

const StopRecordingConfirmation = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const { hasPermissions } = usePermissions();

  const canManageRecording = hasPermissions(PermissionTypes.recording);

  useEffect(() => {
    if (!canManageRecording) {
      dispatch(closeModal('stopRecordingConfirmation'));
    }
  }, [canManageRecording, dispatch]);

  const handleOk = () => {
    dispatch(stopRecordingRequest());
    dispatch(closeModal('stopRecordingConfirmation'));
  };

  const handleCancel = () => {
    dispatch(closeModal('stopRecordingConfirmation'));
  };

  return (
    <Dialog
      open
      maxWidth="xs"
      aria-labelledby="stop-recording-title"
      aria-describedby="stop-recording-description"
    >
      <DialogTitle id="stop-recording-title">{t('stop_recording_confirm.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="stop-recording-description">
          {t('stop_recording_confirm.description')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="grayscale">
          {t('stop_recording_confirm.btn_cancel_label')}
        </Button>
        <Button onClick={handleOk}>{t('stop_recording_confirm.btn_ok_label')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default StopRecordingConfirmation;
