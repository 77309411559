import React, { useState } from 'react';
import { Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

const height = 210;
const width = 150;

const ImageWrapper = styled('div')({
  position: 'relative',
  width,
  height,
  overflow: 'hidden',
  borderRadius: '4px',
  borderWidth: '1px',
  borderStyle: 'solid',
});

export const SlideThumbnailImg = styled('img')({
  display: 'block',
  objectFit: 'contain',
  maxWidth: '100%',
  maxHeight: '100%',
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
});

const Thumbnail = ({ src, active }: { src: string; active: boolean }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <ImageWrapper
      sx={(theme) => ({
        borderColor: active ? theme.palette.primary.main : theme.palette.grey['300'],
        outline: active ? `1px solid ${theme.palette.primary.main}` : 'none',
      })}
    >
      {!isLoaded && <Skeleton variant="rectangular" width={width} height={height} />}
      <SlideThumbnailImg
        src={src}
        alt={`slide-${src}`}
        onLoad={() => setIsLoaded(true)}
        width={width}
        loading="lazy"
        height={height}
      />
    </ImageWrapper>
  );
};

export default Thumbnail;
