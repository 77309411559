import { Avatar, AvatarProps } from '@mui/material';
import { UserName } from 'features/users/types';
import React from 'react';
import { getInitials } from 'utils/getInitials';

export type UserAvatarProps = AvatarProps<
  'div',
  {
    name: UserName;
    initials?: string;
    color: string;
  }
>;

const UserAvatar = React.memo(({ initials, name, color, ...props }: UserAvatarProps) => (
  <Avatar style={{ backgroundColor: color }} alt={name || undefined} {...props}>
    {initials || getInitials(name)}
  </Avatar>
));

export default UserAvatar;
