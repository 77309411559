import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { whiteboardClosed, whiteboardOpened } from 'features/layout/features/content/actions';
import { openModal } from 'features/modal/modalSlice';
import { selectWhiteboardOpen } from 'features/whiteboard/whiteboardSlice';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { usePermissions } from 'features/permissions/usePermissions';
import { PermissionTypes } from 'features/permissions/types';
import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';

export const useWhiteboardControl = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('room');

  const whiteboardOpen = useAppSelector(selectWhiteboardOpen);

  const e2eeEnabled = useAppSelector(selectE2eeEnabled);

  const isFeatureEnabled = useFeatureFlag('whiteboard');

  const { hasPermissions } = usePermissions();
  const canEditWhiteboard = hasPermissions(PermissionTypes.editWhiteboard);

  const isFeatureAvailable = isFeatureEnabled && canEditWhiteboard;

  const label = whiteboardOpen ? t('toolbar.disable_whiteboard') : t('toolbar.enable_whiteboard');

  const toggleWhiteboard = () => {
    if (whiteboardOpen) {
      dispatch(whiteboardClosed());
      return;
    }

    if (e2eeEnabled) {
      dispatch(openModal('whiteboardE2eeConfirmation'));
    } else {
      dispatch(whiteboardOpened());
    }
  };

  return {
    active: whiteboardOpen,
    label,
    toggleWhiteboard,
    isFeatureAvailable,
  };
};
