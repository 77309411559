import { call, put } from 'redux-saga/effects';
import { SFrameManager } from 'features/e2ee/SFrameManager';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { sframeInitialized } from 'features/e2ee/actions';
import { AesCm256EncryptionKey } from 'features/e2ee/AesCm256EncryptionKey';

export function* initSframeClientSaga(e2eeId: number) {
  const streamKey: AesCm256EncryptionKey = yield call(E2EEManager.getStreamKey);
  if (!streamKey.key) {
    throw new Error('Cannot initialize SFrame client. Stream key is not defined.');
  }

  yield call(SFrameManager.initialize, e2eeId, streamKey.key);

  yield put(sframeInitialized());
}
