import { useTranslation } from 'react-i18next';
import WaitingScreen from 'components/WaitingScreen/WaitingScreen';
import { Typography } from '@mui/material';

const ErrorBoundaryFallback = () => {
  const { t } = useTranslation();

  return (
    <WaitingScreen show>
      <Typography>{t('runtime_error')}</Typography>
    </WaitingScreen>
  );
};

export default ErrorBoundaryFallback;
