import { splashScreenOpened } from 'features/breakout-rooms/breakoutRoomsSlice';
import { normalizeBreakoutRoomsSaga } from 'features/breakout-rooms/sagas/normalizeBreakoutRoomsSaga';
import { call, put } from 'redux-saga/effects';
import { signalingBreakoutRoomsReceived } from 'features/breakout-rooms/actions';

export function* onBreakoutRoomReceivedSaga(
  action: ReturnType<typeof signalingBreakoutRoomsReceived>
) {
  yield call(normalizeBreakoutRoomsSaga, {
    rooms: action.payload.rooms,
    unassignedUsers: action.payload.unassignedUsers,
  });

  if (action.payload.started) {
    yield put(splashScreenOpened);
  }
}
