import { selectSignalingInitData } from 'features/application/applicationSlice';
import { SignalingInitData } from 'features/application/types';
import { call, select } from 'redux-saga/effects';
import { RTCClient } from 'utils/webrtc';

export function* establishPublishingConnectionSaga() {
  const initData: SignalingInitData = yield select(selectSignalingInitData);

  yield call(RTCClient.mediaServerConnector.connectToPublishingServer, 'publishing', {
    iceServers: initData.iceServers,
    mediaServer: initData.mediaServers.publishing,
  });
}
