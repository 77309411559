import { styled } from '@mui/material/styles';
import { Theme, ToastContainer as ToastifyContainer, ToastContainerProps } from 'react-toastify';
import { styleConstants } from 'theme/styleConstants';
import { ToolbarPlacement } from 'features/toolbar/types';

const { innerPadding, containerPadding } = styleConstants.layout;

const WrappedToastContainer = ({
  className,
  colorTheme,
  ...props
}: ToastContainerProps & { className?: string; colorTheme?: Theme }) => (
  <div className={className}>
    <ToastifyContainer {...props} theme={colorTheme} />
  </div>
);

export const ToastContainer = styled(WrappedToastContainer)<{ placement: ToolbarPlacement }>(({
  theme,
  placement = 'left',
}) => {
  const horizontalOffset = `calc(${theme.toolbar.width} + ${theme.layout.innerPadding})`;

  const toastContainerPadding = innerPadding + containerPadding;

  return {
    '&.action-toast-container': {
      '.Toastify__toast-container': {
        width: `calc(var(--toastify-action-toast-width) + ${toastContainerPadding * 2}px)`,
        position: 'absolute',
        padding: toastContainerPadding,
        zIndex: theme.zIndex.speedDial,
        left: 0,
        top: 0,
        '@media (max-width: 419px)': {
          width: '100%',
        },
        '@media (max-height: 500px)': {
          maxHeight: '55vh',
          overflowY: 'auto',
        },
      },
    },
    '.action-toast': {
      background: 'transparent',
    },
    '&.simple-toast-container': {
      '.Toastify__toast-container': {
        padding: toastContainerPadding,
        '&.Toastify__toast-container--bottom-left': {
          position: 'absolute',
          left: 0,
          bottom: 0,
          paddingBottom: '2px',
          [theme.breakpoints.down(420)]: {
            paddingBottom: theme.layout.innerPadding,
            width: `calc(100vw - ${horizontalOffset} - ${theme.layout.innerPadding})`,
            ...(placement === 'bottom' && {
              width: '100vw',
            }),
          },
        },
        '&.Toastify__toast-container--top-center': {
          top: theme.topBar.height,
        },
      },
    },
  };
});
