import { call, put, select } from 'redux-saga/effects';
import { Action } from '@reduxjs/toolkit';
import { signalingQuestionDeleted } from 'features/qa/actions';
import { selectNotificationsByScope } from 'features/notifications/notificationsSlice';
import { FeatureFlag } from 'features/feature-set/types';
import { NotificationId } from 'features/notifications/types';
import { closeActionNotifications } from 'features/notifications/thunks/closeActionNotifications';

export function* onQuestionDeletedSaga(action: ReturnType<typeof signalingQuestionDeleted>) {
  const notificationIds: NotificationId[] = yield select(
    selectNotificationsByScope,
    FeatureFlag.qa
  );

  const filteredNotificationIds: NotificationId[] = notificationIds.filter((notificationId) => {
    if (typeof notificationId !== 'string') {
      return false;
    }

    return notificationId.includes(action.payload.id);
  });

  if (filteredNotificationIds.length) {
    const cleanupNotificationsThunk: Action = yield call(
      closeActionNotifications,
      filteredNotificationIds
    );
    yield put(cleanupNotificationsThunk);
  }
}
