import { AppThunk } from 'store/store';
import { selectNotificationsByInteraction } from 'features/notifications/notificationsSlice';
import { InteractionKind } from 'features/application/types';
import { closeActionNotifications } from 'features/notifications/thunks/closeActionNotifications';

export const cleanupRelatedInteractionNotifications =
  (interaction: InteractionKind): AppThunk<void> =>
  (dispatch, getState) => {
    const pendingNotifications = selectNotificationsByInteraction(getState(), interaction);

    dispatch(closeActionNotifications(pendingNotifications));
  };
