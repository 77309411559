import React from 'react';
import { styled, SxProps } from '@mui/material/styles';

const Root = styled('span')(({ theme }) => ({
  display: 'inline-flex',
  flexFlow: 'row wrap',
  placeContent: 'center',
  alignItems: 'center',
  fontWeight: 500,
  minWidth: '26px',
  lineHeight: 1,
  padding: theme.spacing(0, 2),
  height: '20px',
  borderRadius: '20px',
  fontSize: 16,
  zIndex: 1,
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.getContrastText(theme.palette.primary.light),
}));

type StandaloneBadgeProps = React.ComponentPropsWithoutRef<'span'> & {
  badgeContent: string | number;
  showZero?: boolean;
  sx?: SxProps;
};

const StandaloneBadge = ({ badgeContent, showZero = false, ...props }: StandaloneBadgeProps) => {
  if (!badgeContent && !showZero) {
    return null;
  }

  return <Root {...props}>{badgeContent}</Root>;
};

export default StandaloneBadge;
