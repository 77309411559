import { useRemoteAudio } from 'features/streaming/useRemoteAudio';
import { UserId } from 'features/users/types';
import { useAppSelector } from 'store/hooks';
import { selectRemoteStreams } from 'features/streaming/streamingSlice';
import { FeedId } from 'features/streaming/types';

interface RemoteAudioProps {
  userId: UserId;
  feedId?: FeedId;
}

export const RemoteAudio = ({ userId, feedId }: RemoteAudioProps) => {
  const streams = useAppSelector((state) => selectRemoteStreams(state, userId, feedId));
  const audioStream = streams?.find((stream) => stream.kind === 'audio');

  useRemoteAudio(audioStream?.mid);

  return null;
};
