import { call, select } from 'redux-saga/effects';
import { downloadFile } from 'utils/http/downloadFile';
import { AxiosResponse } from 'axios';
import { selectRoomId } from 'features/room/roomSlice';
import { exportSinglePollService } from 'features/polls/services';
import { exportSinglePollRequested } from 'features/polls/actions';
import * as Sentry from '@sentry/react';

export function* exportSinglePollSaga(action: ReturnType<typeof exportSinglePollRequested>) {
  try {
    const roomId: string = yield select(selectRoomId);

    const response: AxiosResponse<Blob> = yield call(
      exportSinglePollService,
      roomId,
      action.payload
    );
    const contentDisposition = response.headers['content-disposition'];

    yield call(downloadFile, response.data, contentDisposition);
  } catch (error) {
    Sentry.captureException(error);
  }
}
