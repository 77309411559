import React from 'react';
import { ListProps } from 'react-virtuoso';
import { StyledParticipantsList } from 'components/participants-list/styled';

const VirtualListRoot = React.forwardRef<HTMLDivElement, ListProps>(
  ({ style, children }, listRef) => (
    <StyledParticipantsList style={style} component="div" ref={listRef}>
      {children}
    </StyledParticipantsList>
  )
);

export default VirtualListRoot;
