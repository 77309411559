import React, { useEffect, useId } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal, selectActiveModalData } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import { useBreakoutRooms } from 'features/breakout-rooms/useBreakoutRooms';
import { renameRoomRequested } from 'features/breakout-rooms/actions';
import { selectBreakoutRoom } from 'features/breakout-rooms/breakoutRoomsSlice';
import { Dialog, DialogActions, DialogContent, DialogTitle } from 'components/Dialog';
import { Box, Button, FormControl, FormHelperText } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Input from 'components/Input';

export interface RenameBreakoutRoomsValues {
  roomName: string;
}

const RenameBreakoutRoomModal = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const modalId = useId();

  const modalData = useAppSelector(selectActiveModalData);
  const roomId = modalData.roomId as string;

  const room = useAppSelector((state) => selectBreakoutRoom(state, roomId));

  const { isBreakoutsAvailable, status } = useBreakoutRooms();

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm<RenameBreakoutRoomsValues>({
    mode: 'onChange',
    defaultValues: {
      roomName: room?.name || '',
    },
  });

  const shouldClose = !isBreakoutsAvailable || !room || status === 'started';

  useEffect(() => {
    if (shouldClose) {
      dispatch(closeModal('renameBreakoutRoom'));
    }
  }, [shouldClose, dispatch]);

  const handleCancel = () => {
    dispatch(closeModal('renameBreakoutRoom'));
  };

  const handleRename: SubmitHandler<RenameBreakoutRoomsValues> = (data) => {
    dispatch(
      renameRoomRequested({
        id: roomId,
        name: data.roomName.trim(),
      })
    );
    dispatch(closeModal('renameBreakoutRoom'));
  };

  return (
    <Dialog open={!!room} maxWidth="xs" aria-labelledby={`${modalId}-title`} onClose={handleCancel}>
      <DialogTitle id={`${modalId}-title`}>
        {t('breakout_rooms.rename_breakout_room.title')}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            pt: 1,
          }}
        >
          <FormControl fullWidth error={!!errors.roomName}>
            <Controller
              name="roomName"
              control={control}
              rules={{
                required: t('common:validation.field_required'),
                validate: (value) => !!value.trim() || t('common:validation.field_required'),
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  autoFocus
                  inputProps={{
                    'aria-label': t('breakout_rooms.rename_breakout_room.input_name_placeholder'),
                  }}
                  placeholder={t('breakout_rooms.rename_breakout_room.input_name_placeholder')}
                  autoComplete="off"
                />
              )}
            />
            <FormHelperText>{errors.roomName?.message}</FormHelperText>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button type="button" color="grayscale" onClick={handleCancel}>
          {t('breakout_rooms.rename_breakout_room.btn_cancel_label')}
        </Button>
        <Button type="button" onClick={handleSubmit(handleRename)} disabled={!isValid}>
          {t('breakout_rooms.rename_breakout_room.btn_ok_label')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RenameBreakoutRoomModal;
