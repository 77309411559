import { createSlice, PayloadAction, prepareAutoBatched } from '@reduxjs/toolkit';
import { LayoutOrderState } from 'features/layout/features/order/types';
import { SourceDetails } from 'features/layout/types';
import { sourceMatches } from 'features/streaming/utils';
import { RootState } from 'store/store';

export const initialState: LayoutOrderState = {
  orderedStreams: [],
  historicalSpeakers: [],
};

const layoutOrderSlice = createSlice({
  name: 'layoutOrder',
  initialState,
  reducers: {
    orderUpdated: {
      reducer(state, action: PayloadAction<SourceDetails[]>) {
        state.orderedStreams = action.payload;
      },
      prepare: prepareAutoBatched<SourceDetails[]>(),
    },
    orderedStreamAdded(state, action: PayloadAction<SourceDetails>) {
      state.orderedStreams.push(action.payload);
    },
    orderedStreamDeleted(state, action: PayloadAction<SourceDetails>) {
      state.orderedStreams = state.orderedStreams.filter(
        (stream) => !sourceMatches(stream, action.payload)
      );
    },
    speakersUpdated: {
      reducer(state, action: PayloadAction<SourceDetails[]>) {
        state.historicalSpeakers = action.payload;
      },
      prepare: prepareAutoBatched<SourceDetails[]>(),
    },
  },
});

export const { orderUpdated, orderedStreamAdded, orderedStreamDeleted, speakersUpdated } =
  layoutOrderSlice.actions;

export const selectOrderedStreams = (state: RootState) => state.layout.order.orderedStreams;
export const selectHistoricalSpeakers = (state: RootState) => state.layout.order.historicalSpeakers;

export const selectActiveSpeaker = (state: RootState) =>
  state.layout.order.orderedStreams.find(
    (source) => source.userId === state.streaming.activeSpeakerId
  );

export const selectActiveSpeakerId = (state: RootState) => state.streaming.activeSpeakerId;

export default layoutOrderSlice.reducer;
