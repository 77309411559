import { Radio as MuiRadio, RadioProps } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

const StyledRadio = styled(MuiRadio)({
  padding: '11px',
});

const Icon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 18,
  height: 18,
  boxShadow:
    theme.palette.mode === 'dark'
      ? `0 0 0 1px ${alpha(theme.palette.common.white, 0.35)}`
      : `inset 0 0 0 1px ${theme.palette.divider}, inset 0 -1px 0 ${theme.palette.common.white}`,
  '.Mui-focusVisible &': {
    outline: `2px auto ${alpha(theme.palette.primary.main, 0.35)}`,
    outlineOffset: 2,
  },
  'input:disabled ~ &': {
    boxShadow:
      theme.palette.mode === 'dark'
        ? `0 0 0 1px ${theme.palette.divider}`
        : `inset 0 0 0 1px ${theme.palette.text.disabled}, inset 0 -1px 0 ${theme.palette.common.white}`,
  },
}));

const CheckedIcon = styled(Icon)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  boxShadow:
    theme.palette.mode === 'dark'
      ? `0 0 0 1px ${theme.palette.primary.main}`
      : `inset 0 0 0 1px ${theme.palette.primary.main}, inset 0 -1px 0 ${theme.palette.common.white}`,
  '&:before': {
    display: 'block',
    width: 18,
    height: 18,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
}));

const Radio = (props: RadioProps) => (
  <StyledRadio
    disableRipple
    color="default"
    checkedIcon={<CheckedIcon />}
    icon={<Icon />}
    {...props}
  />
);

export default Radio;
