import React, { useMemo } from 'react';
import { VirtualListItem } from 'components/SectionedVirtualList';
import { Components, Virtuoso, VirtuosoProps } from 'react-virtuoso';
import { styleConstants } from 'theme/styleConstants';
import { BreakoutsUsersVirtualListItemType } from 'features/breakout-rooms/types';
import { styled } from '@mui/material/styles';
import { useAppSelector } from 'store/hooks';
import BreakoutsUsersListItem from 'features/breakout-rooms/components/breakouts-users-selector/BreakoutsUsersListItem';
import {
  SelectedUserIds,
  ToggleUserHandler,
} from 'features/breakout-rooms/modals/AddUsersToRoomModal';
import { selectBreakoutsUnassignedUsers } from 'features/breakout-rooms/selectors/selectBreakoutsUnassignedUsers';
import BreakoutsUsersListItemContent from 'features/breakout-rooms/components/breakouts-users-selector/BreakoutsUsersListItemContent';
import VirtualListRoot from 'components/participants-list/VirtualListRoot';

const {
  participantsPanel: { listItemHeight },
} = styleConstants;

export type BreakoutsUsersListProps = {
  searchQuery: string;
  toggleUser: ToggleUserHandler;
  selectedIds: SelectedUserIds;
  participantsLimit: number;
};

export interface BreakoutsUsersListContext {
  toggleUser: ToggleUserHandler;
  participantsLimit: number;
  selectedIds: SelectedUserIds;
}

const MUIComponents: Components<BreakoutsUsersVirtualListItemType, BreakoutsUsersListContext> = {
  List: VirtualListRoot,
  Item: BreakoutsUsersListItem,
};

const renderItemContent = (
  index: number,
  item: BreakoutsUsersVirtualListItemType,
  context: BreakoutsUsersListContext
) => {
  if (!item.user) {
    return null;
  }

  return <BreakoutsUsersListItemContent item={item} context={context} />;
};

const StyledVirtuoso = styled(
  (props: VirtuosoProps<VirtualListItem, BreakoutsUsersListContext>) => (
    <Virtuoso {...props} style={{ height: listItemHeight * 4 }} />
  )
)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '4px',
}));

const BreakoutsUsersList = React.memo(
  ({ searchQuery, toggleUser, selectedIds, participantsLimit }: BreakoutsUsersListProps) => {
    const rooms = useAppSelector((state) => selectBreakoutsUnassignedUsers(state, searchQuery));

    const context: BreakoutsUsersListContext = useMemo(
      () => ({
        toggleUser,
        participantsLimit,
        selectedIds,
      }),
      [participantsLimit, toggleUser, selectedIds]
    );

    return (
      <StyledVirtuoso
        data={rooms}
        components={MUIComponents}
        itemContent={renderItemContent}
        context={context}
        fixedItemHeight={listItemHeight}
      />
    );
  }
);

export default BreakoutsUsersList;
