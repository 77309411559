import { openModal } from 'features/modal/modalSlice';
import { selectRequireRemoveUserConfirmation } from 'features/room/roomSlice';
import { kickUser, kickUserRequested } from 'features/users/actions';
import { put, select } from 'redux-saga/effects';

export function* onKickUserRequestedSaga(action: ReturnType<typeof kickUserRequested>) {
  const userId = action.payload;

  const requireConfirmation: boolean = yield select(selectRequireRemoveUserConfirmation);

  if (requireConfirmation) {
    yield put(
      openModal('kickUserConfirmation', {
        userId,
      })
    );
  } else {
    yield put(kickUser(userId));
  }
}
