import { selectFeatureFlag } from 'features/feature-set/featuresSlice';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { recordingToggled } from 'features/recorder/actions';
import { call, put, select } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';

export function* onToggleRecordingCommandSaga(newState?: boolean) {
  const controlEnabled: boolean = yield select(selectFeatureFlag, 'recordings');
  const canToggleRecording: boolean = yield call(hasPermissionsSaga, PermissionTypes.recording);

  if (!controlEnabled) {
    yield call(
      eventBus.notAllowedError,
      'Recording disabled. You’ll need to edit this room’s properties to record sessions in this room',
      'recording'
    );

    return;
  }

  if (!canToggleRecording) {
    yield call(
      eventBus.forbiddenActionError,
      'Forbidden action. This participant is not allowed to control recording',
      'recording'
    );

    return;
  }

  yield put(recordingToggled(newState));
}
