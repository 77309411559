import { ToolbarPlacement } from 'features/toolbar/types';

export const getPanelHeight = (toolbarPlacement: ToolbarPlacement, isMobile?: boolean) => {
  if (isMobile) {
    if (toolbarPlacement === 'bottom') {
      return `calc(100% - var(--toolbar-width))`;
    }

    return '100%';
  }

  if (toolbarPlacement === 'bottom') {
    return `calc(100% - var(--toolbar-width) - var(--topbar-height))`;
  }

  return `calc(100% - var(--topbar-height))`;
};
