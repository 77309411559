import { User } from '@sentry/react';
import { allowBroadcast, disallowBroadcast } from 'features/permissions/actions';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { UserId } from 'features/users/types';
import { selectUserById } from 'features/users/usersSlice';
import { call, put, select } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';

export function* onManageBroadcastCommandSaga(userId: UserId, allow: boolean) {
  const targetUser: User = yield select(selectUserById, userId);

  if (!targetUser) {
    return;
  }

  const canManageBroadcast: boolean = yield call(
    hasPermissionsSaga,
    PermissionTypes.manageBroadcast,
    {
      targetRole: targetUser.role,
    }
  );

  if (!canManageBroadcast) {
    yield call(
      eventBus.forbiddenActionError,
      'Forbidden action. This participant is not allowed to manage broadcast',
      'manage-broadcast'
    );

    return;
  }

  const targetRoleCanBroadcast: boolean = yield call(
    hasPermissionsSaga,
    PermissionTypes.broadcast,
    {
      role: targetUser.role,
    }
  );

  if (targetRoleCanBroadcast) {
    // if target users has static permissions granted by role
    // we can't change them

    yield call(
      eventBus.forbiddenActionError,
      'Forbidden action. This participant is not allowed to manage target participant’s broadcast',
      'manage-broadcast'
    );
    return;
  }

  if (allow) {
    yield put(allowBroadcast(userId));
  } else {
    yield put(disallowBroadcast(userId));
  }
}
