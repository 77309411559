import { AppThunk } from 'store/store';
import { NotificationId } from 'features/notifications/types';
import { closeActionNotification } from 'features/notifications/thunks/closeActionNotification';

export const closeActionNotifications =
  (ids: NotificationId[]): AppThunk<void> =>
  (dispatch) => {
    for (const id of ids) {
      dispatch(closeActionNotification(id));
    }
  };
