import { UserEntries } from 'features/users/types';

// targetId can be either a user ID or an external ID of a user
export const findChatTargetUser = (users: UserEntries, targetId: string) => {
  const user = users[targetId];
  if (user) {
    return user;
  }

  const userByExternalId = Object.values(users).find((u) => u.externalId === targetId);
  if (userByExternalId) {
    return userByExternalId;
  }

  return null;
};
