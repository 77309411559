import { ActiveMediaDevices, MediaDeviceEntities } from 'features/user-media/types';
import { getRelatedDevice } from 'features/user-media/utils/getRelatedDevice';

export const normalizeActiveDevices = (
  mediaDevices: MediaDeviceEntities,
  activeDevices: ActiveMediaDevices
) => {
  const normalizedDevices: Partial<Record<MediaDeviceKind, string>> = {};

  for (const [kind, deviceId] of Object.entries(activeDevices)) {
    const device = mediaDevices.find((d) => d.deviceId === deviceId && d.kind === kind);

    if (device) {
      normalizedDevices[kind as MediaDeviceKind] = getRelatedDevice(mediaDevices, device).deviceId;
    }
  }

  return normalizedDevices;
};
