import React from 'react';
import { useAppSelector } from 'store/hooks';
import { Box } from '@mui/material';
import {
  selectToolbarPanel,
  selectToolbarPlacement,
  selectToolbarVisibility,
  selectToolbarWidth,
} from 'features/toolbar/toolbarSlice';
import { animated, config as springConfig, useSpring } from '@react-spring/web';
import { styled } from '@mui/material/styles';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { ToolbarPlacement } from 'features/toolbar/types';
import Toolbar from 'features/toolbar/Toolbar/Toolbar';
import { RecorderGuard } from 'features/recorder/RecorderGuard';
import { styleConstants } from 'theme/styleConstants';

const toolbarPanelWidth = styleConstants.toolbarPanel.width;

const toolbarPaddingSide: Record<ToolbarPlacement, string> = {
  left: 'paddingLeft',
  right: 'paddingRight',
  bottom: 'paddingBottom',
};

const getAnimatedProperty = (
  toolbarWidth: number,
  placement: ToolbarPlacement,
  direction: 'to' | 'from'
) => {
  const config: Record<ToolbarPlacement, Record<'from' | 'to', React.CSSProperties>> = {
    left: {
      to: {
        paddingLeft: `${toolbarWidth + toolbarPanelWidth}px`,
      },
      from: {
        paddingLeft: `${toolbarWidth}px`,
      },
    },
    right: {
      to: {
        paddingRight: `${toolbarWidth + toolbarPanelWidth}px`,
      },
      from: {
        paddingRight: `${toolbarWidth}px`,
      },
    },
    bottom: {
      to: {
        paddingLeft: `${toolbarPanelWidth}px`,
      },
      from: {
        paddingLeft: '0px',
      },
    },
  };

  return config[placement][direction];
};

const Root = styled('div')({
  width: '100%',
  overflow: 'hidden',
  position: 'relative',
  height: `calc(var(--app-height) - var(--topbar-height))`,
});

const Content = styled(animated(Box), {
  shouldForwardProp: (prop) => prop !== 'toolbarPlacement',
})<{ toolbarPlacement: ToolbarPlacement }>(({ toolbarPlacement, theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  [toolbarPaddingSide[toolbarPlacement]]: theme.toolbar.width,
}));

const RoomLayoutWrapper = ({ children }: { children: React.ReactNode }) => {
  const activePanel = useAppSelector(selectToolbarPanel);

  const toolbarPlacement = useAppSelector(selectToolbarPlacement);
  const toolbarWidth = useAppSelector(selectToolbarWidth);
  const toolbarVisible = useAppSelector(selectToolbarVisibility);

  const { isMobile } = useBreakpoints();

  const styles = useSpring({
    to:
      activePanel !== null
        ? getAnimatedProperty(toolbarWidth, toolbarPlacement, 'to')
        : getAnimatedProperty(toolbarWidth, toolbarPlacement, 'from'),
    from: getAnimatedProperty(toolbarWidth, toolbarPlacement, 'from'),
    config: { ...springConfig.stiff, clamp: true },
  });

  return (
    <Root>
      {toolbarVisible && (
        <RecorderGuard>
          <Toolbar />
        </RecorderGuard>
      )}
      <Content style={isMobile ? undefined : styles} toolbarPlacement={toolbarPlacement}>
        {children}
      </Content>
    </Root>
  );
};

export default RoomLayoutWrapper;
