import { alpha, styled } from '@mui/material/styles';
import { InputBase } from '@mui/material';

const Input = styled(InputBase)(({ theme }) => ({
  lineHeight: '1.375em',
  transition: theme.transitions.create(['border-color', 'box-shadow']),
  borderRadius: 4,
  'label + &': {
    marginTop: theme.spacing(7),
  },
  '&.Mui-disabled': {
    '& .MuiInputAdornment-root': {
      color: theme.palette.text.disabled,
    },
  },
  '&.Mui-error': {
    '& .MuiInputBase-input:not(.MuiInputBase-inputMultiline)': {
      borderColor: theme.palette.error.main,
      '&:focus': {
        boxShadow: `${alpha(theme.palette.error.main, 0.35)} 0 0 0 2px`,
        borderColor: theme.palette.error.main,
      },
    },
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    border: `1px solid ${theme.palette.divider}`,
    borderColor: theme.palette.divider,
    fontSize: '1rem',
    height: '1.375em',
    lineHeight: theme.input.lineHeight,
    padding: '7px 12px',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.35)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiSelect-select:focus': {},
  '& .MuiInputBase-inputAdornedStart.MuiInputBase-input.MuiSelect-select:focus': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderColor: theme.palette.primary.main,
  },
  '&.Mui-focused .MuiInputBase-inputAdornedStart.MuiInputBase-input.MuiSelect-select': {
    borderColor: theme.palette.primary.main,
  },
  '& .MuiInputAdornment-root': {
    transition: theme.transitions.create(['border-color']),
    color: theme.palette.text.primary,
    maxHeight: 'none',
    height: 'auto',
    marginRight: 0,
    padding: theme.spacing(1, 0, 1, 1),
    fontSize: 28,
    border: `1px solid ${theme.palette.divider}`,
    borderRightWidth: 0,
    '&.MuiInputAdornment-positionStart': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
  },
  '& .MuiInputBase-inputAdornedStart': {
    paddingLeft: 6,
    borderLeftWidth: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    '&:focus': {
      boxShadow: 'none',
      borderColor: theme.palette.primary.main,
    },
  },
  '&.Mui-focused .MuiInputAdornment-root': {
    borderColor: theme.palette.primary.main,
  },
}));

export default Input;
