import { styled } from '@mui/material/styles';
import { ComponentPropsWithoutRef } from 'react';

const IframeContainer = styled('div')({
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden',

  '& iframe': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    height: '100%',
    transform: 'translate(-50%, -50%)',
    border: 0,
  },
});

export type ResponsiveIframeProps = ComponentPropsWithoutRef<'iframe'>;

const ResponsiveIframe = ({
  title,
  referrerPolicy = 'no-referrer',
  // sandbox = 'allow-scripts allow-same-origin',
  ...props
}: ResponsiveIframeProps) => (
  <IframeContainer>
    <iframe
      title={title}
      referrerPolicy={referrerPolicy}
      // sandbox={sandbox}
      {...props}
    />
  </IframeContainer>
);

export default ResponsiveIframe;
