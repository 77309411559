import { BreakoutRoomEntries } from 'features/breakout-rooms/types';

export const searchRooms = (roomEntries: BreakoutRoomEntries, searchQuery: string) => {
  if (!searchQuery) {
    return roomEntries;
  }

  const lowerCaseSearchQuery = searchQuery.toLowerCase();
  const result: BreakoutRoomEntries = {};

  for (const room of Object.values(roomEntries)) {
    if (room.name?.toLowerCase().includes(lowerCaseSearchQuery)) {
      result[room.id] = room;
    }
  }

  return result;
};
