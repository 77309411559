import { call } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { unassignUserFromBreakoutRoomRequested } from 'features/breakout-rooms/actions';

export function* unassignUsersFromBreakoutRoomSaga(
  action: ReturnType<typeof unassignUserFromBreakoutRoomRequested>
) {
  yield call(SignalingSocket.send, {
    event: 'unassignUserFromBreakoutRoom',
    data: {
      id: action.payload.id,
      userId: action.payload.userId,
    },
  });
}
