import { PayloadAction } from '@reduxjs/toolkit';
import { layoutModeChanged } from 'features/layout/features/config/configSlice';
import { PrejoinResponse } from 'features/room/types';
import { toolbarVisibilityChanged } from 'features/toolbar/toolbarSlice';
import { put } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';

export function* prepareLayoutDefaultsSaga(action: PayloadAction<PrejoinResponse>) {
  if (eventBus.initState.showToolbar !== undefined) {
    yield put(toolbarVisibilityChanged(eventBus.initState.showToolbar));
  } else {
    yield put(toolbarVisibilityChanged(action.payload.settings.toolbarEnabled));
  }

  if (eventBus.initState.layoutMode !== undefined) {
    yield put(layoutModeChanged(eventBus.initState.layoutMode));
  } else {
    yield put(layoutModeChanged(action.payload.settings.layoutModeOnJoin));
  }
}
