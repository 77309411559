import Janus from 'lib/janus';
import { Rid, RidStats } from 'utils/webrtc/types';
import { ControlledPublishingHandle } from 'utils/webrtc/publishing/ControlledPublishingHandle';
import { toFixedNumber } from 'utils/math';

// TODO track stats for tile
// video
// - bitrate
// - fps
// - frame width / height
// - codec??
// audio
// - bitrate
// - fps

interface InitOptions {
  feed: ControlledPublishingHandle;
}

const deltaT = 1;

export class PublishingRTCStats {
  outboundReportByRid: Record<string, Rid> = {};

  MOS: number = 0;

  totalPacketsSent: number = 0;

  totalPacketLoss: number = 0;

  totalPacketsLost: number = 0;

  totalJitter: number = 0;

  totalRTT: number = 0;

  layers: Record<string, RidStats> = {};

  feed?: ControlledPublishingHandle;

  constructor(options: InitOptions) {
    this.feed = options.feed;
    // this.feedId = options.feed.feedId;
  }

  private calculateVideoOutboundStats = (report: any) => {
    // eslint-disable-next-line prefer-destructuring
    const rid: Rid = report.rid;
    this.outboundReportByRid[report.id] = rid;

    if (!this.layers[rid]) {
      this.layers[rid] = {
        rid,
        lastResult: {},
        packetsSent: 0,
        rtt: 0,
        packetLoss: 0,
        packetsLost: 0,
        jitter: 0,
      };
    }

    const layer = this.layers[rid];
    const { packetsSent } = report;
    if (layer.lastResult.packetsSent === undefined) {
      layer.lastResult.packetsSent = packetsSent;
    } else {
      layer.packetsSent = Math.round((packetsSent - layer.lastResult.packetsSent) / deltaT);

      this.totalPacketsSent += layer.packetsSent;

      layer.lastResult.packetsSent = packetsSent;
    }
  };

  private calculateVideoRemoteInboundStats = (report: any) => {
    const rid = this.outboundReportByRid[report.localId];

    if (!rid) {
      return;
    }

    const { packetsLost, roundTripTime, jitter } = report;
    const layer = this.layers[rid];

    if (packetsLost !== undefined) {
      if (layer.lastResult.packetsLost === undefined) {
        layer.lastResult.packetsLost = packetsLost;
      } else {
        layer.packetsLost = Math.round((packetsLost - layer.lastResult.packetsLost) / deltaT);
        this.totalPacketsLost += layer.packetsLost;

        if (layer.packetsSent !== 0) {
          layer.packetLoss = Math.round((layer.packetsLost / layer.packetsSent) * 100);
        } else {
          layer.packetLoss = 0;
        }

        layer.lastResult.packetsLost = packetsLost;
      }
    }

    layer.rtt = Math.round(roundTripTime * 1000);
    layer.jitter = toFixedNumber(jitter * 1000, 2);

    this.totalRTT += layer.rtt;
    this.totalJitter += layer.jitter;
  };

  private calculateMOS = () => {
    // define the actual active layers count
    let activeLayers = 0;
    let totalRTT = 0;
    let totalJitter = 0;

    Object.values(this.layers).forEach((layer) => {
      if (layer.packetsSent) {
        activeLayers += 1;
        totalRTT += layer.rtt;
        totalJitter += layer.jitter;
      }
    });

    if (activeLayers === 0) {
      return;
    }

    const averageLatency = totalRTT / activeLayers;

    const averageJitter = totalJitter / activeLayers;
    // Take the average latency, add jitter, but double the impact to latency
    // then add 10 for protocol latencies
    const effectiveLatency = averageLatency + averageJitter * 2 + 10;

    // We start out with an R-value of 93.2, and deduct from there, based on network conditions.
    let R = 93.2;

    // Implement a basic curve - deduct 4 for the R value at 160ms of latency
    // (round trip). Anything over that gets a much more agressive deduction
    if (effectiveLatency < 160) {
      R -= effectiveLatency / 40;
    } else {
      R -= (effectiveLatency - 120) / 10;
    }

    // Now, let's deduct 2.5 R values per percentage of packet loss
    R -= this.totalPacketLoss * 2.5;

    // prevent negatives
    R = Math.max(R, 0);

    // Convert the R into an MOS value.(this is a known formula)
    this.MOS = 1 + 0.035 * R + 0.000007 * R * (R - 60) * (100 - R);
  };

  getStats = async () => {
    if (!this.feed) {
      Janus.warn('Invalid handle');
      return null;
    }

    const config = this.feed.videoroom.webrtcStuff;
    if (!config.pc) {
      Janus.warn('Invalid PeerConnection');
      return null;
    }

    if (!Janus.unifiedPlan) {
      Janus.warn('Trying to get stats from non-unified plan. Skip');
      return null;
    }

    const sender = config.pc.getSenders().find((s) => s.track?.kind === 'video');

    if (!sender) {
      Janus.warn(`RTC Stats: No sender found`);
      return null;
    }

    this.totalPacketsLost = 0;
    this.totalPacketsSent = 0;
    this.totalJitter = 0;
    this.totalRTT = 0;

    const stats = await sender.getStats();

    stats.forEach((report) => {
      if (
        report.mediaType === 'video' ||
        (report.type === 'outbound-rtp' && !report.id.includes('rtcp'))
      ) {
        this.calculateVideoOutboundStats(report);
      }
      if (
        report.mediaType === 'video' ||
        (report.type === 'remote-inbound-rtp' && !report.id.includes('rtcp'))
      ) {
        this.calculateVideoRemoteInboundStats(report);
      }
    });

    if (this.totalPacketsSent !== 0) {
      this.totalPacketLoss = Math.min(
        Math.round((this.totalPacketsLost / this.totalPacketsSent) * 100),
        100
      );
    } else {
      this.totalPacketLoss = 0;
    }

    this.calculateMOS();

    const layers: Record<string, Omit<RidStats, 'lastResult'>> = {};
    for (const layer of Object.values(this.layers)) {
      const { lastResult, ...rest } = layer;
      layers[layer.rid] = rest;
    }

    return {
      layers,
      totalPacketLoss: this.totalPacketLoss,
      totalPacketsLost: this.totalPacketsLost,
      totalPacketsSent: this.totalPacketsSent,
      totalJitter: Math.round(this.totalJitter),
      totalRTT: this.totalRTT,
      MOS: this.MOS,
    };
  };
}
