import { call, put } from 'redux-saga/effects';
import { Action } from '@reduxjs/toolkit';
import {
  signalingQuestionLiveAnswerCancelled,
  signalingQuestionLiveAnswerStopped,
} from 'features/qa/actions';
import { closeActionNotification } from 'features/notifications/thunks/closeActionNotification';

export function* onLiveAnswerStoppedSaga(
  action: ReturnType<
    typeof signalingQuestionLiveAnswerStopped | typeof signalingQuestionLiveAnswerCancelled
  >
) {
  const cleanupNotificationThunk: Action = yield call(
    closeActionNotification,
    `${action.payload.questionId}_${action.payload.id}`
  );
  yield put(cleanupNotificationThunk);
}
