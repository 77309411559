import { call, put, select } from 'redux-saga/effects';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import i18n from 'i18n';
import { Action } from '@reduxjs/toolkit';
import { USER_LEFT_NOTIFICATION_ID } from 'features/notifications/constants';
import { displaySimpleNotification } from 'features/notifications/simple-notifications/displaySimpleNotification';
import {
  selectLeftUsersCount,
  selectSimpleNotificationById,
} from 'features/notifications/notificationsSlice';
import { updateSimpleNotification } from 'features/notifications/simple-notifications/updateSimpleNotification';
import { SimpleNotification } from 'features/notifications/types';
import * as Sentry from '@sentry/react';
import { logger } from 'utils/logger';
import { userLeftNotificationTriggered } from 'features/notifications/actions';
import { selectUserById } from 'features/users/usersSlice';
import { User } from 'features/users/types';

export function* handleLeaveNotificationSaga(
  action: ReturnType<typeof userLeftNotificationTriggered>
) {
  try {
    const canReceiveNotification: boolean = yield call(
      hasPermissionsSaga,
      PermissionTypes.seeParticipantsPanel
    );

    if (!canReceiveNotification) {
      return;
    }

    const activeNotification: SimpleNotification = yield select(
      selectSimpleNotificationById,
      USER_LEFT_NOTIFICATION_ID
    );

    if (!activeNotification) {
      if (Array.isArray(action.payload)) {
        return;
      }

      // show the simple notification with the username only for the first time
      const user: User = yield select(selectUserById, action.payload.id);
      if (!user?.name) {
        return;
      }

      const notificationThunk: Action = yield call(
        displaySimpleNotification,
        {
          content: i18n.t('notifications:user_left.content_one', { username: user.name }),
        },
        {
          name: 'user_left',
          toastId: USER_LEFT_NOTIFICATION_ID,
        }
      );
      yield put(notificationThunk);

      return;
    }

    const leftUsersCount: number = yield select(selectLeftUsersCount);

    // group incoming notifications
    const notificationThunk: Action = yield call(
      updateSimpleNotification,
      USER_LEFT_NOTIFICATION_ID,
      {
        content: i18n.t('notifications:user_left.content', {
          count: leftUsersCount,
        }),
      }
    );
    yield put(notificationThunk);
  } catch (error) {
    Sentry.captureException(error);
    logger.remote({ tier: 1 }).error('Cannot handle user leave notification', error);
  }
}
