import { closeModal } from 'features/modal/modalSlice';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'components/Dialog';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import { useId } from 'react';
import { clearAllActionNotifications } from 'features/notifications/actions';

const ClearActionNotificationsConfirmation = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const prefix = useId();

  const handleOk = () => {
    dispatch(clearAllActionNotifications());
    dispatch(closeModal('clearActionNotificationsConfirmation'));
  };

  const handleCancel = () => {
    dispatch(closeModal('clearActionNotificationsConfirmation'));
  };

  return (
    <Dialog
      open
      maxWidth="xs"
      aria-labelledby={`${prefix}-title`}
      aria-describedby={`${prefix}-description`}
      onClose={handleCancel}
    >
      <DialogTitle id={`${prefix}-title`} onClose={handleCancel}>
        {t('clear_action_notifications.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id={`${prefix}-description`}>
          {t('clear_action_notifications.description')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="grayscale">
          {t('clear_action_notifications.btn_cancel_label')}
        </Button>
        <Button onClick={handleOk}>{t('clear_action_notifications.btn_ok_label')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClearActionNotificationsConfirmation;
