import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import { bulkMuteRemoteAudio } from 'features/streaming/actions';
import { usePermissions } from 'features/permissions/usePermissions';
import { useEffect } from 'react';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { PermissionTypes } from 'features/permissions/types';

const BulkMuteRemoteAudioConfirmation = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const { hasPermissions } = usePermissions();

  const canMuteAllRoles = hasPermissions(PermissionTypes.remoteMuting, {
    targetRole: {
      coverage: 'all',
    },
  });

  useEffect(() => {
    if (!canMuteAllRoles) {
      dispatch(closeModal('bulkMuteRemoteAudioConfirmation'));
    }
  }, [canMuteAllRoles, dispatch]);

  const handleCancel = () => {
    dispatch(closeModal('bulkMuteRemoteAudioConfirmation'));
  };

  const handleOk = () => {
    dispatch(bulkMuteRemoteAudio());
    dispatch(closeModal('bulkMuteRemoteAudioConfirmation'));
  };

  return (
    <ConfirmationDialog
      open
      title={t('bulk_mute_remote_audio_confirm.title')}
      description={t('bulk_mute_remote_audio_confirm.description')}
      cancelText={t('bulk_mute_remote_audio_confirm.btn_cancel_label')}
      okText={t('bulk_mute_remote_audio_confirm.btn_ok_label')}
      onOk={handleOk}
      onCancel={handleCancel}
      hideCloseButton
      preventClose
    />
  );
};

export default BulkMuteRemoteAudioConfirmation;
