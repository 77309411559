import { decode } from 'features/e2ee/utils/decode';

export const decrypt = async (data: BufferSource, key: CryptoKey, iv: BufferSource) => {
  const encoded = await window.crypto.subtle.decrypt(
    {
      name: 'AES-GCM',
      iv,
    },
    key,
    data
  );

  return decode(encoded);
};
