import { selectMaximizedStream } from 'features/layout/features/content/contentSlice';
import { orderUpdated } from 'features/layout/features/order/orderSlice';
import { findContentForStageSaga } from 'features/layout/sagas/findContentForStageSaga';
import { call, put, select } from 'redux-saga/effects';
import { RTCClient } from 'utils/webrtc';
import { PayloadAction } from '@reduxjs/toolkit';
import { selectTargetTilesCount } from 'features/dev-mode/devModeSlice';
import { addDebugTiles } from 'utils/layout';
import { SourceDetails } from '../types';

export function* onOrderChangedSaga(action: PayloadAction<SourceDetails[]>) {
  const targetTilesCount: number = yield select(selectTargetTilesCount);

  const newOrder: SourceDetails[] = yield call(addDebugTiles, action.payload, targetTilesCount);

  yield put(orderUpdated(newOrder));

  yield call(RTCClient.receivingFeed.updateSimulcastConfig);

  const maximizedStream: SourceDetails = yield select(selectMaximizedStream);

  if (!maximizedStream) {
    yield call(findContentForStageSaga);
  }
}
