import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BrandingState } from 'features/branding/types';
import { BrandingOptions } from 'features/room/types';
import { normalizeBrandingOptions } from 'features/branding/normalizeBrandingOptions';
import { RootState } from 'store/store';

export const initialState: BrandingState = {
  components: {},
  palette: {},
};

export const brandingSlice = createSlice({
  name: 'branding',
  initialState,
  reducers: {
    brandingReceived(state, action: PayloadAction<BrandingOptions>) {
      const options = normalizeBrandingOptions(action.payload);

      state.components = options.components;
      state.palette = options.palette;
      state.applicationLogoUrl = action.payload.applicationLogoUrl;
    },
  },
});

export const { brandingReceived } = brandingSlice.actions;

export const selectBrandingOptions = (state: RootState) => state.branding;

export const selectApplicationLogo = (state: RootState) => state.branding.applicationLogoUrl;

export default brandingSlice.reducer;
