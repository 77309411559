import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from 'components/ConfirmationDialog';
import Button from '@mui/material/Button';
import React, { useEffect } from 'react';
import { usePermissions } from 'features/permissions/usePermissions';
import { PermissionTypes } from 'features/permissions/types';

const RecordingFreeTotalLimitModal = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const { hasPermissions } = usePermissions();

  const canManageRecording = hasPermissions(PermissionTypes.recording);

  useEffect(() => {
    if (!canManageRecording) {
      dispatch(closeModal('recordingFreePlanTotalLimit'));
    }
  }, [canManageRecording, dispatch]);

  const handleOk = () => {
    dispatch(closeModal('recordingFreePlanTotalLimit'));
  };

  const handleCancel = () => {
    dispatch(closeModal('recordingFreePlanTotalLimit'));
  };

  return (
    <ConfirmationDialog
      open
      maxWidth="sm"
      title={t('recording_free_plan_total_limit.title')}
      description={t('recording_free_plan_total_limit.description')}
      cancelText={t('recording_free_plan_total_limit.btn_cancel_label')}
      onOk={handleOk}
      onCancel={handleCancel}
      okButton={
        <Button href="https://dashboard.digitalsamba.com" target="_blank" rel="noopener noreferrer">
          {t('recording_free_plan_total_limit.btn_ok_label')}
        </Button>
      }
      preventClose
      hideCloseButton
    />
  );
};

export default RecordingFreeTotalLimitModal;
