export function loadInContextEditor() {
  const isLoaded = document.getElementById('crowdin-jipt');
  if (isLoaded) {
    return;
  }

  const inlineScript = document.createElement('script');
  inlineScript.innerHTML = `var _jipt = []; _jipt.push(["project", "${process.env.REACT_APP_CROWDIN_PROJECT}"]);`;
  document.body.appendChild(inlineScript);

  const jipt = document.createElement('script');
  jipt.src = '//cdn.crowdin.com/jipt/jipt.js';
  jipt.id = 'crowdin-jipt';
  jipt.async = true;
  document.body.appendChild(jipt);
}
