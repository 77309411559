import { call, select } from 'redux-saga/effects';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { User } from 'features/users/types';
import { SignalingSocket } from 'services/signaling';
import { e2eeEncrypt } from 'features/e2ee/utils/e2eeEncrypt';
import { selectLocalUser } from 'features/users/usersSlice';
import { AbstractE2eeUser } from 'features/e2ee/types';

export function* exchangeEncryptedNameSaga(user: AbstractE2eeUser) {
  const sharedSecret: CryptoKey = yield call(
    E2EEManager.exchangeEncryptionKey,
    user.id,
    user.e2eePublicKey
  );

  const localUser: User = yield select(selectLocalUser);
  if (!localUser.name) {
    throw new Error('Cannot encrypt a name. The name is undefined.');
  }

  const encryptedUsername: string = yield call(e2eeEncrypt, localUser.name, sharedSecret);

  yield call(SignalingSocket.send, {
    event: 'shareEncryptedName',
    data: {
      name: encryptedUsername,
      id: user.id,
    },
  });
}
