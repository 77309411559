import { useAppSelector } from 'store/hooks';
import { selectIsBreakoutRoom } from 'features/breakout-rooms/breakoutRoomsSlice';
import { usePermissions } from 'features/permissions/usePermissions';
import {
  selectTranscriptionEnabled,
  selectTranscriptionStarted,
} from 'features/transcripts/slices/transcription/transcriptionSlice';
import { PermissionTypes } from 'features/permissions/types';

export const useTranscription = () => {
  const isBreakoutRoom = useAppSelector(selectIsBreakoutRoom);
  const { hasPermissions } = usePermissions();

  const transcriptionEnabled = useAppSelector(selectTranscriptionEnabled);
  const transcriptionStarted = useAppSelector(selectTranscriptionStarted);
  const canTranscribeSession = hasPermissions(PermissionTypes.transcribeSession);
  const transcriptionAvailable = !isBreakoutRoom && transcriptionEnabled;

  return {
    transcriptionAvailable,
    transcriptionStarted,
    canTranscribeSession,
  };
};
