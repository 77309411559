import { MenuItem } from '@mui/material';
import ToolbarMenuItem from 'features/toolbar/ToolbarMenuItem/ToolbarMenuItem';
import { useTranslation } from 'react-i18next';
import { useTranscriptionControl } from 'features/toolbar/Toolbar/controls/transcription/useTranscriptionControl';
import React from 'react';
import { ToolbarMenuItemWrapperProps } from 'features/toolbar/types';

const TranscriptionControl = ({ onClick }: ToolbarMenuItemWrapperProps) => {
  const { t } = useTranslation('room');

  const {
    stopTranscription,
    label,
    isFeatureAvailable,
    transcriptionStarted,
    toggleCaptions,
    captionsDisplayed,
    captionsAvailable,
    canTranscribeSession,
    icon,
    active,
    toggleControl,
  } = useTranscriptionControl();

  if (!isFeatureAvailable) {
    return null;
  }

  if (!canTranscribeSession && !captionsAvailable) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(event);
    toggleControl();
  };

  if (canTranscribeSession && transcriptionStarted) {
    return (
      <ToolbarMenuItem
        menu
        active={active}
        ariaLabel={label}
        icon={icon}
        MenuProps={{
          PopperProps: {
            sx: {
              zIndex: (theme) => theme.zIndex.tooltip,
            },
          },
        }}
        id="transcription"
      >
        <MenuItem onClick={stopTranscription}>
          {t('toolbar.transcription.menu_options.stop_transcription')}
        </MenuItem>
        {captionsAvailable && (
          <MenuItem onClick={toggleCaptions}>
            {captionsDisplayed
              ? t('toolbar.transcription.menu_options.hide_captions')
              : t('toolbar.transcription.menu_options.show_captions')}
          </MenuItem>
        )}
      </ToolbarMenuItem>
    );
  }

  return (
    <ToolbarMenuItem
      active={active}
      overlay={label}
      ariaLabel={label}
      icon={icon}
      id="transcription"
      onClick={handleClick}
    />
  );
};

export default TranscriptionControl;
