import { useTranslation } from 'react-i18next';
import MobileMenuItem from 'features/toolbar/Toolbar/mobile/MobileMenuItem';
import { useSettingsControl } from 'features/toolbar/Toolbar/controls/settings/useSettingsControl';
import { MobileMenuItemWrapperProps } from 'features/toolbar/types';
import React from 'react';

const SettingsControlMobile = ({ onClick }: MobileMenuItemWrapperProps) => {
  const { t } = useTranslation('room');

  const { openSettings, icon } = useSettingsControl();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    onClick?.(event);
    openSettings();
  };

  return (
    <MobileMenuItem icon={icon} onClick={handleClick}>
      {t('toolbar.settings')}
    </MobileMenuItem>
  );
};

export default SettingsControlMobile;
