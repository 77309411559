import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { muteAudioSaga } from 'features/streaming/sagas/muteAudioSaga';
import { muteVideoSaga } from 'features/streaming/sagas/muteVideoSaga';
import { toggleLocalAudioSaga } from 'features/streaming/sagas/toggleLocalAudioSaga';
import { toggleLocalVideoSaga } from 'features/streaming/sagas/toggleLocalVideoSaga';
import { unmuteAudioSaga } from 'features/streaming/sagas/unmuteAudioSaga';
import { unmuteVideoSaga } from 'features/streaming/sagas/unmuteVideoSaga';
import { call } from 'redux-saga/effects';
import { BroadcastMessageTypes, eventBus } from 'utils/eventBus';

export function* onControlBroadcastCommandSaga(command: BroadcastMessageTypes) {
  const canBroadcast: boolean = yield call(hasPermissionsSaga, PermissionTypes.broadcast);

  if (!canBroadcast) {
    yield call(
      eventBus.forbiddenActionError,
      'Forbidden action. This participant is not allowed to broadcast',
      'broadcast'
    );

    return;
  }

  switch (command) {
    case 'enableVideo':
      yield call(unmuteVideoSaga);
      break;
    case 'enableAudio':
      yield call(unmuteAudioSaga);
      break;
    case 'disableVideo':
      yield call(muteVideoSaga);
      break;
    case 'disableAudio':
      yield call(muteAudioSaga);
      break;
    case 'toggleVideo':
      yield call(toggleLocalVideoSaga);
      break;
    case 'toggleAudio':
      yield call(toggleLocalAudioSaga);
      break;

    default:
      break;
  }
}
