import { v4 as uuidv4 } from 'uuid';

export const BROWSER_ID = 'browserId';

export function getBrowserId() {
  let browserId;

  try {
    browserId = localStorage.getItem(BROWSER_ID);
    if (!browserId) {
      browserId = uuidv4();
      localStorage.setItem(BROWSER_ID, browserId);
    }
  } catch (err) {
    browserId = uuidv4();
  }

  return browserId;
}
