import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from 'components/Dialog';
import { Button } from '@mui/material';
import { endSession } from 'features/room/actions';
import { PermissionTypes } from 'features/permissions/types';
import { usePermissions } from 'features/permissions/usePermissions';
import { useEffect } from 'react';

const EndSessionConfirmation = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const { hasPermissions } = usePermissions();

  const canEndSession = hasPermissions(PermissionTypes.endSession);

  useEffect(() => {
    if (!canEndSession) {
      dispatch(closeModal('endSessionConfirm'));
    }
  }, [canEndSession, dispatch]);

  const handleOk = () => {
    dispatch(endSession());
    dispatch(closeModal('endSessionConfirm'));
  };

  const handleCancel = () => {
    dispatch(closeModal('endSessionConfirm'));
  };

  return (
    <Dialog
      open
      maxWidth="xs"
      aria-labelledby="end-session-confirm-title"
      aria-describedby="end-session-confirm-description"
    >
      <DialogTitle id="end-session-confirm-title">{t('end_session_confirm.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="end-session-confirm-description">
          {t('end_session_confirm.description')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="grayscale" autoFocus>
          {t('end_session_confirm.btn_cancel_label')}
        </Button>
        <Button onClick={handleOk} color="error">
          {t('end_session_confirm.btn_ok_label')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EndSessionConfirmation;
