import { getContrastRatio, styled } from '@mui/material/styles';

const LogoWrapper = styled('div')(({ theme }) => {
  const color =
    getContrastRatio(theme.palette.common.white, theme.room.background) >
    theme.palette.contrastThreshold
      ? theme.palette.common.white
      : '#323F66';

  return {
    color,
    svg: {
      verticalAlign: 'middle',
    },
    img: {
      verticalAlign: 'middle',
      maxWidth: '200px',
      maxHeight: '40px',
      objectFit: 'contain',
    },
  };
});

export default LogoWrapper;
