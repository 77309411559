import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'features/modal/modalSlice';
import { Trans, useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, DialogContent } from 'components/Dialog';
import { DialogContentText } from '@mui/material';
import getBrowserPermissionsDocLink from 'utils/getBrowserPermissionsDocLink';

const BlockedDevicesModal = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const hideModal = () => dispatch(closeModal('mediaDevicesBlocked'));

  return (
    <Dialog open aria-labelledby="devices-error-dialog" onClose={hideModal} maxWidth="xs">
      <DialogTitle id="devices-error-dialog" onClose={hideModal}>
        {t('devices_blocked.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Trans
            i18nKey="modals:devices_blocked.description"
            t={t}
            components={[
              // eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content
              <a href={getBrowserPermissionsDocLink()} target="_blank" rel="noopener noreferrer" />,
            ]}
          />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default BlockedDevicesModal;
