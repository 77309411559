import { FeedId } from 'features/streaming/types';
import { UserId } from 'features/users/types';

export enum AppLayout {
  tiled = 'tiled',
  tiled_content = 'tiled_content',
}

export enum LayoutMode {
  tiled = 'tiled',
  auto = 'auto',
}

export interface LayoutPagination {
  page: number;
  maxTiles: number;
  numPages: number;
  totalStreams: number;
  streams: SourceDetails[];
}

export interface StreamMaximizedPayload {
  source: SourceDetails;
}

export interface PinControlToggledPayload {
  source: SourceDetails;
  controlActive: boolean;
}

export type SourceKind = 'remote' | 'screenshare-local' | 'screenshare-remote' | 'local' | 'debug';

export interface SourceDetails {
  userId: UserId;
  feedId?: FeedId;
  kind: SourceKind;
}

export interface ControlVisibility {
  pin: boolean;
  fullscreen: boolean;
  minimizeLocal: boolean;
}
