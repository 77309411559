import { styled } from '@mui/material/styles';
import { animated } from '@react-spring/web';
import { Box } from '@mui/material';
import { ToolbarPlacement } from 'features/toolbar/types';
import { getPanelHeight } from 'features/toolbar/ToolbarPanel/utils';

export const Root = styled(animated(Box), {
  shouldForwardProp: (prop) => prop !== 'isMobile' && prop !== 'open',
})<{ isMobile?: boolean; open?: boolean }>(({ theme, isMobile, open }) => ({
  ...(isMobile &&
    open && {
      position: 'fixed',
      inset: 0,
      zIndex: theme.zIndex.drawer,
    }),
}));

export const PanelWrapper = styled(animated(Box), {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<{ isMobile?: boolean; placement: ToolbarPlacement }>(({ theme, isMobile, placement }) => ({
  position: 'fixed',
  top: 0,
  bottom: 0,
  height: getPanelHeight(placement, isMobile),
  width: '100%',
  maxWidth: theme.toolbarPanel.width,
  padding: '8px',
  paddingRight: 0,
  marginTop: theme.topBar.height,
  willChange: 'transform',
  ...(placement === 'left' && {
    marginLeft: theme.toolbar.width,
    left: 0,
  }),
  ...(placement === 'right' && {
    marginRight: theme.toolbar.width,
    right: 0,
    paddingRight: '8px',
    paddingLeft: 0,
  }),
  ...(isMobile && {
    width: '85%',
    margin: 0,
    zIndex: theme.zIndex.drawer,
  }),
}));

export const Panel = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})(({ theme }) => ({
  height: '100%',
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '8px',
  overflow: 'hidden',
}));

export const ScrollableContent = styled('div')({
  overflowY: 'auto',
  flex: 1,
});

export const ToolbarPanelScrollRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'hidden',
});

export const ToolbarPanelRoot = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const SearchWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 3, 4),
}));

export const AddButtonWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
}));
