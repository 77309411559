import { placementChanged } from 'features/toolbar/toolbarSlice';
import { ToolbarPlacement } from 'features/toolbar/types';
import { call, put } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';

export function* onChangeToolbarPositionCommandSaga(placement: ToolbarPlacement) {
  if (['left', 'right', 'bottom'].includes(placement)) {
    yield put(placementChanged(placement));
  } else {
    yield call(eventBus.error, {
      name: 'layout',
      message: 'Invalid toolbar position requested',
    });
  }
}
