import { Box, Avatar as MuiAvatar, AvatarProps } from '@mui/material';
import { selectAvatarSize } from 'features/layout/features/dimensions/dimensionsSlice';
import { AvatarSize } from 'features/layout/features/dimensions/types';
import { useAppSelector } from 'store/hooks';

const scalingFactor = 0.3;

const sizes: Record<AvatarSize, number> = {
  xxs: 30,
  xs: 60,
  sm: 90,
  md: 120,
  lg: 150,
  xl: 190,
};

type CustomAvatarProps = AvatarProps & {
  size?: AvatarSize;
};

const Avatar = ({ children, sx = [], size, ...props }: CustomAvatarProps) => {
  const avatarSizeStr = useAppSelector(selectAvatarSize);
  const avatarSize = sizes[size || avatarSizeStr];
  const avatarFontSize = avatarSize * scalingFactor;

  return (
    <Box
      sx={{
        maxWidth: 120,
        maxHeight: 120,
        height: avatarSize,
        width: avatarSize,
        transition: (theme) => theme.transitions.create('all'),
      }}
    >
      <MuiAvatar
        {...props}
        style={{
          transition: 'all .2s ease-in',
          fontSize: `clamp(.75rem, ${avatarFontSize}px, 4rem)`,
        }}
        sx={[
          {
            width: '100%',
            height: '100%',
            flexShrink: 1,
            transition: (theme) => theme.transitions.create('fontSize'),
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {children}
      </MuiAvatar>
    </Box>
  );
};
export default Avatar;
