import { Dialog, List, ListItemButton, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEndSessionControl } from 'features/toolbar/Toolbar/controls/end-call/useEndSessionControl';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
}

const EndCallDialog = ({ onClose, open }: SimpleDialogProps) => {
  const { t } = useTranslation('room');

  const { endSession, leaveSession, leaveBreakout, leaveBreakoutAvailable, endSessionAvailable } =
    useEndSessionControl();

  const handleEndSession = () => {
    onClose();
    endSession();
  };

  const handleLeaveBreakout = () => {
    onClose();
    leaveBreakout();
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <List>
        {leaveBreakoutAvailable ? (
          <ListItemButton autoFocus onClick={handleLeaveBreakout}>
            <ListItemText primary={t('toolbar.end_session_options.leave_breakout')} />
          </ListItemButton>
        ) : null}
        <ListItemButton autoFocus onClick={leaveSession}>
          <ListItemText primary={t('toolbar.end_session_options.leave_session')} />
        </ListItemButton>
        {endSessionAvailable ? (
          <ListItemButton
            onClick={handleEndSession}
            sx={{
              color: 'error.main',
            }}
          >
            <ListItemText primary={t('toolbar.end_session_options.close_session')} />
          </ListItemButton>
        ) : null}
      </List>
    </Dialog>
  );
};

export default EndCallDialog;
