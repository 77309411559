import { PayloadAction } from '@reduxjs/toolkit';
import { RTCEventPayload } from 'features/streaming/types';
import { call } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { VideoroomConnectionState } from 'utils/webrtc/ControlledReceivingHandle';
import { RTCClient } from 'utils/webrtc';
import { logger } from 'utils/logger';
import * as Sentry from '@sentry/react';

export function* onVideoroomErrorSaga(
  action: PayloadAction<RTCEventPayload<{ error_code: number; error: string }>>
) {
  const {
    message: { error_code: errorCode, error },
    handle,
  } = action.payload;
  Sentry.captureException(new Error(`[${errorCode}]: ${error}`));

  const feed = RTCClient.receivingFeed.receiver?.plugin;

  switch (errorCode) {
    case 428: {
      yield call(
        logger.remote().error,
        `Couldn't subscribe in feed ${handle}, maybe there's no such publisher? Original: ${error}`
      );

      if (feed?.connectionState === VideoroomConnectionState.connecting) {
        yield call(feed.setConnectionState, VideoroomConnectionState.created);
      }

      if (action.payload.eventBody?.transaction && feed) {
        yield call(
          RTCClient.receivingFeed.receiver!.retrySubscription,
          feed.handle,
          action.payload.eventBody.transaction,
          error
        );
      }

      break;
    }
    case 424: {
      yield call(
        logger.remote().error,
        `Error in ${handle}, receiving room is unconfigured? Original: ${error}`
      );

      if (feed?.connectionState === VideoroomConnectionState.connecting) {
        yield call(feed.setConnectionState, VideoroomConnectionState.created);
      }

      break;
    }

    case 433: {
      yield call(
        logger.remote().error,
        `Error in feed ${handle}. Wrong room pin. Original: ${error}`
      );
      break;
    }

    case 426: {
      yield call(SignalingSocket.send, {
        event: 'roomNotFound',
        data: { type: 'videoroom', id: RTCClient.roomId },
      });

      break;
    }

    default: {
      yield call(logger.remote().error, `Error in feed ${handle}. Original: ${error}`);
    }
  }
}
