import React from 'react';
import { Box, Typography } from '@mui/material';

type StatsItemProps = {
  label: string;
  value: string | number;
};

const StatsItem = ({ value, label }: StatsItemProps) => (
  <Box>
    <Typography
      sx={{
        fontWeight: 700,
        marginRight: 2,
      }}
      component="span"
    >
      {label}:
    </Typography>
    <span>{value}</span>
  </Box>
);

export default StatsItem;
