import Tooltip from 'components/Tooltip';
import React, { forwardRef } from 'react';
import Icon from 'components/Icon';
import { alpha, darken, styled } from '@mui/material/styles';
import { IconButton, IconButtonProps } from '@mui/material';

const Button = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'disableInteraction',
})<{ disableInteraction?: boolean }>(({ theme, disableInteraction }) => ({
  backgroundColor: alpha(theme.palette.common.black, 0.7),
  color: theme.palette.common.white,
  transition: theme.transitions.create('color'),
  fontSize: 24,
  width: 32,
  height: 32,
  '&:focus-visible': {
    outline: 'none',
    color: darken(theme.palette.common.white, 0.4),
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.7),
  },
  ...(disableInteraction && {
    pointerEvents: 'none',
    cursor: 'default',
  }),
}));

export type StreamControlButtonProps = IconButtonProps<
  'button',
  {
    icon: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement | HTMLLIElement, MouseEvent>) => void;
    disableInteraction?: boolean;
    text?: string;
  }
>;

export const StreamControlButton = forwardRef<HTMLButtonElement, StreamControlButtonProps>(
  ({ icon, text, ...props }, ref) => (
    <Tooltip title={text} placement="top">
      <Button {...props} ref={ref}>
        <Icon name={icon} />
      </Button>
    </Tooltip>
  )
);
