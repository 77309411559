import { PayloadAction } from '@reduxjs/toolkit';
import { streamMaximized, streamMinimized } from 'features/layout/actions';
import { selectOrderedStreams } from 'features/layout/features/order/orderSlice';
import { findContentForStageSaga } from 'features/layout/sagas/findContentForStageSaga';
import { LayoutMode, SourceDetails } from 'features/layout/types';
import { call, put, select } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';

export function* onLayoutModeChangedSaga(action: PayloadAction<LayoutMode>) {
  yield call(eventBus.sendMessage, 'layoutModeChanged', {}, { mode: action.payload });

  if (action.payload === LayoutMode.auto) {
    // pick up some stream to maximize;
    yield call(findContentForStageSaga, true);
  } else {
    const orderedStreams: SourceDetails[] = yield select(selectOrderedStreams);

    // TODO: we'll revise this with updted tile controls behavior
    const screenshareStream = orderedStreams.find(
      (stream) => stream.kind === 'screenshare-remote' || stream.kind === 'screenshare-local'
    );

    if (screenshareStream) {
      yield put(streamMaximized({ source: screenshareStream }));
    } else {
      yield put(streamMinimized());
    }
  }
}
