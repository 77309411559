import { Video } from 'features/streaming/components/media/Video';
import { selectPreserveAspectRatio } from 'features/streaming/streamingSlice';
import { useEffect, useRef } from 'react';
import { BoxProps } from '@mui/material';
import { useAppSelector } from 'store/hooks';

type LocalVideoProps = BoxProps<
  'video',
  {
    mirror?: boolean;
    srcObject?: MediaStream | null;
  }
>;

function LocalVideo({ srcObject = null, mirror }: LocalVideoProps) {
  const ref = useRef<HTMLVideoElement | null>(null);
  const preserveAspectRatio = useAppSelector(selectPreserveAspectRatio);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    ref.current.srcObject = srcObject;
  }, [srcObject]);

  return (
    <Video
      muted
      autoPlay
      ref={ref}
      mirror={mirror}
      playsInline
      className={`fitment-${preserveAspectRatio ? 'fit' : 'crop'}`}
    />
  );
}

export default LocalVideo;
