import React from 'react';
import { useRecorder } from 'features/recorder/useRecorder';

interface RecorderGuardProps {
  show?: boolean;
  children: React.ReactElement;
}

export const RecorderGuard = React.memo(({ show, children }: RecorderGuardProps) => {
  const isRecorder = useRecorder();

  // eslint-disable-next-line no-nested-ternary
  return isRecorder ? (show ? children : null) : children;
});
