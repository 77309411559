import createCache from '@emotion/cache';
import { getPiPAvailable } from 'components/PiP/PiPProvider';

const getSpeedyModeFlag = () => {
  const pipAvailable = getPiPAvailable();

  return !pipAvailable;
};

export const emotionCache = createCache({
  key: 'emotion-cache-no-speedy',
  speedy: getSpeedyModeFlag(),
});
