import office from 'assets/images/vb/office.jpg';
import officeThumb from 'assets/images/vb/thumb_office.jpg';
import beach from 'assets/images/vb/beach.jpg';
import beachThumb from 'assets/images/vb/thumb_beach.jpg';
import bookshelf from 'assets/images/vb/bookshelf.jpg';
import bookshelfThumb from 'assets/images/vb/thumb_bookshelf.jpg';
import fireworks from 'assets/images/vb/fireworks.jpg';
import fireworksThumb from 'assets/images/vb/thumb_fireworks.jpg';
import forest from 'assets/images/vb/forest.jpg';
import forestThumb from 'assets/images/vb/thumb_forest.jpg';
import mountain from 'assets/images/vb/mountain.jpg';
import mountainThumb from 'assets/images/vb/thumb_mountain.jpg';
import office2 from 'assets/images/vb/office2.jpg';
import office2Thumb from 'assets/images/vb/thumb_office2.jpg';
import savana from 'assets/images/vb/savana.jpg';
import savanaThumb from 'assets/images/vb/thumb_savana.jpg';

export const backgroundsList: Record<string, { src: string; thumb: string; alt: string }> = {
  office: {
    src: office,
    thumb: officeThumb,
    alt: 'Office',
  },
  office2: {
    src: office2,
    thumb: office2Thumb,
    alt: 'Office 2',
  },
  beach: {
    src: beach,
    thumb: beachThumb,
    alt: 'Palm beach',
  },
  fireworks: {
    src: fireworks,
    thumb: fireworksThumb,
    alt: 'Fireworks',
  },
  bookshelf: {
    src: bookshelf,
    thumb: bookshelfThumb,
    alt: 'Bookshelf',
  },
  forest: {
    src: forest,
    thumb: forestThumb,
    alt: 'Forest',
  },
  mountain: {
    src: mountain,
    thumb: mountainThumb,
    alt: 'Mountain',
  },
  savannah: {
    src: savana,
    thumb: savanaThumb,
    alt: 'Savana',
  },
};

export const backgrounds = Object.values(backgroundsList);
