import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridPanelContainer = styled(Box)<{ dragHandlePadding?: boolean }>(
  ({ theme, dragHandlePadding }) => ({
    background: theme.palette.grey['50'],
    position: 'absolute',
    width: `calc(100% - ${dragHandlePadding ? '18px' : '8px'})`,
    height: 'calc(100% - 8px)',
    borderRadius: '8px',
    overflow: 'hidden',
    display: 'flex',
  })
);
