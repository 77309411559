import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { styled } from '@mui/material/styles';
import { FormControl, IconButton } from '@mui/material';
import { Controller } from 'react-hook-form';
import Input from 'components/Input';
import Icon from 'components/Icon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Control } from 'react-hook-form/dist/types/form';
import { PollFormValues } from 'features/polls/components/PollForm';
import NoStylesButton from 'components/NoStylesButton';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  marginBottom: theme.spacing(6),
  '& .MuiInputBase-root .MuiInputBase-input': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));

type SortableItemProps = {
  id: string;
  errors: any;
  control: Control<PollFormValues>;
  index: number;
  deleteChoice: (index: number) => void;
};

const DragHandle = styled(NoStylesButton, {
  shouldForwardProp: (prop) => prop !== 'isDragging',
})<{ isDragging: boolean }>(({ theme, isDragging }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  touchAction: 'manipulation',
  flexShrink: 0,
  width: '38px',
  cursor: 'grab',
  border: `1px solid ${theme.palette.divider}`,
  borderTopLeftRadius: '4px',
  borderBottomLeftRadius: '4px',
  borderRightWidth: 0,
  ...(isDragging && {
    backgroundColor: theme.palette.action.selected,
  }),
}));

export function DynamicChoice({ id, index, control, errors, deleteChoice }: SortableItemProps) {
  const { t } = useTranslation(['modals', 'polls']);

  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Root
      ref={setNodeRef}
      style={style}
      sx={{
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <DragHandle {...listeners} {...attributes} ref={setActivatorNodeRef} isDragging={isDragging}>
        <Icon
          name="drag"
          sx={{
            fontSize: '32px',
          }}
        />
      </DragHandle>
      <FormControl fullWidth error={!!errors?.choices?.[index]?.text}>
        <Controller
          name={`choices.${index}.text`}
          control={control}
          rules={{
            required: true,
            maxLength: 128,
          }}
          render={({ field: { ref, ...field } }) => <Input {...field} inputRef={ref} />}
        />
      </FormControl>
      <IconButton
        sx={{
          ml: 1,
          p: '2px 3px',
        }}
        onClick={() => deleteChoice(index)}
        aria-label={t('polls.poll_form.choices.btn_delete_choice')}
      >
        <Icon
          name="close"
          sx={{
            fontSize: '32px',
          }}
        />
      </IconButton>
    </Root>
  );
}
