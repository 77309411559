import { put, select } from 'redux-saga/effects';
import { UserId } from 'features/users/types';
import { SourceDetails } from 'features/layout/types';
import {
  selectHistoricalSpeakers,
  selectOrderedStreams,
  speakersUpdated,
} from 'features/layout/features/order/orderSlice';
import { orderChanged } from 'features/layout/actions';

export function* updateOrderSaga(userId: UserId) {
  const streams: SourceDetails[] = yield select(selectOrderedStreams);
  const speakers: SourceDetails[] = yield select(selectHistoricalSpeakers);

  const streamExists = streams.find((stream) => stream.userId === userId);
  const speakerExists = speakers.find((stream) => stream.userId === userId);

  if (!streamExists) {
    yield put(orderChanged([...streams, { userId, kind: 'remote' }]));
  }

  if (!speakerExists) {
    yield put(speakersUpdated([...speakers, { userId, kind: 'remote' }]));
  }
}
