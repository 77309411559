import {
  BreakoutRoomWithUserEntries,
  BreakoutRoomWithUserIds,
} from 'features/breakout-rooms/types';
import { UserEntries } from 'features/users/types';

export function mapUserEntriesToRoom(
  rooms: BreakoutRoomWithUserIds[],
  allUserEntries: UserEntries
): BreakoutRoomWithUserEntries[] {
  const roomsWithUserEntries: BreakoutRoomWithUserEntries[] = [];

  for (const room of rooms) {
    const roomWithEntries: BreakoutRoomWithUserEntries = {
      id: room.id,
      name: room.name,
      users: [],
    };

    for (const userId of room.users) {
      const user = allUserEntries[userId];

      if (user) {
        roomWithEntries.users.push(user);
      }
    }
    roomsWithUserEntries.push(roomWithEntries);
  }
  return roomsWithUserEntries;
}
