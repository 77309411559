import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SDKState } from 'features/sdk/types';
import { RootState } from 'store/store';
import { SDKInitState } from 'utils/eventBus';

const initialState: SDKState = {
  // poor mans default, gets overriden with better value upon loading
  isEmbed:
    (window !== window.parent && window.location.search.includes('dsEmbedFrame')) ||
    window.location.search.includes('dsEmbedFrameNative'),
  connected: false,
  initState: {},
};

export const SDKStateSlice = createSlice({
  name: 'streaming',
  initialState,
  reducers: {
    SDKInitialized(state, action: PayloadAction<boolean>) {
      state.isEmbed = action.payload;
    },
    SDKConnected(state, action: PayloadAction<Partial<SDKInitState>>) {
      state.connected = true;
      state.initState = action.payload;
    },
  },
});

export const { SDKInitialized, SDKConnected } = SDKStateSlice.actions;

export const selectIsSDKConnected = (state: RootState) => state.SDKState.connected;
export const selectSDKInitState = (state: RootState) => state.SDKState.initState;

export default SDKStateSlice.reducer;
