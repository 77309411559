import { call, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { PermissionChangePayload, User, UserId } from 'features/users/types';
import { notification } from 'features/notifications/toast/notification';
import i18n from 'i18n';
import { selectLocalUserId, selectUserById } from 'features/users/usersSlice';
import { logger } from 'utils/logger';
import { broadcastPermissionRevoked } from 'features/permissions/actions';

export function* onBroadcastDisallowedSaga({ payload }: PayloadAction<PermissionChangePayload>) {
  const { initiator, id } = payload;

  const localUserId: UserId = yield select(selectLocalUserId);

  if (localUserId === id && initiator) {
    logger
      .remote({ tier: 1 })
      .info(`The user was disallowed to broadcast by user id=${initiator.id}`);

    const initiatorUser: User = yield select(selectUserById, initiator.id);

    if (initiatorUser.name) {
      yield call(
        notification,
        i18n.t('notifications:broadcast_disallowed', { username: initiatorUser.name })
      );
    } else {
      yield call(notification, i18n.t('notifications:broadcast_disallowed_unnamed'));
    }
  }

  yield put(broadcastPermissionRevoked(payload));
}
