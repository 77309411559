import { call, select } from 'redux-saga/effects';
import { e2eeDecrypt } from 'features/e2ee/utils/e2eeDecrypt';
import { E2eeReceiver, maxRatchetAttempts } from 'features/e2ee/E2eeReceiver';
import { ChatMessage } from 'features/chat/types';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { canExposeInternals } from 'utils/development';
import * as Sentry from '@sentry/react';
import { logger } from 'utils/logger';
import { AesCm256EncryptionKey } from 'features/e2ee/AesCm256EncryptionKey';
import { User } from 'features/users/types';
import { selectActiveUserById, selectLocalUser } from 'features/users/usersSlice';

export function* decryptE2eeMessageSaga(payload: ChatMessage) {
  const receiver: E2eeReceiver | undefined = yield call(E2EEManager.getReceiver, payload.user.id);

  //  for the local user, we use its original key
  if (payload.local) {
    const applicationKey: AesCm256EncryptionKey = yield call(E2EEManager.getApplicationKey);
    let decryptionKey = applicationKey.encryptionKey!;

    // @FIXME remove test code
    if (canExposeInternals() && window.e2eeAppSharedKey) {
      decryptionKey = window.e2eeAppSharedKey;
    }

    try {
      const message: string = yield call(e2eeDecrypt, payload.message, decryptionKey);
      const localUser: User = yield select(selectLocalUser);

      const result = {
        ...payload,
        message,
        user: {
          ...payload.user,
          name: localUser.name,
        },
      };

      if (result.targetUser) {
        const targetUser: User = yield select(selectActiveUserById, result.targetUser.id);
        if (targetUser) {
          result.targetUser.name = targetUser.name;
        }
      }

      return result;
    } catch (error) {
      const message = 'Cannot decrypt the local chat message';

      Sentry.captureException(new Error(message));
      logger.remote({ system: true, capture: 'e2ee' }).error(`${message}: ${error}`);
    }

    return undefined;
  }

  if (!receiver?.applicationKey) {
    return undefined;
  }

  let message = '';
  let decryptionKey = receiver.applicationKey;

  // @FIXME remove test code
  if (canExposeInternals() && window.e2eeAppSharedKey) {
    decryptionKey = window.e2eeAppSharedKey;
  }

  try {
    message = yield call(e2eeDecrypt, payload.message, decryptionKey);
  } catch {
    for (let i = 0; i < maxRatchetAttempts; i++) {
      const ratchetKey: CryptoKey = yield call(receiver.ratchetApplicationKey);

      try {
        message = yield call(e2eeDecrypt, payload.message, ratchetKey);

        // stop tries on successful decryption
        break;
      } catch {
        // /* empty */
      }
    }
  }

  if (message) {
    const result = {
      ...payload,
      message,
    };

    if (receiver.name) {
      result.user.name = receiver.name;
    }

    return result;
  }

  const error = `Cannot decrypt chat message from userId=${payload.user.id}`;

  Sentry.captureException(new Error(error));
  logger.remote({ system: true, capture: 'e2ee' }).error(error);

  return undefined;
}
