import { useEffect } from 'react';

export const useBlurredBackground = (enable: boolean) => {
  useEffect(() => {
    if (enable) {
      document.body.classList.add('bg-blurred');
    } else {
      document.body.classList.remove('bg-blurred');
    }

    return () => {
      document.body.classList.remove('bg-blurred');
    };
  }, [enable]);
};
