import { SignalingRoomUser } from 'services/signaling';
import { SourceDetails } from 'features/layout/types';
import { styleConstants } from 'theme/styleConstants';

export const orderRoomUsers: (users: SignalingRoomUser[]) => SourceDetails[] = (users) =>
  users
    .sort(
      (userA, userB) =>
        (userB.startedTalkingTimestamp || userB.joinTimestamp) -
        (userA.startedTalkingTimestamp || userA.joinTimestamp)
    )
    .map((user) => ({
      userId: user.id,
      kind: 'remote',
    }));

export const addDebugTiles = (streams: SourceDetails[], targetTilesCount: number) => {
  const originalStreams = streams.filter((stream) => stream?.kind !== 'debug');

  if (targetTilesCount > 0) {
    const targetDelta = targetTilesCount - originalStreams.length;

    if (targetDelta > 0) {
      for (let i = 1; i < targetDelta; i++) {
        originalStreams.push({ kind: 'debug', userId: `${i}` });
      }
    }
  }

  return originalStreams;
};

const minFeasibleRatio = 9 / 21;
const minFeasibleWidth = 110;
const minFeasibleHeight = 90;

export const layoutConfig = {
  defaultPinWidth: 0.8,
  maxContentWidth: 0.9,
  minContentWidth: 0.2,
  minSidebarWidth: minFeasibleWidth + styleConstants.layout.pagination.controlWidth * 2,
  minFeasibleHeight,
  minFeasibleWidth,
  minFeasibleRatio,
};
