import React from 'react';
import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { FeatureFlagType } from 'features/feature-set/types';

interface FeatureGuardProps {
  feature: FeatureFlagType;
  children: React.ReactNode;
}

// NOTE: this wrapper component passes down props injected from its parent. Hence, children's props
// will be overwritten by parent's props. If it is not expected, consider using 'useFeatureFlag' hook
// directly or assign props to this component instead.
export const FeatureGuard = React.memo(({ feature, children, ...props }: FeatureGuardProps) => {
  const enabled = useFeatureFlag(feature);

  if (enabled && React.isValidElement(children)) {
    return React.cloneElement(children, props);
  }

  return null;
});
