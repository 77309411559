import React, { useCallback, useEffect, useId, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal, selectActiveModalData } from 'features/modal/modalSlice';
import { Trans, useTranslation } from 'react-i18next';
import { useBreakoutRooms } from 'features/breakout-rooms/useBreakoutRooms';
import {
  selectActiveBreakoutUser,
  selectUserBreakoutRoomId,
} from 'features/breakout-rooms/breakoutRoomsSlice';
import TargetRoomSelector, {
  SelectedRoomId,
} from 'features/breakout-rooms/components/target-room-selector/TargetRoomSelector';
import { Box } from '@mui/material';
import { assignUsersToBreakoutRoomRequested } from 'features/breakout-rooms/actions';
import Button from '@mui/material/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'components/Dialog';

const MoveUserToAnotherRoomModal = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const modalId = useId();

  const modalData = useAppSelector(selectActiveModalData);
  const userId = modalData.userId as string;

  const [selectedId, setSelectedId] = useState<SelectedRoomId>();

  const { isBreakoutsAvailable, breakoutRoomsCreated, participantsLimit } = useBreakoutRooms();

  const user = useAppSelector((state) => selectActiveBreakoutUser(state, userId));

  const modalShouldBeClosed = !isBreakoutsAvailable || !breakoutRoomsCreated || !user;

  const roomId = useAppSelector((state) => selectUserBreakoutRoomId(state, userId));

  useEffect(() => {
    if (modalShouldBeClosed) {
      dispatch(closeModal('moveUserToAnotherBreakoutRoom'));
    }
  }, [modalShouldBeClosed, dispatch]);

  const handleCancel = () => {
    dispatch(closeModal('moveUserToAnotherBreakoutRoom'));
  };

  const handleOk = () => {
    dispatch(
      assignUsersToBreakoutRoomRequested({
        id: selectedId as string,
        users: [userId],
      })
    );
    dispatch(closeModal('moveUserToAnotherBreakoutRoom'));
  };

  const handleRoomSelect = useCallback((id: string) => {
    setSelectedId(id);
  }, []);

  const excludedIds = useMemo(() => [roomId], [roomId]);

  return (
    <Dialog
      open={!modalShouldBeClosed}
      maxWidth="xs"
      aria-labelledby={`${modalId}-title`}
      aria-describedby={`${modalId}-description`}
    >
      <DialogTitle id={`${modalId}-title`}>
        {t('breakout_rooms.move_participant.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id={`${modalId}-description`}>
          <Trans
            i18nKey="modals:breakout_rooms.move_participant.description"
            t={t}
            values={{
              username: user?.name,
              participantsLimit,
            }}
            components={{
              bold: <strong />,
            }}
          />
        </DialogContentText>
        <Box
          sx={{
            mt: 6,
          }}
        >
          <TargetRoomSelector
            selectedId={selectedId}
            selectRoom={handleRoomSelect}
            participantsLimit={participantsLimit}
            excludedIds={excludedIds}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="grayscale">
          {t('breakout_rooms.move_participant.btn_cancel_label')}
        </Button>
        <Button onClick={handleOk} disabled={!selectedId}>
          {t('breakout_rooms.move_participant.btn_ok_label')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MoveUserToAnotherRoomModal;
