import { PayloadAction } from '@reduxjs/toolkit';
import { NewMessagePayload } from 'features/chat/types';
import { call, select } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { encryptE2eeMessageSaga } from 'features/chat/sagas/encryptE2eeMessageSaga';

export function* sendMessageSaga(action: PayloadAction<NewMessagePayload>) {
  let { message } = action.payload;

  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
  if (e2eeEnabled) {
    message = yield call(encryptE2eeMessageSaga, action.payload.message);
  }

  if (message) {
    yield call(SignalingSocket.send, {
      event: 'sendChatMessage',
      data: {
        ...action.payload,
        message,
      },
    });
  }
}
