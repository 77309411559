import { useSpeakersTest } from 'hooks/useSpeakersTest';
import { useDeviceTest } from 'hooks/useDeviceTest';
import { useCountdownTimer } from 'use-countdown-timer';
import { logger } from 'utils/logger';

const TIMEOUT = 1000 * 5;

export const useAudioTest = () => {
  const [testStatus, setTestStatus] = useDeviceTest();
  const { playTestSound } = useSpeakersTest();
  const { countdown, start: startCountdown } = useCountdownTimer({
    timer: TIMEOUT,
  });

  let chunks: BlobPart[] = [];

  const handleRecordingStart = () => {
    setTestStatus('processing');
    startCountdown();
  };

  const handleRecordingError = () => {
    setTestStatus('failure');
  };

  const handleRecordingStop = async () => {
    const blob = new Blob(chunks, { type: 'audio/mpeg' });
    chunks = [];
    const audioURL = window.URL.createObjectURL(blob);
    setTestStatus('success');
    await playTestSound({
      url: audioURL,
      onEnd: () => {
        setTestStatus('idle');
      },
    });
  };

  const handleDataAvailable = (e: BlobEvent) => {
    chunks.push(e.data);
  };

  const testAudio = async (stream: MediaStream) => {
    if (testStatus !== 'idle') {
      return;
    }

    const mediaRecorder = new MediaRecorder(stream);

    mediaRecorder.ondataavailable = handleDataAvailable;
    mediaRecorder.onstart = handleRecordingStart;
    mediaRecorder.onerror = handleRecordingError;
    mediaRecorder.onstop = handleRecordingStop;

    mediaRecorder.start();

    setTimeout(() => {
      mediaRecorder.stop();
    }, TIMEOUT);

    logger.remote({ tier: 1 }).info('User tested microphone');
  };

  return { status: testStatus, countdown: Math.floor((countdown / 1000) % 60), testAudio };
};
