import { call } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { upvoteQuestionRequested } from 'features/qa/actions';

export function* upvoteQuestionSaga(action: ReturnType<typeof upvoteQuestionRequested>) {
  yield call(SignalingSocket.send, {
    event: 'upvoteQuestion',
    data: {
      id: action.payload.id,
    },
  });
}
