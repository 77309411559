import { RoomLayoutValuesContext } from 'features/layout/Context';
import { selectShowScrensharePreview } from 'features/layout/features/config/configSlice';
import { selectMaximizedStream } from 'features/layout/features/content/contentSlice';
import { selectContentWidth } from 'features/layout/features/contentDimensions/contentDimensionsSlice';
import LocalScreensharePreviewOverlay from 'features/streaming/components/media/LocalScreensharePreviewOverlay';
import { sourceMatches } from 'features/streaming/utils';
import React, { useContext } from 'react';
import LocalVideoStream from 'features/streaming/components/media/LocalVideoStream';
import StreamMedia from 'features/streaming/components/stream-display/StreamMedia';
import { StreamingMediaProps } from 'features/streaming/types';
import { useAppSelector } from 'store/hooks';
import {
  selectLocalScreenshareBroadcasting,
  selectLocalScreenshareStreams,
} from 'features/streaming/streamingSlice';

const LocalScreenshare = ({ source, avatarSize }: StreamingMediaProps) => {
  const showPreviewControl = useAppSelector(selectShowScrensharePreview);
  const maximizedFeed = useAppSelector(selectMaximizedStream);
  const isMaximized = sourceMatches(source, maximizedFeed);

  const streams = useAppSelector(selectLocalScreenshareStreams);
  const videoBroadcasting = useAppSelector(selectLocalScreenshareBroadcasting);

  const contentWidth = useAppSelector(selectContentWidth);
  const { roomWidth } = useContext(RoomLayoutValuesContext);

  const contentWidthPx = contentWidth * roomWidth;

  const smallPreview = isMaximized && contentWidthPx < 420;
  const wrapBarContent = isMaximized && contentWidthPx < 700;

  const renderContent = () => (
    <StreamMedia source={source} avatarSize={avatarSize}>
      {streams.map((stream) =>
        stream.kind === 'video' && videoBroadcasting ? (
          <LocalVideoStream mode="screenshare" id={stream.id} key={stream.id} />
        ) : null
      )}
      {isMaximized && contentWidthPx > 420 ? (
        <LocalScreensharePreviewOverlay floating wrap={wrapBarContent} />
      ) : null}
    </StreamMedia>
  );

  if (smallPreview) {
    return renderContent();
  }

  return showPreviewControl || !isMaximized ? renderContent() : <LocalScreensharePreviewOverlay />;
};

export default LocalScreenshare;
