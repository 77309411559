import StreamDisplay from 'features/streaming/components/stream-display/StreamDisplay';
import Tile from 'features/layout/tiles/Tile';
import React from 'react';
import { SourceDetails } from 'features/layout/types';

type PagedTileProps = {
  source: SourceDetails;
};

const PagedTile = React.memo(({ source }: PagedTileProps) => (
  <Tile>
    <StreamDisplay source={source} />
  </Tile>
));

export default PagedTile;
