import { usePermissions } from 'features/permissions/usePermissions';
import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { PermissionTypes } from 'features/permissions/types';
import { useAppSelector } from 'store/hooks';
import { selectQAUpvoteEnabled } from 'features/qa/qaSlice';

export const useQA = () => {
  const { hasPermissions } = usePermissions();
  const isFeatureEnabled = useFeatureFlag('qa');

  const upvoteEnabled = useAppSelector(selectQAUpvoteEnabled);

  const canAnswer = hasPermissions(PermissionTypes.answerQA);
  const canModerate = hasPermissions(PermissionTypes.moderateQA);
  const canUpvote = hasPermissions(PermissionTypes.upvoteQA);

  const isFeatureAvailable = isFeatureEnabled;

  return {
    isFeatureAvailable,
    canAnswer,
    canModerate,
    canUpvote,
    upvoteEnabled,
  };
};
