import {
  selectHistoricalSpeakers,
  speakersUpdated,
} from 'features/layout/features/order/orderSlice';
import { followActiveSpeakerSaga } from 'features/layout/sagas/followActiveSpeakerSaga';
import { call, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { ActiveSpeakerChanged } from 'features/streaming/types';
import { selectLocalUserId } from 'features/users/usersSlice';
import { onSpeakerOrderChangedSaga } from 'features/layout/sagas/onSpeakerOrderChangedSaga';
import { UserId } from 'features/users/types';
import { eventBus } from 'utils/eventBus';
import { SourceDetails } from '../types';

export function* onActiveSpeakerChangedSaga(action: PayloadAction<ActiveSpeakerChanged>) {
  const { id: userId } = action.payload;
  const localUserId: UserId = yield select(selectLocalUserId);

  yield call(eventBus.sendMessage, 'activeSpeakerChanged', { userId });

  // update order in which speakers have spoken;
  const speakers: SourceDetails[] = yield select(selectHistoricalSpeakers);

  if (userId !== localUserId) {
    let movedUser: SourceDetails = { userId, kind: 'remote' };

    yield call(followActiveSpeakerSaga, movedUser);

    if (speakers[0]?.userId !== userId) {
      const otherStreams: SourceDetails[] = [];

      for (const stream of speakers) {
        if (stream.userId === userId) {
          movedUser = stream;
        } else {
          otherStreams.push(stream);
        }
      }

      const newHistoricalSpeakers = [movedUser, ...otherStreams];

      yield put(speakersUpdated(newHistoricalSpeakers));

      yield call(onSpeakerOrderChangedSaga, newHistoricalSpeakers, movedUser);
    }
  }
}
