import {
  paginationUpdated,
  selectCurrentPage,
  selectMaxTiles,
} from 'features/layout/features/pagination/paginationSlice';
import { selectFilteredStreams, selectHideLocalStream } from 'features/layout/selectors';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectLocalUserId } from 'features/users/usersSlice';
import { SourceDetails } from 'features/layout/types';

export const usePaginationObserver = () => {
  const dispatch = useAppDispatch();

  const maxTiles = useAppSelector(selectMaxTiles);
  const filteredStreams = useAppSelector(selectFilteredStreams);
  const currentPage = useAppSelector(selectCurrentPage);
  const localUserId = useAppSelector(selectLocalUserId);
  const hideLocalStream = useAppSelector(selectHideLocalStream);

  useEffect(() => {
    let page = Number(currentPage);
    let pageTiles = Number(maxTiles);
    let numPages = 0;
    let slicedPages = 1;
    let totalStreams = filteredStreams.length;

    let streams: SourceDetails[];
    const streamsPerPage: Record<string, SourceDetails[]> = {};

    pageTiles = Math.max(pageTiles - (hideLocalStream ? 0 : 1), 1);

    const tail = [...filteredStreams];

    do {
      streamsPerPage[slicedPages] = tail.splice(0, pageTiles);
      slicedPages += 1;
      numPages += 1;
    } while (tail.length > 0);

    if (page > numPages) {
      page = numPages === 1 ? 1 : numPages - 1;
    }

    streams = streamsPerPage[page] || [];

    // preserve own tile;
    if (!hideLocalStream && !streams.find((stream) => stream.kind === 'local')) {
      streams = streams.concat([{ userId: localUserId || '0', kind: 'local' }]);
      totalStreams += 1;
    }

    dispatch(
      paginationUpdated({
        page,
        numPages,
        streams,
        totalStreams,
      })
    );
  }, [currentPage, maxTiles, filteredStreams, hideLocalStream, localUserId, dispatch]);
};
