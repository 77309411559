import { User } from '@sentry/react';
import { changeRoleRequest } from 'features/permissions/actions';
import { selectRolesToManage } from 'features/permissions/permissionsSlice';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { UserId } from 'features/users/types';
import { selectUserById } from 'features/users/usersSlice';
import { call, put, select } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';

export function* onChangeRoleCommandSaga(data: { userId: UserId; role: string }) {
  const { userId, role: targetRole } = data;

  const targetUser: User = yield select(selectUserById, userId);

  if (targetUser.role === targetRole) {
    yield call(eventBus.error, {
      name: 'permissions',
      message: `Target user already has requested role (${targetRole})`,
    });

    return;
  }

  const canManageRole: boolean = yield call(hasPermissionsSaga, PermissionTypes.manageRoles, {
    targetRole: targetUser.role,
  });

  if (!canManageRole) {
    yield call(eventBus.error, {
      name: 'permissions',
      message: `Your user cannot manage target user's role`,
    });

    return;
  }

  const rolesToManage: { value: string }[] | null = yield select(selectRolesToManage);

  if (!rolesToManage || !rolesToManage.find((role) => role.value === targetRole)) {
    yield call(eventBus.error, {
      name: 'permissions',
      message: `Invalid role requested (${targetRole}).${
        rolesToManage?.length ? `Available roles ${rolesToManage.join(', ')}` : ''
      }`,
    });

    return;
  }

  yield put(
    changeRoleRequest({
      role: targetRole,
      id: userId,
    })
  );
}
