import React from 'react';
import { Backdrop } from '@mui/material';
import { ToolbarPanelType, ToolbarPlacement } from 'features/toolbar/types';
import { config, useSpring } from '@react-spring/web';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { Panel, PanelWrapper, Root } from 'features/toolbar/ToolbarPanel/styled';

export type ToolbarPanelConfig = {
  type: ToolbarPanelType;
  component: React.ComponentType;
}[];

export type ToolbarPanelProps = {
  open: boolean;
  children: React.ReactNode;
  placement: ToolbarPlacement;
  onBackdropClick?: () => void;
};

const ToolbarPanel = ({ children, open, onBackdropClick, placement }: ToolbarPanelProps) => {
  const { isMobile } = useBreakpoints();

  const isOpensRight = placement === 'bottom' || placement === 'left';
  const defaultTransformTo = isOpensRight ? '-100%' : '100%';

  const styles = useSpring({
    from: { translateX: '-100%' },
    to: { translateX: open ? '0' : defaultTransformTo },
    config: { ...config.stiff, clamp: true },
  });

  return (
    <Root
      isMobile={isMobile}
      open={open}
      role={isMobile ? 'presentation' : undefined}
      data-testid="toolbar-panel-root"
    >
      {isMobile && (
        <Backdrop open={open} onClick={onBackdropClick} data-testid="toolbar-panel-backdrop" />
      )}
      <PanelWrapper
        style={styles}
        isMobile={isMobile}
        placement={placement}
        data-testid="toolbar-panel"
      >
        <Panel>{children}</Panel>
      </PanelWrapper>
    </Root>
  );
};

export default ToolbarPanel;
