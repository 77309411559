import { BreakoutRoomEntries, BreakoutRoomWithUserIds } from 'features/breakout-rooms/types';

export const getRooms = ({
  roomEntries,
  ids,
}: {
  roomEntries: BreakoutRoomEntries;
  ids: string[];
}): BreakoutRoomWithUserIds[] => {
  const result: BreakoutRoomWithUserIds[] = [];
  for (const id of ids) {
    result.push(roomEntries[id]);
  }
  return result;
};
