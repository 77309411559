import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectToolbarPanel,
  toolbarPanelClosed,
  toolbarPanelOpened,
} from 'features/toolbar/toolbarSlice';
import { ToolbarPanelType } from 'features/toolbar/types';
import { useCallback } from 'react';

export const useToolbarPanel = () => {
  const dispatch = useAppDispatch();
  const toolbarPanel = useAppSelector(selectToolbarPanel);

  const openPanel = useCallback(
    (type: ToolbarPanelType) => {
      dispatch(toolbarPanelOpened(type));
    },
    [dispatch]
  );

  const closePanel = useCallback(() => {
    dispatch(toolbarPanelClosed());
  }, [dispatch]);

  const togglePanel = useCallback(
    (type: ToolbarPanelType) => {
      if (toolbarPanel === type) {
        dispatch(toolbarPanelClosed());
      } else {
        dispatch(toolbarPanelOpened(type));
      }
    },
    [dispatch, toolbarPanel]
  );

  return {
    toolbarPanel,
    openPanel,
    closePanel,
    togglePanel,
  };
};
