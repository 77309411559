import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import UnbrandedLayout from 'layouts/UnbrandedLayout';

type WaitingScreenProps = {
  show: boolean;
  children?: React.ReactNode;
  spinner?: boolean;
  title?: string;
  // flat?: boolean;
};

const WaitingScreen = ({ show, title, children, spinner }: WaitingScreenProps) => {
  if (!show) {
    return null;
  }

  const content = (
    <Box
      sx={{
        textAlign: 'center',
      }}
    >
      {title && (
        <Typography variant="h3" mb={3}>
          {title}
        </Typography>
      )}
      {children && children}
      {spinner ? <CircularProgress sx={{ mt: 4 }} disableShrink /> : null}
    </Box>
  );

  return <UnbrandedLayout>{content}</UnbrandedLayout>;
};

export default WaitingScreen;
