import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { DevModeState } from './types';

export const initialState: DevModeState = {
  devModeEnabled: false,
  targetTilesCount: 0,
};

export const devModeSlice = createSlice({
  name: 'devMode',
  initialState,
  reducers: {
    debugToggled(state) {
      state.devModeEnabled = !state.devModeEnabled;
    },
    targetTilesCountSet(state, action: PayloadAction<number>) {
      state.targetTilesCount = action.payload;
    },
  },
});

export const { debugToggled, targetTilesCountSet } = devModeSlice.actions;

export const selectTargetTilesCount = (state: RootState) => state.devMode.targetTilesCount;
export const selectDevModeEnabled = (state: RootState) => state.devMode.devModeEnabled;

export default devModeSlice.reducer;
