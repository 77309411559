import { getRelatedDevice } from 'features/user-media/utils/getRelatedDevice';
import { EnumeratedMediaDevices } from 'features/user-media/types';

// Resolve persisted device. Try to get the relative device if applicable.
export const getPersistedDeviceId = (
  mediaDevices: EnumeratedMediaDevices,
  kind: MediaDeviceKind
) => {
  try {
    const persistedDeviceId = localStorage.getItem(kind);
    if (!persistedDeviceId) {
      return undefined;
    }

    const devices = Object.values(mediaDevices).flat();
    const device = devices.find((d) => d.deviceId === persistedDeviceId && d.kind === kind);
    if (!device) {
      return undefined;
    }

    const relativeDevice = getRelatedDevice(mediaDevices[kind], device);
    return relativeDevice.deviceId;
  } catch {
    return undefined;
  }
};
