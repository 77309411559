import Title from 'components/Title';
import Subtitle from 'components/Subtitle';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import Popper from 'components/Popper';
import { useAppSelector } from 'store/hooks';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { E2eePopoverTrigger } from 'features/join/shared/E2eePopoverTrigger';

const Header = () => {
  const { t } = useTranslation('join');

  const e2eeEnabled = useAppSelector(selectE2eeEnabled);

  return (
    <Box textAlign="center">
      <Title>{t('publisher.title')}</Title>
      {e2eeEnabled ? (
        <Subtitle>
          {t('publisher.e2ee_subtitle.before_e2ee_label')}{' '}
          <Popper
            id="e2ee-session-message"
            triggerType={['hover', 'focus']}
            placement="top"
            content={<Typography>{t('publisher.e2ee_subtitle.e2ee_tooltip_text')}</Typography>}
            contentWidth="304px"
          >
            <E2eePopoverTrigger>{t('publisher.e2ee_subtitle.e2ee_label')}</E2eePopoverTrigger>
          </Popper>{' '}
          {t('publisher.e2ee_subtitle.after_e2ee_label')}
        </Subtitle>
      ) : (
        <Subtitle>{t('publisher.subtitle')}</Subtitle>
      )}
    </Box>
  );
};

export default Header;
