import { StreamKind } from 'utils/webrtc/types';
import { FeedId, SubscriberMid } from 'features/streaming/types';
import Janus from 'lib/janus';
import { ControlledReceivingHandle } from 'utils/webrtc/ControlledReceivingHandle';
import { ReceivingMidStats } from 'features/system-stats/types';

interface InitOptions {
  feed: ControlledReceivingHandle;
  feedId: FeedId;
  mid: SubscriberMid;
  mediaElement: HTMLMediaElement;
}

export class MediaStreamStats {
  private bsnow: number = 0;

  private bsbefore: number = 0;

  private tsnow: number = 0;

  private tsbefore: number = 0;

  private fdbefore: number = 0;

  feedId?: FeedId;

  feed?: ControlledReceivingHandle;

  mid?: SubscriberMid;

  kind?: StreamKind;

  element?: HTMLMediaElement;

  bitrate: number = 0;

  fps?: number;

  codec?: string;

  videoWidth?: number;

  videoHeight?: number;

  constructor(options: InitOptions) {
    this.feed = options.feed;
    this.feedId = options.feedId;
    this.mid = options.mid;
    this.element = options.mediaElement;
  }

  getStats = async (): Promise<ReceivingMidStats | null> => {
    if (!this.mid || !this.feed || !this.feedId) {
      Janus.warn('Invalid handle or mid');
      return null;
    }

    const config = this.feed.janusPlugin.webrtcStuff;
    if (!config.pc) {
      Janus.warn('Invalid PeerConnection');
      return null;
    }

    const { mid } = this;

    if (!Janus.unifiedPlan) {
      Janus.warn('Trying to get stats from non-unified plan. Skip');
      return null;
    }

    const transceiver = config.pc.getTransceivers().find((t) => t.mid === mid);
    if (!transceiver) {
      Janus.warn(`No transceiver with mid ${mid}`);
      return null;
    }
    if (!transceiver.receiver) {
      Janus.warn(`No receiver with mid ${mid}`);
      return null;
    }

    const stats = await transceiver.receiver.getStats();

    stats.forEach((res) => {
      if (!res) {
        return;
      }

      let inStats = false;

      if (
        (res.mediaType === 'video' || res.id.toLowerCase().includes('video')) &&
        res.type === 'inbound-rtp' &&
        !res.id.includes('rtcp')
      ) {
        this.kind = 'video';
        inStats = true;
      }

      if (
        (res.mediaType === 'audio' || res.id.toLowerCase().includes('audio')) &&
        res.type === 'inbound-rtp' &&
        !res.id.includes('rtcp')
      ) {
        this.kind = 'audio';
        inStats = true;
      }

      if (inStats) {
        this.bsnow = res.bytesReceived;
        this.tsnow = res.timestamp;

        if (this.bsbefore === null || this.tsbefore === null) {
          // Skip this round
          this.bsbefore = this.bsnow;
          this.tsbefore = this.tsnow;

          if (this.kind === 'video') {
            this.fdbefore = res.framesDecoded;
          }
        } else {
          let timePassed = this.tsnow - this.tsbefore;
          if (Janus.webRTCAdapter.browserDetails.browser === 'safari') {
            timePassed /= 1000; // Apparently the timestamp is in microseconds, in Safari
          }

          let bitrate = Math.round(((this.bsnow - this.bsbefore) * 8) / timePassed);
          if (Janus.webRTCAdapter.browserDetails.browser === 'safari') {
            bitrate /= 1000;
          }

          this.bitrate = bitrate;
          this.bsbefore = this.bsnow;
          this.tsbefore = this.tsnow;

          if (this.kind === 'video') {
            this.fps = res.framesDecoded - this.fdbefore;
            this.fdbefore = res.framesDecoded;

            if (this.element instanceof HTMLVideoElement) {
              this.videoWidth = this.element.videoWidth;
              this.videoHeight = this.element.videoHeight;
            }

            // @TODO find out better way to get this?
            this.codec = res.codecId?.includes('_96') ? 'vp8' : 'h264';
          }
        }
      }
    });

    return {
      feedId: this.feedId,
      mid: this.mid,
      kind: this.kind!,
      bitrate: this.bitrate,
      fps: this.fps,
      codec: this.codec,
      videoWidth: this.videoWidth,
      videoHeight: this.videoHeight,
    };
  };
}
