import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import {
  mediaDevicesUpdated,
  selectActiveMediaDevices,
  selectAllMediaDevicePermissions,
  selectMediaDevices,
  selectMediaDevicesByKind,
} from 'features/user-media/userMediaSlice';
import { enumerateDevices } from 'features/user-media/utils';
import { EnumeratedMediaDevices, UpdateMediaDevicesMeta } from 'features/user-media/types';

export interface UpdateMediaDevicesFunction {
  (meta?: UpdateMediaDevicesMeta): Promise<EnumeratedMediaDevices>;
}

export const useMediaDevices = () => {
  const dispatch = useDispatch();

  const mediaDevices = useAppSelector(selectMediaDevicesByKind);
  const mediaDeviceEntities = useAppSelector(selectMediaDevices);
  const activeMediaDevices = useAppSelector(selectActiveMediaDevices);
  const permissions = useAppSelector(selectAllMediaDevicePermissions);

  const updateMediaDevices: UpdateMediaDevicesFunction = useCallback(
    async (meta?: UpdateMediaDevicesMeta) => {
      const updatedDevices = await enumerateDevices();
      dispatch(mediaDevicesUpdated(updatedDevices, meta));
      return updatedDevices;
    },
    [dispatch]
  );

  const persistDevices = useCallback(
    (updatedDeviceId: string, updatedDeviceKind: MediaDeviceKind) => {
      const targetDevice = mediaDeviceEntities.find(
        (d) => d.deviceId === updatedDeviceId && d.kind === updatedDeviceKind
      );

      if (!targetDevice) {
        return;
      }

      try {
        localStorage.setItem(targetDevice.kind, targetDevice.deviceId);

        const entries = Object.entries(activeMediaDevices);
        for (const [kind, deviceId] of entries) {
          if (deviceId && kind !== targetDevice.kind) {
            localStorage.setItem(kind, deviceId);
          }
        }
      } catch {
        // do nothing
      }
    },
    [activeMediaDevices, mediaDeviceEntities]
  );

  return {
    mediaDevices,
    activeMediaDevices,
    micPermissions: permissions.audioinput,
    camPermissions: permissions.videoinput,
    updateMediaDevices,
    persistDevices,
  };
};
