import camelcase from 'camelcase';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TabPanelInner } from 'components/Tabs';
import { Box, Stack, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import SettingControl from 'components/SettingControl';
import { Switch } from 'components/Switch';
import { selectFeatureFlags } from 'features/feature-set/featuresSlice';
import { FeatureFlag } from 'features/feature-set/types';
import { disableFeatureRequested, enabledFeatureRequested } from 'features/feature-set/actions';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { selectPrivateGroupChat } from 'features/chat/chatSlice';
import { selectIsBreakoutRoom } from 'features/breakout-rooms/breakoutRoomsSlice';

const ComponentsTab = () => {
  const { t } = useTranslation('settings');
  const dispatch = useAppDispatch();

  const e2eeEnabled = useAppSelector(selectE2eeEnabled);

  const featureFlags = useAppSelector(selectFeatureFlags);

  const privateGroupChat = useAppSelector(selectPrivateGroupChat);

  const [state, setState] = useState({ ...featureFlags });

  const isBreakoutRoom = useAppSelector(selectIsBreakoutRoom);

  useEffect(() => {
    setState({ ...featureFlags });
  }, [featureFlags]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    const name = event.target.name as FeatureFlag;

    const convertedFlag = camelcase(name);

    if (checked) {
      dispatch(enabledFeatureRequested({ feature: name }));
    } else {
      dispatch(disableFeatureRequested({ feature: name }));
    }

    setState({
      ...state,
      [convertedFlag]: checked,
    });
  };

  return (
    <TabPanelInner>
      <Box
        sx={{
          mb: 5,
        }}
      >
        <Typography>
          {isBreakoutRoom ? t('components.description_breakout') : t('components.description')}
        </Typography>
      </Box>
      <Stack spacing={6}>
        <SettingControl
          label={t('components.whiteboard.label')}
          control={
            <Switch
              onChange={handleChange}
              checked={state.whiteboard}
              name={FeatureFlag.whiteboard}
              inputProps={{
                'aria-label': state.whiteboard
                  ? t('components.whiteboard.switch_disable_aria_label')
                  : t('components.whiteboard.switch_enable_aria_label'),
              }}
            />
          }
        />
        <SettingControl
          label={t('components.qa.label')}
          control={
            <Switch
              onChange={handleChange}
              checked={state.qa}
              disabled={e2eeEnabled}
              name={FeatureFlag.qa}
              inputProps={{
                'aria-label': state.qa
                  ? t('components.qa.switch_disable_aria_label')
                  : t('components.qa.switch_enable_aria_label'),
              }}
            />
          }
        />
        <SettingControl
          label={t('components.general_chat.label')}
          control={
            <Switch
              onChange={handleChange}
              checked={state.generalChat}
              name={FeatureFlag.generalChat}
              inputProps={{
                'aria-label': state.generalChat
                  ? t('components.general_chat.switch_disable_aria_label')
                  : t('components.general_chat.switch_enable_aria_label'),
              }}
            />
          }
        />
        <SettingControl
          label={t('components.private_chat.label')}
          control={
            <Switch
              onChange={handleChange}
              checked={isBreakoutRoom ? false : state.privateChat}
              disabled={isBreakoutRoom}
              name={FeatureFlag.privateChat}
              inputProps={{
                'aria-label': state.privateChat
                  ? t('components.private_chat.switch_disable_aria_label')
                  : t('components.private_chat.switch_enable_aria_label'),
              }}
            />
          }
        />
        <SettingControl
          label={t('components.private_group_chat.label')}
          control={
            <Switch
              onChange={handleChange}
              checked={isBreakoutRoom ? false : state.privateGroupChat}
              disabled={!privateGroupChat.name || isBreakoutRoom}
              name={FeatureFlag.privateGroupChat}
              inputProps={{
                'aria-label': state.privateGroupChat
                  ? t('components.private_group_chat.switch_disable_aria_label')
                  : t('components.private_group_chat.switch_enable_aria_label'),
              }}
            />
          }
        />
      </Stack>
    </TabPanelInner>
  );
};

export default ComponentsTab;
