import { AppThunk } from 'store/store';
import {
  actionNotificationUpdated,
  selectNotificationById,
} from 'features/notifications/notificationsSlice';
import { NotificationId, ActionNotificationParams } from 'features/notifications/types';
import { notification } from 'features/notifications/toast/notification';
import Notification from 'features/notifications/components/Notification';
import { ToastOptions } from 'react-toastify';
import { selectGlobalUser } from 'features/users/usersSlice';
import { ACTION_NOTIFICATION_CONTAINER_ID } from 'features/notifications/constants';

export const updateActionNotification =
  (
    id: NotificationId,
    params: Partial<ActionNotificationParams>,
    toastOptions?: ToastOptions
  ): AppThunk<void | NotificationId> =>
  (dispatch, getState) => {
    const activeNotification = selectNotificationById(getState(), id);
    if (!activeNotification) {
      // nothing to update
      return;
    }

    // gather data for the toast notification
    const props = { ...activeNotification, ...params };
    const initiator = selectGlobalUser(getState(), props.initiatorId || '') || props.initiator;

    notification.update(id, {
      render: ({ data, ...toastContentProps }) => (
        <Notification {...toastContentProps} user={initiator} {...props} id={id} />
      ),
      ...toastOptions,
      containerId: ACTION_NOTIFICATION_CONTAINER_ID,
    });

    dispatch(
      actionNotificationUpdated({
        id,
        ...params,
      })
    );
  };
