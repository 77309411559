import { Dialog, DialogActions, DialogContent, DialogTitle } from 'components/Dialog';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Box, Button, FormControl, FormControlLabel, RadioGroup } from '@mui/material';
import { closeModal } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import React, { useEffect, useId } from 'react';
import Input from 'components/Input';
import SettingControl from 'components/SettingControl';
import Radio from 'components/Radio';
import { styled } from '@mui/material/styles';
import { createRoomsRequested } from 'features/breakout-rooms/actions';
import {
  selectBreakoutParticipantsLimit,
  selectBreakoutRoomsCreated,
  selectBreakoutRoomsLimit,
} from 'features/breakout-rooms/breakoutRoomsSlice';
import { useBreakoutRooms } from 'features/breakout-rooms/useBreakoutRooms';
import { selectAvailableUserIds } from 'features/users/usersSlice';

export interface CreateBreakoutRoomsValues {
  number: string;
  assignParticipants?: 'auto' | 'manually';
}

const StyledSettingControl = styled(SettingControl)({
  justifyContent: 'flex-start',
  '.SettingControl-label-wrapper': {
    flexBasis: '375px',
  },
});

function formatNumber(num: number) {
  if (Number.isInteger(num)) {
    return num.toString();
  }

  const floorValue = Math.floor(num);
  const ceilValue = Math.ceil(num);
  return `${floorValue}-${ceilValue}`;
}

function calculateUsersPerRoom(participantsCount: number, roomNumber: string) {
  if (!roomNumber || Number.isNaN(Number(roomNumber))) {
    return '0';
  }

  const parsedRoomNumber = parseInt(roomNumber, 10);
  if (parsedRoomNumber === 0) {
    return '0';
  }

  return formatNumber(participantsCount / parsedRoomNumber);
}

const CreateBreakoutRoomsModal = () => {
  const { t } = useTranslation(['modals', 'breakout-rooms']);
  const dispatch = useAppDispatch();

  const modalId = useId();

  const roomsLimit = useAppSelector(selectBreakoutRoomsLimit);
  const participantsLimit = useAppSelector(selectBreakoutParticipantsLimit);
  const breakoutRoomsCreated = useAppSelector(selectBreakoutRoomsCreated);

  const userIds = useAppSelector(selectAvailableUserIds);

  const { isBreakoutsAvailable } = useBreakoutRooms();

  const {
    handleSubmit,
    watch,
    formState: { errors, isValid },
    control,
  } = useForm<CreateBreakoutRoomsValues>({
    mode: 'onChange',
    defaultValues: {
      number: '1',
      assignParticipants: 'auto',
    },
  });

  const roomNumber = watch('number');

  const usersPerRoom = calculateUsersPerRoom(userIds.length, roomNumber);

  const modalShouldBeClosed = !isBreakoutsAvailable || breakoutRoomsCreated;

  useEffect(() => {
    if (modalShouldBeClosed) {
      dispatch(closeModal('createBreakoutRooms'));
    }
  }, [modalShouldBeClosed, dispatch]);

  const createRooms: SubmitHandler<CreateBreakoutRoomsValues> = (data) => {
    dispatch(
      createRoomsRequested({
        number: parseInt(data.number, 10),
        autoAssign: data.assignParticipants === 'auto',
      })
    );
    dispatch(closeModal('createBreakoutRooms'));
  };

  const handleCancel = () => {
    dispatch(closeModal('createBreakoutRooms'));
  };

  return (
    <Dialog
      open={!modalShouldBeClosed}
      maxWidth="sm"
      aria-labelledby={`${modalId}-title`}
      onClose={handleCancel}
    >
      <DialogTitle id={`${modalId}-title`}>{t('breakout_rooms.create_rooms.title')}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            pt: 1,
          }}
        >
          <Box
            sx={{
              mb: 5,
            }}
          >
            <StyledSettingControl
              label={t('breakout-rooms:create_rooms.room_number.label')}
              helperText={t('breakout-rooms:create_rooms.room_number.help_text', {
                limit: participantsLimit,
                usersPerRoom,
                breakoutsCount: roomNumber || 0,
              })}
              alignItems="flex-start"
              control={
                <FormControl error={!!errors.number}>
                  <Controller
                    name="number"
                    control={control}
                    rules={{
                      required: true,
                      pattern: /^[1-9]\d*$/,
                      validate: {
                        lessThanLimit: (v) => parseInt(v, 10) <= roomsLimit,
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        inputProps={{
                          inputMode: 'numeric',
                          'aria-label': t('breakout-rooms:create_rooms.room_number.label'),
                        }}
                        sx={{
                          maxWidth: 80,
                        }}
                        {...field}
                      />
                    )}
                  />
                </FormControl>
              }
            />
          </Box>
          <StyledSettingControl
            label={t('breakout-rooms:create_rooms.assign_participants.label')}
            alignItems="flex-start"
            control={
              <FormControl>
                <Controller
                  name="assignParticipants"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      <FormControlLabel
                        control={<Radio />}
                        value="auto"
                        label={t(
                          'breakout-rooms:create_rooms.assign_participants.radio_label_auto'
                        )}
                      />
                      <FormControlLabel
                        control={<Radio />}
                        value="manually"
                        label={t(
                          'breakout-rooms:create_rooms.assign_participants.radio_label_manual'
                        )}
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            }
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button type="button" color="grayscale" onClick={handleCancel}>
          {t('breakout_rooms.create_rooms.btn_cancel_label')}
        </Button>
        <Button type="button" onClick={handleSubmit(createRooms)} disabled={!isValid}>
          {t('breakout_rooms.create_rooms.btn_ok_label')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateBreakoutRoomsModal;
