import { useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectLocalStream } from 'features/streaming/streamingSlice';
import { RTCClient } from 'utils/webrtc';

type Mode = 'media' | 'screenshare';

// @TODO consider removing screensharing feed from here
export const usePublishingFeed = (mode: Mode) => {
  const control = useMemo(
    () => (mode === 'media' ? RTCClient.publishingFeed : RTCClient.screensharingFeed),
    [mode]
  );

  // @TODO get streams directly
  const videoFeedStream = useAppSelector((state) => selectLocalStream(state, 'video', mode));
  const audioFeedStream = useAppSelector((state) => selectLocalStream(state, 'audio', mode));

  const videoStream = useMemo(() => {
    if (!videoFeedStream) {
      return null;
    }

    return control.getStream(videoFeedStream.id).mediaStream;
  }, [control, videoFeedStream]);

  const audioStream = useMemo(() => {
    if (!audioFeedStream) {
      return null;
    }

    return control.getStream(audioFeedStream.id).mediaStream;
  }, [control, audioFeedStream]);

  return {
    control,
    videoStream,
    audioStream,
  };
};
