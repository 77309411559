import { call } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { stopLiveAnswerRequested } from 'features/qa/actions';

export function* stopLiveAnswerSaga(action: ReturnType<typeof stopLiveAnswerRequested>) {
  yield call(SignalingSocket.send, {
    event: 'stopLiveAnswerQuestion',
    data: {
      liveAnswerId: action.payload.liveAnswerId,
      questionId: action.payload.questionId,
    },
  });
}
