import { call, select } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { clonePollRequested } from 'features/polls/actions';
import { Poll, PollPayload } from 'features/polls/types';
import { selectPoll } from 'features/polls/pollsSlice';

export function* clonePollSaga(action: ReturnType<typeof clonePollRequested>) {
  const poll: Poll = yield select(selectPoll, action.payload);
  if (!poll) {
    return;
  }

  const pollPayload: PollPayload = {
    question: `Cloned - ${poll.question}`,
    choices: poll.choices.map((choice) => choice.text),
    multipleChoices: poll.multipleChoices,
    anonymous: poll.anonymous,
  };

  yield call(SignalingSocket.send, { event: 'createPoll', data: pollPayload });
}
