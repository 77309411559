import { createContext } from 'react';

export interface RoomLayoutValuesContextState {
  roomWidth: number;
  roomHeight: number; // [?]
  contentHeight: number; // [?]
  tilesWidth: number;
  tilesHeight: number; // [?]
  verticalLayout: boolean;
}

export const RoomLayoutValuesContext = createContext<RoomLayoutValuesContextState>({
  roomWidth: window.innerWidth,
  roomHeight: window.innerHeight,
  contentHeight: window.innerHeight,
  tilesWidth: 0,
  tilesHeight: 0,
  verticalLayout: false,
});

// WARN: Use caution and always use both of these providers simultaneously.
export const { Provider: RoomLayoutValuesProvider } = RoomLayoutValuesContext;
