export const getFillerColor = (index: number) => {
  const palette = [
    '#EC644B',
    '#1E8BC3',
    '#26A65B',
    '#F5AB35',
    '#9B59B6',
    '#86E2D5',
    '#DB0A5B',
    '#96281B',
    '#67809F',
    '#F9690E',
  ];

  return palette[index % palette.length];
};
