import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'components/Dialog';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectRoomE2eeCode } from 'features/e2ee/e2eeSlice';
import { Box, Button } from '@mui/material';
import { closeModal } from 'features/modal/modalSlice';
import { useEffect, useId, useState } from 'react';
import CodeBox from 'components/CodeBox';
import CopyButton from 'components/CopyButton/CopyButton';

const RoomSecurityCodeModal = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const [copied, setCopied] = useState(false);

  const prefix = useId();

  const roomCode = useAppSelector(selectRoomE2eeCode);

  useEffect(() => {
    setCopied(false);
  }, [roomCode]);

  const handleOk = () => {
    dispatch(closeModal('roomE2eeCode'));
  };

  const handleClose = () => {
    dispatch(closeModal('roomE2eeCode'));
  };

  const onCopy = () => {
    setCopied(true);
  };

  return (
    <Dialog
      open
      maxWidth="xs"
      onClose={handleClose}
      aria-labelledby={`${prefix}-title`}
      aria-describedby={`${prefix}-description`}
    >
      <DialogTitle id={`${prefix}-title`}>{t('room_e2ee_code.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText id={`${prefix}-description`}>
          {t('room_e2ee_code.description')}
        </DialogContentText>
        <Box
          data-testid="code-box"
          sx={{
            mt: 5,
          }}
        >
          <CodeBox>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(4,minmax(0,1fr))',
                justifyItems: 'center',
                gap: 1,
              }}
            >
              {roomCode.map((chunk, i) => (
                <span key={i}>{chunk}</span>
              ))}
            </Box>
          </CodeBox>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <CopyButton copied={copied} onCopy={onCopy} text={roomCode.join(' ')}>
          {(isCopied) =>
            isCopied ? t('room_e2ee_code.btn_copied_label') : t('room_e2ee_code.btn_copy_label')
          }
        </CopyButton>
        <Button onClick={handleOk} autoFocus>
          {t('room_e2ee_code.btn_ok_label')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RoomSecurityCodeModal;
