import { TabPanelInner } from 'components/Tabs';
import { useAppSelector } from 'store/hooks';
import {
  selectConnectionQuality,
  selectSendingLayers,
  selectSendingTotals,
} from 'features/system-stats/systemStatsSlice';
import {
  Alert,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import StatsItem from 'features/room/settings/StatsItem';
import { useTranslation } from 'react-i18next';
import { ConnectionQualityLevel } from 'utils/webrtc/ConnectionQuality';
import { inRange, toFixedNumber } from 'utils/math';

const SystemStatsTab = () => {
  const { t } = useTranslation('settings');
  const sendingLayers = useAppSelector(selectSendingLayers);
  const sendingTotals = useAppSelector(selectSendingTotals);
  const connectionQuality = useAppSelector(selectConnectionQuality);

  const printConnectionQuality = () => {
    if (connectionQuality < ConnectionQualityLevel.bad) {
      return (
        <Box component="span" color="#757575">
          {t('system_stats.quality_levels.bad')}
        </Box>
      );
    }

    if (inRange(connectionQuality, ConnectionQualityLevel.bad, ConnectionQualityLevel.poor)) {
      return (
        <Box component="span" color="#e53935">
          {t('system_stats.quality_levels.poor')}
        </Box>
      );
    }

    if (inRange(connectionQuality, ConnectionQualityLevel.poor, ConnectionQualityLevel.good)) {
      return (
        <Box component="span" color="#ffb300">
          {t('system_stats.quality_levels.fair')}
        </Box>
      );
    }

    return (
      <Box component="span" color="#43a047">
        {t('system_stats.quality_levels.good')}
      </Box>
    );
  };

  return (
    <TabPanelInner
      sx={{
        maxWidth: 'none',
      }}
    >
      {connectionQuality < ConnectionQualityLevel.poor ? (
        <Box
          sx={{
            mb: 4,
          }}
        >
          <Alert severity="warning">{t('system_stats.weak_connection')}</Alert>
        </Box>
      ) : null}

      <Box
        sx={{
          mb: 4,
        }}
      >
        <Typography
          component="span"
          sx={{
            fontWeight: 700,
            mr: 2,
          }}
        >
          {t('system_stats.connection_quality')}
        </Typography>
        <Typography component="span">
          {printConnectionQuality()} (MOS {toFixedNumber(connectionQuality, 2)})
        </Typography>
      </Box>

      <Box>
        <Typography component="h3" variant="subtitle1" gutterBottom>
          {t('system_stats.totals_title')}
        </Typography>
        <Box
          sx={{
            mb: 4,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Box minWidth={200}>
            <StatsItem
              label={t('system_stats.total_packets_sent')}
              value={sendingTotals.packetsSent}
            />
            <StatsItem
              label={t('system_stats.total_packets_lost')}
              value={sendingTotals.packetsLost}
            />
            <StatsItem
              label={t('system_stats.total_packet_loss')}
              value={`${sendingTotals.packetLoss}%`}
            />
          </Box>
          <Box minWidth={200}>
            <StatsItem
              label={t('system_stats.total_jitter')}
              value={`${Math.round(sendingTotals.jitter)}ms`}
            />
            <StatsItem label={t('system_stats.total_rtt')} value={`${sendingTotals.rtt}ms`} />
          </Box>
        </Box>
      </Box>
      <Typography component="h3" variant="subtitle1" gutterBottom>
        Details:
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Layer</TableCell>
              <TableCell>Packets sent</TableCell>
              <TableCell>Packets lost</TableCell>
              <TableCell>Packet loss</TableCell>
              <TableCell>Jttr</TableCell>
              <TableCell>RTT</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(sendingLayers).map((layer) => (
              <TableRow key={layer.rid} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{layer.rid}</TableCell>
                <TableCell>{layer.packetsSent}</TableCell>
                <TableCell>{layer.packetsLost}</TableCell>
                <TableCell>{layer.packetLoss}</TableCell>
                <TableCell>{Math.round(layer.jitter)}</TableCell>
                <TableCell>{layer.rtt}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TabPanelInner>
  );
};

export default SystemStatsTab;
