import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { kickUser } from 'features/users/actions';
import { User, UserId } from 'features/users/types';
import { selectUserById } from 'features/users/usersSlice';
import { call, put, select } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';

export function* onRemoveUserCommandSaga(userId: UserId) {
  const targetUser: User = yield select(selectUserById, userId);

  if (targetUser) {
    const canRemoveUser: boolean = yield call(
      hasPermissionsSaga,
      PermissionTypes.removeParticipant,
      { targetRole: targetUser.role }
    );

    if (!canRemoveUser) {
      yield call(
        eventBus.forbiddenActionError,
        'Forbidden action. This participant is not allowed to remove target participant',
        'remove-user'
      );

      return;
    }

    yield put(kickUser(userId));
  }
}
