import { ACTION_NOTIFICATION_CONTAINER_ID } from 'features/notifications/constants';
import { notification } from 'features/notifications/toast/notification';
import { call, put } from 'redux-saga/effects';
import { actionNotificationsReset } from 'features/notifications/notificationsSlice';

export function* clearAllActionNotificationsSaga() {
  // @ts-ignore
  yield call(notification.dismiss, { containerId: ACTION_NOTIFICATION_CONTAINER_ID });
  yield put(actionNotificationsReset());
}
