import { virtualBackgroundChangeRequest } from 'features/virtual-backgrounds/actions';
import { selectPendingVirtualBackgroundConfig } from 'features/virtual-backgrounds/selectors';
import { put, select } from 'redux-saga/effects';
import { SelectedVirtualBackground } from 'utils/webrtc/VirtualBackground';

export function* applyPendingVirtualBackgroundSaga() {
  const pendingConfig: SelectedVirtualBackground = yield select(
    selectPendingVirtualBackgroundConfig
  );

  if (pendingConfig) {
    yield put(virtualBackgroundChangeRequest(pendingConfig));
  }
}
