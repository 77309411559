import { call } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { deleteQuestionRequested } from 'features/qa/actions';

export function* deleteQuestionSaga(action: ReturnType<typeof deleteQuestionRequested>) {
  yield call(SignalingSocket.send, {
    event: 'deleteQuestion',
    data: {
      id: action.payload,
    },
  });
}
