import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { signalingRoomJoined } from 'features/room/actions';
import { normalizeQuestions } from 'features/qa/utils/normalizeQuestions';
import { Answer, QAState, SetQuestionAnswerTypePayload, SortingCriteria } from 'features/qa/types';
import {
  signalingAnswerDeleted,
  signalingQuestionAnswered,
  signalingQuestionAsked,
  signalingQuestionDeleted,
  signalingQuestionLiveAnswerCancelled,
  signalingQuestionLiveAnswerStarted,
  signalingQuestionLiveAnswerStopped,
  signalingQuestionUpvoted,
  signalingQuestionUpvoteRemoved,
} from 'features/qa/actions';
import { toolbarPanelClosed, toolbarPanelOpened } from 'features/toolbar/toolbarSlice';
import { authorizeUser } from 'features/room/thunks/authorizeUser';

export const initialState: QAState = {
  upvoteEnabled: false,
  exportProcessing: false,
  panelOpen: false,
  unreadCount: 0,
  questions: {
    answerType: {},
    entities: {},
    allIds: [],
  },
  answers: {
    entities: {},
  },
};

export const qaSlice = createSlice({
  name: 'qa',
  initialState,
  reducers: {
    answerTypeSet: (state, action: PayloadAction<SetQuestionAnswerTypePayload>) => {
      const question = state.questions.entities[action.payload.questionId];
      if (action.payload.type) {
        const { index } = action.payload;
        question.typedIndex = index;
        state.questions.answerType[action.payload.questionId] = action.payload.type;
      } else {
        delete question.typedIndex;
        delete state.questions.answerType[action.payload.questionId];
      }
    },
    answerTypeReset: (state) => {
      state.questions.answerType = {};
    },
    exportQuestionsRequested: (state) => {
      state.exportProcessing = true;
    },
    exportQuestionsFulfilled: (state) => {
      state.exportProcessing = false;
    },
    exportQuestionsRejected: (state) => {
      state.exportProcessing = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signalingRoomJoined, (state, action) => {
        const { questions, answers, totalCount } = normalizeQuestions(action.payload.questions);

        if (!state.panelOpen) {
          const currentTotal =
            state.questions.allIds.length + Object.keys(state.answers.entities).length;
          state.unreadCount = Math.max(0, totalCount - currentTotal);
        }

        state.questions.entities = questions.entities;
        state.questions.allIds = questions.allIds;
        state.answers.entities = answers;
      })
      .addCase(signalingQuestionAsked, (state, action) => {
        state.questions.entities[action.payload.id] = action.payload;
        state.questions.allIds.push(action.payload.id);

        if (!state.panelOpen) {
          state.unreadCount += 1;
        }
      })
      .addCase(signalingQuestionAnswered, (state, action) => {
        state.answers.entities[action.payload.id] = action.payload;

        const question = state.questions.entities[action.payload.questionId];
        question.answers.unshift(action.payload.id);

        if (!state.panelOpen) {
          state.unreadCount += 1;
        }
      })
      .addCase(signalingQuestionDeleted, (state, action) => {
        const { id: questionId } = action.payload;
        const question = state.questions.entities[questionId];

        state.questions.allIds = state.questions.allIds.filter((id) => id !== questionId);

        for (const id of question.answers) {
          delete state.answers.entities[id];
        }

        delete state.questions.answerType[questionId];
        delete state.questions.entities[questionId];

        state.unreadCount = Math.max(0, state.unreadCount - 1);
      })
      .addCase(signalingAnswerDeleted, (state, action) => {
        const { id: answerId, questionId } = action.payload;
        const question = state.questions.entities[questionId];

        question.answers = question.answers.filter((id) => id !== answerId);
        delete state.answers.entities[answerId];

        state.unreadCount = Math.max(0, state.unreadCount - 1);
      })
      .addCase(signalingQuestionLiveAnswerStarted, (state, action) => {
        const { id: answerId, questionId } = action.payload;

        const question = state.questions.entities[questionId];
        question.liveAnswerId = answerId;
        question.answers.unshift(answerId);

        state.answers.entities[answerId] = action.payload;
      })
      .addCase(signalingQuestionLiveAnswerCancelled, (state, action) => {
        const { questionId, id: answerId } = action.payload;

        const question = state.questions.entities[questionId];
        question.liveAnswerId = undefined;

        question.answers = question.answers.filter((id) => id !== answerId);
        delete state.answers.entities[answerId];
      })
      .addCase(signalingQuestionLiveAnswerStopped, (state, action) => {
        state.answers.entities[action.payload.id] = action.payload;

        delete state.questions.answerType[action.payload.questionId];
        const question = state.questions.entities[action.payload.questionId];
        question.liveAnswerId = undefined;

        if (!state.panelOpen) {
          state.unreadCount += 1;
        }
      })
      .addCase(toolbarPanelOpened, (state, action) => {
        if (action.payload === 'qa') {
          state.panelOpen = true;
          state.unreadCount = 0;
        } else {
          state.panelOpen = false;
        }
      })
      .addCase(toolbarPanelClosed, (state) => {
        state.panelOpen = false;
      })
      .addCase(authorizeUser.fulfilled, (state, action) => {
        const { upvoteQaEnabled } = action.payload.settings;
        state.upvoteEnabled = upvoteQaEnabled;
      })
      .addCase(signalingQuestionUpvoted, (state, action) => {
        const {
          data: { id, local },
        } = action.payload;

        const question = state.questions.entities[id];
        question.votes += 1;

        if (local) {
          question.voted = true;
        }
      })
      .addCase(signalingQuestionUpvoteRemoved, (state, action) => {
        const {
          data: { id, local },
        } = action.payload;

        const question = state.questions.entities[id];
        question.votes -= 1;

        if (local) {
          question.voted = false;
        }
      });
  },
});

export const {
  answerTypeSet,
  answerTypeReset,
  exportQuestionsRequested,
  exportQuestionsRejected,
  exportQuestionsFulfilled,
} = qaSlice.actions;

const selectQuestionEntities = (state: RootState) => state.qa.questions.entities;

export const selectQuestionAnswerTypes = (state: RootState) => state.qa.questions.answerType;

export const selectQuestionAnswerType = (state: RootState, questionId: string) =>
  state.qa.questions.answerType[questionId] || null;

export const selectQuestionIds = (state: RootState) => state.qa.questions.allIds;

const selectAnswerEntities = (state: RootState) => state.qa.answers.entities;

export const selectQuestions = createSelector(
  [
    selectQuestionEntities,
    selectQuestionIds,
    (state: RootState, criteria: SortingCriteria) => criteria,
  ],
  (entities, ids, criteria) => {
    let sortedQuestions = ids
      .map((id) => entities[id])
      .sort((a, b) => {
        switch (criteria) {
          case 'asc':
            return new Date(a.date).getTime() - new Date(b.date).getTime();
          case 'desc':
            return new Date(b.date).getTime() - new Date(a.date).getTime();
          case 'votes':
            return b.votes - a.votes;
          default:
            return 0;
        }
      });

    // Reinsert questions with a typedIndex at their original positions
    for (const id of ids) {
      const question = entities[id];
      if (question.typedIndex !== undefined) {
        // Find and remove the question from its current position
        sortedQuestions = sortedQuestions.filter((q) => q.id !== id);
        // Insert it back at its typedIndex
        sortedQuestions.splice(question.typedIndex, 0, question);
      }
    }

    return sortedQuestions;
  }
);

export const selectQuestionById = (state: RootState, id: string) => state.qa.questions.entities[id];

export const selectAnswerById = (state: RootState, id: string): Answer | undefined =>
  state.qa.answers.entities[id];

export const selectAnswersByQuestion = createSelector(
  [
    selectQuestionEntities,
    selectAnswerEntities,
    (state: RootState, questionId: string) => questionId,
  ],
  (entities, answers, questionId) => {
    const question = entities[questionId];

    const result: Answer[] = [];

    for (const id of question.answers) {
      const answer = answers[id];

      // omit active live answers
      if (!answer.live || (answer.live && !answer.active)) {
        result.push(answer);
      }
    }

    return result;
  }
);

export const selectQAUnreadCount = (state: RootState) => state.qa.unreadCount;

export const selectQAExportProcessing = (state: RootState) => state.qa.exportProcessing;

export const selectQAUpvoteEnabled = (state: RootState) => state.qa.upvoteEnabled;

export default qaSlice.reducer;
