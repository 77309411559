import { createAction } from '@reduxjs/toolkit';
import { FilesRawFile, FileOpenedPayload, SignalingFileOpenedPayload } from 'features/files/types';

export const signalingFilesReceived = createAction<{ files: FilesRawFile[] }>(
  'signaling/filesReceived'
);

export const signalingFileOpened = createAction<SignalingFileOpenedPayload>('signaling/fileOpened');
export const signalingFileClosed = createAction('signaling/fileClosed');

export const fileOpenRequested = createAction<FileOpenedPayload>(
  'contentLibrary/fileOpenRequested'
);
