import { call } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { ContentLibraryManager } from 'utils/content-library/ContentLibraryManager';
import { cancelContentLibraryFileProcessingRequested } from 'features/content-library/actions';

export function* cancelContentLibraryFileSaga(
  action: ReturnType<typeof cancelContentLibraryFileProcessingRequested>
) {
  yield call(SignalingSocket.send, { event: 'deleteLibraryFile', data: action.payload });
  yield call(ContentLibraryManager.cancelUpload, action.payload.id);
}
