import ToolbarMenuItem from 'features/toolbar/ToolbarMenuItem/ToolbarMenuItem';
import { useTranslation } from 'react-i18next';
import { useSettingsControl } from 'features/toolbar/Toolbar/controls/settings/useSettingsControl';

const SettingsControl = () => {
  const { t } = useTranslation('room');

  const { openSettings, icon } = useSettingsControl();

  return (
    <ToolbarMenuItem
      id="settings"
      overlay={t('toolbar.settings')}
      ariaLabel={t('toolbar.settings')}
      icon={icon}
      onClick={openSettings}
    />
  );
};

export default SettingsControl;
