import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  answerQuestionRequested,
  askQuestionRequested,
  cancelLiveAnswerRequested,
  deleteAnswerRequested,
  deleteQuestionRequested,
  questionAnswerTypeResetRequested,
  removeUpvoteQuestionRequested,
  signalingAnswerDeleted,
  signalingQuestionAnswered,
  signalingQuestionAsked,
  signalingQuestionDeleted,
  signalingQuestionLiveAnswerCancelled,
  signalingQuestionLiveAnswerStarted,
  signalingQuestionLiveAnswerStopped,
  startLiveAnswerRequested,
  stopLiveAnswerRequested,
  upvoteQuestionRequested,
} from 'features/qa/actions';
import { askQuestionSaga } from 'features/qa/sagas/askQuestionSaga';
import { resetQuestionAnswerSaga } from 'features/qa/sagas/resetQuestionAnswerSaga';
import { deleteQuestionSaga } from 'features/qa/sagas/deleteQuestionSaga';
import { deleteAnswerSaga } from 'features/qa/sagas/deleteAnswerSaga';
import { cancelLiveAnswerSaga } from 'features/qa/sagas/cancelLiveAnswerSaga';
import { startLiveAnswerSaga } from 'features/qa/sagas/startLiveAnswerSaga';
import { stopLiveAnswerSaga } from 'features/qa/sagas/stopLiveAnswerSaga';
import { onLiveAnswerStartedSaga } from 'features/qa/sagas/onLiveAnswerStartedSaga';
import { onLiveAnswerStoppedSaga } from 'features/qa/sagas/onLiveAnswerStoppedSaga';
import { onQuestionAskedSaga } from 'features/qa/sagas/onQuestionAskedSaga';
import { answerQuestionSaga } from 'features/qa/sagas/answerQuestionSaga';
import { onQuestionAnsweredSaga } from 'features/qa/sagas/onQuestionAnsweredSaga';
import { onQuestionDeletedSaga } from 'features/qa/sagas/onQuestionDeletedSaga';
import { onAnswerDeletedSaga } from 'features/qa/sagas/onAnswerDeletedSaga';
import { upvoteQuestionSaga } from 'features/qa/sagas/upvoteQuestionSaga';
import { removeUpvoteQuestionSaga } from 'features/qa/sagas/removeUpvoteQuestionSaga';
import { exportQuestionsSaga } from 'features/qa/sagas/exportQuestionsSaga';
import { exportQuestionsRequested } from 'features/qa/qaSlice';

export default function* qaWatcher() {
  yield takeEvery(askQuestionRequested, askQuestionSaga);
  yield takeEvery(signalingQuestionAsked, onQuestionAskedSaga);

  yield takeEvery(upvoteQuestionRequested, upvoteQuestionSaga);
  yield takeEvery(removeUpvoteQuestionRequested, removeUpvoteQuestionSaga);

  yield takeEvery(answerQuestionRequested, answerQuestionSaga);
  yield takeEvery(questionAnswerTypeResetRequested, resetQuestionAnswerSaga);

  yield takeEvery(signalingQuestionAnswered, onQuestionAnsweredSaga);

  yield takeEvery(deleteQuestionRequested, deleteQuestionSaga);
  yield takeEvery(signalingQuestionDeleted, onQuestionDeletedSaga);

  yield takeEvery(deleteAnswerRequested, deleteAnswerSaga);
  yield takeEvery(signalingAnswerDeleted, onAnswerDeletedSaga);

  yield takeEvery(startLiveAnswerRequested, startLiveAnswerSaga);
  yield takeEvery(stopLiveAnswerRequested, stopLiveAnswerSaga);
  yield takeEvery(cancelLiveAnswerRequested, cancelLiveAnswerSaga);

  yield takeEvery(signalingQuestionLiveAnswerStarted, onLiveAnswerStartedSaga);
  yield takeEvery(signalingQuestionLiveAnswerCancelled, onLiveAnswerStoppedSaga);
  yield takeEvery(signalingQuestionLiveAnswerStopped, onLiveAnswerStoppedSaga);

  yield takeLatest(exportQuestionsRequested, exportQuestionsSaga);
}
