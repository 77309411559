import { Dialog, DialogTitle, DialogContent, DialogContentText } from 'components/Dialog';
import { BroadcastSetupReason } from 'features/room/types';
import { useTranslation } from 'react-i18next';
import { UserName } from 'features/users/types';

type PromptPermissionsProps = {
  open: boolean;
  reason: BroadcastSetupReason;
  username: UserName;
  roomName?: string;
};

const PromptPermissionsModal = ({ open, username, reason, roomName }: PromptPermissionsProps) => {
  const { t } = useTranslation('room');
  const renderText = () => {
    if (reason === 'breakout') {
      return t('broadcast_setup.prompt_media_permissions.description_breakout', { roomName });
    }

    return username
      ? t('broadcast_setup.prompt_media_permissions.description', { username })
      : t('broadcast_setup.prompt_media_permissions.description_unnamed');
  };

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      aria-labelledby="prompt-media-permissions-title"
      aria-describedby="prompt-media-permissions-description"
      disableEscapeKeyDown
    >
      <DialogTitle id="prompt-media-permissions-title">
        {t('broadcast_setup.prompt_media_permissions.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="prompt-media-permissions-description">
          {renderText()}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default PromptPermissionsModal;
