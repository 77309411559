import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'features/modal/modalSlice';
import { Trans, useTranslation } from 'react-i18next';
import { DialogContent, DialogContentText } from '@mui/material';
import { Dialog, DialogTitle } from 'components/Dialog';

const ScreensharePermissionsError = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('modals');

  const hideModal = () => dispatch(closeModal('screensharePermissionsError'));

  return (
    <Dialog open aria-labelledby="audio-error-dialog" onClose={hideModal} maxWidth="xs">
      <DialogTitle id="audio-error-dialog" onClose={hideModal}>
        {t('screenshare_blocked.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Trans
            i18nKey="modals:screenshare_blocked.description"
            t={t}
            components={[
              // eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content
              <a
                href="x-apple.systempreferences:com.apple.preference.security?Privacy_ScreenCapture"
                rel="noopener noreferrer"
              />,
            ]}
          />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default ScreensharePermissionsError;
