import { useTranslation } from 'react-i18next';
import ToolbarMenuItem from 'features/toolbar/ToolbarMenuItem/ToolbarMenuItem';
import { useParticipantsControl } from 'features/toolbar/Toolbar/controls/participants/useParticipantsControl';
import React from 'react';
import { ToolbarMenuItemWrapperProps } from 'features/toolbar/types';

const ParticipantsControl = ({ onClick }: ToolbarMenuItemWrapperProps) => {
  const { t } = useTranslation('room');

  const { active, icon, togglePanel, totalParticipants, isFeatureAvailable } =
    useParticipantsControl();

  if (!isFeatureAvailable) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(event);
    togglePanel();
  };

  return (
    <ToolbarMenuItem
      id="participants"
      overlay={t('toolbar.participants')}
      ariaLabel={t('toolbar.participants')}
      icon={icon}
      badgeContent={totalParticipants}
      active={active}
      onClick={handleClick}
    />
  );
};

export default ParticipantsControl;
