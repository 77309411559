import Button from '@mui/material/Button';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal, selectActiveModalData } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, DialogContent, DialogActions } from 'components/Dialog';
import { DialogContentText } from '@mui/material';

const BroadcasterLimitReachedModal = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const modalData = useAppSelector(selectActiveModalData);

  const hideModal = () => dispatch(closeModal('broadcasterLimitReached'));

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="broadcaster-limit-reached-dialog"
      aria-describedby="broadcaster-limit-reached-description"
      onClose={hideModal}
      open
    >
      <DialogTitle id="broadcaster-limit-reached-dialog" onClose={hideModal}>
        {t('broadcaster_limit_reached.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="broadcaster-limit-reached-description">
          {t('broadcaster_limit_reached.description', { count: modalData.limit })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={hideModal} color="grayscale">
          {t('broadcaster_limit_reached.btn_close_label')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BroadcasterLimitReachedModal;
