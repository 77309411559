import Icon from 'components/Icon';
import { styleConstants } from 'theme/styleConstants';
import { lighten, styled } from '@mui/material/styles';
import NoStylesButton from 'components/NoStylesButton';
import { ButtonProps } from '@mui/material';
import React from 'react';

const Root = styled(NoStylesButton)(({ theme }) => {
  const bgColor = '#25D366';

  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: styleConstants.featureBadge.width,
    height: styleConstants.featureBadge.height,
    borderRadius: '8px',
    backgroundColor: bgColor,
    cursor: 'pointer',
    transition: theme.transitions.create(['background-color']),

    fontSize: 28,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: lighten(bgColor, theme.palette.action.focusOpacity),
    },
    '&:focus-visible': {
      backgroundColor: lighten(bgColor, theme.palette.action.focusOpacity),
    },
  };
});

const E2eeBadge = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => (
  <Root {...props} ref={ref}>
    <Icon
      name="e2ee-1"
      sx={{
        fontSize: 32,
      }}
    />
  </Root>
));

export default E2eeBadge;
