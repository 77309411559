import { call } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { askQuestionRequested } from 'features/qa/actions';

export function* askQuestionSaga(action: ReturnType<typeof askQuestionRequested>) {
  yield call(SignalingSocket.send, {
    event: 'askQuestion',
    data: {
      question: action.payload,
    },
  });
}
