import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal, selectActiveModalData } from 'features/modal/modalSlice';
import { Trans, useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { useBreakoutRooms } from 'features/breakout-rooms/useBreakoutRooms';
import { deleteRoomRequested } from 'features/breakout-rooms/actions';
import { selectBreakoutRoom } from 'features/breakout-rooms/breakoutRoomsSlice';

const DeleteBreakoutRoomConfirmation = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const modalData = useAppSelector(selectActiveModalData);
  const roomId = modalData.roomId as string;

  const room = useAppSelector((state) => selectBreakoutRoom(state, roomId));

  const { isBreakoutsAvailable, status } = useBreakoutRooms();

  const shouldClose = !isBreakoutsAvailable || !room || status === 'started';

  useEffect(() => {
    if (shouldClose) {
      dispatch(closeModal('deleteBreakoutRoom'));
    }
  }, [shouldClose, dispatch]);

  const handleCancel = () => {
    dispatch(closeModal('deleteBreakoutRoom'));
  };

  const handleOk = () => {
    dispatch(deleteRoomRequested(roomId));
    dispatch(closeModal('deleteBreakoutRoom'));
  };

  return (
    <ConfirmationDialog
      open
      title={t('breakout_rooms.delete_breakout_room.title')}
      description={
        <Trans
          i18nKey="modals:breakout_rooms.delete_breakout_room.description"
          t={t}
          values={{
            name: room?.name,
          }}
          components={{
            bold: <strong />,
          }}
        />
      }
      cancelText={t('breakout_rooms.delete_breakout_room.btn_cancel_label')}
      okText={t('breakout_rooms.delete_breakout_room.btn_ok_label')}
      onOk={handleOk}
      onCancel={handleCancel}
      hideCloseButton
      preventClose
    />
  );
};

export default DeleteBreakoutRoomConfirmation;
