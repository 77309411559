import { createAction } from '@reduxjs/toolkit';
import {
  EventBusReceiveMessage,
  InteractionKind,
  RedirectPayload,
  SignalingDisconnectPayload,
} from 'features/application/types';
import { SignalingPong, SystemUpdatePayload } from 'services/signaling';

export const redirect = createAction<RedirectPayload>('application/redirect');

export const RTCClientInitialized = createAction('application/RTCClientInitialized');

export const signalingConnected = createAction('signaling/connected');
export const pong = createAction<SignalingPong>('signaling/pong');

export const systemUpdate = createAction<SystemUpdatePayload>('signaling/systemUpdate');

export const signalingDisconnected =
  createAction<SignalingDisconnectPayload>('signaling/disconnected');
export const signalingReconnected = createAction('signaling/signalingReconnected');

export const eventBusCommandReceived = createAction<EventBusReceiveMessage>(
  'eventBus/commandReceived'
);

export const interactionTriggered = createAction<InteractionKind>(
  'application/interactionTriggered'
);
