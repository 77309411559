import { APIErrorScope, APIErrorType, ErrorResponse } from 'utils/http';

class ApiError<D = any> extends Error {
  scope?: APIErrorScope;

  type?: APIErrorType;

  details?: D;

  constructor(payload?: ErrorResponse | string, ...params: any[]) {
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.name = this.constructor.name;

    if (typeof payload === 'object') {
      this.message = payload.message;
      this.scope = payload.error;
      this.type = payload.error_type;
      this.details = payload.error_details;
    } else {
      this.message = payload ?? '';
    }
  }
}

export default ApiError;
