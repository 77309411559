import { EventUser } from 'services/signaling';

export function isPoll(poll: unknown): poll is Poll {
  return poll !== null && typeof poll === 'object' && 'choices' in poll;
}

export type PollsActiveView = 'list' | 'details';

export interface PollChoice {
  id: string;
  text: string;
  votersCount: number;
}

export interface PollVote {
  voter: EventUser;
  choices: number[];
}

export interface Poll {
  id: string;
  sessionId: string;
  question: string;
  choices: PollChoice[];
  multipleChoices: boolean;
  anonymous: boolean;
  active: boolean;
  ended: boolean;
  shared: boolean;
  editable: boolean;
  votersCount: number;
  voted?: number[];
  user: EventUser | null;
}

export type PollEntities = Record<string, Poll>;
export type PollIds = string[];

export type VotedPoll = Record<string, boolean>;

type UnreadUpdateType = 'shared' | 'launched';

export interface PollActiveView {
  name: PollsActiveView;
  data: Record<string, any>;
}

export type DetailedResultVotes = Record<string, EventUser[]>;

export interface DetailedResult {
  status: 'idle' | 'loading' | 'success' | 'error';
  votes: DetailedResultVotes;
}

export type LocalVotedPolls = Record<string, VotedPoll>;

export interface PollsState {
  panelOpen: boolean;
  exportProcessing: boolean;
  unreadUpdates: UnreadUpdateType[];
  activePollId: string | null;
  activePollSessionId: string | null;
  activeView: PollActiveView;
  entities: PollEntities;
  ids: PollIds;
  localVotedPolls: LocalVotedPolls;
  detailedResults: Record<string, DetailedResult>;
}

export interface PollPayload {
  question: string;
  choices: string[];
  multipleChoices: boolean;
  anonymous: boolean;
}

export interface EditPollPayload extends PollPayload {
  id: string;
}

export interface VotePayload {
  id: string;
  choices: number[];
}

export interface VoteResponse extends VotePayload {
  local: boolean;
  voter?: EventUser;
}

export interface DetailedPollResultVote {
  voter: EventUser;
  choices: number[];
}

export interface DetailedPollResultsPayload {
  id: string;
  votes: DetailedPollResultVote[];
}
