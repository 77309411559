import React from 'react';
import Icon from 'components/Icon';
import { Box, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import NoStylesButton from 'components/NoStylesButton';
import Popper from 'components/Popper';

const ActionButton = styled(NoStylesButton, {
  shouldForwardProp: (prop) => prop !== 'processing',
})<{ processing?: boolean }>(({ processing, theme }) => ({
  marginBottom: theme.spacing(1),
  color: theme.palette.primary.main,
  cursor: 'pointer',
  ...(processing && {
    cursor: 'default',
    pointerEvents: 'none',
  }),
  '&:focus': {
    borderRadius: 4,
    boxShadow: `${alpha(theme.palette.primary.main, 0.35)} 0 0 0 3px`,
  },
}));

const TooltipTrigger = styled(NoStylesButton)(({ theme }) => ({
  alignSelf: 'flex-start',
  margin: '-5px',
  color: theme.palette.primary.main,
  fontSize: '32px',
  lineHeight: 1,
  cursor: 'pointer',
  '&:focus .Icon-root': {
    borderRadius: 4,
    boxShadow: `${alpha(theme.palette.primary.main, 0.35)} 0 0 0 3px`,
  },
}));

type MicrophoneTestProps = {
  id: string;
  iconLabel: string;
  tooltip: string | React.ReactElement;
  helpText?: string;
  children: React.ReactNode;
  onClick: () => void;
  processing?: boolean;
};

const DeviceTest = ({
  helpText,
  children,
  tooltip,
  id,
  onClick,
  processing,
  iconLabel,
}: MicrophoneTestProps) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
    }}
  >
    <div>
      <ActionButton type="button" processing={processing} onClick={onClick}>
        {children}
      </ActionButton>
      {helpText && (
        <Typography
          component="span"
          sx={{ display: 'block', color: 'text.secondary', fontSize: '0.75rem' }}
        >
          {helpText}
        </Typography>
      )}
    </div>
    <Popper
      id={id}
      placement="right"
      content={typeof tooltip === 'string' ? <Typography>{tooltip}</Typography> : tooltip}
    >
      <TooltipTrigger type="button">
        <Icon name="warning" aria-label={iconLabel} />
      </TooltipTrigger>
    </Popper>
  </Box>
);

export default DeviceTest;
