import { Action } from '@reduxjs/toolkit';
import { put, select } from 'redux-saga/effects';
import { logger } from 'utils/logger';
import { User } from 'features/users/types';
import { selectUserById } from 'features/users/usersSlice';
import { PermissionStatus } from 'features/user-media/types';
import { selectMediaDevicePermissions } from 'features/user-media/userMediaSlice';
import { addActionNotification } from 'features/notifications/addActionNotification';
import i18n from 'i18n';
import { remoteVideoUnmuteAccepted, videoUnmuteAsked } from 'features/streaming/actions';

export function* onVideoUnmuteAskedSaga({
  payload: { initiator },
}: ReturnType<typeof videoUnmuteAsked>) {
  logger.remote({ tier: 1 }).info(`User was asked to turn on camera by user id=${initiator.id}`);

  const user: User = yield select(selectUserById, initiator.id);
  const camPermissions: PermissionStatus = yield select(selectMediaDevicePermissions, 'videoinput');

  if (camPermissions === 'granted') {
    yield put(
      addActionNotification({
        content: i18n.t('notifications:unmute_remote_video_request.content', {
          username: user.name,
        }),
        cancelText: i18n.t('notifications:unmute_remote_video_request.btn_cancel_label'),
        okText: i18n.t('notifications:unmute_remote_video_request.btn_ok_label'),
        okAction: remoteVideoUnmuteAccepted(),
        initiatorId: initiator.id,
        interaction: 'unmuteVideo',
        scope: ['broadcast'],
      }) as unknown as Action
    );
  } else {
    yield put(
      addActionNotification({
        content: i18n.t('notifications:unmute_remote_video_request_no_permissions.content', {
          username: user.name,
        }),
        okText: i18n.t('notifications:unmute_remote_video_request_no_permissions.btn_ok_label'),
        initiatorId: initiator.id,
      }) as unknown as Action
    );
  }
}
