import { call } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { createRoomsRequested } from 'features/breakout-rooms/actions';

export function* createBreakoutRoomsSaga(action: ReturnType<typeof createRoomsRequested>) {
  yield call(SignalingSocket.send, {
    event: 'createBreakoutRooms',
    data: {
      number: action.payload.number,
      autoAssign: action.payload.autoAssign,
    },
  });
}
