import {
  messageSent,
  messagesRead,
  selectActiveChatTab,
  selectPrivateChatActiveView,
  selectPrivateGroupChat,
  unreadMessageCounterIncreased,
} from 'features/chat/chatSlice';
import { ChatTab, PrivateChatActiveViewState, PrivateGroupChatState } from 'features/chat/types';
import { selectToolbarPanel } from 'features/toolbar/toolbarSlice';
import { ToolbarPanelType } from 'features/toolbar/types';
import { User } from 'features/users/types';
import { selectLocalUser } from 'features/users/usersSlice';
import { call, put, select } from 'redux-saga/effects';
import { getPrivateChatTarget, PrivateChatTarget } from 'features/chat/utils/getPrivateChatTarget';

function* updateCounter(
  targetChatId: string,
  chatPanelOpen: boolean,
  activeChatTab: ChatTab,
  messageId: string
) {
  const activePrivateChatView: PrivateChatActiveViewState = yield select(
    selectPrivateChatActiveView
  );

  const targetChatOpened =
    activePrivateChatView.name === 'personal' && activePrivateChatView.data.id === targetChatId;

  const targetChatVisible = chatPanelOpen && activeChatTab === ChatTab.private && targetChatOpened;

  if (targetChatVisible) {
    yield put(messagesRead({ messages: [messageId], chat: targetChatId }));
  } else {
    yield put(unreadMessageCounterIncreased({ key: targetChatId, modifier: 1 }));
  }
}

export function* updateMessageCounterSaga(action: ReturnType<typeof messageSent>) {
  const openPanel: ToolbarPanelType | null = yield select(selectToolbarPanel);

  const chatPanelOpen = openPanel === 'chat';
  const activeChatTab: ChatTab = yield select(selectActiveChatTab);
  const publicChatVisible = chatPanelOpen && activeChatTab === ChatTab.public;

  const { targetUser, privateGroup, user, id } = action.payload;

  if (privateGroup) {
    const privateGroupChat: PrivateGroupChatState = yield select(selectPrivateGroupChat);
    const targetChatId = privateGroupChat.id;

    yield call(updateCounter, targetChatId, chatPanelOpen, activeChatTab, id);
  } else if (targetUser) {
    const localUser: User = yield select(selectLocalUser);

    const { targetChatId }: PrivateChatTarget = yield call(getPrivateChatTarget, {
      localUserId: localUser.id,
      localUserExternalId: localUser.externalId,
      user,
      targetUser,
    });

    yield call(updateCounter, targetChatId, chatPanelOpen, activeChatTab, id);
  } else if (publicChatVisible) {
    yield put(messagesRead({ messages: [action.payload.id], chat: 'public' }));
  } else {
    yield put(unreadMessageCounterIncreased({ key: 'public', modifier: 1 }));
  }
}
