import { call, put, select } from 'redux-saga/effects';
import { notification } from 'features/notifications/toast/notification';
import i18n from 'i18n';
import { selectIsRecorderSession } from 'features/recorder/recorderSlice';
import { selectShowCaptionsInRecordings } from 'features/layout/features/config/configSlice';
import { captionsToggled } from 'features/transcripts/slices/captions/captionsSlice';

export function* onTranscriptionStartedSaga() {
  const isRecorderSession: boolean = yield select(selectIsRecorderSession);
  if (isRecorderSession) {
    const showCaptions: boolean = yield select(selectShowCaptionsInRecordings);
    if (showCaptions) {
      yield put(captionsToggled(true));
    }
  }

  yield call(notification, i18n.t('notifications:transcription_enabled'));
}
