// @ts-nocheck
import { userAgentDetails } from 'utils/userAgentDetails';

// This function will return 1 if version1 is greater than version2, -1 if version1 is less than version2, and 0 if they are equal.
function compareVersions(version1, version2) {
  return version1.localeCompare(version2, undefined, { numeric: true, sensitivity: 'base' });
}

function supportsEncodedTransforms() {
  return Boolean(window.RTCRtpScriptTransform);
}

function supportsInsertableStreams() {
  return Boolean(
    RTCRtpSender &&
      (RTCRtpSender.prototype.createEncodedStreams ||
        (RTCRtpSender.prototype.createEncodedAudioStreams &&
          RTCRtpSender.prototype.createEncodedVideoStreams))
  );
}

export function browserSupportsE2EE() {
  const { browser } = userAgentDetails();

  if (
    browser.name?.toLowerCase() === 'safari' &&
    browser.version &&
    compareVersions(browser.version, '17.2.0') === -1
  ) {
    return false;
  }

  return supportsEncodedTransforms() || supportsInsertableStreams();
}
