import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectLocalAudioBroadcasting,
  selectLocalPublisherJoined,
} from 'features/streaming/streamingSlice';
import { selectMediaDevicePermissions } from 'features/user-media/userMediaSlice';
import { openModal } from 'features/modal/modalSlice';
import { localAudioToggled } from 'features/streaming/actions';
import { useTranslation } from 'react-i18next';

export const useLocalAudio = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('room');

  const isAudioBroadcasting = useAppSelector(selectLocalAudioBroadcasting);
  const isConnectedToFeed = useAppSelector(selectLocalPublisherJoined);

  const micPermissions = useAppSelector((state) =>
    selectMediaDevicePermissions(state, 'audioinput')
  );

  const isPermissionsGranted = micPermissions === 'granted';

  const makeMicColor = () => {
    if (!isPermissionsGranted) {
      return 'error.main';
    }

    return 'common.white';
  };

  const renderAudioTooltipText = () => {
    if (isAudioBroadcasting) {
      return t('tooltips.mute_local_audio');
    }

    return t('tooltips.unmute_local_audio');
  };

  const toggleMic = () => {
    if (!isPermissionsGranted) {
      dispatch(openModal('audioPermissionsError'));
    } else {
      dispatch(localAudioToggled());
    }
  };

  return {
    micColor: makeMicColor,
    audioTooltipText: renderAudioTooltipText(),
    toggleMic,
    isAudioBroadcasting,
    isPermissionsGranted,
    isConnectedToFeed,
    micIcon: isAudioBroadcasting ? 'mic' : 'mute',
  };
};
