import { Button } from '@mui/material';
import { alpha, getContrastRatio, styled } from '@mui/material/styles';

export const TopbarControl = styled(Button)(({ theme }) => {
  const bgColor =
    getContrastRatio(theme.palette.common.white, theme.room.background) >
    theme.palette.contrastThreshold
      ? alpha(theme.palette.common.white, 0.2)
      : alpha(theme.palette.action.active, theme.palette.action.hoverOpacity);

  return {
    color: theme.palette.getContrastText(theme.room.background),
    whiteSpace: 'nowrap',
    marginLeft: 'auto',
    marginRight: theme.spacing(2),
    '&:hover': {
      backgroundColor: bgColor,
    },
    '&:focus': {
      backgroundColor: bgColor,
    },
    '&.Mui-focusVisible': {
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      color: theme.palette.grey['600'],
    },
  };
});
