import { call, put, select } from 'redux-saga/effects';
import { signalingPollLaunched } from 'features/polls/actions';
import { closeSharedPollNotificationSaga } from 'features/polls/sagas/closeSharedPollNotificationSaga';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { ToolbarPanelType } from 'features/toolbar/types';
import { selectToolbarPanel, toolbarPanelOpened } from 'features/toolbar/toolbarSlice';
import { Action } from '@reduxjs/toolkit';
import { addActionNotification } from 'features/notifications/addActionNotification';
import i18n from 'i18n';
import { FeatureFlag } from 'features/feature-set/types';
import { selectIsRecorderSession } from 'features/recorder/recorderSlice';

export function* onPollLaunchedSaga(action: ReturnType<typeof signalingPollLaunched>) {
  yield call(closeSharedPollNotificationSaga, action.payload.data.id);

  const isRecorderSession: boolean = yield select(selectIsRecorderSession);
  // show the polls panel if it is a recorder session
  if (isRecorderSession) {
    yield put(toolbarPanelOpened('polls'));

    return;
  }

  const canTakePolls: boolean = yield call(hasPermissionsSaga, PermissionTypes.takePolls);
  if (!canTakePolls) {
    return;
  }

  const openedPanel: ToolbarPanelType = yield select(selectToolbarPanel);
  if (openedPanel === 'polls') {
    // show only if the polls panel is not opened
    return;
  }

  const { initiator, data } = action.payload;

  const actionNotificationThunk: Action = yield call(
    addActionNotification,
    {
      content: i18n.t('notifications:polls.request_to_vote.content', {
        username: initiator.name,
      }),
      okText: i18n.t('notifications:polls.request_to_vote.btn_ok_label'),
      okAction: toolbarPanelOpened('polls'),
      initiator,
      scope: [FeatureFlag.polls, 'poll_request_vote'],
    },
    {
      toastId: `poll_request_vote_${data.id}`,
    }
  );

  yield put(actionNotificationThunk);
}
