import {
  paginationPageChanged,
  selectCurrentPage,
} from 'features/layout/features/pagination/paginationSlice';
import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import Icon from 'components/Icon';
import { styled } from '@mui/material/styles';
import { styleConstants } from 'theme/styleConstants';
import { logger } from 'utils/logger';

interface PaginationTileProps {
  direction: 'next' | 'prev';
}

const ArrowIcon = styled(Icon, {
  shouldForwardProp: (prop) => prop !== 'direction',
})<{ direction: PaginationTileProps['direction'] }>(({ theme, direction }) => ({
  color: theme.palette.getContrastText(theme.room.background),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '2rem',
  transform: direction === 'prev' ? 'rotate(180deg)' : 'none',
}));

const ClickableArea = styled('div')<{ direction: 'next' | 'prev' }>(({ direction, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  zIndex: 2,
  height: '100%',
  width: `${styleConstants.layout.pagination.controlWidth}px`,
  background: theme.room.background,
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  [direction === 'next' ? 'right' : 'left']: '0',
}));

export const PaginationControl = ({ direction }: PaginationTileProps) => {
  const dispatch = useAppDispatch();
  const page = useAppSelector(selectCurrentPage);

  const handleClick = useCallback(() => {
    logger
      .remote({ action: true })
      .info(`Paging from page ${page} to ${page + direction === 'next' ? 1 : -1}`);

    if (direction === 'next') {
      dispatch(paginationPageChanged(page + 1));
    } else {
      dispatch(paginationPageChanged(page - 1));
    }
  }, [page, direction, dispatch]);

  return (
    <ClickableArea direction={direction} onClick={handleClick}>
      <ArrowIcon name="forward" direction={direction} />
    </ClickableArea>
  );
};
