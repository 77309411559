import { call, select } from 'redux-saga/effects';
import { HasPermissionsOptions } from 'features/permissions/utils/checkPermissions';
import { selectLocalUser } from 'features/users/usersSlice';
import { PermissionsState, PermissionTypes } from 'features/permissions/types';
import { User } from 'features/users/types';
import { refinePermissions } from 'features/permissions/utils/refinePermissions';
import { selectPermissionsState } from 'features/permissions/permissionsSlice';

export function* hasPermissionsSaga(
  permissions: PermissionTypes | PermissionTypes[],
  options: HasPermissionsOptions = {}
) {
  const permissionsState: PermissionsState = yield select(selectPermissionsState);
  const localUser: User = yield select(selectLocalUser);

  const granted: boolean = yield call(refinePermissions, permissions, {
    permissionsState,
    localUser,
    ...options,
  });

  return granted;
}
