import { selectVirtualBackgroundStatus } from 'features/virtual-backgrounds/selectors';
import { RTCClient } from 'utils/webrtc';
import { BackgroundOptions } from 'utils/webrtc/VirtualBackground';
import { AppThunk } from 'store/store';
import {
  virtualBackgroundChanged,
  virtualBackgroundChangeStarted,
  virtualBackgroundFailure,
} from 'features/virtual-backgrounds/virtualBackgroundsSlice';
import { logger } from 'utils/logger';

export const activateVirtualBackground =
  (options: BackgroundOptions): AppThunk<Promise<MediaStream | void>> =>
  async (dispatch, getState) => {
    const status = selectVirtualBackgroundStatus(getState());
    if (status === 'updating') {
      return undefined;
    }

    dispatch(virtualBackgroundChangeStarted());

    try {
      const stream = await RTCClient.publishingFeed.virtualBackground.activate(options);

      logger.remote({ tier: 1 }).log('The user applied a virtual background');

      dispatch(
        virtualBackgroundChanged({
          type: options.type,
          value: options.value,
        })
      );

      return stream;
    } catch (error) {
      dispatch(virtualBackgroundFailure());

      return undefined;
    }
  };
