import Icon from 'components/Icon';
import { styleConstants } from 'theme/styleConstants';
import { styled, SxProps } from '@mui/material/styles';
import React from 'react';
import Tooltip from 'components/Tooltip';
import { useTranslation } from 'react-i18next';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: styleConstants.featureBadge.width,
  height: styleConstants.featureBadge.height,
  borderRadius: '8px',
  backgroundColor: theme.palette.error.main,
  color: theme.palette.common.white,
}));

type TranscriptionBadgeProps = React.ComponentPropsWithoutRef<'div'> & {
  sx?: SxProps;
};

const TranscriptionBadge = (props: TranscriptionBadgeProps) => {
  const { t } = useTranslation('room');

  return (
    <Tooltip title={t('topbar.transcripts_badge_tooltip')} placement="bottom">
      <Root {...props} data-testid="transcription-badge">
        <Icon
          name="microphone-text-2"
          sx={{
            fontSize: 32,
          }}
        />
      </Root>
    </Tooltip>
  );
};

export default TranscriptionBadge;
