import { styled } from '@mui/material/styles';

export const Video = styled('video', {
  shouldForwardProp: (prop) => prop !== 'mirror',
})<{ mirror?: boolean }>(({ theme, mirror }) => ({
  position: 'absolute',
  top: 0,
  left: '50%',
  pointerEvents: 'none',
  height: '100%',
  width: '100%',
  transform: mirror ? 'scaleX(-1) translateX(50%)' : 'translateX(-50%)',
  '&.fitment-crop': {
    objectFit: 'cover',
  },
  '&.fitment-fit': {
    backgroundColor: theme.room.tileBackground,
    objectFit: 'contain',
  },
}));
