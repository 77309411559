import { NotificationId } from 'features/notifications/types';
import { call, put } from 'redux-saga/effects';
import { Action } from '@reduxjs/toolkit';
import { closeActionNotification } from 'features/notifications/thunks/closeActionNotification';

export function* closeRequestVoteNotificationSaga(id: NotificationId) {
  const cleanupNotificationThunk: Action = yield call(
    closeActionNotification,
    `poll_request_vote_${id}`
  );
  yield put(cleanupNotificationThunk);
}
