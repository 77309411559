import React from 'react';
import { ItemProps } from 'react-virtuoso';
import { BreakoutsUsersVirtualListItemType } from 'features/breakout-rooms/types';
import { ListItemButtonProps } from '@mui/material';
import { BreakoutsUsersListContext } from 'features/breakout-rooms/components/breakouts-users-selector/BreakoutsUsersList';
import { StyledParticipantListItem } from 'components/participants-list/styled';

type BreakoutsUsersListItemProps = ListItemButtonProps<
  'li',
  ItemProps<BreakoutsUsersVirtualListItemType> & {
    context?: BreakoutsUsersListContext;
  }
>;

const BreakoutsUsersListItem = React.forwardRef<HTMLLIElement, BreakoutsUsersListItemProps>(
  ({ children, item, context, ...props }, ref) => (
    <StyledParticipantListItem {...props} ref={ref}>
      {children}
    </StyledParticipantListItem>
  )
);

export default BreakoutsUsersListItem;
