import { call, select } from 'redux-saga/effects';
import { selectUserById } from 'features/users/usersSlice';
import { User } from 'features/users/types';
import { RTCClient } from 'utils/webrtc';
import i18n from 'i18n';
import { logger } from 'utils/logger';
import { notification } from 'features/notifications/toast/notification';
import { videoMutedRemotely } from 'features/streaming/actions';

export function* onVideoMutedRemotelySaga(action: ReturnType<typeof videoMutedRemotely>) {
  logger
    .remote({ tier: 1 })
    .info(`Camera turned off remotely by user id=${action.payload.initiator.id}`);

  const user: User = yield select(selectUserById, action.payload.initiator.id);
  if (user?.name) {
    yield call(notification, i18n.t('notifications:video_muted_remotely', { username: user.name }));
  } else {
    yield call(notification, i18n.t('notifications:video_muted_remotely_unnamed'));
  }

  yield call(RTCClient.publishingFeed.stopVideoBroadcasting);
}
