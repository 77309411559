import { assignLocalUserToBreakoutRoomSaga } from 'features/breakout-rooms/sagas/assignLocalUserToBreakoutRoomSaga';
import { prepareRoomMovementSaga } from 'features/breakout-rooms/sagas/prepareRoomMovementSaga';
import { takeEvery, takeLatest } from 'redux-saga/effects';
import { createBreakoutRoomsSaga } from 'features/breakout-rooms/sagas/createBreakoutRoomsSaga';
import {
  assignUsersToBreakoutRoomRequested,
  broadcastRoomMessageRequested,
  clearRoomsRequested,
  createRoomsRequested,
  deleteRoomRequested,
  leaveBreakoutRoomRequested,
  renameRoomRequested,
  signalingBreakoutRoomMessageBroadcast,
  signalingBreakoutRoomsCreated,
  signalingBreakoutRoomsReceived,
  signalingBreakoutRoomsStarted,
  signalingBreakoutRoomsStopped,
  splashScreenCloseRequested,
  startBreakoutRoomsRequested,
  stopBreakoutRoomsRequested,
  unassignUserFromBreakoutRoomRequested,
  splashScreenOpenRequested,
  assignLocalUserToBreakoutRoomRequested,
  signalingUserUnassignedFromBreakoutRoom,
  addBreakoutRoomRequested,
} from 'features/breakout-rooms/actions';
import { deleteBreakoutRoomSaga } from 'features/breakout-rooms/sagas/deleteBreakoutRoomSaga';
import { renameBreakoutRoomSaga } from 'features/breakout-rooms/sagas/renameBreakoutRoomSaga';
import { signalingRoomJoined } from 'features/room/actions';
import { onBreakoutRoomCreatedSaga } from 'features/breakout-rooms/sagas/onBreakoutRoomCreatedSaga';
import { onBreakoutRoomReceivedSaga } from 'features/breakout-rooms/sagas/onBreakoutRoomReceivedSaga';
import { onRoomJoinedSaga } from 'features/breakout-rooms/sagas/onRoomJoinedSaga';
import { clearBreakoutRoomsSaga } from 'features/breakout-rooms/sagas/clearBreakoutRoomsSaga';
import { startBreakoutRoomsSaga } from 'features/breakout-rooms/sagas/startBreakoutRoomsSaga';
import { stopBreakoutRoomsSaga } from 'features/breakout-rooms/sagas/stopBreakoutRoomsSaga';
import { onBreakoutRoomsStoppedSaga } from 'features/breakout-rooms/sagas/onBreakoutRoomsStoppedSaga';
import { onBreakoutRoomsStartedSaga } from 'features/breakout-rooms/sagas/onBreakoutRoomsStartedSaga';
import { signalingUserJoined } from 'features/users/actions';
import { onUserJoinedSaga } from 'features/breakout-rooms/sagas/onUserJoinedSaga';
import { assignUsersToBreakoutRoomSaga } from 'features/breakout-rooms/sagas/assignUsersToBreakoutRoomSaga';
import { unassignUsersFromBreakoutRoomSaga } from 'features/breakout-rooms/sagas/unassignUsersFromBreakoutRoomSaga';
import { broadcastMessageSaga } from 'features/breakout-rooms/sagas/broadcastMessageSaga';
import { onMessageBroadcastSaga } from 'features/breakout-rooms/sagas/onMessageBroadcastSaga';
import { onSplashScreenCloseRequestedSaga } from 'features/notifications/sagas/onSplashScreenCloseRequestedSaga';
import { addBreakoutRoomSaga } from 'features/breakout-rooms/sagas/addBreakoutRoomSaga';
import { onLeaveBreakoutRoomRequestedSaga } from './onLeaveBreakoutRoomRequestedSaga';
import { onSplashScreenOpenRequestedSaga } from './onSplashScreenOpenRequestedSaga';
import { onUserUnassignedFromBreakoutRoomSaga } from './onUserUnassignedFromBreakoutRoomSaga';
import { roomMovementRequested } from '../breakoutRoomsSlice';

export default function* breakoutRoomsWatcher() {
  yield takeEvery(createRoomsRequested, createBreakoutRoomsSaga);
  yield takeEvery(deleteRoomRequested, deleteBreakoutRoomSaga);
  yield takeEvery(renameRoomRequested, renameBreakoutRoomSaga);
  yield takeEvery(clearRoomsRequested, clearBreakoutRoomsSaga);
  yield takeEvery(addBreakoutRoomRequested, addBreakoutRoomSaga);

  yield takeEvery(signalingBreakoutRoomsCreated, onBreakoutRoomCreatedSaga);
  yield takeEvery(signalingBreakoutRoomsReceived, onBreakoutRoomReceivedSaga);
  yield takeEvery(signalingRoomJoined, onRoomJoinedSaga);

  yield takeEvery(startBreakoutRoomsRequested, startBreakoutRoomsSaga);
  yield takeEvery(stopBreakoutRoomsRequested, stopBreakoutRoomsSaga);
  yield takeEvery(signalingBreakoutRoomsStarted, onBreakoutRoomsStartedSaga);
  yield takeEvery(signalingBreakoutRoomsStopped, onBreakoutRoomsStoppedSaga);

  yield takeEvery(signalingUserJoined, onUserJoinedSaga);

  yield takeEvery(assignUsersToBreakoutRoomRequested, assignUsersToBreakoutRoomSaga);
  yield takeEvery(unassignUserFromBreakoutRoomRequested, unassignUsersFromBreakoutRoomSaga);
  yield takeEvery(assignLocalUserToBreakoutRoomRequested, assignLocalUserToBreakoutRoomSaga);

  yield takeLatest(roomMovementRequested, prepareRoomMovementSaga);

  yield takeLatest(broadcastRoomMessageRequested, broadcastMessageSaga);
  yield takeEvery(signalingBreakoutRoomMessageBroadcast, onMessageBroadcastSaga);
  yield takeEvery(signalingUserUnassignedFromBreakoutRoom, onUserUnassignedFromBreakoutRoomSaga);

  yield takeEvery(splashScreenOpenRequested, onSplashScreenOpenRequestedSaga);
  yield takeEvery(splashScreenCloseRequested, onSplashScreenCloseRequestedSaga);

  yield takeEvery(leaveBreakoutRoomRequested, onLeaveBreakoutRoomRequestedSaga);
}
