import { call } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { PayloadAction } from '@reduxjs/toolkit';
import { ChangeRolePayload } from 'features/users/types';
import { logger } from 'utils/logger';

export function* changeRoleSaga(action: PayloadAction<ChangeRolePayload>) {
  logger
    .remote({ action: true, tier: 1 })
    .info(
      `The user changed the role of a user with id=${action.payload.id} to ${action.payload.role}`
    );

  yield call(SignalingSocket.send, {
    event: 'changeRole',
    data: {
      id: action.payload.id,
      role: action.payload.role,
    },
  });
}
