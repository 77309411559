import { useCallback } from 'react';
import { useDeviceTest } from 'hooks/useDeviceTest';
import speakersTestSound from 'assets/sounds/speakers-test.wav';
import { logger } from 'utils/logger';
import { useAudio } from './useAudio';

interface PlaySoundOptions {
  url?: string;
  onEnd?: () => void;
  onStart?: () => void;
}

export const useSpeakersTest = () => {
  const { audio } = useAudio();
  const [testStatus, setTestStatus] = useDeviceTest();

  const playTestSound = useCallback(
    async (options: PlaySoundOptions = {}) => {
      if (audio === null || ['processing', 'loading'].includes(testStatus)) {
        return;
      }

      setTestStatus('loading');

      if (options.url) {
        audio.src = options.url;
      } else {
        audio.src = speakersTestSound;
      }

      audio.onplay = () => {
        setTestStatus('processing');
        options.onStart?.();
      };

      audio.onended = () => {
        setTestStatus('idle');
        options.onEnd?.();
      };

      await audio.play();

      logger.remote({ action: true }).info('User tested speakers');
    },
    [audio, testStatus, setTestStatus]
  );

  return { playTestSound, status: testStatus, setTestStatus };
};
