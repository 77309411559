import { eventBus } from 'utils/eventBus';
import { singleton } from 'utils/singleton';

const stringifyEventPayload = (object: any, depth = 0, max_depth = 2) => {
  // change max_depth to see more levels, for a touch event, 2 is good
  if (depth > max_depth) return 'Object';

  const obj: Record<string, any> = {};

  // don't omit properties since we DO want prototype fields as well
  // therefore disabling the rule
  // eslint-disable-next-line guard-for-in
  for (const key in object) {
    let value = object[key];

    if (value instanceof Node) {
      value = { nodeName: value.nodeName };
    } else if (value instanceof Window) {
      value = 'Window';
    } else if (value instanceof Object) {
      value = stringifyEventPayload(value, depth + 1, max_depth);
    }

    obj[key] = value;
  }

  return depth ? obj : JSON.stringify(obj);
};

type UICallbackName = 'leaveSession';

class EventListenerManager {
  storedListeners: Record<string, Record<string, (...args: any[]) => void>> = {};

  UIcallbacks: Partial<Record<UICallbackName, boolean>> = {
    leaveSession: false,
  };

  findOrCreateEventListener = (eventName: string, target: string) => {
    if (this.storedListeners[target]?.[eventName]) {
      return this.storedListeners[target][eventName];
    }

    this.storedListeners[target] ??= {};
    this.storedListeners[target][eventName] = this.sendEventPayload(eventName, target);

    return this.storedListeners[target][eventName];
  };

  removeEventListener = (eventName: string, target: string) => {
    if (this.storedListeners[target]?.[eventName]) {
      delete this.storedListeners[target][eventName];
    }
  };

  addUICallback = (name: UICallbackName) => {
    this.UIcallbacks[name] = true;
  };

  removeUICallback = (name: UICallbackName) => {
    this.UIcallbacks[name] = false;
  };

  UICallbackAction = (name: UICallbackName, handler: () => void) => () => {
    if (this.UIcallbacks[name]) {
      eventBus.sendMessage('UICallback', {}, { name });
    } else {
      handler();
    }
  };

  private sendEventPayload = (eventName: string, target: string) => (event: Event) => {
    eventBus.sendMessage(
      'documentEvent',
      {},
      { eventName, target, payload: stringifyEventPayload(event) }
    );
  };
}

export const SDKEventListenerManager = singleton<EventListenerManager>(
  () => new EventListenerManager()
);
