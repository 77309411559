import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SDKConnected } from 'features/sdk/sdkStateSlice';
import { RootState } from 'store/store';
import {
  ToolbarOrientation,
  ToolbarPanelData,
  ToolbarPanelType,
  ToolbarPlacement,
  ToolbarState,
} from 'features/toolbar/types';
import { authorizeUser } from 'features/room/thunks/authorizeUser';
import { styleConstants } from 'theme/styleConstants';
import { selectIsRecorderSession } from 'features/recorder/recorderSlice';

export const initialState: ToolbarState = {
  visible: true,
  activePanel: null,
  data: {},
  placement: 'left',
  systemPlacement: 'left',
};

export const toolbarSlice = createSlice({
  name: 'toolbar',
  initialState,
  reducers: {
    toolbarPanelOpened: {
      reducer: (state, action: PayloadAction<ToolbarPanelType, string, ToolbarPanelData>) => {
        state.activePanel = action.payload;
        state.data = action.meta;
      },
      prepare: (payload: ToolbarPanelType, meta: ToolbarPanelData = {}) => ({
        payload,
        meta,
      }),
    },
    toolbarPanelClosed(state) {
      state.activePanel = null;
    },
    clearToolbarPanelData(state) {
      state.data = {};
    },
    placementChanged(state, action: PayloadAction<ToolbarPlacement>) {
      state.placement = action.payload;
    },
    toolbarVisibilityChanged(state, action: PayloadAction<boolean>) {
      state.visible = action.payload;
    },
    toolbarVisibilityToggled(state) {
      state.visible = !state.visible;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authorizeUser.fulfilled, (state, action) => {
        state.placement = action.payload.settings.toolbarPosition;
        state.systemPlacement = action.payload.settings.toolbarPosition;
      })
      .addCase(SDKConnected, (state, action) => {
        const options = action.payload;

        if (options.showToolbar !== undefined) {
          state.visible = options.showToolbar;
        }
      });
  },
});

export const {
  toolbarPanelOpened,
  toolbarPanelClosed,
  placementChanged,
  toolbarVisibilityChanged,
  toolbarVisibilityToggled,
  clearToolbarPanelData,
} = toolbarSlice.actions;

export const selectToolbarPanel = (state: RootState) => state.toolbar.activePanel;

export const selectToolbarPanelData = (state: RootState) => state.toolbar.data;

export const selectToolbarPlacement = (state: RootState) => state.toolbar.placement;
export const selectToolbarSystemPlacement = (state: RootState) => state.toolbar.systemPlacement;

export const selectToolbarVisibility = (state: RootState) => state.toolbar.visible;

export const selectToolbarWidth = createSelector(
  [selectToolbarVisibility, selectIsRecorderSession],
  (toolbarVisible, isRecorderSession) => {
    // toolbar is always hidden in recorder session
    if (isRecorderSession) {
      return 0;
    }

    return toolbarVisible ? styleConstants.toolbar.width : 0;
  }
);

export const selectToolbarOrientation = (state: RootState): ToolbarOrientation =>
  state.toolbar.placement === 'bottom' ? 'horizontal' : 'vertical';

export default toolbarSlice.reducer;
