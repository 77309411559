import { selectOrderedStreams } from 'features/layout/features/order/orderSlice';
import { selectPagination } from 'features/layout/features/pagination/paginationSlice';
import { call, put, select } from 'redux-saga/effects';
import { UserId } from 'features/users/types';
import { orderChanged } from 'features/layout/actions';
import { RTCClient } from 'utils/webrtc';
import { LayoutPagination, SourceDetails } from '../types';

export function* onSpeakerOrderChangedSaga(speakers: SourceDetails[], movedUser: SourceDetails) {
  let orderedStreams: SourceDetails[] = yield select(selectOrderedStreams);

  // see if we need to update display order;
  const { numPages, streams }: LayoutPagination = yield select(selectPagination);

  const pageStreams: Record<UserId, SourceDetails> = {};
  for (const stream of streams) {
    pageStreams[stream.userId] = stream;
  }

  const remotePageStreams = streams.filter((stream) => stream.kind === 'remote');

  // don't reorder streams if there's only one page;
  const hasPagination = numPages > 1;
  const speakerNotDisplayed = !pageStreams[movedUser.userId];

  if (hasPagination && speakerNotDisplayed) {
    orderedStreams = [...orderedStreams];

    const historicalPageSpeakers = speakers.filter(
      (stream) => pageStreams[stream.userId] && stream.kind === 'remote'
    );

    const movedIdx = orderedStreams.findIndex((stream) => stream.userId === movedUser.userId);

    let replaceIdx = movedIdx;

    // if everyone spoke, replace the person who spoke longest ago
    if (historicalPageSpeakers.length === remotePageStreams.length) {
      const replaceUser = historicalPageSpeakers.pop();

      replaceIdx = orderedStreams.findIndex((stream) => stream.userId === replaceUser?.userId);
    } else if (historicalPageSpeakers.length) {
      // if some people spoke, replace the first person who hasn't;
      const replaceUser = remotePageStreams.find(
        (stream) => !historicalPageSpeakers.find((row) => row.userId === stream.userId)
      );

      if (replaceUser) {
        replaceIdx = orderedStreams.findIndex((stream) => stream.userId === replaceUser.userId);
      }
    } else {
      // if noone spoke, replace the first tile
      replaceIdx = 0;
    }

    [orderedStreams[movedIdx], orderedStreams[replaceIdx]] = [
      orderedStreams[replaceIdx],
      orderedStreams[movedIdx],
    ];

    yield put(orderChanged(orderedStreams));
  } else {
    yield call(RTCClient.receivingFeed.updateSimulcastConfig);
  }
}
