import { signalingWhiteboardOpened } from 'features/layout/features/content/actions';
import { gridPanelOpened } from 'features/layout/features/content/contentSlice';
import { closeModal } from 'features/modal/modalSlice';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { call, put } from 'redux-saga/effects';
import { parseBoardState } from 'utils/parseBoardState';
import { board, ShapeList } from 'utils/whiteboard/BoardStateManager';

export function* onWhiteboardOpenedSaga(action: ReturnType<typeof signalingWhiteboardOpened>) {
  yield call(board.createStore);

  if (action.payload.shapes?.length) {
    const parsedBoardState: ShapeList = yield call(parseBoardState, action.payload.shapes);

    yield call(board.setState, parsedBoardState);
  }

  yield put(closeModal('whiteboardE2eeConfirmation'));
  yield put(gridPanelOpened('whiteboard'));

  const canEditWhiteboard: boolean = yield call(hasPermissionsSaga, PermissionTypes.editWhiteboard);

  if (canEditWhiteboard) {
    yield call(board.watch);
  } else {
    yield call(board.stop);
  }
}
