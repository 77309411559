import WaitingScreen from 'components/WaitingScreen/WaitingScreen';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

const SessionDuplicated = () => {
  const { t } = useTranslation('waiting-screens');

  return (
    <WaitingScreen show title={t('session_duplicated.title')}>
      <Box
        className="max-w-prose"
        sx={{
          margin: '0 auto',
        }}
      >
        <Typography>{t('session_duplicated.text')}</Typography>
      </Box>
    </WaitingScreen>
  );
};

export default SessionDuplicated;
