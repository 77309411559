import { privateChatOpened } from 'features/chat/chatSlice';
import { readPrivateChatSaga } from 'features/chat/sagas/readPrivateChatSaga';
import { call, put, select } from 'redux-saga/effects';
import { ToolbarPanelType } from 'features/toolbar/types';
import { selectToolbarPanel, toolbarPanelOpened } from 'features/toolbar/toolbarSlice';

export function* onPrivateChatOpenedSaga(action: ReturnType<typeof privateChatOpened>) {
  const toolbarPanel: ToolbarPanelType = yield select(selectToolbarPanel);

  if (toolbarPanel !== 'chat') {
    yield put(toolbarPanelOpened('chat'));
  }

  yield call(readPrivateChatSaga, action.payload.id);
}
