import { signalingPublicKeyShared } from 'features/e2ee/actions';
import { exchangeEncryptedNameSaga } from 'features/e2ee/sagas/exchangeEncryptedNameSaga';
import { call } from 'redux-saga/effects';

export function* onPublicKeySharedSaga(action: ReturnType<typeof signalingPublicKeyShared>) {
  yield call(exchangeEncryptedNameSaga, {
    e2eePublicKey: action.payload.key,
    id: action.payload.initiator.id,
  });
}
