import { PermissionTypes } from 'features/permissions/types';
import { User } from 'features/users/types';

export interface HasDynamicPermissionsOptions {
  targetUser?: User;
}

export const lookupDynamicPermission = (
  permission: PermissionTypes,
  dynamicPermissions: PermissionTypes[] | undefined
) => {
  if (!dynamicPermissions) {
    return false;
  }

  return dynamicPermissions.includes(permission);
};
