import { AppThunk } from 'store/store';
import { selectNotificationsByUser } from 'features/notifications/notificationsSlice';
import { UserId } from 'features/users/types';
import { userLeft } from 'features/users/usersSlice';
import { closeActionNotifications } from 'features/notifications/thunks/closeActionNotifications';
import { NotificationId } from 'features/notifications/types';

export const cleanupUsers =
  (usersIds: UserId[]): AppThunk<void> =>
  async (dispatch, getState) => {
    const userIds: UserId[] = [];
    const allNotifications: NotificationId[] = [];

    for (const id of usersIds) {
      userIds.push(id);
      const notifications = selectNotificationsByUser(getState(), id);
      allNotifications.push(...notifications);
    }

    dispatch(closeActionNotifications(allNotifications));
    dispatch(userLeft(userIds));
  };
