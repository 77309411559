import {
  assignLocalUserToBreakoutRoomRequested,
  assignUsersToBreakoutRoomRequested,
} from 'features/breakout-rooms/actions';
import { UserId } from 'features/users/types';
import { selectLocalUserId } from 'features/users/usersSlice';
import { put, select } from 'redux-saga/effects';

export function* assignLocalUserToBreakoutRoomSaga(
  action: ReturnType<typeof assignLocalUserToBreakoutRoomRequested>
) {
  const localUserId: UserId = yield select(selectLocalUserId);

  yield put(
    assignUsersToBreakoutRoomRequested({
      id: action.payload,
      users: [localUserId],
    })
  );
}
