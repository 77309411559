import { openModal } from 'features/modal/modalSlice';
import { useAppDispatch } from 'store/hooks';
import { captionsToggled } from 'features/transcripts/slices/captions/captionsSlice';
import { useTranslation } from 'react-i18next';
import { stopTranscriptionRequested } from 'features/transcripts/actions';
import { useTranscription } from 'features/transcripts/slices/transcription/useTranscription';
import { useCaptions } from 'features/transcripts/slices/captions/useCaptions';

export const useTranscriptionControl = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('room');

  const { transcriptionStarted, transcriptionAvailable, canTranscribeSession } = useTranscription();

  const { captionsDisplayed, captionsAvailable } = useCaptions();

  const startTranscription = () => {
    dispatch(openModal('startTranscriptionConfirmation'));
  };

  const stopTranscription = () => {
    dispatch(stopTranscriptionRequested());
  };

  const toggleCaptions = () => {
    dispatch(captionsToggled());
  };

  const toggleControl = () => {
    if (canTranscribeSession) {
      startTranscription();
    } else {
      toggleCaptions();
    }
  };

  const renderLabel = () => {
    if (canTranscribeSession) {
      return transcriptionStarted
        ? t('toolbar.transcription.menu_trigger')
        : t('toolbar.transcription.start_transcription');
    }

    return captionsDisplayed ? t('toolbar.disable_captions') : t('toolbar.enable_captions');
  };

  return {
    active: captionsDisplayed,
    transcriptionStarted,
    captionsDisplayed,
    captionsAvailable,
    startTranscription,
    stopTranscription,
    toggleCaptions,
    toggleControl,
    label: renderLabel(),
    icon: 'transcript-3',
    isFeatureAvailable: transcriptionAvailable,
    canTranscribeSession,
  };
};
