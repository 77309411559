import { ApiError } from 'utils/http';
import { RestrictionError } from '@uppy/core/lib/Restricter';

export type DeepPartial<T> = T extends Function
  ? T
  : T extends object
    ? { [P in keyof T]?: DeepPartial<T[P]> }
    : T;

export type ArrayElement<T extends unknown[]> = T extends (infer Element)[] ? Element : never;

export function isError(error: unknown): error is Error {
  return Boolean(error) && error instanceof Error;
}

export function isDomException(error: unknown): error is DOMException {
  return Boolean(error) && error instanceof DOMException;
}

export function isAPIError(error: unknown): error is ApiError {
  return (
    error !== null &&
    typeof error === 'object' &&
    'name' in error &&
    (error as any).name === ApiError.name &&
    'message' in error
  );
}

export function isUppyRestrictionErrorError(error: unknown): error is RestrictionError<any, any> {
  return error !== null && typeof error === 'object' && 'isRestriction' in error;
}
