import { createSlice } from '@reduxjs/toolkit';
import { authorizeUser } from 'features/room/thunks/authorizeUser';
import { RootState } from 'store/store';
import { TranscriptionState } from 'features/transcripts/slices/transcription/types';
import {
  signalingTranscriptionStarted,
  signalingTranscriptionStopped,
} from 'features/transcripts/actions';

export const initialState: TranscriptionState = {
  enabled: false,
  started: false,
};

export const transcriptionSlice = createSlice({
  name: 'transcription',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(authorizeUser.fulfilled, (state, action) => {
        state.enabled = action.payload.settings.transcriptionEnabled;
      })
      .addCase(signalingTranscriptionStarted, (state) => {
        state.started = true;
      })
      .addCase(signalingTranscriptionStopped, (state) => {
        state.started = false;
      });
  },
});

// export const {} = transcriptsSlice.actions;

export default transcriptionSlice.reducer;

export const selectTranscriptionEnabled = (state: RootState) =>
  state.transcripts.transcription.enabled;

export const selectTranscriptionStarted = (state: RootState) =>
  state.transcripts.transcription.started;
