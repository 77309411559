export type RoomOnlyEvents =
  | 'userJoined'
  | 'userLeft'
  | 'streamStarted'
  | 'streamStopped'
  | 'screenshareStarted'
  | 'screenshareStopped';

export const roomOnlyEvents = [
  'userJoined',
  'userLeft',
  'streamStarted',
  'streamStopped',
  'screenshareStarted',
  'screenshareStopped',
];
