import { apiErrorMessages } from 'utils/http/index';
import ApiError from 'utils/http/ApiError';

export const lookupApiErrorMessage = (error: ApiError) => {
  if (!error.type) {
    return undefined;
  }

  return apiErrorMessages[error.type];
};
