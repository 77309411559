import { AppStartListening } from 'store/listenerMiddleware';
import { selectActiveMediaDevice } from 'features/user-media/userMediaSlice';
import { RTCClient } from 'utils/webrtc';

export const audioOutputDeviceChangeListener = (startListening: AppStartListening) =>
  startListening({
    predicate: (_action, currentState, originalState) => {
      const previousDevice = selectActiveMediaDevice(originalState, 'audiooutput');
      const currentDevice = selectActiveMediaDevice(currentState, 'audiooutput');

      return previousDevice !== currentDevice;
    },
    effect: (action, { getState }) => {
      const audioOutputDevice = selectActiveMediaDevice(getState(), 'audiooutput');

      if (audioOutputDevice) {
        RTCClient.receivingFeed.updateAudioOutput(audioOutputDevice);
      }
    },
  });
