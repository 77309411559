import { alpha, styled } from '@mui/material/styles';
import { List, ListItem, ListItemButton, ListItemProps } from '@mui/material';
import * as React from 'react';
import { styleConstants } from 'theme/styleConstants';

export const StyledParticipantsList = styled(List)({
  '& .MuiListItemText-root': {
    flex: 1,
    margin: 0,
  },
  '& .MuiListItemText-primary': {
    width: '100%',
    maxWidth: '100%',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  '& .MuiListItemText-secondary': {
    fontSize: 12,
    lineHeight: 1.2,
  },
  '& .MuiListItemSecondaryAction-root': {
    '.Icon-root': {
      fontSize: 32,
    },
  },
}) as typeof List;

export const StyledParticipantListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'sectionHeader',
})<
  ListItemProps<
    React.ElementType,
    {
      sectionHeader?: boolean;
    }
  >
>(({ theme, sectionHeader }) => ({
  minHeight: styleConstants.participantsPanel.listItemHeight,

  '& .MuiListItemSecondaryAction-root': {
    position: 'static',
    transform: 'none',
    marginLeft: 'auto',
    display: 'flex',
    paddingLeft: theme.spacing(1),
    alignItems: 'center',
  },
  ...(sectionHeader && {
    borderRadius: 4,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(3),
    minHeight: 46,
    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),

    '& .MuiListItemText-root': {
      flex: '0 1 auto',
      '.MuiListItemText-primary': {
        fontSize: '1.125rem',
      },
    },

    '& .MuiIconButton-edgeEnd': {
      marginRight: -4,
    },
  }),
}));

export const StyledParticipantListItemButton = styled(ListItemButton)({
  minHeight: styleConstants.participantsPanel.listItemHeight,
});
