import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal, selectActiveModalData } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import { muteRemoteAudio } from 'features/streaming/actions';
import { UserId } from 'features/users/types';
import { selectActiveUserById } from 'features/users/usersSlice';
import { PermissionTypes } from 'features/permissions/types';
import { usePermissions } from 'features/permissions/usePermissions';
import { useEffect } from 'react';
import ConfirmationDialog from 'components/ConfirmationDialog';

const MuteRemoteAudioConfirmation = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const modalData = useAppSelector(selectActiveModalData);

  const userId = modalData.userId as UserId;
  const user = useAppSelector((state) => selectActiveUserById(state, userId));

  const { hasPermissions } = usePermissions();

  const canRemotelyMute = hasPermissions(PermissionTypes.remoteMuting, {
    targetRole: user?.role,
  });

  useEffect(() => {
    if (!user || !canRemotelyMute) {
      dispatch(closeModal('muteRemoteAudioConfirmation'));
    }
  }, [user, canRemotelyMute, dispatch]);

  if (!user) {
    return null;
  }

  const handleCancel = () => {
    dispatch(closeModal('muteRemoteAudioConfirmation'));
  };

  const handleOk = () => {
    dispatch(muteRemoteAudio(user.id));
    dispatch(closeModal('muteRemoteAudioConfirmation'));
  };

  return (
    <ConfirmationDialog
      open
      title={t('mute_remote_audio_confirm.title', { username: user.name })}
      description={t('mute_remote_audio_confirm.description', { username: user.name })}
      cancelText={t('mute_remote_audio_confirm.btn_cancel_label')}
      okText={t('mute_remote_audio_confirm.btn_ok_label')}
      onOk={handleOk}
      onCancel={handleCancel}
      hideCloseButton
      preventClose
    />
  );
};

export default MuteRemoteAudioConfirmation;
