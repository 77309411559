import { useWhiteboardControl } from 'features/toolbar/Toolbar/controls/whiteboard/useWhiteboardControl';
import ToolbarMenuItem from 'features/toolbar/ToolbarMenuItem/ToolbarMenuItem';
import React from 'react';
import { ToolbarMenuItemWrapperProps } from 'features/toolbar/types';

const WhiteboardControl = ({ onClick }: ToolbarMenuItemWrapperProps) => {
  const { active, label, toggleWhiteboard, isFeatureAvailable } = useWhiteboardControl();

  if (!isFeatureAvailable) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(event);
    toggleWhiteboard();
  };

  return (
    <ToolbarMenuItem
      id="whiteboard-control"
      overlay={label}
      ariaLabel={label}
      active={active}
      icon="whiteboard"
      onClick={handleClick}
    />
  );
};

export default WhiteboardControl;
