import React, { cloneElement, useState, useEffect, useRef, useCallback } from 'react';
import {
  Fade,
  Popper as MuiPopper,
  Paper,
  ClickAwayListener,
  PopperPlacementType,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useControlled } from '@mui/material/utils';

type MouseEventArgs = [React.MouseEvent, ...any[]];

type TriggerType = 'hover' | 'click' | 'focus';

export interface PopperInjectedProps extends React.ComponentPropsWithRef<any> {
  ref: React.Ref<any>;
  'aria-describedby': string;
  open: boolean;
  placement: PopperPlacementType | undefined;
}

export type PopperProps = {
  id: string;
  children: React.ReactElement | ((props: PopperInjectedProps) => React.ReactNode);
  content: React.ReactNode;
  contentWidth?: string;
  open?: boolean;
  arrow?: boolean;
  triggerType?: TriggerType | TriggerType[];
  rootClose?: boolean;
  onClose?: (event: React.SyntheticEvent | Event) => void;
  placement?: PopperPlacementType;
  modifiers?: any;
  transition?: any;
};

function handleMouseOverOut(
  // eslint-disable-next-line @typescript-eslint/no-shadow
  handler: (...args: MouseEventArgs) => any,
  args: MouseEventArgs
) {
  const [e] = args;
  const target = e.currentTarget;
  const related = e.relatedTarget;

  if ((!related || related !== target) && !target.contains(related as Element)) {
    handler(...args);
  }
}

const StyledPopper = styled(MuiPopper, {
  shouldForwardProp: (prop) => prop !== 'arrow',
})<{ arrow?: boolean }>(({ theme, arrow }) => ({
  zIndex: theme.zIndex.tooltip,
  '& .MuiPopper-popper': {
    position: 'relative',
    backfaceVisibility: 'hidden',
  },
  '& .MuiTypography-root': {
    fontSize: theme.typography.body2.fontSize,
  },
  '&[data-popper-placement*="bottom"]': {
    '& .MuiPopper-popper': {
      marginTop: arrow ? 14 : 0,
    },
    '& .MuiPopper-arrow': {
      top: 0,
      left: 0,
      marginTop: -9,
      width: '1.8em',
      '&::before': {
        borderWidth: '0 0.9em 1em 0.9em',
        borderBottomColor: theme.palette.background.paper,
      },
      '&::after': {
        borderWidth: '0 0.9em 1em 0.9em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
  },
  '&[data-popper-placement*="top"]': {
    '& .MuiPopper-popper': {
      marginBottom: arrow ? 14 : 0,
    },
    '& .MuiPopper-arrow': {
      bottom: 0,
      left: 0,
      marginBottom: -9,
      width: '1.8em',
      '&::before': {
        borderWidth: '1em 0.9em 0 0.9em',
        borderTopColor: theme.palette.background.paper,
      },
      '&::after': {
        borderWidth: '0.9em 1em 0 0.9em',
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
  },
  '&[data-popper-placement*="right"]': {
    '& .MuiPopper-popper': {
      marginLeft: arrow ? 14 : 0,
    },
    '& .MuiPopper-arrow': {
      left: 0,
      marginLeft: -9,
      height: '1.8em',
      '&::before': {
        borderWidth: '0.9em 1em 0.9em 0',
        borderRightColor: theme.palette.background.paper,
      },
      '&::after': {
        borderWidth: '0.9em 1em 0.9em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
  },
  '&[data-popper-placement*="left"]': {
    '& .MuiPopper-popper': {
      marginRight: arrow ? 14 : 0,
    },
    '& .MuiPopper-arrow': {
      right: 0,
      marginRight: -9,
      height: '1.8em',
      '&::before': {
        borderWidth: '0.9em 0 0.9em 1em',
        borderLeftColor: theme.palette.background.paper,
      },
      '&::after': {
        borderWidth: '0.9em 0 0.9em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
}));

const Arrow = styled('div')({
  position: 'absolute',
  fontSize: 10,
  width: '1em',
  height: '1em',
  '&::before, &::after': {
    position: 'absolute',
    display: 'block',
    content: '""',
    margin: 'auto',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderColor: 'transparent',
  },
});

const Popper = ({
  id,
  children,
  modifiers,
  content,
  placement,
  onClose,
  open: controlledOpen,
  arrow = true,
  triggerType = 'click',
  rootClose = true,
  transition = true,
  contentWidth = '220px',
  ...props
}: PopperProps) => {
  const anchorRef = useRef<HTMLElement>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const [open, setOpen] = useControlled({
    controlled: controlledOpen,
    default: false,
    name: 'Popper',
    state: 'open',
  });

  const { onFocus, onBlur, onClick, ...restTriggerProps } =
    typeof children !== 'function' ? React.Children.only(children).props : ({} as any);

  const handleHide = useCallback(
    (event: React.SyntheticEvent | Event) => {
      setOpen(false);

      if (onClose && open) {
        onClose(event);
      }
    },
    [onClose, open, setOpen]
  );

  useEffect(() => {
    if (!open) {
      return undefined;
    }

    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape' || event.key === 'Esc') {
        handleHide(event);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleHide, open]);

  const handleShow = () => {
    setOpen(true);
  };

  const handleClick = (event: React.SyntheticEvent | Event) => {
    setOpen((prevOpen) => !prevOpen);
    onClick?.(event);

    if (onClose && !controlledOpen && open) {
      onClose(event);
    }
  };

  const handleFocus = (event: React.SyntheticEvent | Event) => {
    handleShow();
    onFocus?.(event);
  };

  const handleBlur = (event: React.SyntheticEvent | Event) => {
    handleHide(event);
    onBlur?.(event);
  };

  const handleMouseOver = (...args: MouseEventArgs) => {
    handleMouseOverOut(handleShow, args);
  };

  const handleMouseOut = (...args: MouseEventArgs) => {
    handleMouseOverOut(handleHide, args);
  };

  const handleClickAway = (e: Event | React.SyntheticEvent) => {
    if (anchorRef.current?.contains(e.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const triggers: string[] = triggerType === null ? [] : [].concat(triggerType as any);
  const triggerProps: PopperInjectedProps = {
    'aria-describedby': id,
    ref: anchorRef,
    ...restTriggerProps,
  };

  if (triggers.includes('click')) {
    triggerProps.onClick = handleClick;
  }

  if (triggers.includes('focus')) {
    triggerProps.onFocus = handleFocus;
    triggerProps.onBlur = handleBlur;
  }

  if (triggers.includes('hover')) {
    triggerProps.onMouseOver = handleMouseOver;
    triggerProps.onMouseOut = handleMouseOut;
  }

  const body = (
    <div className="MuiPopper-popper">
      <Box maxWidth={contentWidth}>
        <Paper
          sx={{
            p: 4,
            background: (theme) => theme.palette.background.paper,
          }}
          elevation={8}
        >
          {content}
        </Paper>
      </Box>
      {arrow ? (
        <Arrow ref={setArrowElement} className="MuiPopper-arrow" data-testid="popper-arrow" />
      ) : null}
    </div>
  );

  return (
    <>
      {typeof children === 'function'
        ? children(triggerProps)
        : cloneElement(children, triggerProps)}
      <StyledPopper
        onResize={undefined}
        onResizeCapture={undefined}
        id={id}
        arrow={arrow}
        transition={transition}
        modifiers={[
          {
            name: 'arrow',
            options: {
              element: arrowElement,
            },
          },
          ...(modifiers || []),
        ]}
        {...props}
        placement={placement}
        open={open}
        anchorEl={anchorRef.current}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div>
              {rootClose ? (
                <ClickAwayListener onClickAway={handleClickAway}>{body}</ClickAwayListener>
              ) : (
                body
              )}
            </div>
          </Fade>
        )}
      </StyledPopper>
    </>
  );
};

export default Popper;
