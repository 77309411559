import { Action } from '@reduxjs/toolkit';
import { addActionNotification } from 'features/notifications/addActionNotification';
import { REMOTE_BROADCASTER_LIMIT_REACHED_NOTIFICATION_ID } from 'features/notifications/constants';
import { selectNotificationById } from 'features/notifications/notificationsSlice';
import { closeActionNotification } from 'features/notifications/thunks/closeActionNotification';
import { ActionNotification } from 'features/notifications/types';
import { updateActionNotification } from 'features/notifications/updateActionNotification';
import { selectAllRoleNames } from 'features/permissions/permissionsSlice';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { selectBroadcasterLimit } from 'features/room/roomSlice';
import { User, UserId } from 'features/users/types';
import {
  broadcastLimitedDismissed,
  selectBroadcastLimitedIds,
  selectUserById,
} from 'features/users/usersSlice';
import i18n from 'i18n';
import { call, put, select } from 'redux-saga/effects';

function* checkIfModerator() {
  const roles: string[] = yield select(selectAllRoleNames);

  for (const role of roles) {
    const canManageBroadcast: boolean = yield call(
      hasPermissionsSaga,
      PermissionTypes.manageBroadcast,
      {
        targetRole: role,
      }
    );

    if (canManageBroadcast) {
      return true;
    }
  }

  return false;
}

export function* handleBroadcasterLimitReachedNotificationSaga() {
  const canManageBroadcast: boolean = yield call(checkIfModerator);

  if (!canManageBroadcast) {
    return;
  }

  const broadcastLimitedIds: UserId[] = yield select(selectBroadcastLimitedIds);
  const broadcastLimitedUserCount = broadcastLimitedIds.length;

  const limit: number = yield select(selectBroadcasterLimit);

  const activeNotification: ActionNotification = yield select(
    selectNotificationById,
    REMOTE_BROADCASTER_LIMIT_REACHED_NOTIFICATION_ID
  );

  if (broadcastLimitedUserCount === 0) {
    if (activeNotification) {
      const cleanupNotificationThunk: Action = yield call(
        closeActionNotification,
        REMOTE_BROADCASTER_LIMIT_REACHED_NOTIFICATION_ID
      );

      yield put(cleanupNotificationThunk);
    }

    return;
  }

  const user: User = yield select(selectUserById, broadcastLimitedIds[0]);

  // @TODO FIXME isn't the notification broken?

  if (!activeNotification) {
    const actionNotificationThunk: Action = yield call(
      addActionNotification,
      {
        content: i18n.t('notifications:broadcaster_limit_reached.content', {
          username: user.name,
          count: limit,
          context: broadcastLimitedUserCount > 1 ? 'grouped' : undefined,
        }),
        preventCloseOnAction: true,
        userAgnostic: true,
        cancelAction: broadcastLimitedDismissed(),
      },
      {
        toastId: REMOTE_BROADCASTER_LIMIT_REACHED_NOTIFICATION_ID,
      }
    );
    yield put(actionNotificationThunk);

    return;
  }

  const updateActionNotificationThunk: Action = yield call(
    updateActionNotification,
    activeNotification.id,
    {
      content: i18n.t('notifications:broadcaster_limit_reached.content', {
        username: user.name,
        count: limit,
        context: broadcastLimitedUserCount > 1 ? 'grouped' : undefined,
      }),
      preventCloseOnAction: true,
      userAgnostic: true,
    }
  );
  yield put(updateActionNotificationThunk);
}
