import { SourceDetails } from 'features/layout/types';
import { PermissionTypes } from 'features/permissions/types';
import { usePermissions } from 'features/permissions/usePermissions';
import { kickUserRequested } from 'features/users/actions';
import { selectActiveUserById } from 'features/users/usersSlice';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import React from 'react';

export const useRemoveUserControl = (
  source: SourceDetails,
  clickCallback: (e: React.MouseEvent<HTMLLIElement | HTMLButtonElement, MouseEvent>) => void
) => {
  const { t } = useTranslation('room');
  const dispatch = useAppDispatch();
  const { hasPermissions } = usePermissions();

  const onClick = (e: React.MouseEvent<HTMLLIElement | HTMLButtonElement, MouseEvent>) => {
    clickCallback?.(e);
    dispatch(kickUserRequested(source.userId));
  };

  const user = useAppSelector((state) => selectActiveUserById(state, source.userId));

  const canRemoveParticipant = hasPermissions(PermissionTypes.removeParticipant, {
    targetRole: user?.role,
  });

  return {
    show: canRemoveParticipant && source.kind === 'remote',
    icon: 'minus',
    text: t('tooltips.kick_user'),
    onClick,
  };
};
