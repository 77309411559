import { call, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { canExposeInternals } from 'utils/development';
import { SFrameManager } from 'features/e2ee/SFrameManager';
import { User, UserId } from 'features/users/types';
import { selectUserById } from 'features/users/usersSlice';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { RTCClient } from 'utils/webrtc';
import { refineSframeStatus } from 'features/e2ee/sagas/refineSframeStatus';
import { ReceiverKeys } from 'features/e2ee/types';

export function* onRemoteStreamSecretDecryptedSaga(action: PayloadAction<UserId>) {
  const user: User = yield select(selectUserById, action.payload);

  if (user) {
    yield call(refineSframeStatus);

    // @FIXME remove test code
    if (canExposeInternals() && window.e2eeStreamKey) {
      yield call(SFrameManager.addReceiver, user.e2eeId!, window.e2eeStreamSharedKey);
      return;
    }

    const receiver: ReceiverKeys | undefined = yield call(E2EEManager.getReceiver, user.id);
    if (receiver?.streamKey) {
      try {
        yield call(SFrameManager.addReceiver, user.e2eeId!, receiver.streamKey);
        yield call(RTCClient.receivingFeed.subscribeToE2eeUser, user.id);
      } catch {
        // no need to move on
      }
    }
  }
}
