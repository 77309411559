import React from 'react';
import SessionEnded from 'features/waiting-screens/SessionEnded';
import RoomError from 'features/waiting-screens/RoomError';
import ConnectionFailure from 'features/waiting-screens/ConnectionFailure';
import ParticipantsLimit from 'features/waiting-screens/ParticipantsLimit';
import { useAppSelector } from 'store/hooks';
import { selectWaitingScreen } from 'features/application/applicationSlice';
import { WaitingScreen } from 'features/application/types';
import UserKicked from 'features/waiting-screens/UserKicked';
import AccessRejected from 'features/waiting-screens/AccessRejected';
import InterceptSession from 'features/waiting-screens/InterceptSession';
import SessionDuplicated from 'features/waiting-screens/SessionDuplicated';

const componentsMap: Record<WaitingScreen, React.ComponentType> = {
  sessionEnded: SessionEnded,
  sessionLeft: SessionEnded,
  roomError: RoomError,
  connectionFailure: ConnectionFailure,
  participantsLimit: ParticipantsLimit,
  userKicked: UserKicked,
  accessRejected: AccessRejected,
  interceptSession: InterceptSession,
  sessionDuplicated: SessionDuplicated,
};

const WaitingScreens = () => {
  const screen = useAppSelector(selectWaitingScreen);

  if (screen === null) {
    return null;
  }

  const Screen = componentsMap[screen];

  return <Screen />;
};

export default WaitingScreens;
