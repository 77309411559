import { useTranslation } from 'react-i18next';
import MobileMenuItem from 'features/toolbar/Toolbar/mobile/MobileMenuItem';
import { MobileMenuItemWrapperProps } from 'features/toolbar/types';
import React from 'react';
import { useQAControl } from 'features/toolbar/Toolbar/controls/qa/useQAControl';

const QAControlMobile = ({ onClick }: MobileMenuItemWrapperProps) => {
  const { t } = useTranslation('room');

  const { active, togglePanel, unreadCount, icon, isFeatureAvailable } = useQAControl();

  if (!isFeatureAvailable) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    onClick?.(event);
    togglePanel();
  };

  return (
    <MobileMenuItem
      icon={icon}
      badgeContent={unreadCount}
      badgeActive
      selected={active}
      onClick={handleClick}
    >
      {t('toolbar.qa')}
    </MobileMenuItem>
  );
};

export default QAControlMobile;
