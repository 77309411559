import { AppThunk } from 'store/store';
import {
  selectAllSimpleNotificationIds,
  simpleNotificationDisplayed,
} from 'features/notifications/notificationsSlice';
import { ActionNotificationParams, NotificationId } from 'features/notifications/types';
import { notification, NotificationOptions } from 'features/notifications/toast/notification';
import { makeId } from 'features/notifications/makeId'; // Instead of passing a function as a callback, it includes a pre-built action object as a prop for the notification.

export const displaySimpleNotification =
  (
    props: ActionNotificationParams,
    options?: Omit<NotificationOptions, 'containerId'>
  ): AppThunk<void | NotificationId> =>
  (dispatch, getState) => {
    const notificationId = makeId(options?.toastId);

    const allIds = selectAllSimpleNotificationIds(getState());
    // prevent duplicates
    if (allIds.includes(notificationId)) {
      return undefined;
    }

    const id = notification(props.content, {
      ...options,
      toastId: notificationId,
      data: {
        ...options?.data,
        kind: 'simple',
      },
    });

    if (id) {
      dispatch(
        simpleNotificationDisplayed({
          id,
          ...props,
        })
      );
    }

    return notificationId;
  };
