import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import { requestRecording } from 'features/recorder/actions';
import { usePermissions } from 'features/permissions/usePermissions';
import { PermissionTypes } from 'features/permissions/types';
import { useEffect } from 'react';
import ConfirmationDialog from 'components/ConfirmationDialog';

const RecordingConsentModal = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const { hasPermissions } = usePermissions();

  const canManageRecording = hasPermissions(PermissionTypes.recording);

  useEffect(() => {
    if (!canManageRecording) {
      dispatch(closeModal('recordingConsent'));
    }
  }, [canManageRecording, dispatch]);

  const handleOk = () => {
    dispatch(requestRecording());
    dispatch(closeModal('recordingConsent'));
  };

  const handleCancel = () => {
    dispatch(closeModal('recordingConsent'));
  };

  return (
    <ConfirmationDialog
      open
      maxWidth="sm"
      title={t('recording_consent.title')}
      description={t('recording_consent.description')}
      cancelText={t('recording_consent.btn_cancel_label')}
      okText={t('recording_consent.btn_ok_label')}
      onOk={handleOk}
      onCancel={handleCancel}
      preventClose
      hideCloseButton
    />
  );
};

export default RecordingConsentModal;
