import {
  AnswerEntities,
  QuestionAllIds,
  QuestionEntities,
  QuestionPayload,
} from 'features/qa/types';

export const normalizeQuestions = (questionsArray: QuestionPayload[]) => {
  const questionEntities: QuestionEntities = {};
  const questionIds: QuestionAllIds = [];
  const answerEntities: AnswerEntities = {};

  let totalCount = 0;

  for (const question of questionsArray) {
    const { id, answers, ...rest } = question;

    const answerIdsForQuestion: string[] = [];
    for (const answer of answers) {
      answerEntities[answer.id] = answer;
      answerIdsForQuestion.push(answer.id);
      totalCount += 1;
    }
    questionEntities[id] = { ...rest, id, answers: answerIdsForQuestion };
    questionIds.push(id);
    totalCount += 1;
  }

  return {
    questions: {
      entities: questionEntities,
      allIds: questionIds,
    },
    answers: answerEntities,
    totalCount,
  };
};
