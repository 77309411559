export const compoundStream = (
  audioStream: MediaStream | null,
  videoStream: MediaStream | null
) => {
  const mediaStream = new MediaStream();

  const audioTrack = audioStream?.getAudioTracks()[0];
  if (audioTrack) {
    mediaStream.addTrack(audioTrack.clone());
  }

  const videoTrack = videoStream?.getVideoTracks()[0];
  if (videoTrack) {
    mediaStream.addTrack(videoTrack.clone());
  }

  if (mediaStream.getTracks().length === 0) {
    return undefined;
  }

  return mediaStream;
};
