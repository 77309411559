import {
  signalingBreakoutRoomsStarted,
  splashScreenOpenRequested,
} from 'features/breakout-rooms/actions';
import { roomMovementRequested } from 'features/breakout-rooms/breakoutRoomsSlice';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { broadcastSetupStarted } from 'features/room/roomSlice';
import { call, put } from 'redux-saga/effects';
import { RTCClient } from 'utils/webrtc';

export function* onBreakoutRoomsStartedSaga(
  action: ReturnType<typeof signalingBreakoutRoomsStarted>
) {
  if (action.payload) {
    yield put(splashScreenOpenRequested(action.payload.reason));

    yield call(RTCClient.setBreakoutRoomId, action.payload.targetRoom.id);

    yield put(roomMovementRequested());

    const broadcastAllowed: boolean = yield call(hasPermissionsSaga, PermissionTypes.broadcast);

    if (!broadcastAllowed) {
      yield put(broadcastSetupStarted({ room: action.payload.targetRoom.id }));
    }
  }
}
