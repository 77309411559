import { call } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { clearRoomsRequested } from 'features/breakout-rooms/actions';

export function* clearBreakoutRoomsSaga(action: ReturnType<typeof clearRoomsRequested>) {
  yield call(SignalingSocket.send, {
    event: 'clearBreakoutRooms',
    data: {
      id: action.payload,
    },
  });
}
