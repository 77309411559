import { put, select } from 'redux-saga/effects';
import {
  answerTypeReset,
  selectQuestionAnswerTypes,
  selectQuestionById,
} from 'features/qa/qaSlice';
import { AnswerType, Question } from 'features/qa/types';
import { stopLiveAnswerRequested } from 'features/qa/actions';

// @TODO FIXME simplify this saga if we decide to have only one live answer at a time
export function* resetQuestionAnswerSaga() {
  const activeAnswers: Record<string, AnswerType> = yield select(selectQuestionAnswerTypes);

  const questionIds = Object.keys(activeAnswers);
  if (questionIds.length === 0) {
    return;
  }

  for (const id of questionIds) {
    const question: Question = yield select(selectQuestionById, id);

    // auto-stop an active live answer
    if (question.liveAnswerId) {
      yield put(
        stopLiveAnswerRequested({
          questionId: question.id,
          liveAnswerId: question.liveAnswerId,
        })
      );
      return;
    }
  }

  // reset any answer state
  yield put(answerTypeReset());
}
