import { useEffect } from 'react';
import AudioTab from 'features/room/settings/tabs/AudioTab';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Control, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useTemporaryMedia } from 'hooks/useTemporaryMedia';
import { selectLocalAudioBroadcasting } from 'features/streaming/streamingSlice';
import { RoomSettingsFormValues } from 'features/room/settings/RoomSettings';
import {
  disableDeviceAutoChangeAlert,
  selectDeviceAutoChangeStatus,
} from 'features/user-media/userMediaSlice';
import { usePublishingFeed } from 'hooks/usePublishingFeed';
import { useUserAudioOutput } from 'hooks/useUserAudioOutput';
import { streamingDeviceChanged } from 'features/streaming/actions';
import { useMediaDevices } from 'hooks/useMediaDevices';
import { usePermissions } from 'features/permissions/usePermissions';
import { PermissionTypes } from 'features/permissions/types';

export type AudioTabContainerProps = {
  control: Control<RoomSettingsFormValues>;
  setValue: UseFormSetValue<RoomSettingsFormValues>;
};

const AudioTabContainer = ({ control, setValue }: AudioTabContainerProps) => {
  const dispatch = useAppDispatch();

  const { hasPermissions } = usePermissions();
  const allowBroadcast = hasPermissions(PermissionTypes.broadcast);

  const isAudioBroadcasting = useAppSelector(selectLocalAudioBroadcasting);
  const deviceAutoChange = useAppSelector(selectDeviceAutoChangeStatus);

  const { micPermissions, activeMediaDevices, persistDevices } = useMediaDevices();

  const audioOutputHandler = useUserAudioOutput();
  const { audioStream } = usePublishingFeed('media');
  const {
    requestTemporaryMedia,
    mediaStream: tempAudioStream,
    cleanupTemporaryMedia,
  } = useTemporaryMedia();

  useEffect(() => {
    if (allowBroadcast && !isAudioBroadcasting) {
      requestTemporaryMedia({
        audio: {
          deviceId: {
            exact: activeMediaDevices.audioinput,
          },
        },
      });
    }

    return () => {
      cleanupTemporaryMedia();
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if (deviceAutoChange.audiooutput) {
      setValue('audiooutput', activeMediaDevices.audiooutput || '', { shouldDirty: true });
      dispatch(disableDeviceAutoChangeAlert('audiooutput'));
    }
  }, [activeMediaDevices.audiooutput, setValue, deviceAutoChange.audiooutput, dispatch]);

  useEffect(() => {
    const requestAudio = async () => {
      await requestTemporaryMedia({
        audio: {
          deviceId: {
            exact: activeMediaDevices.audioinput,
          },
        },
      });
    };

    if (deviceAutoChange.audioinput) {
      setValue('audioinput', activeMediaDevices.audioinput || '', { shouldDirty: true });
      dispatch(disableDeviceAutoChangeAlert('audioinput'));

      if (!isAudioBroadcasting) {
        requestAudio();
      }
    }
  }, [
    activeMediaDevices.audioinput,
    isAudioBroadcasting,
    deviceAutoChange.audioinput,
    setValue,
    requestTemporaryMedia,
    dispatch,
  ]);

  const handleMicChange = async (deviceId: string) => {
    cleanupTemporaryMedia();

    persistDevices(deviceId, 'audioinput');

    if (!isAudioBroadcasting) {
      await requestTemporaryMedia({
        audio: {
          deviceId: {
            exact: deviceId,
          },
        },
      });
    }

    dispatch(
      streamingDeviceChanged({
        id: deviceId,
        kind: 'audioinput',
      })
    );
  };

  const handleOutputChange = async (deviceId: string) => {
    persistDevices(deviceId, 'audiooutput');
    await audioOutputHandler(deviceId);
  };

  return (
    <AudioTab
      vuMeterActive
      onMicChange={handleMicChange}
      onOutputChange={handleOutputChange}
      micDisabled={micPermissions !== 'granted'}
      control={control}
      audioStream={isAudioBroadcasting ? audioStream : tempAudioStream}
      allowBroadcast={allowBroadcast}
    />
  );
};

export default AudioTabContainer;
