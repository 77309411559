import { activeTabChanged } from 'features/chat/chatSlice';
import { readPrivateChatSaga } from 'features/chat/sagas/readPrivateChatSaga';
import { readPublicChatSaga } from 'features/chat/sagas/readPublicChatSaga';
import { ChatTab } from 'features/chat/types';
import { call } from 'redux-saga/effects';

export function* onActiveTabChangedSaga(action: ReturnType<typeof activeTabChanged>) {
  const tab = action.payload;

  if (tab === ChatTab.public) {
    yield call(readPublicChatSaga);
  } else {
    yield call(readPrivateChatSaga);
  }
}
