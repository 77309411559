import { PayloadAction } from '@reduxjs/toolkit';
import { FeedId, RTCEventPayload } from 'features/streaming/types';
import { call } from 'redux-saga/effects';
import { RTCClient } from 'utils/webrtc';
import { logger } from 'utils/logger';

export function* onPublisherLeavingSaga(action: PayloadAction<RTCEventPayload<FeedId>>) {
  const { message } = action.payload;
  logger.log(`Publisher left: ${message}`);

  yield call(RTCClient.receivingFeed.cleanupSubscription, message);
}
