import MobileMenuItem from 'features/toolbar/Toolbar/mobile/MobileMenuItem';
import { useRecordingControl } from 'features/toolbar/Toolbar/controls/recording/useRecordingControl';
import { RecordingIcon } from 'features/toolbar/Toolbar/controls/recording/RecordingIcon';
import React from 'react';
import { MobileMenuItemWrapperProps } from 'features/toolbar/types';

const RecordingControlMobile = ({ onClick }: MobileMenuItemWrapperProps) => {
  const { toggleRecording, recorderStatus, active, icon, label, isFeatureAvailable } =
    useRecordingControl();

  if (!isFeatureAvailable) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    onClick?.(event);
    toggleRecording();
  };

  return (
    <MobileMenuItem
      icon={<RecordingIcon name={icon} active={active} />}
      selected={active}
      disabled={recorderStatus === 'starting'}
      onClick={handleClick}
    >
      {label}
    </MobileMenuItem>
  );
};

export default RecordingControlMobile;
