import React from 'react';
import { AppThunk } from 'store/store';
import {
  actionNotificationDisplayed,
  selectActionNotificationPanel,
  selectAllNotificationIds,
  selectBufferNotificationIds,
  showNotificationsPanel,
} from 'features/notifications/notificationsSlice';
import { ActionNotificationParams, NotificationId } from 'features/notifications/types';
import {
  ACTION_NOTIFICATION_CONTAINER_ID,
  ACTION_NOTIFICATION_LIMIT,
} from 'features/notifications/constants';
import Notification from 'features/notifications/components/Notification';
import { notification, NotificationOptions } from 'features/notifications/toast/notification';
import { selectIsRecorderSession } from 'features/recorder/recorderSlice';
import { selectGlobalUser } from 'features/users/usersSlice';
import { makeId } from 'features/notifications/makeId';

// Instead of passing a function as a callback, it includes a pre-built action object as a prop for the notification.
// When the notification succeeds, it dispatches that pre-built action and triggers a corresponding saga.
// It is a level of indirection, but it allows us to continue following the Redux principles.

export const addActionNotification =
  (
    props: ActionNotificationParams,
    options?: Omit<NotificationOptions, 'containerId'>
  ): AppThunk<void | NotificationId> =>
  (dispatch, getState) => {
    const isRecorder = selectIsRecorderSession(getState());
    if (isRecorder) {
      return undefined;
    }

    const notificationId = makeId(options?.toastId);

    const allIds = selectAllNotificationIds(getState());

    // prevent duplicates¬
    if (allIds.includes(notificationId)) {
      return undefined;
    }

    const bufferIds = selectBufferNotificationIds(getState());
    const notificationPanelOpened = selectActionNotificationPanel(getState());

    const initiator = selectGlobalUser(getState(), props.initiatorId || '') || props.initiator;

    if (!notificationPanelOpened && bufferIds.length < ACTION_NOTIFICATION_LIMIT) {
      notification(
        ({ data, ...toastContentProps }) => (
          <Notification {...toastContentProps} user={initiator} {...props} id={notificationId} />
        ),
        {
          ...options,
          toastId: notificationId,
          containerId: ACTION_NOTIFICATION_CONTAINER_ID,
        }
      );
    }

    if (bufferIds.length === ACTION_NOTIFICATION_LIMIT) {
      dispatch(showNotificationsPanel());

      notification.clearWaitingQueue({ containerId: ACTION_NOTIFICATION_CONTAINER_ID });

      notification.dismiss({ containerId: ACTION_NOTIFICATION_CONTAINER_ID });
    }

    dispatch(
      actionNotificationDisplayed({
        id: notificationId,
        ...props,
      })
    );

    return notificationId;
  };
