import { call, select } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { PayloadAction } from '@reduxjs/toolkit';
import { LowerHandPayload, UserId } from 'features/users/types';
import { logger } from 'utils/logger';
import { selectLocalUserId } from 'features/users/usersSlice';

export function* lowerHandSaga(action: PayloadAction<LowerHandPayload>) {
  const localUserId: UserId = yield select(selectLocalUserId);

  const remoteAction = action.payload && localUserId !== action.payload;
  if (!remoteAction) {
    logger.remote({ action: true, tier: 1 }).info('The user lowered hand');
  }

  yield call(SignalingSocket.send, {
    event: 'lowerHand',
    ...(remoteAction && {
      data: { id: action.payload },
    }),
  });
}
