import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

const UnavailableCameraPopper = () => {
  const { t } = useTranslation('join');

  return <Typography>{t('publisher.poppers.camera_unavailable')}</Typography>;
};

export default UnavailableCameraPopper;
