export const observeResize = (
  target: Element | Element[],
  onResize: ResizeObserverCallback
): (() => void) => {
  if (typeof ResizeObserver !== 'undefined') {
    const observer = new ResizeObserver(onResize);

    if (Array.isArray(target)) {
      target.forEach((t) => observer.observe(t));
    } else {
      observer.observe(target);
    }

    return () => observer.disconnect();
  }

  return () => {};
};
