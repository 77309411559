import SimulcastControls from 'features/dev-mode/components/SimulcastControls';
import { Box } from '@mui/material';
import { SourceDetails } from 'features/layout/types';
import { useAppSelector } from 'store/hooks';
import { selectRemoteStreams } from 'features/streaming/streamingSlice';
import { selectDevModeEnabled } from 'features/dev-mode/devModeSlice';
import FeedMeta from 'features/dev-mode/components/FeedMeta';

const FeedDebugControls = ({ source }: { source: SourceDetails }) => {
  const { userId, feedId } = source;

  const debugEnabled = useAppSelector(selectDevModeEnabled);
  const streams = useAppSelector((state) => selectRemoteStreams(state, userId));

  if (!debugEnabled || !streams) {
    return null;
  }

  const videoStream = streams.find((stream) => stream.kind === 'video');

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        p: 2,
        width: '100%',
        zIndex: 10,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <FeedMeta userId={userId} feedId={feedId} />
        {videoStream?.substream !== undefined ? <SimulcastControls mid={videoStream.mid} /> : null}
      </Box>
    </Box>
  );
};

export default FeedDebugControls;
