import { eventBusCommandSaga } from 'features/application/sagas/eventBusCommandSaga';
import { onUserMediaFailedCommandSaga } from 'features/sdk/onUserMediaFailedCommandSaga';
import { userMediaFailed } from 'features/user-media/userMediaSlice';
import { takeLatest, takeEvery } from 'redux-saga/effects';
import {
  eventBusCommandReceived,
  interactionTriggered,
  pong,
  redirect,
  signalingDisconnected,
  signalingReconnected,
} from 'features/application/actions';
import { redirectSaga } from 'features/application/sagas/redirectSaga';
import { connectSignalingSaga } from 'features/application/sagas/connectSignalingSaga';
import { authorizeUser } from 'features/room/thunks/authorizeUser';
import { destroySessionSaga } from 'features/application/sagas/destroySessionSaga';
import { prepareReconnectionSaga } from 'features/application/sagas/prepareReconnectionSaga';
import { reconnectionSaga } from 'features/application/sagas/reconnectionSaga';
import { signalingReconnectionStarted } from 'features/application/applicationSlice';
import { refreshConnectionMediaTokensSaga } from 'features/application/sagas/refreshConnectionMediaTokensSaga';
import { interactionBusCommandSaga } from 'features/application/sagas/interactionBusCommandSaga';

export default function* appWatcher() {
  yield takeLatest(authorizeUser.fulfilled, connectSignalingSaga);

  yield takeLatest(signalingDisconnected, destroySessionSaga);
  yield takeLatest(signalingReconnectionStarted, prepareReconnectionSaga);
  yield takeLatest(signalingReconnected, reconnectionSaga);

  yield takeLatest(eventBusCommandReceived, eventBusCommandSaga);
  yield takeEvery(userMediaFailed, onUserMediaFailedCommandSaga);

  yield takeLatest(interactionTriggered, interactionBusCommandSaga);

  yield takeEvery(redirect, redirectSaga);
  yield takeEvery(pong, refreshConnectionMediaTokensSaga);
}
