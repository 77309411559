import { backgroundsList } from 'features/room/settings/tabs/VideoBackgroundTab/backgrounds';
import { SelectedVirtualBackground } from 'utils/webrtc/VirtualBackground';

export const vbConfigToSDKState = (config: SelectedVirtualBackground) => {
  const { type, value } = config;

  if (!(type && value)) {
    return {
      enabled: false,
    };
  }

  if (type === 'blur') {
    return {
      enabled: true,
      type,
      value,
    };
  }

  const matchingImage = Object.entries(backgroundsList).find((background) => {
    const backgroundConfig = background[1];

    return backgroundConfig.src === value;
  });

  if (matchingImage) {
    const [bgName, bgConfig] = matchingImage;

    return {
      enabled: true,
      type: 'image',
      name: bgName,
      value: bgConfig,
    };
  }

  return {
    enabled: true,
    type: 'imageUrl',
    value,
  };
};
