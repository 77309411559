import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import { DialogContent, DialogContentText, Button } from '@mui/material';
import { Dialog, DialogActions, DialogTitle } from 'components/Dialog';

const RecordingFailureRuntimeModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('modals');

  const hideModal = () => dispatch(closeModal('recordingFailureRuntime'));

  return (
    <Dialog
      open
      aria-labelledby="recording-failure-runtime-title"
      aria-describedby="recording-failure-runtime-description"
      onClose={hideModal}
    >
      <DialogTitle id="recording-failure-runtime-title" onClose={hideModal}>
        {t('recording_failure_runtime.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="recording-failure-runtime-description">
          {t('recording_failure_runtime.description')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={hideModal}>{t('recording_failure_runtime.btn_ok_label')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RecordingFailureRuntimeModal;
