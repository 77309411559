import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { E2EEState, KeyRatchetType } from 'features/e2ee/types';

export const initialState: E2EEState = {
  enabled: false,
  roomE2eeCode: [],
  keyRatchet: {
    application: false,
    stream: false,
  },
};

export const e2eeSlice = createSlice({
  name: 'e2ee',
  initialState,
  reducers: {
    e2eeEnabled(state) {
      state.enabled = true;
    },
    roomE2eeCodeGenerated(state, action: PayloadAction<string[]>) {
      state.roomE2eeCode = action.payload;
    },
    senderKeyRatchetStarted(state, action: PayloadAction<KeyRatchetType>) {
      state.keyRatchet[action.payload] = true;
    },
    senderKeyRatchetFinished(state, action: PayloadAction<KeyRatchetType>) {
      state.keyRatchet[action.payload] = false;
    },
  },
});

export const {
  e2eeEnabled,
  roomE2eeCodeGenerated,
  senderKeyRatchetStarted,
  senderKeyRatchetFinished,
} = e2eeSlice.actions;

export const selectE2eeEnabled = (state: RootState) => state.e2ee.enabled;
export const selectRoomE2eeCode = (state: RootState) => state.e2ee.roomE2eeCode;
export const selectIsRatcheting = (state: RootState, type: KeyRatchetType) =>
  state.e2ee.keyRatchet[type];

export default e2eeSlice.reducer;
