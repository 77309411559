import { selectChatUnreadMessagesCount } from 'features/chat/chatSlice';
import { useToolbarPanel } from 'hooks/useToolbarPanel';
import { useAppSelector } from 'store/hooks';
import { useChat } from 'features/chat/useChat';

export const useChatControl = () => {
  const unreadCount = useAppSelector(selectChatUnreadMessagesCount);

  const { toolbarPanel, togglePanel } = useToolbarPanel();

  const { chatAvailable } = useChat();

  const toggleChatPanel = () => {
    togglePanel('chat');
  };

  return {
    active: toolbarPanel === 'chat',
    togglePanel: toggleChatPanel,
    icon: 'chat',
    unreadCount,
    isFeatureAvailable: chatAvailable,
  };
};
