import { IconButton } from '@mui/material';
import { alpha, darken, styled } from '@mui/material/styles';
import Icon from 'components/Icon';
import { openModal } from 'features/modal/modalSlice';
import { useDispatch } from 'react-redux';

const Button = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'disableInteraction',
})<{ disableInteraction?: boolean }>(({ theme, disableInteraction }) => ({
  backgroundColor: alpha(theme.palette.common.white, 0.3),
  color: theme.palette.common.white,
  transition: theme.transitions.create('color'),
  fontSize: 24,
  width: 32,
  height: 32,
  '&:focus-visible': {
    outline: 'none',
    color: darken(theme.palette.common.white, 0.4),
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.5),
  },
  ...(disableInteraction && {
    pointerEvents: 'none',
    cursor: 'default',
  }),
}));

export const CaptionsSettingsControl = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      openModal('roomSettings', {
        activeTab: 4,
      })
    );
  };

  return (
    <Button onClick={handleClick}>
      <Icon name="settings" />
    </Button>
  );
};
