import { useCallback } from 'react';
import { userMediaReceived } from 'features/user-media/userMediaSlice';
import { useDispatch } from 'react-redux';
import { useMediaDevices } from 'hooks/useMediaDevices';

export const useUserAudioOutput = () => {
  const dispatch = useDispatch();

  const { persistDevices } = useMediaDevices();

  return useCallback(
    (deviceId: string) => {
      persistDevices(deviceId, 'audiooutput');

      dispatch(
        userMediaReceived({
          updateActiveDevice: true,
          device: {
            id: deviceId,
            kind: 'audiooutput',
          },
          permissions: {
            audiooutput: 'granted',
          },
        })
      );
    },
    [dispatch, persistDevices]
  );
};
