import { notification } from 'features/notifications/toast/notification';
import { logger } from 'utils/logger';
import { store } from 'store/store';
import {
  sendingStatsUpdated,
  updateConnectionQuality,
} from 'features/system-stats/systemStatsSlice';
import { ControlledPublishingHandle } from 'utils/webrtc/publishing/ControlledPublishingHandle';
import { PublishingRTCStats } from 'utils/webrtc/publishing/PublishingRTCStats';
import ConnectionQualityNotification from 'features/system-stats/ConnectionQualityNotification';
import { WEAK_CONNECTION_NOTIFICATION_ID } from 'features/notifications/constants';

export enum ConnectionQualityLevel {
  bad = 2,
  poor = 3,
  good = 4,
}

export class ConnectionQuality {
  MOSRange: number[] = [];

  connectionQuality = ConnectionQualityLevel.good;

  rtcStats?: PublishingRTCStats;

  rtcStatsTimer?: number;

  qualityTimer?: number;

  initialize = (feed: ControlledPublishingHandle) => {
    this.rtcStats = new PublishingRTCStats({ feed });
  };

  private captureMOS = (value: number) => {
    this.MOSRange.push(value);
    if (this.MOSRange.length > 10) {
      this.MOSRange.shift();
    }
  };

  private calculateConnectionQuality = () => {
    this.qualityTimer = window.setInterval(() => {
      const MOSRange = [...this.MOSRange];

      let sum = 0;
      for (const mos of MOSRange) {
        sum += mos;
      }

      this.connectionQuality = sum / MOSRange.length;

      store.dispatch(updateConnectionQuality(this.connectionQuality));

      if (this.connectionQuality < ConnectionQualityLevel.poor) {
        notification(ConnectionQualityNotification, {
          position: 'top-center',
          closeOnClick: false,
          autoClose: false,
          toastId: WEAK_CONNECTION_NOTIFICATION_ID,
          className: 'weak-connection-toast',
        });

        logger.remote({ tier: 1 }).warn(`Connection is weak.`);
      } else if (notification.isActive(WEAK_CONNECTION_NOTIFICATION_ID)) {
        notification.dismiss(WEAK_CONNECTION_NOTIFICATION_ID);
      }
    }, 10000);
  };

  captureStats = () => {
    const { rtcStats, rtcStatsTimer } = this;
    if (rtcStatsTimer) {
      return;
    }

    if (!rtcStats) {
      return;
    }

    this.rtcStatsTimer = window.setInterval(async () => {
      const stats = await rtcStats.getStats();
      if (stats) {
        // skip the first tick
        if (stats.MOS > 0) {
          this.captureMOS(stats.MOS);
        }

        store.dispatch(sendingStatsUpdated(stats));
      }
    }, 1000);

    this.calculateConnectionQuality();
  };

  stopCapturingStats = () => {
    if (this.rtcStatsTimer) {
      window.clearInterval(this.rtcStatsTimer);
      window.clearInterval(this.qualityTimer);
      this.rtcStatsTimer = undefined;
      this.qualityTimer = undefined;
    }
  };

  cleanup = () => {
    this.stopCapturingStats();
    this.rtcStats = undefined;
  };
}
