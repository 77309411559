import { NotificationId } from 'features/notifications/types';
import { v4 as uuidv4 } from 'uuid';

export const makeId = (toastId?: NotificationId) => {
  if (!toastId) {
    return uuidv4();
  }

  return toastId;
};
