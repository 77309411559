import { produce } from 'immer';
import { Draft } from '@reduxjs/toolkit';
import { MediaStreamType } from 'features/streaming/types';
import { MediaSource, BroadcastMediaStatus } from 'utils/broadcast-setup';

export type JoinPublisherActionType =
  | { type: 'statusUpdated'; payload: BroadcastMediaStatus }
  | {
      type: 'mediaUpdated';
      payload: {
        type: MediaStreamType;
        processing?: boolean;
        active?: boolean;
        disabled?: boolean;
        activateMedia?: boolean;
      };
    }
  | { type: 'accessDenied' }
  | { type: 'setMediaDisabled'; payload: { type: MediaStreamType; value: boolean } }
  | { type: 'setMediaActive'; payload: { type: MediaStreamType; value: boolean } }
  | { type: 'mediaActivated'; payload: MediaStreamType }
  | { type: 'mediaDeactivated'; payload: MediaStreamType }
  | {
      type: 'mediaUnblocked';
      payload: MediaStreamType;
    };

type State = {
  status: BroadcastMediaStatus;
  audio: MediaSource;
  video: MediaSource;
};

export const mediaSourceDefaultValue = {
  active: false,
  disabled: false,
  processing: false,
};

export const initialState: State = {
  status: 'prompt',
  audio: {
    ...mediaSourceDefaultValue,
  },
  video: {
    ...mediaSourceDefaultValue,
  },
};

// eslint-disable-next-line consistent-return
export const reducer = produce((state: Draft<State>, action: JoinPublisherActionType) => {
  switch (action.type) {
    case 'statusUpdated': {
      state.status = action.payload;
      break;
    }
    case 'mediaUpdated': {
      const { type, activateMedia, ...rest } = action.payload;

      const updatedMediaType = {
        ...state[type],
        ...rest,
      };

      if (activateMedia) {
        updatedMediaType.active = true;
        updatedMediaType.disabled = false;
      }

      return { ...state, [type]: updatedMediaType };
    }
    case 'setMediaActive': {
      state[action.payload.type].active = action.payload.value;
      break;
    }
    case 'setMediaDisabled': {
      state[action.payload.type].active = action.payload.value;
      break;
    }
    case 'mediaActivated': {
      state[action.payload].active = true;
      state[action.payload].disabled = false;
      break;
    }
    case 'mediaDeactivated': {
      state[action.payload].active = false;
      state[action.payload].disabled = true;
      break;
    }
    case 'mediaUnblocked': {
      state[action.payload].disabled = false;
      break;
    }
    default:
      throw new Error('Undefined action');
  }
});
