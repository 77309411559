import VideoPlaceholder from 'components/VideoPlaceholder';
import getBrowserPermissionsDocLink from 'utils/getBrowserPermissionsDocLink';
import Header from 'features/join/publisher/components/Header';
import { Trans, useTranslation } from 'react-i18next';
import AspectRatio from 'components/AspectRatio';
import { JoinScreenInner, JoinScreenInnerWide } from 'features/join/styles';
import { Box, Button, Grid, Typography } from '@mui/material';
import { BroadcastMediaStatus } from 'utils/broadcast-setup';
import AwaitingRoomJoin from 'features/join/shared/AwaitingRoomJoin';
import React from 'react';
import { useAppSelector } from 'store/hooks';
import { selectAwaitingRoomJoin } from 'features/room/roomSlice';

type DevicesErrorProps = {
  joinWithoutDevices: () => void;
  status: BroadcastMediaStatus;
};

const DevicesError = ({ joinWithoutDevices, status }: DevicesErrorProps) => {
  const { t } = useTranslation('join');

  const awaitingRoomJoin = useAppSelector(selectAwaitingRoomJoin);

  const renderJoinButton = () => {
    if (awaitingRoomJoin) {
      return (
        <Box
          sx={{
            mt: 7,
          }}
        >
          <AwaitingRoomJoin />
        </Box>
      );
    }

    return (
      <Button
        sx={{
          mt: 6,
        }}
        type="button"
        variant="contained"
        fullWidth
        onClick={joinWithoutDevices}
      >
        {t('publisher.btn_join_without_devices_label')}
      </Button>
    );
  };

  return (
    <JoinScreenInnerWide>
      <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
        <Header />
      </Box>
      <Grid container spacing={0} alignItems="center">
        <Grid item xs={12} lg={7}>
          <Box mb={8}>
            <AspectRatio>
              <VideoPlaceholder />
            </AspectRatio>
          </Box>
        </Grid>
        <Grid item xs={12} lg={5} flexShrink={0}>
          <JoinScreenInner sx={{ ml: { lg: 10 } }}>
            <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
              <Header />
            </Box>
            <Box textAlign="center">
              <Typography
                sx={{
                  whiteSpace: 'pre-line',
                }}
              >
                {status === 'failure' ? (
                  t('publisher.errors.user_media_failed')
                ) : (
                  <>
                    <Trans
                      i18nKey="join:publisher.errors.devices_blocked"
                      t={t}
                      components={[
                        // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
                        <a
                          href={getBrowserPermissionsDocLink()}
                          target="_blank"
                          rel="noopener noreferrer"
                        />,
                      ]}
                    />
                    <Typography
                      component="span"
                      sx={{
                        display: 'block',
                        mt: 6,
                      }}
                    >
                      {t('publisher.errors.divider')}
                    </Typography>
                  </>
                )}
              </Typography>
              {renderJoinButton()}
            </Box>
          </JoinScreenInner>
        </Grid>
      </Grid>
    </JoinScreenInnerWide>
  );
};

export default DevicesError;
