import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { lowerHand, raiseHand, selectLocalUserHandRaised } from 'features/users/usersSlice';
import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { PermissionTypes } from 'features/permissions/types';
import { usePermissions } from 'features/permissions/usePermissions';

export const useRaiseHandControl = () => {
  const { t } = useTranslation('room');

  const dispatch = useAppDispatch();

  const handRaised = useAppSelector(selectLocalUserHandRaised);

  const { hasPermissions } = usePermissions();
  const isFeatureEnabled = useFeatureFlag('raiseHand');

  const permissionsGranted = hasPermissions(PermissionTypes.raiseHand);
  const isFeatureAvailable = isFeatureEnabled && permissionsGranted;

  const toggleControl = () => {
    if (handRaised) {
      dispatch(lowerHand());
    } else {
      dispatch(raiseHand());
    }
  };

  const label = handRaised ? t('toolbar.hand_lower') : t('toolbar.hand_raise');

  return {
    active: handRaised,
    toggleControl,
    icon: 'raise-hand',
    label,
    isFeatureAvailable,
  };
};
