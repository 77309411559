import { styled } from '@mui/material/styles';
import Icon from 'components/Icon';

const Root = styled('div')({
  position: 'absolute',
  zIndex: 1,
  top: '8px',
  left: '8px',
});

const StyledIcon = styled(Icon)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
  fontSize: theme.typography.pxToRem(28),
  borderRadius: '50%',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 4,
  width: 32,
  height: 32,
}));

export const HandRaiseBadge = () => (
  <Root>
    <StyledIcon name="raise-hand" />
  </Root>
);
