import { call, select } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { selectContentLibraryOpenedFileCurrentPage } from 'features/content-library/contentLibrarySlice';
import { contentLibraryChangeFilePageRequested } from 'features/content-library/actions';

export function* changeContentLibraryFileSaga(
  action: ReturnType<typeof contentLibraryChangeFilePageRequested>
) {
  const { id, page } = action.payload;

  const currentPage: number = yield select(selectContentLibraryOpenedFileCurrentPage);
  if (currentPage === page) {
    return;
  }

  yield call(SignalingSocket.send, {
    event: 'changeLibraryFilePage',
    data: {
      id,
      page,
    },
  });
}
