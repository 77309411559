import { call } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { enabledFeatureRequested } from 'features/feature-set/actions';

export function* enableFeatureSaga(action: ReturnType<typeof enabledFeatureRequested>) {
  yield call(SignalingSocket.send, {
    event: 'enableFeature',
    data: { feature: action.payload.feature },
  });
}
