import { styled } from '@mui/material/styles';
import {
  ToolbarOrientation,
  ToolbarPlacement,
  ToolbarOrientationStylePreset,
} from 'features/toolbar/types';

const rootPlacementPosition: ToolbarOrientationStylePreset = {
  left: {
    top: 0,
    left: 0,
  },
  right: {
    top: 0,
    right: 0,
  },
  bottom: {
    left: 0,
    right: 0,
  },
};

export const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'placement' && prop !== 'orientation',
})<{ placement: ToolbarPlacement; orientation: ToolbarOrientation }>(
  ({ theme, placement, orientation }) => ({
    position: 'absolute',
    bottom: 0,
    ...rootPlacementPosition[placement],
    width: theme.toolbar.width,
    height: '100%',
    padding: theme.layout.innerPadding,
    paddingRight: 0,
    ...(placement === 'right' && {
      paddingLeft: 0,
      paddingRight: theme.layout.innerPadding,
    }),
    ...(orientation === 'horizontal' && {
      paddingTop: 0,
      paddingRight: theme.layout.innerPadding,
      width: '100%',
      height: theme.toolbar.width,
    }),
  })
);

export const Inner = styled('nav', {
  shouldForwardProp: (prop) => prop !== 'orientation',
})<{ orientation: ToolbarOrientation }>(({ theme, orientation }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  flexShrink: 0,
  background: theme.toolbar.background,
  color: theme.palette.common.white,
  borderRadius: '8px',
  zIndex: theme.zIndex.appBar,
  position: 'relative',
  ...(orientation === 'horizontal' && {
    flexDirection: 'row',
  }),
}));

export const ToolbarNavRoot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'orientation',
})<{ orientation: ToolbarOrientation }>(({ orientation, theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: `${theme.layout.innerPadding} 0`,
  ...(orientation === 'horizontal' && {
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    padding: `0 ${theme.layout.innerPadding}`,
    '.ToolbarItem-root': {
      padding: '0 var(--toolbar-item-padding)',
    },
  }),
}));

export const MenuFixedItems = styled('div', {
  shouldForwardProp: (prop) => prop !== 'orientation',
})<{ orientation: ToolbarOrientation }>(({ orientation }) => ({
  display: 'flex',
  flexDirection: orientation === 'horizontal' ? 'row' : 'column',
  flexGrow: 1,
}));

export const ToolbarBg = styled('div')(({ theme }) => ({
  backgroundColor: theme.room.background,
  position: 'absolute',
  inset: 0,
  zIndex: 1,
}));
