import { userAgentDetails } from 'utils/userAgentDetails';

function getBrowserPermissionsDocLink() {
  const browser = userAgentDetails().browser.name?.toLowerCase();

  switch (browser) {
    case 'chrome':
      return 'https://support.google.com/chrome/answer/114662';
    case 'firefox':
      return 'https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions#w_using-prompts-to-allow-or-block-camera-and-microphone-permissions-for-a-site';
    case 'safari':
      return 'https://support.apple.com/en-in/guide/safari/ibrwe2159f50/mac';
    case 'mobile safari':
      return 'https://support.apple.com/en-in/guide/iphone/iphb3100d149/ios';
    case 'opera':
      return 'https://help.opera.com/en/latest/web-preferences/#camera';
    case 'edge':
      return 'https://support.microsoft.com/en-us/windows/windows-camera-microphone-and-privacy-a83257bc-e990-d54a-d212-b5e41beba857';
    default:
      return 'https://support.google.com/chrome/answer/114662';
  }
}

export default getBrowserPermissionsDocLink;
