/**
 * Some browsers/devices will only allow audio to be played after a user interaction.
 * This util unlocks audio on the first user interaction automatically.
 *
 * It also creates a pool of unlocked HTML5 Audio objects
 * that can be used for playing sounds without user interaction.
 *
 * Concept from: http://paulbakaus.com/tutorials/html5/web-audio-on-ios/
 * Borrows in part from: https://github.com/goldfire/howler.js/blob/master/src/howler.core.js
 */

import { logger } from 'utils/logger';
import { singleton } from 'utils/singleton';
import * as Sentry from '@sentry/react';
import { AudioElement } from 'utils/webrtc/types';

export class UnlockedAudio extends Audio {
  unlocked: boolean = false;

  unloaded: boolean = true;
}

class Pool {
  ctx: AudioContext = new AudioContext();

  isUnlocked: boolean = false;

  pool: UnlockedAudio[] = [];

  poolSize: number = 1000;

  scratchBuffer?: AudioBuffer;

  globalMute: boolean = false;

  usedElements: Record<string, AudioElement> = {};

  tail: number = 0;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    /*
    It's a singleton class. Most of the stuff you would want to put in here could
    be safely made static or be called sometime later at execution time
    */
  }

  private releaseElement = (audio: UnlockedAudio) => {
    if (audio.unlocked) {
      this.pool.push(audio);
    }
  };

  initialize = () => {
    if (this.isUnlocked) {
      logger.debug('AudioPool: Audio context has been unlocked already');
      return;
    }

    logger.debug('AudioPool: auto unlock: started', this.ctx.state);

    const unlock = () => {
      // Scratch buffer to prevent memory leaks on iOS.
      // See: https://stackoverflow.com/questions/24119684/web-audio-api-memory-leaks-on-mobile-platforms
      this.scratchBuffer = this.ctx.createBuffer(1, 1, 22050);

      // Create a pool of unlocked HTML5 Audio objects
      while (this.pool.length < this.poolSize) {
        try {
          const audio = new UnlockedAudio();

          // Mark this Audio object as unlocked to ensure it can get returned
          // to the unlocked pool when released.
          audio.unlocked = true;

          audio.id = `unlocked-audio-${this.tail}`;
          this.tail += 1;

          this.releaseElement(audio);
        } catch (error) {
          Sentry.captureException(error);
          logger.error('AudioPool: Error creating unlocked audio', error);
          break;
        }
      }

      const source = this.ctx.createBufferSource();
      source.buffer = this.scratchBuffer;
      source.connect(this.ctx.destination);
      source.start(0);

      this.ctx.resume().then(() => {
        logger.debug('AudioPool: auto unlock: context resumed');
      });

      source.onended = () => {
        source.disconnect(0);
        this.isUnlocked = true;

        document.removeEventListener('touchstart', unlock, true);
        document.removeEventListener('click', unlock, true);

        logger.debug('AudioPool: auto unlock: should be fine now');
      };
    };

    document.addEventListener('touchstart', unlock, true);
    document.addEventListener('click', unlock, true);
  };

  obtainElement = async (): Promise<UnlockedAudio | HTMLAudioElement | null> => {
    logger.debug('AudioPool: obtaining element');
    if (this.pool.length) {
      const element = this.pool.pop();
      if (!element) {
        return null;
      }

      element.unloaded = false;

      // make sure the AudioContext isn't suspended, and resume it if it is.
      if (this.ctx.state === 'suspended') {
        await this.ctx.resume();
      }

      this.usedElements[element.id] = element;

      element.volume = this.globalMute ? 0 : 1;

      return element;
    }

    const testSound = new Audio();
    testSound.play().catch(() => {
      logger.warn('Audio pool exhausted, returning potentially locked audio object.');
    });

    const unsafeAudio = this.obtainUnsafeAudio();
    this.usedElements[unsafeAudio.id] = unsafeAudio;

    return unsafeAudio;
  };

  private obtainUnsafeAudio = () => {
    const unsafeAudio = new Audio();

    this.tail += 1;

    unsafeAudio.id = `unlocked-audio-${this.tail}`;

    unsafeAudio.volume = this.globalMute ? 0 : 1;

    return unsafeAudio;
  };

  unloadElement = (audio: UnlockedAudio) => {
    logger.debug('AudioPool: unloading element');

    // stop audio playback
    audio.pause();

    // cleanup the source
    audio.srcObject = null;
    audio.removeAttribute('src');

    // remove common event listeners
    audio.onended = null;
    audio.onplay = null;
    audio.oncanplaythrough = null;

    // reload element state
    audio.load();
    audio.unloaded = true;

    delete this.usedElements[audio.id];

    this.releaseElement(audio);
  };

  muteAll = () => {
    this.globalMute = true;

    for (const audio of Object.values(this.usedElements)) {
      if (audio) {
        audio.volume = 0;
      }
    }
  };

  unmuteAll = () => {
    this.globalMute = false;

    const usedElementsValues = Object.values(this.usedElements);
    for (const audio of usedElementsValues) {
      if (audio) {
        audio.volume = 1;
      }
    }
  };
}

export const AudioPool = singleton<Pool>(() => new Pool());
