import { MenuItem } from '@mui/material';
import ToolbarMenuItem from 'features/toolbar/ToolbarMenuItem/ToolbarMenuItem';
import { useTranslation } from 'react-i18next';
import { useEndSessionControl } from 'features/toolbar/Toolbar/controls/end-call/useEndSessionControl';

const EndCallControl = () => {
  const { t } = useTranslation('room');

  const {
    leaveSession,
    endSession,
    endSessionAvailable,
    icon,
    leaveBreakoutAvailable,
    leaveBreakout,
  } = useEndSessionControl();

  const showNestedControls = endSessionAvailable || leaveBreakoutAvailable;

  const overlayLabel = leaveBreakoutAvailable
    ? t('toolbar.leave_breakout')
    : t('toolbar.end_session');

  if (showNestedControls) {
    return (
      <ToolbarMenuItem
        menu
        overlay={overlayLabel}
        ariaLabel={overlayLabel}
        icon={icon}
        id="end-call"
      >
        {leaveBreakoutAvailable ? (
          <MenuItem onClick={leaveBreakout}>
            {t('toolbar.end_session_options.leave_breakout')}
          </MenuItem>
        ) : null}
        <MenuItem onClick={leaveSession}>{t('toolbar.end_session_options.leave_session')}</MenuItem>
        {endSessionAvailable ? (
          <MenuItem
            sx={{
              color: 'error.main',
            }}
            onClick={endSession}
          >
            {t('toolbar.end_session_options.close_session')}
          </MenuItem>
        ) : null}
      </ToolbarMenuItem>
    );
  }

  return (
    <ToolbarMenuItem
      overlay={t('toolbar.leave_session')}
      ariaLabel={t('toolbar.leave_session')}
      icon={icon}
      id="end-call"
      onClick={leaveSession}
    />
  );
};

export default EndCallControl;
