import { useEffect } from 'react';

export const useWindowHeight = () => {
  useEffect(() => {
    const heightWatcher = () => {
      document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
    };

    window.addEventListener('resize', heightWatcher);
    window.addEventListener('orientationchange', heightWatcher);
    window.screen.orientation?.addEventListener('change', heightWatcher);

    heightWatcher();

    return () => {
      window.removeEventListener('resize', heightWatcher);
      window.removeEventListener('orientationchange', heightWatcher);
      window.screen.orientation?.removeEventListener('change', heightWatcher);
    };
  }, []);
};
