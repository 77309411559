import * as Sentry from '@sentry/react';
import { openModal } from 'features/modal/modalSlice';
import { store } from 'store/store';
import { logger } from 'utils/logger';
import { ControlledReceivingHandle } from 'utils/webrtc/ControlledReceivingHandle';
import { RTCClient } from 'utils/webrtc/index';
import { ReceivingFeed } from 'utils/webrtc/ReceivingFeed';
import { JanusConnection, ReceivingNegotiationMeta } from 'utils/webrtc/types';

export class BaseReceiver {
  connection?: JanusConnection;

  feed: ReceivingFeed;

  plugin?: ControlledReceivingHandle;

  constructor(receivingFeed: ReceivingFeed) {
    this.feed = receivingFeed;
  }

  negotiationMeta: ReceivingNegotiationMeta = {
    iceRestartAttempt: false,
  };

  iceRestartHandler: (feed: ControlledReceivingHandle) => Promise<void> = async () => {};

  retrySubscription: (
    connectionHandle: string,
    transactionId: string,
    errorMessage: string
  ) => void = () => {};

  onIceState = (state: RTCIceConnectionState) => {
    logger
      .remote({ system: true, capture: 'streaming' })
      .debug('Receiving feed: ice state:', state);

    const restore = (reason: 'disconnected' | 'failed') => {
      if (RTCClient.supressErrors) {
        return;
      }

      window.clearTimeout(this.negotiationMeta.iceRestartTimer);

      if (!this.negotiationMeta.iceRestartAttempt) {
        this.negotiationMeta.iceRestartTimer = window.setTimeout(async () => {
          const feed = this.plugin;
          if (!feed) {
            return;
          }

          logger
            .remote({ capture: 'streaming' })
            .warn(`Subscribing peer connection ${reason}. Trying to recover...`);

          this.negotiationMeta.iceRestartAttempt = true;

          await this.iceRestartHandler(feed);
        }, 5000);

        return;
      }

      const message = 'Subscribing peer connection failed';
      logger.remote({ capture: 'streaming' }).error(message);
      Sentry.captureException(new Error(message));

      store.dispatch(openModal('peerConnectionFailure'));
    };

    if (state === 'connected') {
      window.clearTimeout(this.negotiationMeta.iceRestartTimer);
      this.negotiationMeta.iceRestartAttempt = false;
    }

    if (state === 'disconnected' || state === 'failed') {
      restore(state);
    }
  };
}
