import { call } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { deleteRoomRequested } from 'features/breakout-rooms/actions';

export function* deleteBreakoutRoomSaga(action: ReturnType<typeof deleteRoomRequested>) {
  yield call(SignalingSocket.send, {
    event: 'deleteBreakoutRoom',
    data: {
      id: action.payload,
    },
  });
}
