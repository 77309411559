import WaitingScreen from 'components/WaitingScreen/WaitingScreen';
import { useAppSelector } from 'store/hooks';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { selectRoomError } from 'features/room/roomSlice';

const RoomError = () => {
  const { t } = useTranslation('waiting-screens');
  const roomError = useAppSelector(selectRoomError);

  return (
    <WaitingScreen show={roomError.global} title={roomError.title}>
      <Typography>{roomError.message || t('room.error')}</Typography>
    </WaitingScreen>
  );
};

export default RoomError;
