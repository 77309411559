import { logger } from 'utils/logger';
import * as Sentry from '@sentry/react';
import { isError } from 'utils/types';

export const GENERIC_JANUS_ERROR = (source: string) => (error: unknown, response?: Response) => {
  const message = isError(error) ? error.message : error;
  logger.remote({ system: true }).error(`WebRTC client error in ${source}:`, message, response);

  Sentry.captureException(typeof message === 'string' ? new Error(message) : error, {
    ...(response && {
      extra: {
        response,
      },
    }),
  });
};

export const NO_ROOM_ID = () => new Error('No room ID set for RTCClient');
export const NO_ROOM_PIN = () => new Error('No room pin set for RTCClient');
export const NO_PUBLISHING_FEED = (handle: string) =>
  new Error(`No publishing feed identified by "${handle}" present`);
export const JOIN_ROOM_ERROR = (details: string) => new Error(`Can't join room: ${details}`);

export const NO_REPLICATED_CONNECTION = (handle: string) =>
  new Error(`No media server connection identified by handle=${handle} present`);
