import { call, put, select } from 'redux-saga/effects';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { senderKeyRatchetFinished, senderKeyRatchetStarted } from 'features/e2ee/e2eeSlice';
import { logger } from 'utils/logger';
import { selectMessageSentWithCurrentKey } from 'features/chat/chatSlice';

export function* ratchetApplicationKeySaga() {
  const messageIsSent: boolean = yield select(selectMessageSentWithCurrentKey);
  if (!messageIsSent) {
    // don't ratchet sender key when no messages were sent with the current key
    return;
  }

  const isGeneratingSecret: boolean = yield call(E2EEManager.applicationKey.getIsGenerating);
  if (isGeneratingSecret) {
    // skip ratcheting since the new key will still be generated

    logger
      .remote({ system: true, capture: 'e2ee' })
      .debug('The application encryption key is refreshing. Skipping the ratcheting');

    return;
  }

  logger
    .remote({ system: true, capture: 'e2ee' })
    .debug("Ratchet sender's application encryption key");

  yield put(senderKeyRatchetStarted('application'));

  yield call(E2EEManager.applicationKey.ratchet);

  yield put(senderKeyRatchetFinished('application'));
}
