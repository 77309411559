import { put, select } from 'redux-saga/effects';
import {
  captionsToggled,
  selectCaptionsDisplayed,
} from 'features/transcripts/slices/captions/captionsSlice';

export function* disableCaptionsSaga() {
  const captionsDisplayed: boolean = yield select(selectCaptionsDisplayed);
  if (captionsDisplayed) {
    yield put(captionsToggled(false));
  }
}
