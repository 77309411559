import { ChatMessage, ChatState } from 'features/chat/types';
import { getPrivateChatSenderId } from 'features/chat/utils/getPrivateChatSenderId';
import { getPrivateChatTarget } from 'features/chat/utils/getPrivateChatTarget';

export const processMessage = (state: ChatState, message: ChatMessage) => {
  const { entities, senders, privateChat, localUserId, localUserExternalId, publicIds } = state;
  const { id, user, privateGroup, targetUser, date } = message;

  // prevent duplicates
  const isAlreadyExists = !!entities[id];

  entities[id] = message;

  const key = getPrivateChatSenderId(user);
  senders[key] = user;

  if (privateGroup) {
    const targetId = state.privateChat.groupChat.id;
    const chatToUpdate = privateChat.chats[targetId] || {
      lastActivity: null,
      group: null,
      messages: [],
    };

    chatToUpdate.lastActivity = date;
    chatToUpdate.group = privateGroup;

    if (!isAlreadyExists) {
      chatToUpdate.messages.push(id);
    }

    privateChat.chats[targetId] = chatToUpdate;
  } else if (targetUser) {
    const { targetChatId, isLocalTarget, chatTargetUser } = getPrivateChatTarget({
      localUserId,
      localUserExternalId,
      user,
      targetUser,
    });

    const chatToUpdate = privateChat.chats[targetChatId] || {
      lastActivity: null,
      messages: [],
    };

    chatToUpdate.lastActivity = date;
    chatToUpdate.externalId = chatTargetUser.externalId;

    if (!isAlreadyExists) {
      chatToUpdate.messages.push(id);
    }

    privateChat.chats[targetChatId] = chatToUpdate;

    if (!isLocalTarget) {
      senders[targetChatId] = chatTargetUser;
    }
  } else if (!isAlreadyExists) {
    publicIds.push(id);
  }
};
