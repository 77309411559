import { call, select } from 'redux-saga/effects';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { PayloadAction } from '@reduxjs/toolkit';
import { EncryptionKeySharedPayload } from 'features/e2ee/types';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';

export function* onEncryptionKeySharedSaga(action: PayloadAction<EncryptionKeySharedPayload>) {
  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
  if (e2eeEnabled) {
    const { initiator, streamKey, applicationKey, name } = action.payload;

    yield call(E2EEManager.decryptRemoteData, initiator.id, { applicationKey, streamKey, name });
  }
}
