import WaitingScreen from 'components/WaitingScreen/WaitingScreen';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

const UserKicked = () => {
  const { t } = useTranslation('waiting-screens');

  return (
    <WaitingScreen show>
      <Typography>{t('user_kicked.text')}</Typography>
    </WaitingScreen>
  );
};

export default UserKicked;
