import { Box } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { ChipButton, ChipGroup, ChipLabel } from 'components/ChipGroup';
import Icon from 'components/Icon';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useAppSelector } from 'store/hooks';
import { selectContentLibraryOpenedFileCurrentPage } from 'features/content-library/contentLibrarySlice';

export const GalleryPagination = ({ goToPage }: { goToPage: (index: number) => void }) => {
  const { isMobile } = useBreakpoints();

  const currentPage = useAppSelector(selectContentLibraryOpenedFileCurrentPage);

  const goBack = () => {
    goToPage(currentPage - 1);
  };

  const goForward = () => {
    goToPage(currentPage + 1);
  };

  if (isMobile) {
    return null;
  }

  return (
    <Box
      className="hover-child"
      sx={{
        position: 'absolute',
        bottom: '8px',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 3,
      }}
    >
      <ChipGroup
        sx={(theme) => ({ height: 30, backgroundColor: alpha(theme.palette.common.black, 0.7) })}
      >
        <ChipButton onClick={goBack}>
          <Icon name="back" />
        </ChipButton>
        <ChipLabel>{currentPage + 1}</ChipLabel>
        <ChipButton onClick={goForward}>
          <Icon name="forward" />
        </ChipButton>
      </ChipGroup>
    </Box>
  );
};
