import { selectVirtualBackgroundStatus } from 'features/virtual-backgrounds/selectors';
import { call, put, select } from 'redux-saga/effects';
import { RTCClient } from 'utils/webrtc';
import {
  virtualBackgroundChanged,
  virtualBackgroundChangeStarted,
  virtualBackgroundFailure,
} from 'features/virtual-backgrounds/virtualBackgroundsSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import { SelectedVirtualBackground } from 'utils/webrtc/VirtualBackground';
import { selectLocalVideoBroadcasting } from 'features/streaming/streamingSlice';
import { VirtualBackgroundsStatus } from 'features/virtual-backgrounds/types';

export function* changeVirtualBackgroundSaga(action: PayloadAction<SelectedVirtualBackground>) {
  const videoBroadcasting: boolean = yield select(selectLocalVideoBroadcasting);
  if (!videoBroadcasting) {
    yield put(virtualBackgroundChanged(action.payload));
    return;
  }

  const status: VirtualBackgroundsStatus = yield select(selectVirtualBackgroundStatus);
  if (status === 'updating') {
    return;
  }

  yield put(virtualBackgroundChangeStarted());

  try {
    yield call(RTCClient.publishingFeed.changeVirtualBackground, action.payload);
    yield put(
      virtualBackgroundChanged({
        type: action.payload.type,
        value: action.payload.value,
      })
    );
  } catch (error) {
    yield put(virtualBackgroundFailure());
  }
}
