import { openModal } from 'features/modal/modalSlice';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { selectRemoteBroadcastingState } from 'features/streaming/streamingSlice';
import { User, UserId } from 'features/users/types';
import { selectUserById } from 'features/users/usersSlice';
import { call, put, select } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';

export function* onControlRemoteAudioCommandSaga(
  _muteRequested: boolean | undefined,
  userId: UserId
) {
  const isBroadcasting: boolean = yield select(selectRemoteBroadcastingState, userId, 'audio');
  const user: User = yield select(selectUserById, userId);

  const muteRequested = _muteRequested === undefined ? isBroadcasting : _muteRequested;

  if (muteRequested !== isBroadcasting) {
    // already in that state. Ignore command;

    return;
  }

  const targetCanBroadcast: boolean = yield call(hasPermissionsSaga, PermissionTypes.broadcast, {
    targetUser: user,
  });

  if (muteRequested) {
    const canMute: boolean = yield call(hasPermissionsSaga, PermissionTypes.remoteMuting, {
      targetRole: user.role,
    });

    if (!canMute) {
      yield call(
        eventBus.forbiddenActionError,
        'Forbidden action. This participant is not allowed to remotely mute',
        'remote-mute'
      );

      return;
    }

    if (!targetCanBroadcast) {
      yield call(
        eventBus.forbiddenActionError,
        'Forbidden action. Target user is not allowed to broadcast',
        'remote-mute'
      );

      return;
    }

    yield put(
      openModal('muteRemoteAudioConfirmation', {
        userId,
      })
    );
  } else {
    const canUnmute: boolean = yield call(hasPermissionsSaga, PermissionTypes.askRemoteUnmute, {
      targetRole: user.role,
    });

    if (!canUnmute) {
      yield call(
        eventBus.forbiddenActionError,
        'Forbidden action. This participant is not allowed to request to unmute',
        'remote-unmute'
      );

      return;
    }

    if (!targetCanBroadcast) {
      yield call(
        eventBus.forbiddenActionError,
        'Forbidden action. Target user is not allowed to broadcast',
        'remote-unmute'
      );

      return;
    }

    yield put(
      openModal('unmuteRemoteAudioConfirmation', {
        userId,
      })
    );
  }
}
