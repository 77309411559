import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from 'components/Dialog';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

type SetupCancelledProps = {
  open: boolean;
  onClose: () => void;
};

const SetupCancelledModal = ({ open, onClose }: SetupCancelledProps) => {
  const { t } = useTranslation('room');

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      aria-labelledby="broadcast-setup-cancelled-title"
      aria-describedby="broadcast-setup-cancelled-description"
      onClose={onClose}
    >
      <DialogTitle id="broadcast-setup-cancelled-title" onClose={onClose}>
        {t('broadcast_setup.cancel.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="broadcast-setup-cancelled-description">
          {t('broadcast_setup.cancel.description')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('broadcast_setup.cancel.btn_ok_label')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SetupCancelledModal;
