import { establishPublishingConnectionSaga } from 'features/streaming/sagas/establishPublishingConnectionSaga';
import { call, fork, put, select } from 'redux-saga/effects';
import { Action, PayloadAction } from '@reduxjs/toolkit';
import { RoleChangedPayload, User, UserId } from 'features/users/types';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes, RoleEntries } from 'features/permissions/types';
import {
  broadcastPermissionGranted,
  broadcastPermissionRevoked,
  filesPermissionRevoked,
  whiteboardPermissionGranted,
  whiteboardPermissionRevoked,
} from 'features/permissions/actions';
import { changeRole, selectLocalUserId, selectUserById } from 'features/users/usersSlice';
import { eventBus } from 'utils/eventBus';
import { logger } from 'utils/logger';
import { selectRoleEntries } from 'features/permissions/permissionsSlice';
import { notification } from 'features/notifications/toast/notification';
import i18n from 'i18n';
import { hasDynamicPermissionsSaga } from 'features/permissions/sagas/hasDynamicPermissionsSaga';
import { RTCClient } from 'utils/webrtc';
import { clearWaitingUsersList } from 'features/users/actions';
import { WAITING_ENTRY_NOTIFICATION_ID } from 'features/notifications/constants';
import { closeActionNotification } from 'features/notifications/thunks/closeActionNotification';
import { raiseHandNotificationUpdateRequested } from 'features/notifications/actions';
import { cleanBreakoutRooms } from 'features/breakout-rooms/breakoutRoomsSlice';
import { questionAnswerTypeResetRequested } from 'features/qa/actions';
import { syncChatCountersWithPermissionsSaga } from 'features/chat/sagas/syncChatCountersWithPermissionsSaga';
import { syncRoleChangePollNotificationsSaga } from 'features/polls/sagas/syncRoleChangePollNotificationsSaga';

export function* onRoleChangedSaga({ payload }: PayloadAction<RoleChangedPayload>) {
  const { role, id, initiator } = payload;

  const user: User = yield select(selectUserById, id);
  if (!user) {
    return;
  }

  yield put(changeRole(payload));

  const localUserId: UserId = yield select(selectLocalUserId);

  const affectsLocalUser = localUserId === id;

  if (affectsLocalUser && initiator) {
    logger
      .remote({ tier: 1 })
      .info(`The user's role was changed to ${role} by user id=${initiator.id}`);

    const initiatorUser: User = yield select(selectUserById, initiator.id);

    const roles: RoleEntries = yield select(selectRoleEntries);

    yield call(
      notification,
      i18n.t('notifications:role_changed', {
        username: initiatorUser.name,
        role: roles[role].displayName,
      })
    );
  }

  if (affectsLocalUser) {
    const userCanControlRoomEntry: boolean = yield call(
      hasPermissionsSaga,
      PermissionTypes.controlRoomEntry
    );

    // clear waiting users
    if (!userCanControlRoomEntry) {
      yield put(clearWaitingUsersList());

      // hide waiting entry action notification
      const cleanupNotificationThunk: Action = yield call(
        closeActionNotification,
        WAITING_ENTRY_NOTIFICATION_ID
      );
      yield put(cleanupNotificationThunk);
    }

    const userCanManageBreakoutRooms: boolean = yield call(
      hasPermissionsSaga,
      PermissionTypes.manageBreakout
    );

    // clear breakout rooms
    if (!userCanManageBreakoutRooms) {
      yield put(cleanBreakoutRooms());
    }

    const userCanAnswer: boolean = yield call(hasPermissionsSaga, PermissionTypes.answerQA);

    // clean up answers state
    if (!userCanAnswer) {
      yield put(questionAnswerTypeResetRequested());
    }

    yield fork(syncChatCountersWithPermissionsSaga);

    // sync poll notifications
    yield fork(syncRoleChangePollNotificationsSaga);
  }

  yield put(raiseHandNotificationUpdateRequested({ reason: 'role-changed' }));

  const { role: oldRole } = user;

  const oldRoleBroadcastAllowed: boolean = yield call(
    hasPermissionsSaga,
    PermissionTypes.broadcast,
    {
      role: oldRole,
    }
  );

  const oldRoleScreenshareAllowed: boolean = yield call(
    hasPermissionsSaga,
    PermissionTypes.screenshare,
    {
      role: oldRole,
    }
  );

  const oldRoleWBAllowed: boolean = yield call(hasPermissionsSaga, PermissionTypes.editWhiteboard, {
    role: oldRole,
  });

  const oldRoleCanPresent: boolean = yield call(
    hasPermissionsSaga,
    PermissionTypes.presentContentLibrary,
    {
      role: oldRole,
    }
  );

  const newRoleBroadcastAllowed: boolean = yield call(
    hasPermissionsSaga,
    PermissionTypes.broadcast,
    {
      role,
    }
  );

  const newRoleScreenshareAllowed: boolean = yield call(
    hasPermissionsSaga,
    PermissionTypes.screenshare,
    {
      role,
    }
  );

  const newRoleWBAllowed: boolean = yield call(hasPermissionsSaga, PermissionTypes.editWhiteboard, {
    role,
  });

  const newRoleCanPresent: boolean = yield call(
    hasPermissionsSaga,
    PermissionTypes.editWhiteboard,
    {
      role,
    }
  );

  const permissionsGranted = !oldRoleBroadcastAllowed && newRoleBroadcastAllowed;
  const permissionsRevoked = oldRoleBroadcastAllowed && !newRoleBroadcastAllowed;

  const screensharePermissionsGranted = !oldRoleScreenshareAllowed && newRoleScreenshareAllowed;
  const screensharePermissionsRevoked = oldRoleScreenshareAllowed && !newRoleScreenshareAllowed;

  const whiteboardPermissionsGranted = !oldRoleWBAllowed && newRoleWBAllowed;
  const whiteboardPermissionsRevoked = oldRoleWBAllowed && !newRoleWBAllowed;

  const filesPermissionsRevoked = oldRoleCanPresent && !newRoleCanPresent;

  if (affectsLocalUser && screensharePermissionsGranted) {
    yield call(establishPublishingConnectionSaga);
    yield call(eventBus.sendMessage, 'permissionsChanged', {}, { screenshare: true });
  }

  if (affectsLocalUser && screensharePermissionsRevoked) {
    yield call(RTCClient.screensharingFeed.stopScreenshare, false);
    yield call(eventBus.sendMessage, 'permissionsChanged', {}, { screenshare: false });
  }

  yield call(eventBus.sendMessage, 'roleChanged', {}, { userId: id, from: oldRole, to: role });

  const userHasDynamicBroadcast: boolean = yield call(
    hasDynamicPermissionsSaga,
    PermissionTypes.broadcast,
    {
      targetUser: user,
    }
  );

  if (whiteboardPermissionsGranted) {
    yield put(whiteboardPermissionGranted(payload));
  }

  if (whiteboardPermissionsRevoked) {
    yield put(whiteboardPermissionRevoked(payload));
  }

  if (filesPermissionsRevoked) {
    yield put(filesPermissionRevoked(payload));
  }

  if (userHasDynamicBroadcast) {
    // skip for the user with dynamic broadcast permissions
    return;
  }

  if (permissionsGranted) {
    yield put(broadcastPermissionGranted(payload));
    return;
  }

  if (permissionsRevoked) {
    yield put(broadcastPermissionRevoked(payload));
  }
}
