import { useTranslation } from 'react-i18next';
import { useParticipantsControl } from 'features/toolbar/Toolbar/controls/participants/useParticipantsControl';
import MobileMenuItem from 'features/toolbar/Toolbar/mobile/MobileMenuItem';
import React from 'react';
import { MobileMenuItemWrapperProps } from 'features/toolbar/types';

const ParticipantsControlMobile = ({ onClick }: MobileMenuItemWrapperProps) => {
  const { t } = useTranslation('room');

  const { active, togglePanel, totalParticipants, icon, isFeatureAvailable } =
    useParticipantsControl();

  if (!isFeatureAvailable) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    onClick?.(event);
    togglePanel();
  };

  return (
    <MobileMenuItem
      icon={icon}
      badgeContent={totalParticipants}
      selected={active}
      onClick={handleClick}
    >
      {t('toolbar.participants')}
    </MobileMenuItem>
  );
};

export default ParticipantsControlMobile;
