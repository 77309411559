import { PermissionsState, PermissionTypes } from 'features/permissions/types';
import { User } from 'features/users/types';
import { lookupDynamicPermission } from 'features/permissions/utils/lookupDynamicPermission';

export interface TargetRoleConfig {
  coverage: Coverage;
}

export type Coverage = 'all' | 'any';

export type HasPermissionsTargetRole = string | TargetRoleConfig;

export interface LookupPermissionOptions {
  permissionsState: PermissionsState;
  permission: PermissionTypes;
  role: string;
  targetRole?: HasPermissionsTargetRole;
  dynamicPermissions?: PermissionTypes[];
  coverage?: Coverage;
  isRecorder?: boolean;
}

export interface CheckPermissionsOptions extends Omit<LookupPermissionOptions, 'permission'> {
  permissions: PermissionTypes | PermissionTypes[];
}

export interface HasPermissionsOptions {
  targetRole?: HasPermissionsTargetRole;
  role?: string;
  targetUser?: User;
  coverage?: Coverage;
}

const lookupPermission = (options: LookupPermissionOptions) => {
  const {
    permissionsState: { rolePermissions, targetRolesMap, allRoles, coverageAcrossRoles },
    permission,
    targetRole,
    role,
    dynamicPermissions,
    coverage,
  } = options;

  if (coverage !== undefined) {
    if (coverage === 'all') {
      return coverageAcrossRoles[permission];
    }

    // @TODO implement "any" coverage
  }

  if (options.isRecorder) {
    return false;
  }

  if (dynamicPermissions) {
    const granted = lookupDynamicPermission(permission, dynamicPermissions);
    if (granted) {
      return true;
    }
  }

  if (rolePermissions[role][permission]) {
    return true;
  }

  if (targetRole) {
    if (typeof targetRole === 'string') {
      return Boolean(rolePermissions[role][`${permission}_${targetRole}`]);
    }

    const targetPermission = targetRolesMap[role][permission];
    if (!targetPermission) {
      return false;
    }

    if (targetRole.coverage === 'all') {
      return allRoles.length === targetPermission.length;
    }

    if (targetRole.coverage === 'any') {
      return targetPermission.length !== 0;
    }
  }

  return false;
};

export const checkPermissions = ({ permissions, ...options }: CheckPermissionsOptions) => {
  if (Array.isArray(permissions)) {
    return permissions.some((permission) => lookupPermission({ permission, ...options }));
  }

  return lookupPermission({
    permission: permissions,
    ...options,
  });
};
