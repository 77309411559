import { ComponentPropsWithRef, forwardRef } from 'react';
import cx from 'clsx';
import { styled, SxProps, Theme } from '@mui/material/styles';

export type IconProps = ComponentPropsWithRef<'span'> & { name: string; sx?: SxProps<Theme> };

const Root = styled('span')``;

const Icon = forwardRef<HTMLSpanElement, IconProps>(({ name, className, ...props }, ref) => (
  <Root className={cx('Icon-root', `icon-${name}`, className)} role="img" ref={ref} {...props} />
));

export default Icon;
