import React, { useCallback, useEffect, useId, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal, selectActiveModalData } from 'features/modal/modalSlice';
import { Trans, useTranslation } from 'react-i18next';
import { useBreakoutRooms } from 'features/breakout-rooms/useBreakoutRooms';
import { selectBreakoutRoom } from 'features/breakout-rooms/breakoutRoomsSlice';
import { Box } from '@mui/material';
import { assignUsersToBreakoutRoomRequested } from 'features/breakout-rooms/actions';
import Button from '@mui/material/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'components/Dialog';
import { UserId } from 'features/users/types';
import BreakoutsUsersSelector from 'features/breakout-rooms/components/breakouts-users-selector/BreakoutsUsersSelector';

export type ToggleUserHandler = (id: UserId) => void;
export type SelectedUserIds = UserId[];

const AddUsersToRoomModal = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const [selectedIds, setSelectedIds] = useState<SelectedUserIds>([]);

  const modalId = useId();

  const modalData = useAppSelector(selectActiveModalData);
  const roomId = modalData.roomId as string;

  const room = useAppSelector((state) => selectBreakoutRoom(state, roomId));

  const { isBreakoutsAvailable, participantsLimit, breakoutRoomsCreated } = useBreakoutRooms();

  const modalShouldBeClosed = !isBreakoutsAvailable || !breakoutRoomsCreated || !room;

  useEffect(() => {
    if (modalShouldBeClosed) {
      dispatch(closeModal('addUsersToBreakoutRoom'));
    }
  }, [modalShouldBeClosed, dispatch]);

  const handleCancel = () => {
    dispatch(closeModal('addUsersToBreakoutRoom'));
  };

  const handleOk = () => {
    dispatch(
      assignUsersToBreakoutRoomRequested({
        id: roomId,
        users: selectedIds,
      })
    );
    dispatch(closeModal('addUsersToBreakoutRoom'));
  };

  const handleUserToggle = useCallback(
    (id: UserId) => {
      const updatedIds = [...selectedIds];
      const currentIndex = selectedIds.indexOf(id);

      if (currentIndex === -1) {
        updatedIds.push(id);
      } else {
        updatedIds.splice(currentIndex, 1);
      }

      setSelectedIds(updatedIds);
    },
    [selectedIds]
  );

  const userLimitSurpassed = room
    ? room.users.length + selectedIds.length > participantsLimit
    : false;

  return (
    <Dialog
      open={!modalShouldBeClosed}
      maxWidth="xs"
      aria-labelledby={`${modalId}-title`}
      aria-describedby={`${modalId}-description`}
    >
      <DialogTitle id={`${modalId}-title`}>
        {t('breakout_rooms.add_participants_to_room.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id={`${modalId}-description`}
          sx={{
            color: userLimitSurpassed ? 'error.main' : 'inherit',
          }}
        >
          {userLimitSurpassed ? (
            t('breakout_rooms.add_participants_to_room.limit_surpassed_validation_error')
          ) : (
            <Trans
              i18nKey="modals:breakout_rooms.add_participants_to_room.description"
              t={t}
              values={{
                roomName: room?.name,
                participantsLimit,
              }}
              components={{
                bold: <strong />,
              }}
            />
          )}
        </DialogContentText>
        <Box
          sx={{
            mt: 6,
          }}
        >
          <BreakoutsUsersSelector
            selectedIds={selectedIds}
            toggleUser={handleUserToggle}
            participantsLimit={participantsLimit}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="grayscale">
          {t('breakout_rooms.add_participants_to_room.btn_cancel_label')}
        </Button>
        <Button onClick={handleOk} disabled={!selectedIds.length || userLimitSurpassed}>
          {t('breakout_rooms.add_participants_to_room.btn_ok_label')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUsersToRoomModal;
