import { call, put, select } from 'redux-saga/effects';
import {
  mediaDevicesUpdated,
  selectActiveMediaDevices,
  selectMediaDevicesByKind,
} from 'features/user-media/userMediaSlice';
import { enumerateDevices } from 'features/user-media/utils';
import { ActiveMediaDevices, EnumeratedMediaDevices } from 'features/user-media/types';
import {
  DeviceDifferences,
  findDeviceDifferences,
} from 'features/user-media/utils/findDeviceDifferences';
import { handleRemovedDevicesSaga } from 'features/streaming/sagas/handleRemovedDevicesSaga';
import { handleAddedDevicesSaga } from 'features/streaming/sagas/handleAddedDevicesSaga';
import { logger } from 'utils/logger';

export function* handleDeviceAutoChangeSaga() {
  const currentMediaDevices: EnumeratedMediaDevices = yield select(selectMediaDevicesByKind);
  const activeMediaDevices: ActiveMediaDevices = yield select(selectActiveMediaDevices);
  const updatedDevices: EnumeratedMediaDevices = yield call(enumerateDevices);

  const { removedActiveDevices, addedDevices }: DeviceDifferences = yield call(
    findDeviceDifferences,
    updatedDevices,
    currentMediaDevices,
    activeMediaDevices
  );

  if (Object.keys(removedActiveDevices).length) {
    logger
      .remote({ action: true })
      .info('User physically removed device(s),', removedActiveDevices);
    yield call(handleRemovedDevicesSaga, updatedDevices, removedActiveDevices);
  } else if (Object.keys(addedDevices).length) {
    logger.remote({ action: true }).info('User added device(s),', addedDevices);
    yield call(handleAddedDevicesSaga, updatedDevices, addedDevices);
  } else {
    yield put(
      mediaDevicesUpdated(updatedDevices, {
        updatePermissions: true,
      })
    );
  }
}
