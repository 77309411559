import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initVirtualBackground } from 'features/virtual-backgrounds/thunks/initVirtualBackground';
import { VirtualBackgroundsState } from 'features/virtual-backgrounds/types';
import { SelectedBackground } from 'utils/webrtc/VirtualBackground';

export const initialState: VirtualBackgroundsState = {
  initialized: false,
  status: 'idle',
  selected: {
    type: '',
    value: '',
  },
  customImageUrl: undefined,
  enforce: false,
  pendingConfig: undefined,
};

export const virtualBackgroundsSlice = createSlice({
  name: 'virtualBackground',
  initialState,
  reducers: {
    virtualBackgroundChangeStarted(state) {
      state.status = 'updating';
    },
    virtualBackgroundReset(state) {
      state.selected = {
        type: '',
        value: '',
      };
      state.pendingConfig = undefined;
    },
    virtualBackgroundChangeFulfilled(state) {
      state.status = 'idle';
    },
    virtualBackgroundChanged(state, action: PayloadAction<SelectedBackground>) {
      state.status = 'idle';
      state.selected = action.payload;
    },
    virtualBackgroundFailure(state) {
      state.status = 'error';
    },
    customImageApplied(state, action: PayloadAction<string>) {
      state.customImageUrl = action.payload;
    },
    virtualBackgroundEnforceToggled(state, action: PayloadAction<boolean>) {
      state.enforce = action.payload;
    },
    pendingConfigApplied(state, action: PayloadAction<SelectedBackground | number>) {
      state.pendingConfig = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initVirtualBackground.pending, (state) => {
        state.status = 'initializing';
      })
      .addCase(initVirtualBackground.fulfilled, (state) => {
        state.status = 'idle';
        state.initialized = true;
      })
      .addCase(initVirtualBackground.rejected, (state, { meta }) => {
        // if skipping because of thunk filter condition, avoid state change;
        if (!meta.condition) {
          state.status = 'error';
        }
      });
  },
});

export const {
  virtualBackgroundChanged,
  virtualBackgroundChangeStarted,
  virtualBackgroundFailure,
  virtualBackgroundReset,
  virtualBackgroundChangeFulfilled,
  customImageApplied,
  virtualBackgroundEnforceToggled,
  pendingConfigApplied,
} = virtualBackgroundsSlice.actions;

export default virtualBackgroundsSlice.reducer;
