import { call } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { logger } from 'utils/logger';
import { PayloadAction } from '@reduxjs/toolkit';
import { UserId } from 'features/users/types';

export function* kickUserSaga(action: PayloadAction<UserId>) {
  logger
    .remote({ action: true, tier: 1 })
    .info(`The user removed a participant with id=${action.payload}`);

  yield call(SignalingSocket.send, {
    event: 'kickUser',
    data: {
      id: action.payload,
    },
  });
}
