import {
  ACTION_NOTIFICATION_CONTAINER_ID,
  SIMPLE_NOTIFICATION_CONTAINER_ID,
} from 'features/notifications/constants';
import { notification } from 'features/notifications/toast/notification';
import { call, put } from 'redux-saga/effects';
import {
  actionNotificationsReset,
  simpleNotificationsReset,
} from 'features/notifications/notificationsSlice';

export function* clearAllNotificationsSaga() {
  // @ts-ignore
  yield call(notification.dismiss, { containerId: ACTION_NOTIFICATION_CONTAINER_ID });
  // @ts-ignore
  yield call(notification.dismiss, { containerId: SIMPLE_NOTIFICATION_CONTAINER_ID });

  yield put(simpleNotificationsReset());
  yield put(actionNotificationsReset());
}
