import { call, take } from 'redux-saga/effects';
import { SFrameManager } from 'features/e2ee/SFrameManager';
import { sframeInitialized } from 'features/e2ee/actions';

// Avoid race conditions. SFrame initialization started on roomJoined event

export function* refineSframeStatus() {
  const isSframeInitialized: boolean = yield call(SFrameManager.isInitialized);

  if (!isSframeInitialized) {
    yield take(sframeInitialized);
  }
}
