import { AppThunk } from 'store/store';
import {
  actionNotificationClosed,
  selectNotificationById,
} from 'features/notifications/notificationsSlice';
import { notification } from 'features/notifications/toast/notification';
import { NotificationId } from 'features/notifications/types';

export const closeActionNotification =
  (id: NotificationId): AppThunk<void> =>
  (dispatch, getState) => {
    const activeNotification = selectNotificationById(getState(), id);
    if (activeNotification) {
      dispatch(actionNotificationClosed(id));
    }

    notification.dismiss(id);
  };
