/**
 * MUI theme settings
 */
import {
  createTheme as createMuiTheme,
  PaletteOptions,
  PaletteColorOptions,
  responsiveFontSizes,
  PaletteColor,
} from '@mui/material/styles';
import { themeBaseOptions } from 'theme/themeBaseOptions';
import { overrideComponents } from 'theme/componentOverrides';
import { CustomThemeOptions, customThemeOptions } from 'theme/customThemeOptions';
import { deepmerge } from '@mui/utils';
import { DeepPartial } from 'utils/types';
import { getThemePalette } from 'theme/palette';

export type Branding = {
  palette?: PaletteOptions;
  components?: DeepPartial<CustomThemeOptions>;
};

export const createThemeCore = (brandingOptions: Branding = {}) => {
  const palette = deepmerge(
    getThemePalette(brandingOptions.palette?.mode),
    brandingOptions.palette
  );

  const customOptions = deepmerge(customThemeOptions, brandingOptions.components);

  return createMuiTheme({
    ...themeBaseOptions,
    palette,
    ...customOptions,
  });
};

export const createTheme = (brandingOptions: Branding) => {
  const themeCore = createThemeCore(brandingOptions);

  return responsiveFontSizes(
    createMuiTheme(themeCore, {
      components: overrideComponents(themeCore),
    })
  );
};

declare module '@mui/material/styles' {
  interface Theme {
    room: {
      background: string;
      tileBackground: string;
      lightBackground: string;
      tileGap: number;
    };
    toolbar: {
      width: string;
      background: string;
      badge: {
        active: {
          background: string;
        };
      };
    };
    input: {
      lineHeight: number;
    };
    toolbarPanel: {
      width: string;
    };
    topBar: {
      height: string;
    };
    layout: {
      containerPadding: string;
      innerPadding: string;
    };
  }

  interface ThemeOptions {
    room?: {
      background: string;
      tileBackground: string;
      lightBackground: string;
      tileGap: number;
    };
    toolbar?: {
      width: string;
      background?: string;
      badge?: {
        active: {
          background: string;
        };
      };
    };
    input?: {
      lineHeight?: number;
    };
    toolbarPanel?: {
      width: string;
    };
    topBar?: {
      height: string;
    };
    layout?: {
      containerPadding: string;
      innerPadding: string;
    };
  }

  interface Palette {
    grayscale: PaletteColor;
  }

  interface PaletteOptions {
    grayscale?: PaletteColorOptions;
  }
}

// @FIXME bug https://github.com/mui/material-ui/issues/31366. Don't use private module.
declare module '@mui/material/Button/Button' {
  interface ButtonPropsColorOverrides {
    grayscale: true;
  }
}
