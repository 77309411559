import {
  AllByRoleEntry,
  GroupedParticipants,
  UserEntries,
  User,
  UserId,
} from 'features/users/types';
import { PermissionsState, PermissionTypes, RoleEntries } from 'features/permissions/types';
import { refinePermissions } from 'features/permissions/utils/refinePermissions';
import { sortUserAlphabetically } from 'features/users/sortUserAlphabetically';

export const groupUserIdsByRole = (
  usersIds: UserId[],
  userEntries: UserEntries
): AllByRoleEntry => {
  const groupedUsers: AllByRoleEntry = {};

  for (const userId of usersIds) {
    const user = userEntries[userId];
    if (user) {
      if (!groupedUsers[user.role]) {
        groupedUsers[user.role] = [];
      }
      groupedUsers[user.role].push(user.id);
    }
  }
  return groupedUsers;
};

export const groupUsersByRole = (
  roleNames: string[],
  roleEntries: RoleEntries,
  usersEntries: UserEntries
) => {
  const userIds = Object.keys(usersEntries);
  const userIdsByRole = groupUserIdsByRole(userIds, usersEntries);

  const groupedParticipants: GroupedParticipants[] = [];

  for (const role of roleNames) {
    const { displayName } = roleEntries[role];
    const ids = userIdsByRole[role];

    groupedParticipants.push({
      role,
      roleDisplayName: displayName,
      users: ids ? ids.map((id) => usersEntries[id]).sort(sortUserAlphabetically) : [],
    });
  }

  return groupedParticipants;
};

export const getHandRaisedUsers = ({
  handRaisedIds,
  userEntries,
  permissionsState,
  localUser,
}: {
  handRaisedIds: UserId[];
  userEntries: UserEntries;
  permissionsState: PermissionsState;
  localUser: User;
}) => {
  const result: User[] = [];

  for (const id of handRaisedIds) {
    const user = userEntries[id];
    const canManageBroadcast = refinePermissions(PermissionTypes.manageBroadcast, {
      permissionsState,
      localUser,
      targetRole: user.role,
    });

    if (id === localUser.id || canManageBroadcast) {
      result.push(user);
    }
  }

  return result;
};

export const getAwaitingEntryUsers = ({
  userEntries,
  awaitingIds,
  canControlRoomEntry,
}: {
  userEntries: UserEntries;
  awaitingIds: UserId[];
  canControlRoomEntry: boolean;
}): User[] => {
  if (!canControlRoomEntry || awaitingIds.length === 0) {
    return [];
  }

  const result: User[] = [];
  for (const id of awaitingIds) {
    result.push(userEntries[id]);
  }
  return result;
};

export const getUsers = (ids: UserId[], entries: UserEntries) => {
  const result: UserEntries = {};
  for (const id of ids) {
    result[id] = entries[id];
  }
  return result;
};
