import { backgroundsList } from 'features/room/settings/tabs/VideoBackgroundTab/backgrounds';
import { call } from 'redux-saga/effects';
import { eventBus, VirtualBackgroundOptions } from 'utils/eventBus';
import { validateImageURL } from 'utils/validateImageURL';

export function* validateVBOptionsSaga(options: Partial<VirtualBackgroundOptions>) {
  let validUrl = false;

  if (options.imageUrl) {
    validUrl = yield call(validateImageURL, options.imageUrl);

    if (validUrl) {
      return {
        valid: true,
        config: { type: 'image', value: options.imageUrl },
        targetOption: 'imageUrl',
      };
    }

    if (options.blur || options.image) {
      yield call(eventBus.error, {
        name: 'virtual-bg',
        message: 'Provided image URL is invalid. Will fall back to other options.',
      });
    } else {
      yield call(eventBus.error, {
        name: 'virtual-bg',
        message:
          'Provided image URL is invalid. Please make sure that it’s publicly available and not blocked by CORS or proxy',
      });

      return { valid: false };
    }
  }

  if (options.image) {
    if (backgroundsList[options.image]) {
      return {
        valid: true,
        config: { type: 'image', value: backgroundsList[options.image].src },
        targetOption: 'image',
      };
    }

    if (options.blur) {
      yield call(eventBus.error, {
        name: 'virtual-bg',
        message: 'Provided image key is invalid. Will fall back to other options.',
      });
    } else {
      yield call(eventBus.error, {
        name: 'virtual-bg',
        message: 'Provided image key is invalid.',
      });
      return { valid: false };
    }
  }

  if (options.blur) {
    if (options.blur === 'balanced' || options.blur === 'strong') {
      return {
        valid: true,
        config: { type: 'blur', value: options.blur },
        targetOption: 'blur',
      };
    }
    yield call(eventBus.error, {
      name: 'virtual-bg',
      message: 'Provided blur setting is invalid.',
    });

    return { valid: false };
  }

  yield call(eventBus.error, {
    name: 'virtual-bg',
    message:
      'Missing required options for virtual background. Please provide either "blur", "image" or "imageUrl" property.',
  });

  return { valid: false };
}
