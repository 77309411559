import React, { useRef } from 'react';
import Draggable from 'react-draggable';
import { useAppSelector } from 'store/hooks';
import { Paper, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import { selectReceivingTotalBandwidth } from 'features/system-stats/systemStatsSlice';

const DebugTotalBandwidth = () => {
  const nodeRef = useRef<HTMLDivElement | null>(null);
  const totalBandwidth = useAppSelector(selectReceivingTotalBandwidth);

  return totalBandwidth ? (
    <Draggable bounds="body" nodeRef={nodeRef}>
      <Paper
        ref={nodeRef}
        sx={{
          position: 'absolute',
          left: 16,
          top: 80,
          p: 1,
          zIndex: 'appBar',
          cursor: 'pointer',
          color: 'common.white',
          backgroundColor: blue[900],
        }}
      >
        <Typography variant="body2">
          Total incoming bandwidth: {Math.round(totalBandwidth)} kb/s
        </Typography>
      </Paper>
    </Draggable>
  ) : null;
};

export default DebugTotalBandwidth;
