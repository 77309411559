import { useTranscription } from 'features/transcripts/slices/transcription/useTranscription';
import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { useAppSelector } from 'store/hooks';
import { selectCaptionsDisplayed } from 'features/transcripts/slices/captions/captionsSlice';

export const useCaptions = () => {
  const { transcriptionStarted, transcriptionAvailable } = useTranscription();

  const captionsEnabled = useFeatureFlag('captions');
  const captionsDisplayed = useAppSelector(selectCaptionsDisplayed);
  const captionsAvailable = transcriptionAvailable && captionsEnabled && transcriptionStarted;

  return {
    captionsAvailable,
    captionsDisplayed,
  };
};
