import { Theme, useMediaQuery } from '@mui/material';

export const useBreakpoints = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isPortrait = useMediaQuery('(orientation: portrait)');

  return {
    isMobile,
    isPortrait,
  };
};
