import { PayloadAction } from '@reduxjs/toolkit';
import { StreamingErrorMessage } from 'features/streaming/types';
import { call } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { logger } from 'utils/logger';
import { RTCClient } from 'utils/webrtc';

export function* onStreamingErrorSaga(action: PayloadAction<StreamingErrorMessage>) {
  const { error, error_code: errorCode } = action.payload;

  switch (errorCode) {
    case 455: {
      yield call(SignalingSocket.send, {
        event: 'roomNotFound',
        data: { type: 'streaming', id: RTCClient.mountpointId },
      });

      break;
    }

    default: {
      yield call(logger.remote().error, `Error in streaming receiver. Original: ${error}`);
    }
  }
}
