import React from 'react';
import { ItemProps } from 'react-virtuoso';
import { TargetRoomVirtualListItemType } from 'features/breakout-rooms/types';
import { StyledParticipantListItemButton } from 'components/participants-list/styled';
import { TargetRoomsListContext } from 'features/breakout-rooms/components/target-room-selector/TargetRoomsList';
import { ListItemButtonProps } from '@mui/material';

type TargetRoomListItemProps = ListItemButtonProps<
  'div',
  ItemProps<TargetRoomVirtualListItemType> & {
    context?: TargetRoomsListContext;
  }
>;

const TargetRoomListItem = React.forwardRef<HTMLDivElement, TargetRoomListItemProps>(
  ({ children, item, context, ...props }, ref) => {
    const { data } = item;
    if (!data) {
      return null;
    }

    if (!context) {
      return null;
    }

    const { selectRoom, selectedId, participantsLimit } = context;

    return (
      <StyledParticipantListItemButton
        {...props}
        ref={ref}
        selected={selectedId === data.id}
        disabled={data.users.length >= participantsLimit}
        onClick={() => selectRoom(data.id)}
      >
        {children}
      </StyledParticipantListItemButton>
    );
  }
);

export default TargetRoomListItem;
