import { call, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { User, UserHandRaisedPayload, UserId } from 'features/users/types';
import { selectActiveUserById, selectLocalUserId } from 'features/users/usersSlice';
import { eventBus } from 'utils/eventBus';
import { raiseHandNotificationUpdateRequested } from 'features/notifications/actions';

export function* onHandRaisedSaga({
  payload: {
    initiator: { id: initiatorId },
  },
}: PayloadAction<UserHandRaisedPayload>) {
  yield call(eventBus.sendMessage, 'handRaised', { userId: initiatorId });

  // skip for the initiator
  const localUserId: UserId = yield select(selectLocalUserId);
  if (localUserId === initiatorId) {
    return;
  }

  const initiatorUser: User | undefined = yield select(selectActiveUserById, initiatorId);
  if (!initiatorUser) {
    return;
  }

  yield put(raiseHandNotificationUpdateRequested({ reason: 'hand-raised' }));
}
