import { useTranslation } from 'react-i18next';
import ToolbarMenuItem from 'features/toolbar/ToolbarMenuItem/ToolbarMenuItem';
import { useChatControl } from 'features/toolbar/Toolbar/controls/chat/useChatControl';
import { ToolbarMenuItemWrapperProps } from 'features/toolbar/types';
import React from 'react';

const ChatControl = ({ onClick }: ToolbarMenuItemWrapperProps) => {
  const { t } = useTranslation('room');

  const { togglePanel, icon, unreadCount, active, isFeatureAvailable } = useChatControl();

  if (!isFeatureAvailable) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(event);
    togglePanel();
  };

  return (
    <ToolbarMenuItem
      id="chat"
      overlay={t('toolbar.chat')}
      ariaLabel={t('toolbar.chat')}
      icon={icon}
      badgeContent={unreadCount}
      badgeActive
      active={active}
      onClick={handleClick}
    />
  );
};

export default ChatControl;
