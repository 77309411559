import { useCallback } from 'react';
import { userMediaFailed, userMediaReceived } from 'features/user-media/userMediaSlice';
import { useDispatch } from 'react-redux';
import { isDomException, isError } from 'utils/types';
import * as Sentry from '@sentry/react';
import { logger } from 'utils/logger';
import { getUserMedia } from 'features/user-media/utils/getUserMedia';

export type SingularUserMedia =
  | { audio: boolean | MediaTrackConstraints }
  | { video: boolean | MediaTrackConstraints };

export interface SingularUserMediaOptions {
  updateActiveDevice: boolean;
}

export const useSingularUserMedia = () => {
  const dispatch = useDispatch();

  return useCallback(
    async (constraints: SingularUserMedia, options?: SingularUserMediaOptions) => {
      const type = Object.keys(constraints)[0] as 'video' | 'audio';
      const mediaDeviceKind = `${type}input` as const;
      try {
        const stream = await getUserMedia(constraints);
        const track = type === 'audio' ? stream.getAudioTracks()[0] : stream.getVideoTracks()[0];

        dispatch(
          userMediaReceived({
            updateActiveDevice: options?.updateActiveDevice ?? true,
            device: {
              id: track.getSettings().deviceId || '',
              kind: mediaDeviceKind,
            },
            permissions: {
              [mediaDeviceKind]: 'granted',
            },
          })
        );

        return { stream };
      } catch (error) {
        if (isDomException(error)) {
          logger
            .remote({ system: true, capture: 'user_media' })
            .error(`Error when requesting user media: ${error.name}: ${error.message}`);

          if (error.name === 'NotFoundError') {
            dispatch(
              userMediaFailed({
                kind: mediaDeviceKind,
                value: 'not-found',
              })
            );
          } else {
            dispatch(
              userMediaFailed({
                kind: mediaDeviceKind,
                value: 'denied',
              })
            );
          }
        } else {
          logger.remote({ tier: 1 }).error(`Error when requesting user media: ${error}`);
          Sentry.captureException(error);
          dispatch(
            userMediaFailed({
              kind: mediaDeviceKind,
              value: 'denied',
            })
          );
        }

        return { stream: null, error: isError(error) ? error : new Error('Unknown error') };
      }
    },
    [dispatch]
  );
};
