import { useEffect, useRef } from 'react';
import { useReceivingFeed } from 'hooks/useReceivingFeed';

export const useRemoteVideo = (mid: string | undefined) => {
  const ref = useRef<HTMLVideoElement | null>(null);

  const { control } = useReceivingFeed();

  useEffect(() => {
    if (mid !== undefined) {
      control.attachVideo(mid, ref);
    }

    return () => {
      if (mid !== undefined) {
        control.detachFromElement(mid, ref);
      }
    };
  }, [mid, control]);

  return ref;
};
