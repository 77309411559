import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { call } from 'redux-saga/effects';
import { PublishingOptions, RTCClient } from 'utils/webrtc';
import { RoomJoinedPayload } from 'features/room/types';

/**
 * This saga handles various aspects of joining room and kicks off "init" process:
 *
 * creates media server connection
 * remembers whether we should do batch subscription to initial participants later on;
 * remembers users' publishing config to use later on
 **/
export function* establishJanusConnectionSaga(
  initData: RoomJoinedPayload,
  publishingOptions?: PublishingOptions
) {
  // this assignment and `initSubscriber` call need to happen before setting up mediaservers
  // because upon conecting to mediaserver we immediately try to subscribe and the `subscriber` needs to be configured
  if (initData.mediaServers.publishing.url !== initData.mediaServers.subscribing.url) {
    RTCClient.receiveMode = 'streaming';
  } else {
    RTCClient.receiveMode = 'videoroom';
  }

  yield call(RTCClient.receivingFeed.initReceiver, RTCClient.receiveMode);
  //

  const broadcastAllowed: boolean = yield call(hasPermissionsSaga, PermissionTypes.broadcast);
  const screenshareAllowed: boolean = yield call(hasPermissionsSaga, PermissionTypes.screenshare);

  // @TODO FIXME don't set publishing states for receivers only
  yield call(RTCClient.setupPublishState, publishingOptions, broadcastAllowed);
  yield call(RTCClient.setInitialSubscriptions, initData.users);

  if (broadcastAllowed || screenshareAllowed) {
    yield call(RTCClient.mediaServerConnector.connectToPublishingServer, 'publishing', {
      iceServers: initData.iceServers,
      mediaServer: initData.mediaServers.publishing,
    });

    yield call(
      RTCClient.mediaServerConnector.connectToSubscriptionServer,
      'base-subscription',
      {
        iceServers: initData.iceServers,
        mediaServer: initData.mediaServers.subscribing,
      },
      // mhm
      RTCClient.receiveMode === 'streaming' ? undefined : 'publishing'
    );
  } else {
    yield call(RTCClient.mediaServerConnector.connectToSubscriptionServer, 'base-subscription', {
      iceServers: initData.iceServers,
      mediaServer: initData.mediaServers.subscribing,
    });
  }
}
