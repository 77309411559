import { call, put } from 'redux-saga/effects';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { logger } from 'utils/logger';
import { e2eeEnabled } from 'features/e2ee/e2eeSlice';
import i18n from 'i18n';
import { browserSupportsE2EE } from 'features/e2ee/utils/browserSupportsE2EE';

export function* enableE2eeSaga() {
  const e2eeSupported: boolean = yield call(browserSupportsE2EE);

  if (!e2eeSupported) {
    logger
      .remote({ system: true, capture: 'e2ee' })
      .info('End-to-end encryption is not supported on this browser');

    throw new Error(i18n.t('common:e2ee_unsupported'));
  }

  logger.remote({ system: true, capture: 'e2ee' }).info('Enabling end-to-end encryption');

  yield put(e2eeEnabled());

  yield call(E2EEManager.enableE2ee);
}
