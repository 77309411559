import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Title from 'components/Title';
import { screensharePreviewToggled } from 'features/layout/features/config/configSlice';
import { localScreenshareToggled } from 'features/streaming/actions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';

const FloatingBar = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'rgba(0, 0, 0, 0.75)',
  position: 'absolute',
  borderRadius: '8px',
  padding: '8px 16px',
  right: `${theme.room.tileGap}px`,
  bottom: `${theme.room.tileGap}px`,
  opacity: 0,
  maxWidth: '75%',
}));

const WhiteButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  borderColor: theme.palette.common.white,
}));

interface LocalScreensharePreviewOverlayProps {
  floating?: boolean;
  wrap?: boolean;
}

const LocalScreensharePreviewOverlay = ({
  floating,
  wrap,
}: LocalScreensharePreviewOverlayProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const togglePreview = () => dispatch(screensharePreviewToggled());
  const toggleScrenshare = () => dispatch(localScreenshareToggled());

  const renderControls = () => (
    <Box>
      <Button
        size={floating ? 'small' : 'medium'}
        type="button"
        sx={{ fontWeight: floating ? 'bold' : 'normal' }}
        variant={floating ? 'text' : 'contained'}
        onClick={toggleScrenshare}
      >
        {t('room:screenshare.stop_sharing')}
      </Button>
      <WhiteButton
        size={floating ? 'small' : 'medium'}
        type="button"
        sx={{ ml: 4, fontWeight: floating ? 'bold' : 'normal' }}
        variant={floating ? 'text' : 'outlined'}
        onClick={togglePreview}
        color="grayscale"
      >
        {floating
          ? t('room:screenshare.hide_preview_action')
          : t('room:screenshare.show_preview_action')}
      </WhiteButton>
    </Box>
  );
  return floating ? (
    <FloatingBar className="hover-child" sx={{ flexDirection: wrap ? 'column' : 'row' }}>
      <Title
        sx={{
          color: '#fff',
          mr: wrap ? 0 : 8,
          mb: wrap ? 2 : 0,
          textAlign: wrap ? 'center' : 'left',
        }}
        style={{
          fontSize: '14px',
        }}
      >
        {t('room:screenshare.preview_label')}
      </Title>
      {renderControls()}
    </FloatingBar>
  ) : (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <Title
        sx={{
          color: '#fff',
          mb: 4,
          textAlign: 'center',
        }}
        style={{
          fontSize: '18px',
        }}
      >
        {t('room:screenshare.preview_label')}
      </Title>
      {renderControls()}
    </Box>
  );
};

export default LocalScreensharePreviewOverlay;
