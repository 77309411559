import { alpha, styled } from '@mui/material/styles';
import Input from 'components/Input';
import { InputProps } from '@mui/material';
import { forwardRef } from 'react';

const RoundedMultilineInput = styled(
  forwardRef((props: InputProps, ref) => <Input multiline maxRows={3} {...props} inputRef={ref} />)
)(({ theme }) => ({
  boxShadow: 'none',
  flex: 1,
  padding: '7px 12px 7px 12px',
  border: `1px solid ${theme.palette.divider}`,
  '&.Mui-focused': {
    borderColor: theme.palette.primary.main,
  },
  '&.Mui-error': {
    borderColor: theme.palette.error.main,
    '&.Mui-focused': {
      boxShadow: `${alpha(theme.palette.error.main, 0.35)} 0 0 0 2px`,
      borderColor: theme.palette.error.main,
    },
  },
  '& .MuiInputBase-inputMultiline': {
    border: 'none',
    borderRadius: 0,
    padding: 0,
    '&:focus': {
      boxShadow: 'none',
    },
  },
}));

export default RoundedMultilineInput;
