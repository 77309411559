import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'features/modal/modalSlice';
import { Trans, useTranslation } from 'react-i18next';
import { requestRecording } from 'features/recorder/actions';
import { usePermissions } from 'features/permissions/usePermissions';
import { PermissionTypes } from 'features/permissions/types';
import React, { useEffect } from 'react';
import ConfirmationDialog from 'components/ConfirmationDialog';

const RecordingE2eeConsentModal = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const { hasPermissions } = usePermissions();

  const canManageRecording = hasPermissions(PermissionTypes.recording);

  useEffect(() => {
    if (!canManageRecording) {
      dispatch(closeModal('recordingE2eeConsent'));
    }
  }, [canManageRecording, dispatch]);

  const handleOk = () => {
    dispatch(requestRecording());
    dispatch(closeModal('recordingE2eeConsent'));
  };

  const handleCancel = () => {
    dispatch(closeModal('recordingE2eeConsent'));
  };

  return (
    <ConfirmationDialog
      open
      maxWidth="sm"
      title={t('recording_e2ee_consent.title')}
      description={
        <Trans
          i18nKey="modals:recording_e2ee_consent.description"
          t={t}
          components={[
            // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
            <a
              href="https://docs.digitalsamba.com/dashboard/configuring-your-room-defaults/general-settings"
              target="_blank"
              rel="noopener noreferrer"
            />,
          ]}
        />
      }
      cancelText={t('recording_e2ee_consent.btn_cancel_label')}
      okText={t('recording_e2ee_consent.btn_ok_label')}
      onOk={handleOk}
      onCancel={handleCancel}
      preventClose
      hideCloseButton
    />
  );
};

export default RecordingE2eeConsentModal;
