import {
  layoutModeChanged,
  localStreamCollapsed,
  localStreamExpanded,
} from 'features/layout/features/config/configSlice';
import {
  signalingWhiteboardChanged,
  signalingWhiteboardClosed,
  signalingWhiteboardCursorUpdated,
  signalingWhiteboardOpened,
  whiteboardClosed,
  whiteboardOpened,
} from 'features/layout/features/content/actions';
import { gridPanelClosed, gridPanelOpened } from 'features/layout/features/content/contentSlice';
import { onGridPanelClosedSaga } from 'features/layout/features/content/sagas/onGridPanelClosedSaga';
import { onPinControlToggledSaga } from 'features/layout/features/content/sagas/onPinControlToggledSaga';
import { dimensionsChanged } from 'features/layout/features/dimensions/dimensionsSlice';
import { orderedStreamDeleted } from 'features/layout/features/order/orderSlice';
import { paginationUpdated } from 'features/layout/features/pagination/paginationSlice';
import { localStreamCollapseSaga } from 'features/layout/sagas/localStreamCollapseSaga';
import { onCustomControlTriggeredSaga } from 'features/layout/sagas/onCustomControlTriggeredSaga';
import { onDimensionsChangedSaga } from 'features/layout/sagas/onDimensionsChangedSaga';
import { onLayoutModeChangedSaga } from 'features/layout/sagas/onLayoutModeChangedSaga';
import { onOrderedStreamDeletedSaga } from 'features/layout/sagas/onOrderedStreamDeletedSaga';
import { onStreamMaximizedSaga } from 'features/layout/sagas/onStreamMaximizedSaga';
import { onStreamMinimizedSaga } from 'features/layout/sagas/onStreamMinimizedSaga';
import { onWhiteboardChangedSaga } from 'features/layout/sagas/onWhiteboardChangedSaga';
import { onWhiteboardCursorPositionChangedSaga } from 'features/layout/sagas/onWhiteboardCursorPositionChangedSaga';
import { prepareLayoutDefaultsSaga } from 'features/layout/sagas/prepareLayoutDefaultsSaga';
import { authorizeUser } from 'features/room/thunks/authorizeUser';
import { debounce, takeEvery, takeLatest } from 'redux-saga/effects';
import { onPaginationSaga } from 'features/layout/sagas/onPaginationSaga';
import { onOrderChangedSaga } from 'features/layout/sagas/onOrderChangedSaga';
import {
  customControlTriggered,
  orderChanged,
  pinControlToggled,
  streamMaximized,
  streamMinimized,
} from 'features/layout/actions';
import { signalingUserJoined } from 'features/users/actions';
import { onUserJoinedSaga } from 'features/layout/sagas/onUserJoinedSaga';
import { onBroadcastEndSaga } from 'features/layout/sagas/onBroadcastEndSaga';
import { activeSpeakerChanged } from 'features/streaming/actions';
import { onActiveSpeakerChangedSaga } from 'features/layout/sagas/onActiveSpeakerChangedSaga';
import { targetTilesCountSet } from 'features/dev-mode/devModeSlice';
import { debugTilesChangeSaga } from 'features/layout/sagas/debugTilesChangeSaga';
import { userLeft } from 'features/users/usersSlice';
import { signalingRoomJoined } from 'features/room/actions';
import { prepareRoomLayoutSaga } from 'features/layout/sagas/prepareRoomLayoutSaga';
import { onLocalStreamExpandedSaga } from 'features/layout/sagas//onLocalStreamExpandedSaga';
import { openWhiteboardSaga } from './openWhiteboardSaga';
import { onWhiteboardOpenedSaga } from './onWhiteboardOpenedSaga';
import { closeWhiteboardSaga } from './closeWhiteboardSaga';
import { onWhiteboardClosedSaga } from './onWhiteboardClosedSaga';
import { onGridPanelOpenedSaga } from '../features/content/sagas/onGridPanelOpenedSaga';

export default function* layoutWatcher() {
  yield takeLatest(authorizeUser.fulfilled, prepareLayoutDefaultsSaga);

  yield takeEvery(signalingRoomJoined, prepareRoomLayoutSaga);

  yield takeEvery(dimensionsChanged, onDimensionsChangedSaga);

  // order stuff
  yield takeEvery(orderChanged, onOrderChangedSaga);

  yield takeEvery(signalingUserJoined, onUserJoinedSaga);

  // @TODO: this would be a good place to do other sorts of cleanup (like for maximized tile);
  yield takeEvery(userLeft, onBroadcastEndSaga);

  yield takeEvery(activeSpeakerChanged, onActiveSpeakerChangedSaga);

  yield takeEvery(streamMaximized, onStreamMaximizedSaga);

  yield takeEvery(streamMinimized, onStreamMinimizedSaga);

  yield debounce(400, paginationUpdated, onPaginationSaga);

  yield takeEvery(localStreamCollapsed, localStreamCollapseSaga);

  yield takeEvery(layoutModeChanged, onLayoutModeChangedSaga);

  yield takeEvery(orderedStreamDeleted, onOrderedStreamDeletedSaga);

  yield takeEvery(pinControlToggled, onPinControlToggledSaga);

  // support debug tiles
  yield takeEvery(targetTilesCountSet, debugTilesChangeSaga);

  yield takeEvery(localStreamExpanded, onLocalStreamExpandedSaga);

  yield takeEvery(whiteboardOpened, openWhiteboardSaga);
  yield takeEvery(whiteboardClosed, closeWhiteboardSaga);

  yield takeEvery(signalingWhiteboardOpened, onWhiteboardOpenedSaga);
  yield takeEvery(signalingWhiteboardClosed, onWhiteboardClosedSaga);
  yield takeEvery(signalingWhiteboardChanged, onWhiteboardChangedSaga);
  yield takeEvery(signalingWhiteboardCursorUpdated, onWhiteboardCursorPositionChangedSaga);

  yield takeEvery(gridPanelOpened, onGridPanelOpenedSaga);
  yield takeEvery(gridPanelClosed, onGridPanelClosedSaga);

  yield takeEvery(customControlTriggered, onCustomControlTriggeredSaga);
}
