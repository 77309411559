import {
  FeedId,
  MediaStreamType,
  PublisherMid,
  SubscriberMid,
  SubscribeStreamList,
} from 'features/streaming/types';
import { UserId } from 'features/users/types';
import Janus, { JanusJS } from 'lib/janus';
import { UnlockedAudio } from 'utils/webrtc/AudioPool';
import { MediaStreamStats } from 'utils/webrtc/MediaStreamStats';

export type Rid = 'l' | 'm' | 'h';

export enum SubscriptionChannelState {
  pending,
  active,
  idle,
}

export enum ReceivingFeedStreamSendingState {
  off,
  on,
}

export enum JanusConnectionType {
  publishing,
  subscription,
}

export enum JanusConnectionStatus {
  idle = 'idle',
  connecting = 'connecting',
  connected = 'connected',
}

export interface JanusConnection {
  status: JanusConnectionStatus;
  connected: boolean;
  handle: string;
  type: JanusConnectionType;
  janus: Janus;
  replicatedHandle?: string;
  options: JanusConnectionOptions;
}

export type PublishingKind = 'publishing' | 'screensharing';

export type StreamKind = 'video' | 'audio';

export interface FeedStream {
  attached: boolean;
  mediaStream: MediaStream;
  kind: StreamKind;
}

export interface LocalFeedStream extends FeedStream {
  id: string;
}

export interface PublishingOptions {
  audio?: boolean;
  video?: boolean;
  stream?: MediaStream;
}

export interface PublishTemplateOptions {
  audio: boolean;
  video: boolean;
}

export type ConfigureSimulcastTemplate = {
  mid?: SubscriberMid;
  substream?: number;
  temporal?: number;
}[];

export type ToggleMediaTemplate = { mid: string; send: boolean }[];

export interface FeedStreamInfo {
  type: StreamKind;
  mid: PublisherMid;
}

export type PublishingFeedStreamInfo = FeedStreamInfo & {
  on: boolean;
};

export type JanusJSPublishersUpdate = {
  id: FeedId;
  streams: {
    type: StreamKind;
    mid: PublisherMid;
    active: boolean;
    disabled?: boolean;
  }[];
}[];

export enum PublishingFeedStatus {
  idle = 'idle',
  connecting = 'connecting',
  connected = 'connected',
}

export interface PublishingNegotiationMeta {
  isNegotiating: boolean;
  // Used when the audio is rapidly clicked during the initial negotiation
  pendingAudioBroadcastState: boolean | null;
  // when resuming audio stream after physically removing audio device
  // signaling update is usually skipped. need this flag to manually notify signaling
  // when new audio track appears
  pendingAudioSignalingState: boolean | null;
  // Used when the HD layer is activated without devices yet
  pendingHDSimulcast: boolean | null;
  activeOffer: JanusJS.OfferParams | null;
  pendingOffer: JanusJS.OfferParams | null;
  iceRestartAttempt: boolean;
}

export interface ReceivingNegotiationMeta {
  iceRestartAttempt: boolean;
  iceRestartTimer?: number;
}

export interface PublishingRoomJoinedMessage {
  id: FeedId;
  private_id: number;
}

export interface MediaServer {
  url: string;
  token: string;
}

export interface MediaServers {
  publishing: MediaServer;
  subscribing: MediaServer;
}

export type IceServers = {
  urls: string;
  username: string;
  credential: string;
}[];

export interface JanusConnectionOptions {
  mediaServer: MediaServer;
  iceServers: IceServers;
}

export type ReceivingStreamsStats = Record<SubscriberMid, MediaStreamStats>;
export type BroadcastingByUserId = Record<UserId, Partial<Record<MediaStreamType, boolean>>>;

export type ConnectionMediaTokens = Record<keyof typeof JanusConnectionType, string>;

export interface RidStats {
  lastResult: {
    packetsSent?: number;
    packetsLost?: number;
    packetLoss?: number;
    jitter?: number;
  };
  rid: Rid;
  packetsSent: number;
  // remote inbound values
  packetsLost: number;
  packetLoss: number;
  rtt: number;
  jitter: number;
}

export interface FeedMediaState {
  enabled: boolean;
  allowed: boolean;
  broadcasting: boolean;
  captured: boolean;
}

export interface FeedMediaStates {
  audio: FeedMediaState;
  video: FeedMediaState;
}

export type AudioElement = UnlockedAudio | HTMLAudioElement | null;

export type E2EEReceivingQueue = Record<
  UserId,
  {
    connectionHandle: string;
    streams: SubscribeStreamList;
  }
>;

export interface E2EEQueueUpdateOptions {
  feedId: FeedId;
  mid: PublisherMid;
  userId: UserId;
}

export enum StreamSubscriptionState {
  created,
  connecting,
  connected,
  disconnected,
}

export type ServerHandle = string;
