import JoinPublisher from 'features/join/publisher/JoinPublisher';
import { usePermissions } from 'features/permissions/usePermissions';
import { PermissionTypes } from 'features/permissions/types';
import JoinAudience from 'features/join/audience/JoinAudience';
import { useAppSelector } from 'store/hooks';
import { selectJoinScreenEnabled } from 'features/room/roomSlice';
import FastJoin from 'features/join/fast-join/FastJoin';
import BrandedLayout from 'layouts/BrandedLayout';

const JoinScreen = () => {
  const joinScreenEnabled = useAppSelector(selectJoinScreenEnabled);

  const { hasPermissions } = usePermissions();

  const allowBroadcast = hasPermissions(PermissionTypes.broadcast);

  const renderContent = () => {
    if (joinScreenEnabled) {
      if (allowBroadcast) {
        return <JoinPublisher />;
      }

      return <JoinAudience />;
    }

    return <FastJoin />;
  };

  return <BrandedLayout>{renderContent()}</BrandedLayout>;
};

export default JoinScreen;
