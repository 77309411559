import React from 'react';
import {
  BadgeProps as MuiBadgeProps,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuItemProps,
} from '@mui/material';
import Icon, { IconProps } from 'components/Icon';
import { ControlBadgeProps } from 'features/toolbar/types';
import Badge from 'features/toolbar/Badge';

type MobileMenuItemProps = MenuItemProps<
  'li',
  ControlBadgeProps & {
    icon: string | React.ReactElement<IconProps>;
    BadgeProps?: Omit<MuiBadgeProps, 'badgeContent'>;
  }
>;

const MobileMenuItem = ({
  children,
  badgeContent,
  badgeActive,
  icon,
  BadgeProps,
  ...props
}: MobileMenuItemProps) => (
  <MenuItem {...props}>
    <ListItemIcon>
      <Badge
        {...BadgeProps}
        badgeContent={badgeContent}
        active={badgeActive}
        max={9999}
        data-testid="toolbar-control-badge"
      >
        {typeof icon === 'string' ? (
          <Icon
            name={icon}
            sx={{
              fontSize: 32,
            }}
          />
        ) : (
          icon
        )}
      </Badge>
    </ListItemIcon>
    <ListItemText>{children}</ListItemText>
  </MenuItem>
);

export default MobileMenuItem;
