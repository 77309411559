import { UserEntries } from 'features/users/types';

export function mapUnassignedUsers(allUsers: UserEntries, roomUsers: UserEntries) {
  const unassignedUsers: UserEntries = {};

  for (const id of Object.keys(allUsers)) {
    if (roomUsers[id] === undefined) {
      unassignedUsers[id] = allUsers[id];
    }
  }

  return unassignedUsers;
}
