import { useTranslation } from 'react-i18next';
import MobileMenuItem from 'features/toolbar/Toolbar/mobile/MobileMenuItem';
import { MobileMenuItemWrapperProps } from 'features/toolbar/types';
import React from 'react';
import { usePollsControl } from 'features/toolbar/Toolbar/controls/polls/usePollsControl';

const PollsControlMobile = ({ onClick }: MobileMenuItemWrapperProps) => {
  const { t } = useTranslation('room');

  const { active, togglePanel, icon, isFeatureAvailable, unreadUpdates } = usePollsControl();

  if (!isFeatureAvailable) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    onClick?.(event);
    togglePanel();
  };

  return (
    <MobileMenuItem
      icon={icon}
      badgeActive
      BadgeProps={{
        invisible: unreadUpdates.length === 0,
        variant: 'dot',
      }}
      selected={active}
      onClick={handleClick}
    >
      {t('toolbar.polls')}
    </MobileMenuItem>
  );
};

export default PollsControlMobile;
