import { createAction } from '@reduxjs/toolkit';
import { RecordingStartedPayload } from 'features/room/types';
import {
  Recorder,
  RecordingFailurePayload,
  RecordingStopRequestedPayload,
} from 'features/recorder/types';

export const recordingToggled = createAction<boolean | undefined>('recorder/recordingToggled');

export const requestRecording = createAction('recorder/request');
export const signalingRecordingRequested = createAction('signaling/recordingRequested');
export const signalingRecordingStarted = createAction<RecordingStartedPayload>(
  'signaling/recordingStarted'
);

export const stopRecorder = createAction('recorder/stop');
export const signalingRecordingStopRequested = createAction<
  RecordingStopRequestedPayload | undefined
>('signaling/recordingStopRequested');
export const signalingRecordingFailed = createAction<RecordingFailurePayload | undefined>(
  'signaling/recordingFailed'
);
export const signalingRecordingStopped = createAction('signaling/recordingStopped');

export const signalingRecorderJoined = createAction<Recorder>('signaling/recorderJoined');
