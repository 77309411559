import WaitingScreen from 'components/WaitingScreen/WaitingScreen';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

const ConnectionFailure = () => {
  const { t } = useTranslation('waiting-screens');

  return (
    <WaitingScreen show title={t('connection_failure.title')}>
      <Typography>{t('connection_failure.text')}</Typography>
    </WaitingScreen>
  );
};

export default ConnectionFailure;
