import { call, put, select } from 'redux-saga/effects';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { NotificationId } from 'features/notifications/types';
import { selectNotificationsByScope } from 'features/notifications/notificationsSlice';
import { Action } from '@reduxjs/toolkit';
import { closeActionNotification } from 'features/notifications/thunks/closeActionNotification';

export function* syncRoleChangePollNotificationsSaga() {
  const canTakePolls: boolean = yield call(hasPermissionsSaga, PermissionTypes.takePolls);
  if (!canTakePolls) {
    // close request to vote notification
    const notifications: NotificationId[] = yield select(
      selectNotificationsByScope,
      'poll_request_vote'
    );

    if (notifications.length) {
      const cleanupNotificationThunk: Action = yield call(
        closeActionNotification,
        notifications[0]
      );
      yield put(cleanupNotificationThunk);
    }
  }
}
