import {
  splashScreenOpenRequested,
  unassignUserFromBreakoutRoomRequested,
} from 'features/breakout-rooms/actions';
import { selectUserBreakoutRoomId } from 'features/breakout-rooms/breakoutRoomsSlice';
import { UserId } from 'features/users/types';
import { selectLocalUserId } from 'features/users/usersSlice';
import { put, select } from 'redux-saga/effects';

export function* onLeaveBreakoutRoomRequestedSaga() {
  const userId: UserId = yield select(selectLocalUserId);
  const roomId: string = yield select(selectUserBreakoutRoomId, userId);

  yield put(
    unassignUserFromBreakoutRoomRequested({
      id: roomId,
      userId,
    })
  );
  yield put(splashScreenOpenRequested('leaving'));
}
