import { GDPRConfig, RoomToken } from 'features/room/types';
import { selectSDKInitState } from 'features/sdk/sdkStateSlice';
import { put, select } from 'redux-saga/effects';
import {
  roomStatusUpdated,
  selectConsentConfig,
  selectLoginError,
  selectLoginSubmitted,
  selectRoomToken,
} from 'features/room/roomSlice';
import { selectIsRecorderSession } from 'features/recorder/recorderSlice';
import { roomJoinRequest } from 'features/room/actions';
import { getConsentFlag } from 'utils/consentFlag';
import { SDKInitState } from 'utils/eventBus';

export function* prepareJoinScreen() {
  const isRecorderSession: boolean = yield select(selectIsRecorderSession);
  if (isRecorderSession) {
    yield put(roomJoinRequest());
    return;
  }

  const sdkInitState: SDKInitState = yield select(selectSDKInitState);

  const token: RoomToken = yield select(selectRoomToken);

  const loginSubmitted: boolean = yield select(selectLoginSubmitted);
  const loginError: string | undefined = yield select(selectLoginError);

  const loginRequired = loginSubmitted ? !!loginError : !token.parsedData?.u;

  const requireSDKLogin = sdkInitState.username && !token.parsedData?.u;

  if (requireSDKLogin && !(loginSubmitted || loginError)) {
    return;
  }

  const consentConfig: GDPRConfig | null = yield select(selectConsentConfig);

  const consentFlag = getConsentFlag();

  const consentRequired = consentConfig && !consentFlag;

  if (loginRequired) {
    yield put(roomStatusUpdated('login'));
    return;
  }

  if (consentRequired) {
    yield put(roomStatusUpdated('consent-screen'));
    return;
  }

  yield put(roomStatusUpdated('join'));
}
