import { call, put, select } from 'redux-saga/effects';
import { signalingPollEnded } from 'features/polls/actions';
import { closeRequestVoteNotificationSaga } from 'features/polls/sagas/closeRequestVoteNotificationSaga';
import { selectIsRecorderSession } from 'features/recorder/recorderSlice';
import { toolbarPanelClosed } from 'features/toolbar/toolbarSlice';

export function* onPollEndedSaga(action: ReturnType<typeof signalingPollEnded>) {
  yield call(closeRequestVoteNotificationSaga, action.payload.id);

  // close the polls panel if it is a recorder session
  const isRecorderSession: boolean = yield select(selectIsRecorderSession);
  if (isRecorderSession) {
    yield put(toolbarPanelClosed());
  }
}
