import { getAPIDomain } from 'utils/http';

export const getConsentFlag = () => {
  try {
    return localStorage.getItem(`${getAPIDomain()}_GDPR_consent_given`);
  } catch {
    return false;
  }
};

export const setConsentFlag = () => {
  try {
    localStorage.setItem(`${getAPIDomain()}_GDPR_consent_given`, 'true');
  } catch {
    // do nothing;
  }
};
