import { call, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { PermissionChangePayload, User, UserId } from 'features/users/types';
import { notification } from 'features/notifications/toast/notification';
import i18n from 'i18n';
import { eventBus } from 'utils/eventBus';
import { RTCClient } from 'utils/webrtc';
import { selectLocalUserId, selectUserById } from 'features/users/usersSlice';
import { logger } from 'utils/logger';

export function* onScreenshareDisallowedSaga({
  payload: { id, initiator },
}: PayloadAction<PermissionChangePayload>) {
  const localUserId: UserId = yield select(selectLocalUserId);
  if (localUserId === id && initiator) {
    logger
      .remote({ tier: 1 })
      .info(`The user was disallowed to share screen by user id=${initiator.id}`);

    const initiatorUser: User = yield select(selectUserById, initiator.id);

    yield call(
      notification,
      i18n.t('notifications:screenshare_disallowed', { username: initiatorUser.name })
    );

    yield call(RTCClient.screensharingFeed.stopScreenshare, false);

    yield call(eventBus.sendMessage, 'permissionsChanged', {}, { screenshare: false });
  }
}
