import { Box, Button } from '@mui/material';
import { roomJoinRequest } from 'features/room/actions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useTranslation } from 'react-i18next';
import { selectAwaitingRoomJoin } from 'features/room/roomSlice';
import AwaitingRoomJoin from 'features/join/shared/AwaitingRoomJoin';
import { styleConstants } from 'theme/styleConstants';

const FastJoinAudience = () => {
  const { t } = useTranslation('join');
  const dispatch = useAppDispatch();

  const awaitingRoomJoin = useAppSelector(selectAwaitingRoomJoin);

  const handleJoin = () => {
    dispatch(roomJoinRequest());
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      {awaitingRoomJoin ? (
        <AwaitingRoomJoin />
      ) : (
        <Box
          sx={{
            width: '100%',
            maxWidth: styleConstants.narrowContainerWidth,
          }}
        >
          <Button type="button" variant="contained" fullWidth onClick={handleJoin}>
            {t('fast_join.btn_label_join')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default FastJoinAudience;
