import { call, put, select } from 'redux-saga/effects';
import i18n from 'i18n';
import { jwtDecode } from 'jwt-decode';
import { validateRoomService } from 'features/room/services';
import { RoomToken, RoomTokenData, ValidateRoomResponse } from 'features/room/types';
import { roomTokenParsed, selectRoomToken } from 'features/room/roomSlice';
import { loadLocalizationSaga } from 'features/room/sagas/loadLocalizationSaga';
import { setDocumentMeta } from 'utils/setDocumentMeta';

export function* validateRoomSaga(roomId: string) {
  const { jwt }: RoomToken = yield select(selectRoomToken);

  const response: ValidateRoomResponse = yield call(validateRoomService, roomId, jwt);

  if (response.meta) {
    yield call(setDocumentMeta, response.meta);
  }

  yield call(loadLocalizationSaga, response.language, response.languages);

  if (!response.valid) {
    throw new Error(i18n.t('waiting-screens:room.room_not_found'));
  }

  let parsedData: RoomTokenData | undefined;

  if (jwt) {
    try {
      parsedData = yield call(jwtDecode, jwt);

      if (parsedData) {
        yield put(roomTokenParsed(parsedData));
      }
    } catch {
      throw new Error(i18n.t('waiting-screens:room.token_invalid'));
    }
  }

  return {
    token: {
      jwt,
      parsedData,
    },
    settings: response,
  };
}
