import { createAction } from '@reduxjs/toolkit';
import { UserId, UserIdPayload } from 'features/users/types';
import { RaiseHandNotificationUpdateReason } from 'features/notifications/types';

export const clearAllActionNotifications = createAction('actionNotifications/clearAll');

export const userJoinNotificationTriggered = createAction<UserIdPayload>(
  'notifications/userJoinTriggered'
);

export const userLeftNotificationTriggered = createAction<UserIdPayload | UserId[]>(
  'notifications/userLeftTriggered'
);

export const waitingEntryNotificationUpdateRequested = createAction<UserIdPayload | undefined>(
  'actionNotifications/waitingEntryUpdateRequested'
);

export const raiseHandNotificationUpdateRequested = createAction<{
  reason: RaiseHandNotificationUpdateReason;
}>('actionNotifications/raiseHandUpdateRequested');

export const broadcasterLimitReachedNotificationUpdateRequested = createAction(
  'actionNotifications/broadcasterLimitReachedUpdateRequested'
);
