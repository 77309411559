import { call, put, select } from 'redux-saga/effects';
import { Action } from '@reduxjs/toolkit';
import i18n from 'i18n';
import { addActionNotification } from 'features/notifications/addActionNotification';
import { signalingQuestionAnswered } from 'features/qa/actions';
import { selectToolbarPanel, toolbarPanelOpened } from 'features/toolbar/toolbarSlice';
import { ToolbarPanelType } from 'features/toolbar/types';
import { Question } from 'features/qa/types';
import { selectQuestionById } from 'features/qa/qaSlice';
import { FeatureFlag } from 'features/feature-set/types';

export function* onQuestionAnsweredSaga(action: ReturnType<typeof signalingQuestionAnswered>) {
  const question: Question = yield select(selectQuestionById, action.payload.questionId);
  if (!question.local || action.payload.local) {
    // only show answers to local questions
    return;
  }

  const { id, questionId, answer, user } = action.payload;

  const activePanel: ToolbarPanelType = yield select(selectToolbarPanel);

  const actionNotificationThunk: Action = yield call(
    addActionNotification,
    {
      content: i18n.t('notifications:qa.local_question_answered.content', {
        username: user.name,
        answer,
      }),
      okText: i18n.t('notifications:qa.local_question_answered.btn_ok_label'),
      okAction: toolbarPanelOpened('qa', {
        questionId,
      }),
      hideOkAction: activePanel === 'qa',
      initiatorId: user.id,
      scope: [FeatureFlag.qa],
      data: {
        questionId,
      },
    },
    {
      toastId: `${questionId}_${id}`,
    }
  );

  yield put(actionNotificationThunk);
}
