import Title from 'components/Title';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { selectAwaitingRoomJoin } from 'features/room/roomSlice';
import AwaitingRoomJoin from 'features/join/shared/AwaitingRoomJoin';

const Intro = () => {
  const { t } = useTranslation('join');

  const awaitingRoomJoin = useAppSelector(selectAwaitingRoomJoin);

  return (
    <Box
      sx={{
        textAlign: 'center',
      }}
    >
      <Title>{t('request_permissions.title')}</Title>
      <Typography fontWeight={700}>{t('request_permissions.description')}</Typography>
      {awaitingRoomJoin ? <AwaitingRoomJoin /> : <CircularProgress sx={{ mt: 4 }} disableShrink />}
    </Box>
  );
};

export default Intro;
