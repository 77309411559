import UAParser from 'ua-parser-js';

export const userAgentDetails = () => {
  const details = UAParser(navigator.userAgent);

  if (details.device.vendor === 'Apple' && details.device.type === undefined) {
    const hasTouchCapabilities = navigator.maxTouchPoints > 0 && 'ontouchend' in document;

    details.device.type = hasTouchCapabilities ? 'tablet' : 'desktop';
  }

  return details;
};
