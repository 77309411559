import React from 'react';
import { Box, Container } from '@mui/material';

type UnbrandedPageProps = {
  children: React.ReactNode;
};

const UnbrandedLayout = ({ children }: UnbrandedPageProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      width: '100%',
      height: '100vh',
      minHeight: '100vh',
      py: 8,
    }}
  >
    <Container maxWidth="xl">{children}</Container>
  </Box>
);

export default UnbrandedLayout;
