import Icon from 'components/Icon';
import { useAppSelector } from 'store/hooks';
import { selectLocalUser } from 'features/users/usersSlice';
import { Box, BoxProps, CircularProgress } from '@mui/material';

type VideoPlaceholderProps = {
  loading?: boolean;
};

const VideoPlaceholder = ({
  children,
  loading,
  ...props
}: BoxProps<'div', VideoPlaceholderProps>) => {
  const user = useAppSelector(selectLocalUser);

  const renderContent = () => {
    if (loading) {
      return <CircularProgress sx={{ color: 'common.white' }} disableShrink />;
    }

    return (
      children || (
        <Icon
          name="camera-off"
          sx={{
            fontSize: 96,
          }}
        />
      )
    );
  };
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: user.avatarColor,
        color: 'common.white',
      }}
      {...props}
    >
      {renderContent()}
    </Box>
  );
};

export default VideoPlaceholder;
