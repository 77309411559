import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { selectAwaitingRoomJoin } from 'features/room/roomSlice';

const AwaitingRoomJoin = () => {
  const { t } = useTranslation('join');

  const awaitingRoomJoin = useAppSelector(selectAwaitingRoomJoin);

  const renderMessage = () => {
    if (awaitingRoomJoin === 'scale-server-boot') {
      return t('awaiting_server_boot');
    }

    if (awaitingRoomJoin === 'entry-access') {
      return t('awaiting_entry_access');
    }

    return t('awaiting_session_start');
  };

  return (
    <Box
      sx={{
        textAlign: 'center',
      }}
    >
      <Typography
        sx={{
          mb: 4,
        }}
      >
        {renderMessage()}
      </Typography>
      <CircularProgress />
    </Box>
  );
};

export default AwaitingRoomJoin;
