import { selectPreserveAspectRatio } from 'features/streaming/streamingSlice';
import { call, select } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';

export function* togglePreserveAspectRatioSaga() {
  const preserveAspectRatio: boolean = yield select(selectPreserveAspectRatio);

  try {
    localStorage.setItem(`preserve_video_aspect_ratio`, `${preserveAspectRatio}`);
  } catch {
    // do nothing;
  }

  yield call(SignalingSocket.send, {
    event: 'adjustAspectRatio',
    data: {
      keep: preserveAspectRatio,
    },
  });
}
