import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectLocalPublisherJoined,
  selectLocalVideoBroadcasting,
} from 'features/streaming/streamingSlice';
import { selectMediaDevicePermissions } from 'features/user-media/userMediaSlice';
import { openModal } from 'features/modal/modalSlice';
import { localVideoToggled } from 'features/streaming/actions';
import { useTranslation } from 'react-i18next';

export const useLocalVideo = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('room');

  const isVideoBroadcasting = useAppSelector(selectLocalVideoBroadcasting);
  const isConnectedToFeed = useAppSelector(selectLocalPublisherJoined);

  const camPermissions = useAppSelector((state) =>
    selectMediaDevicePermissions(state, 'videoinput')
  );

  const isPermissionsGranted = camPermissions === 'granted';

  const makeCamColor = () => {
    if (!isPermissionsGranted) {
      return 'error.main';
    }

    return 'common.white';
  };

  const renderVideoTooltipText = () => {
    if (isVideoBroadcasting) {
      return t('toolbar.disable_camera');
    }

    return t('toolbar.enable_camera');
  };

  const toggleVideo = () => {
    if (!isPermissionsGranted) {
      dispatch(openModal('videoPermissionsError'));
    } else {
      dispatch(localVideoToggled());
    }
  };

  return {
    camColor: makeCamColor,
    videoTooltipText: renderVideoTooltipText(),
    toggleVideo,
    isVideoBroadcasting,
    isPermissionsGranted,
    isConnectedToFeed,
    camIcon: isVideoBroadcasting ? 'camera' : 'camera-off',
  };
};
