import { streamMinimized } from 'features/layout/actions';
import { selectBroadcasterTileVisibility } from 'features/layout/features/config/configSlice';
import { SourceDetails } from 'features/layout/types';
import { BroadcasterTileBehaviour } from 'features/room/types';
import { selectRemoteBroadcastingState } from 'features/streaming/streamingSlice';
import { StreamingStopped } from 'features/streaming/types';
import { put, select } from 'redux-saga/effects';

export function* cleanupMaximizedStreamSaga(
  maximizedStream: SourceDetails,
  stoppedStream: StreamingStopped
) {
  const { type, feedId } = stoppedStream;
  const tileBehaviour: BroadcasterTileBehaviour = yield select(selectBroadcasterTileVisibility);

  if (maximizedStream.feedId === feedId) {
    yield put(streamMinimized());
    return;
  }

  if (maximizedStream.feedId || tileBehaviour === 'all') {
    return;
  }

  // handling media
  if (tileBehaviour === 'cam' && type === 'video') {
    yield put(streamMinimized());
    return;
  }

  if (tileBehaviour !== 'cam_mic') {
    return;
  }

  const videoBroadcasting: boolean = yield select(
    selectRemoteBroadcastingState,
    maximizedStream.userId,
    'video'
  );

  const audioBroadcasting: boolean = yield select(
    selectRemoteBroadcastingState,
    maximizedStream.userId,
    'audio'
  );

  if (!videoBroadcasting && !audioBroadcasting) {
    yield put(streamMinimized());
  }
}
