import { MenuFixedItems, ToolbarNavRoot } from 'features/toolbar/Toolbar/styles';
import CameraControl from 'features/toolbar/Toolbar/controls/CameraControl';
import MicControl from 'features/toolbar/Toolbar/controls/MicControl';
import ScreenshareControl from 'features/toolbar/Toolbar/controls/ScreenshareControl';
import SettingsControl from 'features/toolbar/Toolbar/controls/settings/SettingsControl';
import EndCallControl from 'features/toolbar/Toolbar/controls/end-call/EndCallControl';
import { ToolbarOrientation } from 'features/toolbar/types';
import { usePermissions } from 'features/permissions/usePermissions';
import { PermissionTypes } from 'features/permissions/types';
import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { Fragment } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectDeviceType } from 'features/application/applicationSlice';
import OverflowMenuContainer from 'features/toolbar/OverflowMenuContainer';

type DesktopMenuProps = {
  orientation: ToolbarOrientation;
};

const ToolbarNav = ({ orientation }: DesktopMenuProps) => {
  const { hasPermissions } = usePermissions();

  const allowBroadcast = hasPermissions(PermissionTypes.broadcast);
  const deviceType = useAppSelector(selectDeviceType);

  const screenshareAvailable = useFeatureFlag('screenshare');
  const allowScreenshare = hasPermissions(PermissionTypes.screenshare);

  const primaryMenuItems = [];

  if (allowBroadcast) {
    primaryMenuItems.push(
      <Fragment key="broadcast-controls">
        <CameraControl />
        <MicControl />
      </Fragment>
    );
  }

  if (screenshareAvailable && allowScreenshare && deviceType === 'desktop') {
    primaryMenuItems.push(<ScreenshareControl key="screenshare" />);
  }

  return (
    <ToolbarNavRoot orientation={orientation}>
      {primaryMenuItems.length ? (
        <MenuFixedItems orientation={orientation}>{primaryMenuItems}</MenuFixedItems>
      ) : null}
      <OverflowMenuContainer leading={primaryMenuItems.length === 0} />
      <MenuFixedItems orientation={orientation}>
        <SettingsControl />
        <EndCallControl />
      </MenuFixedItems>
    </ToolbarNavRoot>
  );
};

export default ToolbarNav;
