import React, { forwardRef } from 'react';
import Icon, { IconProps } from 'components/Icon';
import { BadgeProps as MuiBadgeProps, Box, IconButton, IconButtonProps } from '@mui/material';
import { iconButtonClasses } from '@mui/material/IconButton';
import { alpha, getContrastRatio, styled } from '@mui/material/styles';
import { ToolbarOrientation } from 'features/toolbar/types';
import Badge from 'features/toolbar/Badge';

type ToolbarControlProps = IconButtonProps<
  'button',
  {
    icon: string | React.ReactElement<IconProps>;
    orientation: ToolbarOrientation;
    BadgeProps?: Omit<MuiBadgeProps, 'badgeContent'>;
    badgeContent?: React.ReactNode;
    badgeActive?: boolean;
    error?: boolean;
    active?: boolean;
    controlColor?: string;
  }
>;

export const Root = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'error' && prop !== 'active' && prop !== 'controlColor',
})<{ error?: boolean; active?: boolean; controlColor?: string }>(({
  theme,
  error,
  active,
  controlColor,
}) => {
  const currentColor = error
    ? theme.palette.error.main
    : controlColor || theme.palette.getContrastText(theme.toolbar.background);

  const bgColor =
    getContrastRatio(theme.palette.common.white, theme.toolbar.background) >
    theme.palette.contrastThreshold
      ? alpha(theme.palette.common.white, 0.2)
      : alpha(theme.palette.action.active, theme.palette.action.hoverOpacity);

  return {
    color: currentColor,
    '&:hover': {
      backgroundColor: bgColor,
    },
    '&:focus-visible': {
      backgroundColor: bgColor,
    },
    [`&.${iconButtonClasses.disabled}`]: {
      color: theme.palette.grey['600'],
    },
    ...(active && {
      color: theme.palette.primary.light,
    }),
  };
});

const WarningIcon = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'orientation',
})({
  position: 'absolute',
  width: 12,
  height: 12,
  top: '50%',
  marginTop: -6,
  right: -10,
});

const ToolbarControl = forwardRef<HTMLButtonElement, ToolbarControlProps>(
  (
    { badgeContent, error, icon, badgeActive, BadgeProps, orientation, disabled, active, ...props },
    ref
  ) => (
    <Root {...props} type="button" disabled={disabled} active={active} error={error} ref={ref}>
      <Box component="span" sx={{ position: 'relative', lineHeight: 1, pointerEvents: 'none' }}>
        <Badge
          {...BadgeProps}
          active={badgeActive}
          badgeContent={badgeContent}
          max={9999}
          slotProps={{
            badge: {
              // @ts-ignore
              'data-testid': 'toolbar-control-badge',
            },
          }}
        >
          {typeof icon === 'string' ? (
            <Icon
              name={icon}
              sx={{
                fontSize: 32,
              }}
            />
          ) : (
            icon
          )}
        </Badge>
      </Box>
      {error && (
        <WarningIcon
          component="svg"
          role="presentation"
          // @ts-ignore
          viewBox="0 0 12 12"
          orientation={orientation}
        >
          <path
            d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
            fill="#FF0000"
          />
          <path d="M7 10H5V8H7V10ZM7 6H5V2H7V6Z" fill="white" />
        </WarningIcon>
      )}
    </Root>
  )
);

export default ToolbarControl;
