import { useAppSelector } from 'store/hooks';
import { PermissionTypes } from 'features/permissions/types';
import { selectLocalUser } from 'features/users/usersSlice';
import { useCallback } from 'react';
import {
  HasDynamicPermissionsOptions,
  lookupDynamicPermission,
} from 'features/permissions/utils/lookupDynamicPermission';
import { refinePermissions } from 'features/permissions/utils/refinePermissions';
import { HasPermissionsOptions } from 'features/permissions/utils/checkPermissions';
import { selectPermissionsState } from 'features/permissions/permissionsSlice';
import { useRecorder } from 'features/recorder/useRecorder';

export const usePermissions = () => {
  const permissionsState = useAppSelector(selectPermissionsState);
  const localUser = useAppSelector(selectLocalUser);
  const isRecorder = useRecorder();

  const hasPermissions = useCallback(
    (permissions: PermissionTypes | PermissionTypes[], options: HasPermissionsOptions = {}) =>
      refinePermissions(permissions, {
        permissionsState,
        localUser,
        isRecorder,
        ...options,
      }),
    [localUser, permissionsState, isRecorder]
  );

  const hasDynamicPermissions = useCallback(
    (permission: PermissionTypes, { targetUser }: HasDynamicPermissionsOptions) => {
      let { dynamicPermissions } = localUser;

      if (targetUser) {
        dynamicPermissions = targetUser.dynamicPermissions;
      }

      return lookupDynamicPermission(permission, dynamicPermissions);
    },
    [localUser]
  );

  return {
    hasPermissions,
    hasDynamicPermissions,
  };
};
