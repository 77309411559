import { Box, Checkbox, FormControl, FormControlLabel, Typography } from '@mui/material';
import { RoomLoginFormValues } from 'features/room/components/RoomLoginForm';
import { useGDPRConfig } from 'hooks/useGDPRConfig';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';

export const ConsentField = ({ control }: { control: Control<RoomLoginFormValues> }) => {
  const { consentConfig, consentRequired } = useGDPRConfig();

  return consentConfig && consentRequired ? (
    <Box>
      <Typography
        sx={{ margin: '1rem 0 .75rem', textAlign: 'left' }}
        dangerouslySetInnerHTML={{
          __html: consentConfig.message,
        }}
      />
      <FormControl fullWidth>
        <Controller
          name="consentGiven"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              sx={{ textAlign: 'left' }}
              control={<Checkbox {...field} />}
              label={consentConfig.checkboxMessage}
            />
          )}
        />
      </FormControl>
    </Box>
  ) : null;
};
