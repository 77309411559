import { call, fork, put, select, take } from 'redux-saga/effects';
import { selectIsRecorderSession } from 'features/recorder/recorderSlice';
import { startRecorderSaga } from 'features/recorder/sagas/startRecorderSaga';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { MediaDevicesPermissions } from 'features/user-media/types';
import { selectAllMediaDevicePermissions } from 'features/user-media/userMediaSlice';
import { openModal } from 'features/modal/modalSlice';
import { roomRendered } from 'features/room/actions';
import { inviteParticipantSuggestionSaga } from 'features/room/sagas/inviteParticipantSuggestionSaga';
import { waitingEntryNotificationUpdateRequested } from 'features/notifications/actions';
import { User } from 'features/users/types';
import { selectAwaitingEntryUsers } from 'features/users/selectors/selectAwaitingEntryUsers';
import { board } from 'utils/whiteboard/BoardStateManager';

export function* onRoomJoinedSaga() {
  board.shouldInitStore = true;

  const isRecorderSession: boolean = yield select(selectIsRecorderSession);
  if (isRecorderSession) {
    yield call(startRecorderSaga);
  } else {
    yield take(roomRendered);

    const canBroadcast: boolean = yield call(hasPermissionsSaga, PermissionTypes.broadcast);
    if (canBroadcast) {
      const permissions: MediaDevicesPermissions = yield select(selectAllMediaDevicePermissions);
      if (permissions.audioinput === 'denied' && permissions.videoinput === 'denied') {
        yield put(openModal('mediaDevicesBlocked'));
      }
    }

    yield fork(inviteParticipantSuggestionSaga);

    const awaitingUsers: User[] = yield select(selectAwaitingEntryUsers);
    if (awaitingUsers.length) {
      yield put(waitingEntryNotificationUpdateRequested());
    }
  }
}
