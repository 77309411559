import { call, put, select } from 'redux-saga/effects';
import { exportQuestionsFulfilled, exportQuestionsRejected } from 'features/qa/qaSlice';
import { exportQuestionsService } from 'features/qa/services';
import { downloadFile } from 'utils/http/downloadFile';
import { AxiosResponse } from 'axios';
import { selectRoomId } from 'features/room/roomSlice';

export function* exportQuestionsSaga() {
  try {
    const roomId: string = yield select(selectRoomId);

    const response: AxiosResponse<Blob> = yield call(exportQuestionsService, roomId);
    const contentDisposition = response.headers['content-disposition'];

    yield call(downloadFile, response.data, contentDisposition);

    yield put(exportQuestionsFulfilled());
  } catch (error) {
    yield put(exportQuestionsRejected());
  }
}
