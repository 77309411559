import { createAction } from '@reduxjs/toolkit';
import { UpdateFeatureFlagPayload } from 'features/feature-set/types';

export const enabledFeatureRequested = createAction<UpdateFeatureFlagPayload>(
  'features/enabledFeatureRequested'
);

export const signalingFeatureEnabled = createAction<UpdateFeatureFlagPayload>(
  'signaling/featureEnabled'
);

export const disableFeatureRequested = createAction<UpdateFeatureFlagPayload>(
  'features/disableFeatureRequested'
);

export const signalingFeatureDisabled = createAction<UpdateFeatureFlagPayload>(
  'signaling/featureDisabled'
);
