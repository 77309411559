import { call } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { PayloadAction } from '@reduxjs/toolkit';
import { UserId } from 'features/users/types';
import { logger } from 'utils/logger';

export function* allowBroadcastSaga(action: PayloadAction<UserId>) {
  logger
    .remote({ action: true, tier: 1 })
    .info(`The user allowed broadcast to the user with id=${action.payload}`);

  yield call(SignalingSocket.send, {
    event: 'allowBroadcast',
    data: {
      id: action.payload,
    },
  });
}
