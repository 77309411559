import React from 'react';
import { useAppSelector } from 'store/hooks';
import { selectFeedIdByUser } from 'features/streaming/streamingSlice';
import { RemoteVideo } from 'features/streaming/components/media/remote-stream/RemoteVideo';
import { FeedId, SubscriberMid } from 'features/streaming/types';
import { UserId } from 'features/users/types';

type RemoteAudioWrapperProps = {
  mid: SubscriberMid;
  userId: UserId;
  feedId?: FeedId;
  className?: string;
  mode: 'media' | 'screenshare';
};

const RemoteVideoWrapper = ({ mode, className, ...source }: RemoteAudioWrapperProps) => {
  const lookupFeedId = useAppSelector((state) => selectFeedIdByUser(state, source.userId));
  const feedId = source.feedId || lookupFeedId;

  if (!feedId) {
    return null;
  }

  return (
    <RemoteVideo mid={source.mid} userId={source.userId} key={feedId} className={className || ''} />
  );
};

export default RemoteVideoWrapper;
