import { call } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { downloadContentLibraryFileRequested } from 'features/content-library/actions';
import { downloadContentLibraryFileService } from 'features/content-library/services';
import { notification } from 'features/notifications/toast/notification';
import i18n from 'i18n';
import { downloadFile } from 'utils/http/downloadFile';

export function* downloadContentLibraryFileSaga(
  action: ReturnType<typeof downloadContentLibraryFileRequested>
) {
  try {
    const {
      fileName,
      contentType,
      data,
    }: {
      fileName: string;
      data: Blob;
      contentType: string;
    } = yield call(downloadContentLibraryFileService, action.payload);

    yield call(downloadFile, data, null, {
      fileName,
      mimeType: contentType,
    });
  } catch (error) {
    Sentry.captureException(error);
    notification(i18n.t('notifications:content_library.file_download_error'));
  }
}
