import { call, delay, put, select } from 'redux-saga/effects';
import { UserId } from 'features/users/types';
import { selectAvailableUserIds } from 'features/users/usersSlice';
import { Action } from '@reduxjs/toolkit';
import { addActionNotification } from 'features/notifications/addActionNotification';
import i18n from 'i18n';
import { openModal } from 'features/modal/modalSlice';
import { INVITE_PARTICIPANT_SUGGESTION_ID } from 'features/notifications/constants';
import { selectInviteAvailable } from 'features/permissions/permissionsSlice';

export function* inviteParticipantSuggestionSaga() {
  const isInviteAvailable: boolean = yield select(selectInviteAvailable);
  if (!isInviteAvailable) {
    return;
  }

  // avoid notification blink on concurrent user joins
  yield delay(250);

  const userIds: UserId[] = yield select(selectAvailableUserIds);

  // show the suggestion to invite participants if it is the only user in a room
  if (userIds.length === 1) {
    const actionNotificationThunk: Action = yield call(
      addActionNotification,
      {
        content: i18n.t('notifications:invite_participant_suggestion.content'),
        okText: i18n.t('notifications:invite_participant_suggestion.btn_ok_label'),
        okAction: openModal('inviteUser'),
        preventCloseOnAction: true,
      },
      {
        toastId: INVITE_PARTICIPANT_SUGGESTION_ID,
      }
    );

    yield put(actionNotificationThunk);
  }
}
