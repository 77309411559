import React from 'react';
import { Box, Typography } from '@mui/material';
import CloseButton from 'components/CloseButton';
import { styled } from '@mui/material/styles';

export type ToolbarPanelHeaderProps = {
  children: React.ReactNode;
  actions?: React.ReactNode;
  onClose: (event: React.SyntheticEvent | Event) => void;
};

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(4, 2, 2, 5),
}));

const ToolbarPanelHeader = ({ children, actions, onClose }: ToolbarPanelHeaderProps) => (
  <Root>
    <Typography variant="h3" className="ToolbarPanelHeader-title">
      {children}
    </Typography>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {actions && actions}
      <CloseButton
        name="close"
        onClick={onClose}
        sx={{
          p: 1,
        }}
      />
    </Box>
  </Root>
);

export default ToolbarPanelHeader;
