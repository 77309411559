import { Dialog, List, ListItemButton, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTranscriptionControl } from 'features/toolbar/Toolbar/controls/transcription/useTranscriptionControl';

export interface TranscriptionOptionsDialogProps {
  open: boolean;
  onClose: () => void;
}

const TranscriptionOptionsDialog = ({ onClose, open }: TranscriptionOptionsDialogProps) => {
  const { t } = useTranslation('room');

  const { stopTranscription, toggleCaptions, captionsDisplayed, captionsAvailable } =
    useTranscriptionControl();

  const handleStopTranscription = () => {
    onClose();
    stopTranscription();
  };

  const handleToggleCaptions = () => {
    onClose();
    toggleCaptions();
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <List>
        <ListItemButton autoFocus onClick={handleStopTranscription}>
          <ListItemText primary={t('toolbar.transcription.menu_options.stop_transcription')} />
        </ListItemButton>
        {captionsAvailable && (
          <ListItemButton autoFocus onClick={handleToggleCaptions}>
            <ListItemText
              primary={
                captionsDisplayed
                  ? t('toolbar.transcription.menu_options.hide_captions')
                  : t('toolbar.transcription.menu_options.show_captions')
              }
            />
          </ListItemButton>
        )}
      </List>
    </Dialog>
  );
};

export default TranscriptionOptionsDialog;
