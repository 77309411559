import { selectIsBreakoutRoom } from 'features/breakout-rooms/breakoutRoomsSlice';
import { notification } from 'features/notifications/toast/notification';
import { call, select } from 'redux-saga/effects';
import i18n from 'i18n';
import { selectTranscriptionStarted } from 'features/transcripts/slices/transcription/transcriptionSlice';

export function* notifyTranscriptionStateSaga() {
  const transcriptionStarted: boolean = yield select(selectTranscriptionStarted);
  const isBreakout: boolean = yield select(selectIsBreakoutRoom);

  if (transcriptionStarted && isBreakout) {
    yield call(notification, i18n.t('notifications:transcription_disabled_breakout'));
  }
}
