import { call } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { disableFeatureRequested } from 'features/feature-set/actions';

export function* disableFeatureSaga(action: ReturnType<typeof disableFeatureRequested>) {
  yield call(SignalingSocket.send, {
    event: 'disableFeature',
    data: { feature: action.payload.feature },
  });
}
