export const getZenModeSetting = () => {
  try {
    return localStorage.getItem('savedZenModeSetting');
  } catch {
    return 'true';
  }
};

export const setZenModeSetting = (checked: boolean) => {
  try {
    localStorage.setItem('savedZenModeSetting', `${!checked}`);
  } catch {
    // do nothing
  }
};
