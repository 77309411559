import { styled } from '@mui/material/styles';
import Logo from 'features/room/components/topbar/Logo';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  color: theme.palette.common.white,
  alignItems: 'center',
  height: theme.topBar.height,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.layout.innerPadding,
  background: theme.room.background,
  zIndex: theme.zIndex.appBar,
}));

const RecorderTopBar = () => (
  <Root>
    <Logo data-testid="topbar-recorder-logo" />
  </Root>
);

export default RecorderTopBar;
