import { cssTransition } from 'features/notifications/toast/notification';

export const simpleToastTransition = cssTransition({
  enter: 'toast-fadeIn',
  exit: 'toast-fadeOut',
  appendPosition: true,
  collapseDuration: 200,
});

export const advancedToastTransition = cssTransition({
  enter: 'action-toast-in',
  // exit: 'action-toast-out',
  exit: 'toast-fadeOut',
  appendPosition: true,
  collapseDuration: 200,
});
