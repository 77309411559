import React from 'react';
import { ListItemText } from '@mui/material';
import { BreakoutRoomWithUserIds } from 'features/breakout-rooms/types';
import { TargetRoomsListContext } from 'features/breakout-rooms/components/target-room-selector/TargetRoomsList';
import StandaloneBadge from 'components/StandaloneBadge';

export type TargetRoomListItemContentProps = {
  room: BreakoutRoomWithUserIds;
  context: TargetRoomsListContext;
};

const primaryTypographyProps = {
  noWrap: true,
};

const TargetRoomListItemContent = React.memo(
  ({ room, context }: TargetRoomListItemContentProps) => (
    <>
      <ListItemText primaryTypographyProps={primaryTypographyProps} primary={room.name} />
      <StandaloneBadge
        badgeContent={`${room.users.length}/${context.participantsLimit}`}
        showZero
      />
    </>
  )
);

export default TargetRoomListItemContent;
