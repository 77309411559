import { call } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { assignUsersToBreakoutRoomRequested } from 'features/breakout-rooms/actions';

export function* assignUsersToBreakoutRoomSaga(
  action: ReturnType<typeof assignUsersToBreakoutRoomRequested>
) {
  yield call(SignalingSocket.send, {
    event: 'assignUsersToBreakoutRoom',
    data: {
      id: action.payload.id,
      users: action.payload.users,
    },
  });
}
