import { logger } from 'utils/logger';

export const overrideMS = () => {
  window.OldMS = window.MediaStream;

  window.mspoool = [];

  class DebugMediaStream extends window.OldMS {
    constructor() {
      super();

      logger.debug('New MS created', this.id);
      window.mspoool.push(this);
    }
  }

  window.MediaStream = DebugMediaStream;
};
