import SearchInput from 'components/SearchInput';
import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TargetRoomsList from 'features/breakout-rooms/components/target-room-selector/TargetRoomsList';
import { useSearch } from 'hooks/useSearch';

export type RoomSelectHandler = (roomId: string) => void;
export type SelectedRoomId = string | undefined;

export type TargetRoomSelectorProps = {
  selectRoom: RoomSelectHandler;
  participantsLimit: number;
  selectedId?: SelectedRoomId;
  excludedIds?: string[];
};

const TargetRoomSelector = React.memo(
  ({ selectRoom, selectedId, participantsLimit, excludedIds }: TargetRoomSelectorProps) => {
    const { t } = useTranslation(['breakout-rooms', 'common']);

    const { onSearchInputChange, searchQuery } = useSearch();

    return (
      <div>
        <Box
          sx={{
            mb: 6,
          }}
        >
          <SearchInput
            fullWidth
            placeholder={t('common:input_search_placeholder')}
            onChange={onSearchInputChange}
            inputProps={{
              'aria-label': t('common:input_search_placeholder'),
            }}
          />
        </Box>
        <TargetRoomsList
          searchQuery={searchQuery}
          selectedId={selectedId}
          selectRoom={selectRoom}
          participantsLimit={participantsLimit}
          excludedIds={excludedIds}
        />
      </div>
    );
  }
);

export default TargetRoomSelector;
