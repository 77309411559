import {
  customControlAdded,
  customControlRemoved,
} from 'features/layout/features/config/configSlice';
import { put } from 'redux-saga/effects';
import { TileActionProperties, TileControlTypes } from 'utils/eventBus';

export function* onControlTileActionSaga(
  type: TileControlTypes,
  data: {
    name: string;
    properties: TileActionProperties;
  }
) {
  if (type === 'addTileAction') {
    yield put(customControlAdded(data));
    return;
  }

  yield put(customControlRemoved(data.name));
}
