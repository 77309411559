import { call, put } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { broadcastRoomMessageRequested } from 'features/breakout-rooms/actions';
import { Action } from '@reduxjs/toolkit';
import { displaySimpleNotification } from 'features/notifications/simple-notifications/displaySimpleNotification';
import i18n from 'i18n';

export function* broadcastMessageSaga(action: ReturnType<typeof broadcastRoomMessageRequested>) {
  yield call(SignalingSocket.send, {
    event: 'broadcastBreakoutRoomMessage',
    data: {
      message: action.payload,
    },
  });

  const notificationThunk: Action = yield call(displaySimpleNotification, {
    content: i18n.t('notifications:breakout_rooms.message_broadcast'),
  });
  yield put(notificationThunk);
}
