import { Box, Button, Typography } from '@mui/material';
import FormFooter from 'components/FormFooter';
import { ConsentField } from 'features/room/components/ConsentField';
import { loginFinished } from 'features/room/roomSlice';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import { styleConstants } from 'theme/styleConstants';
import { setConsentFlag } from 'utils/consentFlag';
import { getAPIDomain } from 'utils/http';
import { logger } from 'utils/logger';

export interface RoomLoginFormValues {
  search: string;
  consentGiven?: boolean;
}

const RoomConsentScreen = () => {
  const { t } = useTranslation(['join', 'common']);
  const dispatch = useAppDispatch();

  const { handleSubmit, control } = useForm<RoomLoginFormValues>({
    defaultValues: {
      consentGiven: false,
    },
  });

  const onSubmit = handleSubmit((data) => {
    if (data.consentGiven) {
      logger.remote({ tier: 1 }).log('User accepted GDPR terms for team: ', getAPIDomain());

      setConsentFlag();
    }

    dispatch(loginFinished());
  });

  return (
    <Box
      sx={{
        maxWidth: styleConstants.narrowContainerWidth,
        m: 'auto',
        textAlign: 'center',
      }}
    >
      <Typography variant="h3" sx={{ mb: { xs: 8, lg: 11 } }}>
        {t('login.title')}
      </Typography>
      <form onSubmit={onSubmit}>
        <ConsentField control={control} />

        <FormFooter>
          <Button type="submit" variant="contained" fullWidth>
            {t('login.btn_label_join')}
          </Button>
        </FormFooter>
      </form>
    </Box>
  );
};

export default RoomConsentScreen;
