import WhiteboardControlMobile from 'features/toolbar/Toolbar/controls/whiteboard/WhiteboardControlMobile';
import React, { ComponentPropsWithoutRef } from 'react';
import { styled } from '@mui/material/styles';
import { ToolbarPlacement } from 'features/toolbar/types';
import { Backdrop, ClickAwayListener, Fade, MenuList } from '@mui/material';
import ChatControlMobile from 'features/toolbar/Toolbar/controls/chat/ChatControlMobile';
import ParticipantsControlMobile from 'features/toolbar/Toolbar/controls/participants/ParticipantsControlMobile';
import RecordingControlMobile from 'features/toolbar/Toolbar/controls/recording/RecordingControlMobile';
import SettingsControlMobile from 'features/toolbar/Toolbar/controls/settings/SettingsControlMobile';
import EndCallControlMobile from 'features/toolbar/Toolbar/controls/end-call/EndCallControlMobile';
import RaiseHandControlMobile from 'features/toolbar/Toolbar/controls/raise-hand/RaiseHandControlMobile';
import { canExposeInternals } from 'utils/development';
import QAControlMobile from 'features/toolbar/Toolbar/controls/qa/QAControlMobile';
import PollsControlMobile from 'features/toolbar/Toolbar/controls/polls/PollsControlMobile';
import TranscriptionControlMobile from 'features/toolbar/Toolbar/controls/transcription/TranscriptionControlMobile';
import ContentLibraryControlMobile from 'features/toolbar/Toolbar/controls/content-library/ContentLibraryControlMobile';

const Root = styled('div')<{ placement: ToolbarPlacement }>(({ placement, theme }) => {
  const offset = `calc(${theme.toolbar.width} - ${theme.layout.innerPadding})`;

  return {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: theme.zIndex.appBar,
    padding: theme.layout.innerPadding,
    display: 'flex',
    ...(placement === 'right' && {
      left: 'auto',
      right: offset,
      paddingRight: 0,
      width: '55%',
    }),
    ...(placement === 'left' && {
      left: offset,
      paddingLeft: 0,
      width: '55%',
    }),
    ...(placement === 'bottom' && {
      bottom: offset,
      paddingBottom: 0,
    }),
  };
});

const Inner = styled('div')<{ placement: ToolbarPlacement }>(({ theme, placement }) => ({
  maxHeight: '60vh',
  width: '100%',
  marginTop: 'auto',
  background: theme.toolbar.background,
  overflowY: 'auto',
  '& .MuiMenuItem-root': {
    minHeight: '52px',
    padding: '9px 28px',
    color: theme.palette.getContrastText(theme.toolbar.background),
  },
  '&& .MuiListItemIcon-root': {
    minWidth: '40px',
  },
  '@media (max-height: 500px)': {
    maxHeight: '100%',
  },
  ...(placement === 'left' && {
    borderTopRightRadius: '16px',
    borderBottomRightRadius: '16px',
    '& .MuiList-root': {
      paddingLeft: theme.layout.innerPadding,
    },
  }),
  ...(placement === 'right' && {
    borderTopLeftRadius: '16px',
    borderBottomLeftRadius: '16px',
    '& .MuiList-root': {
      paddingRight: theme.layout.innerPadding,
    },
  }),
  ...(placement === 'bottom' && {
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
  }),
}));

export type MobileMenuProps = ComponentPropsWithoutRef<'div'> & {
  open: boolean;
  onClose: () => void;
  placement: ToolbarPlacement;
};

const MobileMenu = ({ open, placement, onClose, ...props }: MobileMenuProps) => (
  <>
    <Backdrop
      open={open}
      sx={{
        zIndex: 10,
      }}
      data-testid="mobile-menu-backdrop"
    />
    <Fade in={open} mountOnEnter unmountOnExit>
      <Root {...props} placement={placement}>
        <ClickAwayListener onClickAway={onClose}>
          <Inner placement={placement}>
            <MenuList>
              <ParticipantsControlMobile onClick={onClose} />
              <ChatControlMobile onClick={onClose} />
              <QAControlMobile onClick={onClose} />
              <PollsControlMobile onClick={onClose} />
              <ContentLibraryControlMobile onClick={onClose} />
              <RaiseHandControlMobile onClick={onClose} />
              <RecordingControlMobile onClick={onClose} />
              <TranscriptionControlMobile onClick={onClose} />
              {canExposeInternals() ? <WhiteboardControlMobile onClick={onClose} /> : null}
              <SettingsControlMobile onClick={onClose} />
              <EndCallControlMobile />
            </MenuList>
          </Inner>
        </ClickAwayListener>
      </Root>
    </Fade>
  </>
);

export default MobileMenu;
