import { SignalingSocket } from 'services/signaling';
import { call, select, delay } from 'redux-saga/effects';
import { pluginHandleAttachFailed } from 'features/streaming/actions';
import { selectIsRecorderSession } from 'features/recorder/recorderSlice';

export function* handlePluginAttachFailureSaga(
  action: ReturnType<typeof pluginHandleAttachFailed>
) {
  const isRecorderSession: boolean = yield select(selectIsRecorderSession);
  if (isRecorderSession) {
    // avoid potential race conditions with non-initialized recorder
    yield delay(5000);

    yield call(SignalingSocket.close, undefined, action.payload);
  }
}
