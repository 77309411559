import { ChangeEvent } from 'react';
import { TabPanelInner } from 'components/Tabs';
import { FormControl } from '@mui/material';
import InputLabel from 'components/InputLabel';
import { useAppSelector } from 'store/hooks';
import { selectTargetTilesCount } from 'features/dev-mode/devModeSlice';
import Input from 'components/Input';
import { RTCClient } from 'utils/webrtc';

const DevModeTab = () => {
  const targetTilesCount = useAppSelector(selectTargetTilesCount);

  const handleDebugTilesChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newTargetCount = Number(e.target.value) || 0;
    RTCClient.mediaDebug.setTargetTiles(newTargetCount);
  };

  return (
    <TabPanelInner>
      <FormControl sx={{ marginTop: '1rem' }} fullWidth>
        <InputLabel id="tile-count-label">Target number of participant tiles</InputLabel>
        <Input fullWidth defaultValue={targetTilesCount} onChange={handleDebugTilesChange} />
      </FormControl>
    </TabPanelInner>
  );
};

export default DevModeTab;
