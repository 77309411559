export const generateSharedKey = async (publicKey: CryptoKey, privateKey: CryptoKey) => {
  const material = await window.crypto.subtle.deriveBits(
    {
      name: 'ECDH',
      public: publicKey,
    },
    privateKey,
    256
  );

  return window.crypto.subtle.importKey('raw', material, { name: 'HKDF' }, false, [
    'deriveKey',
    'deriveBits',
  ]);
};
