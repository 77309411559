import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, ButtonProps } from '@mui/material';
import Icon from 'components/Icon';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useControlled } from '@mui/material/utils';

const Root = styled(Button)({
  '&&&': {
    textTransform: 'none',
    color: 'inherit',
    letterSpacing: 'normal',
  },
});

type CopyHandler = (text: string, result: boolean) => void;

type CopyButtonProps = Omit<CopyToClipboard.Props, 'children' | 'onCopy'> &
  Omit<ButtonProps, 'children'> & {
    children: React.ReactNode | ((copied: boolean) => React.ReactNode);
    copied?: boolean;
    icon?: React.ReactNode;
    onCopy?: CopyHandler;
  };

const CopyButton = ({
  copied: controlledCopied,
  children,
  onCopy,
  text,
  options,
  icon,
  ...props
}: CopyButtonProps) => {
  const [copied, setCopied] = useControlled({
    controlled: controlledCopied,
    default: false,
    name: 'CopyButton',
    state: 'copied',
  });

  const handleCopy: CopyHandler = (copiedText, result) => {
    setCopied(true);

    onCopy?.(copiedText, result);
  };

  return (
    <CopyToClipboard onCopy={handleCopy} text={text} {...options}>
      <Root color="grayscale" {...props} startIcon={icon || <Icon name="copy" />}>
        {typeof children === 'function' ? children(copied) : children}
      </Root>
    </CopyToClipboard>
  );
};

export default CopyButton;
