import MobileMenuItem from 'features/toolbar/Toolbar/mobile/MobileMenuItem';
import React from 'react';
import { MobileMenuItemWrapperProps } from 'features/toolbar/types';
import { useContentLibraryControl } from 'features/toolbar/Toolbar/controls/content-library/useContentLibraryControl';

const ContentLibraryControlMobile = ({ onClick }: MobileMenuItemWrapperProps) => {
  const { active, label, toggleContentLibrary, isFeatureAvailable, icon } =
    useContentLibraryControl();

  if (!isFeatureAvailable) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    onClick?.(event);
    toggleContentLibrary();
  };

  return (
    <MobileMenuItem
      id="content-library-control"
      selected={active}
      icon={icon}
      onClick={handleClick}
    >
      {label}
    </MobileMenuItem>
  );
};

export default ContentLibraryControlMobile;
