import { useCallback, useRef } from 'react';
import { useAudioLevel } from 'hooks/useAudioLevel';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

const Meter = styled('div')({
  '--vu-meter-value': '0%',
  display: 'flex',
  justifyContent: 'flex-end',
  height: '0.75rem',
  overflow: 'hidden',
  background: 'linear-gradient(90deg, #ffe60a 12%, #00ff06 25%, #0f0 80%, #f00 94%)',
  borderRadius: '8px',
});

const Bar = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: 'calc(100% - var(--vu-meter-value))',
  height: '0.75rem',
  overflow: 'hidden',
  backgroundColor: '#c0c0c0',
});

const Label = styled('span')(({ theme }) => ({
  display: 'block',
  marginBottom: '0.375rem',
  color: theme.palette.text.secondary,
  fontSize: '0.75rem',
}));

type VUMeterProps = {
  on: boolean;
  label?: string;
  className?: string;
  stream: MediaStream | null;
};

const VUMeter = ({ stream, label, on, ...props }: BoxProps<'div', VUMeterProps>) => {
  const { t } = useTranslation('common');
  const ref = useRef<HTMLDivElement>(null);

  const updateVUMeter = useCallback(
    (width: number) => {
      if (ref.current && on) {
        ref.current.style.setProperty('--vu-meter-value', `${width}%`);
        ref.current.setAttribute('aria-valuenow', String(width));
      }
    },
    [on]
  );

  useAudioLevel({
    stream,
    onChange: updateVUMeter,
  });

  return (
    <Box {...props}>
      {label && <Label>{label}</Label>}
      <Meter>
        <Bar
          ref={ref}
          role="progressbar"
          aria-label={t('vumeter.aria_label')}
          aria-valuemin={0}
          aria-valuemax={100}
        />
      </Meter>
    </Box>
  );
};

export default VUMeter;
