import BroadcastSettings from 'features/room/broadcast-setup/BroadcastSettings';
import { Box } from '@mui/material';

const BroadcastPreview = () => (
  <form>
    <Box
      sx={{
        width: '100%',
        mt: 5,
      }}
    >
      <BroadcastSettings />
    </Box>
  </form>
);

export default BroadcastPreview;
