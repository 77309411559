import { streamMaximized, streamMinimized } from 'features/layout/actions';
import { selectCurrentLayoutMode } from 'features/layout/features/config/configSlice';
import {
  contentWidthChanged,
  selectPinnedContentWidth,
} from 'features/layout/features/contentDimensions/contentDimensionsSlice';
import { selectOrderedStreams } from 'features/layout/features/order/orderSlice';
import { LayoutMode, SourceDetails } from 'features/layout/types';
import { UserId } from 'features/users/types';
import { selectLocalUserId } from 'features/users/usersSlice';
import { call, put, select } from 'redux-saga/effects';
import { ContentTypes, eventBus } from 'utils/eventBus';

export function* onControlRemoteTileCommandSaga(
  command: ContentTypes,
  data: { userId: UserId; tile: 'media' | 'screenshare' }
) {
  const activeMode: LayoutMode = yield select(selectCurrentLayoutMode);
  if (activeMode === LayoutMode.auto) {
    yield call(eventBus.error, {
      name: 'layout',
      message: 'Cannot control layout in auto mode',
    });

    return;
  }

  if (command === 'minimizeContent') {
    yield put(streamMinimized());

    return;
  }

  const { userId, tile } = data;

  const streams: SourceDetails[] = yield select(selectOrderedStreams);
  const localUserId: UserId = yield select(selectLocalUserId);

  const local = userId === localUserId;

  const maximizedStream: SourceDetails = {
    kind: `${tile === 'screenshare' ? 'screenshare-' : ''}${local ? 'local' : 'remote'}`,
    userId,
  };

  // Note that `sourceMatches` util that we have shouldn't be used here
  // it also matches by feed id, which is unknown here, and we actually 🤓 do a lookup
  // to find it
  const existingStream = streams.find(
    (stream) => stream.kind === maximizedStream.kind && stream.userId === maximizedStream.userId
  );

  if (!local && !existingStream) {
    return;
  }

  maximizedStream.feedId = existingStream?.feedId;

  if (command === 'maximizeUser') {
    yield put(contentWidthChanged(1));
  } else {
    const pinWidth: number = yield select(selectPinnedContentWidth);

    yield put(contentWidthChanged(pinWidth));
  }

  yield put(streamMaximized({ source: maximizedStream }));
}
