import { fork } from 'redux-saga/effects';
import { exchangeEncryptionKeysSaga } from 'features/e2ee/sagas/exchangeEncryptionKeysSaga';
import * as Sentry from '@sentry/react';
import { logger } from 'utils/logger';
import { User } from 'features/users/types';

export function* bulkExchangeEncryptionKeysSaga(users: User[]) {
  for (const user of users) {
    try {
      yield fork(exchangeEncryptionKeysSaga, {
        id: user.id,
        e2eePublicKey: user.e2eePublicKey!,
      });
    } catch (error) {
      Sentry.captureException(error);
      logger
        .remote({ system: true, capture: 'e2ee' })
        .error(`Encryption key exchange failed with the userId=${user.id}`, error);
    }
  }
}
