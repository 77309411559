import { useToolbarPanel } from 'hooks/useToolbarPanel';
import { useQA } from 'features/qa/useQA';
import { useAppSelector } from 'store/hooks';
import { selectQAUnreadCount } from 'features/qa/qaSlice';

export const useQAControl = () => {
  const { toolbarPanel, togglePanel } = useToolbarPanel();

  const unreadCount = useAppSelector(selectQAUnreadCount);

  const { isFeatureAvailable } = useQA();

  const toggleQAPanel = () => {
    togglePanel('qa');
  };

  return {
    active: toolbarPanel === 'qa',
    togglePanel: toggleQAPanel,
    icon: 'qa',
    isFeatureAvailable,
    unreadCount,
  };
};
