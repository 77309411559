import React from 'react';
import { useTranslation } from 'react-i18next';
import ToolbarMenuItem from 'features/toolbar/ToolbarMenuItem/ToolbarMenuItem';
import { ToolbarMenuItemWrapperProps } from 'features/toolbar/types';
import { usePollsControl } from 'features/toolbar/Toolbar/controls/polls/usePollsControl';

const PollsControl = ({ onClick }: ToolbarMenuItemWrapperProps) => {
  const { t } = useTranslation('room');

  const { togglePanel, icon, active, isFeatureAvailable, unreadUpdates } = usePollsControl();

  if (!isFeatureAvailable) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(event);
    togglePanel();
  };

  return (
    <ToolbarMenuItem
      id="polls"
      overlay={t('toolbar.polls')}
      ariaLabel={t('toolbar.polls')}
      icon={icon}
      badgeActive
      BadgeProps={{
        invisible: unreadUpdates.length === 0,
        variant: 'dot',
      }}
      active={active}
      onClick={handleClick}
    />
  );
};

export default PollsControl;
