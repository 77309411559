import { selectActiveChatTab } from 'features/chat/chatSlice';
import { readPrivateChatSaga } from 'features/chat/sagas/readPrivateChatSaga';
import { readPublicChatSaga } from 'features/chat/sagas/readPublicChatSaga';
import { ChatTab } from 'features/chat/types';
import { toolbarPanelOpened } from 'features/toolbar/toolbarSlice';
import { call, select } from 'redux-saga/effects';

export function* onChatToolbarPanelOpenedSaga(action: ReturnType<typeof toolbarPanelOpened>) {
  if (action.payload !== 'chat') {
    return;
  }

  const activeTab: ChatTab = yield select(selectActiveChatTab);

  if (activeTab === ChatTab.public) {
    yield call(readPublicChatSaga);
  } else {
    yield call(readPrivateChatSaga);
  }
}
