import { Box, Collapse, Fade, IconButton, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import StandaloneBadge from 'components/StandaloneBadge';
import Icon from 'components/Icon';
import { styled, useTheme } from '@mui/material/styles';
import { styleConstants } from 'theme/styleConstants';
import { useAppSelector } from 'store/hooks';
import { selectActionNotifications } from 'features/notifications/notificationsSlice';
import Notification, { NotificationProps } from 'features/notifications/components/Notification';
import { TransitionGroup } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import CloseButton from 'components/CloseButton';
import { dispatch } from 'store/store';
import { openModal } from 'features/modal/modalSlice';
import { selectUserById } from 'features/users/usersSlice';

const {
  notifications: {
    notificationPanelHeight,
    notificationPanelHeaderHeight,
    actionNotificationWidth,
  },
  layout: { innerPadding, containerPadding },
} = styleConstants;

const DropdownIcon = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'opened',
})<{ opened: boolean }>(({ theme, opened }) => ({
  fontSize: 32,
  padding: 0,
  marginRight: '4px',
  transition: theme.transitions.create('transform'),
  transform: opened ? 'rotate(180deg)' : 'rotate(0deg)',
}));

const rootPadding = innerPadding + containerPadding;

const Root = styled(Box)(({ theme }) => ({
  position: 'absolute',
  padding: rootPadding,
  left: 0,
  top: 0,
  zIndex: theme.zIndex.speedDial,
  width: '100%',
  [theme.breakpoints.up(420)]: {
    maxWidth: `calc(${actionNotificationWidth}px + ${rootPadding * 2}px)`,
  },
}));

const Inner = styled(Paper)(({ theme }) => ({
  boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.3)',
  borderRadius: '8px',
  overflow: 'hidden',
  width: '100%',
  [theme.breakpoints.up(420)]: {
    maxWidth: actionNotificationWidth,
  },
}));

const List = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 2),
  borderTop: `1px solid ${theme.palette.divider}`,
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.grey[200],
  height: '100%',
  overflowY: 'auto',
  maxHeight: `min(50vh, ${notificationPanelHeight}px)`,
  borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px',
  '@media (max-height: 500px)': {
    maxHeight: '40vh',
  },
}));

const Body = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '8px 8px 8px 20px',
  fontSize: 16,
  height: notificationPanelHeaderHeight,
});

const triggerId = 'action-notification-panel-trigger';
const listId = 'action-notification-panel-list';

const NotificationWrapper = ({ initiatorId = '', ...props }: NotificationProps) => {
  const initiator = useAppSelector((state) => selectUserById(state, initiatorId));

  return <Notification {...props} user={initiator} />;
};

const NotificationPanel = React.forwardRef<HTMLDivElement>((_, ref) => {
  const theme = useTheme();
  const { t } = useTranslation('room');
  const [open, setOpen] = useState(false);

  const notifications = useAppSelector(selectActionNotifications);

  const triggerPanel = () => {
    setOpen(!open);
  };

  const closeAll = () => {
    dispatch(openModal('clearActionNotificationsConfirmation'));
  };

  return (
    <Root ref={ref}>
      <Inner elevation={theme.palette.mode === 'dark' ? 6 : 0}>
        <Body>
          <Typography
            sx={{
              fontSize: 18,
            }}
          >
            {t('notifications_panel.label')}
          </Typography>
          <StandaloneBadge
            data-testid="notifications-number"
            badgeContent={notifications.length}
            sx={{
              ml: 2,
              bgcolor: 'primary.light',
            }}
          />
          <Box
            sx={{
              ml: 'auto',
            }}
          >
            <DropdownIcon
              id={triggerId}
              opened={open}
              onClick={triggerPanel}
              aria-label={t('notifications_panel.trigger_aria_label')}
              aria-controls={listId}
              aria-expanded={open ? true : undefined}
            >
              <Icon name="dropdown-arrow" />
            </DropdownIcon>
            <CloseButton onClick={closeAll} />
          </Box>
        </Body>
        <Collapse in={open} unmountOnExit>
          <List id={listId} aria-labelledby={triggerId} data-testid="action-notification-panel">
            <TransitionGroup component={null}>
              {notifications.map((notification) => (
                <Fade key={notification.id} timeout={200}>
                  <Box
                    sx={{
                      py: 1,
                    }}
                    data-testid="action-notification"
                  >
                    <NotificationWrapper {...notification} />
                  </Box>
                </Fade>
              ))}
            </TransitionGroup>
          </List>
        </Collapse>
      </Inner>
    </Root>
  );
});

export default NotificationPanel;
