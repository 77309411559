export { default as ApiError } from './ApiError';
export * from './getAPIDomain';
export * from './http';
export * from './lookupApiErrorMessage';

export enum APIErrorScope {
  server = 'server',
  validation = 'validation',
  authentication = 'authentication',
  notFound = 'not-found',
}

export enum APIErrorType {
  token_invalid = 'token_invalid',
  token_required = 'token_required',
  role_invalid = 'role_invalid',
  free_usage = 'free_usage',
  max_sessions_limit_exceeded = 'max_sessions_limit_exceeded',
  max_participants_limit_exceeded = 'max_participants_limit_exceeded',
  expired = 'expired',
}

export interface ErrorResponse<T = any> {
  message: string;
  error: APIErrorScope;
  error_type?: APIErrorType;
  error_details?: T;
}

export type APIErrorMessage = {
  title?: string;
  description: string;
};

export const apiErrorMessages: Record<keyof typeof APIErrorType, APIErrorMessage> = {
  [APIErrorType.free_usage]: {
    title: 'You cannot start this session',
    description: 'Your account has run out of free minutes. Please contact your administrator.',
  },
  [APIErrorType.role_invalid]: {
    description: 'Role is invalid. Contact your administrator.',
  },
  [APIErrorType.token_invalid]: {
    description: 'Token is invalid. Contact your administrator.',
  },
  [APIErrorType.token_required]: {
    description: 'Token is required. Contact your administrator.',
  },
  [APIErrorType.expired]: {
    description: 'Room is expired.',
  },
  [APIErrorType.max_participants_limit_exceeded]: {
    description:
      'You cannot join this session because the concurrent participants limit set for this account has been reached. Please contact your administrator.',
  },
  [APIErrorType.max_sessions_limit_exceeded]: {
    description:
      'You cannot start this session because the concurrent sessions limit set for this account has been reached. Please contact your administrator.',
  },
};
