import { setupMediaDefaultsSaga } from 'features/application/sagas/setupMediaDefaultsSaga';
import { call, fork } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { PrejoinResponse } from 'features/room/types';
import { SignalingSocket } from 'services/signaling';
import { getBrowserId } from 'utils/getBrowserId';
import { logger } from 'utils/logger';
import * as Sentry from '@sentry/react';

export function* connectSignalingSaga(action: PayloadAction<PrejoinResponse>) {
  const {
    signallingUrl,
    signallingToken,
    user,
    media,
    settings: { joinScreenEnabled },
  } = action.payload;

  logger.remote().info(`Join screen is ${joinScreenEnabled ? 'enabled' : 'disabled'}`);
  yield fork(Sentry.setTag, 'participant_id', user.id);

  const browserId: string = yield call(getBrowserId);

  yield call(SignalingSocket.connect, signallingUrl, signallingToken, browserId);
  yield call(setupMediaDefaultsSaga, media);
}
