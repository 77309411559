import { closeModal } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { lockRoomRequested } from 'features/room/actions';
import { usePermissions } from 'features/permissions/usePermissions';
import { PermissionTypes } from 'features/permissions/types';
import { useEffect } from 'react';

const LockRoomConfirmation = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const { hasPermissions } = usePermissions();

  const canControlRoomEntry = hasPermissions(PermissionTypes.controlRoomEntry);

  useEffect(() => {
    if (!canControlRoomEntry) {
      dispatch(closeModal('lockRoomConfirmation'));
    }
  }, [canControlRoomEntry, dispatch]);

  const handleOk = () => {
    dispatch(lockRoomRequested());
    dispatch(closeModal('lockRoomConfirmation'));
  };

  const handleCancel = () => {
    dispatch(closeModal('lockRoomConfirmation'));
  };

  return (
    <ConfirmationDialog
      open
      title={t('lock_room_confirmation.title')}
      description={t('lock_room_confirmation.description')}
      cancelText={t('lock_room_confirmation.btn_cancel_label')}
      okText={t('lock_room_confirmation.btn_ok_label')}
      onOk={handleOk}
      onCancel={handleCancel}
    />
  );
};

export default LockRoomConfirmation;
