import {
  BreakoutRoomWithUserEntries,
  BreakoutRoomWithUserIds,
  NormalizedRoomsState,
} from 'features/breakout-rooms/types';
import { call, put, select } from 'redux-saga/effects';
import { selectUsers } from 'features/users/usersSlice';
import { User, UserEntries } from 'features/users/types';
import { normalizeRooms } from 'features/breakout-rooms/utils/normalizeRooms';
import { roomsConfigured } from 'features/breakout-rooms/breakoutRoomsSlice';
import { mapUserEntriesToRoom } from 'features/breakout-rooms/utils/mapUserEntriesToRoom';
import { mapUnassignedUsers } from 'features/breakout-rooms/utils/mapUnassignedUsers';

// Checks if the provided array of rooms is an array of BreakoutRoomWithUserIds.
function isRoomsWithUserIds(
  rooms: Array<BreakoutRoomWithUserIds | BreakoutRoomWithUserEntries>
): rooms is BreakoutRoomWithUserIds[] {
  const roomWithUsers = rooms.find((room) => room.users.length > 0);
  if (!roomWithUsers) {
    return false;
  }

  return typeof roomWithUsers.users[0] === 'string';
}

export function* normalizeBreakoutRoomsSaga({
  rooms,
  unassignedUsers,
}: {
  rooms: Array<BreakoutRoomWithUserEntries | BreakoutRoomWithUserIds>;
  unassignedUsers?: User[];
}) {
  if (rooms.length === 0) {
    return;
  }

  const allUserEntries: UserEntries = yield select(selectUsers);

  const roomsWithUserEntries: BreakoutRoomWithUserEntries[] = isRoomsWithUserIds(rooms)
    ? yield call(mapUserEntriesToRoom, rooms, allUserEntries)
    : rooms;

  const normalizedState: NormalizedRoomsState = yield call(normalizeRooms, roomsWithUserEntries);

  const configurationPayload = {
    normalizedState,
    unassignedUsers: mapUnassignedUsers(allUserEntries, normalizedState.users),
  };

  if (unassignedUsers) {
    for (const user of unassignedUsers) {
      configurationPayload.unassignedUsers[user.id] = user;
    }
  } else {
    configurationPayload.unassignedUsers = mapUnassignedUsers(
      allUserEntries,
      normalizedState.users
    );
  }

  yield put(roomsConfigured(configurationPayload));
}
