import { PayloadAction } from '@reduxjs/toolkit';
import { selectExposedMode } from 'features/layout/features/contentDimensions/contentDimensionsSlice';
import { ExposedMode } from 'features/layout/features/contentDimensions/types';
import { selectPagination } from 'features/layout/features/pagination/paginationSlice';
import { updatePagination } from 'features/layout/pagination/updatePagination';
import { call, select } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';
import { RTCClient } from 'utils/webrtc';
import { LayoutPagination, StreamMaximizedPayload } from '../types';

export function* onStreamMaximizedSaga(action: PayloadAction<StreamMaximizedPayload>) {
  const { source } = action.payload;

  const currentMode: ExposedMode = yield select(selectExposedMode);

  const pagination: LayoutPagination = yield select(selectPagination);

  const contentType =
    source.kind === 'screenshare-local' || source.kind === 'screenshare-remote'
      ? 'screenshare'
      : 'media';

  yield call(
    eventBus.sendMessage,
    'userMaximized',
    {},
    { mode: currentMode, userId: source.userId, type: contentType }
  );

  // @TODO: does this work good?
  if (currentMode === 'maximize') {
    yield call(updatePagination, {
      numPages: pagination.numPages,
      streams: [],
      totalStreams: pagination.totalStreams,
      page: pagination.page,
    });
  }

  yield call(RTCClient.receivingFeed.updateSimulcastConfig);
}
