import { call, put, select } from 'redux-saga/effects';
import { signalingFeatureDisabled } from 'features/feature-set/actions';
import { NotificationId } from 'features/notifications/types';
import { selectNotificationsByScope } from 'features/notifications/notificationsSlice';
import { Action } from '@reduxjs/toolkit';
import { closeActionNotifications } from 'features/notifications/thunks/closeActionNotifications';
import i18n from 'i18n';
import { displaySimpleNotification } from 'features/notifications/simple-notifications/displaySimpleNotification';
import { FeatureFlag } from 'features/feature-set/types';
import { onChatFeatureDisabled } from 'features/chat/sagas/onChatFeatureDisabled';

export function* onFeatureDisabledSaga(action: ReturnType<typeof signalingFeatureDisabled>) {
  const feature = action.payload.feature as FeatureFlag;
  // cleanup related notifications
  const allFeatureNotifications: NotificationId[] = yield select(
    selectNotificationsByScope,
    feature
  );

  const cleanupNotificationsThunk: Action = yield call(
    closeActionNotifications,
    allFeatureNotifications
  );
  yield put(cleanupNotificationsThunk);

  // show a notification
  const localizedFeatureName = i18n.t(`settings:components.${feature}.label`);
  const notificationThunk: Action = yield call(displaySimpleNotification, {
    content: i18n.t('notifications:components.component_disabled', {
      component: localizedFeatureName,
    }),
  });
  yield put(notificationThunk);

  // handle chat-related logic
  if (
    [FeatureFlag.generalChat, FeatureFlag.privateChat, FeatureFlag.privateGroupChat].includes(
      feature
    )
  ) {
    yield call(onChatFeatureDisabled, feature);
  }
}
