import { PayloadAction } from '@reduxjs/toolkit';
import { ChatMessage, ChatTab, PrivateChatActiveViewState } from 'features/chat/types';
import { call, put, select } from 'redux-saga/effects';
import {
  messageSent,
  selectActiveChatTab,
  selectChatPanelOpen,
  selectPrivateChatActiveView,
} from 'features/chat/chatSlice';
import { decryptE2eeMessageSaga } from 'features/chat/sagas/decryptE2eeMessageSaga';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { eventBus } from 'utils/eventBus';

export function* onChatMessageSentSaga(action: PayloadAction<ChatMessage>) {
  let { payload } = action;

  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
  if (e2eeEnabled) {
    payload = yield call(decryptE2eeMessageSaga, action.payload);
  }

  if (payload) {
    yield put(messageSent(payload));

    const panelOpen: boolean = yield select(selectChatPanelOpen);
    const activeTab: ChatTab = yield select(selectActiveChatTab);
    const privateChatActiveView: PrivateChatActiveViewState = yield select(
      selectPrivateChatActiveView
    );

    const message = {
      message: payload,
      chatState: {
        panelOpen,
        activeTab: activeTab === 0 ? 'public' : 'private',
        privateChatActiveView,
      },
    };

    yield call(eventBus.sendMessage, 'chatMessageReceived', {}, message);
  }
}
