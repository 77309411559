import memoize from 'memoize-one';
import { UserName } from 'features/users/types';

export const getInitials = memoize((name: UserName) => {
  if (!name) {
    return undefined;
  }

  const names = name.trim().split(' ');
  let initials = names[0].charAt(0);

  if (names.length > 1) {
    initials += names[names.length - 1].charAt(0);
  }
  return initials;
});
