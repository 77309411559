import { createSelector, createSlice, PayloadAction, prepareAutoBatched } from '@reduxjs/toolkit';
import { LayoutDimensionsState } from 'features/layout/features/dimensions/types';
import { RootState } from 'store/store';

export const initialState: LayoutDimensionsState = {
  tileHeight: 0,
  tileWidth: 0,
  cols: 1,
  rows: 1,
  maxTiles: 1,
  correctionHeight: 0,
  correctionWidth: 0,
  avatarSize: 'sm',
  renderArea: {
    width: 0,
    height: 0,
  },
};

export const layoutDimensionsSlice = createSlice({
  name: 'layoutDimensions',
  initialState,
  reducers: {
    dimensionsChanged: {
      reducer(state, action: PayloadAction<LayoutDimensionsState>) {
        return action.payload;
      },
      prepare: prepareAutoBatched<LayoutDimensionsState>(),
    },
  },
});

export const { dimensionsChanged } = layoutDimensionsSlice.actions;

export const selectAvatarSize = createSelector(
  (state: RootState) => state.layout.dimensions,
  (dimensions) => dimensions.avatarSize
);

export const selectCorrectionHeight = (state: RootState) =>
  state.layout.dimensions.correctionHeight;

export const selectCorrectionWidth = (state: RootState) => state.layout.dimensions.correctionWidth;

export const selectTileDimensions = createSelector(
  (state: RootState) => state.layout.dimensions,
  (dimensions) => ({
    cols: dimensions.cols,
    rows: dimensions.rows,
    tileHeight: dimensions.tileHeight,
  })
);

export const selectRenderAreaDimensions = (state: RootState) => state.layout.dimensions.renderArea;

export const selectTileWidth = (state: RootState) => state.layout.dimensions.tileWidth;

export default layoutDimensionsSlice.reducer;
