import { PayloadAction } from '@reduxjs/toolkit';
import { streamMaximized, streamMinimized } from 'features/layout/actions';
import {
  contentWidthChanged,
  selectPinnedContentWidth,
} from 'features/layout/features/contentDimensions/contentDimensionsSlice';
import { PinControlToggledPayload } from 'features/layout/types';
import { put, select } from 'redux-saga/effects';

export function* onPinControlToggledSaga({
  payload: { source, controlActive },
}: PayloadAction<PinControlToggledPayload>) {
  if (controlActive) {
    // unpinning, go to grid;
    // on streamMinimized saga will put content on stage if necessary;
    yield put(streamMinimized());

    return;
  }

  // pinning. Need to set proper content width in case we're switching from fullscreen;
  const pinWidth: number = yield select(selectPinnedContentWidth);

  yield put(contentWidthChanged(pinWidth));
  yield put(streamMaximized({ source }));
}
