import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { useBreakoutRooms } from 'features/breakout-rooms/useBreakoutRooms';
import { clearRoomsRequested } from 'features/breakout-rooms/actions';

const ClearBreakoutRoomsConfirmation = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const { isBreakoutsAvailable } = useBreakoutRooms();

  useEffect(() => {
    if (!isBreakoutsAvailable) {
      dispatch(closeModal('clearBreakoutRooms'));
    }
  }, [isBreakoutsAvailable, dispatch]);

  const handleCancel = () => {
    dispatch(closeModal('clearBreakoutRooms'));
  };

  const handleOk = () => {
    dispatch(clearRoomsRequested());
    dispatch(closeModal('clearBreakoutRooms'));
  };

  return (
    <ConfirmationDialog
      open
      title={t('breakout_rooms.clear_breakout_rooms.title')}
      description={t('breakout_rooms.clear_breakout_rooms.description')}
      cancelText={t('breakout_rooms.clear_breakout_rooms.btn_cancel_label')}
      okText={t('breakout_rooms.clear_breakout_rooms.btn_ok_label')}
      onOk={handleOk}
      onCancel={handleCancel}
      hideCloseButton
      preventClose
    />
  );
};

export default ClearBreakoutRoomsConfirmation;
