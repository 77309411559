import { IconButton } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

export const ChipGroup = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.pxToRem(16),
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 40,
  color: theme.palette.common.white,
  backgroundColor: alpha(theme.room.lightBackground, 0.7),
  borderRadius: 40 / 2,
  whiteSpace: 'nowrap',
  transition: theme.transitions.create(['background-color', 'box-shadow']),
  outline: 0,
  textDecoration: 'none',
  border: 0,
  padding: 0,
  verticalAlign: 'middle',
}));

export const ChipLabel = styled('span')(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  whiteSpace: 'nowrap',
  textTransform: 'capitalize',
}));

export const ChipButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: theme.typography.pxToRem(28),
  padding: 0,
  borderRadius: '50%',
  WebkitTapHighlightColor: 'transparent',
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.2),
  },
}));
