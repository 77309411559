import React from 'react';
import { useAppSelector } from 'store/hooks';
import { selectCustomLogoUrl } from 'features/room/roomSlice';
import LogoWrapper from 'features/room/components/topbar/LogoWrapper';
import { ReactComponent as DSLogo } from 'assets/images/logo_mono.svg';
import { Box, BoxProps } from '@mui/material';

const Logo = ({ sx = [], ...props }: BoxProps) => {
  const customLogoUrl = useAppSelector(selectCustomLogoUrl);

  return (
    <Box
      sx={[
        {
          pl: 2,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    >
      <LogoWrapper>
        {customLogoUrl ? (
          <img src={customLogoUrl} alt="Logo" data-testid="custom-logo" />
        ) : (
          <DSLogo title="Digital Samba logo" data-testid="ds-logo" />
        )}
      </LogoWrapper>
    </Box>
  );
};

export default Logo;
