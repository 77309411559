import { call, put } from 'redux-saga/effects';
import { FeedMediaStates, RTCClient } from 'utils/webrtc';
import { interactionTriggered } from 'features/application/actions';

export function* toggleLocalVideoSaga() {
  const mediaStates: FeedMediaStates = yield call(RTCClient.publishingFeed.getMediaStates);
  if (mediaStates.video.broadcasting) {
    yield put(interactionTriggered('muteVideo'));
  } else {
    yield put(interactionTriggered('unmuteVideo'));
  }

  yield call(RTCClient.publishingFeed.toggleVideoBroadcasting);
}
