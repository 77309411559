import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeedId } from 'features/streaming/types';
import { RootState } from 'store/store';
import {
  ReceivingStats,
  ReceivingStatsState,
  SendingStatsState,
  SystemStatsState,
} from 'features/system-stats/types';
import { UserId } from 'features/users/types';
import { selectFeedIdByUser } from 'features/streaming/streamingSlice';
import { ConnectionQualityLevel } from 'utils/webrtc/ConnectionQuality';

export const initialState: SystemStatsState = {
  connectionQuality: ConnectionQualityLevel.good,
  receiving: {
    byMid: {},
    totalBandwidth: 0,
  },
  sending: {
    layers: {},
    totalPacketLoss: 0,
    totalPacketsLost: 0,
    totalPacketsSent: 0,
    totalJitter: 0,
    totalRTT: 0,
    MOS: 0,
  },
};

export const systemStatsSlice = createSlice({
  name: 'systemStats',
  initialState,
  reducers: {
    sendingStatsUpdated(state, action: PayloadAction<SendingStatsState>) {
      state.sending = action.payload;
    },
    receivingStatsUpdated(state, action: PayloadAction<ReceivingStatsState>) {
      state.receiving = action.payload;
    },
    updateConnectionQuality(state, action: PayloadAction<number>) {
      state.connectionQuality = action.payload;
    },
  },
});

export const { sendingStatsUpdated, receivingStatsUpdated, updateConnectionQuality } =
  systemStatsSlice.actions;

export const selectSendingTotals = createSelector(
  [(state: RootState) => state.systemStats.sending],
  (sendingStats) => ({
    packetLoss: sendingStats.totalPacketLoss,
    packetsLost: sendingStats.totalPacketsLost,
    packetsSent: sendingStats.totalPacketsSent,
    jitter: sendingStats.totalJitter,
    rtt: sendingStats.totalRTT,
  })
);
export const selectSendingLayers = (state: RootState) => state.systemStats.sending.layers;

export const selectReceivingStatsByUser = (
  state: RootState,
  userId: UserId,
  sourceFeedId?: FeedId
): ReceivingStats => {
  const lookupFeedId = selectFeedIdByUser(state, userId);
  const feedId = sourceFeedId || lookupFeedId;
  const result: ReceivingStats = {};

  for (const meta of Object.values(state.systemStats.receiving.byMid)) {
    if (meta.feedId === feedId) {
      if (meta.kind === 'audio') {
        result.audioBitrate = meta.bitrate;
      } else {
        result.fps = meta.fps;
        result.codec = meta.codec;
        result.videoWidth = meta.videoWidth;
        result.videoHeight = meta.videoHeight;
        result.videoBitrate = meta.bitrate;
      }
    }
  }

  return result;
};

export const selectReceivingTotalBandwidth = (state: RootState) =>
  state.systemStats.receiving.totalBandwidth;

export const selectConnectionQuality = (state: RootState) => state.systemStats.connectionQuality;

export default systemStatsSlice.reducer;
