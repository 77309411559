import { ActionNotification } from 'features/notifications/types';
import { INVITE_PARTICIPANT_SUGGESTION_ID } from 'features/notifications/constants';
import { selectNotificationById } from 'features/notifications/notificationsSlice';
import { call, put, select } from 'redux-saga/effects';
import { Action } from '@reduxjs/toolkit';
import { closeActionNotification } from 'features/notifications/thunks/closeActionNotification';

export function* onUserJoinedSaga() {
  // cleanup invite suggestion
  const inviteSuggestionNotification: ActionNotification = yield select(
    selectNotificationById,
    INVITE_PARTICIPANT_SUGGESTION_ID
  );

  if (inviteSuggestionNotification) {
    const cleanupNotificationThunk: Action = yield call(
      closeActionNotification,
      INVITE_PARTICIPANT_SUGGESTION_ID
    );
    yield put(cleanupNotificationThunk);
  }
}
