import { useLayoutEffect, useRef } from 'react';
import { useRefEffect } from '@fluentui/react-hooks';
import type { RefCallback } from '@fluentui/react-hooks';
import { observeResize } from 'utils/observeResize';
import { ToolbarOrientation } from 'features/toolbar/types';

export type OverflowItemsChangedCallback = (
  overflowIndex: number,
  items: { ele: HTMLElement; isOverflowing: boolean }[]
) => void;

export type OverflowParams = {
  onOverflowItemsChanged: OverflowItemsChangedCallback;
  pinnedIndex?: number;
  orientation?: ToolbarOrientation;
};

export type OverflowRefs = {
  menuButtonRef: RefCallback<HTMLElement>;
};

export const useOverflow = ({
  onOverflowItemsChanged,
  pinnedIndex,
  orientation = 'vertical',
}: OverflowParams): OverflowRefs => {
  const updateOverflowRef = useRef<() => void>();
  const containerWidthRef = useRef<number>();

  // Attach a resize observer to the container
  const containerRef = useRefEffect<HTMLElement>((container) => {
    const cleanupObserver = observeResize(container, (entries) => {
      const key = orientation === 'vertical' ? 'height' : 'width';
      const keyFallback = orientation === 'vertical' ? 'clientHeight' : 'clientWidth';

      containerWidthRef.current = entries ? entries[0].contentRect[key] : container[keyFallback];

      if (updateOverflowRef.current) {
        updateOverflowRef.current();
      }
    });

    return () => {
      cleanupObserver();
      containerWidthRef.current = undefined;
    };
  });

  const menuButtonRef = useRefEffect<HTMLElement>((menuButton) => {
    containerRef(menuButton.parentElement);
    return () => containerRef(null);
  });

  useLayoutEffect(() => {
    const container = containerRef.current;
    const menuButton = menuButtonRef.current;
    if (!container || !menuButton) {
      return;
    }

    // items contains the container's children, excluding the overflow menu button itself
    const items: HTMLElement[] = [];
    for (let i = 0; i < container.children.length; i++) {
      const item = container.children[i];
      if (item instanceof HTMLElement && item !== menuButton) {
        items.push(item);
      }
    }

    let prevOverflowIndex = items.length;
    const setOverflowIndex = (overflowIndex: number) => {
      if (prevOverflowIndex !== overflowIndex) {
        prevOverflowIndex = overflowIndex;
        onOverflowItemsChanged(
          overflowIndex,
          items.map((ele, index) => ({
            ele,
            isOverflowing: index >= overflowIndex && index !== pinnedIndex,
          }))
        );
      }
    };

    // Keep track of the minimum width of the container to fit each child index.
    // This cache is an integral part of the algorithm and not just a performance optimization: it allows us to
    // recalculate the overflowIndex on subsequent resizes even if some items are already inside the overflow.
    const minContainerWidth: number[] = [];
    let extraWidth = 0; // The accumulated width of items that don't move into the overflow

    updateOverflowRef.current = () => {
      const containerWidth = containerWidthRef.current;
      if (containerWidth === undefined) {
        return;
      }

      // Iterate the items in reverse order until we find one that fits within the bounds of the container
      for (let i = items.length - 1; i >= 0; i--) {
        // Calculate the min container width for this item if we haven't done so yet
        if (minContainerWidth[i] === undefined) {
          const sizeKey = orientation === 'vertical' ? 'offsetHeight' : 'offsetWidth';
          const offsetKey = orientation === 'vertical' ? 'offsetTop' : 'offsetLeft';

          const itemOffsetEnd = items[i][offsetKey] + items[i][sizeKey];

          // If the item after this one is pinned, reserve space for it
          if (i + 1 < items.length && i + 1 === pinnedIndex) {
            // Use distance between the end of the previous item and this one (rather than the
            // pinned item's offsetWidth), to account for any margin between the items.
            extraWidth = minContainerWidth[i + 1] - itemOffsetEnd;
          }

          // Reserve space for the menu button after the first item was added to the overflow
          if (i === items.length - 2) {
            extraWidth += menuButton[sizeKey];
          }

          minContainerWidth[i] = itemOffsetEnd + extraWidth;
        }

        if (containerWidth > minContainerWidth[i]) {
          setOverflowIndex(i + 1);
          return;
        }
      }

      // If we got here, nothing fits outside the overflow
      setOverflowIndex(0);
    };

    let cancelAnimationFrame: (() => void) | undefined;

    // If the container width is already known from a previous render, update the overflow with its width.
    // Do this in an animation frame to avoid forcing layout to happen early.
    if (containerWidthRef.current !== undefined) {
      if (window) {
        const animationFrameId = window.requestAnimationFrame(updateOverflowRef.current);
        cancelAnimationFrame = () => window.cancelAnimationFrame(animationFrameId);
      }
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (cancelAnimationFrame) {
        cancelAnimationFrame();
      }

      // On cleanup, need to remove all items from the overflow
      // so they don't have stale properties on the next render
      setOverflowIndex(items.length);
      updateOverflowRef.current = undefined;
    };
  }, [containerRef, menuButtonRef, onOverflowItemsChanged, orientation, pinnedIndex]);

  return { menuButtonRef };
};
