import React, { useMemo } from 'react';
import { VirtualListItem } from 'components/SectionedVirtualList';
import { Components, Virtuoso, VirtuosoProps } from 'react-virtuoso';
import { styleConstants } from 'theme/styleConstants';
import { TargetRoomVirtualListItemType } from 'features/breakout-rooms/types';
import TargetRoomListItemContent from 'features/breakout-rooms/components/target-room-selector/TargetRoomListItemContent';
import TargetRoomListItem from 'features/breakout-rooms/components/target-room-selector/TargetRoomListItem';
import {
  RoomSelectHandler,
  SelectedRoomId,
} from 'features/breakout-rooms/components/target-room-selector/TargetRoomSelector';
import { styled } from '@mui/material/styles';
import { selectTargetBreakoutRooms } from 'features/breakout-rooms/selectors/selectTargetBreakoutRooms';
import { useAppSelector } from 'store/hooks';
import VirtualListRoot from 'components/participants-list/VirtualListRoot';

const {
  participantsPanel: { listItemHeight },
} = styleConstants;

export type TargetRoomsListProps = {
  searchQuery: string;
  selectRoom: RoomSelectHandler;
  selectedId: SelectedRoomId;
  participantsLimit: number;
  excludedIds?: string[];
};

export interface TargetRoomsListContext {
  selectRoom: (id: string) => void;
  participantsLimit: number;
  selectedId: SelectedRoomId;
}

const MUIComponents: Components<TargetRoomVirtualListItemType, TargetRoomsListContext> = {
  List: VirtualListRoot,
  Item: TargetRoomListItem,
};

const renderItemContent = (
  index: number,
  node: TargetRoomVirtualListItemType,
  context: TargetRoomsListContext
) => {
  if (!node.data) {
    return null;
  }

  return <TargetRoomListItemContent room={node.data} context={context} />;
};

const StyledVirtuoso = styled((props: VirtuosoProps<VirtualListItem, TargetRoomsListContext>) => (
  <Virtuoso {...props} style={{ height: listItemHeight * 4 }} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '4px',
}));

const TargetRoomsList = React.memo(
  ({
    searchQuery,
    selectRoom,
    selectedId,
    participantsLimit,
    excludedIds,
  }: TargetRoomsListProps) => {
    const rooms = useAppSelector((state) =>
      selectTargetBreakoutRooms(state, searchQuery, excludedIds)
    );

    const context: TargetRoomsListContext = useMemo(
      () => ({
        selectRoom,
        participantsLimit,
        selectedId,
      }),
      [participantsLimit, selectRoom, selectedId]
    );

    return (
      <StyledVirtuoso
        data={rooms}
        components={MUIComponents}
        itemContent={renderItemContent}
        context={context}
        fixedItemHeight={listItemHeight}
      />
    );
  }
);

export default TargetRoomsList;
