import { Box, Button, Typography } from '@mui/material';
import { usePiPWindow } from 'components/PiP/PiPProvider';
import Tile from 'features/layout/tiles/Tile';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const PiPPlaceholder = () => {
  const { t } = useTranslation('room');
  const { closePipWindow } = usePiPWindow();

  return (
    <Box
      sx={(theme) => ({
        padding: `${theme.room.tileGap}px`,
        width: '100%',
        height: '100%',
      })}
    >
      <Tile cols={1} sx={{ height: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="body2" color="white" sx={{ mb: 4 }}>
            {t('grid.pip_placeholder')}
          </Typography>

          <Button size="medium" type="button" variant="contained" onClick={closePipWindow}>
            {t('grid.pip_placeholder_action')}
          </Button>
        </Box>
      </Tile>
    </Box>
  );
};
