import { toolbarPanelOpened } from 'features/toolbar/toolbarSlice';
import { takeEvery, takeLatest } from 'redux-saga/effects';
import { deleteChatMessage, signalingChatMessageSent } from 'features/chat/actions';
import { sendMessageSaga } from 'features/chat/sagas/sendMessageSaga';
import { deleteMessageSaga } from 'features/chat/sagas/deleteMessageSaga';
import { onChatMessageSentSaga } from 'features/chat/sagas/onChatMessageSentSaga';
import {
  activeTabChanged,
  messageSent,
  privateChatOpened,
  sendChatMessage,
} from 'features/chat/chatSlice';
import { onPrivateChatOpenedSaga } from 'features/chat/sagas/onPrivateChatOpenedSaga';
import { updateMessageCounterSaga } from './updateMessageCounterSaga';
import { onActiveTabChangedSaga } from './onActiveTabChangedSaga';
import { onChatToolbarPanelOpenedSaga } from './onChatToolbarPanelOpenedSaga';

export default function* chatWatcher() {
  yield takeLatest(sendChatMessage, sendMessageSaga);
  yield takeEvery(messageSent, updateMessageCounterSaga);
  yield takeEvery(signalingChatMessageSent, onChatMessageSentSaga);

  yield takeLatest(deleteChatMessage, deleteMessageSaga);

  yield takeEvery(privateChatOpened, onPrivateChatOpenedSaga);

  yield takeEvery(activeTabChanged, onActiveTabChangedSaga);
  yield takeEvery(toolbarPanelOpened, onChatToolbarPanelOpenedSaga);
}
