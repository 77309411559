import React from 'react';
import MobileMenuItem from 'features/toolbar/Toolbar/mobile/MobileMenuItem';
import { MobileMenuItemWrapperProps } from 'features/toolbar/types';
import { useRaiseHandControl } from 'features/toolbar/Toolbar/controls/raise-hand/useRaiseHandControl';

const RaiseHandControlMobile = ({ onClick }: MobileMenuItemWrapperProps) => {
  const { toggleControl, label, icon, active, isFeatureAvailable } = useRaiseHandControl();

  if (!isFeatureAvailable) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    onClick?.(event);
    toggleControl();
  };

  return (
    <MobileMenuItem icon={icon} onClick={handleClick} selected={active}>
      {label}
    </MobileMenuItem>
  );
};

export default RaiseHandControlMobile;
