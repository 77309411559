import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { DetailedResultVotes, isPoll, PollsState, VotedPoll } from 'features/polls/types';
import {
  signalingPollCreated,
  signalingPollDeleted,
  signalingPollEdited,
  signalingPollEnded,
  signalingPollLaunched,
  signalingPollResultsShared,
  signalingPollResultsSharingStopped,
  signalingPollsReceived,
  signalingPollVoted,
} from 'features/polls/actions';
import { normalizePolls } from 'features/polls/utils/normalizePolls';
import { toolbarPanelClosed, toolbarPanelOpened } from 'features/toolbar/toolbarSlice';
import { requestDetailedPollResults } from 'features/polls/thunks/requestDetailedPollResults';

export const initialState: PollsState = {
  panelOpen: false,
  exportProcessing: false,
  unreadUpdates: [],
  activePollId: null,
  activePollSessionId: null,
  activeView: {
    name: 'list',
    data: {},
  },
  entities: {},
  ids: [],
  localVotedPolls: {},
  detailedResults: {},
};

export const pollsSlice = createSlice({
  name: 'polls',
  initialState,
  reducers: {
    pollDetailsOpened: (state, action: PayloadAction<string>) => {
      state.activeView = {
        name: 'details',
        data: {
          id: action.payload,
        },
      };
    },
    returnedToPollsListView(state) {
      state.activeView = {
        name: 'list',
        data: {},
      };
    },
    exportPollResultsRequested: (state) => {
      state.exportProcessing = true;
    },
    exportPollResultsFulfilled: (state) => {
      state.exportProcessing = false;
    },
    exportPollResultsRejected: (state) => {
      state.exportProcessing = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signalingPollCreated, (state, action) => {
        const poll = action.payload;

        const insertIndex = state.activePollId ? 1 : 0;

        state.entities[poll.id] = poll;
        state.ids.splice(insertIndex, 0, poll.id);
      })
      .addCase(signalingPollsReceived, (state, action) => {
        const { ids, entities, activePollId, activePollSessionId, localVotedPolls } =
          normalizePolls(action.payload.polls);

        // preserve choices when they are retrieved from the server
        if (localVotedPolls) {
          state.localVotedPolls = localVotedPolls;
        }

        // avoid resetting local votes state on reconnection
        if (activePollId && state.activePollSessionId !== activePollSessionId) {
          // reset previous votes for this poll if poll was relaunched
          delete state.localVotedPolls[activePollId];

          // cleanup detailed results
          delete state.detailedResults[activePollId];
          state.activeView = {
            name: 'list',
            data: {},
          };
        }

        state.activePollId = activePollId;
        state.activePollSessionId = activePollSessionId;
        state.ids = ids;
        state.entities = entities;
      })
      .addCase(signalingPollLaunched, (state, action) => {
        const { data: poll } = action.payload;

        // preserve choices when they are retrieved from the server
        if (poll.voted) {
          state.localVotedPolls[poll.id] = Object.fromEntries(
            poll.voted.map((index) => [index, true])
          );
        }

        // avoid resetting local votes state on reconnection
        if (state.activePollSessionId !== poll.sessionId) {
          // reset previous votes for this poll if poll was relaunched
          delete state.localVotedPolls[poll.id];

          // cleanup detailed results
          delete state.detailedResults[poll.id];
          state.activeView = {
            name: 'list',
            data: {},
          };
        }

        state.activePollId = poll.id;
        state.activePollSessionId = poll.sessionId;
        state.entities[poll.id] = poll;

        // Reorder so the active poll is always on top
        state.ids = state.ids.filter((id) => id !== poll.id);
        state.ids.unshift(poll.id);

        // display the badge
        if (!state.panelOpen) {
          state.unreadUpdates.push('launched');
        }
      })
      .addCase(signalingPollEdited, (state, action) => {
        state.entities[action.payload.id] = action.payload;
      })
      .addCase(signalingPollEnded, (state, action) => {
        state.activePollId = null;
        state.activePollSessionId = null;

        const poll = state.entities[action.payload.id];
        poll.active = false;
        poll.ended = true;

        if (!state.panelOpen) {
          state.unreadUpdates = state.unreadUpdates.filter((update) => update !== 'launched');
        }
      })
      .addCase(signalingPollDeleted, (state, action) => {
        delete state.entities[action.payload.id];
        delete state.detailedResults[action.payload.id];
        state.ids = state.ids.filter((id) => id !== action.payload.id);

        if (!state.panelOpen) {
          state.unreadUpdates = [];
        }
      })
      .addCase(signalingPollVoted, (state, action) => {
        // populate local votes
        if (action.payload.local) {
          state.localVotedPolls[action.payload.id] = Object.fromEntries(
            action.payload.choices.map((index) => [index, true])
          );
        }

        const poll = state.entities[action.payload.id];
        poll.votersCount ??= 0;
        poll.votersCount += 1;
        for (const index of action.payload.choices) {
          poll.choices[index].votersCount ??= 0;
          poll.choices[index].votersCount += 1;
        }
      })
      .addCase(signalingPollResultsShared, (state, action) => {
        if (isPoll(action.payload.data)) {
          const poll = action.payload.data;
          state.entities[poll.id] = poll;

          if (!state.panelOpen) {
            state.unreadUpdates.push('shared');
          }
        } else {
          const poll = state.entities[action.payload.data.id];
          poll.shared = true;
        }
      })
      .addCase(signalingPollResultsSharingStopped, (state, action) => {
        const poll = state.entities[action.payload.id];
        poll.shared = false;

        if (!state.panelOpen) {
          state.unreadUpdates = state.unreadUpdates.filter((update) => update !== 'shared');
        }
      })
      .addCase(toolbarPanelOpened, (state, action) => {
        if (action.payload === 'polls') {
          state.panelOpen = true;
          state.unreadUpdates = [];
        } else {
          state.panelOpen = false;
        }
      })
      .addCase(toolbarPanelClosed, (state) => {
        state.panelOpen = false;
      })
      .addCase(requestDetailedPollResults.pending, (state, action) => {
        state.detailedResults[action.meta.arg] = {
          status: 'loading',
          votes: {},
        };
      })
      .addCase(requestDetailedPollResults.rejected, (state, action) => {
        state.detailedResults[action.meta.arg] = {
          status: 'error',
          votes: {},
        };
      })
      .addCase(requestDetailedPollResults.fulfilled, (state, action) => {
        const votes: DetailedResultVotes = {};

        for (const vote of action.payload.votes) {
          for (const choice of vote.choices) {
            if (!votes[choice]) {
              votes[choice] = [];
            }
            votes[choice].push(vote.voter);
          }
        }

        state.detailedResults[action.meta.arg] = {
          status: 'success',
          votes,
        };
      });
  },
});

export const {
  pollDetailsOpened,
  returnedToPollsListView,
  exportPollResultsRejected,
  exportPollResultsRequested,
  exportPollResultsFulfilled,
} = pollsSlice.actions;

export const selectActivePollView = (state: RootState) => state.polls.activeView;

export const selectPoll = (state: RootState, id: string) => state.polls.entities[id];

export const selectPollEntities = (state: RootState) => state.polls.entities;

export const selectPollIds = (state: RootState) => state.polls.ids;

export const selectPolls = createSelector([selectPollEntities, selectPollIds], (entities, ids) =>
  ids.map((id) => entities[id])
);

export const selectActivePollId = (state: RootState) => state.polls.activePollId;

export const selectIsLocalVotedPoll = (state: RootState, id: string): VotedPoll | undefined =>
  state.polls.localVotedPolls[id];

export const selectUnreadPollUpdates = (state: RootState) => state.polls.unreadUpdates;

export const selectPollDetailedResults = (state: RootState, id: string) =>
  state.polls.detailedResults[id];

export default pollsSlice.reducer;
