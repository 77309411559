import { useAppSelector } from 'store/hooks';
import {
  selectRemoteBroadcastingState,
  selectRemoteStreams,
} from 'features/streaming/streamingSlice';
import StreamMedia from 'features/streaming/components/stream-display/StreamMedia';
import { StreamingMediaProps } from 'features/streaming/types';
import RemoteVideoWrapper from 'features/streaming/components/media/remote-stream/RemoteVideoWrapper';

const RemoteMedia = ({ source, avatarSize }: StreamingMediaProps) => {
  const streams = useAppSelector((state) =>
    selectRemoteStreams(state, source.userId, source.feedId)
  );

  const isVideoBroadcasting = useAppSelector((state) =>
    selectRemoteBroadcastingState(state, source.userId, 'video')
  );

  return (
    <StreamMedia source={source} avatarSize={avatarSize}>
      {streams?.map((stream) =>
        stream.kind === 'video' && isVideoBroadcasting ? (
          <RemoteVideoWrapper
            key={stream.mid}
            mid={stream.mid}
            userId={source.userId}
            mode="media"
          />
        ) : null
      )}
    </StreamMedia>
  );
};

export default RemoteMedia;
