export const styleConstants = {
  narrowContainerWidth: 376,
  notifications: {
    actionNotificationWidth: 340,
    notificationPanelHeaderHeight: 40,
    notificationPanelHeight: 460,
  },
  layout: {
    containerPadding: 4,
    innerPadding: 8,
    pagination: {
      controlWidth: 28,
    },
  },
  featureBadge: {
    width: 56,
    height: 32,
  },
  recordingIndicator: {
    offset: 12,
  },
  toolbar: {
    width: 88,
    innerPadding: 10,
    badge: {
      active: {
        background: '#fff',
        color: '#000',
      },
    },
    item: {
      padding: 14,
    },
  },
  toolbarPanel: {
    width: 368,
  },
  topBar: {
    height: 48,
  },
  participantsPanel: {
    listItemHeight: 56,
  },
};
