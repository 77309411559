import { usePiPWindow } from 'components/PiP/PiPProvider';
import { leaveBreakoutRoomRequested } from 'features/breakout-rooms/actions';
import { leaveSession } from 'features/room/actions';
import { openModal } from 'features/modal/modalSlice';
import { selectIsBreakoutRoom } from 'features/breakout-rooms/breakoutRoomsSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { usePermissions } from 'features/permissions/usePermissions';
import { PermissionTypes } from 'features/permissions/types';
import { SDKEventListenerManager } from 'utils/sdk/eventLiseners';

export const useEndSessionControl = () => {
  const dispatch = useAppDispatch();

  const { pipWindow, closePipWindow } = usePiPWindow();

  const { hasPermissions } = usePermissions();
  const isFeatureEnabled = useFeatureFlag('endSession');

  const canEndSession = hasPermissions(PermissionTypes.endSession);
  const canManageBreakout = hasPermissions(PermissionTypes.manageBreakout);
  const inBreakout: boolean = useAppSelector(selectIsBreakoutRoom);

  const leaveBreakoutAvailable = canManageBreakout && inBreakout;
  const endSessionAvailable = !inBreakout && isFeatureEnabled && canEndSession;

  const handleLeaveSession = SDKEventListenerManager.UICallbackAction('leaveSession', () => {
    dispatch(leaveSession());
  });

  const handleEndSession = () => {
    if (pipWindow) {
      closePipWindow();
    }

    dispatch(openModal('endSessionConfirm'));
  };

  const handleLeaveBreakout = () => {
    dispatch(leaveBreakoutRoomRequested());
  };

  return {
    leaveSession: handleLeaveSession,
    leaveBreakout: handleLeaveBreakout,
    endSession: handleEndSession,
    icon: 'end-call',
    endSessionAvailable,
    leaveBreakoutAvailable,
  };
};
