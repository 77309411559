import { selectMaximizedStream } from 'features/layout/features/content/contentSlice';
import { PaginationUpdatePayload } from 'features/layout/features/pagination/types';
import { SourceDetails } from 'features/layout/types';
import { FeedId, SubscribeStreamList } from 'features/streaming/types';
import { sourceMatches } from 'features/streaming/utils';
import invert from 'lodash.invert';
import { store } from 'store/store';
import { canExposeInternals } from 'utils/development';
import { logger } from 'utils/logger';
import { ReceivingFeedStreamSendingState, RTCClient, ToggleMediaTemplate } from 'utils/webrtc';
import { batchToggleMediaTemplate, sendMessage } from 'utils/webrtc/messages';

export const updatePagination = async (pageConfig: PaginationUpdatePayload) => {
  const pageStreams = [...pageConfig.streams];

  const feedIdByUserId = invert(RTCClient.userIdByFeedId);

  const streamsToSubscribe: SubscribeStreamList = [];
  const streamsToConfigure: ToggleMediaTemplate = [];

  const maximizedStream = selectMaximizedStream(store.getState());

  const maximizedFeedId = maximizedStream
    ? maximizedStream.feedId || feedIdByUserId[maximizedStream.userId]
    : null;

  if (maximizedStream && !pageStreams.find((stream) => sourceMatches(maximizedStream, stream))) {
    pageStreams.push(maximizedStream);
  }

  const localFeedId = RTCClient.publishingFeed.handle?.feedId;

  const pageStreamsByFeedId: Record<FeedId, SourceDetails> = {};
  for (const rec of pageStreams) {
    const feedId = feedIdByUserId[rec.userId];
    if (rec.feedId || feedId) {
      pageStreamsByFeedId[rec.feedId || feedId] = rec;
    }
  }

  if (localFeedId) {
    delete pageStreamsByFeedId[localFeedId];
  }

  RTCClient.receivingFeed.media.forEachFeed((list, feedId) => {
    const requestedStream = pageStreamsByFeedId[feedId];

    const videoStream = list.getVideoStream();
    // we got to subscribe/enable given stream
    if (requestedStream) {
      if (videoStream) {
        // TODO: Could also be improved;
        const canConfigure = videoStream.subscriberMid;

        if (canConfigure) {
          videoStream.setReceivingState(ReceivingFeedStreamSendingState.on);

          // we have a connection but it's currently not sending anything
          streamsToConfigure.push({
            mid: videoStream.subscriberMid,
            send: true,
          });
        } else {
          // handle case when we aren't subscribed to a specific video stream at all but know it exists
          streamsToSubscribe.push({
            feed: feedId,
            mid: videoStream.publisherMid,
          });
        }
      }
    }
    // stream wasn't requested, see if we should disable it
    else {
      // don’t do anything to staged content; !!!
      if (feedId === maximizedFeedId) {
        delete pageStreamsByFeedId[feedId];
        return;
      }

      // if feed has video we don't want, disable receiving it;
      if (videoStream && videoStream.subscriberMid) {
        videoStream.setReceivingState(ReceivingFeedStreamSendingState.off);

        streamsToConfigure.push({
          mid: videoStream.subscriberMid,
          send: false,
        });
      }
    }

    delete pageStreamsByFeedId[feedId];
  });

  const feed = RTCClient.receivingFeed.receiver?.plugin?.janusPlugin;

  // TODO;
  if (Object.values(pageStreamsByFeedId).length) {
    logger
      .remote({ capture: 'layout' })
      .error('unprocessed streams in pagination', pageStreamsByFeedId);
  }

  if (canExposeInternals()) {
    logger.warn(
      'conf',
      streamsToConfigure,
      'sub',
      streamsToSubscribe,
      'page',
      pageStreams,
      'page unprocessed',
      pageStreamsByFeedId
    );
  }

  if (feed && RTCClient.receivingFeed.receiver?.connection) {
    if (streamsToConfigure.length) {
      await sendMessage(feed, batchToggleMediaTemplate(streamsToConfigure));
    }

    if (streamsToSubscribe.length) {
      await RTCClient.receivingFeed.requestSubscription(
        RTCClient.receivingFeed.receiver.connection.handle,
        streamsToSubscribe
      );
    }
  }
};
