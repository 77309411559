import { createSelector } from '@reduxjs/toolkit';
import { selectHandRaisedIds, selectLocalUser, selectUserEntries } from 'features/users/usersSlice';
import { selectPermissionsState } from 'features/permissions/permissionsSlice';
import { getHandRaisedUsers } from 'features/users/utils';

export const selectHandRaisedUsers = createSelector(
  [selectPermissionsState, selectUserEntries, selectHandRaisedIds, selectLocalUser],
  (permissionsState, userEntries, handRaisedIds, localUser) =>
    getHandRaisedUsers({ handRaisedIds, userEntries, permissionsState, localUser })
);
