import { PermissionTypes } from 'features/permissions/types';
import { usePermissions } from 'features/permissions/usePermissions';
import CameraControl from 'features/toolbar/Toolbar/controls/CameraControl';
import MicControl from 'features/toolbar/Toolbar/controls/MicControl';
import RaiseHandControl from 'features/toolbar/Toolbar/controls/raise-hand/RaiseHandControl';
import { useRaiseHandControl } from 'features/toolbar/Toolbar/controls/raise-hand/useRaiseHandControl';
import { MenuFixedItems, Root, ToolbarBg, ToolbarNavRoot } from 'features/toolbar/Toolbar/styles';
import React, { Fragment } from 'react';
import EndCallControl from '../Toolbar/controls/end-call/EndCallControl';

export const PipToolbar = () => {
  const { hasPermissions } = usePermissions();
  const allowBroadcast = hasPermissions(PermissionTypes.broadcast);
  const { isFeatureAvailable: raiseHandAvailable } = useRaiseHandControl();

  const primaryMenuItems = [];

  if (allowBroadcast) {
    primaryMenuItems.push(
      <Fragment key="broadcast-controls">
        <CameraControl />
        <MicControl />
      </Fragment>
    );
  }

  if (raiseHandAvailable) {
    primaryMenuItems.push(<RaiseHandControl key="raise-hand-control" />);
  }

  primaryMenuItems.push(<EndCallControl key="end-call-control" />);

  return (
    <Root placement="bottom" orientation="horizontal">
      <ToolbarBg />
      <ToolbarNavRoot orientation="horizontal" style={{ position: 'relative', zIndex: 2 }}>
        <MenuFixedItems orientation="horizontal" style={{ justifyContent: 'center' }}>
          {primaryMenuItems}
        </MenuFixedItems>
      </ToolbarNavRoot>
    </Root>
  );
};
