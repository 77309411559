import { customControlTriggered } from 'features/layout/actions';
import { call } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';

export function* onCustomControlTriggeredSaga(action: ReturnType<typeof customControlTriggered>) {
  yield call(eventBus.sendMessage, 'tileAction', undefined, {
    name: action.payload.action,
    source: action.payload.source,
  });
}
