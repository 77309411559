import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal, selectActiveModalData } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from 'components/Dialog';
import Button from '@mui/material/Button';
import { UserId } from 'features/users/types';
import { useCallback, useEffect, useState } from 'react';
import { Box, MenuItem, SelectChangeEvent } from '@mui/material';
import Select from 'components/Select';
import { selectActiveUserById } from 'features/users/usersSlice';
import { changeRoleRequest } from 'features/permissions/actions';
import { selectRolesToManage } from 'features/permissions/permissionsSlice';
import { PermissionTypes } from 'features/permissions/types';
import { usePermissions } from 'features/permissions/usePermissions';

const ManageRole = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const modalData = useAppSelector(selectActiveModalData);
  const userId = modalData.userId as UserId;

  const rolesToManage = useAppSelector(selectRolesToManage);

  const user = useAppSelector((state) => selectActiveUserById(state, userId));

  const { hasPermissions } = usePermissions();
  const canManageRole = hasPermissions(PermissionTypes.manageRoles, {
    targetRole: user?.role,
  });

  const [selectedRole, setSelectedRole] = useState(user?.role);

  const handleClose = useCallback(() => {
    dispatch(closeModal('manageRole'));
  }, [dispatch]);

  useEffect(() => {
    if (!user || !canManageRole) {
      handleClose();
    }
  }, [dispatch, handleClose, user, canManageRole]);

  const handleInputChange = (event: SelectChangeEvent) => {
    setSelectedRole(event.target.value);
  };

  if (!user) {
    return null;
  }

  const handleOk = () => {
    if (selectedRole !== user.role) {
      dispatch(
        changeRoleRequest({
          role: selectedRole!,
          id: userId,
        })
      );
    }
    handleClose();
  };

  return (
    <Dialog
      open
      maxWidth="xs"
      aria-labelledby="manage-role-title"
      aria-describedby="manage-role-description"
    >
      <DialogTitle id="manage-role-title">{t('manage_role.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="manage-role-description">
          {t('manage_role.description', { username: user.name })}
        </DialogContentText>
        <Box
          sx={{
            mt: 6,
          }}
        >
          {rolesToManage ? (
            <Select
              onChange={handleInputChange}
              value={selectedRole}
              sx={{
                width: '100%',
              }}
            >
              {rolesToManage.map((role) => (
                <MenuItem key={role.value} value={role.value}>
                  {role.displayName}
                </MenuItem>
              ))}
            </Select>
          ) : null}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="grayscale">
          {t('manage_role.btn_cancel_label')}
        </Button>
        <Button onClick={handleOk}>{t('manage_role.btn_ok_label')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManageRole;
