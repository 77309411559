import { DialogProps } from '@mui/material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'components/Dialog';
import Button from '@mui/material/Button';
import React, { useId } from 'react';

export type ConfirmationDialogProps = DialogProps & {
  title: string;
  description: string | React.ReactElement;
  cancelText?: string;
  okText?: string;
  onCancel: () => void;
  hideCloseButton?: boolean;
  preventClose?: boolean;
  onOk?: () => void;
  okButton?: React.ReactNode;
  cancelButton?: React.ReactNode;
  children?: React.ReactNode;
};

const ConfirmationDialog = ({
  title,
  description,
  okText,
  cancelText,
  onCancel,
  onOk,
  preventClose,
  hideCloseButton,
  okButton,
  cancelButton,
  children,
  ...props
}: ConfirmationDialogProps) => {
  const prefix = useId();

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby={`${prefix}-title`}
      aria-describedby={`${prefix}-description`}
      onClose={preventClose ? undefined : onCancel}
      {...props}
    >
      <DialogTitle id={`${prefix}-title`} onClose={hideCloseButton ? undefined : onCancel}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id={`${prefix}-description`}>{description}</DialogContentText>
        {children && children}
      </DialogContent>
      <DialogActions>
        {cancelButton || (
          <Button onClick={onCancel} color="grayscale">
            {cancelText}
          </Button>
        )}
        {okButton || <Button onClick={onOk}>{okText}</Button>}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
