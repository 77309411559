import { selectBreakoutRoomsUsers } from 'features/breakout-rooms/breakoutRoomsSlice';
import { dimensionsChanged } from 'features/layout/features/dimensions/dimensionsSlice';
import { selectPaginationTotalStreams } from 'features/layout/features/pagination/paginationSlice';
import { fitToRenderArea } from 'features/layout/fitToRenderArea';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectDeviceType } from 'features/application/applicationSlice';
import { RoomLayoutValuesContext } from './Context';

// Distance traveled to trigger an update;
const sensitivityWidth = 30;
const sensitivityHeight = 30;

const frameLength = 1000 / 60;

export const useDimensionsObserver = () => {
  const dispatch = useAppDispatch();

  const memory = useRef({
    tilesWidth: 0,
    tilesHeight: 0,
    totalStreams: 0,
    deviceType: 'desktop',
    animationId: 0,
    adjustmentCalculationTimeout: 0,
    lastTimestamp: 0,
  });

  const deviceType = useAppSelector(selectDeviceType);
  const totalStreams = useAppSelector(selectPaginationTotalStreams);
  const breakoutUsers = useAppSelector(selectBreakoutRoomsUsers);

  const { tilesWidth, tilesHeight } = useContext(RoomLayoutValuesContext);

  const recalculateDimensions = useCallback(() => {
    if (memory.current.animationId) {
      cancelAnimationFrame(memory.current.animationId);
    }

    memory.current.animationId = requestAnimationFrame((timestamp) => {
      if (timestamp - memory.current.lastTimestamp < frameLength) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { tilesWidth, tilesHeight, totalStreams, deviceType } = memory.current;

      const dimensions = fitToRenderArea(tilesWidth, tilesHeight, totalStreams, deviceType);

      if (dimensions.tileHeight) {
        dispatch(dimensionsChanged(dimensions));
      }

      memory.current.lastTimestamp = timestamp;
    });
  }, [dispatch]);

  // keep device type up to date;
  useEffect(() => {
    memory.current.deviceType = deviceType;
  }, [deviceType]);

  // always recalculate when total number of tiles changes;
  useEffect(() => {
    memory.current.totalStreams = totalStreams;

    recalculateDimensions();
  }, [totalStreams, breakoutUsers, recalculateDimensions]);

  // recalculate when size changes only if it's a noticeable change;
  useEffect(() => {
    if (!(tilesWidth && tilesHeight)) {
      return;
    }

    clearTimeout(memory.current.adjustmentCalculationTimeout);

    const widthDelta = Math.abs(memory.current.tilesWidth - tilesWidth);
    const heightDelta = Math.abs(memory.current.tilesHeight - tilesHeight);

    if (widthDelta >= sensitivityWidth || heightDelta >= sensitivityHeight) {
      memory.current.tilesWidth = tilesWidth;
      memory.current.tilesHeight = tilesHeight;

      recalculateDimensions();
    }

    memory.current.adjustmentCalculationTimeout = window.setTimeout(() => {
      memory.current.tilesWidth = tilesWidth;
      memory.current.tilesHeight = tilesHeight;

      recalculateDimensions();
    }, frameLength);
  }, [tilesWidth, tilesHeight, recalculateDimensions]);
};
