import { signalingEntryAccessAwaitingStarted } from 'features/room/actions';
import { fork, select } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { logger } from 'utils/logger';
import { exchangeEncryptedNameSaga } from 'features/e2ee/sagas/exchangeEncryptedNameSaga';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';

export function* onEntryAccessAwaitingStarted(
  action: ReturnType<typeof signalingEntryAccessAwaitingStarted>
) {
  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
  if (!e2eeEnabled) {
    return;
  }

  const { entryManagers } = action.payload;

  for (const manager of entryManagers) {
    try {
      yield fork(exchangeEncryptedNameSaga, manager);
    } catch (error) {
      Sentry.captureException(error);
      logger
        .remote({ system: true, capture: 'e2ee' })
        .error(`Name exchange failed with the userId=${manager.id}`, error);
    }
  }
}
