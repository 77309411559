import { call, select, take } from 'redux-saga/effects';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { selectIsRatcheting, senderKeyRatchetFinished } from 'features/e2ee/e2eeSlice';
import { logger } from 'utils/logger';

export function* refreshApplicationKey() {
  logger
    .remote({ system: true, capture: 'e2ee' })
    .debug('Refreshing the application encryption key...');

  const isRatcheting: boolean = yield select(selectIsRatcheting, 'application');
  if (isRatcheting) {
    logger
      .remote({ system: true, capture: 'e2ee' })
      .debug('Application key ratcheting is in-progress. Waiting for it to finish...');

    yield take(senderKeyRatchetFinished('application').type);
  }

  yield call(E2EEManager.generateApplicationKey);
}
