import { put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { PermissionChangePayload, UserId } from 'features/users/types';
import { selectLocalUserId } from 'features/users/usersSlice';
import { logger } from 'utils/logger';
import { broadcastPermissionGranted } from 'features/permissions/actions';

export function* onBroadcastAllowedSaga({ payload }: PayloadAction<PermissionChangePayload>) {
  const { initiator, id } = payload;

  const localUserId: UserId = yield select(selectLocalUserId);

  if (localUserId === id && initiator) {
    logger.remote({ tier: 1 }).info(`The user was allowed to broadcast by user id=${initiator.id}`);
  }

  yield put(broadcastPermissionGranted(payload));
}
