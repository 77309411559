import { createAction } from '@reduxjs/toolkit';
import {
  EncryptedNameSharedPayload,
  EncryptionKeySharedPayload,
  PublicKeySharedPayload,
  UserNameDecrypted,
} from 'features/e2ee/types';
import { UserId } from 'features/users/types';

export const signalingEncryptionKeyShared = createAction<EncryptionKeySharedPayload>(
  'signaling/encryptionKeyShared'
);

export const signalingPublicKeyShared = createAction<PublicKeySharedPayload>(
  'signaling/publicKeyShared'
);

export const signalingEncryptedNameShared = createAction<EncryptedNameSharedPayload>(
  'signaling/encryptedNameShared'
);

export const sframeInitialized = createAction('e2ee/sframeInitialized');

export const applicationKeyGenerated = createAction('e2ee/applicationKeyGenerated');

export const remoteStreamSecretDecrypted = createAction<UserId>('e2ee/remoteStreamSecretDecrypted');
export const userNameDecrypted = createAction<UserNameDecrypted>('e2ee/userNameDecrypted');
