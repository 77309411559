import {
  selectActiveVirtualBackground,
  selectVirtualBackgroundStatus,
} from 'features/virtual-backgrounds/selectors';
import React, { useEffect, useState } from 'react';
import VideoBackgroundTab from 'features/room/settings/tabs/VideoBackgroundTab';
import { useBroadcastSetup } from 'utils/broadcast-setup';
import { SelectedVirtualBackground } from 'utils/webrtc/VirtualBackground';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { initVirtualBackground } from 'features/virtual-backgrounds/thunks/initVirtualBackground';

const VirtualBackground = () => {
  const appDispatch = useAppDispatch();

  const { videoStream, changeVB, resetVB } = useBroadcastSetup();

  const selectedBackground = useAppSelector(selectActiveVirtualBackground);
  const status = useAppSelector(selectVirtualBackgroundStatus);

  const [selectedBg, setSelectedBg] = useState(selectedBackground.value);

  useEffect(() => {
    appDispatch(initVirtualBackground());
  }, [appDispatch]);

  const handleReset = async () => {
    if (!selectedBg) {
      return;
    }

    setSelectedBg('');

    await resetVB();
  };

  const handleChange = async (config: SelectedVirtualBackground) => {
    if (selectedBg === config.value) {
      return;
    }

    setSelectedBg(config.value);

    await changeVB(config);
  };

  return (
    <VideoBackgroundTab
      loading={status === 'initializing'}
      error={status === 'error'}
      videoStream={videoStream}
      onChange={handleChange}
      onReset={handleReset}
      selected={selectedBackground.value}
    />
  );
};

export default VirtualBackground;
