import { JanusJS } from 'lib/janus';
import {
  PublishTemplateOptions,
  ConfigureSimulcastTemplate,
  ToggleMediaTemplate,
} from 'utils/webrtc/types';
import {
  FeedId,
  PublisherMid,
  SubscribeStreamList,
  UnsubscribeStreamList,
} from 'features/streaming/types';

export const sendMessage: (
  target: JanusJS.PluginHandle,
  messageTemplate: any,
  jsep?: JanusJS.JSEP
) => Promise<{ data: any; request: { transaction?: string; body: any } }> = <G>(
  target: JanusJS.PluginHandle,
  messageTemplate: any,
  jsep?: JanusJS.JSEP
) =>
  new Promise<G>((resolve, reject) => {
    const pluginMessage: JanusJS.PluginMessage = {
      message: messageTemplate,
      success: (result: G) => {
        resolve(result);
      },
      error: (e) => {
        reject(e);
      },
    };

    if (jsep) {
      pluginMessage.jsep = jsep;
    }

    target.send(pluginMessage);
  });

// @TODO potentially move this to singleton
export const subscribeStreams: (feedId: FeedId, mids?: PublisherMid[]) => SubscribeStreamList = (
  feedId,
  mids
) => {
  // subscribe to all feeds;
  if (!mids?.length) {
    return [{ feed: feedId }];
  }

  return mids.map((mid) => ({ feed: feedId, mid }));
};

export const joinAsPublisherTemplate = (roomId: string, roomPin: string) => ({
  request: 'join',
  ptype: 'publisher',
  room: roomId,
  pin: roomPin,
});

export const joinAsSubscriberTemplate = (
  roomId: string,
  roomPin: string,
  streams: SubscribeStreamList,
  privateId?: number,
  options?: { temporal: number; substream: number }
) => {
  if (options) {
    streams = streams.map((row) => ({ ...row, ...options }));
  }
  return {
    request: 'join',
    ptype: 'subscriber',
    pin: roomPin,
    room: roomId,
    streams,
    private_id: privateId,
  };
};

export const subscribeTemplate = (
  streams: SubscribeStreamList,
  options?: { temporal: number; substream: number }
) => {
  if (options) {
    streams = streams.map((row) => ({ ...row, ...options }));
  }

  return {
    request: 'subscribe',
    streams,
  };
};

export const unsubscribeTemplate = (streams: UnsubscribeStreamList) => ({
  request: 'unsubscribe',
  streams,
});

export const startSubscriptionTemplate = (roomId: string) => ({
  request: 'start',
  room: roomId,
});

export const watchRoomTemplate = (mountpointId: string, roomPin: string) => ({
  request: 'watch',
  id: mountpointId,
  pin: roomPin,
  // watch all available streams
  media: [],
});

export const publishTemplate = (options: PublishTemplateOptions) => ({
  request: 'configure',
  // TODO;
  bitrateCap: 1500000,
  ...options,
});

export const updateSimulcastTemplate = (streams: ConfigureSimulcastTemplate) => ({
  request: 'configure',
  streams,
});

export const batchToggleMediaTemplate = (streams: ToggleMediaTemplate) => ({
  request: 'configure',
  streams,
});

export const iceRestartTemplate = () => ({
  request: 'configure',
  restart: true,
});
