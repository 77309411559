import { PayloadAction } from '@reduxjs/toolkit';
import { RedirectPayload } from 'features/application/types';

// @TODO pay attention to missing yield
// eslint-disable-next-line require-yield
export function* redirectSaga(action: PayloadAction<RedirectPayload>) {
  setTimeout(() => {
    window.location.href = action.payload.destination || window.location.href;
  }, action.payload.timeout || 0);
}
