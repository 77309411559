import * as Sentry from '@sentry/react';
import { facingModeChanged, mediaDevicesUpdated } from 'features/user-media/userMediaSlice';
import {
  enumerateDevices,
  getDevicePermissions,
  stopStreamTracks,
} from 'features/user-media/utils';
import { RefObject } from 'react';
import { store } from 'store/store';
import { logger } from 'utils/logger';
import { BasePublishing } from 'utils/webrtc/publishing';
import { ScreensharingFeed } from 'utils/webrtc/publishing/ScreensharingFeed';
import { LocalFeedStream, StreamKind } from 'utils/webrtc/types';
import { isDomException } from 'utils/types';
import { getUserMedia } from 'features/user-media/utils/getUserMedia';
import webrtcAdapter from 'webrtc-adapter';
import { EnumeratedMediaDevices } from 'features/user-media/types';

export class MediaController {
  streams: Record<string, LocalFeedStream> = {};

  storedMediaStreams: Record<string, MediaStream> = {};

  tracks: MediaStreamTrack[] = [];

  feed: BasePublishing;

  constructor(feed: BasePublishing) {
    this.feed = feed;
  }

  addStream = (track: MediaStreamTrack) => {
    const { id } = track;

    if (this.streams[id]) {
      return;
    }

    const message = 'Local track added';
    Sentry.addBreadcrumb({
      category: 'streaming',
      message,
      data: track,
    });

    logger.remote().info(message, track);

    const clonedTrack = track.clone();
    const mediaStream = new MediaStream();
    mediaStream.addTrack(clonedTrack);

    if (this.feed instanceof ScreensharingFeed) {
      clonedTrack.onended = () => {
        (this.feed as ScreensharingFeed).stopScreenshare();
      };
    }

    this.streams[id] = {
      mediaStream,
      id,
      attached: false,
      kind: track.kind as LocalFeedStream['kind'],
    };

    this.tracks.push(track);
  };

  stopStreamTracks = (stream: MediaStream) => {
    const tracks = stream.getTracks();

    tracks.forEach((track) => {
      track.stop();
    });
  };

  removeStream = (id: string) => {
    const stream = this.streams[id];

    if (stream) {
      this.stopStreamTracks(stream.mediaStream);
    }

    this.tracks = this.tracks.filter((track) => track.id !== id);

    delete this.streams[id];
  };

  handleRemoveTrack = async (track: MediaStreamTrack) => {
    const message = 'Local track removed';
    Sentry.addBreadcrumb({
      category: 'streaming',
      message,
      data: track,
    });

    logger.remote().info(message, track);

    const updatedDevices = await enumerateDevices();

    const kind = track.kind as StreamKind;
    const mediaDeviceKind = `${kind}input` as const;

    const permissions = getDevicePermissions(updatedDevices, mediaDeviceKind);

    // block streaming if track removed because of permissions change
    if (permissions !== 'granted') {
      const blockDevices = async (devices: EnumeratedMediaDevices = updatedDevices) => {
        logger
          .remote({ capture: 'streaming' })
          .log('Permissions have been changed and not granted anymore, stopping stream', kind);

        store.dispatch(
          mediaDevicesUpdated(devices, {
            updateActiveDevices: true,
            updatePermissions: true,
          })
        );

        if (kind === 'audio') {
          await this.feed.blockAudioPublishing();
        } else {
          await this.feed.blockVideoPublishing();
        }
      };

      if (webrtcAdapter.browserDetails.browser === 'firefox') {
        try {
          const stream = await this.requestExternalStream({
            video: kind === 'video',
            audio: kind === 'audio',
          });
          const checkupDevices = await enumerateDevices();

          const finalPermissions = getDevicePermissions(checkupDevices, mediaDeviceKind);

          stopStreamTracks(stream);

          if (finalPermissions !== 'granted') {
            await blockDevices(checkupDevices);
          } else {
            store.dispatch(
              mediaDevicesUpdated(checkupDevices, {
                updateActiveDevices: true,
                updatePermissions: true,
              })
            );
          }
        } catch {
          await blockDevices();
        }
      } else {
        await blockDevices();
      }
    }

    this.removeStream(track.id);

    this.feed.updateRedux();
  };

  attachToElement = async (id: string, ref: RefObject<HTMLVideoElement>) => {
    if (!ref.current) {
      return;
    }

    try {
      const stream = this.streams[id];

      if (stream.kind === 'video') {
        const track = stream.mediaStream.getVideoTracks()[0];

        if (track && ref.current && this.feed.kind === 'publishing') {
          const settings = track.getSettings();

          if (settings.facingMode) {
            store.dispatch(facingModeChanged(settings.facingMode));
          }
        }
      }

      ref.current.srcObject = stream.mediaStream;
      await ref.current.play();
    } catch (error) {
      if (!isDomException(error)) {
        Sentry.captureException(error);
        logger.remote().error('An error occurred while attaching a media element', error);
      }
    }
  };

  requestExternalStream = async (constraints?: MediaStreamConstraints) => {
    if (constraints) {
      return getUserMedia(constraints);
    }

    const activeMediaConfig = this.feed.getActiveMediaConfig();

    return getUserMedia({
      audio: activeMediaConfig.audio,
      video: activeMediaConfig.video,
    });
  };
}
