import { call } from 'redux-saga/effects';
import { EventTypes } from 'utils/eventBus';
import { SDKEventListenerManager } from 'utils/sdk/eventLiseners';

export function* onControlEventListenersCommandSaga(
  type: EventTypes,
  data: { eventName: string; target: 'document' | 'window' }
) {
  const target = data.target === 'document' ? document : window;
  const listener: (event: Event) => void = yield call(
    SDKEventListenerManager.findOrCreateEventListener,
    data.eventName,
    data.target
  );

  if (type === 'connectEventListener') {
    target.addEventListener(data.eventName, listener);

    return;
  }

  target.removeEventListener(data.eventName, listener);
  yield call(SDKEventListenerManager.removeEventListener, data.eventName, data.target);
}
