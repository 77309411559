import { singleton } from 'utils/singleton';
import { logger } from 'utils/logger';
import { RatchetResult } from 'features/e2ee/types';

class Client {
  private _client?: any;

  private initialized: boolean = false;

  private lastRatchetCall?: number;

  private lastRatchetResult?: RatchetResult;

  private pendingInitialRatchet?: Promise<RatchetResult>;

  initialize = async (senderId: number, encryptionKey: ArrayBuffer) => {
    logger.remote({ system: true, capture: 'e2ee' }).info('Initializing SFrame client');

    // @ts-ignore
    const { SFrame } = await import('sframe');

    this._client = await SFrame.createClient(senderId, {
      skipVp8PayloadHeader: true,
    });

    await this.setSenderEncryptionKey(encryptionKey);

    this.initialized = true;

    logger.remote({ system: true, capture: 'e2ee' }).info('SFrame client initialized');
  };

  isInitialized = () => this.initialized;

  setSenderEncryptionKey = async (encryptionKey: ArrayBuffer) => {
    await this._client.setSenderEncryptionKey(encryptionKey);
  };

  addReceiver = async (senderId: number, encryptionKey: ArrayBuffer) => {
    if (!this.isInitialized()) {
      throw new Error('Cannot add a receiver. SFrame client is not initialized.');
    }

    await this._client.addReceiver(senderId);
    await this._client.setReceiverEncryptionKey(senderId, encryptionKey);

    logger
      .remote({ system: true, capture: 'e2ee' })
      .debug(`Added a new receiver to SFrame, senderId=${senderId}`);
  };

  deleteReceiver = async (senderId: number) => {
    if (!this.isInitialized()) {
      throw new Error('Cannot delete a receiver. SFrame client is not initialized.');
    }

    await this._client.deleteReceiver(senderId);
  };

  cleanup = () => {
    logger.remote({ system: true, capture: 'e2ee' }).info('Clearing SFrame client');

    this.initialized = false;
    this.lastRatchetCall = undefined;
    this.lastRatchetResult = undefined;
    this.pendingInitialRatchet = undefined;

    if (this._client) {
      this._client.close();
    }
  };

  getClient = () => this._client;

  ratchetSenderEncryptionKey = async (): Promise<RatchetResult> => {
    const ratchet = async (timestamp: number) => {
      this.lastRatchetCall = timestamp;

      const result = await this._client.ratchetSenderEncryptionKey();

      this.lastRatchetResult = result;

      logger
        .remote({
          system: true,
          capture: 'e2ee',
        })
        .info('Sender stream key has been ratcheted successfully');

      return result;
    };
    const now = Date.now();

    if (!this.lastRatchetCall || !this.lastRatchetResult || now - this.lastRatchetCall > 1000) {
      if (!this.pendingInitialRatchet) {
        this.pendingInitialRatchet = ratchet(now);

        return this.pendingInitialRatchet;
      }

      if (!this.lastRatchetResult) {
        return this.pendingInitialRatchet;
      }

      return ratchet(now);
    }

    return this.lastRatchetResult;
  };
}

export const SFrameManager = singleton<Client>(() => new Client());
