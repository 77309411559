import { localStreamCollapsed } from 'features/layout/features/config/configSlice';
import { SourceDetails } from 'features/layout/types';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';

export const useMinimizeLocalTileControl = (
  source: SourceDetails,
  clickCallback: (e: React.MouseEvent<HTMLLIElement | HTMLButtonElement, MouseEvent>) => void
) => {
  const { t } = useTranslation('room');
  const dispatch = useAppDispatch();

  const onClick = (e: React.MouseEvent<HTMLLIElement | HTMLButtonElement, MouseEvent>) => {
    clickCallback?.(e);
    dispatch(localStreamCollapsed());
  };

  const showControl = source.kind === 'local';

  return {
    show: showControl,
    icon: 'minimize',
    text: t('tooltips.minimize_own'),
    onClick,
  };
};
