import React, { useEffect, useId } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal, selectActiveModalData } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from 'components/ConfirmationDialog';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogTitle } from 'components/Dialog';
import { DialogContent, DialogContentText } from '@mui/material';
import { usePermissions } from 'features/permissions/usePermissions';
import { PermissionTypes } from 'features/permissions/types';

const RecordingPlanLengthLimitModal = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const modalData = useAppSelector(selectActiveModalData);

  const prefix = useId();

  const { hasPermissions } = usePermissions();

  const canManageRecording = hasPermissions(PermissionTypes.recording);

  useEffect(() => {
    if (!canManageRecording) {
      dispatch(closeModal('recordingPlanLengthLimit'));
    }
  }, [canManageRecording, dispatch]);

  const handleOk = () => {
    dispatch(closeModal('recordingPlanLengthLimit'));
  };

  const handleCancel = () => {
    dispatch(closeModal('recordingPlanLengthLimit'));
  };

  if (modalData.minutesLimit >= 60) {
    return (
      <Dialog open aria-labelledby={`${prefix}-title`} aria-describedby={`${prefix}-description`}>
        <DialogTitle id={`${prefix}-title`}>{t('recording_plan_length_limit.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText id={`${prefix}-description`}>
            {t('recording_plan_length_limit.description', { limit: modalData.minutesLimit })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>{t('recording_plan_length_limit.btn_ok_label')}</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <ConfirmationDialog
      open
      maxWidth="sm"
      title={t('recording_free_plan_length_limit.title')}
      description={t('recording_free_plan_length_limit.description', {
        limit: modalData.minutesLimit,
      })}
      cancelText={t('recording_free_plan_length_limit.btn_cancel_label')}
      onOk={handleOk}
      onCancel={handleCancel}
      preventClose
      hideCloseButton
      okButton={
        <Button href="https://dashboard.digitalsamba.com" target="_blank" rel="noopener noreferrer">
          {t('recording_free_plan_length_limit.btn_ok_label')}
        </Button>
      }
    />
  );
};

export default RecordingPlanLengthLimitModal;
